import { FormControlLabel } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as ROUTES from '../../../../../constants/routes';
import { PLP_SAVE_LIST_TEALIUM } from '../../../../../constants/Tealium';
import {
  DELETE_REMOVE_WISH_LIST_ACTION,
  SET_WISH_LIST_PLP_LOADING_ACTION,
} from '../../../../../redux/actions/wish-list.actions';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { wishListSelector } from '../../../../../redux/selectors/wish-list.selector';
import { ReactComponent as BookmarkIcon } from '../../../../../theme/assets/bookmark_border.svg';
import { ReactComponent as BookmarkBorderIcon } from '../../../../../theme/assets/bookmark_filled.svg';
import {
  enterKeyPressHandler,
  sendTealiumData,
} from '../../../../../utils/utils';
import { UserType } from '../../../../../_foundation/enum/User/UserType';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { useSaveToListMenu } from '../../../SaveToListMenu/hooks/SaveToListMenuHooks';
import { SaveToListMenu } from '../../../SaveToListMenu/SaveToListMenu';
import { WishListProductsConstants } from '../../../WishListProducts/WishListProductsConstants';
import { ProductCardSaveListConstants } from './ProductCardSaveListConstants';
import { UnsignedSaveList } from './UnsignedSaveList/UnsignedSaveList';

/**
 * @interface IProductCardSaveList
 */
interface IProductCardSaveList {
  id: string;
  name: string;
  partNumber?: string;
  descriptionName?: string;
  productData: IProductListContents;
}

/**
 * @component ProductCardSaveList renders the Save to list option when user is authenticated.
 */
const ProductCardSaveList: React.FC<IProductCardSaveList> = ({
  id,
  name,
  partNumber,
  descriptionName,
  productData,
}) => {
  const { SAVE_TO_LIST, SAVED_TO_LISTS, REMOVE_FROM_LIST } =
    ProductCardSaveListConstants;

  const { PARAM_ID } = WishListProductsConstants;

  const { pathname, search } = useLocation();

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { userType } = useSelector(authenticationSelector);

  const {
    wishListProductPage: {
      giftListIds,
      loading,
      giftListId: currentGiftListId,
    },
  } = useSelector(wishListSelector);

  const { t } = useTranslation();

  const saveToListRef = useRef<HTMLElement>(null);

  const isAuthenticated = userType !== UserType.NotSignedIn;

  const { RenderPopper, ntePopperClickHandler, showPopper } = useNtePopper({
    id: `save-to-list-${id}-menu`,
    ariaLabelledById: `save-to-list-${id}`,
    focusTrap: false,
    disablePortal: false,
  });

  const [isSelected, setIsSelected] = useState(false);

  useSaveToListMenu({ productData, setIsSelected });

  const showSavedList = isSelected && isAuthenticated;

  const saveToListIcon = showSavedList ? (
    <BookmarkBorderIcon
      className='checked'
      role='img'
      aria-label='bookmark checked icon'
    />
  ) : (
    <BookmarkIcon role='img' aria-label='bookmark icon' />
  );

  const saveToListLabel = showSavedList ? t(SAVED_TO_LISTS) : t(SAVE_TO_LIST);

  const isCartPage = ROUTES.SHOPPING_CART === pathname;

  const giftListIdParam = new URLSearchParams(search).get(PARAM_ID);

  const giftListId = giftListIdParam ? giftListIdParam : currentGiftListId;

  const showRemoveFromList = Boolean(giftListId) || isCartPage;

  /**
   * @method removeFromListHandler Handles events with respect to removing that specific
   * product from the current wishlist.
   */
  const removeFromListHandler = (): void => {
    const giftItemId = giftListIds[id];

    if (mySite && giftListId && partNumber && descriptionName) {
      dispatch(SET_WISH_LIST_PLP_LOADING_ACTION(true));

      dispatch(
        DELETE_REMOVE_WISH_LIST_ACTION({
          storeID: mySite.storeID,
          wishListId: giftListId,
          itemId: giftItemId,
          productId: id,
          wishListPlp: true,
          productPartNumber: partNumber,
          descriptionName,
        })
      );
    }
  };

  /**
   * @method popperClickHandler Opens / Closes the Nte popper and also
   * takes care of bringing the popper in view.
   */
  const popperClickHandler = (): void => {
    ntePopperClickHandler();

    sendTealiumData({
      tealium_event: PLP_SAVE_LIST_TEALIUM,
      product_id: [partNumber],
      product_name: [name],
    });
  };

  return (
    <>
      {isAuthenticated && showRemoveFromList && (
        <>
          {!loading ? (
            <FormControlLabel
              control={<BookmarkBorderIcon className='checked' />}
              className='card_save_list'
              label={t(REMOVE_FROM_LIST)}
              ref={saveToListRef}
              onClick={removeFromListHandler}
              tabIndex={0}
              onKeyDown={(e: any) =>
                enterKeyPressHandler(e, removeFromListHandler)
              }
              role={'button'}
            />
          ) : (
            <Skeleton />
          )}
        </>
      )}

      {!showRemoveFromList && (
        <>
          <FormControlLabel
            control={saveToListIcon}
            className='card_save_list'
            label={saveToListLabel}
            ref={saveToListRef}
            onClick={popperClickHandler}
            tabIndex={0}
            onKeyDown={(e: any) => enterKeyPressHandler(e, popperClickHandler)}
            role={'button'}
          />

          <RenderPopper
            anchor={saveToListRef}
            ntePopperCloseHandler={ntePopperClickHandler}
            showPopper={showPopper}
            flip>
            {isAuthenticated ? (
              <SaveToListMenu
                id={id}
                clickHandler={ntePopperClickHandler}
                productData={productData}
              />
            ) : (
              <UnsignedSaveList id={id} clickHandler={ntePopperClickHandler} />
            )}
          </RenderPopper>
        </>
      )}
    </>
  );
};

export { ProductCardSaveList };
