import { call, put } from '@redux-saga/core/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import i18n from '../../../i18n';
import { alertMessageId } from '../../../utils/utils';
import { orderService } from '../../../_foundation/apis/order/order.service';
import { FETCH_ORDER_LOOKUP_SUCCESS_ACTION } from '../../actions/orderLookUp.action';

const translationKey = 'API_ERROR_HANDLING.MY_ACCOUNT.TRACK_MY_ORDERS';

export function* fetchOrderLookUp(action: any): Generator {
  try {
    const response: any = yield call(
      orderService.fetchOrderLookupByOrderNoOrCustomerNo,
      action.payload
    );
    if (response) {
      yield put(FETCH_ORDER_LOOKUP_SUCCESS_ACTION(response));
    }
  } catch (e) {
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.ORDER_STATUS.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}
