import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import './SkipToMainContentStyles.scss';

/**
 * @component SkipToMainContent renders the
 * "Skip to content" hashlink when the user
 * starts to tab navigate the application.
 */
const SkipToMainContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HashLink
      className='print-exclude skip-to-content-link'
      rel='nofollow'
      smooth
      to='#main-content'>
      {t('SKIP_TO_CONTENT')}
    </HashLink>
  );
};

export { SkipToMainContent };
