import { Button, TypographyVariant } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { MutableRefObject } from 'react';

/**
 * @interface NavMenuItemProps
 */
interface NavMenuItemProps {
  /**
   * @prop ariaExpanded
   * Determines whether the menu item is open or not.
   */
  ariaExpanded?: boolean;
  imgAlt?: string;
  menuIconName?: string;
  menuIconSvg?: JSX.Element;
  menuTitle: string;
  menuAnchor?: MutableRefObject<any>;
  onClick?: () => void;
  titleVariant?: TypographyVariant;
  className?: string;
  disablePopperAction?: boolean;
  ariaControls?: string;
  disableAriaControls?: boolean;
  disableAriaExpanded?: boolean;
}

/**
 * @component NavMenuItem renders the NavMenu Item.
 */
const NavMenuItem: React.FC<NavMenuItemProps> = ({
  imgAlt = 'nav-icon',
  menuAnchor,
  menuIconName,
  menuIconSvg,
  menuTitle,
  onClick,
  titleVariant = 'body2',
  className = '',
  disablePopperAction = false,
}) => (
  <li
    className={`nav_menu_item cursor-pointer ${className} ${
      disablePopperAction ? 'pointer-events-none' : ''
    }`}>
    <Button onClick={onClick} className='no-link-effect menu-button'>
      {menuIconName && (
        <img alt={imgAlt} width='24' height='24' src={menuIconName} />
      )}

      {menuIconSvg && menuIconSvg}

      <div ref={menuAnchor}>
        <span
          className='menu-title'
          dangerouslySetInnerHTML={{ __html: menuTitle }}
        />
      </div>

      <ExpandMoreIcon className='expand-icon' />
    </Button>
  </li>
);

export { NavMenuItem };
