import { AxiosRequestConfig } from 'axios';
import { makeRequest } from '../axios/axiosConfig';
import { AssociatedPromotionResponse } from './IPromocode';
import { PromoCodeServiceConstant } from './promoCode.service.constants';

/**
 * @Interface IFetchAssociatedPromotionRequest
 */
export interface IFetchAssociatedPromotionRequest {
  storeID: string;
  promoCode: string;
}

const promoCodeService = {
  /**
   * @method fetchAssociatedPromotionInfo
   * responsible to apply the promocode for order
   */
  async fetchAssociatedPromotionInfo({
    storeID,
    promoCode,
  }: IFetchAssociatedPromotionRequest): Promise<AssociatedPromotionResponse> {
    const { FETCH_ASSOCIATED_PROMOTION_URL } = PromoCodeServiceConstant;

    const fetchAssociatedPromotionPayload = {
      promoCode: promoCode,
    };

    try {
      const fetchAssociatedPromotionRequest: AxiosRequestConfig = {
        url: FETCH_ASSOCIATED_PROMOTION_URL(storeID),
        method: 'POST',
        data: fetchAssociatedPromotionPayload,
      };

      const fetchAssociatedPromotionResponse: AssociatedPromotionResponse =
        await makeRequest(fetchAssociatedPromotionRequest);

      return fetchAssociatedPromotionResponse;
    } catch (err) {
      throw err;
    }
  },

  /**
   * @method  removeAssociatedPromotionInfo
   * responsible to remove the promocode for a order
   */
  async removeAssociatedPromotionInfo({
    storeID,
    promoCode,
  }: IFetchAssociatedPromotionRequest): Promise<AssociatedPromotionResponse> {
    const { REMOVE_ASSOCIATED_PROMOTION_URL } = PromoCodeServiceConstant;

    const fetchAssociatedPromotionPayload = {
      promoCode: promoCode,
    };

    try {
      const removeAssociatedPromotionRequest: AxiosRequestConfig = {
        url: REMOVE_ASSOCIATED_PROMOTION_URL(storeID, promoCode),
        method: 'DELETE',
        data: fetchAssociatedPromotionPayload,
      };

      const removeAssociatedPromotionResponse: AssociatedPromotionResponse =
        await makeRequest(removeAssociatedPromotionRequest);

      return removeAssociatedPromotionResponse;
    } catch (err) {
      throw err;
    }
  },
};

export { promoCodeService };
