import { ZipCodeUSValidatorForDeliveryOptions } from '../../../../utils/validators/zipCode.validation';

const DeliveryOptionConstants = {
  TRANSLATIONS: {
    DELIVERY_OPTION_TITLE: 'DELIVERY_OPTION.TITLE',
    DELIVERY_OPTION_SUBTITLE_PART1: 'DELIVERY_OPTION.SUBTITLE_PART1',
    DELIVERY_OPTION_SUBTITLE_PART2: 'DELIVERY_OPTION.SUBTITLE_PART2',
    DELIVERY_OPTION_SUBTITLE_PART3: 'DELIVERY_OPTION.SUBTITLE_PART3',
    DELIVERY_OPTION_SUBTITLE_PART4: 'DELIVERY_OPTION.SUBTITLE_PART4',
    DELIVERY_OPTION_SHIPPING_TITLE: 'DELIVERY_OPTION.SHIPPING_TITLE',
    DELIVERY_OPTION_ZIP_CODE: 'DELIVERY_OPTION.ZIP_CODE',
    STORE_PICK_TITLE: 'DELIVERY_OPTION.STORE_PICK_TITLE',
    ADDITIONAL: 'DELIVERY_OPTION.ADDITIONAL',
    STORE_PICKUP: 'DELIVERY_OPTION.STORE_PICKUP',
    ITEMS_AVAILABILITY: 'DELIVERY_OPTION.ITEMS_AVAILABILITY',
    AVAILABLE_MESSAGE: 'DELIVERY_OPTION.AVAILABLE_MESSAGE',
    AVAILABLE_MESSAGE_STORE: 'DELIVERY_OPTION.AVAILABLE_MESSAGE_STORE',
    BOPIS_AVAILABLE_MESSAGE: 'DELIVERY_OPTION.BOPIS_AVAILABLE_MESSAGE',
    NOT_AVAILABLE_MESSAGE: 'DELIVERY_OPTION.NOT_AVAILABLE_MESSAGE',
    RESTRICTED_ITEM_MESSAGE_PDP_PLP:
      'DELIVERY_OPTION.RESTRICTED_ITEM_MESSAGE_PDP_PLP',
    RESTRICTED_ITEM_MESSAGE_CART:
      'DELIVERY_OPTION.RESTRICTED_ITEM_MESSAGE_CART',
    TRANSLATION_KEY: {
      DELIVERY_OPTION_BUTTON_KEY: 'DELIVERY_OPTION.TRANSLATION_KEY.BUTTON_KEY',
      CHECK_STORE_STOCK: 'DELIVERY_OPTION.TRANSLATION_KEY.CHECK_STORE_STOCK',
      SEE_PICK_UP_DETAILS:
        'DELIVERY_OPTION.TRANSLATION_KEY.SEE_PICK_UP_DETAILS',
    },
    SEE_PICK_UP_DETAILS: {
      INFO1: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.INFO1',
      INFO2: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.INFO2',
      INFO3: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.INFO3',
      INFO4: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.INFO4',
      LINE_ONE: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_ONE',
      LINE_TWO: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_TWO',
      LINE_THREE: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_THREE',
      LINE_FOUR: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_FOUR',
      LINE_FIVE: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_FIVE',
      LINE_SIX: 'DELIVERY_OPTION.SEE_PICK_UP_DETAILS.LINE_SIX',
    },
  },
  FACTORY_SHIPPED: 'DELIVERY_OPTION.FACTORY_SHIPPED',
  DELIVERY_ESPOT: 'DELIVERY_LIFTGATE',
  VALIDATORS: {
    ZIP_CODE: ZipCodeUSValidatorForDeliveryOptions.ZIP_CODE_US,
  },
  NULL: 'null',
  CONT: 'CONT',
  TAX_ONLY: 'TAX ONLY',
  ZERR: 'ZERR',
  TBD: 'TBD',
  FREE: 'FREE',
  GET_IT: 'Get it by',
  GET_IT_STORE: 'Get it',
  SHIP_TO_STORE: 'Ship to Store',
  ESPOT_BOPIS: 'DELIVERY_STOREPICKUP_DETAIL',
};

export { DeliveryOptionConstants };
