import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_GIFT_CARD_ACTION,
  ADD_TO_CART_REQUEST_ACTION,
  DELETE_ITEM_IN_CART_ACTION,
  FETCH_CART_DETAILS_REQUEST_ACTION,
  FETCH_GUEST_CONTACT_INFO_ACTION,
  FETCH_SHIPPING_INFO_ACTION,
  FETCH_USABLE_PAYMENT_INFO_ACTION,
  GET_CART_PRODUCTS_ACTION,
  GUEST_AND_CART_DETAILS_ACTION,
  GUEST_AND_CATALOG_ORDER_ACTION,
  ORDER_CALCULATE_ACTION,
  PRECHECKOUT_CART_ACTION,
  REMOVE_GIFT_CARD_ACTION,
  UPDATE_ITEM_IN_CART_ACTION,
  UPDATE_PAYMENT_INFO_ACTION,
  UPDATE_SHIPPING_AND_BILLING_INFO_ACTION,
} from '../../actions/order.actions';
import {
  ADD_PO_NUMBER_ACTION,
  FETCH_ORDER_CONFIRMATION_ACTION,
} from '../../actions/orderConfirmation.action';
import {
  addGiftCard,
  addToCart,
  calculateOrder,
  deleteItemInCart,
  fetchCartDetails,
  fetchUsablePaymentInfo,
  getCartProducts,
  getGuestContactInfo,
  getOrderConfirmationDetails,
  getShippingInfo,
  guestAndCartInfo,
  guestAndCatalogOrderInfo,
  preCheckoutandCheckoutInfo,
  removeGiftCard,
  updateItemInCart,
  updatePaymentInfo,
  updatePoNumberInfo,
  updateShippingAndBillingInfo,
} from '../workers/order.workers';

export function* watchSaga() {
  yield takeLatest(FETCH_CART_DETAILS_REQUEST_ACTION, fetchCartDetails);

  yield takeEvery(ADD_TO_CART_REQUEST_ACTION, addToCart);

  yield takeEvery(GUEST_AND_CART_DETAILS_ACTION, guestAndCartInfo);

  yield takeEvery(GUEST_AND_CATALOG_ORDER_ACTION, guestAndCatalogOrderInfo);

  yield takeEvery(UPDATE_ITEM_IN_CART_ACTION, updateItemInCart);

  yield takeEvery(DELETE_ITEM_IN_CART_ACTION, deleteItemInCart);

  yield takeLatest(GET_CART_PRODUCTS_ACTION, getCartProducts);

  yield takeLatest(FETCH_SHIPPING_INFO_ACTION, getShippingInfo);

  yield takeLatest(FETCH_GUEST_CONTACT_INFO_ACTION, getGuestContactInfo);

  yield takeLatest(
    UPDATE_SHIPPING_AND_BILLING_INFO_ACTION,
    updateShippingAndBillingInfo
  );

  yield takeLatest(ADD_GIFT_CARD_ACTION, addGiftCard);

  yield takeLatest(REMOVE_GIFT_CARD_ACTION, removeGiftCard);

  yield takeLatest(FETCH_USABLE_PAYMENT_INFO_ACTION, fetchUsablePaymentInfo);

  yield takeLatest(PRECHECKOUT_CART_ACTION, preCheckoutandCheckoutInfo);

  yield takeLatest(
    FETCH_ORDER_CONFIRMATION_ACTION,
    getOrderConfirmationDetails
  );

  yield takeLatest(UPDATE_PAYMENT_INFO_ACTION, updatePaymentInfo);

  yield takeLatest(ADD_PO_NUMBER_ACTION, updatePoNumberInfo);

  yield takeLatest(ORDER_CALCULATE_ACTION, calculateOrder);
}
