import HTMLReactParser from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { FOOTER_MENU_CLICK_TEALIUM } from '../../../../constants/Tealium';
import { sendTealiumData } from '../../../../utils/utils';
import { eSpotService } from '../../../../_foundation/apis/transaction/espot/eSpot.service';
import { REQUIRED_ESPOT_ATTRIBUTE_MISSING } from '../../../../_foundation/constants/error';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';

const useEspotFooterLinks = (espotName: string) => {
  const [parsedEspotLinks, setParsedEspotLinks] = useState<Array<JSX.Element>>(
    []
  );

  /* Use the mysite hook to get information about the site store id to fetch espot content. */
  const { mySite } = useSite();

  const initializeEspot = useCallback(async () => {
    const { storeID, catalogID } = mySite;

    try {
      const espotPayload = {
        storeID,
        catalogID,
        name: espotName,
      };
      const espotResponse: any = await eSpotService.fetchEspotData(
        espotPayload
      );

      const marketingContent =
        espotResponse.MarketingSpotData[0]?.baseMarketingSpotActivityData[0]
          ?.marketingContentDescription[0]?.marketingText;

      if (marketingContent) {
        parseEspotContent(
          espotResponse.MarketingSpotData[0]?.baseMarketingSpotActivityData[0]?.marketingContentDescription[0]?.marketingText.trim()
        );
      } else {
        throw new Error(REQUIRED_ESPOT_ATTRIBUTE_MISSING);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mySite, espotName]);

  const parseEspotContent = (content: any) => {
    const parsedReactContent: any = HTMLReactParser(content);

    const recordClickAndScrollToTop = (
      e:
        | React.MouseEvent<HTMLAnchorElement>
        | React.TouchEvent<HTMLAnchorElement>
    ) => {
      sendTealiumData({
        tealium_event: FOOTER_MENU_CLICK_TEALIUM,
        nav_footer_element: e.currentTarget.text,
      });
      if (isFirefox) {
        window.scrollTo({
          top: 0,
        });
      }
    };

    const espotLinks = parsedReactContent?.props?.children.map(
      (listElement: any) => {
        const anchorElement = listElement?.props?.children;
        const href = anchorElement?.props?.href;
        const childText = anchorElement?.props.children;
        const target = anchorElement?.props?.target;

        return (
          <li className='footer_link' key={anchorElement.props.children}>
            <Link
              rel='nofollow'
              to={{ pathname: href }}
              onClick={recordClickAndScrollToTop}
              target={target}>
              {childText}
            </Link>
          </li>
        );
      }
    );
    setParsedEspotLinks(espotLinks);
  };

  useEffect(() => {
    if (mySite) {
      initializeEspot();
    }
  }, [initializeEspot, mySite]);

  return { parsedEspotLinks };
};

export { useEspotFooterLinks };
