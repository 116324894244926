import { OrderPOInformationConstants } from '../../components/Checkout/CheckoutPayment/OrderPOInformation/OrderPOInformationConstants';

export const site = {
  storeName: process.env.REACT_APP_STORENAME as string,
  transactionContext: process.env.REACT_APP_TRANSACTION_CONTEXT as string,
  searchContext: process.env.REACT_APP_SEARCH_CONTEXT as string,
  dxContext: process.env.REACT_APP_DX_CONTEXT as string,
  cmcPath: '/lobtools/cmc/ManagementCenterMain',
  hostUrl: process.env.REACT_APP_HOST_URL as string,
  yottaVersionNumber: process.env.REACCT_APP_YOTTA_VERSION_NUMBER as string,
  yottaConfigKey: process.env.REACT_APP_YOTTA_CONFIG_KEY as string,
  fetchProductsProfileName: process.env
    .REACT_APP_PRODUCTS_QUERY_PROFILE_NAME as string,
  minimumAmountToShowPercentage: process.env
    .REACT_APP_DISCOUNT_PERCENTAGE_PRICE as string,
  productCountPerPage: process.env.REACT_APP_PRODUCT_COUNT_PER_PAGE as string,
  TOKENEXORIGINSERVER: process.env.REACT_APP_TOKENX_ORIGIN_SERVER as string,
  TOKENEXID: process.env.REACT_APP_NT_TOKENX_ID as string,
  TOKENEXSCHEME: process.env.REACT_APP_NT_TOKENX_SCHEME as string,
  // trackOrderUrl: process.env.REACT_APP_STRATEGICIQ_BASE_URL as string,
  // trackOrderLicKey: process.env.REACT_APP_STRATEGICIQ_LIC_KEY as string,
  storeLocatorDefaultRadius: process.env
    .REACT_APP_DEFAULT_STORE_LOCATOR_RADIUS as string,
  storeLocatorResponseCount: process.env
    .REACT_APP_DEFAULT_STORE_LOCATOR_LIMIT as string,
  inventoryThreshold: process.env
    .REACT_APP_DEFAULT_INVENTORY_THRESHOLD as string,
  triggerProductSearchSuggestion: process.env
    .REACT_APP_TRIGGER_PRODUCT_SEARCH as string,
  triggerCategorySearchSuggestion: process.env
    .REACT_APP_TRIGGER_CATEGORY_SEARCH as string,
  enrichedPlpUrl: process.env.REACT_APP_BRANDS_QUERY_PROFILE_NAME as string,
  avsAuthToken: process.env.REACT_APP_AVS_AUTH_TOKEN as string,
  showAdvantagePromo: process.env.REACT_APP_SHOW_ADVANTAGE_PROMO as string,
  maxLineItems: process.env.REACT_APP_MAX_LINE_ITEMS as string,
  maxQuantity: process.env.REACT_APP_MAX_QUANTITY as string,
  TOKENEXDEBUG: process.env.REACT_APP_TOKENX_DEBUG
    ? Boolean(process.env.REACT_APP_TOKENX_DEBUG).valueOf()
    : false,
  soldinstoreAttributeId: process.env
    .REACT_APP_SOLD_IN_STORE_ATTRIBUTE_ID as string,
  promotionAttributeId: process.env.REACT_APP_PROMOTION_ATTRIBUTE_ID as string,
  searchDebounceTime: process.env.REACT_APP_SEARCH_DEBOUNCE_TIME as string,
  enableRVISiteWide: process.env.REACT_APP_ENABLE_RVI_SITE_WIDE as string,
  enablePlpGridView: process.env.REACT_APP_ENABLE_PLP_GRID_VIEW as string,
  disablePayPalPayment: process.env.REACT_APP_DISABLE_PAYPAL as string,
  enableGiftCardPaymentOption: process.env.REACT_APP_ENABLE_GIFTCARD as string,
  defaultPlpView: process.env.REACT_APP_DEFAULT_PLP_VIEW as string,
  enableIOMPaymentOptions: process.env
    .REACT_APP_ENABLE_IOM_PAYMENT_OPTIONS as string,
  enablePaypalPaymentOptions: process.env
    .REACT_APP_ENABLE_PAYPAL_OPTION as string,
  enableNET30PaymentOptions: process.env
    .REACT_APP_ENABLE_NET30_OPTION as string,
  fetchLimitedProductDataProfileName: process.env
    .REACT_APP_LIMITED_PRODUCT_DATA_PROFILE_NAME as string,
  poNumberRegex: OrderPOInformationConstants.PO_NUMBER_REGEX,
  DEALS_EVERY_DAY_SHIPPING_FILTER: 'everyday-free-shippingFilter',
  DEALS_NEW_PRODUCTS: 'new-productsFilter',
  DEALS_ON_SALE: 'on-saleFilter',
  DEALS_REBATES_SPECIAL_OFFER: 'rebates-special-offersFilter',
  DEALS_ADVANTAGE_EXCLUSIVE: 'advantage-exclusivesFilter',
};
