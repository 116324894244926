import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SHOP_STORE } from '../../../constants/routes';
import { breadcrumbSelector } from '../../../redux/selectors/breadcrumb.selector';
import { categoriesSelector } from '../../../redux/selectors/categories.selector';
import { useCurrentPathName } from '../../../utils/hooks/current-pathname/CurrentPathName';
import { ImageUrlUtility } from '../../../utils/image-url-utility';
import { formatToTitleCase, stripSeeAllItemCount } from '../../../utils/utils';
import { BreadcrumbType } from '../../../_foundation/enum/Breadcrumbs/Breadcrumbs';
import { IBreadcrumbLink } from '../../../_foundation/interface/Breadcrumb/IBreadcrumb';
import { IStoreName } from '../../../_foundation/interface/ProductList/IProductList';
import { NteEspot } from '../../NteEspot/NteEspot';
import { seoSelector } from '../../Seo/redux/selector/seo';
import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';
import './scss/PageHeader.scss';

/**
 * @interface PageHeaderProps
 */
export interface PageHeaderProps {
  /**
   * @prop headerImg
   * Defines the category img of the Page header.
   */
  headerImg?: string;

  /**
   * @prop pageTitle
   * The page Title that needs to be displayed.
   */
  pageTitle?: string;

  /**
   * @prop showBreadcrumbs
   * showBreadcrumbs flag whether to show breadcrumbs or not.
   */
  showBreadcrumbs?: boolean;

  /**
   * @prop espotId
   * showBreadcrumbs flag whether to show breadcrumbs or not.
   */
  espotId?: string;

  /**
   * @prop additional page title to be displayed in h2
   */
  h2Title?: string;

  /**
   * @prop additional page title to be displayed in h3
   */
  h3Title?: string;

  /**
   * @prop extra high page header prop
   */
  xtrahigh?: boolean;

  /**
   * @prop breadcrumbType
   * Determines the type of breadcrumb to be loaded.
   */
  breadcrumbType?: BreadcrumbType;

  breadcrumbArray?: IBreadcrumbLink[];

  /**
   * @prop Responsible for page information
   */
  pageType?: string;

  brandHeader?: string;
}

/**
 * @component PageHeader
 * @param headerImg - Defines the Category img of the Page.
 * @param pageTitel - Defines the Page Title to be displayed.
 * @returns PageHeader Component.
 */
const PageHeader = ({
  headerImg,
  pageTitle = '',
  showBreadcrumbs,
  espotId,
  h2Title,
  h3Title,
  xtrahigh = false,
  breadcrumbType = BreadcrumbType.Static,
  breadcrumbArray,
  pageType = 'default',
  brandHeader,
}: PageHeaderProps) => {
  const { currentPathName } = useCurrentPathName();

  const history = useHistory();

  const isShopThisStore: boolean =
    history.location.pathname.split('/').splice(0, 2).join('/') === SHOP_STORE;

  const imageUrl =
    headerImg &&
    (headerImg.includes('.jpg') ||
      headerImg.includes('.png') ||
      headerImg.includes('.jpeg') ||
      headerImg.includes('.svg'))
      ? `${stripSeeAllItemCount(headerImg.toLowerCase()).replace(/ /g, '-')}`
      : headerImg
      ? `${stripSeeAllItemCount(headerImg.toLowerCase()).replace(
          / /g,
          '-'
        )}.jpg`
      : '';

  let backgroundImageUrl = '';

  if (headerImg && xtrahigh) {
    backgroundImageUrl = headerImg;
  }

  if (headerImg) {
    backgroundImageUrl = ImageUrlUtility.createImgSrc(imageUrl);
  }

  let breadcrumbMargin = 'page-title';
  if (!showBreadcrumbs && pageTitle) {
    breadcrumbMargin = 'page-title-margin-top';
  }
  if (showBreadcrumbs && !pageTitle) {
    breadcrumbMargin = 'breadcrumbs-title';
  }

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const seoConfig = useSelector(seoSelector);

  const { currentCategoryId } = useSelector(categoriesSelector);

  const {
    name,
    loading: breadcrumbLoading,
    breadCrumbTrailEntryView,
  } = useSelector(breadcrumbSelector);

  const storeDetail = history.location.state as IStoreName;

  const isProductBreadcrumb = breadcrumbType === BreadcrumbType.Product;

  const isCategoryBreadcrumb = breadcrumbType === BreadcrumbType.Category;

  const isBreadcrumb = isCategoryBreadcrumb || isProductBreadcrumb;

  const isLoading = Boolean(currentCategoryId) && !seoConfig && isBreadcrumb;

  let breadcrumbTitle: string | null = '';

  if (isCategoryBreadcrumb) {
    breadCrumbTrailEntryView && breadCrumbTrailEntryView.length > 0
      ? (breadcrumbTitle =
          breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 1].label)
      : (breadcrumbTitle = '');
  } else {
    breadcrumbTitle = isProductBreadcrumb
      ? name
      : seoConfig &&
        seoConfig[formattedPath] &&
        seoConfig[formattedPath]?.page.title.split('|')[0].trim();
  }

  const title = useMemo(() => {
    if (brandHeader) {
      return brandHeader;
    }

    if (breadcrumbTitle && breadcrumbTitle !== '') {
      return breadcrumbTitle;
    }

    if (pageTitle !== '') {
      return pageTitle;
    }

    return '';
  }, [brandHeader, breadcrumbTitle, pageTitle]);

  return (
    <div
      className={`page-row ${breadcrumbMargin} ${
        backgroundImageUrl && 'bleeder has-background-image'
      } ${xtrahigh && 'xtrahigh'}`}>
      {backgroundImageUrl ? (
        <div className='background-image-grid'>
          <div
            className='background-image lazyload'
            data-bgset={backgroundImageUrl}></div>

          <span className='gradient-overlay left'></span>

          <span className='gradient-overlay right'></span>

          <div className='content-wrapper'>
            {showBreadcrumbs && !isShopThisStore && (
              <BreadCrumbs
                whiteText={Boolean(backgroundImageUrl)}
                breadcrumbType={breadcrumbType}
                breadcrumbArray={breadcrumbArray}
              />
            )}

            {(breadcrumbType === BreadcrumbType.Static ||
              (!isLoading && (!breadcrumbLoading || brandHeader))) && (
              <>
                <Typography className={`page-title-header`} variant='h1'>
                  {decode(title)}
                </Typography>

                {h2Title && (
                  <Typography variant='h2' className='top-margin28'>
                    {decode(h2Title)}
                  </Typography>
                )}

                {h3Title && (
                  <Typography variant='h3' className='top-margin28'>
                    {decode(h3Title)}
                  </Typography>
                )}
              </>
            )}
            {espotId && (
              <NteEspot espotId={espotId} className='title-subheader' />
            )}
          </div>
        </div>
      ) : (
        <div className='content-wrapper'>
          {showBreadcrumbs && !isShopThisStore && (
            <BreadCrumbs
              whiteText={Boolean(backgroundImageUrl)}
              breadcrumbType={breadcrumbType}
              breadcrumbArray={breadcrumbArray}
            />
          )}

          {isShopThisStore && storeDetail && (
            <BreadCrumbs
              breadcrumbType={BreadcrumbType.Static}
              breadcrumbArray={[
                { displayText: `Sold at ${storeDetail.storeName}`, url: '/' },
              ]}
            />
          )}

          {!isLoading && (
            <>
              <Typography className={`page-title-header`} variant='h1'>
                {decode(title)}
              </Typography>

              {h2Title && (
                <Typography variant='h2' className='top-margin28'>
                  {decode(h2Title)}
                </Typography>
              )}

              {h3Title && (
                <Typography variant='h3' className='top-margin28'>
                  {decode(h3Title)}
                </Typography>
              )}
            </>
          )}

          {espotId && (
            <NteEspot espotId={espotId} className='title-subheader' />
          )}
        </div>
      )}
    </div>
  );
};

export { PageHeader };
