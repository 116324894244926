import { AxiosRequestConfig } from 'axios';
import { IEmailOptDeals } from '../../interface/EmailDeals/IEmailOptin';
import { makeRequest } from '../axios/axiosConfig';
import { EmailDealsServiceConstants } from './emailDeals.service.constants';

/**
 * Service to call the email deals signup API
 */
const EmailDealsService = {
  async optinEmailDeals({
    storeId,
    emailOptDeals,
    history,
  }: IEmailOptDeals): Promise<any> {
    const { EMAIL_OPT_IN_URL } = EmailDealsServiceConstants;

    try {
      const emailOptRequest: AxiosRequestConfig = {
        url: EMAIL_OPT_IN_URL(storeId),
        method: 'POST',
        data: emailOptDeals,
      };

      const emailOptResponse: any = await makeRequest(
        emailOptRequest,
        undefined,
        history
      );

      return emailOptResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { EmailDealsService };
