import { Skeleton } from '@material-ui/lab';

/**
 * @component EdealsSignupModalSkeleton placeholder for the espot used in the EdealsSignupModal.
 */
const EdealsSignupModalSkeleton: React.FC = () => {
  return (
    // @Fred styling fix needed
    <div className='cg-cols-2 cmc-content' style={{ width: '300px' }}>
      <div className='left'>
        <Skeleton className='page-skeleton' variant='rect' />
      </div>

      <div className='right card-content'>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
};

export { EdealsSignupModalSkeleton };
