import {
  ClickAwayListener,
  Divider,
  Popper,
  PopperPlacementType,
} from '@material-ui/core';
import React, { useEffect } from 'react';

/**
 * @interface NtePopperProps
 */
interface NtePopperProps {
  /**
   * @prop anchor Pass in the reference of the anchor element
   * with which the popper will be rendered as a reference.
   */
  anchor: React.MutableRefObject<any>;

  /**
   * @prop children Children elements that needs to be rendered
   * inside the NTE popper.
   */
  children: JSX.Element;

  /**
   * @prop closeHandler is responsible for closing
   * popper when clicking away from the popper component.
   */
  closeHandler: () => void;

  /**
   * @prop dismissHandler will be called once the popper
   * component unmounts from the DOM.
   */
  dismissHandler?: () => void;

  /**
   * @prop popperOnMount will be called once the popper
   * component mounts in the DOM.
   */
  popperOnMount?: () => void;

  /**
   * @prop id for the popper component.
   */
  id: string;

  /**
   * @prop showPopper flag determines whether to show
   * the popper component or not.
   */
  showPopper: any;

  /**
   * @prop popperPlacement Indicates the placement of the popper with respect
   * to it's anchor element.
   */
  popperPlacement: PopperPlacementType;

  /**
   * @prop ariaLabelledById for Nte popper.
   */
  ariaLabelledById?: string;

  /**
   * @prop classname for Nte popper.
   */
  className?: string;

  /**
   * @prop flip flag flips the popper when the popper gets clipped from the screen.
   */
  flip?: boolean;

  /**
   * @prop disable portal flag disables the react portal functionality of the popper
   * https://reactjs.org/docs/portals.html
   * https://mui.com/material-ui/api/popper/
   */
  disablePortal?: boolean;
}

/**
 * @component NtePopper is responsible for rendering
 * the generic NTE popper container component.
 */
const NtePopper: React.FC<NtePopperProps> = ({
  anchor,
  children,
  closeHandler,
  dismissHandler,
  popperOnMount,
  id,
  className = '',
  showPopper,
  popperPlacement,
  ariaLabelledById,
  flip = false,
  disablePortal = false,
}) => {
  const [arrowRef, setArrowRef] = React.useState<any>(null);
  const hideChatArray = ['my-store-menu', 'hamburger-menu'];

  useEffect(() => {
    if (popperOnMount) {
      /**
       * If a mount handler will be called when
       * the popper opens up.
       */
      if (hideChatArray.includes(id)) {
        var e = document.getElementById('cxone-guide-container');
        if (e != null) {
          e.className = 'hide-element';
        }
      }
      popperOnMount();
    }

    return () => {
      if (dismissHandler) {
        /**
         * If the dismiss handler is passed call it
         * once the popper component is unmounted.
         */
        if (hideChatArray.includes(id)) {
          var e = document.getElementById('cxone-guide-container');
          if (e != null) {
            e.className = '';
          }
        }
        dismissHandler();
      }
    };
  }, [dismissHandler, popperOnMount]);

  return (
    <Popper
      anchorEl={anchor.current}
      aria-modal={true}
      className={`nte_popper ${className}`}
      disablePortal={disablePortal}
      role='dialog'
      id={id}
      aria-labelledby={ariaLabelledById}
      modifiers={{
        offset: {
          enabled: true,
          offset: '[0, 14]',
        },
        flip: {
          enabled: flip,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
      open={showPopper}
      placement={popperPlacement}>
      {/* Click away listener is responsible for closing the popper when the user click somewhere in the DOM */}
      <ClickAwayListener mouseEvent='onMouseDown' onClickAway={closeHandler}>
        <div className='nte_popper-wrapper'>
          {/* Nte Popper arrow which dynamically adjusts the position based on viewports. */}
          <span className='nte_popper-arrow' ref={setArrowRef} />

          {/* Divider creates the border with the arrow icon. */}
          <Divider className='nte_popper-divider' />

          {/* Paper renders the popper component's children components. */}
          <div className='nte_popper-paper'>{children}</div>
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export { NtePopper };
