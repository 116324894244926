import React, { useEffect, useState } from 'react';
import './scss/NteCarousel.scss';
import Slider from 'react-slick';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Hidden } from '@material-ui/core';

/**
 * @interface CarouselProps defines props that should be passed to Nte Carousel.
 */
export interface CarouselProps {
  className?: string;
  slides: Array<JSX.Element>;
  ariaLabel?: string;
  dots?: boolean;
  infinite?: boolean;
  slidesToShow: number;
  slidesToScroll: number;
  speed?: number;
  autoPlay?: boolean;
  autoplaySpeed?: number;
  initialSlide?: number;
  isPlayPauseButton?: boolean;
  carouselCurrentSlide?: (value: number) => void;
  afterChange?: (Value: number) => void;
}

/**
 *
 * @param slides - Array of all slides in the Carousel.
 * @param visibleSlides - Total Number of slides to be displayed at a given time.
 * @param slideheight - Carousel Slide Height.
 * @param slideWidth - Carousel Slide Width.
 * @param className - Custom Classname to modify the carousel.
 * @returns Generic NteCarousel.
 */
const NteCarousel: React.FC<CarouselProps> = ({
  className,
  ariaLabel = 'carousel',
  slides,
  dots = false,
  infinite = true,
  slidesToShow,
  slidesToScroll,
  speed = 500,
  autoPlay = false,
  autoplaySpeed,
  initialSlide = 0,
  isPlayPauseButton = false,
  carouselCurrentSlide,
  afterChange,
}: CarouselProps) => {
  const [autoPlayOn, setAutoPlayOn] = useState<boolean>(false);

  const sliderRef = React.useRef<Slider>(null);

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const settings = {
    className,
    dots,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    initialSlide,
    autoPlay,
    autoplaySpeed,
    afterChange: (current: number) => {
      setActiveSlide(current);
    },
  };

  useEffect(() => {
    if (carouselCurrentSlide) {
      carouselCurrentSlide(activeSlide);
    }
    if (autoPlayOn) {
      sliderRef.current?.slickPlay();
    } else {
      sliderRef.current?.slickPause();
    }
  }, [activeSlide, autoPlayOn, carouselCurrentSlide]);

  const autoPlayMethod = () => {
    setAutoPlayOn(!autoPlayOn);
  };

  const disableButtons = () => {
    return slides.length <= settings.slidesToShow;
  };

  return (
    <div
      role='region'
      aria-label={ariaLabel}
      className={`${className} carousel nte_carousel`}>
      <p className='sr-only'>
        This is a carousel with auto-rotating slides. Activate any of the
        buttons to disable rotation. Use Next and Previous buttons to navigate,
        or jump to a slide with the slide dots.
      </p>

      <Hidden smDown>
        {isPlayPauseButton && (
          <button
            aria-label={autoPlayOn ? 'pause slide show' : 'play slide show'}
            className='play-pause'
            onClick={autoPlayMethod}
            type='button'>
            {!autoPlayOn ? (
              <PlayArrowIcon className='carousel-play-icon' />
            ) : (
              <PauseIcon className='carousel-pause-icon' />
            )}
          </button>
        )}
      </Hidden>

      <button
        className='slick-prev'
        onClick={() => sliderRef.current?.slickPrev()}
        aria-label='go to previous slide'
        disabled={disableButtons()}>
        <ArrowBackIcon />
      </button>

      <Slider
        ref={sliderRef}
        {...settings}
        arrows={false}
        className={`carousel ${className}`}>
        {slides.map((slide, index: number) => {
          return (
            <div
              key={index}
              className='carousel-slide'
              aria-label={`slide ${index + 1} 0f ${slides.length}`}>
              {slide}
            </div>
          );
        })}
      </Slider>

      <button
        className='slick-next'
        onClick={() => sliderRef.current?.slickNext()}
        aria-label='go to next slide'
        disabled={disableButtons()}>
        <ArrowForwardIcon />
      </button>
    </div>
  );
};

export { NteCarousel };
