import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWidth } from '../../../utils/hooks/use-width/use-width';
import { ProductUtility } from '../../../utils/product-utility';
import { checkTruthy, hasKey } from '../../../utils/utils';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import { NteCarousel } from '../Carousel/NteCarousel';
import { FETCH_RVI_ACTION } from './redux/actions/rvi.actions';
import { rviSelector } from './redux/selector/rvi.selector';
import { IRviCard, RviCard } from './RviCard/RviCard';
import { RviCardSkeleton } from './RviCard/RviCardSkeleton';
import { RviConstants } from './RviConstants';

/**
 * @component Rvi renders the Recently Viewed Items carousel.
 */
const Rvi: React.FC = () => {
  const { VISIBLE_SLIDES, RVI_TITLE } = RviConstants;

  const { mySite } = useSite();

  const { rviDataUpdated, rviProducts } = useSelector(rviSelector);

  const dispatch = useDispatch();

  const width = useWidth();

  const [rviSlides, setRviSlides] = useState<JSX.Element[]>([]);

  const { t } = useTranslation();

  let visibleSlides = 4;

  const initRviProducts = useCallback((): void => {
    if (rviProducts) {
      const rviSlides: JSX.Element[] = [];

      rviProducts.forEach((productDetails: IProductListContents) => {
        const {
          name,
          partNumber,
          thumbnailRaw,
          seo,
          salesPriceRestricted,
          manufacturer,
          type,
        } = productDetails;

        const showSeeCartPriceMsg = checkTruthy(salesPriceRestricted);

        const rviCard: IRviCard = {
          itemName: decode(name),
          productPartNumber: partNumber,
          thumbnail: thumbnailRaw,
          seo,
          showSeeCartPriceMsg,
          isStoreOnly: ProductUtility.isStoreOnly(productDetails),
          manufacturer: manufacturer,
          productType: type,
          productData: productDetails,
        };

        rviSlides.push(<RviCard {...rviCard} />);
      });

      setRviSlides(rviSlides);
    }
  }, [rviProducts]);

  useEffect(() => {
    initRviProducts();
  }, [initRviProducts]);

  const fetchRviProducts = useCallback((): void => {
    if (mySite?.storeID && checkTruthy(mySite?.enableRVISiteWide)) {
      dispatch(
        FETCH_RVI_ACTION({
          storeID: mySite?.storeID,
        })
      );
    }
  }, [dispatch, mySite]);

  useEffect(() => {
    fetchRviProducts();
  }, [fetchRviProducts]);

  const initRVITargetView = useCallback((): void => {
    /** Fire only after RVI is finished loading */
    if (
      !rviDataUpdated &&
      rviProducts &&
      rviProducts.length > 0 &&
      visibleSlides
    ) {
      if (typeof adobe != 'object') {
        console.error('Adobe not loaded yet, cannot trigger view.');
      } else {
        if (adobe?.target?.triggerView) {
          adobe?.target?.triggerView('rvi_ready');
        } else {
          console.error(
            "Adobe and/or Adobe.target undefined. Can't trigger view rvi_ready"
          );
        }
      }
    }
  }, [rviDataUpdated, rviProducts, visibleSlides]);

  useEffect(() => {
    if (mySite) {
      initRVITargetView();
    }
  }, [mySite, initRVITargetView]);

  if (hasKey(VISIBLE_SLIDES, width)) {
    visibleSlides = VISIBLE_SLIDES[width];
  }

  const noOfRviSlides = rviSlides.length;

  return (
    <>
      {mySite && checkTruthy(mySite.enableRVISiteWide) && noOfRviSlides > 0 && (
        <section className='page-row rvi print-exclude'>
          <Typography variant='h2'>{t(RVI_TITLE)}</Typography>

          {rviDataUpdated ? (
            <NteCarousel
              className='rvi-carousel product-list'
              slides={[<RviCardSkeleton />, <RviCardSkeleton />]}
              slidesToShow={visibleSlides}
              slidesToScroll={visibleSlides}
              infinite={noOfRviSlides > visibleSlides}
            />
          ) : (
            noOfRviSlides > 0 && (
              <NteCarousel
                className='rvi-carousel product-list'
                slides={rviSlides}
                slidesToShow={visibleSlides}
                slidesToScroll={visibleSlides}
                infinite={noOfRviSlides > visibleSlides}
              />
            )
          )}
        </section>
      )}
    </>
  );
};

export { Rvi };
