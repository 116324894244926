import { call, put } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { CartService } from '../../../../../_foundation/apis/cart/cart.service';
import { IGetCartDetails } from '../../../../../_foundation/interface/Cart/IOrder';
import i18n from '../../../../../i18n';
import { SET_BUTTON_LOADING_ACTION } from '../../../../../redux/actions/button.actions';
import { FETCH_CART_DETAILS_SUCCESS_ACTION } from '../../../../../redux/actions/order.actions';
import { trackUserEvent } from '../../../../../utils/lucky-orange';
import { alertMessageId } from '../../../../../utils/utils';
import { SHOW_NTE_ALERT_ACTION } from '../../../../NteAlert/actions/nte-alert.actions';
import { CheckoutFlows } from '../../../enum/Checkout';
import { CheckoutService } from '../../../services/checkout.service';
import {
  CARDINAL_ORDER_REQUEST_SUCCESS_ACTION,
  DELIVERY_PAGE_API_LOADING,
  FETCH_CHECKOUT_CART_DETAILS_DELIVERY,
  FETCH_CHECKOUT_CART_DETAILS_PAYMENT,
  FETCH_CHECKOUT_CART_DETAILS_REVIEW,
} from '../../actions/checkout.actions';

const translationKey = 'API_ERROR_HANDLING.CART_AND_CHECKOUT';

export function* fetchCheckoutCartDetails(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.getCartDetails,
      action.payload
    );

    if (response && action.payload.deliveryApiLoader) {
      yield put(DELIVERY_PAGE_API_LOADING(false));
    }

    const { checkoutFlow } = action.payload as IGetCartDetails;

    if (checkoutFlow === CheckoutFlows.Delivery) {
      yield put(FETCH_CHECKOUT_CART_DETAILS_DELIVERY(response));

      yield put(FETCH_CART_DETAILS_SUCCESS_ACTION(response));
    } else if (checkoutFlow === CheckoutFlows.Payment) {
      yield put(FETCH_CHECKOUT_CART_DETAILS_PAYMENT(response));

      yield put(FETCH_CART_DETAILS_SUCCESS_ACTION(response));
    } else if (checkoutFlow === CheckoutFlows.Review) {
      yield put(FETCH_CHECKOUT_CART_DETAILS_REVIEW(response));

      yield put(FETCH_CART_DETAILS_SUCCESS_ACTION(response));
    }
  } catch (e: any) {
    const { response }: AxiosError = e as AxiosError;

    const { checkoutFlow } = action.payload as IGetCartDetails;

    yield put(DELIVERY_PAGE_API_LOADING(false));

    const pageName =
      CheckoutFlows.Delivery === checkoutFlow
        ? 'Delivery'
        : CheckoutFlows.Payment === checkoutFlow
        ? 'Payment'
        : CheckoutFlows.Review === checkoutFlow
        ? 'Review'
        : '';

    trackUserEvent({
      eventMsg: response?.data,
      eventName: `Error occured in fetch cart details of the ${pageName} page`,
    });

    throw e;
  }
}

export function* makeCardinalOrder(action: any): Generator {
  try {
    const response: any = yield call(
      CheckoutService.cardinalOrder,
      action.payload
    );

    yield put(CARDINAL_ORDER_REQUEST_SUCCESS_ACTION(response));

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: true,
      })
    );
  } catch (e) {
    console.error(e);

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.PAYPAL.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: false,
      })
    );
  }
}
