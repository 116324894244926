import { call, put } from 'redux-saga/effects';
import { breadcrumbService } from '../../../_foundation/apis/search/breadcrumb/breadcrumb.service';
import {
  FETCH_BREADCRUMB_SUCCESS_ACTION,
  SET_BREADCRUMB_ERROR_RESPONSE_ACTION,
} from '../../actions/breadcrumb.actions';

export function* fetchBreadcrumbs(action: any): Generator {
  try {
    const response: any = yield call(
      breadcrumbService.fetchBreadcrumb,
      action.payload
    );

    yield put(FETCH_BREADCRUMB_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);

    yield put(SET_BREADCRUMB_ERROR_RESPONSE_ACTION(true));
  }
}
