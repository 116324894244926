import { Typography } from '@material-ui/core';
import React from 'react';
import './ThumbNailStyles.scss';
import { ReactComponent as AdvantageIcon } from '../../../../src/theme/assets/adv_color.svg';

/**
 * @type ThumbnailImageSize
 */
export type ThumbnailImageSize = 'small' | 'large';

/**
 * @type ThumbnailDimensions
 */
export type ThumbnailDimensions = { width?: string; height?: string };

/**
 * @interface IThumbnail
 */
export interface IThumbnail {
  /**
   * @prop description
   * Description text under the thumbnail img.
   */
  description?: string;

  /**
   * @prop dimensions
   * Dimensions of the thumbnail image.
   */
  dimensions?: ThumbnailDimensions;

  /**
   * @prop fullWidth flag makes the component
   * to render full width with respect to
   * it's parent container's width.
   */
  fullWidth?: boolean;

  /**
   * @prop imgAlt
   * alt text for the image.
   */
  imgAlt?: string;

  /**
   * @prop imgSrc
   * src link of the image to be rendered.
   */
  imgSrc: string;

  /**
   * @prop size
   * Size of the thumbnail image.
   * By default the thumbnail size is small.
   */
  size?: ThumbnailImageSize;

  /**
   * @prop onClick
   * Callback Function when Thumbnail is clicked.
   */
  onClick?: () => void;

  /**
   * @prop placeholderImg
   * Place holder image.
   */
  placeholderImg?: string;

  /**
   * @prop className
   * Custom class(es).
   */
  className?: string;

  /**
   * @prop advantage Icon in grid view.
   */
  showAdvantageExclusivesBadge?: boolean;
}

/**
 * @component Thumbnail renders a thumbnail image
 * along with an optional description text under it.
 */
const Thumbnail: React.FC<IThumbnail> = ({
  description,
  imgAlt = '',
  imgSrc,
  size = 'large',
  fullWidth = false,
  onClick,
  placeholderImg,
  className = '',
  showAdvantageExclusivesBadge,
}) => {
  return (
    <div className={`thumbnail ${className}`} onClick={onClick}>
      {showAdvantageExclusivesBadge && (
        <AdvantageIcon
          className='advantage-badge-grid'
          role='img'
          aria-label='advantage badge'
        />
      )}
      <img
        className='lazyload resize-lazy-image'
        data-src={imgSrc}
        data-sizes='auto'
        data-parent-fit='width'
        alt={imgAlt}
      />

      {description && (
        <Typography align='center' variant='h3'>
          {description}
        </Typography>
      )}
    </div>
  );
};

export { Thumbnail };
