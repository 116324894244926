import { createAction } from '@reduxjs/toolkit';
import { IFetchProduct } from '../../_foundation/apis/search/products/products.service';
import IWishListProductResponse from '../../_foundation/interface/Responses/IProductsByPartNumbersResponse';
import { IAddItemAction } from '../../_foundation/interface/SaveToList/ISaveToList';
import {
  IAddItemToWishList,
  IDeleteRemoveWishListRequest,
  IFetchSharedWishList,
  IShareListRequest,
  IShareListSuccess,
  IWishListProductRequest,
  IWishListRequest,
  IWishListResponse,
} from '../../_foundation/interface/WishList/IWishList';
import * as ACTION_TYPES from '../action-types/wish-list.action-types';

const GET_WISH_LIST_ACTION = createAction<IWishListRequest>(
  ACTION_TYPES.GET_WISH_LIST
);

const ADD_ITEM_TO_WISHLIST_ACTION = createAction<IAddItemToWishList>(
  ACTION_TYPES.ADD_ITEM_TO_WISHLIST
);

const GET_WISH_LIST_SUCCESS_ACTION = createAction<IWishListResponse>(
  ACTION_TYPES.GET_WISH_LIST_SUCCESS
);

const ADD_REMOVE_WISH_LIST_ACTION = createAction<IAddItemAction>(
  ACTION_TYPES.ADD_REMOVE_WISH_LIST
);

const ADD_REMOVE_WISH_LIST_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.ADD_REMOVE_WISH_LIST_SUCCESS
);

const DELETE_REMOVE_WISH_LIST_ACTION =
  createAction<IDeleteRemoveWishListRequest>(
    ACTION_TYPES.DELETE_REMOVE_WISH_LIST
  );

const DELETE_REMOVE_WISH_LIST_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.DELETE_REMOVE_WISH_LIST_SUCCESS
);

const FETCH_WISH_LIST_PRODUCTS_ACTION = createAction<IFetchProduct>(
  ACTION_TYPES.FETCH_WISH_LIST_PRODUCTS
);

const FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION =
  createAction<IWishListProductResponse>(
    ACTION_TYPES.FETCH_WISH_LIST_PRODUCTS_SUCCESS
  );

const FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION =
  createAction<IWishListProductResponse>(
    ACTION_TYPES.FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS
  );

const WISH_LIST_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.WISH_LIST_LOADING
);

const RESET_WISH_LIST_ACTION = createAction(ACTION_TYPES.RESET_WISH_LIST);

const SHARE_WISH_LIST_ACTION = createAction<IShareListRequest>(
  ACTION_TYPES.SHARE_WISH_LIST
);

const SHARE_WISH_LIST_SUCCESS_ACTION = createAction<IShareListSuccess>(
  ACTION_TYPES.SHARE_WISH_LIST_SUCCESS
);

const RESET_SHARE_WISH_LIST_ACTION = createAction(
  ACTION_TYPES.RESET_SHARE_WISH_LIST
);

const CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION = createAction(
  ACTION_TYPES.CLEAR_WISH_LIST_PRODUCT_PAGE
);

const FETCH_WISH_LIST_ITEMS_ACTION = createAction<IWishListProductRequest>(
  ACTION_TYPES.FETCH_WISH_LIST_ITEMS
);

const FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION = createAction<IWishListResponse>(
  ACTION_TYPES.FETCH_WISH_LIST_ITEMS_SUCCESS
);

const REFRESH_WISH_LIST_PLP_ACTION = createAction(
  ACTION_TYPES.REFRESH_WISH_LIST_PLP
);

const TOGGLE_WISH_LIST_PLP_PAGES_ACTION = createAction(
  ACTION_TYPES.TOGGLE_WISH_LIST_PLP_PAGES
);

const SET_WISH_LIST_PLP_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_WISH_LIST_PLP_LOADING
);

const SET_CURRENT_GIFT_LIST_ID_ACTION = createAction<string>(
  ACTION_TYPES.SET_CURRENT_GIFT_LIST_ID
);

const FETCH_SHARED_WISHLIST_DETAILS_ACTION = createAction<IFetchSharedWishList>(
  ACTION_TYPES.FETCH_SHARED_WISHLIST_DETAILS
);

export {
  GET_WISH_LIST_ACTION,
  GET_WISH_LIST_SUCCESS_ACTION,
  ADD_REMOVE_WISH_LIST_ACTION,
  ADD_REMOVE_WISH_LIST_SUCCESS_ACTION,
  DELETE_REMOVE_WISH_LIST_ACTION,
  DELETE_REMOVE_WISH_LIST_SUCCESS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION,
  WISH_LIST_LOADING_ACTION,
  RESET_WISH_LIST_ACTION,
  SHARE_WISH_LIST_ACTION,
  SHARE_WISH_LIST_SUCCESS_ACTION,
  RESET_SHARE_WISH_LIST_ACTION,
  FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION,
  CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION,
  FETCH_WISH_LIST_ITEMS_ACTION,
  FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION,
  REFRESH_WISH_LIST_PLP_ACTION,
  TOGGLE_WISH_LIST_PLP_PAGES_ACTION,
  SET_WISH_LIST_PLP_LOADING_ACTION,
  ADD_ITEM_TO_WISHLIST_ACTION,
  SET_CURRENT_GIFT_LIST_ID_ACTION,
  FETCH_SHARED_WISHLIST_DETAILS_ACTION,
};
