import { takeLatest } from 'redux-saga/effects';
import {
  GET_STORES_ACTION,
  GET_US_STORES_ACTION,
  SEARCH_STORE_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION,
  STORE_PICKUP_INVENTORY_REQUEST_ACTION,
} from '../../actions/storeLocator';
import {
  fetchStorePickUpInventory,
  fetchStorePickUpInventoryLatAndLong,
  getStores,
  getStoresByAddress,
  getUsStores,
} from '../workers/storeLocator';

export function* watchSaga() {
  yield takeLatest(GET_STORES_ACTION, getStores);

  yield takeLatest(GET_US_STORES_ACTION, getUsStores);

  yield takeLatest(SEARCH_STORE_ACTION, getStoresByAddress);

  yield takeLatest(
    STORE_PICKUP_INVENTORY_REQUEST_ACTION,
    fetchStorePickUpInventory
  );

  yield takeLatest(
    STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION,
    fetchStorePickUpInventoryLatAndLong
  );
}
