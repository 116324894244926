import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { IProductListFacetsEntry } from '../../../_foundation/interface/ProductList/IProductList';
import { REFRESH_SEO_ACTION } from '../../../components/Seo/redux/actions/seo';
import { FacetConstants } from '../../../components/Widgets/Facets/Facet/FacetConstants';
import {
  FETCH_BREADCRUMB_ACTION,
  RESET_BREADCRUMB_ACTION,
} from '../../../redux/actions/breadcrumb.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  RESET_PRODUCT_LIST_ACTION,
} from '../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../redux/selectors/breadcrumb.selector';
import { formatQueryParams } from '../../utils';

const useCategoryFacetParam = () => {
  const { CATEGORY_QUERY_KEY, BRAND_QUERY_KEY } = FacetConstants;

  const [categoryParams, setCategoryParams] = useQueryParam(
    CATEGORY_QUERY_KEY,
    StringParam
  );

  const [brandParam, setBrandParam] = useQueryParam(
    BRAND_QUERY_KEY,
    StringParam
  );

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const dispatch = useDispatch();

  const history = useHistory();

  const { mySite } = useSite();

  const setCategoryFacetParam = ({
    checked,
    currentValue,
  }: {
    checked: boolean;
    currentValue: string;
  }): void => {
    let categoryIdentifiers = [];

    if (categoryParams) {
      const queryParamIds: any = formatQueryParams('|').remove(categoryParams);

      categoryIdentifiers = queryParamIds;
    }

    if (checked) {
      categoryIdentifiers.push(currentValue);
    } else {
      categoryIdentifiers = categoryIdentifiers.filter(
        (categoryId: any) => categoryId !== currentValue
      );
    }

    if (categoryIdentifiers.length !== 0) {
      const categoryParam = formatQueryParams('|').add(categoryIdentifiers);

      dispatch(ADD_CATEGORY_IDS_ACTION(categoryIdentifiers));

      setCategoryParams(categoryParam, 'replaceIn');
    } else {
      setCategoryParams(undefined, 'replaceIn');

      setBrandParam(undefined, 'replaceIn');

      dispatch(ADD_CATEGORY_IDS_ACTION([]));

      dispatch(CURRENT_BRAND_IDENTIFIER_ACTION(''));

      dispatch(CURRENT_BRAND_INFO_ACTION({ brandName: '', seo: '' }));

      if (brandParam) {
        dispatch(
          FETCH_BREADCRUMB_ACTION({
            categoryId: brandParam,
            catalogId: mySite.catalogID,
            storeID: mySite.storeID,
          })
        );
      }
    }

    dispatch(RESET_BREADCRUMB_ACTION());
  };

  const removeActiveCategoryFacet = (
    activeCategoryFacet: IProductListFacetsEntry
  ): void => {
    dispatch(RESET_PRODUCT_LIST_ACTION({ preserveSelectedFacet: true }));

    if (breadCrumbTrailEntryView.length !== 0 && mySite) {
      const activeBreadcrumb = breadCrumbTrailEntryView.findIndex(
        ({ value }) => value === activeCategoryFacet.extendedData.uniqueId
      );

      const hasBrands = breadCrumbTrailEntryView[0].seo.href.includes('brand');

      if (hasBrands && breadCrumbTrailEntryView[0].label) {
        dispatch(
          CURRENT_BRAND_INFO_ACTION({
            brandName: breadCrumbTrailEntryView[0].label,
            seo: breadCrumbTrailEntryView[0].seo.href,
          })
        );

        dispatch(
          FETCH_BREADCRUMB_ACTION({
            catalogId: mySite.catalogID,
            categoryId: breadCrumbTrailEntryView[0].value,
            storeID: mySite.storeID,
          })
        );
      }

      const selectedCategoryId =
        breadCrumbTrailEntryView[activeBreadcrumb - 1]?.value;

      dispatch(
        FETCH_BREADCRUMB_ACTION({
          categoryId: selectedCategoryId,
          catalogId: mySite.catalogID,
          storeID: mySite.storeID,
        })
      );
    }

    setCategoryFacetParam({
      checked: false,
      currentValue: activeCategoryFacet.value,
    });
  };

  const removeBrandCategoryFacet = (): void => {
    dispatch(CURRENT_BRAND_IDENTIFIER_ACTION(''));

    dispatch(
      CURRENT_BRAND_INFO_ACTION({
        brandName: '',
        seo: '',
      })
    );

    dispatch(RESET_PRODUCT_LIST_ACTION({ preserveSelectedFacet: true }));

    dispatch(REFRESH_SEO_ACTION());

    if (breadCrumbTrailEntryView.length !== 0) {
      const currentCategoryPath =
        breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 1].seo.href;

      history.push({
        pathname: currentCategoryPath,
        search: history.location.search,
      });
    }
  };

  return {
    setCategoryFacetParam,
    removeActiveCategoryFacet,
    removeBrandCategoryFacet,
  };
};

export { useCategoryFacetParam };
