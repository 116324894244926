import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SUGGEST_TYPE_REQUEST_ACTION } from '../../../redux/actions/suggestion.actions';
import { suggestionSelector } from '../../../redux/selectors/suggestion.selector';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import {
  CategoryBrandsSuggestion,
  SuggestionEntry,
} from '../../../_foundation/interface/SearchSiteContent/ISearchSuggestionContent';
import { checkTruthy, formatSearchTerm, isValidRegx } from '../../utils';
import { SearchProductsContentConstant } from './SearchProductsContent.constant';
import debounce from 'lodash/debounce';

/**
 * @method useSuggestTypeContent Responsible for constructing the Suggestion list for a specific search term.
 */
const useSuggestTypeContent = ({ currentValue, showSuggestionMenu }: any) => {
  const { Categories, Brands } = SearchProductsContentConstant;

  const { suggestionView } = useSelector(suggestionSelector);

  const [categoriesBrandsList, setCategoriesBrandsList] = useState<
    Array<string>
  >([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [cancelSource, setCancelSource] = useState<CancelTokenSource>();

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const triggerCategorySeach =
    mySite && checkTruthy(mySite.triggerCategorySearchSuggestion);

  /**
   * @method generateListForSearch responsible for generating suggestion list.
   */
  const generateListForSearch = (
    suggestionEntry: SuggestionEntry[]
  ): CategoryBrandsSuggestion[] =>
    suggestionEntry
      ? suggestionEntry.map(({ name, seo, fullPath }) => {
          return { name: name, seo: seo, fullPath: fullPath };
        })
      : [];

  /**
   * @callback constructCategoriesBrandsFromSearchTerm
   */
  const constructCategoriesBrandsFromSearchTerm = useCallback((): void => {
    if (currentValue.length === 0) {
      setCategoriesBrandsList([]);
    }

    if (currentValue.length > 0 && suggestionView?.length > 0) {
      const categoriesAndBrandsSuggestionList: string[] = [];

      setLoading(true);

      if (currentValue.length >= 3 && suggestionView?.length >= 1) {
        const categorySuggestion = suggestionView?.find(
          ({ identifier }) => identifier.toLowerCase() === Categories
        );

        let generatedCategories;
        if (categorySuggestion && categorySuggestion?.entry) {
          generatedCategories = generateListForSearch(categorySuggestion.entry);
        }
        if (isValidRegx(currentValue)) {
          const regex = new RegExp(currentValue, 'ig');
          const matchedCategories = generatedCategories?.filter(
            (e: CategoryBrandsSuggestion) => e.name.match(regex)
          );
          if (matchedCategories && matchedCategories.length > 0) {
            matchedCategories.forEach((suggestion: any) => {
              categoriesAndBrandsSuggestionList.push(suggestion);
            });
          }

          const brandSuggestion = suggestionView?.find(({ identifier }) =>
            identifier.toLowerCase().startsWith(Brands)
          );

          let generatedBrands;

          if (brandSuggestion && brandSuggestion?.entry) {
            generatedBrands = generateListForSearch(brandSuggestion.entry);
          }

          const matchedBrands = generatedBrands?.filter(
            (e: CategoryBrandsSuggestion) => e.name.match(regex)
          );

          if (matchedBrands && matchedBrands.length > 0) {
            matchedBrands.forEach((suggestion: any) => {
              categoriesAndBrandsSuggestionList.push(suggestion);
            });
          }

          if (categoriesAndBrandsSuggestionList.length > 0) {
            setCategoriesBrandsList(categoriesAndBrandsSuggestionList);
          } else {
            setCategoriesBrandsList([]);
          }

          setLoading(false);
        }
      }
    }
  }, [Brands, Categories, currentValue, suggestionView]);

  useEffect(() => {
    constructCategoriesBrandsFromSearchTerm();
  }, [constructCategoriesBrandsFromSearchTerm, currentValue, suggestionView]);

  /**
   * @callback fetchSuggestionTypes Fetches the suggestion list for the specific keyword.
   */
  const fetchSuggestionTypes = useMemo(
    () =>
      debounce((currentValue) => {
        dispatch(
          FETCH_SUGGEST_TYPE_REQUEST_ACTION({
            term: formatSearchTerm(currentValue),
            storeID: mySite.storeID,
            cancelSource,
          })
        );
      }, +mySite?.searchDebounceTime),
    [cancelSource, mySite, dispatch]
  );

  useEffect(() => {
    if (mySite && currentValue.length >= 3 && triggerCategorySeach) {
      fetchSuggestionTypes(currentValue);
    }
  }, [currentValue, fetchSuggestionTypes, mySite, triggerCategorySeach]);

  useEffect(() => {
    /**
     * Initialize the cancel token to cancel the suggestion calls
     * when the user hits enter or clicks an item from suggestion list.
     */
    if (showSuggestionMenu) {
      const cancelToken = axios.CancelToken;

      const cancelSource = cancelToken.source();

      setCancelSource(cancelSource);
    }
  }, [showSuggestionMenu]);

  return { categoriesBrandsList, loading, cancelSource };
};

export { useSuggestTypeContent };
