/**
 * Field parsing that determines how the input field gets stored and sent to the backend.
 */
const FieldParsing = {
  /** Zip code parsing.*/
  ZIP_CODE_US: (value: string) => value.replace(/-/g, '').substring(0, 9),

  // Zip code parsing to limit characters to 7.
  ZIP_CODE_CANADA: (value: string) => {
    if (!value) return value;

    return value.substring(0, 7);
  },
  
  // Zip code parsing to limit characters to 9.
  ZIP_CODE_OUTSIDE_US_CANADA: (value: string) => {
    if (!value) return value;

    return value.substring(0, 10);
  },

  /** Credit/Debit card number parsing.*/
  CREDIT_CARD: (value: string) => value.replace(/\D/g, ''),

  /** Phone Number parsing for US.*/
  PHONE_NUMBER_US: (value: string) => value.replace(/-/g, '').substring(0, 10),

  /** Phone Number parsing for outside US.*/
  PHONE_NUMBER_OUTSIDE_US: (value: string) => value.substring(0, 20),

  /** Name parsing to limit to 35 characters.*/
  NAME: (value: string) => value.substring(0, 35),

  /** City parsing to limit to 30 characters.*/
  CITY: (value: string) => value.substring(0, 30),

  /** Business Name parsing to limit to 40 characters.*/
  BUSINESS_NAME: (value: string) => value.substring(0, 40),

  /** Email parsing to limit to 50 characters.*/
  EMAIL: (value: string) => value.substring(0, 50),

  /** Password parsing to limit to 30 characters.*/
  PASSWORD: (value: string) => value.substring(0, 30),

  /** Credit/Debit card parsing.*/
  CREDIT_DEBIT_CARD: (value: string) =>
    value.replace(/\D/g, '').substring(0, 16),
};

export { FieldParsing };
