import React, { useRef } from 'react';
import { HEADER_MENU_CLICK_TEALIUM } from '../../../../../../constants/Tealium';
import { useMenuNavigator } from '../../../../../../utils/hooks/menu-navigator/MenuNavigatorHooks';
import { sendTealiumData } from '../../../../../../utils/utils';
import { useNteNestedMenu } from '../../../../../Menu/NteMenuList/hooks/NteNestedMenuHooks';
import { NteMenuList } from '../../../../../Menu/NteMenuList/NteMenuList';
import { useNtePopper } from '../../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../../NavMenuItem/NavMenuItem';

/**
 * @interface RenderNestedListProps
 */
interface RenderNestedListProps {
  /**
   * @prop menuItem contains the list
   * of menu Items that needs to be
   * rendered inside the menu.
   */
  menuItem: any;
}

/**
 * @component RenderNestedList renders the Nested menu list.
 */
const RenderNestedList: React.FC<RenderNestedListProps> = ({ menuItem }) => {
  const menuRef = useRef(null);

  const { RenderPopper, ntePopperClickHandler, showPopper } = useNtePopper({
    id: `${menuItem.menuName}-menu`,
    ariaLabelledById: `${menuItem.menuName}-menu-list`,
    className: 'no-scroll',
  });

  const {
    menuState: { active, isRootMenu, items },
    clickEventsHandler,
  } = useNteNestedMenu({ showPopper, initMenuData: menuItem.children });

  const { navigateNestedMenu } = useMenuNavigator();

  /**
   * @method menuItemClickHandler is responsible for handling
   * the click events when the menu item is clicked.
   */
  const menuItemClickHandler = (selectedEvent?: any): void => {
    if (!selectedEvent?.backAction) {
      sendTealiumData({
        tealium_event: HEADER_MENU_CLICK_TEALIUM,
        nav_header_element: selectedEvent?.currentItemIdentifier,
      });
    }

    if (!selectedEvent.isNestedMenu && !selectedEvent?.backAction) {
      const currentPath = selectedEvent?.currentItem.toLowerCase();

      navigateNestedMenu({
        currentPath,
        currentItemIdentifier: selectedEvent?.currentItemIdentifier,
        ntePopperClickHandler,
        seoPath: selectedEvent.currentPath,
      });

      return;
    }

    // Trigger clickEventsHandler for nested menus.
    clickEventsHandler(selectedEvent);
  };

  return (
    <>
      <NavMenuItem
        menuAnchor={menuRef}
        menuTitle={menuItem.menuName}
        ariaExpanded={showPopper}
        onClick={ntePopperClickHandler}
        titleVariant='h2'
        disablePopperAction={showPopper}
        ariaControls={menuItem.ariaControls}
      />

      {showPopper && (
        <RenderPopper
          anchor={menuRef}
          showPopper={showPopper}
          ntePopperCloseHandler={ntePopperClickHandler}>
          <NteMenuList
            menuListCloseHandler={ntePopperClickHandler}
            menuTitle={active ? active : menuItem.menuName}
            nteMenuClickHandler={menuItemClickHandler}
            isRootMenu={isRootMenu}
            menuItems={items}
            id={`${menuItem.menuName}-menu-list`}
          />
        </RenderPopper>
      )}
    </>
  );
};

export { RenderNestedList };
