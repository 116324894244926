import React from 'react';
import { useHistory } from 'react-router';
import { MarkerOptions } from './IMarkerOptions';

const Marker: React.FC<MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  const history = useHistory();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        marker.unbindAll();
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      if (options.url) {
        marker.addListener('click', () => {
          if (options.url) {
            history.push(options.url);
          }
        });
      }
    }
  }, [marker, options, history]);

  return null;
};

export { Marker };
