import {
  CHECKOUT,
  CHECKOUT_DELIVERY,
  CHECKOUT_PAYMENT,
  CHECKOUT_REVIEW,
} from '../../../constants/routes';

const CheckoutHooksConstants = {
  CHECKOUT_FLOWS: [CHECKOUT, CHECKOUT_DELIVERY, CHECKOUT_PAYMENT, CHECKOUT_REVIEW],
};

export { CheckoutHooksConstants };
