import { createReducer } from '@reduxjs/toolkit';
import IEspotResponse from '../../_foundation/interface/Responses/IEspotResponse';
import {
  RESET_ESPOT_ACTION,
  UPDATE_ESPOT_ACTION,
} from '../actions/espot.actions';

/**
 * @interface IEspotDataState
 */
export interface IEspotDataState {
  [key: string]: IEspotResponse;
}

const initEspotState: IEspotDataState = {}

const espotDataReducer = createReducer(initEspotState, (builder) => {
  /**
   * @action UPDATE_ESPOT_ACTION
   * Updates the espot data.
   */
  builder.addCase(
    UPDATE_ESPOT_ACTION,
    (state: IEspotDataState, action: any) => {
      if (action.payload) {
        return { ...action.payload}
      }
    }
  );

  /**
   * @action RESET_ESPOT_ACTION
   * Reset the espot Data.
   */
  builder.addCase(RESET_ESPOT_ACTION, (state: IEspotDataState, action: any) => {
    return { ...initEspotState };
  });
});

export { espotDataReducer };
