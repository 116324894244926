const PickupPersonServiceConstants = {
  GET_PICKUP_PERSON_URL: (storeID: string, orderID: string) =>
    `/wcs/resources/store/${storeID}/bopis/pickup-person?orderId=${orderID}`,
  ADD_PICKUP_PERSON_URL: (storeID: string) =>
    `/wcs/resources/store/${storeID}/bopis/pickup-person`,
  DELETE_PICKUP_PERSON_URL: (storeID: string) =>
    `/wcs/resources/store/${storeID}/bopis/delete-pickup-person`,
};

export { PickupPersonServiceConstants };
