const StoreLocatorConstants = {
  MY_STORE_COOKIE: 'MyStore',
  FIND_A_STORE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.FIND_A_STORE',
  OPENS_AT: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.OPENS_AT',
  OPEN_UNTIL: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.OPEN_UNTIL',
  STORE_FINDER_REQUEST_LENGTH: 2,
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  STORE_WEB_STATUS: 'STOREWEBSTATUS',
  LOCAL_DELIVERY: 'LOCALDELIVERY',
  TEMPORARILY_CLOSED: 'Temporarily closed',
  SAME_DAY_PICKUP_NOT_AVAILABLE: 'Same Day Pickup Not Available',
  PERMANENTLY_CLOSED: 'Permanently Closed',
  CLOSED: 'closed',
  COMING_SOON: 'Coming Soon',
  SAME_DAY_DELIVERY: 'same day delivery',
  STORE_PICKUP: 'store pickup',
  FIND_A_STORE_FALLBACK: 'Find a Store',
  OPENS_AT_FALLBACK: 'Opens at',
  OPEN_UNTIL_FALLBACK: 'Open until',
};

export { StoreLocatorConstants };
