import { InputBaseProps } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { TextInput } from '../../../components/Forms/TextInput/TextInput';
import './SearchBar.scss';

interface Props extends InputBaseProps {
  /**
   * placeHolder for searchBar
   */
  placeHolder?: any;

  /**
   * label for searchBar
   */
  label: string;

  /**
   * Responsible for capturing the currentvalue in searchBar
   */
  setCurrentValue?: (currentValue: string) => void;

  /**
   * Responsible to capture the value in searchBar
   */
  value?: string;

  autoComplete?: string;
}

/**
 * This Component is responsible for rendering the SearchBar without additional icons.
 */
const SearchBar: React.FC<Props> = ({
  placeHolder,
  label,
  onChange,
  setCurrentValue,
  value,
  autoComplete,
  ...inputBaseProps
}) => {
  /**
   * state used to determine when to show the closeIcon
   */
  const [closeIcon, setCloseIcon] = useState(0);

  /**
   * searchText used to determine the placeholder for searchBar
   */
  let searchText = placeHolder;

  /**
   * handleSearchEvent used to determine when to show to closeIcon
   */
  const handleSearchEvent = (e: any) => {
    if (onChange) {
      onChange(e);
    }

    if (e.target.value.length > 0) {
      setCloseIcon(1);
    }
    if (e.target.value.length === 0) {
      setCloseIcon(0);
    }
  };

  /**
   * handleCloseIcon responsible for clearing the text in searchBar
   */
  const handleCloseIcon = () => {
    if (setCurrentValue) {
      setCurrentValue('');
      setCloseIcon(0);
    }
  };

  /**
   * closeKeyboard is used to close mobile device keyboard on enter
   */
  const closeKeyboard = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  useEffect(() => {
    if (isSafari) {
      document.body.classList.add('safari');
    }
  }, []);

  return (
    <div className='search_bar-wrapper'>
      <TextInput
        value={value}
        placeholder={searchText}
        label={label}
        className='search-bar'
        name={label}
        type='search'
        enterKeyHint='search'
        id='search'
        autoCapitalize='words'
        aria-autocomplete='list'
        aria-controls='results'
        aria-haspopup='listbox'
        aria-expanded='false'
        aria-describedby='search-bar-instructions'
        fullWidth={true}
        autoFocus={true}
        onChange={(e) => handleSearchEvent(e)}
        onKeyPress={(e) => closeKeyboard(e)}
        autoComplete={autoComplete}
      />
      <InputAdornment position='end' className='search-icons-container'>
        <div className='searchIcon_search'>
          {closeIcon ? (
            <CloseIcon className='close-icon' onClick={handleCloseIcon} />
          ) : (
            <SearchIcon className='searchIconGray' fontSize='large' />
          )}
        </div>
      </InputAdornment>
      <div id='search-bar-instructions' style={{ display: 'none' }}>
        Begin typing to search, use arrow keys to navigate, Enter to select
      </div>
    </div>
  );
};

export { SearchBar };
