const TealiumUTagConstants = {
  SEARCH_RESULTS_PAGE_TYPE: 'search results',
  SEARCH_RESULTS_PAGE_NAME_FOUND: 'search results | found',
  SEARCH_RESULTS_PAGE_NAME_NOT_FOUND: 'search results | not found',
  LOGGED_IN: 'logged in',
  LOGGED_OUT: 'logged out',
  PERSONAL_ACCOUNT: 'personal',
  CART_PAGE: 'cart',
};

export { TealiumUTagConstants };
