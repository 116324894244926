import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { formatTextWithDashes } from '../../../utils/utils';

/**
 * @interface INteAccordion
 */
interface INteAccordion {
  /**
   * Title Of Accordion.
   */
  accordionTitle?: string;

  /**
   * Children to be displayed after the accordion is expanded.
   */
  children: JSX.Element;

  /**
   * Sub title text for the accordion component.
   */
  accordionSubTitle?: string;

  /**
   * Description for the accordion component.
   */
  accordionDescription?: string;

  /**
   * Set default expanded state.
   */
  defaultExpanded?: boolean;

  /**
   * Set custom className.
   */
  className?: string;

  expanded?: boolean;

  onClick?: () => void;

  onChange?: (e: any, element: boolean) => void;

  /**
   * Set element variant.
   */
  variant?: Variant | 'inherit';
}

/**
 * @component NteAccordion renders the accordion component.
 */
const NteAccordion: React.FC<INteAccordion> = ({
  accordionTitle,
  children,
  accordionSubTitle,
  defaultExpanded,
  className = '',
  expanded,
  onClick,
  variant = 'h3',
  onChange,
  accordionDescription,
}) => (
  <Accordion
    defaultExpanded={defaultExpanded}
    expanded={expanded}
    className={`nte_accordion ${className}`}
    onChange={onChange}>
    <AccordionSummary
      aria-controls={'accordion-' + formatTextWithDashes(accordionTitle)}
      id={'accordion-' + formatTextWithDashes(accordionTitle)}
      {...(onClick && { onClick })}
      expandIcon={
        <ExpandMoreIcon aria-controls={formatTextWithDashes(accordionTitle)} />
      }>
      {accordionTitle && (
        <Typography variant={variant}>{accordionTitle}</Typography>
      )}
      {accordionSubTitle && (
        <Typography variant='body1'>{accordionSubTitle}</Typography>
      )}
      {/* To add styling so that the description below the title (not next to it) */}
      {accordionDescription && (
        <Typography variant='body2'>{accordionDescription}</Typography>
      )}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export { NteAccordion };
