const PRICE_FACET_KEY = 'PLP_PAGE';

const PriceFacetConstants = {
  PRICE_FACET_TITLE: `${PRICE_FACET_KEY}.PRICE_FACET_TITLE`,
  $_MIN: `${PRICE_FACET_KEY}.$_MIN`,
  $_MAX: `${PRICE_FACET_KEY}.$_MAX`,
  TO: `${PRICE_FACET_KEY}.TO`,
  GO: `${PRICE_FACET_KEY}.GO`,
  PRICE_IDENTIFIER: 'OfferPrice_usd',
  SOLD_IN_STORES: 'sold in stores',
};

export { PriceFacetConstants };
