import { Grid, Hidden, Toolbar } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { HOME } from '../../../../constants/routes';
import CheckoutHeader from '../../../Checkout/CheckoutHeader/CheckoutHeader';
import { useCheckout } from '../../../Checkout/hooks/CheckoutHooks';
import Logo from '../../Logo';
import LogoText from '../../LogoText';
import { NtePrimarySearchBar } from '../../SearchBar/CustomSearchBar/NtePrimarySearchBar';
import { NavBarHamburgerMenu } from '../PrimaryMenuItems/NavBarMenuItem/NavBarHamburgerMenu/NavBarHamburgerMenu';
import { NavBarMenu } from '../PrimaryMenuItems/NavBarMenuItem/NavBarMenu';

/**
 * @component PrimaryNavBar renders the Primary NavBar
 */
const PrimaryNavBar: React.FC = () => {
  const { isCheckout } = useCheckout();

  return (
    <Toolbar className={`primary nav_bar ${isCheckout && 'checkout'}`}>
      <Link
        aria-label='home link'
        rel='nofollow'
        to={HOME}
        className='no-link-effect home-link'>
        <Logo
          className='logo'
          aria-labelledby='nteLogoIconTitle nteLogoIconDesc'
          role={'img'}
        />

        <Hidden smDown>
          <LogoText
            className='logo_text'
            aria-labelledby='nteLogoTextTitle nteLogoTextDesc'
          />
        </Hidden>
      </Link>

      {isCheckout ? (
        <CheckoutHeader />
      ) : (
        <>
          <nav aria-label='primary'>
            <Hidden mdUp>
              <div className='hamburger-container'>
                <NavBarHamburgerMenu />
              </div>
            </Hidden>

            <Grid
              className='top-level-menu-list'
              component='ul'
              container
              alignItems='center'
              justifyContent='flex-start'>
              <Hidden smDown>
                <NavBarMenu />
              </Hidden>
            </Grid>
          </nav>

          <NtePrimarySearchBar />
        </>
      )}
    </Toolbar>
  );
};

export { PrimaryNavBar };
