import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { PageHeader } from '../../Widgets/PageHeader/PageHeader';
import {
  manipulateAccordion,
  manipulateTabs,
  manipulateModals,
  manipulateVideos,
} from '../../../custom/template-logic/cmc-content';
import { useNteAlert } from '../../NteAlert/hooks/NteAlertHooks';
import { useRedirectToSection } from '../../../utils/hooks/espot/Redirect';

/**
 * @interface ICmcTemplatesPage
 */
export interface ICmcTemplatesPage {
  loading: boolean;
}

/**
 * @component renders the cmc templates page.
 */
const CmcTemplatesPage: React.FC<ICmcTemplatesPage> = ({ loading }) => {
  useRedirectToSection(loading);

  useEffect(() => {
    if (!loading) {
      manipulateAccordion();
      manipulateTabs();
      manipulateModals();
      manipulateVideos();
    }
  }, [loading]);

  const { setAlert } = useNteAlert();

  const regAlert = () => {
    setAlert({
      message:
        "this is the message with a longer message that actually wraps a few lines, because... only by wrapping do we get the true measure of this app's Greatness",
      severity: 'error',
    });
  };

  return (
    <div className='page cmc_templates_page cmc-content'>
      <PageHeader pageTitle='CMC Templates' />
      <div className='page-row page-title'>
        <div className='content-wrapper'>
          <ol
            vocab='https://schema.org/'
            typeof='BreadcrumbList'
            className='bread-crumbs-container bread_crumbs'>
            <li
              property='itemListElement'
              typeof='ListItem'
              className='bread-crumbs-link text-dark'>
              <a
                id='/'
                className='link-effect bread-crumbs-link text-dark'
                href='/'
                property='name'>
                Northern Tool
              </a>
              <meta property='position' content='1' />
            </li>
            <li
              property='itemListElement'
              typeof='ListItem'
              className='bread-crumbs-link text-dark next-to-last'>
              <span className='MuiTypography-root bread-crumbs-link separator MuiTypography-body2'>
                &nbsp;&nbsp;/&nbsp;&nbsp;
              </span>
              <a
                id='/customer-help'
                className='link-effect bread-crumbs-link text-dark'
                href='/customer-help'
                property='name'>
                Help
              </a>
              <meta property='position' content='2' />
            </li>
            <li
              property='itemListElement'
              typeof='ListItem'
              className='bread-crumbs-link text-dark'>
              <span className='MuiTypography-root bread-crumbs-link separator MuiTypography-body2'>
                &nbsp;&nbsp;/&nbsp;&nbsp;
              </span>
              <span
                className='MuiTypography-root bread-crumbs-link disable-link MuiTypography-body2'
                property='name'>
                Tax Information
              </span>
              <meta property='position' content='3' />
            </li>
          </ol>
          <h1 className='MuiTypography-root page-title-header marginless MuiTypography-h1'>
            Tax Information
          </h1>
        </div>
      </div>
      <div className='page-row'>
        <div className='nte-card' id='shopping'>
          <div className='accordion open'>
            <button className='header'>
              <h3>Does sales tax apply to my purchase?</h3>
            </button>
            <div className='content'>
              <h4>U.S. Purchases</h4>
              <p className='section-row'>
                Northern currently collects sales tax on purchases shipped to
                all states and territories except:
                <strong>
                  Alaska, American Samoa, Delaware, Guam, Montana, New
                  Hampshire, North Marianas Islands, Oregon,
                </strong>
                and <strong>Virgin Islands</strong>.
              </p>
              <h4>Canadian Purchases</h4>
              <p>
                Northern currently collects tax on purchases shipped to Canada.
              </p>
            </div>
          </div>
          <div className='accordion open'>
            <button className='header'>
              <h3>Am I responsible for customs duties and taxes?</h3>
            </button>
            <div className='content'>
              <p>
                Import duties and/or taxes for orders shipped to non-U.S.
                destinations may be imposed by your local government on
                shipments from outside your country. These charges are separate
                from Northern Tool's charges and are beyond our control or
                ability to predict. These charges, if any, are made payable to
                your government.
              </p>
              <p>
                You are responsible for paying any taxes, duties and/or customs
                fees charged by your government. Please check with your local
                post office for more information.
              </p>
            </div>
          </div>
          <div className='accordion open'>
            <button className='header'>
              <h3>How do I get tax exemption on my order?</h3>
            </button>
            <div className='content'>
              <p>
                If you qualify, there are three ways you can make tax-exempt
                purchases at Northern Tool. First, determine whether you will be
                placing a tax-exempt order in a physical store, or via the
                phone/web.
              </p>
              <p>
                <strong>Important:</strong> Tax exemption status for store
                purchases is not transferrable to purchases made by phone or on
                the website. If want to make tax-exempt purchases both in stores
                and online, you will need follow both of the processes outlined
                below.
              </p>
              <ul className='nte-ul-list'>
                <li>
                  <p>
                    <strong>
                      In person: visit a Northern Tool retail store.
                    </strong>
                  </p>
                  <p>
                    Visit your local Northern Tool + Equipment store and our
                    retail associates will assist you in setting up a tax-exempt
                    account. The stores have an electronic portal that enables
                    you to fill out the tax exemption forms on-site.
                    Alternatively, you can print and fill out{' '}
                    <a href='#tax-forms'>the required forms</a> provided below.
                    <a href='/store-locator'>Find a store near you</a>.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Phone or web: in advance or by reimbursement.
                    </strong>
                  </p>

                  <div className='nte-card nte-border-card field-margin'>
                    <p>
                      <strong style={{ textDecoration: 'underline' }}>
                        Option #1: Set up tax-exempt account in advance.
                        (Recommended)
                      </strong>
                    </p>
                    <p>
                      <strong>Call Sales Department at 1-800-221-0516.</strong>
                      <br />
                      The Sales Department is available 24 hours, 7 days a week.
                      A representative will look up or assign you a customer
                      number. Retain this number for the next step.
                    </p>
                    <p>
                      <strong>Email Us Your Customer Number.</strong> <br />
                      Send an email with your customer number to
                      <a href='mailto:taxdept@northerntool.com'>
                        taxdept@northerntool.com
                      </a>
                      , and we will email you a link to fill out your exemption
                      certificates electronically.
                    </p>
                    <p>
                      Alternatively, you can <a href='#tax-forms'>download</a>,
                      print, and fax/mail/email the completed forms to us.
                    </p>
                    <ul className='nte-ul-list'>
                      <li>
                        <strong>Fax:</strong>952-808-6682
                      </li>
                      <li>
                        <strong>Mail:</strong>Northern Tool + Equipment, Attn:
                        Tax Department, P.O. Box 1219, Burnsville, MN 55337
                      </li>
                      <li>
                        <strong>Email:</strong>
                        <a href='mailto:taxdept@northerntool.com'>
                          taxdept@northerntool.com
                        </a>
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      <strong>Process the Order.</strong> <br />
                      Your account should update in one business day. After
                      allowing for processing, call the sales department to
                      place your order. If you would like to place your order
                      online, see the next step.
                    </p>
                    <p>
                      <strong>Place Tax-Exempt Orders Online.</strong> <br />
                      <a href='/my-account/create-account'>Create an account</a>
                      or <a href='/sign-in'>sign in</a> as the business account
                      that will qualify for tax exemption.
                    </p>
                    <p>
                      <strong>Input Customer Number on "My Account".</strong>
                      <br />
                      Once you are logged in, on the
                      <a href='/my-account'>"My Account"</a> page, scroll down
                      to find a "Customer Number" field. Type in your customer
                      number and click submit.
                    </p>
                    <p>
                      <strong>Important:</strong> Please allow one business day
                      for our system to recognize your tax exemption status.
                      Future online orders placed with this approved tax-exempt
                      account will not be charged tax.
                    </p>
                  </div>

                  <div className='nte-card'>
                    <p>
                      <strong>
                        Option #2: Make a purchase on NorthernTool.com and tax
                        will be reimbursed afterwards.
                      </strong>
                    </p>
                    <p>
                      <strong>Create an Account.</strong> <br />
                      <a href='/my-account/create-account'>Create an account</a>
                      or <a href='/sign-in'>sign in</a> as the business account
                      that will qualify for tax exemption.
                    </p>
                    <p>
                      <strong>Place Order.</strong> <br />
                      Place order normally. Allow one day for the order to
                      process through our system;
                      <a href='/contact-us'>contact Customer Care</a> the
                      following day. A representative will look up your customer
                      number. Retain this number for the next step.
                    </p>
                    <p>
                      <strong>Email us your customer number.</strong> <br />
                      Send an email with your customer number to
                      <a href='mailto:taxdept@northerntool.com'>
                        taxdept@northerntool.com
                      </a>
                      , and we will email you a link to fill out your exemption
                      certificates electronically.
                    </p>
                    <p>
                      Alternatively, you can <a href='#tax-forms'>download</a>,
                      print, and fax/mail/email the completed forms to us.
                    </p>
                    <ul className='nte-ul-list'>
                      <li>
                        <strong>Fax:</strong>952-808-6682
                      </li>
                      <li>
                        <strong>Mail:</strong>Northern Tool + Equipment, Attn:
                        Tax Department, P.O. Box 1219, Burnsville, MN 55337
                      </li>
                      <li>
                        <strong>Email:</strong>
                        <a href='mailto:taxdept@northerntool.com'>
                          taxdept@northerntool.com
                        </a>
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      <strong>Process the Reimbursement.</strong> <br />
                      Once the tax exemption certificates are approved, we will
                      notify you that you are eligible for tax exemption. You
                      will need to contact Customer Care for final
                      reimbursement.
                    </p>
                    <p>
                      <strong>Important:</strong> Please allow one business day
                      for our system to recognize your tax exemption status.
                      Future online orders placed with this approved tax-exempt
                      account will not be charged tax.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='accordion open'>
            <div className='header'>
              <h3>What are sales tax holidays?</h3>
            </div>
            <div className='content'>
              <p>
                At certain times of the year, some states offer a sales tax
                holiday that makes qualifying items exempt from sales tax during
                the holiday period. Common types of sales tax holidays include
                Energy Star, storm or hurricane preparedness and back-to-school.
                Various items such as generators, inverters, flashlights,
                tie-down kits and tarps may be exempt during this period.
              </p>
            </div>
          </div>
          <div className='accordion open'>
            <div className='header'>
              <h3>Additional tax information</h3>
            </div>
            <div className='content'>
              <p>
                <strong>Use Tax:</strong>Notice for Use Tax in States Where
                Northern Tool Does Not Collect Sales Tax: This notice is for
                customers who take delivery of merchandise in states where sales
                tax is not charged.
              </p>
              <ul className='nte-ul-list'>
                <li>
                  Purchases are subject to use tax in certain states unless
                  exempt from taxation in those states.
                </li>
                <li>
                  The purchase is not exempt merely because it is made over the
                  Internet or by Catalog.
                </li>
                <li>
                  Some states will require customers to report their purchases
                  that were not taxed and pay use tax on those purchases.
                  Customers should check their state's reporting requirements
                  for tax forms to be used for this purpose.
                </li>
              </ul>
              <p>
                <strong>Purchases Shipped to Kentucky:</strong>
              </p>
              <ul>
                <li>
                  <p>
                    The Commonwealth of Kentucky requires Kentucky purchasers to
                    report all purchases of tangible personal property that are
                    not taxed by the retailer and pay use tax on those purchases
                    unless exempt under Kentucky law. The tax may be reported
                    and paid on the Kentucky individual income tax return or by
                    filing a consumer use tax return with the Kentucky
                    Department of Revenue. These forms and corresponding
                    instructions may be found on the Kentucky Department of
                    Revenue's website.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className='accordion open'>
            <div className='header'>
              <h3 id='tax-forms'>Where can I find tax forms?</h3>
            </div>
            <div className='content'>
              <h4>United States Tax Forms</h4>
              <div className='grid section-row'>
                <div className='nte-tab-select g-col-12 g-col-lg-3'>
                  <select className='tabs'>
                    <option value='' disabled selected hidden>
                      Choose a State...
                    </option>
                    <option data-target-id='stateforms-AL' value='AL'>
                      Alabama
                    </option>
                    <option data-target-id='stateforms-AZ' value='AZ'>
                      Arizona
                    </option>
                    <option data-target-id='stateforms-AR' value='AR'>
                      Arkansas
                    </option>
                    <option data-target-id='stateforms-CA' value='CA'>
                      California
                    </option>
                    <option data-target-id='stateforms-CO' value='CO'>
                      Colorado
                    </option>
                    <option data-target-id='stateforms-CT' value='CT'>
                      Connecticut
                    </option>
                    <option data-target-id='stateforms-DC' value='DC'>
                      District of Columbia
                    </option>
                    <option data-target-id='stateforms-FL' value='FL'>
                      Florida
                    </option>
                    <option data-target-id='stateforms-GA' value='GA'>
                      Georgia
                    </option>
                    <option data-target-id='stateforms-HI' value='HI'>
                      Hawaii
                    </option>
                    <option data-target-id='stateforms-ID' value='ID'>
                      Idaho
                    </option>
                    <option data-target-id='stateforms-IL' value='IL'>
                      Illinois
                    </option>
                    <option data-target-id='stateforms-IN' value='IN'>
                      Indiana
                    </option>
                    <option data-target-id='stateforms-IA' value='IA'>
                      Iowa
                    </option>
                    <option data-target-id='stateforms-KS' value='KS'>
                      Kansas
                    </option>
                    <option data-target-id='stateforms-KY' value='KY'>
                      Kentucky
                    </option>
                    <option data-target-id='stateforms-LA' value='LA'>
                      Louisiana
                    </option>
                    <option data-target-id='stateforms-MD' value='MD'>
                      Maryland
                    </option>
                    <option data-target-id='stateforms-ME' value='ME'>
                      Maine
                    </option>
                    <option data-target-id='stateforms-MA' value='MA'>
                      Massachusetts
                    </option>
                    <option data-target-id='stateforms-MI' value='MI'>
                      Michigan
                    </option>
                    <option data-target-id='stateforms-MN' value='MN'>
                      Minnesota
                    </option>
                    <option data-target-id='stateforms-MS' value='MS'>
                      Mississippi
                    </option>
                    <option data-target-id='stateforms-MO' value='MO'>
                      Missouri
                    </option>
                    <option data-target-id='stateforms-NE' value='NE'>
                      Nebraska
                    </option>
                    <option data-target-id='stateforms-NV' value='NV'>
                      Nevada
                    </option>
                    <option data-target-id='stateforms-NJ' value='NJ'>
                      New Jersey
                    </option>
                    <option data-target-id='stateforms-NM' value='NM'>
                      New Mexico
                    </option>
                    <option data-target-id='stateforms-NY' value='NY'>
                      New York
                    </option>
                    <option data-target-id='stateforms-NC' value='NC'>
                      North Carolina
                    </option>
                    <option data-target-id='stateforms-ND' value='ND'>
                      North Dakota
                    </option>
                    <option data-target-id='stateforms-OK' value='OK'>
                      Oklahoma
                    </option>
                    <option data-target-id='stateforms-OH' value='OH'>
                      Ohio
                    </option>
                    <option data-target-id='stateforms-PA' value='PA'>
                      Pennsylvania
                    </option>
                    <option data-target-id='stateforms-PR' value='PR'>
                      Puerto Rico
                    </option>
                    <option data-target-id='stateforms-RI' value='RI'>
                      Rhode Island
                    </option>
                    <option data-target-id='stateforms-SC' value='SC'>
                      South Carolina
                    </option>
                    <option data-target-id='stateforms-SD' value='SD'>
                      South Dakota
                    </option>
                    <option data-target-id='stateforms-TN' value='TN'>
                      Tennessee
                    </option>
                    <option data-target-id='stateforms-TX' value='TX'>
                      Texas
                    </option>
                    <option data-target-id='stateforms-UT' value='UT'>
                      Utah
                    </option>
                    <option data-target-id='stateforms-VT' value='VT'>
                      Vermont
                    </option>
                    <option data-target-id='stateforms-VA' value='VA'>
                      Virginia
                    </option>
                    <option data-target-id='stateforms-WA' value='WA'>
                      Washington
                    </option>
                    <option data-target-id='stateforms-WV' value='WV'>
                      West Virginia
                    </option>
                    <option data-target-id='stateforms-WI' value='WI'>
                      Wisconsin
                    </option>
                    <option data-target-id='stateforms-WY' value='WY'>
                      Wyoming
                    </option>
                  </select>
                </div>
                <div id='state-forms' className='g-col-12 g-col-lg-9'>
                  <div className='tab-content' id='stateforms-AL'>
                    <h4>Alabama State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/alabama/al-tax-rules.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          AL - General Sales Tax Rules
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-AZ'>
                    <h4>Arizona State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/arizona/AZ-form-5000-gov&amp;mfg.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          AZ - Transaction Privilege Tax Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/arizona/AZ-5000A -resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          AZ - Arizona Resale Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-AR'>
                    <h4>Arkansas State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/arkansas/AR-SST.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          AR - Certificate of Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-CA'>
                    <h4>California State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-BOE-230-resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - General Resale Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-CDTFA-230M-Manufacturing.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - Partial Exemption Certificate for Manufacturing,
                          Research and Development Equipment
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-BOE-146-RES-Native-American.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - Exemption Certificate and Statement of Delivery
                          in Indian Country
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-BOE-146TSG-Tribal-Gov.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - Exemption Certificate – Property Used in Tribal
                          Self-Governance and Statement of Delivery
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-CDTFA-230-G-1-Common-Carrier.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - California Sales Tax Exemption Certifcate
                          Supporting Bill of Lading
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/CA-US-Diplomat-Exemption-Affidavit.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - Foreign Diplomat Exemption Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/california/Border-States-Resale-Cetificate-01-909.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CA - Border States Uniform Sale for Resale Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-CO'>
                    <h4>Colorado State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/colorado/CO-Standard-Affidavit-of-Exempt-Sale-DR5002-GSA.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CO - Standard Colorado Affidavit of Exempt Sale
                          (DR-5002)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/colorado/dr-0563.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CO - Standard sales tax exemption certificate
                          (DR-0563)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/colorado/dr-1191.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CO - Machinery and machine tools purchases (DR-1191)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/colorado/dr-0511.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CO - Farm equipment purchases (DR-0511)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-CT'>
                    <h4>Connecticut State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-Contractors-CERT-141.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Contractor's Exempt Purchase Certificate
                          (CERT-141)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-Gov-Entity-CERT-134.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Exempt Purchases by Qualifying Governmental
                          Agencies (CERT-134)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-Manufacturing-CERT-100.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Manufacturing (CERT-100)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-Qualifying-Exempt-Org-CERT-119.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Purchases of Tangible Personal Property and
                          Services by Qualifying Exempt Organizations (CERT-119)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Resale Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/connecticut/CT-Tribal-Gov-CERT-127.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          CT - Exempt Purchases by an Enrolled Member or by the
                          Tribal Government of the Mashantucket Pequot Tribe or
                          Mohegan Tribe (CERT-127)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-DC'>
                    <h4>District of Columbia State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/district-of-columbia/DC-OTR-368-resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          DC - OTR-368 Certificate of Resale
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/district-of-columbia/DC-OTR-553-contractor.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          DC - OTR-553 Contractor’s Exempt Purchase Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-FL'>
                    <h4>Florida State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/dr-14.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Standard sales tax exemption certificate (DR-14)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/dr-13.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Annual Resale Certificate for Sales Tax (DR-13)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/exemption-gov.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Federal Employee Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/exemption-farm.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Power Farm Equipment Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/exemption-vessels.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Interstate Vessels Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/florida/letter-of-intent-to-export.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          FL - Letter of Intent to Export
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-GA'>
                    <h4>Georgia State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/georgia/st-5.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          GA - Certificate of Exemption – Purchaser or Dealer
                          (ST-5)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/georgia/st-5m.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          GA - Manufacturers' Certificate of Exemption (ST-5M)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/georgia/ga-gate-program.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          GA - Georgia Agricultural Tax Exemption (GATE)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/georgia/st-6.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          GA - Out-of-State Delivery Certificate of Exemption
                          (ST-6)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/georgia/st-4.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          GA - Out-of-State Dealer Certificate of Exemption
                          (ST-4)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-HI'>
                    <h4>Hawaii State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/hawaii/HI-Federal-Gov.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          HI - Federal Government Exemption Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/hawaii/HI-G-17-resale-wholesale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          HI - Resale Certificate For Goods General Form 1
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-ID'>
                    <h4>Idaho State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/idaho/ID-ST-101-sales-and-use.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ID - Sales Tax Resale or Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-IL'>
                    <h4>Illinois State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/illinois/IL-CRT-61-Resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          IL - Certificate of Resale
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/illinois/IL-ST-587-Agriculture-and-Mfg.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          IL - Equipment Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-IN'>
                    <h4>Indiana State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/indiana/IN-SST-exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          IN - Certificate of Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-IA'>
                    <h4>Iowa State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/iowa/exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          IA - Sales Tax Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-KS'>
                    <h4>Kansas State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28g.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - US Govement Exemption Certificate PR-78
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-uofmissouri.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - University of Missouri Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-douglascounty.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Douglas County Tax Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-78.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Sales Tax Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-201.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Integrated Production Machinery and Equipment
                          Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28a.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Resale Exemption Certificate ST-28A
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28m.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Multi-Jurisdictional ST-28M
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28d.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Ingredient or Component Part Exemption
                          Certificate ST-28D
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Generic Exemption Certificate ST-28
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28c.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Consume in Production Exemption Certificate Form
                          ST-28C
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kansas/exemption-st-28f.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KS - Agricultural Exemption Cerrticate ST-28F
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-KY'>
                    <h4>Kentucky State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kentucky/KY-Farm-51A158.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KY - Farm Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kentucky/KY-Fed-State-Local-Gov-SST.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KY - Certificate of Exemption
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kentucky/KY-Non-profit-51A126.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KY - Purchase Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kentucky/KY-Out-of-state-purchase-51A127.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KY - Out-of-State Purchase Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/kentucky/KY-Resale-51A105.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          KY - Resale Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-LA'>
                    <h4>Louisiana State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/louisiana/R-1020.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          LA - Sales Tax Exemption Certificate (R-1020)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/louisiana/R-1056.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          LA - Certificate of Sales/Use Tax Exemption (R-1056)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/louisiana/R-1356.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          LA - Sales Tax Exemption Certificate For Purchases By
                          The Federal Government (R-1356)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/louisiana/LA-Resale-Request.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          LA - Louisiana Resale Request
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MD'>
                    <h4>Maryland State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maryland/MD-Agricultural.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MD - Maryland Sales and Use Tax Agricultural Exemption
                          Certification
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maryland/MD-Contractor.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MD - Maryland Contractor
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maryland/MD-Exempt-entity-request.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MD - Maryland ‐ Exemption Request
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maryland/MD-Federal Gov.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MD - Federal Government Exemption Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maryland/MD-MTC-Resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MD - Uniform Sales &amp; Use Tax Exemption/Resale
                          Certificate—Multijurisdiction
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MI'>
                    <h4>Michigan State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/michigan/MI-SST.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MI - Streamlined Sales Tax Agreement Certificate of
                          Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MN'>
                    <h4>Minnesota State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/minnesota/st-3.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MN - Certificate of Exemption (ST-3)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-ME'>
                    <h4>Maine State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maine/ME-Exempt-org.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ME - Maine - Exempt Organization Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maine/ME-Resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ME - Maine - Resale Request
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maine/ME-ST-A-117-mfg.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ME - Maine Revenue Services Sales/Excise Tax Division
                          (A-117)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/maine/ME-ST-A-126-Farm.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ME - Maine Revenue Services Sales/Excise Tax Division
                          (A-126)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MA'>
                    <h4>Massachusetts State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/massachusetts/MA-ST-4-Resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MA - ST-4 - Sales Tax Resale Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/massachusetts/MA-ST-5C-Contractors.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MA - ST-5C - Contractor’s Sales Tax Exempt Purchase
                          Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/massachusetts/MA-ST-5-Gov-or-Exempt-org.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MA - ST-5 - Sales Tax Exempt Purchaser Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/massachusetts/MA-ST-12-Ag-and-Mfg.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MA - ST-12 - Exempt Use Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MS'>
                    <h4>Mississippi State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href="https://assets.northerntool.com/info/help/tax-forms/mississippi/Mississippi Farmer's Affidavit.pdf"
                          target='_blank'
                          rel='noreferrer'>
                          MS - Farmer Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/mississippi/Sample MS Resale Certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MS - Resale Certificate Sample
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/mississippi/Sample MS Sales Tax Permit.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MS - Sales Tax Permit Sample
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-MO'>
                    <h4>Missouri State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/missouri/MO_Form_149.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          MO - Certificate of Exemption (ST-3)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NE'>
                    <h4>Nebraska State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/nebraska/form13.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NE - Resale or Exempt Sale Certificate (Form 13)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/nebraska/form13e.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NE - Energy Source Exempt Sale Certificate (Form 13E)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/nebraska/form26.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NE - Native American Indians Exemption Certificate
                          (Form 26)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NV'>
                    <h4>Nevada State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/nevada/NV-SST-2018.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NV - Streamlined Sales Tax Agreement Certificate of
                          Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NJ'>
                    <h4>New Jersey State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-jersey/NJ-SST.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NJ - Streamlined Sales and Use Tax Agreement – New
                          Jersey Certificate of Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NM'>
                    <h4>New Mexico State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://tap.state.nm.us/TAP/_/'
                          target='_blank'
                          rel='noreferrer'>
                          NM - New Mexico's Taxpayers Access Point
                        </a>
                      </li>
                      <li>CRS Number 03-43681-00-08</li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NY'>
                    <h4>New York State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-DTF-950-Diplomat.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Certificate Of Sales Tax Exemption for Diplomatic
                          Missions and Personnel
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-Federal-Gov-Affidavit.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Federal Government Exemption Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-ST-119-1-Exempt-Org.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Exempt Organization Exempt Purchase Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-ST-120-Resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Resale Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-ST-121.9-Arts-Entertainment.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Exempt Purchase Certificate for Certain Property
                          and Services Used in Dramatic and Musical Arts
                          Performances
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-ST-121-Mfg-and-exempt-use.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Exempt Use Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-ST-125-Farmers.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - Farmer’s and Commercial Horse Boarding Operator's
                          Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/new-york/NY-State-and-local-gov-affidavit.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NY - State and Local Government Exemption Affidavit
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-NC'>
                    <h4>North Carolina State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-carolina/e-595e.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NC - Streamlined Certificate of Exemption (E-595E)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-carolina/e-599c.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          NC - Export Sales (E-599C)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-ND'>
                    <h4>North Dakota State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-dakota/contractors.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ND - Contractor's Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-dakota/manufacturers.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ND - Certificate of Processing (Manufacturing)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-dakota/resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ND - Certificate of Resale
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/north-dakota/gov.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          ND - Exemption Certificate for U.S. Government
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-OK'>
                    <h4>Oklahoma State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/oklahoma/sales.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OK - Exemption Certificate Form
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/oklahoma/agricultural.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OK - Agricultural Affidavit
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/oklahoma/OK_Streamlined_Exemption_Cert.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OK - Streamlined Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/oklahoma/OK_Agricultural_Exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OK - Agricultural Exemption Form
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/oklahoma/OK_Disabled_Veterans_Exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OK - Disabled Veterans Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-OH'>
                    <h4>Ohio State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/ohio/OH-SST-Exemption-certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          OH - Streamlined Sales and Use Tax Agreement
                          Certificate of Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-PA'>
                    <h4>Pennsylvania State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/pennsylvania/PA-General-tax-REV-1220.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          PA - Pennsylvania Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-PR'>
                    <h4>Puerto Rico State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/puerto-rico/PR-AS-2916-1-exemption-certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          PR - Certificate for Exempt Purchases
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-RI'>
                    <h4>Rhode Island State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/rhode-island/RI-SST-Exemption-certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          RI - Streamlined Sales and Use Tax Agreement
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/rhode-island/RI-Contractors-Exemption-Certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          RI - Contractor's Exempt Purchase Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-SC'>
                    <h4>South Carolina State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/south-carolina/st-8.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          SC - Exemption Certificate (ST-8)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/south-carolina/st-8a.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          SC - Resale Certificate (ST-8A)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/south-carolina/st-8f.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          SC - Agricultural Exemption Certificate (ST-8F)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-SD'>
                    <h4>South Dakota State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/south-dakota/SD_Streamlined_Exemption_Certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          SD - Streamlined Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-TN'>
                    <h4>Tennessee State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/tennessee/exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TN - Streamlined Certificate of Exemption
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/tennessee/agricultural-exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TN - Agricultural Sales &amp; Use Tax Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/tennessee/rv-f1301301.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TN - Government Certificate of Exemption (RV-F1301301)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/tennessee/rv-f1300701.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TN - Blanket Certificate of Resale (RV-F1300701)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-TX'>
                    <h4>Texas State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/exemption-01-339.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Exemption Certificate (01-339)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/exemption-01-339-s.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Exemption Certificate (01-339-S)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/exemption-01-924.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Exemption Certificate (01-924)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/exemption-01-925.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Exemption Certificate (01-925)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/resale-01-339.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Resale Certificate (01-339)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/resale-01-339-s.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Resale Certificate (01-339-S)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/TX-Border-States-Resale-Cetificate-01-909.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Resale Certificate, Border States (01-909-1)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/texas/TX_Direct_Payment_Exemption_Certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          TX - Direct Payment Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-UT'>
                    <h4>Utah State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/utah/UT-SST.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          UT - Streamlined Sales Tax Agreement Exemption
                          Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-VT'>
                    <h4>Vermont State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/vermont/VT-SST-Exemption-Certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VT - Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-VA'>
                    <h4>Virginia State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-10.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Resale (ST-10)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-10a.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Export Sales (ST-10A)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-11.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Industrial manufacturing, machinery, tools, and
                          agriculture (ST-11)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-12.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - VA political subdivisions (ST-12)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-13a.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Churches (ST-13A)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-14.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Out-of-State Dealers (ST-14)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-15.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Heating Purchases (ST-15)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-16.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Harvesters of waterway products (ST-16)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-17.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Harvesters of forest products (ST-17)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-18.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Farmers (ST-18)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-19.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Shipping lines (ST-19)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/virginia/st-23.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          VA - Multi-fuel heating stoves (ST-23)
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-WA'>
                    <h4>Washington State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/washington/WA-SST-Exemption-certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WA - Streamlined Sales Tax Exemption Certificate
                          (SSTGB Form F0003)
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/washington/WA-Buyers-Retail-Tax-Exempt-Cert-not-for-resale.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WA - Buyers’ Retail Sales Tax Exemption Certificate
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/washington/WA-Deliv-To-Native-American.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WA - Sales To Indians With Delivery In Indian Country
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-WV'>
                    <h4>West Virginia State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/west-virginia/WV-SST-exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WV - Streamlined Sales Tax Agreement Certificate of
                          Exemption
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-WI'>
                    <h4>Wisconsin State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/wisconsin/exemption.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WI - Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='tab-content' id='stateforms-WY'>
                    <h4>Wyoming State Tax Forms</h4>
                    <ul>
                      <li>
                        <a
                          href='https://assets.northerntool.com/info/help/tax-forms/wyoming/WY-SST-Exemption-certificate.pdf'
                          target='_blank'
                          rel='noreferrer'>
                          WY - Exemption Certificate
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                Important points to remember about{' '}
                <strong>Tax Exemption Forms:</strong>
              </p>
              <ul className='nte-ul-list'>
                <li>
                  Please make sure all required fields are completed including
                  the type of exemption you are claiming. Incomplete forms will
                  not be accepted.
                </li>
                <li>
                  A copy of your business sales tax permit is not an acceptable
                  substitute for the state-approved form.
                </li>
                <li>
                  If you are purchasing items for your own use, we cannot accept
                  a resale exemption certificate. You must pay sales tax on
                  these items.
                </li>
                <li>
                  Farmers - please note that some states do not allow
                  agricultural exemptions for many of the items Northern Tool +
                  Equipment sells. Please consult your tax advisor before
                  submitting an exemption certificate.
                </li>
              </ul>
              <h4>Canadian Tax Forms</h4>
              <table className='nte-table' id='taxExemptForms1'>
                <thead>
                  <tr>
                    <th scope='col'>Form Name & Link</th>
                    <th scope='col'>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope='row'>
                      <a href='https://assets.northerntool.com/info/help/tax-forms/canada/ca-edc.pdf'>
                        <img
                          src='https://assets.northerntool.com/icons/pdf_sm.gif'
                          width='15'
                          height='16'
                          alt='PDF'
                        />
                        &nbsp; Export Distribution Centre (EDC) Form
                      </a>
                    </th>
                    <td>
                      GST exemption form for buyers authorized by the Minister
                      of National Revenue
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <a href='https://assets.northerntool.com/info/help/tax-forms/canada/ca-farm-exemption.pdf'>
                        <img
                          src='https://assets.northerntool.com/icons/pdf_sm.gif'
                          width='15'
                          height='16'
                          alt='PDF'
                        />
                        &nbsp; Multi-Province Farm Exemption Certificate
                      </a>
                    </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <a href='https://assets.northerntool.com/info/help/tax-forms/canada/ca-sale-tax.pdf'>
                        <img
                          src='https://assets.northerntool.com/icons/pdf_sm.gif'
                          width='15'
                          height='16'
                          alt='PDF'
                        />
                        &nbsp; Multi-Province Uniform Sale &amp; Use Tax
                        Certification
                      </a>
                    </th>
                    <td>
                      Manufacturers or resellers may be exempt from PST/QST by
                      completing this form
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='page-row'>
        <h2>Alerts</h2>
        <div className='cg-cols-auto'>
          <button onClick={regAlert}>Alert</button>
        </div>
      </div>
      <div className='page-row cmc-content'>
        <h2>Home Page Slides</h2>
        <a
          href='/pressure-washers'
          className='home-slide grid lazyload'
          data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/mockups/0322_flamingos-09.jpg'
          style={{ backgroundColor: '#000' }}>
          <div className='g-col-6 g-start-4 align-center'>
            <div
              className='content-cell content-padding-56'
              style={{
                backgroundColor: 'rgba(0, 0, 0, .9',
              }}>
              <h2 className='color-gold sizeh2'>INDUSTRY LEADING</h2>
              <h2 className='color-white sizeh1'>PINK FLAMINGOS</h2>
              <button>FLOCK NOW</button>
            </div>
          </div>
        </a>
        <div
          className='home-slide lazyload grid'
          data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/mockups/Sid_Sloth.webp'
          style={{
            backgroundColor: '#e5e5e5',
            backgroundSize: '25%',
            backgroundPosition: '77%',
          }}>
          <div className='g-col-4 g-start-2'>
            <div className='content-padding-56 content-cell'>
              <h2 className='sizeh1'>MADE FOR SID</h2>
              <button className='contained'>SLOTH NOW</button>
            </div>
          </div>
        </div>

        <div
          className='background-image-grid'
          style={{ backgroundColor: '#000' }}>
          <div
            className='home-slide lazyload grid'
            data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/mockups/acme-rr.jpeg'
            style={{
              backgroundColor: 'rgba(0, 0, 0, .7',
            }}>
            <div className='g-col-4 g-start-2'>
              <div className=' content-padding-56 content-cell'>
                <h2 className='color-gold sizeh2'>Time To</h2>
                <h2 className='color-white sizeh1'>Power Shop</h2>
                <button className='outlined'>MEEP MEEP</button>
              </div>
            </div>
          </div>
          <span className='gradient-overlay left'></span>
          <span className='gradient-overlay right'></span>
        </div>
      </div>

      <div className='page-row'>
        <h2>Video modals</h2>
        <div className='grid'>
          <article
            className='nte-card no-padding video g-col-3'
            aria-label='Back to School'>
            <button
              className='inline-text video-trigger link-wrapped-card'
              data-video-url='https://www.youtube.com/embed/m-gfWHTGoPs'>
              <div
                className='image-cell lazyload'
                aria-label='power-tools'
                data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/info/about/tftt/donations.jpg'
                style={{ aspectRatio: '316 / 178' }}></div>
            </button>
            <div className='content-cell'>
              <h3>Northern Tool Heads Back to School</h3>
              <summary>
                <p>
                  <time>1/14/22</time>
                  Every school visit is filled with valuable teaching moments!
                  How is one local tool retailer positively affecting our young
                  students and their aspirations for a promising future in the
                  skilled trades industry? Watch this video to find out.
                </p>
              </summary>
            </div>
          </article>
          <div className='g-col-3'>
            <p>
              This would be a an
              <button className='inline-text'> inline text button.</button>
            </p>
          </div>
          <div className='g-col-3'>
            <button className='inline-text link-wrapped-card nte-card no-padding dark-ui fbv'>
              <div
                className='image-cell lazyload'
                aria-label='farm acreage'
                data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/shopping/campaigns/0303-pressure-washer.jpg'
                style={{
                  aspectRatio: '16/9',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}></div>
              <div className='content-cell vertical-space-between'>
                <h2>Power Through Spring</h2>
                <p className='link-effect bold-weight'>
                  Shop Our Pressure Washer Sale
                </p>
              </div>
            </button>
          </div>
          <article
            className='nte-card no-padding video g-col-3'
            aria-label='Back to School'>
            <button
              className='inline-text video-trigger link-wrapped-card'
              data-video-url='https://players.brightcove.net/1507807800001/rf1BTdKk6M_default/index.html?videoId=6116779877001'>
              <div
                className='image-cell lazyload'
                aria-label='power-tools'
                data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/info/about/tftt/donations.jpg'
                style={{ aspectRatio: '316 / 178' }}></div>
            </button>
            <div className='content-cell'>
              <h3>Northern Tool Heads Back to School</h3>
              <summary>
                <p>
                  <time>1/14/22</time>
                  Every school visit is filled with valuable teaching moments!
                  How is one local tool retailer positively affecting our young
                  students and their aspirations for a promising future in the
                  skilled trades industry? Watch this video to find out.
                </p>
              </summary>
            </div>
          </article>
        </div>
      </div>
      <div className='dark-ui page-row bleeder elevate-4'>
        <div className='banner-container'>
          <div className='narrow720'>
            <h1 className='align-center'>
              As a Trusted Family Business, We Honor and Serve Those Who Do the
              Tough Jobs.
            </h1>
          </div>
          <figure role='group'>
            <img
              data-src='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/info/about/our-story-bkgrd.jpg'
              data-sizes='auto'
              className='lazyload'
              alt='Our Story'
              style={{ borderBottom: '1px solid #f5b100' }}
            />
            <figcaption className='align-right'>
              Founder, Don Kotula (left); Owners, Ryan and Wade Kotula (right)
            </figcaption>
          </figure>
        </div>
        <div className='narrow720 nte-borderless-card'>
          <h2>The Best Things Are Built Right From the Garage</h2>
          <p>
            Founder Don Kotula's vision was simple right from the beginning —
            build lasting relationships by providing the right solution for
            every customer. That vision rang true on the very first day the
            company started, right there in his own garage, and it's stayed that
            way for over 40 years.
          </p>

          <p>
            Don has always been a hard-working, resourceful kind of guy. As a
            youngster, he grew up on Minnesota's Iron Range and worked at his
            father's scrap yard. That's where he learned how to recycle old
            train parts, mining equipment and other industrial scrap. The
            knowledge he gained in those early days transferred over to his
            first business venture as a teenager, refurbishing and selling used
            hydraulic components to loggers...
          </p>

          <div className='accordion'>
            <button className='header'>Open/Close</button>
            <div className='content narrow720'>
              <p>
                This early taste of business success led Don to the University
                of Minnesota – Duluth, where he earned a finance and business
                degree in 1972. After eight years in the accounting and sales
                world, a nationwide recession prompted a return to his
                entrepreneurial roots. In 1981 Don dusted off his hydraulics
                expertise and set up shop in his garage, selling cylinders,
                valves and how-to log splitter manuals by mail. It was the start
                of something much bigger: the birth of Northern Hydraulics. In
                1998 the company officially changed its name to Northern Tool +
                Equipment to better reflect its quickly growing product
                assortment, and the rest is history.
              </p>

              <p>
                Sons Ryan and Wade Kotula have been an important part of this
                company every step of the way. From their early start sweeping
                floors, mailing catalogs and packing orders, to their valuable
                time learning the ropes from their father, to their current role
                as owners, they've worked hand-in-hand with the leadership team
                to guide the business. Ryan and Wade continue to practice Don's
                passion for always engaging and listening to the customer,
                learning the challenges of the people being served, and helping
                them tackle any job in successful, efficient ways.
              </p>

              <p>
                From the retail stores, to the manufacturing facilities, to the
                distribution centers, all the way to the home office, this
                entrepreneurial spirit carries through the entire Northern Tool
                team. The company mindset is always open to exploring fresh
                ideas and challenging team members to use their extensive
                industry knowledge in creative, helpful and bold new ways.
                Although we've come a long way from our early emphasis on
                refurbished parts to our current position as a leader in
                superior quality tools at great prices, the culture of that
                garage start-up remains the same. Success grows only by
                maintaining and cultivating those customer relationships,
                exactly like Don Kotula has practiced right from the start.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='page-row bleeder white-ui elevate-2'>
        <img
          data-src='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/info/about/our-products-bkgrd.jpg'
          data-sizes='auto'
          className='lazyload'
          alt='Our Products'
        />
        <div className='narrow720 nte-borderless-card'>
          <h2>The Perfect Product Lineup</h2>
          <p>
            "Quality tools for serious work" isn't just a tagline, it's our
            company promise. When it comes to selection, Northern Tool doesn't
            stop at supplying you with over 100,000 tools from the top names in
            the industry including
            <a href='/shop/tools/category_stihl'>STIHL</a>,
            <a href='/shop/tools/category_milwaukee'>Milwaukee</a>,
            <a href='/shop/tools/category_honda-power-equipment'>Honda</a>, and
            hundreds of other
            <a href='/shop/tools/shop_brands'>leading national brands</a>. In
            fact, we take our assortment a step further by designing, building
            and testing our own exclusive products + equipment, as well. We
            manufacture and sell
            <a href='/shop/tools/category_northstar'>NorthStar</a>,
            <a href='/shop/tools/category_powerhorse'>Powerhorse</a>,
            <a href='/shop/tools/category_bannon'>Bannon</a>,
            <a href='/shop/tools/category_klutch'>Klutch</a> and a host of other
            <a href='/shop/tools/category_top-categories'>premium products</a>
            you can't get anywhere else. This exceptional lineup ensures that
            you'll find the right tools and equipment to custom-fit your needs.
          </p>
          <p></p>
        </div>
      </div>
      <h2 className='page-row'>Image Captions</h2>
      <div className='page-row bleeder'>
        <figure role='group'>
          <img
            src='https://www.northerntool.com/images/about-us/our-story-bkgrd.jpg'
            alt='nte story'
          />
          <figcaption className='align-right'>
            Founder, Don Kotula (left); Owners, Ryan and Wade Kotula (right)
          </figcaption>
        </figure>
      </div>
      <h2 className='page-row'>Selects</h2>
      <div className='page-row cg-cols-2'>
        <div className='nte-tab-select'>
          <select className='tabs'>
            <option value='' disabled selected hidden>
              Placeholder
            </option>
            <option data-target-id='store' value='Store'>
              Store
            </option>
            <option data-target-id='bopis' value='Bopis'>
              Bopis
            </option>
          </select>
        </div>
        <div>
          <img
            src='https://www.northerntool.com/images/stores/feedback/bopis-receipt-example.gif'
            id='bopis'
            className='tab-content'
            alt='bopis receipt'
          />
          <img
            src='https://www.northerntool.com/images/stores/feedback/store-receipt.gif'
            id='store'
            className='tab-content'
            alt='store receipt'
          />
        </div>
      </div>

      <h2 className='page-row'>Modals</h2>
      <div className='cg-cols-5 page-row'>
        <button
          type='button'
          className='modal-trigger'
          data-target-id='modal-id'>
          Button Activated Modal
        </button>
        <div className='modal-container' id='modal-id'>
          <h2>H2 Header</h2>
          <h3>H3 Header</h3>
          <p>
            Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
            cake. Liquorice donut brownie sweet apple pie pastry candy canes
            lollipop. Biscuit pastry powder caramels cookie. Marzipan chocolate
            chupa chups topping gummi bears dragée. Jelly beans lemon drops
            tiramisu donut cupcake cake dragée lollipop. Tiramisu chocolate
            wafer powder tootsie roll topping candy.
          </p>
        </div>
      </div>

      <h2 className='page-row'>Lists</h2>
      <div className='cg-cols-3 page-row'>
        <div>
          <p className='bold-weight'>UL list</p>
          <ul className='nte-ul-list'>
            <li>Item</li>
            <li>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </li>
            <li>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </li>
            <li>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </li>
            <li className='list-style-none'>
              <ul>
                <li className='bold-weight'>Nested Item</li>
                <li>
                  Donut sweet roll bonbon powder sweet roll soufflé tart cake
                  oat cake. Liquorice donut brownie sweet apple pie pastry candy
                  canes lollipop. Biscuit pastry powder caramels cookie.
                  Marzipan chocolate chupa chups topping gummi bears dragée.
                  Jelly beans lemon drops tiramisu donut cupcake cake dragée
                  lollipop. Tiramisu chocolate wafer powder tootsie roll topping
                  candy.
                </li>
                <li>
                  Donut sweet roll bonbon powder sweet roll soufflé tart cake
                  oat cake. Liquorice donut brownie sweet apple pie pastry candy
                  canes lollipop. Biscuit pastry powder caramels cookie.
                  Marzipan chocolate chupa chups topping gummi bears dragée.
                  Jelly beans lemon drops tiramisu donut cupcake cake dragée
                  lollipop. Tiramisu chocolate wafer powder tootsie roll topping
                  candy.
                </li>
                <li style={{ listStyle: 'none' }}>
                  <ul>
                    <li className='bold-weight'>Subnested Item</li>
                    <li>
                      Donut sweet roll bonbon powder sweet roll soufflé tart
                      cake oat cake. Liquorice donut brownie sweet apple pie
                      pastry candy canes lollipop. Biscuit pastry powder
                      caramels cookie. Marzipan chocolate chupa chups topping
                      gummi bears dragée. Jelly beans lemon drops tiramisu donut
                      cupcake cake dragée lollipop. Tiramisu chocolate wafer
                      powder tootsie roll topping candy.
                    </li>
                    <li>
                      Donut sweet roll bonbon powder sweet roll soufflé tart
                      cake oat cake. Liquorice donut brownie sweet apple pie
                      pastry candy canes lollipop. Biscuit pastry powder
                      caramels cookie. Marzipan chocolate chupa chups topping
                      gummi bears dragée. Jelly beans lemon drops tiramisu donut
                      cupcake cake dragée lollipop. Tiramisu chocolate wafer
                      powder tootsie roll topping candy.
                    </li>
                    <li>
                      Donut sweet roll bonbon powder sweet roll soufflé tart
                      cake oat cake. Liquorice donut brownie sweet apple pie
                      pastry candy canes lollipop. Biscuit pastry powder
                      caramels cookie. Marzipan chocolate chupa chups topping
                      gummi bears dragée. Jelly beans lemon drops tiramisu donut
                      cupcake cake dragée lollipop. Tiramisu chocolate wafer
                      powder tootsie roll topping candy.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <p className='bold-weight'>OL list</p>
          <ol className='nte-ol-list'>
            <li>Item</li>
            <li>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </li>
            <li>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </li>
            <li>
              <p>
                Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
                cake. Liquorice donut brownie sweet apple pie pastry candy canes
                lollipop. Biscuit pastry powder caramels cookie. Marzipan
                chocolate chupa chups topping gummi bears dragée. Jelly beans
                lemon drops tiramisu donut cupcake cake dragée lollipop.
                Tiramisu chocolate wafer powder tootsie roll topping candy.
              </p>
              <ul className='nte-ul-list'>
                <li className='bold-weight'>Nested Item</li>
                <li>
                  Donut sweet roll bonbon powder sweet roll soufflé tart cake
                  oat cake. Liquorice donut brownie sweet apple pie pastry candy
                  canes lollipop. Biscuit pastry powder caramels cookie.
                  Marzipan chocolate chupa chups topping gummi bears dragée.
                  Jelly beans lemon drops tiramisu donut cupcake cake dragée
                  lollipop. Tiramisu chocolate wafer powder tootsie roll topping
                  candy.
                </li>
                <li>
                  Donut sweet roll bonbon powder sweet roll soufflé tart cake
                  oat cake. Liquorice donut brownie sweet apple pie pastry candy
                  canes lollipop. Biscuit pastry powder caramels cookie.
                  Marzipan chocolate chupa chups topping gummi bears dragée.
                  Jelly beans lemon drops tiramisu donut cupcake cake dragée
                  lollipop. Tiramisu chocolate wafer powder tootsie roll topping
                  candy.
                </li>
              </ul>
            </li>
            <li>List Continues</li>
          </ol>
        </div>
        <div>
          <p className='bold-weight'>DL list</p>
          <dl className='nte-dl-list'>
            <dt>Definition Title</dt>
            <dd>Description</dd>
            <dt>Definition Title</dt>
            <dd>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </dd>
            <dt>Definition Title</dt>
            <dd>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </dd>
            <dd>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </dd>
            <dd>
              Donut sweet roll bonbon powder sweet roll soufflé tart cake oat
              cake. Liquorice donut brownie sweet apple pie pastry candy canes
              lollipop. Biscuit pastry powder caramels cookie. Marzipan
              chocolate chupa chups topping gummi bears dragée. Jelly beans
              lemon drops tiramisu donut cupcake cake dragée lollipop. Tiramisu
              chocolate wafer powder tootsie roll topping candy.
            </dd>
          </dl>
        </div>
      </div>
      <h2 className='page-row'>Buttons</h2>
      <div className='cg-cols-4'>
        <h3>Default</h3>
        <button type='button' className='btn-small'>
          Default Small Button
        </button>
        <button type='button'>Default Button</button>
        <button type='button' className='btn-large'>
          Default Large Button
        </button>
        <h3>Contained</h3>
        <button type='button' className='contained btn-small'>
          Contained Small Button
        </button>
        <button type='button' className='contained'>
          Contained Button
        </button>
        <button type='button' className='contained btn-large'>
          Contained Large Button
        </button>
        <h3>Text Default</h3>
        <a href='/' className='btn-small'>
          Default Link Small Button
        </a>
        <a href='/' className='btn'>
          Default Link Button
        </a>
        <a href='/' className='btn-large'>
          Default Link Large Button
        </a>
        <h3>Text Contained</h3>
        <a href='/' className='btn-small contained'>
          Default Link Small Button
        </a>
        <a href='/' className='btn contained'>
          Default Link Button
        </a>
        <a href='/' className='btn-large contained'>
          Default Link Large Button
        </a>
      </div>
      <h2 className='page-row'>Tables</h2>
      <div className='page-row'>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>First</th>
              <th scope='col'>Last</th>
              <th scope='col'>Handle</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row'>1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope='row'>2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope='row'>3</th>
              <td>Larry the Bird</td>
              <td>@twitter</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 className='page-row'>Category eSpots Content</h2>
      <div className='page-row cmc-content'>
        <div className='cg-cols-3'>
          <div
            className='nte-card no-padding'
            role='group'
            aria-label='Promo Card Category'>
            <div
              className='image-cell'
              aria-label='power-tools'
              style={{
                backgroundImage: `url(https://www.northerntool.com/images/categories/771593/category_01_hero/chp-strongway-jacks.jpg)`,
              }}></div>

            <div className='content-cell dark-ui'>
              <h3>Strongway Jacks + Stands: Built Strong, Tested Tough</h3>
              <p>
                <a rel='nofollow noindex' className='bold-weight' href='/'>
                  See All &gt;
                </a>
              </p>
            </div>
          </div>
          <a
            rel='nofollow noindex'
            href='/deal-of-the-day'
            className='link-wrapped-card nte-card no-padding dark-ui'>
            <div
              className='image-cell lazyload'
              aria-label='deal of the day'
              data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/shopping/campaigns/020619-dotd.jpg'
              style={{
                aspectRatio: '16/9',
                backgroundPosition: 'center',
              }}></div>
            <div className='content-cell'>
              <h2>Save on Our Top Deals Every Day</h2>
              <p className='link-effect bold-weight'>See the Deals</p>
            </div>
          </a>
          <a
            rel='nofollow noindex'
            href='/pressure-washers'
            className='link-wrapped-card nte-card no-padding dark-ui fbv'>
            <div
              className='image-cell lazyload'
              aria-label='farm acreage'
              data-bgset='https://assets.northerntool.com/cdn-cgi/image/fit=scale-down,width={width}/shopping/campaigns/0303-pressure-washer.jpg'
              style={{
                aspectRatio: '16/9',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}></div>
            <div className='content-cell vertical-space-between'>
              <h2>Power Through Spring</h2>
              <p className='link-effect bold-weight'>
                Shop Our Pressure Washer Sale
              </p>
            </div>
          </a>
        </div>
      </div>
      <h2 className='page-row'>Rich Content</h2>
      <div className='page-row cmc-content rich nte-card'>
        <div className='page-tab-row'>
          <div className='tab-row-ui'>
            <ul className='tab-row'>
              <li className='tab'>Description</li>
              <li className='tab'>Specs</li>
              <li className='tab'>Reviews</li>
              <li className='tab'>Q & A</li>
              <li className='tab'>Accessories</li>
              <li className='tab'>Compare</li>
            </ul>
          </div>
        </div>
        <div className='grid'>
          <div className='g-col-6'>
            <h2>Product Summary</h2>
            <p>
              If you need to create kindling for your campfire, wood stove,
              fireplace or pizza oven and you don’t want to risk injury by using
              an axe, the Kindling Cracker™ is just what you need. Built in
              Australia with an award-winning, patented design, it's the safer,
              faster and easier way to make the best kindling for your fire.
            </p>
            <h3>What's Included</h3>
            <p>(1) Kindling Cracker Firewood Splitter</p>
          </div>
          <div
            className='g-col-6 image-cell'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/product/400x400/118/118990_400x400.jpg)`,
            }}></div>
        </div>
        <hr />
        <div className='grid'>
          <div
            className='g-col-6'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/product/rich-content/118990/118990-1a.jpg)`,
            }}></div>
          <div className='g-col-6'>
            <h2>Kindling Cracker: The Best Way to Split Wood</h2>
            <h3>Inspiration Strikes</h3>
            <p>
              The Kindling Cracker delivers real-world ingenuity from an
              unlikely source — a school-age student who was determined to avoid
              injuries from splitting firewood into kindling. The simple, safe
              and easy-to-use design that began as a science fair project has
              become a perennial award winner, from science fairs in New Zealand
              to regional and international recognition from Scientific
              American, International TeenBusiness, Google and more.
            </p>
            <figure className='block-quote'>
              <blockquote>
                <p>
                  &quot;I am delighted with the effectiveness and simplicity of
                  this tool. It makes short and safe work out of splitting
                  kindling!&quot;
                </p>
              </blockquote>
              <figcaption>Tim, Minnesota</figcaption>
            </figure>
          </div>
        </div>
        <hr />
        <div className='grid'>
          <div className='g-col-6'>
            <h3>Quality, Premium Build</h3>
            <p>
              The Kindling Cracker is made in an Australian foundry. It consists
              of one solid piece of high-quality cast iron, so it&#39;s
              something that your grandkids&#39; grandkids will use to split
              their own kindling, if cared for and used correctly. The only
              maintenance it needs is a bit brushing off and maybe a touch of
              paint from time to time to look like new for generations.
            </p>
            <figure className='block-quote gray-bg'>
              <blockquote>
                <p>
                  &quot;You must buy one of these! It makes the production of
                  kindling safe, fast and fun. I wish I had this 30 years
                  ago.&quot;
                </p>
              </blockquote>
              <figcaption>Herman, New Jersey</figcaption>
            </figure>
            <figure className='block-quote'>
              <blockquote cite='https://www.huxley.net/bnw/four.html'>
                <p>
                  Words can be like X-rays, if you use them properly—they’ll go
                  through anything. You read and you’re pierced.
                </p>
              </blockquote>
              <figcaption>
                Aldous Huxley, <cite>Brave New World</cite>
              </figcaption>
            </figure>
          </div>
          <div
            className='g-col-6'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/product/rich-content/118990/118990-2a.jpg)`,
            }}></div>
        </div>
        <hr />
        <div className='grid'>
          <div className='grid g-col-6'>
            <div
              className='g-col-12'
              style={{
                backgroundImage: `url(https://www.northerntool.com/images/product/rich-content/62858/62858.jpg)`,
              }}></div>
            <div
              className='g-col-12'
              style={{
                backgroundImage: `url(https://www.northerntool.com/images/product/rich-content/62858/62858.jpg)`,
              }}></div>
          </div>
          <div className='g-col-6'>
            <h3>Safe and Easy to Use</h3>
            <p>
              The Kindling Cracker is a top-grade cast iron splitting head
              mounted inside of a 12in.H cast iron frame. To make a perfect
              piece of kindling, place a piece of firewood inside the iron
              safety ring and strike with a blunt instrument, such as a hammer,
              mallet or even another piece of wood. This drives the wood down
              onto the splitting wedge for a quick, easy split. There is no need
              to swing a sharp axe blade dangerously close to your hands.
              It&#39;s the perfect companion for your backyard stack of
              firewood, creating perfect kindling for your fireplace, fire pit,
              pizza oven, sauna, barbecue or smoker.
            </p>
            <p className='block-quote'>
              &quot;I can&#39;t believe my Kindling Cracker is still in one
              piece! You guys must make these things out of old UFO parts.&quot;
              <span className='quote-attribute'>Bill, Texas</span>
            </p>
          </div>
        </div>
        <hr />
        <div className='grid'>
          <div className='g-col-9'>
            <h3>5-year limited warranty</h3>
            <p>
              Shop in complete confidence knowing this solid cast iron tool is
              backed by a 5-year limited warranty.
            </p>
            <h3>Guaranteed Satisfaction</h3>
            <p>
              Shop in complete confidence, knowing Northern Tool + Equipment
              protects your investment and is ready to provide you with the
              assistance and helpful resources you need.
            </p>
          </div>
          <div
            className='g-col-3'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/product/rich-content/118990/warranty-badge.svg)`,
            }}></div>
        </div>
      </div>
      <div className='page-row nte-card'>
        <div className='grid'>
          <div className='g-col-12'>
            <h2>Key Specs</h2>
          </div>
          <div className='g-col-6'>Spec</div>
          <div className='g-col-6'>Spec</div>
          <div className='g-col-6'>Spec</div>
          <div className='g-col-6'>Spec</div>
        </div>
      </div>
      {/*Help Template*/}
      <h2 className='page-row'>Help Cards Template</h2>
      <div className='nte-card container cmc-content'>
        <h3>Card Title</h3>
        <div className='grid'>
          <div className='g-col-9'>
            <ul className='nte-ul-list'>
              <li>
                <a href='/go/somewhere'>How to Get Parts</a>
              </li>
              <li>
                <a href='/go/somewhere'>How Can We Help</a>
              </li>
              <li>
                <a href='/go/somewhere'>Find Our Service Locations</a>
              </li>
            </ul>
          </div>
          <div className='g-col-3'>
            <img
              src='https://www.northerntool.com/images/icons/help/Help-Icon-60px-warranty.jpg'
              width='60'
              height='60'
              alt=''
              title='Product Warranties'
            />
          </div>
        </div>
      </div>
      <h2 className='page-row'>Bootstrap Grid</h2>
      <div className='page-row test'>
        <div className='container nte-card'>
          <div className='grid'>
            <div className='g-col-6'>1 of 2</div>
            <div className='g-col-6'>2 of 2</div>
          </div>
          <div className='grid'>
            <div className='g-col'>1 of 3</div>
            <div className='g-col'>2 of 3</div>
            <div className='g-col'>3 of 3</div>
          </div>
          <div className='grid'>
            <div className='g-col'>1 of 3</div>
            <div className='g-col-6'>2 of 3 (wider)</div>
            <div className='g-col'>3 of 3</div>
          </div>
        </div>
      </div>
      <div id='HP_09' data-espot='HP_09' className='page-row cmc-content'>
        <div className='grid nte-card no-padding'>
          <div
            className='g-col-5 image-cell'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/v9/content/donearlystore2.jpg)`,
            }}></div>
          <div className='g-col-7 content-cell'>
            <h2>Family Owned + Operated for Over 40 Years</h2>
            <p>
              <strong>It's All About Family.</strong> From our humble start in
              Don Kotula’s garage back in 1981, all the way to our current
              status as the ultimate destination for hard-working
              do-it-yourselfers and professionals, Northern Tool + Equipment has
              always been a family-owned and operated business that puts the
              customer first. Our selection of products proves it, with a great
              lineup of generators, pressure washers, power tools, hand tools,
              air compressors, welders, material handling equipment and more
              from the top national brands. But our selection doesn’t stop
              there. We also design, build, test and deliver an extensive
              selection of world-class products made right in our own
              manufacturing facilities. You’ll always find great prices on the
              perfect family of tools and products needed to tackle the tough
              challenges. And best of all, they’re backed with the product
              expertise and customer care you can always count on at Northern
              Tool.
            </p>
            <p>
              <a rel='nofollow noindex' className='bold-weight' href='/'>
                Bold Link &gt;
              </a>
            </p>
          </div>
        </div>
      </div>
      <div id='HP_09' data-espot='HP_09' className='page-row cmc-content'>
        <div className='grid nte-card no-padding content-left'>
          <div className='g-col-7 content-cell'>
            <h2>Family Owned + Operated for Over 40 Years</h2>
            <p>
              <strong>It's All About Family.</strong> From our humble start in
              Don Kotula’s garage back in 1981, all the way to our current
              status as the ultimate destination for hard-working
              do-it-yourselfers and professionals, Northern Tool + Equipment has
              always been a family-owned and operated business that puts the
              customer first. Our selection of products proves it, with a great
              lineup of generators, pressure washers, power tools, hand tools,
              air compressors, welders, material handling equipment and more
              from the top national brands. But our selection doesn’t stop
              there. We also design, build, test and deliver an extensive
              selection of world-class products made right in our own
              manufacturing facilities. You’ll always find great prices on the
              perfect family of tools and products needed to tackle the tough
              challenges. And best of all, they’re backed with the product
              expertise and customer care you can always count on at Northern
              Tool.
            </p>
            <p>
              <a rel='nofollow noindex' className='bold-weight' href='/'>
                Bold Link &gt;
              </a>
            </p>
          </div>
          <div
            className='g-col-5 image-cell'
            style={{
              backgroundImage: `url(https://www.northerntool.com/images/v9/content/donearlystore2.jpg)`,
            }}></div>
        </div>
      </div>
      <h2 className='page-row'>FAQ page example</h2>
      <div className='page-row'>
        <div className='nte-card accordion'>
          <button className='header'>
            <h4>
              Why does the product item# appear differently in the catalog than
              on the website?
            </h4>
          </button>
          <div className='content'>
            <p>
              The item# in our catalogs will also contain the catalog reference
              number (example: 165911-2751). We omit the catalog reference
              number on the website since it doesn't apply.
            </p>
            <p>
              Note: when searching for an item# on the website, please omit the
              catalog reference number and the dash.
            </p>
          </div>
        </div>
      </div>
      <div className='page-row'>
        <div className='nte-card bottom-margin28 sandwich search-bar'>
          <Toolbar>
            <SearchIcon />
            <InputBase
              placeholder='Search…'
              inputProps={{ 'aria-label': 'search' }}
            />
          </Toolbar>
        </div>
        <div className='nav-left-content'>
          <div className='left nte-card tabs'>
            <h2>FAQ Categories</h2>
            <button className='tab' data-target-id='shopping'>
              <h4>Shopping Questions</h4>
            </button>
            <button className='tab' data-target-id='checkout'>
              <h4>Checking Out Questions</h4>
            </button>
            <button className='tab' data-target-id='post-purchase'>
              <h4>Post-Purchase Questions</h4>
            </button>
            <button className='tab' data-target-id='shipping-cost'>
              <h4>Shipping Cost Questions</h4>
            </button>
            <button className='tab' data-target-id='order-tracking'>
              <h4>Order Tracking Questions</h4>
            </button>
            <button className='tab' data-target-id='delivery'>
              <h4>Carrier + Delivery Questions</h4>
            </button>
          </div>
          <div className='right'>
            {/*Shopping tab*/}
            <div className='nte-card tab-content active' id='shopping'>
              <div className='accordion open'>
                <button className='header'>
                  <h4>How do I know if an item is in stock?</h4>
                </button>
                <div className='content'>
                  <p>How do I know if an item is in stock?</p>
                </div>
              </div>
              <div className='accordion'>
                <button className='header'>
                  <h4>
                    Why does the product item# appear differently in the catalog
                    than on the website?
                  </h4>
                </button>
                <div className='content'>
                  <p>
                    The item# in our catalogs will also contain the catalog
                    reference number (example: 165911-2751). We omit the catalog
                    reference number on the website since it doesn't apply.
                  </p>
                  <p>
                    Note: when searching for an item# on the website, please
                    omit the catalog reference number and the dash.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <button className='header'>
                  <h4>
                    Why would the price for an item on the website be different
                    from the price in the print catalog?
                  </h4>
                </button>
                <div className='content'>
                  <p>
                    Pricing can differ online from the catalogs due to sales and
                    offers we have for our internet customers. We also have
                    exclusive specials for our Email Deals subscribers.
                  </p>
                  <p>
                    Aside from sales, our internet pricing matches the prices in
                    our most current catalogs. If you have a valid catalog with
                    different pricing, please use our catalog order form and
                    enter in your catalog number, including the four digit
                    suffix.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <button className='header'>
                  <h4>
                    Where can I receive answers to questions that I have about a
                    particular product?
                  </h4>
                </button>
                <div className='content'>
                  <ul className='nte-ul-list'>
                    <li>
                      Product information is available on our product pages
                      including detailed product descriptions, features +
                      benefits, key specs, application images, videos and
                      product manuals.
                    </li>
                    <li>
                      Contact our product experts by calling 1-800-838-0516 (7AM
                      – 10PM CT, Monday - Friday | 8AM – 5PM CT, Saturday and
                      Sunday).
                    </li>
                    <li>
                      Activate an online chat session (7AM – 10PM CT, Monday -
                      Friday).
                    </li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <button className='header'>
                  <h4>
                    How long will the cart be saved for? Does it save pricing as
                    well?
                  </h4>
                </button>
                <div className='content'>
                  <p>
                    If you do not have a password-protected online account with
                    us, you are a guest customer in our system. We save shopping
                    carts for guest customers on their device for 60 days. As a
                    guest, your cart will not carry over from device to device.
                    If you'd like to save your cart for longer than 60 days, or
                    wish to view your cart on a different device, create an
                    account now.
                  </p>
                  <p>
                    If you are a registered customer (meaning you have a
                    password-protected online account), we will save your
                    shopping cart for as long as you have your account, or until
                    you place your next order.
                  </p>
                  <p>
                    While items you select generally will remain in your cart,
                    pricing may change: the items you selected may be re-priced
                    due to our sale schedule. In some cases, an item may become
                    obsolete; if that happens, the items are removed from your
                    cart.
                  </p>
                </div>
              </div>
            </div>

            {/*Checkout tab*/}
            <div className='nte-card tab-content' id='checkout'>
              <div className='accordion'>
                <div className='header'>
                  <h4>What are the steps to place an order?</h4>
                </div>
                <div className='content'>
                  <p>
                    Ordering at NorthernTool.com is quick, easy and secure. Use
                    our Catalog Order Form or simply follow these steps to place
                    your order.
                  </p>
                  <ol>
                    <li>
                      To add an item to your shopping cart, click the “Add to
                      Cart” button on any product page, or product listing page.
                    </li>
                    <li>
                      When you are finished shopping, click the "Cart" icon at
                      the top of the screen. Review the contents of your cart
                      and provide a keycode at this time if you have one.
                    </li>
                    <li>Click the “Checkout” button on your shopping cart.</li>
                    <li>
                      Sign in to your online account, create a new online
                      account, or continue checking out as a guest by not
                      creating a password.
                    </li>
                    <li>
                      Verify your billing and shipping address. Change these
                      addresses when necessary.
                    </li>
                    <li>
                      Select a shipping option, then click the “Continue
                      Checkout” button.
                    </li>
                    <li>
                      Use a gift card, PayPal, or credit card to pay for your
                      order, then click the “Place Order” button.
                    </li>
                    <li>
                      Print your order confirmation* receipt for your records.
                    </li>
                  </ol>
                  <p>
                    *NorthernTool.com will send you order confirmation and
                    status updates via email to the email address provided in
                    your billing address. In addition, customers with an online
                    account will receive email notification when their in-stock
                    orders have shipped.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>How do I use a keycode?</h4>
                </div>
                <div className='content'>
                  <p>
                    Each item is charged shipping when it leaves. However, you
                    are not charged any additional shipping from what was quoted
                    at the time of the order. The shipping cost is pro-rated.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>
                    Can I place an order now and have it held to be shipped at a
                    later date?
                  </h4>
                </div>
                <div className='content'>
                  <p>
                    Estimated Delivery Times for Credit Approved In Stock
                    Orders:
                  </p>
                  <ul className='nte-ul-list'>
                    <li>Standard and Truck: See map of delivery times</li>
                    <li>Priority: 2 - 3 Business Days</li>
                    <li>Express: 1 - 2 Business Days</li>
                    <li>
                      Items shipped to store (Free Ship to Store): 5-12 Business
                      Days
                    </li>
                    <li>
                      Items shipped direct from manufacturer: Delivery times are
                      listed on the item's product detail page. Note: Items
                      shipped from the manufacturer are not available for
                      shipment to Alaska or Hawaii
                    </li>
                    <li>Parcel Post to Alaska and Hawaii: 30 Business Days</li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>What payment methods do you accept?</h4>
                </div>
                <div className='content'>
                  <p>
                    Estimated Delivery Times for Credit Approved In Stock
                    Orders:
                  </p>
                  <ul className='nte-ul-list'>
                    <li>Standard and Truck: See map of delivery times</li>
                    <li>Priority: 2 - 3 Business Days</li>
                    <li>Express: 1 - 2 Business Days</li>
                    <li>
                      Items shipped to store (Free Ship to Store): 5-12 Business
                      Days
                    </li>
                    <li>
                      Items shipped direct from manufacturer: Delivery times are
                      listed on the item's product detail page. Note: Items
                      shipped from the manufacturer are not available for
                      shipment to Alaska or Hawaii
                    </li>
                    <li>Parcel Post to Alaska and Hawaii: 30 Business Days</li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>Where/how can I use my Northern Tool Gift Cards?</h4>
                </div>
                <div className='content'>
                  <p>
                    Estimated Delivery Times for Credit Approved In Stock
                    Orders:
                  </p>
                  <ul className='nte-ul-list'>
                    <li>Standard and Truck: See map of delivery times</li>
                    <li>Priority: 2 - 3 Business Days</li>
                    <li>Express: 1 - 2 Business Days</li>
                    <li>
                      Items shipped to store (Free Ship to Store): 5-12 Business
                      Days
                    </li>
                    <li>
                      Items shipped direct from manufacturer: Delivery times are
                      listed on the item's product detail page. Note: Items
                      shipped from the manufacturer are not available for
                      shipment to Alaska or Hawaii
                    </li>
                    <li>Parcel Post to Alaska and Hawaii: 30 Business Days</li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>When will my credit card be charged?</h4>
                </div>
                <div className='content'>
                  <p>
                    Estimated Delivery Times for Credit Approved In Stock
                    Orders:
                  </p>
                  <ul className='nte-ul-list'>
                    <li>Standard and Truck: See map of delivery times</li>
                    <li>Priority: 2 - 3 Business Days</li>
                    <li>Express: 1 - 2 Business Days</li>
                    <li>
                      Items shipped to store (Free Ship to Store): 5-12 Business
                      Days
                    </li>
                    <li>
                      Items shipped direct from manufacturer: Delivery times are
                      listed on the item's product detail page. Note: Items
                      shipped from the manufacturer are not available for
                      shipment to Alaska or Hawaii
                    </li>
                    <li>Parcel Post to Alaska and Hawaii: 30 Business Days</li>
                  </ul>
                </div>
              </div>
            </div>
            {/*Post Purchase tab*/}
            <div className='nte-card tab-content' id='post-purchase'>
              <div className='accordion open'>
                <div className='header'>
                  <h4>How do I cancel or change an order?</h4>
                </div>
                <div className='content'>
                  <p>Please contact Customer Care:</p>
                  <ul className='nte-ul-list'>
                    <li>
                      Call 1-800-222-5381 (7AM – 10PM CT, Monday - Friday | 8AM
                      – 5PM CT, Saturday and Sunday).
                    </li>
                    <li>
                      Activate an online chat session (7AM – 10PM CT, Monday -
                      Friday).
                    </li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>
                    How long does it take to receive my order when sending check
                    or money order?
                  </h4>
                </div>
                <div className='content'>
                  <ul className='nte-ul-list'>
                    <li>
                      Check: typically takes approximately two to three weeks
                      upon receipt.
                    </li>
                    <li>
                      Money Order: typically takes approximately two weeks upon
                      receipt.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>
                    How will I know when Backordered items in my order are in
                    stock? And shipped?
                  </h4>
                </div>
                <div className='content'>
                  <p>
                    Items temporarily out of stock go on a backorder status. You
                    will not be charged for the backordered items or their
                    shipping costs until the items have shipped.
                  </p>
                  <ul className='nte-ul-list'>
                    <li>
                      If an email address was provided with your order, we will
                      notify you via email when the order has shipped.
                    </li>
                    <li>
                      If an email address was not provided with your order, we
                      will not contact you when the order has shipped.
                    </li>
                    <li>
                      Checking the status of your order can be done by visiting
                      our My Orders page at any time.
                    </li>
                    <li>
                      Contact Customer Care with questions about your order or
                      your order status:
                      <ul className='nte-ul-list'>
                        <li>
                          Call 1-800-222-5381 (7AM – 10PM CT, Monday - Friday |
                          8AM – 5PM CT, Saturday and Sunday).
                        </li>
                        <li>
                          Activate an online chat session (7AM – 10PM CT, Monday
                          - Friday).
                        </li>
                        <li>
                          Visit our Contact Us page to email your inquiry.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>
                    If I have more than one item on my order and an item(s) is
                    on backorder, do I get charged shipping twice?
                  </h4>
                </div>
                <div className='content'>
                  <p>
                    Yes, you are charged shipping fees only once an item ships
                    out. You are not charged any additional shipping fees from
                    what was quoted at the time of the order. The shipping cost
                    is prorated.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>How do I obtain an invoice from my order?</h4>
                </div>
                <div className='content'>
                  <p>
                    Look up your order by logging in to your account, or
                    providing a combination of your order number/customer number
                    and ZIP code on our order status page. Next to your order
                    summary you will see a white "Email Invoice" button. When
                    clicked, it will present you with a layer. Put in only one
                    email address at a time, and click "Send Invoice". Please
                    allow up to 5 minutes for the invoice to arrive. Add
                    noreply@northerntool.com and D365.Prod@northerntool.com to
                    your contacts to ensure it does not go into your spam filter.
                    You can print or forward the invoice from your email client.
                  </p>
                </div>
              </div>
              <div className='accordion'>
                <div className='header'>
                  <h4>
                    Do you sell parts for your items? How do I order them?
                  </h4>
                </div>
                <div className='content'>
                  <ul className='nte-ul-list'>
                    <li>
                      Replacement parts can be ordered by calling 1-800-657-0516
                      (7AM - 6PM CT, Monday - Friday | 8AM - 5PM CT, Saturday).
                    </li>
                    <li>
                      If possible, have the item#, model# or part# ready. This
                      will assist us in locating the replacement parts for you.
                    </li>
                    <li>
                      <a href='/link'>
                        Learn more about how to order replacement parts.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className='page-row'>Top Pressure Washers Example:</h2>
      <div className='page-row page-title has-breadcrumbs has-background-image'>
        <div className='wrap-all'>
          <span
            style={{
              backgroundImage:
                'url(https://www.northerntool.com/images/categories/hero/32.jpg)',
            }}
            className='background-image'></span>
          <div className='content-wrapper'>
            <ul className='bread-crumbs-container'>
              <li className='bread-crumbs-link'>Northern Tool</li>
              <li className='bread-crumbs-link'>Page Name</li>
              <li className='bread-crumbs-link'>No Link</li>
            </ul>
            <h1>Top Pressure Washers</h1>
          </div>
        </div>
      </div>
      <div className='page-row'>
        <div className='nte-card'>
          <h2>Weighing Your Options</h2>
          <p>
            Looking for the best pressure washer without overspending for power
            you don't need? Know your options before buying a pressure washer
            whether it's to clean windows or strip paint. There are two key
            specs to pay attention to when it comes to buying a pressure washer,
            PSI (pounds per square inch) and GPM (gallons per minute). Check out
            our pressure washer buyer's guide to find the exact measurement you
            need to look for when buying.
          </p>
        </div>
      </div>
      <div className='page-row'>
        <div className='nte-card'>
          <h2>Best Electric Pressure Washer</h2>
          <p>
            There are a couple benefits to buying electric pressure washers.
            They can be used indoors or outdoors and are quieter than gas-
            powered pressure washers. We choose the following as the best
            electric pressure washers because of their proven reliability,
            sturdy CAT pump and convenient features including adjustable
            pressure and thermostat. These make for ideal home-use.
          </p>
        </div>
        <ul className='product-list section-row'>
          <li className='product-block nte-card'>
            <div className='content'>
              <div className='image-col'></div>
              <div className='info-col'>
                <h5>Product Card Widget</h5>
              </div>
              <div className='price-col'></div>
            </div>
          </li>
        </ul>
        <button className='large outlined centered'>
          Shop All [this Catergory]
        </button>
      </div>
      <div className='page-row'>
        <div className='nte-card'>
          <h2>Best Gas Pressure Washer</h2>
          <p>
            Gas pressure washers offer necessary portability for outdoor jobs
            while providing high pressures and flows. These are usually ideal
            for larger jobs or when you need a little more power. Whether you're
            removing grease stains or using for household maintenance, the
            following gas pressure washers have our vote as the best.
          </p>
        </div>
        <ul className='product-list section-row'>
          <li className='product-block nte-card'>
            <div className='content'>
              <div className='image-col'></div>
              <div className='info-col'>
                <h5>Product Card Widget</h5>
              </div>
              <div className='price-col'></div>
            </div>
          </li>
          <li className='product-block nte-card'>
            <div className='content'>
              <div className='image-col'></div>
              <div className='info-col'>
                <h5>Product Card Widget</h5>
              </div>
              <div className='price-col'></div>
            </div>
          </li>
        </ul>
        <button className='large outlined centered'>
          Shop All [this Category]
        </button>
      </div>
      <div className='page-row'>
        <div className='nte-card'>
          <h2>Best Pressure Washer for the Money</h2>
          <p>
            Looking for the best deal on your pressure washer? These pressure
            washers have been rated the best pressure washer for the money.
            Tackle countless jobs without sacrificing power all in an affordable
            price range. Whether you need to finish a job around the house or
            use for business, these pressure washers are made to handle tough
            jobs.
          </p>
        </div>
        <ul className='product-list section-row'>
          <li className='product-block nte-card'>
            <div className='content'>
              <div className='image-col'></div>
              <div className='info-col'>
                <h5>Product Card Widget</h5>
              </div>
              <div className='price-col'></div>
            </div>
          </li>
        </ul>
        <button className='large outlined centered'>
          Shop All [this Catergory]
        </button>
      </div>
      <div className='page-row'>
        <h2 className='title-block'>Recently Viewed Items</h2>
        <div className='recently-view-widget cg-cols-3'>
          <div className='nte-card'>Product</div>
          <div className='nte-card'>Product</div>
          <div className='nte-card'>Product</div>
        </div>
      </div>
    </div>
  );
};

export default CmcTemplatesPage;
