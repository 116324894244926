const BASE_URL = '/search/resources/api/v2/products';

/**
 * @interface IProductUrlParams
 */
interface IProductUrlParams {
  searchContext: string;
  storeID: string;
  parentCategoryID?: string;
  facetValue?: string;
  storeIdentifier?: string;
  soldInStoreAttributeId?: string;
  promotionAttributeId?: string;
  dealsFacet?: string;
}

const ProductServiceConstants = {
  TOP_BRANDS_URL: (categoryID: string, storeID: string) =>
    `${BASE_URL}?categoryId=${categoryID}&storeId=${storeID}&searchSource=E&profileName=HCL_ComposeFacetListByCategoryId&_wcf.search.filter.expr=buyable:true&_wcf.search.filter.expr=prices.offer.usd:(>=0.00)`,

  PRODUCT_PROMO_URL: (
    transactionContext: string,
    storeID: string,
    productID: string
  ) =>
    `${transactionContext}/store/${storeID}/associated_promotion?q=byProduct&qProductId=${productID}`,

  PRODUCT_BY_CATEGORY_URL: ({
    searchContext,
    storeID,
    parentCategoryID,
    facetValue,
    dealsFacet,
  }: IProductUrlParams) =>
    `${searchContext}/store/${storeID}/productview/byCategory/${parentCategoryID}?searchSource=E&_wcf.search.filter.expr=buyable:true&_wcf.search.filter.expr=prices.offer.usd:(>=0.00)${
      facetValue ? `${facetValue}` : ''
    }${dealsFacet ? dealsFacet : ''}`,

  PRODUCT_BY_CATEGORY_BRAND_URL: ({
    searchContext,
    storeID,
    parentCategoryID,
    facetValue,
  }: IProductUrlParams) =>
    `${searchContext}/store/${storeID}/productview/byCategory/${parentCategoryID}?searchSource=E&facet=manufacturer.raw%253A%2522${facetValue}%2522&_wcf.search.filter.expr=buyable:true&_wcf.search.filter.expr=prices.offer.usd:(>=0.00)`,

  PRODUCT_BY_STORE_IDENTIFIER: ({
    searchContext,
    storeID,
    parentCategoryID,
    storeIdentifier,
    soldInStoreAttributeId,
  }: IProductUrlParams) =>
    `${searchContext}/store/${storeID}/productview/byCategory/${parentCategoryID}?searchSource=E&facet=facets.${soldInStoreAttributeId}.value.raw%253A%2522${storeIdentifier}%2522`,

  PRIVATE_BRANDS_URL: (storeID: string) =>
    `/wcs/resources/store/${storeID}/brands/private`,

  FETCH_PRODUCTS_BY_ID: (storeID: string) =>
    `${BASE_URL}?storeId=${storeID}&langId=-1`,

  FETCH_PRODUCT_BY_PART_NUMBERS: (queryParams: any, storeID: string) =>
    `${BASE_URL}?${queryParams}&storeId=${storeID}&profileName=${process.env.REACT_APP_PROFILE_NAME_FIND_BY_PART_NUMBER}`,

  GET_SHIPPING_INFO: (
    storeID: string,
    physicalStoreId: string,
    partNumber: string
  ) => `
      /wcs/resources/store/${storeID}/storelocator/shipping?siteLevelStoreSearch=false&physicalStoreId=${physicalStoreId}&partNumber=${partNumber}`,

  GET_INVENTORY_INFO: (storeID: string) =>
    `/wcs/resources/store/${storeID}/storelocator/getStoreInventory`,

  CATALOG_ENTRY_PRODUCTS: (storeId: string, parentCatalogEntryID: string) =>
    `${BASE_URL}?storeId=${storeId}&id=${parentCatalogEntryID}`,

  FETCH_DEAL_OF_THE_DAY_PRODUCT: (storeId: string, date: string) =>
    `/wcs/resources/store/${storeId}/product/deal-of-the-day?date=${date}`,

  FETCH_CATEGORY_BY_IDENTIFIER: (storeId: string, identifier: string) =>
    `/search/resources/api/v2/categories?storeId=${storeId}&identifier=${identifier}`,
};

export { ProductServiceConstants };
