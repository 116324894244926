import {
  BaseTextFieldProps,
  FormHelperText,
  OutlinedInputProps,
  OutlinedTextFieldProps,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { LUCKY_ORANGE_CONSTANTS } from '../../../constants/LuckyOrange';
import { formatAriaLabel, formatTextWithDashes } from '../../../utils/utils';

interface TextInputProps {
  /**
   * @prop error Error flag determines whether the
   * field is in the error state.
   */
  error?: boolean;

  /**
   * @prop helperText Helper Text can be used to displaye
   * text under the field in case of error
   * during field validations.
   */
  helperText?: string;

  /**
   * @prop id of the text input field.
   */
  id: string;

  /**
   * @prop label text for text input field.
   */
  label: string;

  /**
   * @prop onChange Callback fired when the value is changed.
   *
   * @param event
   * The event source of the callback.
   * Value can be accessed by event.target.value
   */
  onChange?: OutlinedTextFieldProps['onChange'];

  /**
   * @prop placeholder text for text input field.
   */
  placeholder?: string;

  /**
   * @prop type determines the input type for text
   * input field.
   */
  type: BaseTextFieldProps['type'];

  /**
   * @prop enterKeyHint determines name of the enter key in IOS keyboard.
   */
  enterKeyHint?: string;

  /**
   * @prop pattern determines the input pattern for the text
   * input field.
   */
  pattern?: string;

  /**
   * @prop name of text field component.
   */
  name: BaseTextFieldProps['name'];

  /**
   * @prop fullWidth determines whether the component needs
   * to be rendered full width with respect to its parent.
   */
  fullWidth?: boolean;

  value?: string;

  onBlur?: (event: any) => void;

  className?: string;

  autoComplete?: BaseTextFieldProps['autoComplete'];

  /**
   * @prop Required states if the text field is required.
   */
  required?: boolean;

  /**
   * @prop disabled states whether to disable the text Input.
   */
  disabled?: boolean;

  /**
   * @prop autoFocus defines how the field should be on initial load
   */
  autoFocus?: boolean;

   /**
   * @prop autoCapitalize is used to capitalize the words, sentences and characters.
   */
  autoCapitalize?: string;

  /**
   * @prop ariaLabel Aria label for the text input field.
   */
  ariaLabel?: string;

  /**
   * @prop anchor Pass in the reference of the anchor element
   * with which the popper will be rendered as a reference.
   */
  anchor?: React.MutableRefObject<any>;

  /**
   * onFocus Callback
   */
  onFocus?: () => void;

  /**
   * maxlength for field
   */
  maxLength?: number;

  /**
   * minLength for field
   */
  minLength?: number;

  InputProps?: Partial<OutlinedInputProps>;

  onKeyPress?: (e: any) => void;
  /**
   * If set to true this will scramble the text in luckyOrange.
   */
  sensitiveData?: boolean;

  isHeaderForm?: boolean;

  helperTextprop?: string;

  keyBoardType?:
    | 'search'
    | 'none'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | undefined;
  // Living Standard
  /**
   * Hints at the type of data that might be entered by the user while editing the element or its contents
   * @see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
}

/**
 * @component TextInput is responsible for rendering
 * the text input field.
 *
 * @param props Send in the required props to render
 * the text input field.
 */
const TextInput: React.FC<TextInputProps> = ({
  error,
  helperText,
  id,
  required,
  label,
  onChange,
  anchor,
  placeholder,
  type,
  enterKeyHint,
  pattern,
  name,
  value,
  fullWidth,
  className = '',
  autoComplete,
  autoCapitalize,
  disabled,
  isHeaderForm,
  onBlur,
  autoFocus,
  ariaLabel,
  onFocus,
  maxLength,
  minLength,
  InputProps,
  sensitiveData = false,
  helperTextprop,
  keyBoardType,
  inputMode,
  ...params
}) => {
  const [shrink, setShrink] = useState(false);

  const ariaLabelValue: string = ariaLabel ? ariaLabel : formatAriaLabel(label);

  const autoCompleteText: string = autoComplete
    ? autoComplete
    : formatTextWithDashes(label);

  /**
   * @method focusHandler responsible for lifting the onFocus callback
   * and setting the state for the input field label shrink attribute.
   */
  const focusHandler = (e: any) => {
    if (onFocus) {
      onFocus();
    }

    if (shrink && !value) {
      setShrink(false);

      return;
    }

    setShrink((shrink) => !shrink);
  };

  /**
   * @method blurHandler responsible for lifting the onBlur callback
   * and setting the state for the input field label shrink attribute.
   */
  const blurHandler = (e: any): void => {
    if (onBlur) {
      onBlur(e);
    }

    setShrink((shrink) => !shrink);
  };

  return (
    <>
      <TextField
        onFocus={focusHandler}
        ref={anchor}
        disabled={disabled}
        style={isHeaderForm ? { marginBottom: 0 } : {}}
        required={required}
        error={isHeaderForm ? false : error}
        name={name}
        helperText={helperText}
        id={id}
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        className={`form-input-field ${className}`}
        type={type}
        variant='outlined'
        value={value}
        fullWidth={fullWidth}
        onBlur={blurHandler}
        autoFocus={autoFocus || false}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
          inputMode: keyBoardType || inputMode,
          enterKeyHint: enterKeyHint,
          autoCapitalize: autoCapitalize,
          pattern: pattern,
          'aria-label': ariaLabelValue,
          label: ariaLabelValue,
          'aria-autocomplete': autoCompleteText ? 'list' : 'none',
          className: `${sensitiveData ? LUCKY_ORANGE_CONSTANTS.SENSITIVE_DATA : ''
            }`,
          
        }}
        InputProps={{
          ...InputProps,
        }}
        InputLabelProps={{ shrink: Boolean(value) || shrink }}
        {...params}
        autoComplete={autoCompleteText}
      />
      {isHeaderForm && (
        <FormHelperText error={error} style={{ marginInlineStart: 16 }}>
          {error ? helperTextprop : ''}
        </FormHelperText>
      )}
    </>
  );
};

export { TextInput };
