import React from 'react';
import './scss/Badge.scss';

export interface BadgeProps {
  /**
   * Text to display
   */
  label: string | Array<string>;

  /**
   * color of the badge
   */
  bgColor?: string;

  /**
   * color of the badge
   */
  className?: string;

  removeDefaultBadge?: boolean;
}

/**
 * component is responsible for rendering badge with label and color
 */
const Badge: React.FC<BadgeProps> = ({
  label,
  bgColor,
  className = '',
  removeDefaultBadge = false,
}) => {
  /**
   * setting the default backgroundColor
   */
  if (!bgColor) {
    bgColor = '#e5e5e5';
  }

  return (
    <span
      className={`${!removeDefaultBadge && 'badge'} ${className}`}
      style={{ backgroundColor: bgColor }}>
      {label}
    </span>
  );
};

export { Badge };
