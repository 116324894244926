export const GET_PICKUP_PERSON = 'GET_PICKUP_PERSON';
export const GET_PICKUP_PERSON_SUCCESS = 'GET_PICKUP_PERSON_SUCCESS';
export const ADD_PICKUP_PERSON = 'ADD_PICKUP_PERSON';
export const ADD_PICKUP_PERSON_SUCCESS = 'ADD_PICKUP_PERSON_SUCCESS';
export const DELETE_PICKUP_PERSON = 'DELETE_PICKUP_PERSON';
export const DELETE_PICKUP_PERSON_SUCCESS = 'DELETE_PICKUP_PERSON_SUCCESS';
export const UPDATE_PICKUP_PERSON = 'UPDATE_PICKUP_PERSON';
export const UPDATE_PICKUP_PERSON_SUCCESS = 'UPDATE_PICKUP_PERSON_SUCCESS';
export const GET_PICKUP_PERSON_REVIEW_SUCCESS =
  'GET_PICKUP_PERSON_REVIEW_SUCCESS';
export const GET_PICKUP_PERSON_CONFIRMATION_SUCCESS =
  'GET_PICKUP_PERSON_CONFIRMATION_SUCCESS';
