import axios, { AxiosResponse } from 'axios';
import { site } from '../../constants/site';
import validateISeoResponse from '../../interface/Responses/ISeoResponse.validator';
import { SeoConstants } from './seo.constants';

/**
 * @interface IFetchSEOParam
 */
interface IFetchSEOParam {
  seoHref: string;
  storeID: string;
}

const seoService = {
  /**
   * @method fetchSEO Fetches the seo config for a specific seoHref.
   *
   * @param IFetchSEOParam
   */
  async fetchSEO({ seoHref, storeID }: IFetchSEOParam): Promise<AxiosResponse> {
    const { SEO_URL } = SeoConstants;

    const seoUrl = SEO_URL(
      storeID,
      seoHref.replace(/\//g, ''),
      site.enrichedPlpUrl
    );

    try {
      const seoResponse = await axios.get(seoUrl);

      validateISeoResponse(seoResponse.data);

      return seoResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { seoService };
