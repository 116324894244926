import { createAction } from '@reduxjs/toolkit';
import { IGetSeoActionSuccess } from '../../../../_foundation/interface/Seo/ISeo';
import * as ACTIONTYPES from '../action-types/widgets-info';

const RESET_WIDGET_INFO = createAction(ACTIONTYPES.RESET_WIDGET_INFO);

const SET_WIDGET_INFO = createAction<IGetSeoActionSuccess>(
  ACTIONTYPES.SET_WIDGET_INFO
);

export { SET_WIDGET_INFO, RESET_WIDGET_INFO };
