import { createReducer, current } from '@reduxjs/toolkit';
import {
  IGiftItem,
  IGiftItemIds,
  IGiftList,
  IGiftListIds,
  IPartnumberQuantityMap,
  IShareListSuccess,
  IWishListProducts,
  IWishListResponse,
  IWishListState,
} from '../../_foundation/interface/WishList/IWishList';
import {
  CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION,
  FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION,
  GET_WISH_LIST_SUCCESS_ACTION,
  REFRESH_WISH_LIST_PLP_ACTION,
  RESET_SHARE_WISH_LIST_ACTION,
  RESET_WISH_LIST_ACTION,
  SET_CURRENT_GIFT_LIST_ID_ACTION,
  SET_WISH_LIST_PLP_LOADING_ACTION,
  SHARE_WISH_LIST_SUCCESS_ACTION,
  TOGGLE_WISH_LIST_PLP_PAGES_ACTION,
  WISH_LIST_LOADING_ACTION,
} from '../actions/wish-list.actions';

const initWishListState: IWishListState = {
  giftList: [],
  saveItemsList: [],
  loading: true,
  wishListProducts: {},
  isSuccess: false,
  recipientEmail: '',
  giftItemIds: {},
  giftListIds: {},
  partNumberQuantityMap: {},
  wishListProductPage: {
    wishListProducts: [],
    loading: true,
    wishListPlpItems: [],
    totalNoOfProducts: -1,
    giftListIds: {},
    pageNumber: '',
    pageSize: '',
    currentPage: 0,
    pageLoading: false,
    giftListId: '',
    sharedListName: '',
  },
};

const wishListReducer = createReducer(initWishListState, (builder) => {
  /**
   * CASE GET_WISH_LIST_SUCCESS_ACTION
   * Fetches the wish list response from saga effects and updates the redux store.
   */
  builder.addCase(
    GET_WISH_LIST_SUCCESS_ACTION,
    (state: IWishListState, action: any) => {
      const { GiftList } = action.payload as IWishListResponse;

      const saveItemsList: string[] = [];

      let giftItemIds: IGiftItemIds = {};

      let giftListIds: IGiftItemIds = {};

      let partNumberQuantityMap: IPartnumberQuantityMap = {};

      /**
       * Gift Item id's and wishlist id's are stored with that item's current productId
       * combination as a key, to easily extract the giftItemId / wishlistid
       * associated with product id.
       */
      GiftList?.forEach(({ item, uniqueID }) => {
        item?.forEach(({ productId, giftListItemID, partNumber , quantityRequested}) => {
          giftListIds = { ...giftListIds, [productId]: uniqueID };

          giftItemIds = { ...giftItemIds, [productId]: giftListItemID };

          partNumberQuantityMap = { ...partNumberQuantityMap, [partNumber]: quantityRequested};
        });
      });

      return {
        ...state,
        giftList: GiftList,
        saveItemsList,
        loading: false,
        giftItemIds,
        giftListIds,
        partNumberQuantityMap,
      };
    }
  );

  /**
   * CASE FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION
   * Updates the wish list products response in the redux store.
   */
  builder.addCase(
    FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION,
    (state: IWishListState, action: any) => {
      const { contents, total, currentItemId } = action.payload;

      if (total > 0 && currentItemId) {
        const { wishListProducts } = current(state);

        const updatedWishListProducts: IWishListProducts = {
          ...wishListProducts,
          [currentItemId]: contents,
        };

        return { ...state, wishListProducts: updatedWishListProducts };
      }
    }
  );

  /**
   * CASE WISH_LIST_LOADING_ACTION
   * Updates the wishlist loading state in redux.
   */
  builder.addCase(
    WISH_LIST_LOADING_ACTION,
    (state: IWishListState, action: any) => {
      return { ...state, loading: action.payload };
    }
  );

  /**
   * CASE RESET_WISH_LIST_ACTION
   * Resets the wish list state.
   */
  builder.addCase(RESET_WISH_LIST_ACTION, (state: IWishListState) => {
    return {
      ...initWishListState,
      wishListProductPage: { ...state.wishListProductPage, pageLoading: true },
    };
  });

  /**
   * CASE SHARE_WISH_LIST_SUCCESS_ACTION
   * Adds the share wishlist response state to the redux store.
   */
  builder.addCase(
    SHARE_WISH_LIST_SUCCESS_ACTION,
    (state: IWishListState, action: any) => {
      const { isSuccess, recipientEmail } = action.payload as IShareListSuccess;

      return { ...state, isSuccess, recipientEmail };
    }
  );

  /**
   * CASE RESET_SHARE_WISH_LIST_ACTION
   * Removes the share wishlist response state from the redux store.
   */
  builder.addCase(RESET_SHARE_WISH_LIST_ACTION, (state: IWishListState) => {
    return { ...state, isSuccess: false, recipientEmail: '' };
  });

  /**
   * CASE FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION
   * Fetches the wish list products and adds it to redux store.
   */
  builder.addCase(
    FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION,
    (state: IWishListState, action: any) => {
      const { contents } = action.payload;
      let partNumberQuantityMap: IPartnumberQuantityMap = {};
      const {
        wishListProductPage: { wishListProducts , wishListPlpItems},
      } = current(state);

      wishListPlpItems?.forEach(({ partNumber, quantityRequested }) => {

          partNumberQuantityMap = { ...partNumberQuantityMap, [partNumber]: quantityRequested};
        });

      return {
        ...state,
        partNumberQuantityMap : partNumberQuantityMap,
        wishListProductPage: {
          ...state.wishListProductPage,
          wishListProducts: [...wishListProducts, contents],
          loading: false,
          wishListPlpItems: [],
          pageLoading: false,
        },
      };
    }
  );

  /**
   * CASE CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION
   * Clears the wish list products store state.
   */
  builder.addCase(
    CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION,
    (state: IWishListState) => {
      return {
        ...state,
        wishListProductPage: initWishListState.wishListProductPage,
      };
    }
  );

  /**
   * CASE FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION
   * Stores the wishlist items for the current plp request.
   */
  builder.addCase(
    FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION,
    (state: IWishListState, action: any) => {
      const wishListResponse = action.payload as IWishListResponse;

      const { pageNumber, pageSize } = wishListResponse;

      if (wishListResponse?.GiftList) {
        const { item, descriptionName } = wishListResponse?.GiftList[0];

        const {
          wishListProductPage: { giftListIds },
        } = current(state);

        let updatedGiftListIds: IGiftListIds = { ...giftListIds };

        wishListResponse?.GiftList.forEach((giftList: IGiftList) => {
          giftList?.item?.forEach((giftItem: IGiftItem) => {
            /**
             * Gift Item id's are stored with that item's current productId
             * combination as a key, to easily extract the giftItemId
             * associated with product id under a specific giftListId.
             */
            updatedGiftListIds = {
              ...updatedGiftListIds,
              [giftItem.productId]: giftItem.giftListItemID,
            };
          });
        });

        const totalNoOfProducts = updatedGiftListIds ? Object.keys(updatedGiftListIds).length : 0;

        return {
          ...state,
          wishListProductPage: {
            ...state.wishListProductPage,
            wishListPlpItems: item ? item : [],
            totalNoOfProducts,
            giftListIds: updatedGiftListIds,
            pageNumber: pageNumber ? pageNumber : '',
            pageSize: pageSize ? pageSize : '',
            loading: pageNumber ? Number(pageNumber) === 1 : false,
            pageLoading: true,
            sharedListName: descriptionName,
          },
        };
      } else {
        return {
          ...state,
          wishListProductPage: {
            ...state.wishListProductPage,
            wishListPlpItems: [],
            totalNoOfProducts: 0,
            loading: false,
          },
        };
      }
    }
  );

  /**
   * CASE TOGGLE_WISH_LIST_PLP_PAGES_ACTION
   * Toggles the wishlist plp pages.
   */
  builder.addCase(
    TOGGLE_WISH_LIST_PLP_PAGES_ACTION,
    (state: IWishListState) => {
      const { wishListProductPage } = current(state);

      const { currentPage } = wishListProductPage;

      const updatedCurrentPage = currentPage + 1;

      return {
        ...state,
        wishListProductPage: {
          ...wishListProductPage,
          currentPage: updatedCurrentPage,
        },
      };
    }
  );

  /**
   * CASE REFRESH_WISH_LIST_PLP_ACTION
   * Refreshes the plp wish list state.
   */
  builder.addCase(REFRESH_WISH_LIST_PLP_ACTION, (state: IWishListState) => {
    return {
      ...state,
      wishListProductPage: initWishListState.wishListProductPage,
    };
  });

  /**
   * CASE SET_WISH_LIST_PLP_LOADING_ACTION
   * Sets the loading state for the wish list plp.
   */
  builder.addCase(
    SET_WISH_LIST_PLP_LOADING_ACTION,
    (state: IWishListState, action: any) => {
      const { wishListProductPage } = current(state);

      return {
        ...state,
        wishListProductPage: {
          ...wishListProductPage,
          loading: action.payload,
        },
      };
    }
  );

  /**
   * CASE SET_CURRENT_GIFT_LIST_ID_ACTION
   * Sets the current gift id, this can be used scenarios where
   * we know that we need to display a specific wishlist related products.
   */
  builder.addCase(
    SET_CURRENT_GIFT_LIST_ID_ACTION,
    (state: IWishListState, action: any) => {
      return {
        ...state,
        wishListProductPage: {
          ...state.wishListProductPage,
          giftListId: action.payload,
          loading: false,
        },
      };
    }
  );
});

export { wishListReducer };
