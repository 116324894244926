import {
  CircularProgress,
  Hidden,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { STORE_INDEX } from '../../../../../../../constants/routes';
import {
  FETCH_SEARCH_STORES_FAILURE_ACTION,
  FETCH_STORES_LOADER_ACTION,
  GET_STORES_ACTION,
  GET_US_STORES_ACTION,
  RESET_SEARCH_STORES_FAILURE_ACTION,
} from '../../../../../../../redux/actions/storeLocator';
import {
  storeLocatorFailSelector,
  storeLocatorSearchSelector,
  storeLocatorSelector,
} from '../../../../../../../redux/selectors/storeLocator';
import { useSearchStores } from '../../../../../../../utils/hooks/StoreLocator/search-stores/search-stores';
import {
  MY_STORE_COOKIE,
  NEAR_BY_STORES,
} from '../../../../../../../_foundation/constants/cookie';
import { UNITED_STATES_COUNTRY_CODE } from '../../../../../../../_foundation/constants/countryCodes';
import { useSite } from '../../../../../../../_foundation/hooks/usesite/useSite';
import {
  MyStoreCookie,
  PhysicalStoreDetails,
} from '../../../../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { NteAlert } from '../../../../../../NteAlert/NteAlert';
import { MenuListHeader } from '../../../../../Menu/MenuListHeader/MenuListHeader';
import { NteGoogleMap } from '../../../../../NteGoogleMap/NteGoogleMap';
import { ScreenReaderOnly } from '../../../../../ScreenReaderOnly/ScreenReaderOnly';
import { useSearchBar } from '../../../../../SearchBar/hooks/SearchBarHooks';
import { SearchBar } from '../../../../../SearchBar/SearchBar';
import { TabPanel } from '../../../../../TabPanel/TabPanel';
import { NearByStoreTitle } from './NearByStoreTile/NearByStoreTile';
import { StoreFinderConstants } from './StoreFinderConstants';

interface Props {
  /**
   * @prop menuTitle for store finder menu.
   */
  menuTitle: string;

  /**
   * @prop showBack flag is responsible for displaying back
   * button in the StoreFinder menu.
   */
  showBack?: boolean;

  /**
   * @prop storeFinderBackHandler handles the back button events
   * in the store finder menu.
   */
  storeFinderBackHandler?: () => void;

  /**
   * @prop storeFinderCloseHandler handles the store finder's
   * close button events.
   */
  storeFinderCloseHandler?: () => void;

  /**
   * @Prop optional sub header
   */
  subHeader?: string;

  /**
   * @Prop part number of the product/item
   */
  partNumber?: string;

  /**
   * @prop orderId for an order
   */
  orderId?: string;

  /**
   * @prop onNavbar for check if it is called from Navbar
   * and render loader only if this component is opened from Navbar
   */
  onNavbar?: boolean;

  /**
   * storepickup section
   */
  storePickup?: boolean;

  setNearbyStoreData?: (nearbyStoreData: PhysicalStoreDetails) => void;

  setStoreValue?: (storeValue: string) => void;

  setStoreSelected?: (storeSelected: boolean) => void;

  isStoreLocator?: boolean;

  setIsInitialSelectPickup?: (isInitialSelectPickup: boolean) => void;
}

/**
 * @component StoreFinder is responsible for rendering the StoreFinder
 * menu inside MyStore popper component.
 */
const StoreFinder: React.FC<Props> = ({
  menuTitle,
  showBack,
  storeFinderBackHandler,
  storeFinderCloseHandler,
  subHeader,
  partNumber,
  orderId,
  storePickup = false,
  setNearbyStoreData,
  setStoreValue,
  setStoreSelected,
  isStoreLocator,
  setIsInitialSelectPickup,
  onNavbar,
}) => {
  const {
    SEARCH_BAR_PLACEHOLDER,
    STORE_FINDER_REQUEST_LENGTH,
    ACCORDION,
    ERROR_MESSAGE,
    VIEW_STORE_INDEX,
    STORE_PICKUP_INSTRUCTION,
    NO_STORE_INVENTORY_AVAILALE,
    STORE_FINDER_GUIDE,
    STORE_FINDER_RESULT,
    STORE_LOCATOR_GENERIC_ERROR,
    SEARCH_BAR_PLACEHOLDER_FALLBACK,
  } = StoreFinderConstants;

  const { mySite } = useSite();

  const storeRadius = mySite
    ? mySite.storeLocatorDefaultRadius
    : process.env.REACT_APP_DEFAULT_STORE_LOCATOR_RADIUS;

  const { currentValue, searchBarOnChangeHandler, setCurrentValue } =
    useSearchBar('');

  const { searchLoader, searchedStoreDetails } = useSelector(
    storeLocatorSearchSelector
  );

  const { isInventoryFailed, isStoreLocatorApiThrow500 } = useSelector(
    storeLocatorFailSelector
  );

  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [isUSStoreDataLoaded, setIsUSStoreDataLoaded] = useState(false);

  const a11yProps = (index: number) => {
    return {
      id: `nte-tab-${index}`,
      'aria-controls': `nte-tabpanel-${index}`,
    };
  };

  const {
    storeDetails,
    loading,
    usStoreDetails,
    fetchStoresLoading,
    isNoStoresAvailable,
    currentStoreDetails: anchorStore,
  } = useSelector(storeLocatorSelector);

  let nearByStoreDetails: any;

  if (storeDetails.length === 0) {
    const nearByStoreSession = sessionStorage.getItem(NEAR_BY_STORES);

    nearByStoreDetails = nearByStoreSession
      ? JSON.parse(nearByStoreSession)
      : [];
  } else {
    nearByStoreDetails = storeDetails ? JSON.stringify(storeDetails) : [];
  }

  const { currentStoreDetails, makeMyStoreClickHandler } = useSearchStores({
    currentAddress: currentValue,
    storeDetails:
      storeDetails && storeDetails.length > 0
        ? storeDetails
        : nearByStoreDetails,
    searchedStoreDetails,
    storeFinderCloseHandler,
    partNumber,
    orderId,
  });

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const noNortherntoolStoresforZipcode = `${t(
    ERROR_MESSAGE.NO_NORTHERN_TOOL_STORES_ZIPCODE,
    {
      storeRadius,
      currentValue,
    }
  )}`;

  const noStoresForLocation = `${t(
    ERROR_MESSAGE.NO_NORTHERN_TOOL_STORES_LOCATION,
    {
      storeRadius,
    }
  )}`;

  const storeIndexLink = (
    <Link onClick={storeFinderCloseHandler} to={STORE_INDEX}>
      {VIEW_STORE_INDEX}
    </Link>
  );

  let noOfItems = mySite
    ? parseInt(mySite.storeLocatorResponseCount)
    : parseInt(process.env.REACT_APP_DEFAULT_STORE_LOCATOR_LIMIT as string);

  let storeDetailsList = currentStoreDetails?.slice(0, noOfItems);

  const noStoresFoundForZipcode =
    currentValue.length >= STORE_FINDER_REQUEST_LENGTH &&
    !isInventoryFailed &&
    !isStoreLocatorApiThrow500 &&
    searchedStoreDetails.length === 0 &&
    !searchLoader;

  const noStoresFoundForYourLocation =
    !searchLoader &&
    !loading &&
    currentValue.length === 0 &&
    !isInventoryFailed &&
    !isStoreLocatorApiThrow500 &&
    !anchorStore;

  const noStoresFoundForYourLocationForInventory =
    !searchLoader &&
    !loading &&
    currentValue.length === 0 &&
    (partNumber || orderId) &&
    !isInventoryFailed &&
    !isStoreLocatorApiThrow500 &&
    storeDetails?.length === 0;

  const isOrderOrPartnumberNotavailable = !orderId || !partNumber;

  /**
   * This method is responsible for closing the 'Change My Store' menu.
   */

  const storeCloseHandler = () => {
    if (currentStoreDetails && storeFinderBackHandler) {
      storeFinderBackHandler();
      return;
    }
  };

  /**
   * Responsible to fetch the US stores when no anchor store is available
   */
  const fetchUsStores = useCallback((): void => {
    if (
      mySite?.storeID &&
      noStoresFoundForYourLocation &&
      usStoreDetails.length === 0 &&
      isOrderOrPartnumberNotavailable
    ) {
      dispatch(
        GET_US_STORES_ACTION({
          storeID: mySite?.storeID,
          country: UNITED_STATES_COUNTRY_CODE,
        })
      );
    }
  }, [
    dispatch,
    isOrderOrPartnumberNotavailable,
    mySite?.storeID,
    noStoresFoundForYourLocation,
    usStoreDetails.length,
  ]);

  const cookieStore = myStoreCookie && !nearByStoreDetails && mySite;

  useEffect(() => {
    fetchUsStores();
  }, [fetchUsStores]);

  useEffect(() => {
    if (isInventoryFailed && !currentValue) {
      dispatch(FETCH_SEARCH_STORES_FAILURE_ACTION(false));
    }
  }, [currentValue, dispatch, isInventoryFailed]);

  useEffect(() => {
    if (cookieStore && mySite && !fetchStoresLoading && !isNoStoresAvailable) {
      if (
        myStoreCookie &&
        JSON.parse(myStoreCookie).latitude &&
        JSON.parse(myStoreCookie).longitude
      ) {
        dispatch(FETCH_STORES_LOADER_ACTION(true));

        dispatch(RESET_SEARCH_STORES_FAILURE_ACTION());

        const parsedStoreCookie: MyStoreCookie = JSON.parse(myStoreCookie);

        dispatch(
          GET_STORES_ACTION({
            storeID: mySite.storeID,
            latitude: parsedStoreCookie.latitude,
            longitude: parsedStoreCookie.longitude,
            radius: mySite.storeLocatorDefaultRadius,
          })
        );
      }
    }
  }, [
    cookieStore,
    dispatch,
    fetchStoresLoading,
    isNoStoresAvailable,
    mySite,
    myStoreCookie,
    nearByStoreDetails,
  ]);

  /**
   * Below useEffect is to trigger a loader to only enable
   * search input once all store data is loaded
   */
  useEffect(() => {
    if (usStoreDetails?.length > 0 || currentStoreDetails?.length > 0) {
      setIsUSStoreDataLoaded(true);
    }
  }, [currentStoreDetails, usStoreDetails]);

  const myStore = sessionStorage.getItem(MY_STORE_COOKIE);

  const currentStoreName = myStore && JSON.parse(myStore)?.storeName;

  const renderStoreDetails =
    (currentStoreName || currentValue.length >= STORE_FINDER_REQUEST_LENGTH) &&
    storeDetailsList &&
    typeof storeDetailsList !== 'string' &&
    storeDetailsList.length !== 0;

  const searchBarPlaceholder = i18n.exists(SEARCH_BAR_PLACEHOLDER).valueOf()
    ? t(SEARCH_BAR_PLACEHOLDER)
    : SEARCH_BAR_PLACEHOLDER_FALLBACK;

  return (
    <div className='store_finder' role='region' aria-label='location filter'>
      <MenuListHeader
        showBack={showBack}
        backHandler={storeFinderBackHandler}
        closeHandler={storeFinderCloseHandler}
        menuTitle={menuTitle}
        id='stock-locator-header'
      />
      {!isUSStoreDataLoaded && onNavbar ? (
        <CircularProgress
          className='prompt-margin-top'
          size={24}
          color='primary'
        />
      ) : (
        <>
          <ScreenReaderOnly message={STORE_FINDER_GUIDE} />

          <SearchBar
            value={currentValue}
            onChange={searchBarOnChangeHandler}
            label={searchBarPlaceholder}
            setCurrentValue={setCurrentValue}
            autoComplete={'postal-code address-level2 address-level1'}
          />

          <div aria-live='assertive'>
            {storeDetailsList?.length > 0 && (
              <ScreenReaderOnly
                message={`${storeDetailsList?.length} ${STORE_FINDER_RESULT}`}
              />
            )}
          </div>

          <Hidden smUp>
            <div className={isStoreLocator ? 'nte-card' : ''}>
              {isStoreLocator && (
                <Tabs
                  value={value}
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={handleChange}
                  aria-label='nte-tabs'>
                  <Tab label='List' {...a11yProps(0)} />

                  <Tab label='Map' {...a11yProps(1)} />
                </Tabs>
              )}

              <TabPanel value={value} index={0}>
                {searchLoader || fetchStoresLoading || loading ? (
                  <CircularProgress
                    className='prompt-margin-top'
                    size={24}
                    color='primary'
                  />
                ) : (
                  <>
                    {isStoreLocatorApiThrow500 &&
                    Boolean(partNumber || orderId) ? (
                      <NteAlert
                        severity='error'
                        messages={[`${NO_STORE_INVENTORY_AVAILALE}`]}
                        showClose={false}
                      />
                    ) : (
                      <>
                        {isStoreLocatorApiThrow500 && (
                          <NteAlert
                            severity='error'
                            messages={[`${STORE_LOCATOR_GENERIC_ERROR}`]}
                            showClose={false}
                          />
                        )}
                      </>
                    )}

                    {isInventoryFailed && (
                      <NteAlert
                        severity='warning'
                        messages={[
                          noNortherntoolStoresforZipcode,
                          storeIndexLink,
                        ]}
                        showClose={false}
                      />
                    )}

                    {renderStoreDetails &&
                    !isInventoryFailed &&
                    !isStoreLocatorApiThrow500 ? (
                      <ul className='stores block-list prompt-margin-top'>
                        {storeDetailsList.map(
                          (
                            storeDetails: PhysicalStoreDetails,
                            index: number
                          ) => (
                            <li key={index} className='block-list-item'>
                              <NearByStoreTitle
                                makeMyStoreClickHandler={
                                  makeMyStoreClickHandler
                                }
                                storeDetails={storeDetails}
                                showInventory={Boolean(partNumber || orderId)}
                                displayStoreDetailsMode={ACCORDION}
                                displayAllStoreDetails={true}
                                storePickup={storePickup}
                                storeFinderCloseHandler={
                                  storeFinderCloseHandler
                                }
                                setNearbyStoreData={setNearbyStoreData}
                                setStoreValue={setStoreValue}
                                setStoreSelected={setStoreSelected}
                                setIsInitialSelectPickup={
                                  setIsInitialSelectPickup
                                }
                              />
                            </li>
                          )
                        )}
                        {storePickup && (
                          <li>
                            <Typography variant='body1'>
                              {t(STORE_PICKUP_INSTRUCTION)}
                            </Typography>
                          </li>
                        )}
                      </ul>
                    ) : (
                      <>
                        {noStoresFoundForZipcode && (
                          <NteAlert
                            severity='warning'
                            messages={[
                              noNortherntoolStoresforZipcode,
                              storeIndexLink,
                            ]}
                            showClose={false}
                          />
                        )}

                        {((noStoresFoundForYourLocation &&
                          isOrderOrPartnumberNotavailable) ||
                          noStoresFoundForYourLocationForInventory) && (
                          <NteAlert
                            severity='warning'
                            messages={[noStoresForLocation, storeIndexLink]}
                            showClose={false}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </TabPanel>
              {isStoreLocator && (
                <TabPanel value={value} index={1}>
                  <NteGoogleMap storeFinderCloseHandler={storeCloseHandler} />
                </TabPanel>
              )}
            </div>
          </Hidden>

          {subHeader && (
            <Typography variant='h3' className='title-bar prompt-margin-top'>
              {subHeader}
            </Typography>
          )}

          <Hidden xsDown>
            {searchLoader || fetchStoresLoading || loading ? (
              <CircularProgress
                className='prompt-margin-top'
                size={24}
                color='primary'
              />
            ) : (
              <>
                {isStoreLocatorApiThrow500 && Boolean(partNumber || orderId) ? (
                  <NteAlert
                    severity='error'
                    messages={[`${NO_STORE_INVENTORY_AVAILALE}`]}
                    showClose={false}
                  />
                ) : (
                  <>
                    {isStoreLocatorApiThrow500 && (
                      <NteAlert
                        severity='error'
                        messages={[`${STORE_LOCATOR_GENERIC_ERROR}`]}
                        showClose={false}
                      />
                    )}
                  </>
                )}
                {isInventoryFailed && (
                  <NteAlert
                    severity='warning'
                    messages={[noNortherntoolStoresforZipcode, storeIndexLink]}
                    showClose={false}
                  />
                )}

                {renderStoreDetails &&
                !isInventoryFailed &&
                !isStoreLocatorApiThrow500 ? (
                  <ul className='stores block-list prompt-margin-top'>
                    {storeDetailsList.map(
                      (storeDetails: PhysicalStoreDetails, index: number) => (
                        <li key={index} className='block-list-item'>
                          <NearByStoreTitle
                            makeMyStoreClickHandler={makeMyStoreClickHandler}
                            storeDetails={storeDetails}
                            showInventory={Boolean(partNumber || orderId)}
                            displayStoreDetailsMode={ACCORDION}
                            displayAllStoreDetails={true}
                            storePickup={storePickup}
                            storeFinderCloseHandler={storeFinderCloseHandler}
                            setNearbyStoreData={setNearbyStoreData}
                            setStoreValue={setStoreValue}
                            setStoreSelected={setStoreSelected}
                            setIsInitialSelectPickup={setIsInitialSelectPickup}
                          />
                        </li>
                      )
                    )}
                    {storePickup && (
                      <li>
                        <Typography variant='body1'>
                          {t(STORE_PICKUP_INSTRUCTION)}
                        </Typography>
                      </li>
                    )}
                  </ul>
                ) : (
                  <>
                    {noStoresFoundForZipcode && (
                      <NteAlert
                        severity='warning'
                        messages={[
                          noNortherntoolStoresforZipcode,
                          storeIndexLink,
                        ]}
                        showClose={false}
                      />
                    )}

                    {((noStoresFoundForYourLocation &&
                      isOrderOrPartnumberNotavailable) ||
                      noStoresFoundForYourLocationForInventory) && (
                      <NteAlert
                        severity='warning'
                        messages={[noStoresForLocation, storeIndexLink]}
                        showClose={false}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Hidden>
        </>
      )}
    </div>
  );
};

export { StoreFinder };
