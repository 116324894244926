const CategoryCarouselConstants = {
  VISIBLE_SLIDES: {
    sm: 5,
    md: 6,
    lg: 7,
    xl: 7,
    xs: 2,
  },
  ADVANTAGE_EXCLUSIVE: 'Advantage Exclusives',
  CATEGORY_FACET: 'ParentCatalogGroup',
};

export { CategoryCarouselConstants };
