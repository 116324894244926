import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import React, { useEffect, useRef, useState } from 'react';

/**
 * @interface MapProps
 */
interface MapProps extends google.maps.MapOptions {
  //style props for Map
  style?: { [key: string]: string };

  /**
   * Set custom className.
   */
  className?: string;
}

/**
 * @component Map renders the Map component.
 */
const Map: React.FC<MapProps> = ({
  children,
  style,
  className = '',
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();

  //use of type as any because - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
  const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }

      return deepEqual(a, b);
    }
  );

  const useDeepCompareMemoize = (value: any) => {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  };

  const useDeepCompareEffectForMaps = (
    callback: React.EffectCallback,
    dependencies: any[]
  ) => {
    useEffect(callback, [callback, ...dependencies.map(useDeepCompareMemoize)]);
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={style} className={className} />
      {map &&
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            return React.cloneElement<any>(child, { map });
          }
        })}
    </>
  );
};

export { Map };
