import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { CheckoutFlows } from '../../../components/Checkout/enum/Checkout';
import { DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION } from '../../../components/Checkout/redux/actions/checkout.actions';
import { is500SeriesError } from '../../../utils/utils';
import { storeLocatorService } from '../../../_foundation/apis/storeLocator/storeLocator.service';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import {
  FETCH_SEARCH_STORES_FAILURE_ACTION,
  FETCH_STORES_LOADER_ACTION,
  FETCH_STORES_THROWS_500_ACTION,
  GET_STORES_SUCCESS_ACTION,
  GET_US_STORES_SUCCESS_ACTION,
  RESET_SEARCH_STORES_FAILURE_ACTION,
  RESET_STORE_SEARCH_ACTION,
  SEARCH_STORE_SUCCESS_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
  STORE_PICKUP_INVENTORY_SUCCESS_ACTION,
  STORE_SEARCH_LOADER_ACTION,
  UPDATE_STORE_PICKUP_LOADING_STATE_ACTION,
} from '../../actions/storeLocator';

export function* getStores(action: any): Generator {
  try {
    const response = yield call(
      storeLocatorService.fetchStores,
      action.payload
    );

    yield put(GET_STORES_SUCCESS_ACTION(response));

    yield put(FETCH_STORES_LOADER_ACTION(false));


    yield put(RESET_SEARCH_STORES_FAILURE_ACTION());

    /**
     * Action to reset search loading on success response from back end
     */
    yield put(RESET_STORE_SEARCH_ACTION());

  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    yield put(RESET_STORE_SEARCH_ACTION());

    if (is500SeriesError(axiosError)) {
      yield put(FETCH_STORES_THROWS_500_ACTION(true));
    } else {
      yield put(FETCH_SEARCH_STORES_FAILURE_ACTION(true));
    }

    yield put(STORE_SEARCH_LOADER_ACTION(false));

    yield put(FETCH_STORES_LOADER_ACTION(false));

    yield put(UPDATE_STORE_PICKUP_LOADING_STATE_ACTION(false))
  }
}

export function* getUsStores(action: any): Generator {
  try {
    const storeDetails: any = yield call(
      storeLocatorService.fetchStores,
      action.payload
    );

    yield put(GET_US_STORES_SUCCESS_ACTION(storeDetails));


    /**
     * Action to reset search loading on success response from back end
     */
    yield put(RESET_STORE_SEARCH_ACTION());
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    yield put(RESET_STORE_SEARCH_ACTION());

    if (is500SeriesError(axiosError)) {
      yield put(FETCH_STORES_THROWS_500_ACTION(true));
    } else {
      yield put(FETCH_SEARCH_STORES_FAILURE_ACTION(true));
    }

    yield put(STORE_SEARCH_LOADER_ACTION(false));

    yield put(FETCH_STORES_LOADER_ACTION(false));

    yield put(UPDATE_STORE_PICKUP_LOADING_STATE_ACTION(false))
  }
}

export function* getStoresByAddress(action: any): Generator {
  try {
    const response = yield call(
      storeLocatorService.fetchStoresByAddress,
      action.payload
    );

    yield put(RESET_SEARCH_STORES_FAILURE_ACTION());

    yield put(SEARCH_STORE_SUCCESS_ACTION(response));


  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    if (is500SeriesError(axiosError)) {
      yield put(FETCH_STORES_THROWS_500_ACTION(true));
    } else {
      yield put(FETCH_SEARCH_STORES_FAILURE_ACTION(true));
    }

    yield put(STORE_SEARCH_LOADER_ACTION(false));

    yield put(FETCH_STORES_LOADER_ACTION(false));

    yield put(UPDATE_STORE_PICKUP_LOADING_STATE_ACTION(false))
  }
}

export function* fetchStorePickUpInventory(action: any): Generator {
  try {
    const response: any = yield call(
      storeLocatorService.fetchStoresByAddress,
      action.payload
    );

    yield put(RESET_SEARCH_STORES_FAILURE_ACTION());

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(STORE_PICKUP_INVENTORY_SUCCESS_ACTION(response));

    
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    if (is500SeriesError(axiosError)) {
      yield put(FETCH_STORES_THROWS_500_ACTION(true));
    } else {
      yield put(FETCH_SEARCH_STORES_FAILURE_ACTION(true));
    }

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(STORE_SEARCH_LOADER_ACTION(false));

    yield put(FETCH_STORES_LOADER_ACTION(false));
    
    yield put(UPDATE_STORE_PICKUP_LOADING_STATE_ACTION(false))
  }
}

export function* fetchStorePickUpInventoryLatAndLong(action: any): Generator {
  try {
    const response: any = yield call(
      storeLocatorService.fetchStores,
      action.payload
    );

    yield put(RESET_SEARCH_STORES_FAILURE_ACTION());

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        DELIVERY_STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION(response)
      );
    } else {
      yield put(STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION(response));
    }

  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    if (is500SeriesError(axiosError)) {
      yield put(FETCH_STORES_THROWS_500_ACTION(true));
    } else {
      yield put(FETCH_SEARCH_STORES_FAILURE_ACTION(true));
    }

    
    yield put(FETCH_STORES_LOADER_ACTION(false));

    yield put(STORE_SEARCH_LOADER_ACTION(false));

    yield put(UPDATE_STORE_PICKUP_LOADING_STATE_ACTION(false));
  }
}
