/**
 * FieldFormatting Utility Allows Form fields to be formatted as the user types the values.
 */

const FieldFormatting = {
  // Zip code formatting to add a '-' when the zip code is longer than 5 characters.
  ZIP_CODE_US: (value: string) => {
    if (!value) return value;

    const formattedValue = value.replace(/-/g, '');

    if (formattedValue.length > 5) {
      return `${formattedValue.substring(0, 5)}-${formattedValue.substring(
        5,
        9
      )}`;
    }

    return value;
  },

  // Phone Number formatting to add brackets and '-' between numbers to send it to backend.
  PHONE_NUMBER: (value: string) => {
    if (!value) return value;

    const onlyNums = value.replace(/ /g, '').replace(/-/g, '').split('');

    // Array to hold spaces between dashes.
    const phoneNumber = [
      ' ',
      ' ',
      ' ',
      '-',
      ' ',
      ' ',
      ' ',
      '-',
      '',
      '',
      '',
      '',
    ];

    let onlyNumsIndex = 0;
    phoneNumber.forEach((value, index) => {
      if (onlyNumsIndex < onlyNums.length) {
        if (value !== '-') {
          phoneNumber[index] = onlyNums[onlyNumsIndex];
          onlyNumsIndex++;
        }
      }
    });

    return phoneNumber.join('');
  },
};

export { FieldFormatting };
