import { takeLatest } from 'redux-saga/effects';
import {
  REQUEST_VERIFICATION_CODE_ACTION,
  RESET_PASSWORD_ACTION,
} from '../../actions/forgot-reset-password.actions';
import {
  requestVerificationCode,
  resetPassword,
} from '../workers/forgot-reset-password.workers';

export function* watchSaga() {
  yield takeLatest(REQUEST_VERIFICATION_CODE_ACTION, requestVerificationCode);

  yield takeLatest(RESET_PASSWORD_ACTION, resetPassword);
}
