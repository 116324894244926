import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_YOTTA_LOAD_STATE } from '../action-types/yotta.action-types';

/**
 * @interface PageStatus For tracking navigation.
 */
export interface YottaLoadState {
  hasAppInitialized: boolean;
}

const initialYottaLoadState: YottaLoadState = {
  hasAppInitialized: false,
};

const yottaReducer = createReducer(initialYottaLoadState, (builder) => {
  builder.addCase(UPDATE_YOTTA_LOAD_STATE, () => {
    return {
      hasAppInitialized: true,
    };
  });
});

export { yottaReducer };
