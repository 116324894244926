import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { productListSelector } from '../../../../redux/selectors/productList.selector';
import { IProductListFacetsEntry } from '../../../../_foundation/interface/ProductList/IProductList';
import { NteAccordion } from '../../Accordion/NteAccordion';
import { ActiveFacetBody } from './ActiveFacetBody/ActiveFacetBody';

/**
 * @interface IActiveFacet
 */
interface IActiveFacet {
  /**
   * @prop showSubTitle
   * Subtitle for active facet accordion.
   */
  showSubTitle?: boolean;

  /**
   * @prop title
   * Title for active facet accordion.
   */
  title: string;

  /**
   * @prop showClearAll
   * Determines whether to show clear all option.
   */
  showClearAll?: boolean;
}

/**
 * @component ActiveFacet renders the active facet component.
 */
const ActiveFacet: React.FC<IActiveFacet> = ({
  showSubTitle = false,
  title,
  showClearAll = true,
}) => {
  const { t } = useTranslation();

  const {
    selectedGroups,
    facetLoading,
    plpError,
    categoryIdentifiers,
    currentBrandIdentifier,
  } = useSelector(productListSelector);

  const [filteredProducts, setFilteredProducts] = useState<string[]>([]);

  const [activeFacets, setActiveFacets] = useState<IProductListFacetsEntry[]>(
    []
  );

  /**
   * @callback initActiveFacets Initializes the active facet selection.
   */
  const initActiveFacets = useCallback((): void => {
    // Display the recently selected facet values at the top.
    const facetValues = Object.values(selectedGroups).reverse();

    let selectedFacets: IProductListFacetsEntry[] = [];

    facetValues.forEach((facet) => {
      selectedFacets = [...selectedFacets, ...facet];
    });

    setActiveFacets(selectedFacets);
  }, [selectedGroups]);

  useEffect(() => {
    initActiveFacets();
  }, [initActiveFacets]);

  /**
   * @callback initFilteredProducts Initializes the filtered products.
   */
  const initFilteredProducts = useCallback(
    (activeFacets: IProductListFacetsEntry[], showSubTitle: boolean): void => {
      if (showSubTitle) {
        if (activeFacets.length !== 0) {
          const filteredFacets: string[] = [];

          activeFacets.forEach((activeFacet) => {
            filteredFacets.push(activeFacet.label);
          });

          setFilteredProducts(filteredFacets);
        } else {
          setFilteredProducts([]);
        }
      }
    },
    []
  );

  useEffect(() => {
    initFilteredProducts(activeFacets, showSubTitle);
  }, [initFilteredProducts, activeFacets, showSubTitle]);

  // Check if the filtered products are available.
  const isFilteredProductsAvailable = activeFacets.length !== 0;

  const showActiveFacets =
    (isFilteredProductsAvailable && !facetLoading) ||
    plpError ||
    categoryIdentifiers.length !== 0 ||
    (categoryIdentifiers.length !== 0 && currentBrandIdentifier);

  return (
    <div className='active_facet'>
      {showActiveFacets && (
        <NteAccordion
          accordionTitle={t(title)}
          accordionSubTitle={filteredProducts.join().replace(/,/g, ', ')}
          defaultExpanded={true}
          children={<ActiveFacetBody showClearAll={showClearAll} />}
        />
      )}
    </div>
  );
};

export { ActiveFacet };
