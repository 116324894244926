import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { ProductCompareDetails } from '../../../PDP/ProductCompareDetails';
import { ModalBody } from '../../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../../Modals/ModalHeader/ModalHeader';
import { ProductCompareConstants } from './ProductCompare.constants';

/**
 * @interface IProductCompareModal
 */
interface IProductCompareModal {
  /**
   * @prop modalHeaderId Modal header's id.
   */
  modalHeaderId: string;

  /**
   * @prop modalCloseHandler Close handler.
   */
  modalCloseHandler: () => void;
  /**
   * @prop compareProducts to display compareProducts.
   */
  compareProducts: IProductListContents[];

  productCompareCloseHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @component ProductCompareModal renders the ProductComparison modal.
 */
const ProductCompareModal: React.FC<IProductCompareModal> = ({
  modalCloseHandler,
  modalHeaderId,
  compareProducts,
  productCompareCloseHandler,
}) => {
  const history = useHistory();

  const { t } = useTranslation();

  const {
    TRANSLATIONS: { COMPARE_PRODUCTS_TITLE },
  } = ProductCompareConstants;

  /**
   * @callback preventBackOnDrawerOpen Prevents the user from going back once the compare drawer is open
   * and when the user clicks on the back button instead of redirecting back the compare drawer will be closed.
   */
  const preventBackOnDrawerOpen = useCallback((): void => {
    window.onpopstate = function () {
      history.go(1);

      productCompareCloseHandler(false);
    };
  }, [history, productCompareCloseHandler]);

  /**
   * @method compareHandler Opens the compare drawer and pushes the current state to history
   * to prevent the component from unmounting and remounting when the user hits the back button.
   */
  const compareHandler = useCallback((): void => {
    const {
      location: { pathname, search },
    } = history;

    history.push({
      pathname,
      search,
    });
  }, [history]);

  useEffect(() => {
    compareHandler();

    preventBackOnDrawerOpen();
  }, [compareHandler, preventBackOnDrawerOpen]);

  useEffect(() => {
    return () => {
      window.onpopstate = () => {};
    };
  }, []);

  // This useEffect will check the compareProducts value inside the Modal -> < 2 or not.
  // if it is then it will close the modal
  useEffect(() => {
    if (compareProducts.length < 2) {
      modalCloseHandler();
    }
  }, [compareProducts, modalCloseHandler]);

  return (
    <div className='nte-card'>
      <ModalHeader
        modalCloseHandler={modalCloseHandler}
        heading={t(COMPARE_PRODUCTS_TITLE)}
        id={modalHeaderId}
      />
      <div className='product_compare_modal'>
        <ModalBody>
          <ProductCompareDetails
            compareProducts={compareProducts}
            isModal={true}
          />
        </ModalBody>
      </div>
    </div>
  );
};

export { ProductCompareModal };
