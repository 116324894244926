import { createReducer } from '@reduxjs/toolkit';
import { INteButtonState } from '../../_foundation/interface/NteButton/INteButton';
import { SET_BUTTON_LOADING_ACTION } from '../actions/button.actions';

const initState: INteButtonState = {
  loading: false,
  buttonType: '',
};

const buttonReducer = createReducer(initState, (builder) => {
  builder.addCase(
    SET_BUTTON_LOADING_ACTION,
    (state: INteButtonState, action: any) => {
      return {
        ...state,
        loading: action.payload.loading,
        buttonType: action.payload.buttonType,
      };
    }
  );
});

export { buttonReducer };
