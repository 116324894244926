import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbType } from '../../../_foundation/enum/Breadcrumbs/Breadcrumbs';
import { IBreadcrumbLink } from '../../../_foundation/interface/Breadcrumb/IBreadcrumb';
import './BreadCrumbsLinks.scss';
import { useBreadcrumb } from './hooks/BreadcrumbHooks';
import { BreadcrumbsSkeleton } from './skeleton/BreadcrumbsSkeleton';

/**
 * @interface IBreadCrumbs
 */
interface IBreadCrumbs {
  /**
   * @prop whiteText Determines whether the breadcrumbs text is white or not.
   */
  whiteText?: boolean;

  /**
   * @prop breadcrumbType Determines the type of breadcrumb to be loaded.
   */
  breadcrumbType: BreadcrumbType;

  /**
   * @prop breadcrumbArray Breadcrumb array link for custom breadcrumbs.
   */
  breadcrumbArray?: IBreadcrumbLink[];

  /**
   * @prop className.
   */
  className?: string;
}

/**
 * @component BreadCrumbs - Renders Links with which the user can navigate
 * through different nested routes.
 */
const BreadCrumbs: React.FC<IBreadCrumbs> = ({
  whiteText = false,
  breadcrumbType = BreadcrumbType.Category,
  breadcrumbArray,
  className = '',
}) => {
  const { breadCrumbsLinks, handleCategoryAndBrandIndexPage } = useBreadcrumb(
    breadcrumbType,
    breadcrumbArray
  );

  const textClass = whiteText ? 'text-white' : 'text-dark';

  /**
   * Add a next-to-last class to the breadcrumb, for styling.
   */
  const nextToLastIndex = breadCrumbsLinks.length - 2;

  return (
    <>
      {breadCrumbsLinks.length > 0 ? (
        <nav className='breadcrumb' aria-label='breadcrumbs'>
          <ol
            vocab='https://schema.org/'
            typeof='BreadcrumbList'
            className={`bread-crumbs-container bread_crumbs ${className}`}>
            {breadCrumbsLinks.map((link: IBreadcrumbLink, index: number) => (
              <li
                property='itemListElement'
                typeof='ListItem'
                className={`bread-crumbs-link ${textClass} ${index === nextToLastIndex ? 'next-to-last' : ''
                  }`}
                key={index}>
                {index !== 0 ? (
                  <Typography
                    variant='body2'
                    component='span'
                    className='bread-crumbs-link separator'>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                  </Typography>
                ) : null}

                {index !== breadCrumbsLinks.length - 1 ? (
                  <Link
                    onClick={() => {
                      handleCategoryAndBrandIndexPage(link);
                    }}
                    to={link.url}
                    id={link.url}
                    property='item'
                    typeof='WebPage'
                    className={`link-effect bread-crumbs-link ${textClass}`}>
                    <span property='name'>{link.displayText}</span>
                  </Link>
                ) : (
                  <Typography
                    property='name'
                    component='span'
                    variant='body2'
                    className='bread-crumbs-link disable-link'>
                    {link.displayText}
                  </Typography>
                )}
                <meta property='position' content={String(index + 1)} />
              </li>
            ))}
          </ol>
        </nav>
      ) : (
        <BreadcrumbsSkeleton textClass={textClass} />
      )}
    </>
  );
};

export { BreadCrumbs };
