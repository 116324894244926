import React from 'react';

/**
 * @component PlpFeedback renders the Plp pages feedback component.
 */
const PlpFeedback: React.FC = () => (
  <div id='product-results-feedback' className='section-row' />
);

export { PlpFeedback };
