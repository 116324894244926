import { decode } from 'html-entities';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { useBrandParams } from '../../../utils/hooks/brand-params/brand-params';

/**
 * @interface IPageMetaTags
 */
interface IPageMetaTags {
  /**
   * @prop metaDescription
   * Description for the meta tag.
   */
  metaDescription?: string;

  /**
   * @prop metaKeyword
   * Keywords for meta tag.
   */
  metaKeyword?: string;

  /**
   * @prop pageTitle
   * Title of the page.
   */
  pageTitle?: string;

  /**
   * @prop isPlpPage
   * check for plppage
   */
  isPlpPage?: boolean;

  /**
   * @prop isPdpPage
   * check for PDP Page
   */
   isPdpPage?: boolean;
}

/**
 * @component PageMetaTags injects the meta tags for a specific page.
 *
 * @param IPageMetaTags
 */
const PageMetaTags: React.FC<IPageMetaTags> = ({
  metaDescription,
  metaKeyword,
  pageTitle,
  isPlpPage = false,
  isPdpPage = false,
}) => {
  const { pathname, search } = useLocation();

  const { brands } = useBrandParams();

  const canonicalUrl = `${window.origin}${isPdpPage && !pathname.includes("/products") ? "/products" : ''}${pathname}${search}`;

  const pdpCanonicalUrl = () => canonicalUrl.split('?')[0] // To remove unnecessary parameters on the PDP page when redirected from search engines.

  let title = '';

  if (pageTitle) {
    if (
      decode(pageTitle).includes('| Northern Tool') ||
      decode(pageTitle).includes('from Northern Tool') ||
      decode(pageTitle).includes('| NorthernTool') ||
      decode(pageTitle).includes(
        'Northern Tool - Quality Tools for Serious Work'
      )
    ) {
      title = decode(pageTitle);
    } else {
      title = decode(pageTitle);
    }
  }

  return (
    <Helmet>
      {metaDescription && (
        <meta name='description' content={metaDescription}></meta>
      )}

      {metaKeyword && <meta name='keywords' content={metaKeyword}></meta>}

      {title ? (
        <title>
          {brands}
          {decode(title)}
        </title>
      ) : (
        <title>Northern Tool - Quality Tools for Serious Work</title>
      )}

      {!isPlpPage && <link rel='canonical' href={isPdpPage ? pdpCanonicalUrl() : canonicalUrl} />}
    </Helmet>
  );
};

export { PageMetaTags };
