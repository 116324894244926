import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductUtility } from '../../../utils/product-utility';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';

interface ICallStoreMessage {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
}

const CallStoreMessage: React.FC = () => {
  const { CALL_STORE } = ProductCardSectionMessagesConstants;

  const { t } = useTranslation();

  return (
    <div className='price-cell'>
      <div className='nte-price24 price product-offer-price offer-price'>
        {t(CALL_STORE)}
      </div>
    </div>
  );
};

const useCallStoreMessage = ({ productCard }: ICallStoreMessage) => {
  const isShowCallStoreMsg = useMemo(() => {
    return productCard?.manufacturer
      ? ProductUtility.showCallStoreMsg(productCard?.manufacturer)
      : '';
  }, [productCard?.manufacturer]);

  return { CallStoreMessage, isShowCallStoreMsg };
};

export { CallStoreMessage, useCallStoreMessage };
