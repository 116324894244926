import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CHECKOUT, SHOPPING_CART } from '../../../constants/routes';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { NteAlert } from '../../NteAlert/NteAlert';
import { ModalBody } from '../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../Modals/ModalHeader/ModalHeader';
import { SignInForm } from './SignInForm/SignInForm';

/**
 * @interface ISignInModal
 */
interface ISignInModal {
  /**
   * @prop signInCloseHandler handles the close events.
   */
  signInCloseHandler: () => void;

  title: string;

  alertMessage?: string;
}

/**
 * @component SignInModal renders the signin modal form.
 *
 * @param ISignInModal
 */
const SignInModal: React.FC<ISignInModal> = ({
  signInCloseHandler,
  title,
  alertMessage,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  useEffect(() => {
    return () => {
      if (
        history.location.pathname.includes(CHECKOUT) &&
        !isUserAuthenticated
      ) {
        history.push(SHOPPING_CART);
      }
    };
  }, [history, isUserAuthenticated]);

  return (
    <div aria-modal={true} role='dialog'>
      <ModalHeader
        heading={title}
        modalCloseHandler={signInCloseHandler}
        id='session-invalid-dialog'
      />

      <ModalBody>
        <>
          {alertMessage && (
            <NteAlert
              messages={[t(alertMessage)]}
              severity='warning'
              showClose={false}
            />
          )}

          <SignInForm signInCloseHandler={signInCloseHandler} />
        </>
      </ModalBody>
    </div>
  );
};

export { SignInModal };
