import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { formatSearchTerm } from '../../../utils/utils';
import { categoryService } from '../../../_foundation/apis/search/category/category.service';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { searchProductsContentService } from '../../../_foundation/apis/search/search-siteContent/searchProducts.service';
import { CANCEL_ON_UNMOUNT } from '../../../_foundation/constants/cancel';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../actions/network-errors.actions';
import {
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_ID_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FACET_LOADING_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
  FETCH_INVENTORY_INFO_SUCCESS_ACTION,
  FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
  FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION,
  NO_SEARCH_RESULTS_FOUND_ACTION,
  REDIRECT_TO_PDP_ACTION,
  SEARCH_DISPLAY_SUCCESS_ACTION,
  SEARCH_PRODUCT_LIST_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
  SHOW_PLP_ERROR_ACTION,
  UPDATE_FACETS_SUCCESS_ACTION,
} from '../../actions/productList.actions';

export function* getProductList(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchProductsByCategory,
      action.payload
    );

    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: action.payload.userType,
        isBrandPlp: action.payload.isBrandPlp,
        searchPlp: false,
        pageNumber: action.payload.pageNumber,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    console.error(e);

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductList(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    const response = yield call(
      productsService.fetchProductsByCategory,
      action.payload
    );

    yield put(
      UPDATE_FACETS_SUCCESS_ACTION({
        response,
        pageNumber: action.payload.pageNumber,
        userType: action.payload.userType,
        searchPlp: false,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getProductListForStore(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchProductsByStoreIdentifier,
      action.payload
    );

    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: action.payload.userType,
        searchPlp: false,
        pageNumber: action.payload.pageNumber,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductListForShopStore(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    const response = yield call(
      productsService.fetchProductsByStoreIdentifier,
      action.payload
    );

    yield put(
      UPDATE_FACETS_SUCCESS_ACTION({
        response,
        pageNumber: action.payload.pageNumber,
        searchPlp: false,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getPrivateBrands(action: any): Generator {
  try {
    const response = yield call(
      productsService.fetchPrivateBrands,
      action.payload
    );

    yield put(FETCH_PRIVATE_BRANDS_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getProductListByKeyword(action: any): Generator {
  try {
    const { siteResponse, isPdpRedirect }: any = yield call(
      searchProductsContentService.fetchProductsBySearchTerm,
      action.payload
    );

    if (!isPdpRedirect) {
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION({
          ...siteResponse,
          searchPlp: true,
          searchTerm: action.payload.searchKeyword,
          pageNumber: action.payload.pageNumber,
        })
      );
    }

    if (isPdpRedirect && siteResponse) {
      const parentCatalogEntryID =
        siteResponse.contents[0].parentCatalogEntryID;
      if (parentCatalogEntryID) {
        const payload = {
          id: [parentCatalogEntryID],
          storeID: action.payload.storeID,
        };

        const response: any = yield call(
          productsService.fetchProductById,
          payload
        );

        yield put(REDIRECT_TO_PDP_ACTION(response.contents[0].seo.href));
      } else {
        yield put(REDIRECT_TO_PDP_ACTION(siteResponse.contents[0].seo.href));
      }
    }
  } catch (e: any) {
    if (e?.message !== CANCEL_ON_UNMOUNT) {
      yield put(NO_SEARCH_RESULTS_FOUND_ACTION());
    }
  }
}

export function* updateProductListByKeyword(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    const { siteResponse }: any = yield call(
      searchProductsContentService.fetchProductsBySearchTerm,
      action.payload
    );

    const response = siteResponse;

    yield put(
      UPDATE_FACETS_SUCCESS_ACTION({
        response,
        pageNumber: action.payload.pageNumber,
        searchPlp: true,
      })
    );
  } catch (e: any) {
    const { response }: AxiosError = e as AxiosError;

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getSearchDisplayByKeyword(action: any): Generator {
  try {
    const response: any = yield call(
      searchProductsContentService.fetchSearchDisplayBySearchTerm,
      action.payload
    );

    const { redirecturl, viewTaskName, storeId } = response;

    const searchDiaplayResponse = {
      redirecturl: redirecturl,
      viewTaskName: viewTaskName,
      storeId: storeId,
      searchTerm: formatSearchTerm(action.payload.term),
    };

    yield put(SEARCH_DISPLAY_SUCCESS_ACTION(searchDiaplayResponse));

    if (!redirecturl && action.payload?.searchProductParams) {
      yield put(
        SEARCH_PRODUCT_LIST_ACTION(action.payload?.searchProductParams)
      );
    }

    return response;
  } catch (e) {
    console.error(e);
  }
}

export function* getInventoryInfo(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchInventoryInfo,
      action.payload
    );

    yield put(FETCH_INVENTORY_INFO_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getSubCategories(action: any): Generator {
  try {
    const response: any = yield call(
      categoryService.fetchAllSubcategoriesForCarousel,
      action.payload
    );

    if (Object.keys(response)) {
      yield put(FETCH_SUB_CATEGORIES_SUCCESS_ACTION(response));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getCategoryByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    yield put(FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getBrandByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    if (response && response.contents && response.contents[0]) {
      yield put(CURRENT_BRAND_ID_ACTION(response.contents[0].id));

      yield put(
        CURRENT_BRAND_IDENTIFIER_ACTION(response.contents[0].identifier)
      );

      yield put(
        CURRENT_BRAND_INFO_ACTION({
          brandName: response.contents[0].name,
          seo: response.contents[0].seo.href,
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}
