import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { enterKeyPressHandler } from '../../../../utils/utils';
import { MENU_NAVIGATE_BACK } from '../../../Menu/NteMenuList/redux/action/menu-list.action';
import { NteButton } from '../../../Forms/NteButton/NteButton';

/**
 * @interface MenuListHeaderProps
 */
interface MenuListHeaderProps {
  /**
   * @prop showBack flag is responsible for
   * showing the back link inside
   * nested menu list.
   */
  showBack?: boolean;

  /**
   * @prop menuTitle to be displayed in the
   * menu header.
   */
  menuTitle: string;

  /**
   * @prop closeHandler for closing the menu
   * while clicking on close icon.
   */
  closeHandler?: () => void;

  /**
   * @prop backHandler handles the back button events.
   */
  backHandler?: (selectedEvent?: any) => void;

  /**
   * @prop id for Menu List header.
   */
  id: string;
}

/**
 * @component MenuListHeader renders the menu list header.
 */
const MenuListHeader: React.FC<MenuListHeaderProps> = ({
  menuTitle,
  showBack = false,
  closeHandler,
  backHandler,
  id,
}) => {
  const backButtonHandler = (): void => {
    if (backHandler) {
      backHandler({ backAction: MENU_NAVIGATE_BACK });
    }
  };

  return (
    <div
      className={`menu_list_header header-region ${
        showBack ? 'show-back' : ''
      }`}>
      <div className='cta-top'>
        {showBack && (
          <NteButton
            className='inline-text menu-back-link'
            aria-label='menu-back-link'
            onClick={backButtonHandler}
            translationKey={`< Back`}
            onKeyDown={(e) => enterKeyPressHandler(e, backButtonHandler)}
          />
        )}

        {closeHandler && (
          <IconButton
            onClick={closeHandler}
            onKeyDown={(e) => enterKeyPressHandler(e, closeHandler)}
            className='cursor-pointer close_icon'
            aria-label='close'>
            <CloseIcon />
          </IconButton>
        )}
      </div>

      <Typography id={id} variant='h2'>
        {menuTitle}
      </Typography>
    </div>
  );
};

export { MenuListHeader };
