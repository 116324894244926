import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the first name input field.
 */
const FirstNameValidator = {
  FIRST_NAME: {
    name: 'firstName',
    id: 'first-name',
    label: 'First Name',
    type: 'text',
    required: true,
    autoComplete:'given-name',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'First name is required.'),
      (value: string) =>
        FieldValidations.NAME(
          value,
          'First name can only be of maximum 35 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.NAME,
    sensitiveData: true,
  },
};

/**
 * Validator for the last name input field.
 */
const LastNameValidator = {
  LAST_NAME: {
    name: 'lastName',
    id: 'last-name',
    label: 'Last Name',
    type: 'text',
    required: true,
    autoComplete:'family-name',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Last name is required.'),
      (value: string) =>
        FieldValidations.NAME(
          value,
          'Last name can only be of maximum 35 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.NAME,
    sensitiveData: true,
  },
};

/**
 * Validator for the nick name input field.
 */
const NickNameValidator = {
  NICK_NAME: {
    name: 'nickName',
    id: 'nick-name',
    label: 'Nick Name*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Nick name is required.'),
      (value: string) =>
        FieldValidations.NAME(
          value,
          'Nick name can only be of maximum 35 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.NAME,
    disableAutoComplete: true,
    sensitiveData: true,
  },
};

/**
 * Validator for the business name input field.
 */
const BusinessNameValidator = {
  BUSINESS_NAME: {
    name: 'businessName',
    id: 'business-name',
    label: 'Business Name',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.BUSINESS_NAME(
          value,
          'Business name can only be of maximum 40 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.BUSINESS_NAME,
    sensitiveData: true,
  },
};

export { FirstNameValidator, LastNameValidator, NickNameValidator, BusinessNameValidator };
