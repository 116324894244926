import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const ProductPricingSkeleton: React.FC = () => (
  <Typography variant='h6'>
    <Skeleton className='content-skeleton' animation='wave' />
  </Typography>
);

export { ProductPricingSkeleton };
