import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as ROUTES from '../../../constants/routes';
import { GET_USER_DETAILS_ACTION } from '../../../redux/actions/auth.actions';
import { FETCH_CART_DETAILS_REQUEST_ACTION } from '../../../redux/actions/order.actions';
import {
  GET_STORES_ACTION,
  SET_ANCHOR_STORE_NAME_ACTION,
} from '../../../redux/actions/storeLocator';
import { networkErrorSelector } from '../../../redux/selectors/network-errors.selector';
import { MY_STORE_COOKIE } from '../../../_foundation/constants/cookie';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { MyStoreCookie } from '../../../_foundation/interface/StoreLocator/IStoreLocator';
import { useInitWishList } from '../init-wish-list/init-wishlist';

const useInitApplication = () => {
  const dispatch = useDispatch();

  const { redirectToErrorPage } = useSelector(networkErrorSelector);

  useInitWishList();

  const history = useHistory();

  const { mySite } = useSite();

  useEffect(() => {
    dispatch(GET_USER_DETAILS_ACTION());
  }, [dispatch]);

  const redirectError = useCallback((): void => {
    if (redirectToErrorPage) {
      history.push(ROUTES.ERROR_PAGE);
    }
  }, [history, redirectToErrorPage]);

  const fetchCartDetails = useCallback((): void => {
    if (mySite) {
      dispatch(
        FETCH_CART_DETAILS_REQUEST_ACTION({
          storeID: mySite.storeID,
        })
      );
    }
  }, [mySite, dispatch]);

  /**
   * @callback initAnchorStoreDetails Initializes the current anchor stores name in redux.
   */
  const initAnchorStoreDetails = useCallback((): void => {
    const storeCookie: any = sessionStorage.getItem(MY_STORE_COOKIE);

    if (storeCookie && JSON.parse(storeCookie)?.storeName) {
      dispatch(SET_ANCHOR_STORE_NAME_ACTION(JSON.parse(storeCookie).storeName));
    }
  }, [dispatch]);

  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  const initGetStores = useCallback((): void => {
    if (mySite && myStoreCookie) {
      const parsedStoreDetail: MyStoreCookie = JSON.parse(myStoreCookie);

      dispatch(
        GET_STORES_ACTION({
          storeID: mySite?.storeID,
          latitude: parsedStoreDetail?.latitude
            ? parsedStoreDetail?.latitude
            : parsedStoreDetail?.geoCoordinates?.lat,
          longitude: parsedStoreDetail?.longitude
            ? parsedStoreDetail?.longitude
            : parsedStoreDetail?.geoCoordinates?.lng,
          radius: mySite?.storeLocatorDefaultRadius,
        })
      );
    }
  }, [dispatch, mySite, myStoreCookie]);

  useEffect(() => {
    initGetStores();
  }, [initGetStores]);

  useEffect(() => {
    initAnchorStoreDetails();
  }, [initAnchorStoreDetails]);

  useEffect(() => {
    redirectError();
  }, [redirectError]);

  useEffect(() => {
    fetchCartDetails();
  }, [fetchCartDetails]);
};

export { useInitApplication };
