import { takeLatest } from 'redux-saga/effects';
import {
  ADD_PICKUP_PERSON_ACTION,
  DELETE_PICKUP_PERSON_ACTION,
  GET_PICKUP_PERSON_ACTION,
  UPDATE_PICKUP_PERSON_ACTION,
} from '../../actions/pickupPerson.actions';
import {
  addPickupPersonSaga,
  deletePickupPersonSaga,
  getPickupPersonSaga,
  updatePickupPersonSaga,
} from '../workers/pickupPerson.workers';

export function* watchSaga() {
  yield takeLatest(GET_PICKUP_PERSON_ACTION, getPickupPersonSaga);

  yield takeLatest(ADD_PICKUP_PERSON_ACTION, addPickupPersonSaga);

  yield takeLatest(DELETE_PICKUP_PERSON_ACTION, deletePickupPersonSaga);

  yield takeLatest(UPDATE_PICKUP_PERSON_ACTION, updatePickupPersonSaga);
}
