import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { STORE_LOCATOR } from '../../../../../../constants/routes';
import { SHOW_STORE_FINDER_ACTION } from '../../../../../../redux/actions/storeLocator';
import { storeLocatorSelector } from '../../../../../../redux/selectors/storeLocator';
import { isStoreAvailable } from '../../../../../../utils/utils';
import {
  MyStoreCookie,
  PhysicalStoreDetails,
} from '../../../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { useNteAlert } from '../../../../../NteAlert/hooks/NteAlertHooks';
import { MyStoreConstants } from './MyStoreConstants';
import { StoreDetails } from './StoreDetails/StoreDetails';
import { StoreFinder } from './StoreFinder/StoreFinder';

interface Props {
  /**
   * @prop myStoreCloseHandler is responsible for closing
   * the store popper component.
   */
  myStoreCloseHandler: () => void;

  /**
   * @prop nearByStoreDetails has the physical store details
   * for the nearBy store.
   */
  nearByStoreDetails: PhysicalStoreDetails | MyStoreCookie;
}

/**
 * @component MyStore is responsible for rendering Store
 * details or the store finder component based on
 * the StoreLocator's business scenario.
 */
const MyStore: React.FC<Props> = ({
  myStoreCloseHandler,
  nearByStoreDetails,
}) => {
  const {
    CHANGE_MY_STORE,
    FIND_A_STORE,
    SEE_OTHER_STORE,
    GET_DIRECTIONS,
    STORE_AD,
    ERR_STORE_AD,
    GET_PARTS,
    URLS: { GOOGLE_MAPS_BASE_URL, GET_PARTS_PATH },
    REGION_AD_ATTRIBUTE,
    FIND_A_STORE_FALLBACK,
    CHANGE_MY_STORE_FALLBACK,
  } = MyStoreConstants;

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { setAlert } = useNteAlert();

  const { showFindStore, currentStoreDetails, storeDetails } =
    useSelector(storeLocatorSelector);

  const showBackButton = isStoreAvailable() && showFindStore;

  const findStoreTitle = i18n.exists(FIND_A_STORE).valueOf()
    ? t(FIND_A_STORE)
    : FIND_A_STORE_FALLBACK;

  const changeMyStoreTitle = i18n.exists(CHANGE_MY_STORE).valueOf()
    ? t(CHANGE_MY_STORE)
    : CHANGE_MY_STORE_FALLBACK;

  const storeFinderTitle = !isStoreAvailable()
    ? findStoreTitle
    : changeMyStoreTitle;

  const regionAdUrl =
    storeDetails &&
    storeDetails[0] &&
    storeDetails[0].Attribute?.filter(
      (attribute) => attribute.displayName === REGION_AD_ATTRIBUTE
    )[0]?.value;

  /**
   * This method is responsible for handling menu list click events.
   * @param selectedEvent carries the event object triggered by menu list onClick.
   */
  const storeDetailsMenuClickHandler = (selectedEvent?: any): void => {
    if (selectedEvent.isNestedMenu) {
      dispatch(SHOW_STORE_FINDER_ACTION(true));

      return;
    }

    if (selectedEvent.currentItem === t(SEE_OTHER_STORE)) {
      history.push(STORE_LOCATOR);
    }

    const googleMapsUrl = `${GOOGLE_MAPS_BASE_URL}${nearByStoreDetails?.addressLine} ${nearByStoreDetails?.city} ${nearByStoreDetails?.stateOrProvinceName}`;

    if (selectedEvent.currentItem === t(GET_DIRECTIONS)) {
      window.open(googleMapsUrl, '_blank');
    }

    if (selectedEvent.currentItem === t(STORE_AD)) {
      if (!regionAdUrl) {
        setAlert({
          message: t(ERR_STORE_AD),
          severity: 'error',
        });

        myStoreCloseHandler();

        return;
      }

      window.open(regionAdUrl, '_blank');
    }

    if (selectedEvent.currentItem === t(GET_PARTS)) {
      history.push(GET_PARTS_PATH);
    }

    // Closes the store on menu item click.
    myStoreCloseHandler();

    // To add navigation logic to respective url's during implementation.
  };

  /**
   * This method is responsible for reverting back to StoreDetails component.
   */
  const storeFinderBackHandler = () =>
    dispatch(SHOW_STORE_FINDER_ACTION(false));

  /**
   * This method is responsible for closing the 'Change My Store' menu.
   */

  const storeCloseHandler = () => {
    if (currentStoreDetails) {
      storeFinderBackHandler();
      myStoreCloseHandler();
      return;
    }
    myStoreCloseHandler();
  };

  return (
    <>
      {showFindStore || !nearByStoreDetails ? (
        <StoreFinder
          menuTitle={storeFinderTitle}
          showBack={showBackButton}
          storeFinderBackHandler={storeFinderBackHandler}
          storeFinderCloseHandler={storeCloseHandler}
          onNavbar={true}
        />
      ) : (
        <StoreDetails
          menuClickHandler={storeDetailsMenuClickHandler}
          myStoreCloseHandler={myStoreCloseHandler}
          storeDetails={nearByStoreDetails}
        />
      )}
    </>
  );
};

export { MyStore };
