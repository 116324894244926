import { createReducer } from '@reduxjs/toolkit';
import {
  IForgotPasswordResponse,
  IForgotResetPasswordState,
} from '../../_foundation/interface/Authentication/IAuth';
import { IError } from '../../_foundation/interface/NetworkErrors/INetworkErrors';
import {
  ADD_FORGOT_PASSWORD_EMAIL_ADDRESS_ACTION,
  RESET_FORGOT_RESET_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  RESET_VERIFICATION_CODE_ACTION,
  TOGGLE_PASSWORD_COMPONENT_ACTION,
  VERIFICATION_CODE_ERROR_ACTION,
  VERIFICATION_CODE_RECEIVED_ACTION,
} from '../actions/forgot-reset-password.actions';

const initForgotResetPasswordState: IForgotResetPasswordState = {
  forgotPasswordError: {
    errorCode: '',
    errorKey: '',
    errorMessage: '',
    errorParameters: '',
  },
  verificationCodeSent: false,
  resetPassword: false,
  resetPasswordEmail: '',
};

const forgotResetPasswordReducer = createReducer(
  initForgotResetPasswordState,
  (builder) => {
    builder.addCase(
      VERIFICATION_CODE_RECEIVED_ACTION,
      (state: IForgotResetPasswordState, action: any) => {
        const forgotPasswordResponse =
          action.payload as IForgotPasswordResponse;

        if (forgotPasswordResponse) {
          return {
            ...state,
            verificationCodeSent: true,
            forgotPasswordError:
              initForgotResetPasswordState.forgotPasswordError,
          };
        }
      }
    );

    builder.addCase(
      VERIFICATION_CODE_ERROR_ACTION,
      (state: IForgotResetPasswordState, action: any) => {
        const { errors } = action.payload as { errors: IError[] };

        const error = errors[0];

        const forgotPasswordError: IError = { ...error };

        return { ...state, forgotPasswordError };
      }
    );

    builder.addCase(
      RESET_VERIFICATION_CODE_ACTION,
      (state: IForgotResetPasswordState) => {
        return {
          ...state,
          forgotPasswordError: initForgotResetPasswordState.forgotPasswordError,
          verificationCodeSent: false,
        };
      }
    );

    builder.addCase(
      RESET_PASSWORD_SUCCESS_ACTION,
      (state: IForgotResetPasswordState, action: any) => {}
    );

    builder.addCase(
      TOGGLE_PASSWORD_COMPONENT_ACTION,
      (state: IForgotResetPasswordState, action: any) => {
        return { ...state, resetPassword: action.payload };
      }
    );

    builder.addCase(
      ADD_FORGOT_PASSWORD_EMAIL_ADDRESS_ACTION,
      (state: IForgotResetPasswordState, action: any) => {
        return { ...state, resetPasswordEmail: action.payload };
      }
    );

    builder.addCase(RESET_FORGOT_RESET_PASSWORD_ACTION, () => {
      return { ...initForgotResetPasswordState };
    });
  }
);

export { forgotResetPasswordReducer };
