import HTMLReactParser from 'html-react-parser';
import React from 'react';
import { useEspot } from '../../../_foundation/apis/transaction/espot/useEspot';
import { ModalHeader } from '../Modals/ModalHeader/ModalHeader';
import { ESpotModalSkeleton } from './ESpotModalSkeleton/ESpotModalSkeleton';

/**
 * @interface IESpotModal
 */
interface IESpotModal {
  /**
   * Method to close modal
   */
  eSpotModalCloseHandler: () => void;

  /**
   * Name of E Spot
   */
  espotName: string;

  /**
   * Modal header id
   */
  modalHeaderId: string;
}

/**
 * @component renders the contents of an espot inside a modal.
 */
const ESpotModal: React.FC<IESpotModal> = ({
  eSpotModalCloseHandler,
  espotName,
  modalHeaderId,
}) => {
  const { espotContent, isLoading } = useEspot(espotName);

  return (
    <div aria-modal={true} role='dialog'>
      <ModalHeader
        modalCloseHandler={eSpotModalCloseHandler}
        heading=''
        id={modalHeaderId}
      />
      {!isLoading ? (
        <div className='cmc-content'>
          {espotContent && HTMLReactParser(espotContent)}
        </div>
      ) : (
        <ESpotModalSkeleton />
      )}
    </div>
  );
};

export default ESpotModal;
