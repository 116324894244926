
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import ISeoResponse from './ISeoResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { ISeoResponse };

  export const ISeoResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IContentsItem": {
            "properties": {
                "id": {
                    "type": "string"
                },
                "identifier": {
                    "type": "string"
                },
                "language": {
                    "type": "string"
                },
                "layout": {
                    "$ref": "#/definitions/ILayout"
                },
                "page": {
                    "$ref": "#/definitions/IPage"
                },
                "status": {
                    "type": "number"
                },
                "store.type": {
                    "type": "string"
                },
                "storeId": {
                    "type": "string"
                },
                "tokenExternalValue": {
                    "type": "string"
                },
                "tokenName": {
                    "type": "string"
                },
                "tokenValue": {
                    "type": "string"
                }
            },
            "required": [
                "id",
                "identifier",
                "language",
                "page",
                "status",
                "store.type",
                "storeId",
                "tokenExternalValue",
                "tokenName",
                "tokenValue"
            ],
            "type": "object"
        },
        "ILayout": {
            "properties": {
                "containerName": {
                    "type": "string"
                },
                "id": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "slots": {
                    "items": {
                        "$ref": "#/definitions/ISlotsItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "containerName",
                "id",
                "name"
            ],
            "type": "object"
        },
        "IPage": {
            "properties": {
                "imageAlternateDescription": {
                    "type": "string"
                },
                "metaDescription": {
                    "type": "string"
                },
                "metaKeyword": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "title": {
                    "type": "string"
                },
                "type": {
                    "type": "string"
                }
            },
            "required": [
                "imageAlternateDescription",
                "metaDescription",
                "metaKeyword",
                "name",
                "title",
                "type"
            ],
            "type": "object"
        },
        "ISlotsItem": {
            "properties": {
                "id": {
                    "type": "string"
                },
                "widgets": {
                    "items": {
                        "$ref": "#/definitions/IWidgetsItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "id",
                "widgets"
            ],
            "type": "object"
        },
        "IWidgetsItem": {
            "properties": {
                "id": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "properties": {},
                "sequence": {
                    "type": "number"
                },
                "widgetName": {
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "sequence",
                "widgetName"
            ],
            "type": "object"
        }
    },
    "properties": {
        "contents": {
            "items": {
                "$ref": "#/definitions/IContentsItem"
            },
            "type": "array"
        }
    },
    "required": [
        "contents"
    ],
    "type": "object"
}

;

  export const isISeoResponse = ajv.compile(ISeoResponseSchema) as ValidateFunction<
    ISeoResponse
  >;

  export default function validateISeoResponse(value: unknown): ISeoResponse {
    if (isISeoResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isISeoResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'ISeoResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isISeoResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'ISeoResponse' },
        ) +
          inspect(value)
      );
    }
  }