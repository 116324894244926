import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
} from '@material-ui/core';
import React from 'react';
import { LUCKY_ORANGE_CONSTANTS } from '../../../constants/LuckyOrange';
import { formatAriaLabel } from '../../../utils/utils';

/**
 * @type SelectOption
 */
export type SelectOption = {
  value: number | string;
  label: string;
  disabled?: boolean;
};

/**
 * @interface INteSelect
 */
interface INteSelect extends SelectProps {
  /**
   * @prop options for the select menu.
   */
  options: SelectOption[];

  /**
   * @prop selectFormId for the select field.
   */
  selectFormId: string;

  /**
   * @prop selectLabel for the select field.
   */
  selectLabel?: string;

  /**
   * @prop className for the select field wrapper.
   */
  className?: string;

  /**
   * If set to true, selection values will scrambled in lucky orange.
   */
  sensitiveData?: boolean;

  /**
   * autocomplete for field
   */
  autoComplete?: string;

  /**
   * disable autocomplete
   */
  disableAutoComplete?: boolean;
}

/**
 * @component NteSelect renders the Nte select.
 */
const NteSelect: React.FC<INteSelect> = ({
  selectFormId,
  selectLabel,
  options,
  className = '',
  sensitiveData = false,
  autoComplete,
  disableAutoComplete,
  ...selectProps
}) => (
  <FormControl
    variant='outlined'
    className={`nte_select form-input-field ${className}`}>
    {selectLabel && <InputLabel id={selectLabel}>{selectLabel}</InputLabel>}
    <Select
      aria-label={formatAriaLabel(selectLabel)}
      {...selectProps}
      inputProps={{ 'aria-label': formatAriaLabel(selectLabel) }}
      native
      autoComplete={
        disableAutoComplete ? 'exclude-auto-complete' : 'autocomplete'
      }
      id={selectFormId}
      className={sensitiveData ? LUCKY_ORANGE_CONSTANTS.SENSITIVE_DATA : ''}>
      {options.map((option, index) => (
        <option key={index} value={option.value} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </Select>
  </FormControl>
);

export { NteSelect };
