const PdpPageConstants = {
  PDP_MINI_FEATURE: 'PDP_MINI_FEATURE',
  PDP_PARTS: 'PDP_PARTS',
  PDP_SUMMARY: 'PDP_SUMMARY',
  PDP_WARNING_DISCLAIMER_ESPOT: 'PDP_WARNINGS',
  PDP_FEATURE_IMAGE_URL: `https://www.northerntool.com/images/product/features/`,
  COLOR: 'Color',
  DEFAULT_QTY: '1',
  WARRANTY_SECTION_HEADING: 'Warranty',
  WARRANTY_SECTION_MANUFACTURE_HEADING: 'Manufacturer&#39;s Warranty',
  WARRANTY_SECTION_PROTECTION_HEADING:
    'Extend Your Warranty Coverage with a Northern Protection Plan (NPP)',
  WARRANTY_SECTION_PROTECTION_TEXT:
    'A Northern Protection Plan is available after adding the product to your shopping cart.',
  WARRANTY_MERC_ASSOC_TYPE: 'UPSELL',
  PDP_FEATURE_BENEFIT_HEADING: 'Features + Benefits',
  CUSTOMER_IMAGES: 'Customer Images',
  PDP_FEATURE_BENEFIT_IMAGE_ASSET_PATH: 'shopping/pdp-callouts/',
  PDP_FEATURE_BENEFIT_IMAGE_TYPE: '.jpg',
  PDP_FEATURE_BENEFIT_IMAGE_WIDTH: 800,
  PROP_65_MODAL: {
    WARNING: 'PRODUCT_DETAILS_PAGE.PROP_65_MODAL.WARNING',
    MESSAGE: 'PRODUCT_DETAILS_PAGE.PROP_65_MODAL.MESSAGE',
    LINK: 'https://www.p65warnings.ca.gov',
  },
  PROP_65_WARNING: 'PRODUCT_DETAILS_PAGE.PROP_65_WARNING',
  SEE_MORE: 'PRODUCT_DETAILS_PAGE.SEE_MORE',
  SEE_FULL_DESCRIPTION: 'PRODUCT_DETAILS_PAGE.SEE_FULL_DESCRIPTION',
  REMOVE: 'PRODUCT_DETAILS_PAGE.REMOVE',
  FIND_SIMILAR_PRODUCTS: 'PRODUCT_DETAILS_PAGE.BUTTON.FIND_SIMILAR_PRODUCTS',
  ATTRIBUTES: {
    DESCRIPTIVE: 'DESCRIPTIVE',
    BADGE: 'BADGE',
    RECONDITIONED: 'RECONDITIONED',
    YES: 'YES',
    STOCK_STATUS: 'stock-status',
    ITEM: 'item',
    PRODUCT: 'product',
    DEFINING: 'defining',
    STORE_ONLY: 'storeonly',
    OFFER: 'offer',
  },
  AVAILABILITY_MESSAGE: {
    TEMPORARILY_UNAVAILABLE:
      'PRODUCT_DETAILS_PAGE.AVAILABILITY_MESSAGE.TEMPORARILY_UNAVAILABLE',
    PERMANENTLY_UNAVAILABLE:
      'PRODUCT_DETAILS_PAGE.AVAILABILITY_MESSAGE.PERMANENTLY_UNAVAILABLE',
  },
  PROP_65_IDENTIFIER: 'prop65',
  CLICK_OR_TAP_IMAGE: 'PRODUCT_DETAILS_PAGE.CLICK_OR_TAP_IMAGE',
  GIFT_CARD_PARTNUMBER: 'GiftCard',
  GIFT_CARD_IDENTIFIER: 'GiftCard-Type',
  CURRENTLY_VIEWING_PRODUCT: 'PRODUCT_DETAILS_PAGE.CURRENTLY_VIEWING_PRODUCT',
  SWATCH_TEXT: {
    CHOOSE_OPTIONS: 'PRODUCT_DETAILS_PAGE.SWATCH_TEXT.CHOOSE_OPTIONS',
    COMPLETE_DESCRIPTION:
      'PRODUCT_DETAILS_PAGE.SWATCH_TEXT.COMPLETE_DESCRIPTION',
  },
  PROFILE_NAME: process.env.REACT_APP_LIMITED_PRODUCT_DATA_PROFILE_NAME,
};

const pdpWidgetName = {
  PDP_WARNING: 'product-warnings-disclaimers',
  PDP_PARTS: 'product-replacement-parts',
  PDP_SUMMARY: '_PDP_SUMMARY',
  PDP_MINI_FEATURE: 'product-mini-feature',
};

const ASSET_LIST = {
  DOCUMENT: 'document',
  IMAGE: 'image',
  IMAGE_DEF_SIZE: 200,
  IMAGE_SIZE: 2000,
};

const PDP_IMAGE_SIZES = {
  CUST_REVIEW_GALLERY: {
    W: 408,
    H: 304,
  },

  CUST_SLIDES: {
    W: 160,
    H: 160,
  },

  CUST_MODAL_SLIDES: {
    W: 580,
    H: 520,
  },

  IMAGE_THUMB: {
    W: 90,
    H: 90,
  },
};

const pdpKeySpecs = {
  PDP_KEY_SPEC_HEADING: 'Key Specs',
  PDP_KEY_SPEC_TABLE_HEADING_NAME: 'Attribute',
  PDP_KEY_SPEC_TABLE_HEADING_VALUE: 'Specification',
  PDP_KEY_SPEC_ITEM_LABEL: 'item#',
  PDP_KEY_SPEC_BRAND_LABEL: 'Brand',
  PDP_KEY_SPEC_MF_LABEL: 'Manufacture&#39;s Warranty',
  PDP_KEY_SPEC_SHIP_WEIGHT: 'Ship Weight',
};

const pdpWarningConstant = {
  PDP_WARNING_DISCLAIMER_HEADING: 'Warnings + Disclaimers',
};

export {
  PdpPageConstants,
  pdpWidgetName,
  PDP_IMAGE_SIZES,
  ASSET_LIST,
  pdpKeySpecs,
  pdpWarningConstant,
};
