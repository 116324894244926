// Constants for Catalog Menu.
const CatalogMenuConstants = {
  CATALOG_TITLE: 'Catalog.Actions.Title',
  REQUEST_FREE_CATALOG: 'Catalog.Actions.Request_Free_Catalog',
  ORDER_FROM_CATALOG: 'Catalog.Actions.Order_From_Catalog',
  CATALOG_TITLE_FALLBACK: 'Catalog',
  REQUEST_FREE_CATALOG_FALLBACK: 'Request a Free Catalog',
  ORDER_FROM_CATALOG_FALLBACK: 'Order From a Catalog',
};

export { CatalogMenuConstants };
