// Constants for Footer Links.
const FooterLinksConstants = {
  CUSTOMER_CARE: 'FOOTER.FOOTER_LINKS.CUSTOMER_CARE',
  ABOUT_US: 'FOOTER.FOOTER_LINKS.ABOUT',
  RESOURCES: 'FOOTER.FOOTER_LINKS.RESOURCES',
  HELPFUL_LINKS: 'FOOTER.FOOTER_LINKS.HELPFUL_LINKS',
  CUSTOMER_CARE_FALLBACK: 'Customer Care',
  ABOUT_FALLBACK: 'About',
  RESOURCES_FALLBACK: 'Resources',
  HELPFUL_LINKS_FALLBACK: 'Helpful Links',
};

export { FooterLinksConstants };
