import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { REFRESH_SEO_ACTION } from '../../../components/Seo/redux/actions/seo';
import { BRAND_INDEX, CATEGORY_INDEX } from '../../../constants/routes';
import { GET_CURRENT_CATEGORY_ID_ACTION } from '../../../redux/actions/categories.actions';
import { RESET_PRODUCT_LIST_ACTION } from '../../../redux/actions/productList.actions';
import {
  BRANDS_IDENTIFIER,
  CATEGORIES_IDENTIFIER,
} from '../../../_foundation/constants/category';
import { MenuNavigatorConstants } from './MenuNavigatorConstants';

/**
 * @interface INavigateNestedMenu
 */
interface INavigateNestedMenu {
  currentPath: string;
  ntePopperClickHandler: () => void;
  currentItemIdentifier?: string;
  seoPath: string;
}

/**
 * @method useMenuNavigator handles navigation
 * for all menus and nested menu items.
 */
const useMenuNavigator = () => {
  const { SEE_ALL_BRAND, SEE_ALL_CATEGORIES } = MenuNavigatorConstants;

  const history = useHistory();

  const dispatch = useDispatch();

  const navigateNestedMenu = ({
    currentPath,
    currentItemIdentifier,
    ntePopperClickHandler,
    seoPath,
  }: INavigateNestedMenu): void => {
    dispatch(REFRESH_SEO_ACTION());

    if (
      currentPath.indexOf(SEE_ALL_CATEGORIES) !== -1 ||
      currentItemIdentifier?.toLowerCase() === CATEGORIES_IDENTIFIER
    ) {
      dispatch(
        GET_CURRENT_CATEGORY_ID_ACTION({
          currentCategoryIdentifier: currentItemIdentifier,
        })
      );

      history.push(CATEGORY_INDEX);

      ntePopperClickHandler();

      return;
    }

    if (
      currentPath === SEE_ALL_BRAND ||
      currentItemIdentifier?.toLowerCase() === BRANDS_IDENTIFIER
    ) {
      dispatch(
        GET_CURRENT_CATEGORY_ID_ACTION({
          currentCategoryIdentifier: currentItemIdentifier,
        })
      );

      history.push(BRAND_INDEX);

      ntePopperClickHandler();

      return;
    }

    dispatch(RESET_PRODUCT_LIST_ACTION({ clearAll: true }));

    history.push(seoPath, { from: history.location.pathname });

    ntePopperClickHandler();
  };

  return { navigateNestedMenu };
};

export { useMenuNavigator };
