import { createAction } from '@reduxjs/toolkit';
import {
  IOrderConfirmation,
  IPoNumber,
} from '../../_foundation/interface/Cart/IOrder';
import IGetUsableShippingResponse from '../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { IShippingMethods } from '../../_foundation/interface/Responses/IShippingInfoResponse';
import * as ACTION_TYPES from '../action-types/orderConfirmation.action-types';

export const FETCH_ORDER_CONFIRMATION_ACTION = createAction<IOrderConfirmation>(
  ACTION_TYPES.FETCH_ORDER_CONFIRMATION_ACTION_REQUEST
);

export const UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION =
  createAction<IShippingMethods>(
    ACTION_TYPES.UPDATE_DELIVERY_SHIPPING_METHOD_DATA
  );

export const FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION =
  createAction<IGetUsableShippingResponse>(
    ACTION_TYPES.FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION
  );

export const FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION = createAction<
  any,
  string
>(ACTION_TYPES.FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION);

export const SET_ORDER_CONFIRMATION_LOADING_ACTION = createAction<any, string>(
  ACTION_TYPES.SET_ORDER_CONFIRMATION_LOADING_ACTION_REQUEST
);

export const SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION = createAction<
  any,
  string
>(ACTION_TYPES.SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION_REQUEST);

export const SET_ORDER_CONFIRMATION_ERROR_ACTION = createAction<any, string>(
  ACTION_TYPES.SET_ORDER_CONFIRMATION_ERROR_ACTION_REQUEST
);

export const RESET_ORDER_CONFIRMATION_ACTION = createAction(
  ACTION_TYPES.RESET_ORDER_CONFIRMATION_ACTION_REQUEST
);

export const ADD_PO_NUMBER_ACTION = createAction<IPoNumber>(
  ACTION_TYPES.ADD_PO_NUMBER_ACTION_REQUEST
);
