import { all } from 'redux-saga/effects';
import * as CHECKOUT from '../../components/Checkout/redux/saga/watchers/checkout.watchers';
import * as SEO from '../../components/Seo/redux/sagas/watchers/seo';
import * as RVI from '../../components/Widgets/Rvi/redux/saga/watchers/rvi.watchers';
import * as AUTH from '../sagas/watchers/auth';
import * as CATEGORIES from '../sagas/watchers/categories.watchers';
import * as BREADCRUMB from './watchers/breadcrumb.watchers';
import * as FORGOT_RESET_PASSWORD from './watchers/forgot-reset-password.watchers';
import * as CART from './watchers/order.watchers';
import * as ORDERDETAILS from './watchers/orderDetails.watchers';
import * as ORDER_LOOKUP from './watchers/orderLookUp.watchers';
import * as PAYMENT from './watchers/payment.watchers';
import * as PICKUP_PERSON from './watchers/pickupPerson.watchers';
import * as PRODUCT_LIST from './watchers/productList.watchers';
import * as PROMO_CODE from './watchers/promoCode.watchers';
import * as SAVED_CARD_DETAILS from './watchers/saved-card-details.watchers';
import * as STORE_LOCATOR from './watchers/storeLocator';
import * as STORE_PICKUP from './watchers/storePickup.watchers';
import * as SUGGESTION from './watchers/suggestion.watchers';
import * as TAX_INFORMATION from './watchers/tax-information.watchers';
import * as USABLE_SHIPPING_INFO from './watchers/usable-shipping-info.watchers';
import * as WISH_LIST from './watchers/wish-list.watchers';

export default function* rootSaga() {
  yield all([
    AUTH.watchSaga(),
    SEO.watchSaga(),
    STORE_LOCATOR.watchSaga(),
    CATEGORIES.watchSaga(),
    PRODUCT_LIST.watchSaga(),
    WISH_LIST.watchSaga(),
    SAVED_CARD_DETAILS.watchSaga(),
    BREADCRUMB.watchSaga(),
    FORGOT_RESET_PASSWORD.watchSaga(),
    TAX_INFORMATION.watchSaga(),
    SUGGESTION.watchSaga(),
    CART.watchSaga(),
    ORDERDETAILS.watchSaga(),
    ORDER_LOOKUP.watchSaga(),
    RVI.watchSaga(),
    USABLE_SHIPPING_INFO.watchSaga(),
    PICKUP_PERSON.watchSaga(),
    PROMO_CODE.watchSaga(),
    STORE_PICKUP.watchSaga(),
    CHECKOUT.watchSaga(),
    PAYMENT.watchSaga(),
  ]);
}
