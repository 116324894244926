import { Paper } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_PRODUCT_FROM_COMPARE_ACTION } from '../../../../../redux/actions/productList.actions';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { ImageUrlUtility } from '../../../../../utils/image-url-utility';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { NteIconButton } from '../../../../NteIconButton/NteIconButton';
import { Thumbnail } from '../../../Thumbnail/Thumbnail';

/**
 * @component ProductComparisonCard renders the Product cards inside the Product Comparison bar.
 */
const ProductComparisonCard: React.FC = () => {
  const {
    productComparison: { compareProducts },
  } = useSelector(productListSelector);

  const dispatch = useDispatch();

  const [placeholderElements, setPlaceHolderElement] = useState<number[]>([]);

  const isProductsAvailable = compareProducts.length !== 0;

  /**
   * @method productRemoveHandler removes the current product from comparison bar.
   */
  const productRemoveHandler = (id: string) => (): void => {
    dispatch(REMOVE_PRODUCT_FROM_COMPARE_ACTION({ id }));
  };

  /**
   * @method initPlaceHolderElements Initialize place holder components based on the
   * number of selected products avaialable in the comparison bar.
   */
  const initPlaceHolderElements = useCallback((): void => {
    if (compareProducts.length !== 0) {
      const elements = 3 - compareProducts.length;

      const placeholderElements: number[] = [];

      for (let i = 0; i <= elements; i++) {
        placeholderElements.push(i);
      }

      setPlaceHolderElement(placeholderElements);
    }
  }, [compareProducts.length]);

  useEffect(() => {
    initPlaceHolderElements();
  }, [compareProducts, initPlaceHolderElements]);

  return (
    <>
      {/* Product Thumbnails. */}
      <ul>
        {isProductsAvailable && (
          <>
            {compareProducts.map(
              (product: IProductListContents, index: number) => (
                <li key={index}>
                  <Thumbnail
                    imgSrc={
                      product?.thumbnailRaw
                        ? ImageUrlUtility.createImgSrc(product?.thumbnailRaw)
                        : ''
                    }
                    imgAlt={product?.name}
                  />

                  <NteIconButton
                    aria-label='Remove product from compare'
                    className='plain-icon-button'
                    onClick={productRemoveHandler(product?.partNumber)}>
                    <CancelIcon aria-label={'remove'} />
                  </NteIconButton>
                </li>
              )
            )}
          </>
        )}
      </ul>

      {/* Placeholder components. */}
      {placeholderElements.map((value: number) => (
        <Paper key={value} className='placeholder' elevation={0}></Paper>
      ))}
    </>
  );
};

export { ProductComparisonCard };
