import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { seoSelector } from '../../../components/Seo/redux/selector/seo';
import { FacetConstants } from '../../../components/Widgets/Facets/Facet/FacetConstants';
import { COLLECTIONS, PROMOTIONS, SHOP_ALL } from '../../../constants/routes';
import { productListSelector } from '../../../redux/selectors/productList.selector';
import { formatToTitleCase } from '../../utils';
import { useCurrentPathName } from '../current-pathname/CurrentPathName';

const useFormattedPath = () => {
  const { CATEGORY_QUERY_KEY } = FacetConstants;

  const { mySite } = useSite();

  const { currentPathName } = useCurrentPathName();

  const { categoryIdentifiers } = useSelector(productListSelector);

  const seoConfig = useSelector(seoSelector);

  const [categoryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const history = useHistory();

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const isBrandPlp = useMemo(
    () => Boolean(seoConfig[formattedPath]?.isBrandPlp),
    [formattedPath, seoConfig]
  );

  const isCollectionPlp = useMemo(() =>
    Boolean(window.location.href.includes(COLLECTIONS)), []);

  const isPromotionPlp = useMemo(() =>
    Boolean(window.location.href.includes(PROMOTIONS)), []);

  /**
   * Responsible to check whether the page is deals plp
   */
  const dealsMatch = useMemo(
    () =>
      mySite?.dealPages
        ?.split(',')
        .find(
          (identifier: string) =>
            identifier === seoConfig[formattedPath]?.identifier
        ),
    [formattedPath, mySite, seoConfig]
  );

  const isCategoryFacetSelection =
    history.location.pathname.includes(SHOP_ALL) ||
    categoryParam ||
    isBrandPlp ||
    dealsMatch ||
    isCollectionPlp ||
    isPromotionPlp ||
    categoryIdentifiers.length !== 0;

  return {
    formattedPath,
    isBrandPlp,
    isCategoryFacetSelection,
    currentPathName,
  };
};

export { useFormattedPath };
