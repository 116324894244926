import * as ROUTES from '../../../../../constants/routes';

// Constants for Account component.
const AccountConstants = {
  ACCOUNT_TITLE: 'APP_BAR.SECONDARY_APP_BAR.ACCOUNT.ACCOUNT_TITLE',
  ACCOUNT_NAME: 'APP_BAR.SECONDARY_APP_BAR.ACCOUNT.ACCOUNT_NAME',
  ACCOUNT_TITLE_FALLBACK: 'Account',
  ACCOUNT_NAME_FALLBACK: "'s Account",
  MENU_ITEMS: {
    ITEMS: [
      {
        item: 'Account Dashboard',
        name: 'ACCOUNT_DASHBOARD',
        route: ROUTES.ACCOUNT_DASHBOARD,
      },
      {
        item: 'My Orders',
        name: 'MY_ORDERS',
        route: ROUTES.MY_ORDERS,
      },
      {
        item: 'My Saved Lists',
        name: 'MY_SAVED_LISTS',
        route: ROUTES.MY_SAVED_LISTS,
      },
      {
        item: 'Sign Out',
        name: 'SIGN_OUT',
        route: ROUTES.HOME,
      },
    ],
  },
};

export { AccountConstants };
