const PaymentServiceConstants = {
  CARD_DETAILS_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/person/@self/checkoutProfile?langId=-1`,
  AUTHENTICATION_KEY_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/tokenex/generateAuthToken`,
  EMAIL_OPT_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/order/email-opt-in`,
};

export { PaymentServiceConstants };
