import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import * as ROUTES from '../../../../constants/routes';
import {
  FETCH_BREADCRUMB_ACTION,
  RESET_BREADCRUMB_ACTION,
} from '../../../../redux/actions/breadcrumb.actions';
import {
  GET_CURRENT_CATEGORY_ID_ACTION,
  RESET_CURRENT_CATEGORY_ID_ACTION,
} from '../../../../redux/actions/categories.actions';
import { breadcrumbSelector } from '../../../../redux/selectors/breadcrumb.selector';
import { categoriesSelector } from '../../../../redux/selectors/categories.selector';
import { productDetailSelector } from '../../../../redux/selectors/productDetail.selector';
import { useCurrentPathName } from '../../../../utils/hooks/current-pathname/CurrentPathName';
import {
  formatBreadCrumbLink,
  formatToTitleCase,
  updateContinueShopping,
} from '../../../../utils/utils';
import { BreadcrumbType } from '../../../../_foundation/enum/Breadcrumbs/Breadcrumbs';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { IBreadcrumbLink } from '../../../../_foundation/interface/Breadcrumb/IBreadcrumb';
import { CategoryIndexPageConstants } from '../../../Pages/CategoryIndexPage/CategoryIndexPageConstants';
import { seoSelector } from '../../../Seo/redux/selector/seo';
import { FacetConstants } from '../../Facets/Facet/FacetConstants';
import { swatchSelector } from '../../Swatch/redux/selector/swatch.selector';
import { BreadcrumbsConstants } from '../BreadcrumbsConstants';

/**
 * @method useBreadcrumb Updates the breadcrumbs either from router link or from the backend response.
 *
 * @param breadcrumbType Determines the type of breadcrumb either static links or links fetched from BE.
 */
const useBreadcrumb = (
  breadcrumbType: BreadcrumbType,
  breadcrumbArray?: IBreadcrumbLink[]
) => {
  const { BRANDS, CATEGORIES, NORTHERN_TOOL, PRODUCT, STORE } =
    BreadcrumbsConstants;

  const { CATEGORY_QUERY_KEY } = FacetConstants;

  const { mySite } = useSite();

  const history = useHistory();

  const { pathname } = useLocation();

  const { productDetails } = useSelector(productDetailSelector);

  const seoConfig = useSelector(seoSelector);

  const [categoryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const { currentPathName } = useCurrentPathName();

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = useMemo(
    () =>
      formatToTitleCase(currentPathName.replace(/-/g, ' ')).replace(/ /g, ''),
    [currentPathName]
  );

  const { partNumber: selectedSku, skuProductDetails } =
    useSelector(swatchSelector);

  const parentChildData =
    productDetails && productDetails?.type.toLowerCase() === PRODUCT;

  const productInfo =
    parentChildData && selectedSku && skuProductDetails
      ? skuProductDetails
      : productDetails;

  const dispatch = useDispatch();

  const isSeeAllDeals: boolean =
    history.location.pathname === ROUTES.SEE_ALL_DEALS;

  const [breadCrumbsLinks, setBreadCrumbsLinks] = useState<
    Array<IBreadcrumbLink>
  >([]);

  const { currentCategoryId, loading: categoriesLoading } =
    useSelector(categoriesSelector);

  const {
    breadCrumbTrailEntryView,
    loading: breadCrumbLoading,
    parentCatalogGroupID,
    breadcrumbErrorResponse,
  } = useSelector(breadcrumbSelector);

  const { ALL_CATEGORIES_IDENTIFIER, ALL_BRANDS_IDENTIFIER } =
    CategoryIndexPageConstants;

  const isNonStaticBreadcrumb =
    breadcrumbType === BreadcrumbType.Category ||
    breadcrumbType === BreadcrumbType.Product;

  const loading =
    breadCrumbLoading && Boolean(currentCategoryId) && isNonStaticBreadcrumb;

  const handleCategoryAndBrandIndexPage = (link: IBreadcrumbLink) => {
    if (link.url === ROUTES.CATEGORY_INDEX) {
      dispatch(
        GET_CURRENT_CATEGORY_ID_ACTION({
          currentCategoryIdentifier: ALL_CATEGORIES_IDENTIFIER,
        })
      );
    } else if (link.url === ROUTES.BRAND_INDEX) {
      dispatch(
        GET_CURRENT_CATEGORY_ID_ACTION({
          currentCategoryIdentifier: ALL_BRANDS_IDENTIFIER,
        })
      );
    }
  };

  /**
   * @method initCategoryBreadcrumbs Fetches the breadcrumb values from the backend response
   * and updates the url's accordingly.
   */
  const initCategoryBreadcrumbs = useCallback((): void => {
    const isBreadcrumbDataAvailable = breadCrumbTrailEntryView.length !== 0;

    if (isBreadcrumbDataAvailable) {
      const anchorLinks: Array<IBreadcrumbLink> = breadCrumbTrailEntryView.map(
        ({ label, seo: { href } }) => {
          if (label === CATEGORIES) {
            return {
              displayText: label,
              url: ROUTES.CATEGORY_INDEX,
            };
          } else if (label === BRANDS) {
            return {
              displayText: label,
              url: ROUTES.BRAND_INDEX,
            };
          }

          return {
            displayText: label ? label : '',
            url: href,
          };
        }
      );

      const homeLink: IBreadcrumbLink = {
        displayText: NORTHERN_TOOL,
        url: ROUTES.HOME,
      };

      anchorLinks.unshift(homeLink);

      /**
       * Incase of product breadcrumbs append the name of the product as the last breadcrumb.
       */
      if (breadcrumbType === BreadcrumbType.Product) {
        anchorLinks.push({
          displayText: `Item# ${productInfo.partNumber}`,
          url: '/',
        });
      }

      setBreadCrumbsLinks(anchorLinks);

      if (productInfo) {
        if (productInfo.partNumber) {
          updateContinueShopping({
            productPartNumber: productInfo.partNumber,
            topCategoryLink: anchorLinks[2]?.url ? anchorLinks[2].url : '',
            topCategoryName: anchorLinks[2]?.displayText
              ? anchorLinks[2].displayText
              : '',
          });
        }
      }
    }
  }, [
    BRANDS,
    CATEGORIES,
    NORTHERN_TOOL,
    breadCrumbTrailEntryView,
    breadcrumbType,
    productInfo,
  ]);

  /**
   * @method initializeBreadCrumbLinks Fetches the breadcrumbs from the browser url.
   */
  const initializeBreadCrumbLinks = useCallback(() => {
    /* Logic to parse the url to form the links in the breadcrumbs.
    1. We separate all the sublinks past the category Keyword.
    2. We form the breadcrumb links with the url.
    3. We render all the breadcrumb links based on the url.
    */
    let allLinks: string[] = [];

    let anchorLinks: Array<IBreadcrumbLink> = [];

    if (breadcrumbArray && breadcrumbArray?.length !== 0) {
      anchorLinks = [
        { displayText: NORTHERN_TOOL, url: ROUTES.HOME },
        ...breadcrumbArray,
      ];
    } else {
      allLinks = pathname.split('/');
      anchorLinks = allLinks.map((link, index) => {
        return {
          displayText: formatBreadCrumbLink(link),
          url: `${allLinks.slice(0, index + 1).join('/')}`,
        };
      });
      anchorLinks[0].displayText = NORTHERN_TOOL;
      anchorLinks[0].url = ROUTES.HOME;
      if (anchorLinks[1].displayText === STORE) {
        anchorLinks[1].url = ROUTES.STORE_INDEX;
      }
    }

    setBreadCrumbsLinks(anchorLinks);
  }, [NORTHERN_TOOL, STORE, breadcrumbArray, pathname]);

  /**
   * @method initBreadcrumbs Initializes the breadcrumbs.
   */
  const initBreadcrumbs = useCallback(() => {
    if (!loading) {
      if (
        breadcrumbType === BreadcrumbType.Category ||
        breadcrumbType === BreadcrumbType.Product
      ) {
        initCategoryBreadcrumbs();
      } else if (breadcrumbType === BreadcrumbType.Static) {
        initializeBreadCrumbLinks();
      }
    }
  }, [
    loading,
    breadcrumbType,
    initCategoryBreadcrumbs,
    initializeBreadCrumbLinks,
  ]);

  useEffect(() => {
    initBreadcrumbs();
  }, [initBreadcrumbs, initializeBreadCrumbLinks, loading, pathname]);

  useEffect(() => {
    if (breadcrumbErrorResponse) {
      initializeBreadCrumbLinks();
    }
  }, [breadcrumbErrorResponse, initializeBreadCrumbLinks]);

  /**
   * @method initBreadcrumbsData Dispatches events to fetch category breadcrumbs.
   */
  const initBreadcrumbsData = useCallback((): void => {
    const isProductBreadcrumb = breadcrumbType === BreadcrumbType.Product;

    if (mySite && !categoryParam) {
      /**
       * This block is responsible for fetching breadcrumb data for the category plp.
       */
      if (!categoriesLoading && !isProductBreadcrumb) {
        /**
         * This block will fetch breadcrumbs data based on the current category id.
         */
        if (currentCategoryId && !isSeeAllDeals) {
          dispatch(
            FETCH_BREADCRUMB_ACTION({
              catalogId: mySite.catalogID,
              categoryId: currentCategoryId,
              storeID: mySite.storeID,
            })
          );
        }
      }
    }
  }, [
    breadcrumbType,
    mySite,
    categoryParam,
    categoriesLoading,
    currentCategoryId,
    isSeeAllDeals,
    dispatch,
  ]);

  useEffect(() => {
    initBreadcrumbsData();
  }, [initBreadcrumbsData]);

  const initDealsBreadcrumbs = useCallback((): void => {
    const isProductBreadcrumb = breadcrumbType === BreadcrumbType.Product;

    if (mySite) {
      if (!categoriesLoading && !isProductBreadcrumb) {
        /**
         * This block will fetch breadcrumbs for see all deals page
         */
        if (
          isSeeAllDeals &&
          currentCategoryId &&
          seoConfig[formattedPath] &&
          seoConfig[formattedPath]?.tokenValue
        ) {
          dispatch(
            FETCH_BREADCRUMB_ACTION({
              catalogId: mySite.catalogID,
              categoryId: currentCategoryId,
              storeID: mySite.storeID,
            })
          );
        }
      }
    }
  }, [
    breadcrumbType,
    categoriesLoading,
    currentCategoryId,
    dispatch,
    formattedPath,
    isSeeAllDeals,
    mySite,
    seoConfig,
  ]);

  useEffect(() => {
    initDealsBreadcrumbs();
  }, [initDealsBreadcrumbs]);

  const initPdpBreadcrumbs = useCallback((): void => {
    const isProductBreadcrumb = breadcrumbType === BreadcrumbType.Product;

    if (mySite) {
      /**
       * This block fetches breadcrumbs data for product details page based on current product's parentCatalogGroupID.
       */
      if (!categoriesLoading && isProductBreadcrumb && parentCatalogGroupID) {
        let catalogGroupIds: string[] = [];

        if (Array.isArray(parentCatalogGroupID)) {
          catalogGroupIds = parentCatalogGroupID[0].split('/');
        } else {
          catalogGroupIds = parentCatalogGroupID.split('/');
        }

        dispatch(
          FETCH_BREADCRUMB_ACTION({
            catalogId: mySite.catalogID,
            categoryId: catalogGroupIds[catalogGroupIds.length - 1],
            storeID: mySite.storeID,
          })
        );
      }
    }
  }, [
    breadcrumbType,
    categoriesLoading,
    dispatch,
    mySite,
    parentCatalogGroupID,
  ]);

  useEffect(() => {
    initPdpBreadcrumbs();
  }, [initPdpBreadcrumbs]);

  useEffect(() => {
    return () => {
      /**
       * Clear the breadcrumbs data whenever a route change happens on component unmount.
       */
      dispatch(RESET_CURRENT_CATEGORY_ID_ACTION());

      dispatch(RESET_BREADCRUMB_ACTION());
    };
  }, [dispatch]);

  return { breadCrumbsLinks, loading, handleCategoryAndBrandIndexPage };
};

export { useBreadcrumb };
