
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IProductsResponse from './IProductsResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IProductsResponse };

  export const IProductsResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IAttributesItem": {
            "properties": {
                "comparable": {
                    "type": "string"
                },
                "displayable": {
                    "type": "string"
                },
                "facetable": {
                    "type": "string"
                },
                "identifier": {
                    "type": "string"
                },
                "merchandisable": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "searchable": {
                    "type": "string"
                },
                "sequence": {
                    "type": "string"
                },
                "storeDisplay": {
                    "type": "string"
                },
                "swatchable": {
                    "type": "string"
                },
                "uniqueID": {
                    "type": "string"
                },
                "usage": {
                    "type": "string"
                },
                "values": {
                    "items": {
                        "$ref": "#/definitions/IValuesItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "identifier",
                "name",
                "uniqueID",
                "values"
            ],
            "type": "object"
        },
        "IBreadCrumbTrailEntryViewExtendedItem": {
            "properties": {
                "label": {},
                "type_": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IBreadCrumbTrailEntryViewItem": {
            "properties": {
                "label": {},
                "type_": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "ICatalogEntryViewItem": {
            "properties": {
                "UserData": {
                    "items": {
                        "$ref": "#/definitions/IUserDataItem"
                    },
                    "type": "array"
                },
                "_ignored": {
                    "type": "string"
                },
                "attributes": {
                    "items": {
                        "$ref": "#/definitions/IAttributesItem"
                    },
                    "type": "array"
                },
                "auxDescription1": {
                    "type": "string"
                },
                "auxDescription2": {
                    "type": "string"
                },
                "buyable": {
                    "type": "string"
                },
                "catalogEntryTypeCode": {
                    "type": "string"
                },
                "fullImage": {
                    "type": "string"
                },
                "fullImageRaw": {
                    "type": "string"
                },
                "hasSingleSKU": {
                    "type": "boolean"
                },
                "inventories.total.quantity": {
                    "type": "string"
                },
                "manufacturer": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "parentCatalogGroupID": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                },
                "partNumber": {
                    "type": "string"
                },
                "price": {
                    "items": {
                        "$ref": "#/definitions/IPriceItem"
                    },
                    "type": "array"
                },
                "resourceId": {
                    "type": "string"
                },
                "salesPriceRestricted": {
                    "type": "string"
                },
                "seo": {
                    "$ref": "#/definitions/ISeo"
                },
                "shortDescription": {
                    "type": "string"
                },
                "storeID": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                },
                "thumbnailRaw": {
                    "type": "string"
                },
                "uniqueID": {
                    "type": "string"
                }
            },
            "required": [
                "name",
                "partNumber",
                "price",
                "uniqueID"
            ],
            "type": "object"
        },
        "IEOfferPriceItem": {
            "properties": {
                "maximumQuantity": {
                    "type": "string"
                },
                "minimumQuantity": {
                    "type": "string"
                },
                "offerPrice": {
                    "type": "string"
                }
            },
            "required": [
                "offerPrice"
            ],
            "type": "object"
        },
        "IEntryItem": {
            "properties": {
                "count": {
                    "type": [
                        "string",
                        "number"
                    ]
                },
                "extendedData": {
                    "$ref": "#/definitions/IExtendedData"
                },
                "label": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "extendedData",
                "label",
                "value"
            ],
            "type": "object"
        },
        "IExtendedData": {
            "properties": {
                "allValuesReturned": {
                    "type": "string"
                },
                "allowMultipleValueSelection": {
                    "type": "string"
                },
                "displaySequence": {
                    "type": "string"
                },
                "displayable": {
                    "type": "string"
                },
                "fdesc": {
                    "type": "string"
                },
                "fname": {
                    "type": "string"
                },
                "max_display": {
                    "type": "string"
                },
                "maximumValuesToDisplay": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "parentIds": {
                    "type": "string"
                },
                "propertyId": {
                    "type": "string"
                },
                "propertyvalue": {
                    "type": "string"
                },
                "sequence": {
                    "type": "string"
                },
                "sortorder": {
                    "type": "string"
                },
                "uniqueId": {
                    "type": "string"
                },
                "zero_display": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IFacetViewItem": {
            "properties": {
                "entry": {
                    "items": {
                        "$ref": "#/definitions/IEntryItem"
                    },
                    "type": "array"
                },
                "extendedData": {
                    "$ref": "#/definitions/IExtendedData"
                },
                "name": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "entry",
                "name",
                "value"
            ],
            "type": "object"
        },
        "IMetaData": {
            "properties": {
                "price": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IPriceItem": {
            "properties": {
                "contractId": {
                    "type": "string"
                },
                "currency": {
                    "type": "string"
                },
                "description": {
                    "type": "string"
                },
                "usage": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "usage",
                "value"
            ],
            "type": "object"
        },
        "ISeo": {
            "properties": {
                "href": {
                    "type": "string"
                }
            },
            "required": [
                "href"
            ],
            "type": "object"
        },
        "IUserDataItem": {
            "properties": {
                "eOfferPrice": {
                    "items": {
                        "$ref": "#/definitions/IEOfferPriceItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "eOfferPrice"
            ],
            "type": "object"
        },
        "IValuesItem": {
            "properties": {
                "identifier": {
                    "type": "string"
                },
                "sequence": {
                    "type": "string"
                },
                "uniqueID": {
                    "type": "string"
                },
                "unitID": {
                    "type": "string"
                },
                "unitOfMeasure": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "identifier",
                "uniqueID",
                "value"
            ],
            "type": "object"
        }
    },
    "properties": {
        "breadCrumbTrailEntryView": {
            "items": {
                "$ref": "#/definitions/IBreadCrumbTrailEntryViewItem"
            },
            "type": "array"
        },
        "breadCrumbTrailEntryViewExtended": {
            "items": {
                "$ref": "#/definitions/IBreadCrumbTrailEntryViewExtendedItem"
            },
            "type": "array"
        },
        "catalogEntryView": {
            "items": {
                "$ref": "#/definitions/ICatalogEntryViewItem"
            },
            "type": "array"
        },
        "facetView": {
            "items": {
                "$ref": "#/definitions/IFacetViewItem"
            },
            "type": "array"
        },
        "metaData": {
            "$ref": "#/definitions/IMetaData"
        },
        "recordSetComplete": {
            "type": "string"
        },
        "recordSetCount": {
            "type": "number"
        },
        "recordSetStartNumber": {
            "type": "number"
        },
        "recordSetTotal": {
            "type": "number"
        },
        "recordSetTotalMatches": {
            "type": "number"
        },
        "resourceId": {
            "type": "string"
        },
        "resourceName": {
            "type": "string"
        }
    },
    "type": "object"
}

;

  export const isIProductsResponse = ajv.compile(IProductsResponseSchema) as ValidateFunction<
    IProductsResponse
  >;

  export default function validateIProductsResponse(value: unknown): IProductsResponse {
    if (isIProductsResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIProductsResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IProductsResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIProductsResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IProductsResponse' },
        ) +
          inspect(value)
      );
    }
  }