import { createReducer, current } from '@reduxjs/toolkit';
import { ICardTokenization } from '../../_foundation/apis/payment/IPayment';
import { ITokenexResponse } from '../../_foundation/apis/payment/payment.service';
import {
  TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS,
  TOKENIZE_CREDIT_DEBIT_CARD_FAILURE,
} from '../action-types/payment.action-types';
import {
  INIT_AUTHENTICATION_KEY_SUCCESS_ACTION,
  RESET_AUTHENTICATION_KEY_ACTION,
} from '../actions/payment.action';

const initialState: ICardTokenization = {
  authKey: '',
  timeStamp: '',
  isCardTokenized: false,
  token: '',
  referenceNumber: '',
  tokenizeAPIFailure: false,
  isInvalidCardNumber: false,
  errorMessage: '',
};

const paymentReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    INIT_AUTHENTICATION_KEY_SUCCESS_ACTION,
    (state: ICardTokenization, action: any) => {
      const { authKey, timeStamp } = current(state);

      if (!authKey && !timeStamp) {
        const { authKey, timeStamp } = action.payload as ICardTokenization;

        return { ...state, authKey, timeStamp };
      }
    }
  );

  builder.addCase(RESET_AUTHENTICATION_KEY_ACTION, () => {
    return { ...initialState };
  });

  builder.addCase(
    TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS,
    (state: ICardTokenization, action: any) => {
      const { Token, ReferenceNumber } = action.payload as ITokenexResponse;

      return {
        ...state,
        token: Token,
        referenceNumber: ReferenceNumber,
        isCardTokenized: true,
      };
    }
  );

  builder.addCase(
    TOKENIZE_CREDIT_DEBIT_CARD_FAILURE,
    (state: ICardTokenization, action: any) => {
      const { Error, ReferenceNumber } = action.payload as ITokenexResponse;
      if (
        Error === '2001: Input data not Luhn compatible' ||
        Error === '2004: Input data must be a valid credit card length'
      ) {
        return {
          ...state,
          tokenizeAPIFailure: true,
          isInvalidCardNumber: true,
          referenceNumber: ReferenceNumber,
        };
      } else {
        return {
          ...state,
          tokenizeAPIFailure: true,
          errorMessage: Error ? Error : '',
          referenceNumber: ReferenceNumber,
        };
      }
    }
  );
});

export { paymentReducer };
