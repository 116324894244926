import { createReducer } from '@reduxjs/toolkit';
import { SHOW_CART_DRAWER_ACTION } from '../actions/order.actions';

const initShowMiniCartStatus: any = {
  showMiniCart: false,
};

interface IShowMiniCartState {
  showMiniCart: boolean;
}

const cartReducer = createReducer(initShowMiniCartStatus, (builder) => {
  /**
   * @action SHOW_CART_DRAWER_ACTION
   * set the state for minicart
   */

  builder.addCase(
    SHOW_CART_DRAWER_ACTION,
    (state: IShowMiniCartState, action: any) => {
      return {
        ...state,
        showMiniCart: action.payload,
      };
    }
  );
});

export { cartReducer };
