import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CATEGORY_PAGE,
  PRODUCT_LIST_PAGE,
} from '../../../constants/PageComposerRoutes';
import {
  GET_CURRENT_CATEGORY_ID_ACTION,
  SET_CURRENT_CATEGORY_ID_ACTION,
} from '../../../redux/actions/categories.actions';
import { categoriesSelector } from '../../../redux/selectors/categories.selector';
import { useCurrentPathName } from '../../../utils/hooks/current-pathname/CurrentPathName';
import { formatToTitleCase } from '../../../utils/utils';
import { seoSelector } from '../redux/selector/seo';

/**
 * @method useUpdateCurrentCategory updates the current category in redux to load sub categories.
 */
const useUpdateCurrentCategory = () => {
  const { categoriesData } = useSelector(categoriesSelector);

  const { currentPathName } = useCurrentPathName();

  const seoConfig = useSelector(seoSelector);

  const dispatch = useDispatch();

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  /**
   * @method initCurrentCategory Fetches the current category items.
   */
  const initCurrentCategory = useCallback(
    (currentPathName: string): void => {
      if (
        currentPathName &&
        seoConfig &&
        seoConfig[formattedPath] &&
        seoConfig &&
        seoConfig[formattedPath].page.type === CATEGORY_PAGE
      ) {
        dispatch(
          GET_CURRENT_CATEGORY_ID_ACTION({
            currentCategory: `/${currentPathName}`,
          })
        );
      }

      if (
        currentPathName &&
        seoConfig &&
        seoConfig[formattedPath] &&
        seoConfig[formattedPath].page.type === PRODUCT_LIST_PAGE
      ) {
        dispatch(
          SET_CURRENT_CATEGORY_ID_ACTION({
            categoryId: seoConfig[formattedPath]?.tokenValue,
          })
        );
      }
    },
    [dispatch, formattedPath, seoConfig]
  );

  useEffect(() => {
    initCurrentCategory(currentPathName);
  }, [currentPathName, categoriesData, initCurrentCategory]);
};

export { useUpdateCurrentCategory };
