const CartServiceConstants = {
  ADD_TO_CART_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/cart?langId=-1`,
  GET_CART_DETAILS: (storeID: string, isCartPage: boolean) =>
    `/wcs/resources/store/${storeID}/cart/@self?langId=-1&sortOrderItemBy=orderItemID${
      isCartPage ? '&cartPage=true' : ''
    }`,
  UPDATE_CART_DETAILS: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/update_order_item`,
  DELETE_ITEM_IN_CART: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/delete_order_item`,
  GET_CART_PRODUCTS: (storeID: string, queryParams: string) =>
    `/search/resources/api/v2/products?${queryParams}&storeId=${storeID}&profileName=${process.env.REACT_APP_PROFILE_NAME_FIND_BY_PART_NUMBER}`,
  GET_SHIPPING_INFO: (
    storeID: string,
    physicalStoreId: string,
    orderId: string
  ) => `
    /wcs/resources/store/${storeID}/storelocator/shipping?siteLevelStoreSearch=false&physicalStoreId=${physicalStoreId}&orderId=${orderId}`,
  UPDATE_PAYMENT_INFO: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/payment_instruction?langId=-1`,
  GIFT_CARD_BALANCE_INFO: (storeID: string) =>
    `/wcs/resources/store/${storeID}/payment/giftCardBalance`,
  UPDATE_SHIPPING_INFO: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/shipping_info?langId=-1`,
  FETCH_USABLE_PAYMENT_INFO: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/usable_payment_info?langId=-1`,
  PRECHECKOUT: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/@self/precheckout?langId=-1`,
  CHECKOUT: (storeId: string) =>
    `/wcs/resources/store/${storeId}/cart/@self/checkout?langId=-1`,
  ORDER_CONFIRMATION: (storeId: string, orderId: string) =>
    `/wcs/resources/store/${storeId}/order/${orderId}?langId=-1`,
  ORDER_CONFIRMATION_PAYLOAD: {
    notifyOrderSubmitted: '1',
    notifyMerchant: '1',
    notifyShopper: '1',
  },
  REMOVE_PAYMENT_INFO: (storeId: string, paymentInstructionId: string) =>
    `/wcs/resources/store/${storeId}/cart/@self/payment_instruction/${paymentInstructionId}`,
  PO_NUMBER_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/order/po-number`,
  CATALOG_ORDER_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/catalogorderform`,
  ORDER_CALCULATE_URL: (storeID: string) =>
    `/wcs/resources/store/${storeID}/cart/calculate`,
  GIFT_CARD_PAYMENT_BILLING_ADDRESS_UPDATE_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/cart/@self/payment_instruction?langId=-1`,
  DEFAULT_QUANTITY: '1',
};

export { CartServiceConstants };
