const translationKey = 'ACCOUNT';

const AccountSignInConstants = {
  SIGN_IN_ALERT_MODAL: `${translationKey}.SIGN_IN_ALERT_MODAL`,
  SIGN_IN_TITLE_MODAL: `${translationKey}.SIGN_IN_TITLE_MODAL`,
  SIGN_IN_PARAM_KEY: 'priceSource',
  SIGN_IN_PARAM_VALUE: 'signIn',
};

export { AccountSignInConstants };
