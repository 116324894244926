import { createReducer } from '@reduxjs/toolkit';
import { IPricing } from '../../_foundation/interface/Pricing/IPricing';
import {
  RESET_EXTENDED_PRICING_ACTION,
  UPDATE_PRODUCT_EXTENDED_PRICING_ACTION,
  UPDATE_PRODUCT_PRICING_ACTION,
} from '../actions/extendedPricing.action';

/**
 * @interface IExtendedPricingState
 */
export interface IExtendedPricingState {
  extendedPricing: IPricing;
  pdpExtendedPricing: IPricing;
  loading: boolean;
}

const initExtendedPricingState: IExtendedPricingState = {
  extendedPricing: {
    EntitledPrice: [],
  },
  pdpExtendedPricing: {
    EntitledPrice: [],
  },
  loading: true,
};

const extendedPricingReducer = createReducer(
  initExtendedPricingState,
  (builder) => {
    builder.addCase(
      UPDATE_PRODUCT_PRICING_ACTION,
      (state: IExtendedPricingState, action: any) => {
        return {
          ...state,
          extendedPricing: action.payload,
          loading: false,
        };
      }
    );

    builder.addCase(RESET_EXTENDED_PRICING_ACTION, () => {
      return { ...initExtendedPricingState };
    });

    builder.addCase(
      UPDATE_PRODUCT_EXTENDED_PRICING_ACTION,
      (state: IExtendedPricingState, action: any) => {
        return { ...state, pdpExtendedPricing: action.payload, loading: false };
      }
    );
  }
);

export { extendedPricingReducer };
