import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';

/**
 * @interface NteSwitch
 */
interface INteSwitch {
  /**
   * @prop data for NteSwitch to check whether switch is checked or not.
   */
  checkedState: boolean;

  /**
   * @prop data for NteSwitch to set the checked state
   */
  setCheckedState: (val: any) => void;

  /**
   * @prop data for NteSwitch to show the label
   */
  label?: string;
}

/**
 * @component NteSwitch renders the Switch Widget.
 *
 * @param INteSwitch
 */
const NteSwitch: React.FC<INteSwitch> = ({
  checkedState,
  setCheckedState,
  label,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedState((prev: any) => !prev);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checkedState}
          onChange={handleChange}
          name='nte-switch'
        />
      }
      label={label}
    />
  );
};

export { NteSwitch };
