import { takeLatest } from 'redux-saga/effects';
import { FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION, FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION } from '../../actions/usable-shipping-info';
import { getExtendedUsableShippingInfo, getUsableShippingInfoSaga } from '../workers/usable-shipping-info.workers';

export function* watchSaga() {
  yield takeLatest(
    FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION,
    getUsableShippingInfoSaga
  );

  yield takeLatest(
    FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION,
    getExtendedUsableShippingInfo
  );
}
