import { createReducer } from '@reduxjs/toolkit';
import { IPickupPersonState } from '../../_foundation/interface/PickupPerson/IPickupPerson';

const initialState: IPickupPersonState = {
  pickupPerson: [],
};

const pickupPersonReducer = createReducer(initialState, (builder) => {});

export { pickupPersonReducer };
