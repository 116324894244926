import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackUserEvent } from '../../utils/lucky-orange';
import { TIER1_ERROR } from '../../_foundation/constants/lo-events';

/**
 * @interface IApiDetailsErrorWrapper
 */
interface IApiDetailsErrorWrapper {
  api?: string;
  apiResponsePayload?: string;
  apiResponseHttpCode?: string;
  triggerLOLog?: boolean;
}

/**
 * @component ApiDetailsErrorWrapper renders the api details error section.
 */
const ApiDetailsErrorWrapper: React.FC<IApiDetailsErrorWrapper> = ({
  api,
  apiResponseHttpCode,
  apiResponsePayload,
  triggerLOLog,
}) => {
  const { pathname } = useLocation();

  const triggerUserEvent = useCallback((): void => {
    if (triggerLOLog) {
      const errorData = {
        url: pathname,
        api: api,
        apiResponsePayload: apiResponsePayload,
        apiResponseHttpCode: apiResponseHttpCode,
      };

      trackUserEvent({
        eventName: TIER1_ERROR,
        eventMsg: errorData,
      });
    }
  }, [pathname, apiResponsePayload, apiResponseHttpCode, api, triggerLOLog]);

  useEffect(() => {
    triggerUserEvent();
  }, [triggerUserEvent]);

  return (
    <div id='API_Error_Details' className='api-error-details'>
      <div id='URL'>{pathname}</div>

      <div id='API'>{api}</div>

      <div id='API_Response'>{apiResponsePayload}</div>

      <div id='API_Response_HttpCode'>{apiResponseHttpCode}</div>
    </div>
  );
};

export { ApiDetailsErrorWrapper };
