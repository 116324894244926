const OrderPOInformationConstants = {
  PO_NUMBER_FIELD: {
    id: 'po-number-id',
    label: 'PO Number',
    name: 'poNumber',
    type: 'text',
    sensitiveData: true,
    maxLength: 25,
  },
  ORDER_INFORMATION: 'Order Information',
  ADD_PO_NUMBER: 'Add a PO Number',
  PO_ERROR_TEXT: 'ACCOUNT.SHIPPING_ADDRESS.MESSAGE.ERROR.INVALID_PO_NUMBER',
  PO_NUMBER_REGEX:
    '^[a-zA-Z0-9 !\\"#$%&\\\'()*+,-./:;<=>?@\\[\\\\\\]^_`{|}~]*$',
};

export { OrderPOInformationConstants };
