import { Grid, ListItem } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavBarMenuConstants } from '../../AppBar/PrimaryMenuItems/NavBarMenuItem/NavBarMenuConstants';

/**
 * @interface IMenuListItem
 */
interface IMenuListItem {
  /**
   * @prop isNestedMenu
   * Determines whether the menu
   * item has nested menu
   */
  isNestedMenu?: boolean;

  /**
   * @prop menuItem
   * Individual menu item to be rendered
   * in the list.
   */
  menuItem: any;

  /**
   * @prop currentItemIndex
   * Currently selected menu item index.
   */
  currentItemIndex: number;

  /**
   * @prop clickHandler handles the click events for the menu list item.
   */
  clickHandler?: (selectedEvent?: any) => void;

  /**
   * @prop icon to be rendered in the list item.
   */
  icon?: JSX.Element;

  /**
   * @prop current item to be compared to current menu list item.
   */
  currentItem?: string | string[];
}

/**
 * @component MenuListItem renders the menu item list.
 *
 * @param IMenuListItem
 */
const MenuListItem: React.FC<IMenuListItem> = ({
  isNestedMenu,
  menuItem,
  currentItemIndex,
  clickHandler,
  icon,
  currentItem,
}) => {
  const isNested = isNestedMenu || menuItem.childrenElement;

  const { SEE_ALL } = NavBarMenuConstants;

  const { t } = useTranslation();

  const setCurrentMenu = (): void => {
    if (clickHandler) {
      if (isNested) {
        clickHandler({
          isNestedMenu: isNested,
          currentItem: menuItem.item,
          currentItemIdentifier: menuItem.identifier,
        });
      } else {
        clickHandler({
          currentItemIndex,
          currentItem: menuItem.item,
          currentItemIdentifier: menuItem.identifier,
          ...{ ...(menuItem.seo && { currentPath: menuItem.seo?.href }) },
        });
      }
    }
  };

  let showIcon = false;

  if (Array.isArray(currentItem)) {
    showIcon = currentItem?.findIndex((item) => item === menuItem.item) !== -1;
  } else {
    showIcon = currentItem === menuItem.item;
  }

  // opening a new tab for menu items with right click
  let clickLink = '';

  if (menuItem.seo?.href) {
    clickLink = menuItem.seo?.href;
  } else if (menuItem.route) {
    clickLink = menuItem.route;
  }

  return (
    <ListItem
      divider={true}
      role='listitem'
      component='li'
      onClick={setCurrentMenu}
      {...(isNestedMenu && { className: 'child-menu-active' })}
      button>
      <Grid
        alignItems='center'
        component='a'
        {...(isNested && { role: 'button' })}
        rel='nofollow'
        role='button'
        className='no-link-effect'
        container
        item
        onClick={(e: any) => {
          if (!menuItem.isTel) {
            e.preventDefault();
          }
        }}
        {...(menuItem.href && { href: menuItem.href })}
        justifyContent='space-between'>
        {showIcon && (
          <span aria-label='Already Saved'>
            <CheckCircleIcon aria-label='already-saved' />
          </span>
        )}
        {!isNested && clickLink ? (
          <a
            className={
              menuItem.item.includes(t(SEE_ALL))
                ? 'menu-link-wrapper bold-weight'
                : 'menu-link-wrapper'
            }
            href={clickLink}>
            {menuItem.item}
          </a>
        ) : (
          <span className='menu-link-wrapper'>{menuItem.item}</span>
        )}

        {isNested && <ChevronRightIcon fontSize='small' />}
      </Grid>
    </ListItem>
  );
};

export { MenuListItem };
