import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { UserType } from '../../../../../_foundation/enum/User/UserType';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { useDealsCheck } from '../../../../../utils/hooks/deals-check/DealsCheckHooks';
import { useFormattedPath } from '../../../../../utils/hooks/formatted-path/formatted-path';
import {
  enterKeyPressHandler,
  removeQueryParamBackslash,
} from '../../../../../utils/utils';
import { CategoryFacet } from '../CategoryFacet/CategoryFacet';
import { FacetCheckbox } from '../FacetCheckbox/FacetCheckbox';
import { FacetConstants } from '../FacetConstants';
import { FacetBodyConstants } from './FacetBodyConstants';

/**
 * @interface IFacetBody
 */
interface IFacetBody {
  filterData: IProductListFacetsEntry[];
  isPriceFacet?: boolean;
  facetGroup: string;
  parentValue?: string;
  isRatings?: boolean;
}

/**
 * @component FacetBody renders the body section of the facet component.
 *
 * @param IFacetBody
 */
const FacetBody: React.FC<IFacetBody> = ({
  filterData,
  isPriceFacet = false,
  facetGroup,
  parentValue,
  isRatings,
}) => {
  const {
    FACET_QUERY_KEY,
    PARENT_CATALOG_GROUP,
    BRAND_FACET_KEY,
    RATINGS,
    ADVANTAGE_EXCLUSIVE,
  } = FacetConstants;

  const { userType } = useSelector(authenticationSelector);

  const {
    DEFAULT_FILTER_VALUES,
    SEE_MORE,
    SEE_LESS,
    PROMOTION,
    BRANDS,
    CATEGORIES,
  } = FacetBodyConstants;

  const history = useHistory();

  const { mySite } = useSite();

  const { selectedGroups } = useSelector(productListSelector);

  const { isCategoryFacetSelection } = useFormattedPath();

  const { isDealsPage } = useDealsCheck();

  const sortedFilterData = [...filterData];

  if (facetGroup === PARENT_CATALOG_GROUP || facetGroup === BRAND_FACET_KEY) {
    /**
     * responsible to sort the facet label by ASC
     */
    sortedFilterData?.sort((facet1, facet2) => {
      if (facet1?.label < facet2?.label) {
        return -1;
      }
      if (facet1?.label > facet2?.label) {
        return 1;
      }
      return 0;
    });
  }

  if (facetGroup === RATINGS) {
    /**
     * responsible to sort the facet label by DESC
     */
    sortedFilterData?.sort((facet1, facet2) => {
      if (facet1?.label > facet2?.label) {
        return -1;
      }
      if (facet1?.label < facet2?.label) {
        return 1;
      }
      return 0;
    });
  }

  // Extract only the selectedFactes label.
  const selectedValues = selectedGroups[facetGroup]
    ? selectedGroups[facetGroup].map(({ label }) => label?.toLowerCase())
    : '';

  // Extracts the param values from url.
  const encodedSearchValues = useLocation().search;

  const searchValues = encodedSearchValues
    ? removeQueryParamBackslash(encodedSearchValues)
    : '';

  const facetsFromQueryParams = new URLSearchParams(searchValues).getAll(
    FACET_QUERY_KEY
  );

  /**
   * Check if the specific facet groups value is already present in the query
   * params or in the selectedFactes array, if so mark that selected facets index.
   */
  const selectedFacetIndex = sortedFilterData.findIndex(
    ({ label }) =>
      facetsFromQueryParams.includes(label.toLowerCase()) ||
      selectedValues.includes(label.toLowerCase())
  );

  const [seeMore, setSeeMore] = useState<boolean>(
    // If the selectedfacets index is greated that 5 the list will be expanded by default.
    selectedFacetIndex >= DEFAULT_FILTER_VALUES
  );

  const [initalValues, setInitialValues] = useState<number>(
    DEFAULT_FILTER_VALUES
  );

  const { t } = useTranslation();

  const facetDisplayValues = [
    ...sortedFilterData.filter(
      ({ label }) =>
        label.toLowerCase() !== t(BRANDS).toLowerCase() &&
        label.toLowerCase() !== t(CATEGORIES).toLowerCase()
    ),
  ];

  /**
   * @callback toggleSeeMore Toggles between the see more and see less state.
   */
  const toggleSeeMore = useCallback((): void => {
    if (seeMore) {
      setInitialValues(sortedFilterData.length);
    } else {
      if (sortedFilterData.length === facetDisplayValues.length) {
        setInitialValues(DEFAULT_FILTER_VALUES);
      } else {
        setInitialValues(facetDisplayValues.length);
      }
    }
  }, [
    DEFAULT_FILTER_VALUES,
    facetDisplayValues.length,
    sortedFilterData.length,
    seeMore,
  ]);

  useEffect(() => {
    toggleSeeMore();
  }, [toggleSeeMore, sortedFilterData.length, seeMore]);

  const seeMoreClickHandler = (): void => setSeeMore((seeMore) => !seeMore);

  /**
   * By default restrict the number of filters to 5, when the
   * user clicks on see more display all the filters.
   */
  const showSeeMore =
    !seeMore && sortedFilterData.length > DEFAULT_FILTER_VALUES;

  if (
    facetGroup === t(PROMOTION) &&
    sortedFilterData.length > 1 &&
    parentValue
  ) {
    let allDealsCount = 0;

    sortedFilterData.forEach((facet) => {
      if (userType === UserType.Advantage) {
        allDealsCount += Number(facet.count);
      } else {
        if (!facet.label.includes(ADVANTAGE_EXCLUSIVE)) {
          allDealsCount += Number(facet.count);
        }
      }
    });

    const allDeals: IProductListFacetsEntry = {
      count: allDealsCount.toString(),
      extendedData: {
        uniqueId: '',
      },
      label: 'All Deals',
      value:
        userType === UserType.Advantage
          ? mySite.allDealsAdvantageFilter
          : mySite.allDealsNonAdvantageFilter,
      isPriceFacet: false,
    };

    const isAllDealsPresent =
      Boolean(mySite.allDealsAdvantageFilter) ||
      Boolean(mySite.allDealsNonAdvantageFilter);

    /**
     * Check if all deals facet config.
     *
     * If all deals facet config is present display all deals facet checkbox.
     *
     * If all deals facet config is not present print error message in the console.
     */
    if (isAllDealsPresent) {
      facetDisplayValues.push(allDeals);
    }
  }

  return (
    <div className='facet_body'>
      <ul className={`facet-list ${seeMore ? 'open' : ''}`}>
        {isCategoryFacetSelection &&
          facetGroup === PARENT_CATALOG_GROUP &&
          !history.location.pathname.includes('search') &&
          !history.location.pathname.includes('shop-store') &&
          !isDealsPage &&
          facetDisplayValues
            .slice(0, initalValues)
            .map((currentFacetData: IProductListFacetsEntry, index: number) =>
              userType === UserType.Advantage ? (
                <li key={index}>
                  <CategoryFacet
                    currentFacetData={currentFacetData}
                    facetGroup={facetGroup}
                  />
                </li>
              ) : (
                !currentFacetData.label.includes(ADVANTAGE_EXCLUSIVE) && (
                  <li key={index}>
                    <CategoryFacet
                      currentFacetData={currentFacetData}
                      facetGroup={facetGroup}
                    />
                  </li>
                )
              )
            )}

        {(facetGroup.toLowerCase() !== PARENT_CATALOG_GROUP.toLowerCase() ||
          history.location.pathname.includes('search') ||
          history.location.pathname.includes('shop-store') ||
          isDealsPage) &&
          facetDisplayValues
            .slice(0, initalValues)
            .map((currentFacetData: IProductListFacetsEntry, index: number) =>
              userType === UserType.Advantage ? (
                <li key={index}>
                  <FacetCheckbox
                    currentFacetData={currentFacetData}
                    isPriceFacet={isPriceFacet}
                    facetGroup={facetGroup}
                    isRatings={isRatings}
                  />
                </li>
              ) : (
                !currentFacetData.label.includes(ADVANTAGE_EXCLUSIVE) && (
                  <li key={index}>
                    <FacetCheckbox
                      key={index}
                      currentFacetData={currentFacetData}
                      isPriceFacet={isPriceFacet}
                      facetGroup={facetGroup}
                      isRatings={isRatings}
                    />
                  </li>
                )
              )
            )}
      </ul>

      {showSeeMore && (
        <div className='see-more'>
          <button
            onClick={seeMoreClickHandler}
            onKeyDown={(e: any) => enterKeyPressHandler(e, seeMoreClickHandler)}
            className='inline-text'
            type='button'
            aria-controls='expandable-content'
            aria-expanded='false'>
            {t(SEE_MORE)}
          </button>
        </div>
      )}

      {!showSeeMore && sortedFilterData.length > DEFAULT_FILTER_VALUES && (
        <div className='see-more'>
          <button
            onClick={seeMoreClickHandler}
            tabIndex={0}
            type='button'
            onKeyDown={(e: any) => enterKeyPressHandler(e, seeMoreClickHandler)}
            className='inline-text'
            aria-controls='expandable-content'
            aria-expanded='true'>
            {t(SEE_LESS)}
          </button>
        </div>
      )}
    </div>
  );
};

export { FacetBody };
