import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_CATEGORIES_ACTION } from '../../../../../../redux/actions/categories.actions';
import { categoriesSelector } from '../../../../../../redux/selectors/categories.selector';
import {
  compareCategorySequence,
  formatTextWithDashes,
} from '../../../../../../utils/utils';
import { useSite } from '../../../../../../_foundation/hooks/usesite/useSite';
import { CategoryIndexPageConstants } from '../../../../../Pages/CategoryIndexPage/CategoryIndexPageConstants';
import { useDealsMenuItem } from '../DealsMenuItem/hooks/DealsMenuItemHooks';
import { IMenuItem } from '../interface/IMenuItem';
import { StoreMenu } from '../interface/StoreMenuInterface';
import { NavBarMenuConstants } from '../NavBarMenuConstants';

/**
 * @hooks useNavBarMenu is responsible for
 * handling the menu items state for the nav
 * bar categories and brands menu.
 */
const useNavBarMenu = () => {
  const { CATEGORIES, SEE_ALL } = NavBarMenuConstants;

  const { mySite } = useSite();

  const { categoriesData, loading: categoriesLoading } =
    useSelector(categoriesSelector);

  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    showDealsMenu,
    dealsMenu,
    dealsHtml,
    loading: dealsLoading,
  } = useDealsMenuItem();

  const loading = categoriesLoading || dealsLoading;

  /**
   * @method fetchAllCategoriesData Fetches all the categories from backend.
   */
  const fetchAllCategoriesData = useCallback((): void => {
    if (mySite && categoriesData.length === 0) {
      dispatch(
        GET_ALL_CATEGORIES_ACTION({
          storeID: mySite.storeID,
        })
      );
    }
  }, [categoriesData.length, dispatch, mySite]);

  useEffect(() => {
    fetchAllCategoriesData();
  }, [fetchAllCategoriesData]);

  /**
   * @callback addSellAllToNestedItems
   * This callback is a recursive callback which is responsible for updating
   * each nested menu item list with "See All {currentMenuName}" as it's first
   * item in the list.
   *
   * This callback is recursively called until all the nested items,
   * nested children values are updated properly starting from 2nd level
   * nested menu items.
   */
  const addSellAllToNestedItems = useCallback(
    (item: StoreMenu): StoreMenu => {
      let seeAllItems: StoreMenu;

      let updatedItem: StoreMenu = item;

      if (item?.children) {
        if (item.children.length !== 0) {
          /**
           * @let menuChildren contains all the children of the
           * menu items starting from 2nd level of nested items.
           */
          let menuChildren: StoreMenu[] = [...item.children];

          /**
           * Update all the nested menu items starting from 2nd
           * level with it's first menu item value as "See All {menuName}"
           */
          seeAllItems = {
            identifier: item.identifier,
            name: `${t(SEE_ALL)} ${item.name}`,
            children: [],
            seo: { href: item.seo ? item.seo.href : '' },
          };

          /**
           * Add product count to the nested menu items.
           */
          let filterMenuChildren = menuChildren.filter(
            (menuChild) =>
              (menuChild.UserData?.[0].productCount &&
                Number(menuChild.UserData?.[0].productCount) !== 0) ||
              (menuChild.product_count && Number(menuChild.product_count) !== 0)
          );

          filterMenuChildren.forEach((menuChild, index) => {
            const productCount = menuChild.UserData?.[0].productCount
              ? `(${Number(menuChild.UserData?.[0].productCount).toFixed()})`
              : menuChild.product_count
              ? `(${Number(menuChild.product_count).toFixed()})`
              : '';

            filterMenuChildren[index] = {
              ...menuChild,
              identifier: menuChild.identifier,
              name: `${menuChild.name} ${productCount}`,
            };
          });

          filterMenuChildren = [...[seeAllItems], ...filterMenuChildren];

          updatedItem = {
            ...updatedItem,
            children: [...filterMenuChildren],
          };

          updatedItem.children?.forEach((item: StoreMenu, index) => {
            if (item.children?.length !== 0) {
              /**
               * Recursively call the addSellAllToNestedItems callback
               * until all the nested items and their nested items
               * are properly updated.
               */
              const { children } = addSellAllToNestedItems(item);

              if (updatedItem.children && index) {
                updatedItem.children[index] = {
                  ...updatedItem.children[index],
                  children,
                };
              }
            }
          });
        }
      }

      return updatedItem;
    },
    [SEE_ALL, t]
  );

  /**
   * @callback addMenuItems is responsible for
   * adding menuItems to be rendered from the
   * response data.
   */
  const addMenuItems = useCallback(() => {
    const { DEALS } = NavBarMenuConstants;

    if (categoriesData && mySite) {
      const menuItems: IMenuItem[] = [];

      const { ALL_BRANDS_IDENTIFIER } = CategoryIndexPageConstants;

      const menuContents = [...categoriesData];

      let brands = menuContents.filter(
        ({ identifier }) => identifier === ALL_BRANDS_IDENTIFIER
      )[0];

      if (brands && brands.children) {
        let brandsChildren = [...brands.children];

        brandsChildren.sort(compareCategorySequence);

        brandsChildren = [
          ...brandsChildren.slice(0, Number(mySite.brandMenuItemLimit)),
        ];

        brands = {
          ...brands,
          children: brandsChildren,
        };

        const brandsIndex = menuContents.findIndex(
          ({ identifier }) => identifier === ALL_BRANDS_IDENTIFIER
        );

        menuContents[brandsIndex] = brands;
      }

      menuContents.forEach((menuData) => {
        if (menuData.identifier === DEALS) {
          let menuChildren: StoreMenu[] = [];

          if (showDealsMenu && dealsMenu && dealsMenu.length > 0) {
            menuChildren = [...dealsMenu];

            const menuItem: IMenuItem = {
              children: menuChildren,
              menuName: menuData.name,
              identifier: menuData.identifier,
              seo: { href: menuData.seo?.href },
            };

            menuItems.push(menuItem);
          } else {
            const menuItem: IMenuItem = {
              children: [],
              ...{ ...(showDealsMenu && { childrenElement: dealsHtml }) },
              menuName: menuData.name,
              identifier: menuData.identifier,
              seo: { href: menuData.seo?.href },
            };

            menuItems.push(menuItem);
          }
        }

        if (menuData && menuData.identifier !== DEALS) {
          /**
           * @let seeAllItems
           * See All Items object holds
           * the first item of the menu list
           * "See All {menuName}"
           *
           * This object can be used to initialize
           * new "See All {menuName}" every time
           * when a menu item gets nested.
           */
          let seeAllItems: StoreMenu;

          /**
           * @let menuChildren contains all the children of the
           * menu items response data.
           */
          let menuChildren: StoreMenu[];

          if (menuData?.children) {
            menuChildren = [...menuData?.children];

            /**
             * @let updatedMenuChildren holds all the updated copy
             * of the nested menu children with it's first item as
             * "See All {menuName}" with respect to it's menu.
             */
            const updatedMenuChildren: StoreMenu[] = [];

            if (menuData.children.length !== 0) {
              /**
               * For Brands menu the first item of the
               * list is just "See All" for Categories
               * the first item is "See All {menuName}"
               */
              const name =
                menuData.name === CATEGORIES
                  ? `${t(SEE_ALL)} ${menuData.name}`
                  : t(SEE_ALL);

              /**
               * Initialize the seeAllItems of that
               * top category menu items.
               */
              seeAllItems = {
                children: [],
                identifier: menuData.identifier,
                name,
                seo: { href: menuData.seo?.href ? menuData.seo.href : '' },
              };

              /**
               * Add product count root menu items.
               */
              menuChildren.forEach((menuChild, index) => {
                if (Number(menuData.product_count) !== 0) {
                  const productCount = menuChild.UserData?.[0].productCount
                    ? `(${Number(
                        menuChild.UserData?.[0].productCount
                      ).toFixed()})`
                    : menuChild.product_count
                    ? `(${Number(menuChild.product_count).toFixed()})`
                    : '';

                  menuChildren[index] = {
                    ...menuChild,
                    identifier: menuChild.identifier,
                    name: `${menuChild.name} ${productCount}`,
                  };
                } else {
                  menuChildren.splice(index, 1);
                }
              });

              /**
               * Append the top category menuItems nested children
               */
              menuChildren = [...[seeAllItems], ...menuChildren];

              /**
               * Update the nested menu items children's item list.
               */
              menuChildren.forEach((item: StoreMenu) => {
                updatedMenuChildren.push(addSellAllToNestedItems(item));
              });
            }
            if (menuData.identifier) {
              /**
               * Construct a menuItem object with all
               * the updated menu item values.
               */
              const menuItem: IMenuItem = {
                children: updatedMenuChildren,
                menuName: menuData.name,
                identifier: menuData.identifier,
                seo: { href: menuData.seo?.href },
                ariaControls: `${formatTextWithDashes(menuData.name)}-menu`,
              };

              menuItems.push(menuItem);
            }
          }
        }
      });

      setMenuItems(menuItems);
    }
  }, [
    categoriesData,
    mySite,
    showDealsMenu,
    dealsMenu,
    dealsHtml,
    CATEGORIES,
    t,
    SEE_ALL,
    addSellAllToNestedItems,
  ]);

  useEffect(() => {
    if (!dealsLoading) {
      addMenuItems();
    }

    return () => {
      setMenuItems([]);
    };
  }, [addMenuItems, dealsLoading]);

  return {
    menuItems,
    loading,
    showDealsMenu,
    dealsMenu,
    dealsHtml,
    dealsLoading,
  };
};

export { useNavBarMenu };
