const BASE_URL = '/wcs/resources/store';
const SEARCH_BASE_URL = '/search/resources/api/v2';

const OrderLookUpServiceConstant = {
  ORDER_LOOKUP_URL: (storeID: string) => `${BASE_URL}/${storeID}/order/status`,

  ORDER_ITEM_LOOKUP_URL: (storeID: string) =>
    `${SEARCH_BASE_URL}/products?storeId=${storeID}`,

  ORDER_CATALOG_REQUEST_URL: (storeID: string) =>
    `${BASE_URL}/${storeID}/catalog/request_catalog`,

  SEND_INVOICE_URL: (storeID: string) =>
    `${BASE_URL}/${storeID}/order/email-invoice`,
};

const START_RANGE_PREFIX = '01/01/';
const END_RANGE_PREFIX = '-12/31/';

export { OrderLookUpServiceConstant, START_RANGE_PREFIX, END_RANGE_PREFIX };
