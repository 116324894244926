import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert, AlertProps } from '@material-ui/lab';
import HTMLReactParser from 'html-react-parser';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CHECKOUT_SESSION_OUT } from '../../_foundation/constants/cookie';
import { CLOSE_NTE_ALERT_ACTION } from './actions/nte-alert.actions';

/**
 * @interface INteAlert
 */
export interface INteAlert {
  /**
   * @prop autoClose
   * Mention the timelimit in ms for auto closing the component.
   * If left unspecified the component won't auto close.
   */
  autoClose?: number;

  /**
   * @prop currentMessageIndex
   * Determines the current alert message in the queue.
   */
  currentMessageIndex?: string;

  /**
   * @prop message
   * Message text for the alert component.
   */
  messages: Array<string | object>;

  /**
   * @prop severity
   * Severity of the alert component => ex: Warning, Success, Info, Error.
   */
  severity?: AlertProps['severity'];

  /**
   * @prop showClose
   * Determines whether to show the close icon or not.
   */
  showClose?: boolean;

  /**
   * @prop closeHander
   * Function called when clicking on close button.
   */
  alertCloseHandler?: () => void;

  /**
   * @prop className
   * Function called when clicking on close button.
   */
  className?: string;

  /**
   * @prop htmlMarkup Adds the html markup values.
   */
  htmlMarkup?: string;

  /**
   * @prop UI variant of the alert component => ex: standard, inline
   */
  variant?: string;
}

/**
 * @component NteAlert renders the alert component.
 *
 * @param INteAlert
 */
const NteAlert: React.FC<INteAlert> = ({
  autoClose,
  currentMessageIndex,
  messages,
  alertCloseHandler,
  severity = 'error',
  showClose = true,
  className = '',
  htmlMarkup,
  variant = 'standard',
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  /**
   * @callback autoCloseAlert Auto closes the alert component after a specified interval.
   */
  const autoCloseAlert = useCallback(
    (autoClose: number): void => {
      setTimeout(() => {
        if (localStorage.getItem(CHECKOUT_SESSION_OUT)) {
          localStorage.removeItem(CHECKOUT_SESSION_OUT);
        }
        dispatch(CLOSE_NTE_ALERT_ACTION({ id: currentMessageIndex }));
      }, autoClose);
    },
    [currentMessageIndex, dispatch]
  );

  useEffect(() => {
    if (autoClose) {
      autoCloseAlert(autoClose);
    }
  }, [autoClose, autoCloseAlert, currentMessageIndex, dispatch]);

  const variantClass =
    severity === 'error' && !autoClose
      ? 'outline-error'
      : severity === 'warning' && !autoClose
      ? 'outline-warning'
      : '';

  /**
   * @method nteAlertCloseHandler Closes the current alert component
   * in the queue based on the current alert message id.
   */
  const nteAlertCloseHandler = (): void => {
    if (alertCloseHandler) {
      alertCloseHandler();
    }
    if (localStorage.getItem(CHECKOUT_SESSION_OUT)) {
      localStorage.removeItem(CHECKOUT_SESSION_OUT);
    }
    dispatch(CLOSE_NTE_ALERT_ACTION({ id: currentMessageIndex }));
  };

  return (
    <Alert
      severity={severity}
      iconMapping={{
        success: <CheckCircleIcon fontSize='inherit' />,
        warning: <WarningIcon fontSize='inherit' />,
        error: <ErrorIcon fontSize='inherit' />,
      }}
      className={`nte_alert ${className} ${variantClass}`}
      id={`alert-${currentMessageIndex}`}
      {...(showClose && { onClose: nteAlertCloseHandler })}>
      {!htmlMarkup &&
        messages.map((message, index) => (
          <Typography key={index} variant='body2'>
            {typeof message === 'object'
              ? message
              : HTMLReactParser(message.toString())}
          </Typography>
        ))}

      {htmlMarkup && (
        <div dangerouslySetInnerHTML={{ __html: t(htmlMarkup) }}></div>
      )}
    </Alert>
  );
};

export { NteAlert };
