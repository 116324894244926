import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { seoSelector } from '../../../components/Seo/redux/selector/seo';
import { formatToTitleCase } from '../../utils';
import { useCurrentPathName } from '../current-pathname/CurrentPathName';

const useSeoState = () => {
  const seoConfig = useSelector(seoSelector);

  const { currentPathName } = useCurrentPathName();

  const [isPdpPage, setIsPdpPage] = useState<boolean>(false);

  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const initPdpPage = useCallback((): void => {
    if (seoConfig && seoConfig[formattedPath]) {
      const {
        page: { type },
      } = seoConfig[formattedPath];

      setIsPdpPage(
        type === 'ItemPage' || type === 'ProductPage' || type === 'VariantPage'
      );
    }
  }, [formattedPath, seoConfig]);

  useEffect(() => {
    initPdpPage();
  }, [initPdpPage]);

  return { isPdpPage };
};

export { useSeoState };
