import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_BY_PRICE_RANGE_ACTION } from '../../../../../redux/actions/productList.actions';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { useTextInput } from '../../../../Forms/TextInput/hooks/TextInputHooks';
import { TextInput } from '../../../../Forms/TextInput/TextInput';
import { FacetBody } from '../FacetBody/FacetBody';
import { PriceFacetConstants } from './PriceFacetConstants';

/**
 * @interface IPriceFacet
 */
interface IPriceFacet {
  filterData: IProductListFacetsEntry[];
}

/**
 * @component PriceFacet renders the price facet.
 *
 * @param IPriceFacet
 */
const PriceFacet: React.FC<IPriceFacet> = ({ filterData }) => {
  const { $_MAX, $_MIN, GO, TO } = PriceFacetConstants;

  const dispatch = useDispatch();

  const {
    facetLoading,
    maxPrice: maxPriceValue,
    minPrice: minPriceValue,
  } = useSelector(productListSelector);

  const { currentValue: minPrice, onInputChange: onMinPriceChange } =
    useTextInput(minPriceValue);

  const { currentValue: maxPrice, onInputChange: onMaxPriceChange } =
    useTextInput(maxPriceValue);

  const { t } = useTranslation();

  /**
   * @method goClickHandler Dispatches the filter by price range.
   */
  const goClickHandler = (): void => {
    if (minPrice && maxPrice) {
      dispatch(FILTER_BY_PRICE_RANGE_ACTION({ minPrice, maxPrice }));
    }
  };

  return (
    <>
      <div className='price_facet'>
        <TextInput
          id='min-price'
          label={t($_MIN)}
          name='minPrice'
          type='number'
          pattern='[0-9]*'
          onChange={onMinPriceChange}
          value={minPrice}
          ariaLabel='minimum price'
        />

        <Typography variant='body2'>{t(TO)}</Typography>

        <TextInput
          id='max-price'
          label={t($_MAX)}
          name='maxPrice'
          type='number'
          pattern='[0-9]*'
          onChange={onMaxPriceChange}
          value={maxPrice}
          ariaLabel='maximum price'
        />

        <NteButton
          translationKey={t(GO)}
          variant='outlined'
          onClick={goClickHandler}
          loading={facetLoading}
          type='button'
        />
      </div>

      <FacetBody
        filterData={filterData}
        isPriceFacet={true}
        facetGroup='Price'
      />
    </>
  );
};

export { PriceFacet };
