import { Divider, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { productsService } from '../../../../../_foundation/apis/search/products/products.service';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../../_foundation/interface/Responses/ICartProductsResponse';
import IShippingInfoResponse from '../../../../../_foundation/interface/Responses/IShippingInfoResponse';
import { PDP_CHECK_STORE_STOCK } from '../../../../../constants/Tealium';
import { SHOPPING_CART, SHOP_ALL } from '../../../../../constants/routes';
import { orderSelector } from '../../../../../redux/selectors/order.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { storeLocatorSelector } from '../../../../../redux/selectors/storeLocator';
import { useGetPageType } from '../../../../../utils/hooks/get-page-type/GetPageType';
import { ProductUtility } from '../../../../../utils/product-utility';
import { checkTruthy, sendTealiumData } from '../../../../../utils/utils';
import { NteDrawer } from '../../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { NteAlert } from '../../../../NteAlert/NteAlert';
import { StoreFinder } from '../../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/StoreFinder';
import { ProductCardConstants } from '../ProductCardConstants';
import { ProductCardSectionMessagesConstants } from '../ProductCardSectionMessages/ProductCardSectionMessagesConstants';

/** @interface  IStorePickupMessage */
interface IStorePickupMessage {
  label: JSX.Element | JSX.Element[];
}

/**
 * @method useStorePickupMessageHook sets the label for the store pickup message.
 */
const useStorePickupMessageHook = (
  productDetails: IProductListContents | ICartContentsItem,
  isProductCompare?: boolean
) => {
  const {
    CART_PAGE_TYPE,
    CHECK_STORES_PICKUP,
    ITEM_PAGE,
    PRODUCT_PAGE,
    CONTENT_PAGE,
    PLP_PAGE_TYPE,
    STORE_PICKUP_MESSAGE_UNAVAILABLE,
    STORE_WEB_STATUS_IDENTIFIER,
    STORE_STATUS,
    NOT_AVAILABLE_AT,
  } = ProductCardSectionMessagesConstants;

  const {
    D365_ATTRIBUTE,
    BOPIS_AVAILABILITY,
    D365_MESSAGE,
    PRODUCT_CARD_SECTION3: {
      STORE_PICKUP: { CHECK_STORE_STOCK, CHECK_STORES },
    },
  } = ProductCardConstants;

  const { t } = useTranslation();

  const { pageType } = useGetPageType();

  const { pathname } = useLocation();

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const { shippingInfo } = useSelector(productListSelector);

  const { shippingInfo: orderShippingInfo } = useSelector(orderSelector);

  const history = useHistory();

  const { mySite } = useSite();

  const isD365Store = currentStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === D365_ATTRIBUTE.toLowerCase()
  )?.value;

  const isNotBopis = currentStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
  )?.value;

  const showD365AdditionalMessage =
    checkTruthy(isD365Store) &&
    (!checkTruthy(isNotBopis) || !checkTruthy(mySite?.globalBopis));

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const isPdpPage = pageType === PRODUCT_PAGE || pageType === ITEM_PAGE;

  const currentPageType = isCartPage ? CART_PAGE_TYPE : pageType;

  const currentPartNumber = useMemo(
    () => productDetails.partNumber,
    [productDetails]
  );

  const [showStoreStockDrawer, setShowStoreStockDrawer] = useState(false);

  const [storePickupMessage, setStorePickupMessage] = useState('');

  const [storeQuantity, setStoreQuantity] = useState('');

  const [storePickupLoading, setStorePickupLoading] = useState(true);

  const [inStockMessage, setInStockMessage] = useState(false);

  const [shippingInventory, setShippingInventory] =
    useState<IShippingInfoResponse>();

  const [storePickUpLabel, setStorePickUpLabel] =
    useState<IStorePickupMessage>();

  const storeStockLabel = currentStoreDetails?.storeName
    ? CHECK_STORE_STOCK
    : CHECK_STORES;

  const initStorePickupMessage = useCallback(() => {
    const isPlpPage = pageType === PLP_PAGE_TYPE;

    const isStoreClosed = currentStoreDetails?.Attribute?.filter(
      ({ displayName, value }) =>
        displayName.toLowerCase() ===
          STORE_WEB_STATUS_IDENTIFIER.toLowerCase() &&
        (value.toLowerCase() ===
          STORE_STATUS.TEMPORARILY_CLOSED.toLowerCase() ||
          value.toLowerCase() === STORE_STATUS.PERMANENTLY_CLOSED.toLowerCase())
    )[0];

    if (currentStoreDetails && isStoreClosed) {
      setStorePickupMessage(NOT_AVAILABLE_AT(currentStoreDetails.storeName));

      setStorePickupLoading(false);

      return;
    }

    if (mySite) {
      setStorePickupLoading(true);

      const { products } = shippingInfo;

      let currentInventoryInfo;

      if (products.length !== 0 && !isPlpPage && !isCartPage) {
        currentInventoryInfo = products.find(
          ({ partNumber }) => partNumber === productDetails.partNumber
        );
      } else if (shippingInventory && !isCartPage) {
        currentInventoryInfo = shippingInventory?.products[0];
      }

      if (isCartPage) {
        currentInventoryInfo = orderShippingInfo.find(
          ({ partNumber }) => partNumber === currentPartNumber
        );
      }

      const { storePickUpMessage, storeQuantity } =
        ProductUtility.constructStorePickup({
          productCard: productDetails,
          isGlobalBopis: checkTruthy(mySite?.globalBopis),
          pageType: currentPageType,
          storeName: currentStoreDetails?.storeName
            ? currentStoreDetails?.storeName
            : '',
          ...{
            ...(currentInventoryInfo && {
              inventory: currentInventoryInfo,
            }),
          },
          ...{
            ...(!currentInventoryInfo && {
              shopAllPage: pathname.toLowerCase().includes(SHOP_ALL),
            }),
          },
        });

      if (storePickUpMessage || storePickUpMessage === '') {
        setStorePickupMessage(storePickUpMessage);

        setStoreQuantity(storeQuantity);

        setStorePickupLoading(false);
      } else {
        setStorePickupLoading(false);

        setStorePickupMessage('');
      }
    }

    if (mySite && !currentStoreDetails) {
      setStorePickupLoading(false);
    }
  }, [
    pageType,
    PLP_PAGE_TYPE,
    currentStoreDetails,
    mySite,
    STORE_WEB_STATUS_IDENTIFIER,
    STORE_STATUS,
    NOT_AVAILABLE_AT,
    shippingInfo,
    isCartPage,
    shippingInventory,
    productDetails,
    currentPageType,
    pathname,
    orderShippingInfo,
    currentPartNumber,
  ]);

  const showStoreOnly = ProductUtility.isStoreOnly(productDetails);

  useEffect(() => {
    initStorePickupMessage();
  }, [initStorePickupMessage]);

  const initStoreInventory = useCallback(async (): Promise<void> => {
    const isContentPage = pageType === CONTENT_PAGE;

    try {
      if (
        mySite &&
        (isPdpPage || isContentPage) &&
        currentPartNumber &&
        !isProductCompare
      ) {
        setStorePickupLoading(true);

        const shippingInventory: IShippingInfoResponse =
          await productsService.getShippingInfo({
            physicalStoreId: currentStoreDetails?.uniqueID
              ? currentStoreDetails.uniqueID
              : '',
            storeID: mySite.storeID,
            partNumber: currentPartNumber,
          });

        if (shippingInventory) {
          setShippingInventory(shippingInventory);
        }
      }
    } catch (e) {
      setStorePickupLoading(false);

      console.error(e);
    }
  }, [
    CONTENT_PAGE,
    currentPartNumber,
    currentStoreDetails,
    isPdpPage,
    isProductCompare,
    mySite,
    pageType,
  ]);

  useEffect(() => {
    initStoreInventory();
  }, [initStoreInventory]);

  const storeStockDrawer = useCallback(
    (
      showStoreStockDrawer: boolean,
      storeStockCloseHandler: () => void
    ): JSX.Element => (
      <NteDrawer
        anchor='right'
        id='store-locator-drawer'
        className='delivery-options-children'
        ariaLabelledById='store-locator-header'
        nteDrawerCloseHandler={storeStockCloseHandler}
        showNteDrawer={showStoreStockDrawer}>
        <Divider className='nte_popper-divider' />
        <div className='nte_popper-paper'>
          <StoreFinder
            menuTitle={t(CHECK_STORES_PICKUP)}
            storeFinderCloseHandler={storeStockCloseHandler}
            partNumber={productDetails.partNumber}
          />
        </div>
      </NteDrawer>
    ),
    [CHECK_STORES_PICKUP, productDetails.partNumber, t]
  );

  const storeStockCloseHandler = useCallback((): void => {
    if (!showStoreStockDrawer) {
      let el = document.getElementById('cxone-guide-container');
      if (el != null) {
        el.className = 'hide-element';
      }
    } else {
      let e = document.getElementById('cxone-guide-container');
      if (e != null) {
        e.className = '';
      }
    }

    setShowStoreStockDrawer((showStoreStockDrawer) => !showStoreStockDrawer);
    if (!showStoreStockDrawer && productDetails) {
      sendTealiumData({
        tealium_event: PDP_CHECK_STORE_STOCK,
        product_id: productDetails?.partNumber,
        product_name: productDetails?.name,
      });
    }
  }, [productDetails, showStoreStockDrawer]);

  const constructKeyword = useCallback(
    (keyWord: string, index: number): JSX.Element | string => {
      if (keyWord.includes('X')) {
        return String(storeQuantity);
      } else if (
        keyWord.includes('Store-Name') &&
        currentStoreDetails &&
        currentStoreDetails?.storeName
      ) {
        return currentStoreDetails.storeName;
      }

      return keyWord;
    },
    [currentStoreDetails, storeQuantity]
  );

  const storeStockButton = useCallback(
    () => (
      <>
        {isPdpPage && !isProductCompare ? (
          <div>
            <NteButton
              translationKey={storeStockLabel}
              variant={showStoreOnly ? 'contained' : 'outlined'}
              size='small'
              className='prompt-margin-top pdp-check-stock'
              onClick={storeStockCloseHandler}
              type='button'
            />
          </div>
        ) : (
          <button
            className='inline-text link-effect'
            onClick={storeStockCloseHandler}
            type='button'
            tabIndex={0}
            role='link'>
            <span>{t(storeStockLabel)}</span>
          </button>
        )}

        {storeStockDrawer(showStoreStockDrawer, storeStockCloseHandler)}
      </>
    ),
    [
      isPdpPage,
      isProductCompare,
      showStoreStockDrawer,
      storeStockCloseHandler,
      storeStockDrawer,
      storeStockLabel,
      showStoreOnly,
      t,
    ]
  );

  const storePickupNotAvailable = useCallback(
    (): JSX.Element => (
      <NteAlert
        messages={[t(STORE_PICKUP_MESSAGE_UNAVAILABLE)]}
        showClose={false}
        severity='warning'
      />
    ),
    [STORE_PICKUP_MESSAGE_UNAVAILABLE, t]
  );

  /**
   * @method
   * Responsible for sending the Props for StorePickup
   */
  const storePickupProps = useCallback((): void => {
    const keyWords = ['X', 'Store-Name'];

    const isOnlineOnlyItem = storePickupMessage
      .toLowerCase()
      .includes('online only');

    const isOutOfStock = storePickupMessage
      .toLowerCase()
      .includes('out of stock');

    const isNotSoldAt = storePickupMessage
      .toLowerCase()
      .includes('not sold at');

    setInStockMessage(storePickupMessage.toLowerCase().includes('in stock at'));

    if (storePickupMessage) {
      const containsKeyword = keyWords.filter((value) =>
        storePickupMessage.includes(value)
      );

      if (containsKeyword.length !== 0) {
        const parts = storePickupMessage.split(' ');

        setStorePickUpLabel({
          label: (
            <>
              {parts.length !== 0 && (
                <>
                  <p
                    className={`${
                      showD365AdditionalMessage ? '' : 'prompt-margin'
                    } ${
                      isOutOfStock
                        ? ''
                        : isNotSoldAt
                        ? 'pickup-message--default'
                        : 'pickup-message--instock'
                    }`}>
                    {parts.map((part, index) => (
                      <>
                        {containsKeyword.includes(part)
                          ? `${constructKeyword(part, index)} `
                          : `${part} `}
                      </>
                    ))}
                  </p>
                  {storePickupMessage.toLowerCase().includes('in stock at') &&
                    showD365AdditionalMessage && (
                      <Typography
                        variant='body2'
                        className='color-brown prompt-margin pickup-message--d365'>
                        {D365_MESSAGE}
                      </Typography>
                    )}
                  {!isOnlineOnlyItem && storeStockButton()}
                </>
              )}
            </>
          ),
        });
      } else {
        setStorePickUpLabel({
          label: (
            <>
              <span className={'pickup-message--default'}>
                {storePickupMessage}
              </span>
              {storePickupMessage.toLowerCase().includes('in stock at') &&
                isPdpPage &&
                showD365AdditionalMessage && (
                  <Typography variant='body2' className='d365'>
                    {D365_MESSAGE}
                  </Typography>
                )}
              <br />
              {!isOnlineOnlyItem && storeStockButton()}
            </>
          ),
        });
      }
    } else if (storePickupMessage === '') {
      setStorePickUpLabel({
        label: <>{!isOnlineOnlyItem && storeStockButton()}</>,
      });
    } else {
      setStorePickUpLabel({
        label: (
          <>
            {storePickupNotAvailable()}
            <br />
            {!isOnlineOnlyItem && storeStockButton()}
          </>
        ),
      });
    }
  }, [
    D365_MESSAGE,
    constructKeyword,
    isPdpPage,
    showD365AdditionalMessage,
    storePickupMessage,
    storePickupNotAvailable,
    storeStockButton,
  ]);

  useEffect(() => {
    storePickupProps();
  }, [storePickupProps, storePickupMessage]);

  return { storePickUpLabel, storePickupLoading, inStockMessage };
};

export { useStorePickupMessageHook };
