import { createAction } from '@reduxjs/toolkit';
import {
  IRviRequest,
  IRviResponse,
} from '../../../../../_foundation/interface/Rvi/IRvi';
import * as ACTION_TYPES from '../action-types/rvi.action-types';

const FETCH_RVI_ACTION = createAction<IRviRequest>(ACTION_TYPES.FETCH_RVI);

const FETCH_RVI_SUCCESS_ACTION = createAction<IRviResponse>(
  ACTION_TYPES.FETCH_RVI_SUCCESS
);

const RESET_RVI_ACTION = createAction(ACTION_TYPES.RESET_RVI);

export { FETCH_RVI_ACTION, FETCH_RVI_SUCCESS_ACTION, RESET_RVI_ACTION };
