import { AxiosRequestConfig } from 'axios';
import { ACCOUNT_LOGIN } from '../../../constants/Tealium';
import { removeLocalStorage, sendTealiumData } from '../../../utils/utils';
import { USER, USER_CONTRACT } from '../../constants/cookie';
import {
  IAuthenticationResponse,
  IChangeUserPassword,
  IContactResponse,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  ILoginIdentity,
  ILoginParam,
  IRegisterUser,
  IResetPasswordRequest,
  IUpdateUserRegistration,
  IUpdateUserRegistrationRequest,
  IUpdateUserRegistrationResponse,
  IUserInfo,
} from '../../interface/Authentication/IAuth';
import { makeRequest } from '../axios/axiosConfig';
import { AuthenticationServiceConstants } from './authentication.service.constants';

class AuthenticationService {
  /**
   * @method authenticateUser Authenticates the user and fetches
   * the user information on successful authentication.
   *
   * @param ILoginParam
   */
  public async authenticateUser({
    logonId,
    logonPassword,
    storeID,
  }: ILoginParam): Promise<IAuthenticationResponse> {
    const { LOGIN_IDENTITY_URL, USER_INFO_URL, USER_CONTRACT_URL } =
      AuthenticationServiceConstants;
    try {
      const loginRequestPayload = {
        logonId,
        logonPassword,
      };

      const loginIdentityRequest: AxiosRequestConfig = {
        url: LOGIN_IDENTITY_URL(storeID),
        data: loginRequestPayload,
        method: 'POST',
      };

      const loginIdentityResponse: ILoginIdentity = await makeRequest(
        loginIdentityRequest
      );

      if (!loginIdentityResponse.programName) {
        throw new Error();
      }

      const { WCToken, WCTrustedToken } = loginIdentityResponse;

      const userInfoRequest: AxiosRequestConfig = {
        url: USER_INFO_URL(storeID),
        headers: {
          WCToken,
          WCTrustedToken,
        },
      };

      const userInfoResponse: IUserInfo = await makeRequest(userInfoRequest);

      const authenticationResponse: IAuthenticationResponse = {
        loginIdentityResponse,
        userInfoResponse,
      };

      const userContextRequest: AxiosRequestConfig = {
        url: USER_CONTRACT_URL(storeID),
        headers: {
          WCToken,
          WCTrustedToken,
        },
      };

      const userContextResponse = await makeRequest(userContextRequest);

      if (userContextResponse && Object.keys(userContextResponse)) {
        if (Object.keys(userContextResponse.contracts).length > 1) {
          sessionStorage.setItem(
            USER_CONTRACT,
            Object.keys(userContextResponse.contracts)[1]
          );
        }
        else {
          sessionStorage.setItem(
            USER_CONTRACT,
            Object.keys(userContextResponse.contracts)[0]
          );
        }
      }

      sendTealiumData({
        tealium_event: ACCOUNT_LOGIN,
      });

      return authenticationResponse;
    } catch (err) {
      throw err;
    }
  }

  public async getUserInfo({ storeID }: any) {
    try {
      const { USER_INFO_URL } = AuthenticationServiceConstants;

      const userInfoRequest: AxiosRequestConfig = {
        url: USER_INFO_URL(storeID),
      };

      const userInfoResponse: IUserInfo = await makeRequest(userInfoRequest);

      return userInfoResponse;
    } catch (e) {
      throw e;
    }
  }

  public async registerUser({
    userDetails,
    catalogID,
    storeID,
    logonId,
    isOrderConfirmation,
    history,
  }: IRegisterUser) {
    const { REGISTER_URL, USER_INFO_URL } = AuthenticationServiceConstants;

    const logonID = userDetails.email ? userDetails.email : logonId;

    const registerUserPayload = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      logonId: logonID,
      logonPassword: userDetails.password,
      logonPasswordVerify: userDetails.confirmPassword,
      email1: isOrderConfirmation ? logonID : userDetails.email,
      storeId: storeID,
      catalogId: catalogID,
      receiveEmail: userDetails.emailDeals?.toString(),
      receiveEmailPreference: [
        {
          value: userDetails.emailDeals?.toString(),
          storeID,
        },
      ],
      challengeQuestion: '-',
      challengeAnswer: '-',
      registerType: 'G',
      profileType: 'C',
      x_reCaptchaToken: userDetails.x_reCaptchaToken,
    };

    try {
      const request: AxiosRequestConfig = {
        url: REGISTER_URL(storeID),
        method: 'POST',
        data: registerUserPayload,
      };

      const registrationResponse = await makeRequest(
        request,
        undefined,
        history
      );

      removeLocalStorage(USER);

      registrationResponse.programName = 'NA';

      const { WCToken, WCTrustedToken } = registrationResponse;

      const userInfoRequest: AxiosRequestConfig = {
        url: USER_INFO_URL(storeID),
        headers: {
          WCToken,
          WCTrustedToken,
        },
      };

      const userInfoResponse: IUserInfo = await makeRequest(
        userInfoRequest,
        undefined,
        history
      );

      const authenticationResponse: IAuthenticationResponse = {
        loginIdentityResponse: registrationResponse,
        userInfoResponse,
      };

      return authenticationResponse;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method getUserContactInformation Fetches the Contact information for a specific user.
   *
   * @param ILoginParam
   * @returns IContactResponse
   */
  public async getUserContactInformation({
    storeID,
  }: ILoginParam): Promise<IContactResponse> {
    try {
      const { CONTACT_INFORMATION_URL } = AuthenticationServiceConstants;

      const contactInformationRequest: AxiosRequestConfig = {
        url: CONTACT_INFORMATION_URL(storeID),
      };

      const contactInformationResponse: IContactResponse = await makeRequest(
        contactInformationRequest
      );

      return contactInformationResponse;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method changeUserPassword changes the password for the user.
   *
   * @param ILoginParam
   * @returns IContactResponse
   */
  public async changeUserPassword({
    storeID,
    passwordDetails,
  }: IChangeUserPassword): Promise<IContactResponse> {
    try {
      const { PASSWORD_URL } = AuthenticationServiceConstants;

      const changePasswordRequest: AxiosRequestConfig = {
        url: PASSWORD_URL(storeID),
        method: 'PUT',
        data: {
          resetPassword: 'true',
          xcred_logonPasswordOld: passwordDetails.currentPassword,
          logonPassword: passwordDetails.newPassword,
          xcred_logonPasswordVerify: passwordDetails.newPassword,
        },
      };

      const changePasswordResponse: IContactResponse = await makeRequest(
        changePasswordRequest
      );

      return changePasswordResponse;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method getRecentOrders Fetches the recent for that user from the backend.
   *
   * @param ILoginParam
   */
  public async getRecentOrders({ storeID }: ILoginParam): Promise<any> {
    try {
      const { GET_RECENT_ORDERS } = AuthenticationServiceConstants;

      const recentOrdersRequest: AxiosRequestConfig = {
        url: GET_RECENT_ORDERS(storeID),
      };

      const recentOrdersResponse = await makeRequest(recentOrdersRequest);

      return recentOrdersResponse;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method addUpdateCustomerNumber makes request to add/edit a user's customer number
   *
   * @param ILoginParam
   */
  public async updateUserRegistrationDetails({
    storeID,
    customerNumber,
    emailAddress,
    cancelToken,
  }: IUpdateUserRegistrationRequest): Promise<IUpdateUserRegistrationResponse> {
    try {
      const { UPDATE_USER_REGISTRATION, USER_INFO_URL, USER_CONTRACT_URL } =
        AuthenticationServiceConstants;

      const addUpdateCustomerNumberRequest: AxiosRequestConfig = {
        url: UPDATE_USER_REGISTRATION(storeID),
        method: 'PUT',
        data: {
          ...{ ...(customerNumber && { customerNumber }) },
          ...{ ...(emailAddress && { logonId: emailAddress }) },
          ...{ ...(emailAddress && { email1: emailAddress }) },
        },
        cancelToken,
      };

      const addUpdateCustomerNumberResponse: IUpdateUserRegistration =
        await makeRequest(addUpdateCustomerNumberRequest);

      const userInfoRequest: AxiosRequestConfig = {
        url: USER_INFO_URL(storeID),
      };

      const userInfo: IUserInfo = await makeRequest(userInfoRequest);

      const userContextRequest: AxiosRequestConfig = {
        url: USER_CONTRACT_URL(storeID),
      };

      const userContextResponse = await makeRequest(userContextRequest);

      if (userContextResponse && Object.keys(userContextResponse)) {
        sessionStorage.setItem(
          USER_CONTRACT,
          Object.keys(userContextResponse.contracts)[0]
        );
      }

      const response: IUpdateUserRegistrationResponse = {
        addEditCustomerNumber: addUpdateCustomerNumberResponse,
        userInfo,
      };

      return response;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method getVerificationCode Sends a request to fetch verification code for the forgot password flow.
   *
   * @param IForgotPasswordRequest
   */
  public async getVerificationCode({
    challengeAnswer = '-',
    logonId,
    storeID,
    resetPassword = 'true',
  }: IForgotPasswordRequest): Promise<IForgotPasswordResponse> {
    try {
      const { PASSWORD_URL } = AuthenticationServiceConstants;

      const verificationCodeRequest: AxiosRequestConfig = {
        url: PASSWORD_URL(storeID),
        method: 'PUT',
        data: {
          challengeAnswer,
          logonId,
          resetPassword,
        },
      };

      const verificationCodeResponse: IForgotPasswordResponse =
        await makeRequest(verificationCodeRequest);

      return verificationCodeResponse;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @method resetPassword Resets the password.
   *
   * @param IResetPasswordRequest
   */
  public async resetPassword({
    logonId,
    logonPassword,
    storeID,
    xcred_logonPasswordVerify,
    xcred_validationCode,
    resetPassword = 'true',
    x_reCaptchaToken,
  }: IResetPasswordRequest): Promise<any> {
    try {
      const { PASSWORD_URL } = AuthenticationServiceConstants;

      const resetPasswordRequest: AxiosRequestConfig = {
        url: PASSWORD_URL(storeID),
        method: 'PUT',
        data: {
          logonId,
          resetPassword,
          logonPassword,
          xcred_logonPasswordVerify,
          xcred_validationCode,
          x_reCaptchaToken,
        },
      };

      const resetPasswordResponse = await makeRequest(resetPasswordRequest);

      return resetPasswordResponse;
    } catch (e) {
      throw e;
    }
  }
}

export { AuthenticationService };
