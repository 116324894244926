export const GET_WISH_LIST = 'GET_WISH_LIST';
export const GET_WISH_LIST_SUCCESS = 'GET_WISH_LIST_SUCCESS';
export const ADD_REMOVE_WISH_LIST = 'ADD_REMOVE_WISH_LIST';
export const ADD_REMOVE_WISH_LIST_SUCCESS = 'ADD_REMOVE_WISH_LIST_SUCCESS';
export const DELETE_REMOVE_WISH_LIST = 'DELETE_REMOVE_WISH_LIST';
export const DELETE_REMOVE_WISH_LIST_SUCCESS =
  'DELETE_REMOVE_WISH_LIST_SUCCESS';
export const FETCH_WISH_LIST_PRODUCTS = 'FETCH_WISH_LIST_PRODUCTS';
export const FETCH_WISH_LIST_PRODUCTS_SUCCESS =
  'FETCH_WISH_LIST_PRODUCTS_SUCCESS';
export const WISH_LIST_LOADING = 'WISH_LIST_LOADING';
export const RESET_WISH_LIST = 'RESET_WISH_LIST';
export const SHARE_WISH_LIST = 'SHARE_WISH_LIST';
export const SHARE_WISH_LIST_SUCCESS = 'SHARE_WISH_LIST_SUCCESS';
export const RESET_SHARE_WISH_LIST = 'RESET_SHARE_WISH_LIST';
export const FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS =
  'FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS';
export const CLEAR_WISH_LIST_PRODUCT_PAGE = 'CLEAR_WISH_LIST_PRODUCT_PAGE';
export const FETCH_WISH_LIST_ITEMS = 'FETCH_WISH_LIST_ITEMS';
export const FETCH_WISH_LIST_ITEMS_SUCCESS = 'FETCH_WISH_LIST_ITEMS_SUCCESS';
export const REFRESH_WISH_LIST_PLP = 'REFRESH_WISH_LIST_PLP';
export const TOGGLE_WISH_LIST_PLP_PAGES = 'TOGGLE_WISH_LIST_PLP_PAGES';
export const SET_WISH_LIST_PLP_LOADING = 'SET_WISH_LIST_PLP_LOADING';
export const ADD_ITEM_TO_WISHLIST = 'ADD_ITEM_TO_WISHLIST';
export const SET_CURRENT_GIFT_LIST_ID = 'SET_CURRENT_GIFT_LIST_ID';
export const FETCH_SHARED_WISHLIST_DETAILS = 'FETCH_SHARED_WISHLIST_DETAILS';
