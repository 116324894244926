/**
 * Default Validator for form validation throughout the entire form.
 *
 */

import { AddressFormConstants } from '../../components/Widgets/AddressForm/AddressForm.constants';

const SPECIAL_INPUT_CHARACTERS_REGEX = /^[a-zA-Z0-9- ]+$/;
const ADDRESS_SPECIAL_INPUT_CHARACTERS_REGEX = /^[a-zA-Z0-9- .]+$/;
const EMAIL_CHARACTERS_REGEX = /^[+a-zA-Z0-9._@-]+$/;
const NO_LETTERS_REGEX = /^[0-9]*$/;
const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]*$/;
const PHONE_NUMBER_REGEX = /^[0-9+-]/;
const PHONE_NUMBER_NON_US_REGEX = /^[0-9-+()]*$/;
const { PO_BOX_REGEX } = AddressFormConstants;

const DefaultValidator = {
  INPUT_VALIDATIONS: {
    maxInputCharacters: (value: string) => value.substring(0, 40),

    maxInputCharactersWithValues: (maxLength: number, value: string) => {
      const maximumLength = maxLength > 0 ? maxLength : 40;
      return value.substring(0, maximumLength);
    },

    phoneNumberValidation: (value: string, message?: string) => {
      if (!value) return '';

      if (!PHONE_NUMBER_REGEX.test(value) || value.length < 10)
        return message || 'Invalid phone number has been entered.';

      return '';
    },
    phoneOutsideUS: (value: string, message?: string) => {
      if (!value) return '';

      if (!PHONE_NUMBER_NON_US_REGEX.test(value) || value.length > 20)
        return message || 'Invalid phone number has been entered.';

      return '';
    },
    numberValidation: (value: string, message?: string) => {
      if (!value) return '';

      if (!NO_LETTERS_REGEX.test(value))
        return message || 'Invalid character has been entered.';

      return '';
    },

    alphaNumericValidation: (value: string, message?: string) => {
      if (!value) return '';

      if (!ALPHA_NUMERIC_REGEX.test(value))
        return message || 'Invalid character has been entered.';

      return '';
    },

    emailInputValidation: (value: string, message?: string) => {
      if (!value) return '';

      if (!EMAIL_CHARACTERS_REGEX.test(value))
        return message || 'Invalid character has been entered.';

      return '';
    },
    specialInputCharacters: (value: string, message?: string) => {
      if (!value) return '';

      if (!SPECIAL_INPUT_CHARACTERS_REGEX.test(value))
        return message || 'Invalid character has been entered.';

      return '';
    },
    cityValidation: (value: string, message?: string) => {
      if (!value) return '';

      if (!SPECIAL_INPUT_CHARACTERS_REGEX.test(value))
        return message || 'Invalid character has been entered.';

      return '';
    },
    addressValidation: (
      value: string,
      message?: string,
      isShippingAddress?: boolean
    ) => {
      if (!value) return '';

      let addressVal =
        ADDRESS_SPECIAL_INPUT_CHARACTERS_REGEX.test(value) ||
        value.includes("'") ||
        value.includes('"') ||
        value.includes(',') ||
        value.includes('/');

      let poBoxErr = PO_BOX_REGEX.test(value);

      if (poBoxErr && isShippingAddress) {
        return 'We are unable to ship to a PO Box. Please provide a physical street address.';
      }

      if (!addressVal) {
        return message || 'Invalid character has been entered.';
      }
      return '';
    },
  },
};

export { DefaultValidator };
