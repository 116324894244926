function getPageHash(url: string): string {
  let hash: number = 0;
  const characterArray: string[] = Array.from(url);

  for (let i = 0; i < characterArray.length; i++) {
    const characterOrd: number = characterArray[i].charCodeAt(0);
    const temp1: number = getSignedNumber(hash << 5);
    const temp2: number = getSignedNumber(temp1 - hash);
    hash = getSignedNumber(temp2 + characterOrd);
    hash = getSignedNumber(hash & hash);
  }
  if (hash < 0) {
    return `0${-hash}`;
  } else {
    return `${hash}`;
  }
}

function getSignedNumber(number: number): number {
  const bitLength: number = 32;
  const mask: number = Math.pow(2, bitLength) - 1;
  const testMask: number = 1 << (bitLength - 1);

  if ((number & testMask) !== 0) {
    return number | ~mask;
  } else {
    return number & mask;
  }
}

export { getPageHash };
