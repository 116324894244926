import { AxiosRequestConfig, CancelToken } from 'axios';
import { OrdersResponse } from '../../../components/Widgets/Orders/OrderDetail/IOrderDetail';
import { USER_CONTRACT } from '../../constants/cookie';
import {
  ICatalogFormDetail,
  IOrderLookUp,
  IOrderLookUpRequest,
} from '../../interface/Order/IOrderLookUp';
import { makeRequest } from '../axios/axiosConfig';
import {
  OrderLookUpServiceConstant,
  START_RANGE_PREFIX,
  END_RANGE_PREFIX,
} from './order-lookup.service.constants';

/**
 * @interface IFetchOrderAuthenticatedUser
 */
export interface IFetchOrderAuthenticatedUser {
  storeID: string;
  range?: string | null;
  cancelToken: CancelToken;
}

/**
 * @interface IFetchOrderItemDetails
 */
export interface IFetchOrderItemDetails {
  storeID: string;
  partNumbers: Array<string>;
  cancelToken?: CancelToken;
}

interface ICatalogRequestDetails {
  storeId: string;
  catalogFormDetail: ICatalogFormDetail;
}

export interface ISendInvoiceRequest {
  recipientEmail: string;
  storeId: string;
  orderNumber: string;
  orderType: string;
  sourceSystem: string;
  recaptchaToken: string;
}

const orderService = {
  async fetchOrderLookupByOrderNoOrCustomerNo({
    storeID,
    inZip,
    inOrder,
    inCust,
    range: inRange,
    cancelToken,
  }: IOrderLookUpRequest): Promise<IOrderLookUp> {
    const { ORDER_LOOKUP_URL } = OrderLookUpServiceConstant;

    const fetchOrderLookupByOrderNoOrCustomerNoUrl = ORDER_LOOKUP_URL(storeID);

    /**
     * If user selects a range, send the * range in format MM/DD/YYYY - MM/DD/YYYY (startDate - endDate)
     */
    if (inRange) {
      inRange = START_RANGE_PREFIX + inRange + END_RANGE_PREFIX + inRange;
    }

    const queryParams = {
      inZip,
      ...{ ...(inOrder && { inOrder }) },
      ...{ ...(inCust && { inCust }) },
      ...{ ...(inRange && { inRange }) },
    };

    try {
      const request: AxiosRequestConfig = {
        url: fetchOrderLookupByOrderNoOrCustomerNoUrl,
        params: queryParams,
        method: 'GET',
        cancelToken,
      };

      const response = await makeRequest(request);

      return response;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchOrdersForAuthenticatedUser Fetches Product promo content for a product Id.
   *
   * @param storeID - Store Id from my site.
   * @param range - Range for the order history to be fetched.
   * @param cancelToken - To cancel request if user has navigated to another page.
   */
  async fetchOrdersForAuthenticatedUser({
    storeID,
    range: inRange,
    cancelToken,
  }: IFetchOrderAuthenticatedUser): Promise<OrdersResponse> {
    const { ORDER_LOOKUP_URL } = OrderLookUpServiceConstant;

    const fetchOrderLookupByOrderNoOrCustomerNoUrl = ORDER_LOOKUP_URL(storeID);

    /**
     * If user selects a range, send the * range in format MM/DD/YYYY - MM/DD/YYYY (startDate - endDate)
     */
    if (inRange) {
      inRange = START_RANGE_PREFIX + inRange + END_RANGE_PREFIX + inRange;
    }

    const queryParams = {
      inRange,
    };

    try {
      const request: AxiosRequestConfig = {
        url: fetchOrderLookupByOrderNoOrCustomerNoUrl,
        params: queryParams,
        method: 'GET',
        cancelToken,
      };

      const response = await makeRequest(request);

      return response;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchOrderItemDetails Fetches Product promo content for a product Id.
   *
   * @param storeID - Store Id from my site.
   * @param partNumbers - part number of each order item.
   * @param cancelToken - To cancel request if user has navigated to another page.
   */
  async fetchOrderItemDetails({
    storeID,
    partNumbers,
    cancelToken,
  }: IFetchOrderItemDetails): Promise<any> {
    let queryParams = new URLSearchParams();

    partNumbers.forEach((partNumber) => {
      partNumber && queryParams.append('partNumber', partNumber);
    });
    const { ORDER_ITEM_LOOKUP_URL } = OrderLookUpServiceConstant;

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    const fetchOrderLookupByOrderNoOrCustomerNoUrl =
      ORDER_ITEM_LOOKUP_URL(storeID);

    if (userContractId) {
      queryParams.append('contractId', userContractId);
    }

    try {
      const request: AxiosRequestConfig = {
        url: fetchOrderLookupByOrderNoOrCustomerNoUrl,
        params: queryParams,
        method: 'GET',
        cancelToken,
      };

      const response = await makeRequest(request);

      return response;
    } catch (e) {
      throw e;
    }
  },

  async sendCatalogRequest({
    storeId,
    catalogFormDetail,
  }: ICatalogRequestDetails): Promise<any> {
    try {
      const { ORDER_CATALOG_REQUEST_URL } = OrderLookUpServiceConstant;

      const sendCatalogRequest: AxiosRequestConfig = {
        url: ORDER_CATALOG_REQUEST_URL(storeId),
        method: 'POST',
        data: {
          ...catalogFormDetail,
        },
      };

      const sendCatalogResponse = await makeRequest(sendCatalogRequest);

      return sendCatalogResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method sendInvoice Makes request to send Invoice.
   *
   * @param IShareListRequest
   */
  async sendInvoice({
    recipientEmail,
    storeId,
    orderNumber,
    orderType,
    sourceSystem,
    recaptchaToken,
  }: ISendInvoiceRequest): Promise<any> {
    try {
      const { SEND_INVOICE_URL } = OrderLookUpServiceConstant;

      const sendInvoiceRequest: AxiosRequestConfig = {
        url: SEND_INVOICE_URL(storeId),
        method: 'POST',
        data: {
          recipientEmail,
          orderNumber,
          orderType,
          sourceSystem,
          recaptchaToken,
        },
      };

      const sendInvoiceResponse: any = await makeRequest(sendInvoiceRequest);

      return sendInvoiceResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { orderService };
