const BASE_URL = '/wcs/resources/store/';

const AuthenticationServiceConstants = {
  LOGIN_IDENTITY_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/loginidentity`,
  USER_INFO_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/person/@self?langId=-1`,
  PASSWORD_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/person/@self?responseFormat=application%2Fjson&langId=-1`,
  REGISTER_URL: (storeID: string) =>
    `/wcs/resources/store/${storeID}/person?langId=-1`,
  CONTACT_INFORMATION_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/person/@self/contact`,
  GET_RECENT_ORDERS: (storeID: string) =>
    `${BASE_URL}${storeID}/order/@history`,
  UPDATE_USER_REGISTRATION: (storeID: string) =>
    `${BASE_URL}${storeID}/person/@self?action=updateUserRegistration`,
  GUEST_IDENTITY_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/guestidentity`,
  USER_CONTRACT_URL: (storeID: string) =>
    `${BASE_URL}${storeID}/contract?q=eligible&langId=-1`,
};

export { AuthenticationServiceConstants };
