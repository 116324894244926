import { takeLatest } from 'redux-saga/effects';
import { FETCH_CART_ITEM_DETAILS_REQUEST_ACTION } from '../../actions/orderDetails.action';
import { fetchItemDetailsByIds } from '../workers/orderDetails.workers';

export function* watchSaga() {
  yield takeLatest(
    FETCH_CART_ITEM_DETAILS_REQUEST_ACTION,
    fetchItemDetailsByIds
  );
}
