import { TextField, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { decode } from 'html-entities';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { formatSearchTerm } from '../../../../../src/utils/utils';
import { CANCEL_ON_SEARCH } from '../../../../_foundation/constants/cancel';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { ProductEntry } from '../../../../_foundation/interface/SearchSiteContent/ISearchSiteContent';
import { PrimaryNavBarConstants } from '../../../../components/Widgets/AppBar/PrimaryNavBar/PrimaryNavBarConstants';
import { SeoConstants } from '../../../../components/Seo/SeoConstants';
import { BreadcrumbsConstants } from '../../../../components/Widgets/BreadCrumbs/BreadcrumbsConstants';
import { useSearchBar } from '../../../../components/Widgets/SearchBar/hooks/SearchBarHooks';
import {
  CLEAR_SEARCH_HISTORY_ACTION,
  GET_SEARCH_HISTORY_ACTION,
} from '../../../../components/Widgets/SearchHistory/actions/search-history.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  RESET_PRODUCT_LIST_ACTION,
  RESET_SEARCH_DISPLAY_ACTION,
  SEARCH_DISPLAY_REQUEST_ACTION,
} from '../../../../redux/actions/productList.actions';
import { highLightText } from '../../../../utils/highlight-text';
import { useProductSuggestionSiteContent } from '../../../../utils/hooks/SearchSiteContent/ProductSuggestionContent';
import { useSearchProductSiteContent } from '../../../../utils/hooks/SearchSiteContent/SearchProductsContent';
import { useSuggestTypeContent } from '../../../../utils/hooks/SearchSiteContent/SuggestTypeContent';
import { SearchLayoutConstants } from '../../../Layouts/Search/SearchLayout.constants';
import { NteIconButton } from '../../../NteIconButton/NteIconButton';
import { useNtePopper } from '../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { FacetConstants } from '../../Facets/Facet/FacetConstants';
import { SearchHistoryConstants } from '../../SearchHistory/SearchHistoryConstants';
import { ADD_SEARCH_HISTORY_ACTION } from '../../SearchHistory/actions/search-history.actions';
import { searchHistorySelector } from '../../SearchHistory/selectors/search-history.selector';
import '../SearchBar.scss';

/**
 * @component
 * Responsible for rendering the CustomSearchBar component
 */
const NtePrimarySearchBar: React.FC = () => {
  const { SEARCH, PRODUCTS, SEARCH_FALLBACK } = PrimaryNavBarConstants;

  const { CLEAR_SEARCH_HISTORY_TITLE } = SearchHistoryConstants;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const { CATEGORY_PATH, BRAND_PATH } = SeoConstants;

  const { CATEGORIES, BRANDS } = BreadcrumbsConstants;

  const { CATEGORY_QUERY_KEY } = FacetConstants;

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { mySite } = useSite();

  const suggestionRef = useRef(null);

  const inputRef: any = useRef(null);

  const [, setCategoryParams] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const [searchQueryParam] = useQueryParam(SEARCH_QUERY_PARAM, StringParam);

  const { showPopper: showSuggestionMenu, setShowPopper } = useNtePopper({
    id: 'suggestion-menu',
    focusTrap: false,
    popperPlacement: 'bottom-start',
  });

  const searchLabel = i18n.exists(SEARCH).valueOf()
    ? t(SEARCH)
    : SEARCH_FALLBACK;

  const dispatch = useDispatch();

  /**
   * Responsible for getting the Search History terms
   */
  const { searchItems } = useSelector(searchHistorySelector);

  const { currentValue, setCurrentValue } = useSearchBar('');

  const { categoriesBrandsList, cancelSource: cancelSuggestion } =
    useSuggestTypeContent({
      currentValue,
      showSuggestionMenu,
    });

  const categoriesBrandsSuggestionList = categoriesBrandsList.slice(0, 5);

  const { productSuggestionList, cancelSource: cancelProductSuggestion } =
    useProductSuggestionSiteContent({
      currentValue,
      showSuggestionMenu,
    });

  let currentProductSuggestionList = productSuggestionList.filter(
    (suggestedProduct) =>
      Number(suggestedProduct['inventories.total.quantity']) > 0
  );

  useSearchProductSiteContent();

  /**
   * @method executeSiteSearch Searches for with the current value
   * and enters the current value in the search history.
   */
  const executeSiteSearch = (searchValue?: string): void => {
    let path = history.location.pathname.split('/');

    /**
     * Check if the url contains the searchValue
     * if searchValue is unique and searchValue or currentValue exist, execute search
     */

    if (
      !(path[path.length - 1] === (searchValue ? searchValue : currentValue)) &&
      (searchValue || currentValue)
    ) {
      if (
        searchQueryParam && searchQueryParam !== searchValue
          ? searchValue
          : currentValue
      ) {
        dispatch(RESET_PRODUCT_LIST_ACTION({}));

        dispatch(RESET_SEARCH_DISPLAY_ACTION());
      }

      setCategoryParams(undefined, 'replaceIn');

      dispatch(ADD_CATEGORY_IDS_ACTION([]));

      if ((currentValue || searchValue) && mySite) {
        /**
         * Responsible to fetch the search term belongs to redirection or search plp
         */
        dispatch(
          SEARCH_DISPLAY_REQUEST_ACTION({
            storeID: mySite.storeID,
            /**
             * @var searchValue will be considered when the user doesn't enters a value in search bar
             * and tries to select a value from the suggestion popper.
             *
             * @var currentValue will be considered when the user types a value in the search bar and
             * clicks the search icon, in this case, the searchValue will get populated with synthetic event
             * and will result an error so to avoid that searchValue type is checked with string type.
             */
            term: searchValue
              ? typeof searchValue === 'string'
                ? searchValue
                : formatSearchTerm(currentValue)
              : formatSearchTerm(currentValue),
            catalogId: mySite.catalogID,
          })
        );

        /**
         * Initialize the cancel token to cancel the suggestion calls
         * when the user hits enter or clicks an item from suggestion list.
         */
        cancelProductSuggestion?.cancel(CANCEL_ON_SEARCH);

        cancelSuggestion?.cancel(CANCEL_ON_SEARCH);
      }

      if (typeof searchValue !== 'string') {
        dispatch(ADD_SEARCH_HISTORY_ACTION(currentValue));
      } else {
        dispatch(ADD_SEARCH_HISTORY_ACTION(searchValue || currentValue));
      }

      setShowPopper(false);
    }
  };

  /**
   * @method setCurrentValueFromSuggestions Sets the currently selected suggestion value in
   * the search input field and navigates the user to the search plp page.
   *
   * @param currentSearchValue Currently selected search value from the suggestion list.
   */
  const setCurrentValueFromSuggestions = (
    currentSearchValue: string,
    isProductSuggestion: boolean
  ): void => {
    if (isProductSuggestion) {
      history.push(currentSearchValue);
    } else {
      setCurrentValue(currentSearchValue);

      if (currentSearchValue !== searchQueryParam) {
        executeSiteSearch(currentSearchValue);
      }
    }

    setShowPopper(false);
    setHasKeyboardNavigated(false);
  };

  const checkPopper = useCallback((): void => {
    const noSuggestion =
      categoriesBrandsList.length === 0 &&
      searchItems.length === 0 &&
      productSuggestionList.length === 0 &&
      currentValue.length === 0;

    if (noSuggestion) {
      setShowPopper(false);
      return;
    }
  }, [
    searchItems.length,
    setShowPopper,
    categoriesBrandsList.length,
    productSuggestionList.length,
    currentValue,
  ]);

  /**
   * The searchbar icons need to be collapsed below 486px
   */
  const condensedState = useMediaQuery('(max-width:486px)');
  /**
   * condensedSearchBar flags whether the searchbar is running in condensed
   * mobile mode
   */
  const [condensedSearchBar, setCondensedSearchBar] = useState('');

  /**
   * state used to determine icon animation
   */
  const [activeState, setActiveState] = useState('');

  /**
   * state used for handling popper
   */
  const [popperOpen, setPopperOpen] = useState(false);

  /**
   * state used for handling blur event for magnifier
   */
  const [blur, setBlur] = useState(false);

  /**
   * state used for handling arrow keypresses
   */
  const [hasKeyboardNavigated, setHasKeyboardNavigated] =
    useState<boolean>(false);

  /**
   * clearSearchHistoryHandler used to clear the search array
   */

  const clearSearchHistoryHandler = (): void => {
    dispatch(CLEAR_SEARCH_HISTORY_ACTION());
  };

  /**
   * handleSearchEvent used to determine when to show the closeIcon
   *
   */
  const handleSearchEvent = (e: any) => {
    if (e.target.value.length > 0) {
      setActiveState('state-active');
    }
    if (e.target.value.length === 0) {
      setActiveState('');
    }
  };

  /**
   * handleCancelIcon responsible for clearing the text in searchBar
   */
  const handleCancelIcon = () => {
    if (setCurrentValue) {
      setCurrentValue('');
      setActiveState('');
    }
  };

  /**
   * HandleFocus responsible for reverting the change
   */
  const handleFocus = (e: any) => {
    setBlur(false);
  };

  /**
   * Responsible for UI changes
   */
  useEffect(() => {
    setCondensedSearchBar(condensedState ? 'condensed-search' : '');
  }, [condensedState, setCondensedSearchBar]);

  /**
   * Responsible for showing the popper
   */
  useEffect(() => {
    checkPopper();
  }, [currentValue, searchItems, productSuggestionList, checkPopper]);

  /**
   * Responsible for getting Search History
   */
  useEffect(() => {
    dispatch(GET_SEARCH_HISTORY_ACTION());
  }, [dispatch]);

  /**
   * Responsible for setting blur event when clicks on magnifier
   */
  useEffect(() => {
    if (blur) {
      inputRef.current.blur();
    }
  }, [blur]);

  const productSuggestionItems: ProductEntry[] =
    currentProductSuggestionList.slice(0, 5);

  const searchData = [
    {
      type: 'Categories + Brands',
      product: categoriesBrandsSuggestionList,
    },
    {
      type: 'Products',
      product: productSuggestionItems,
    },
    {
      type: 'Search',
      product: searchItems,
    },
  ];

  const finalConstructedSearchData = searchData.reduce(
    (searchTerm: any, row: any) => {
      const finalData: any = [];

      row?.product?.map((data: any) => {
        if (data?.partNumber) {
          return finalData.push({
            type: row.type,
            name: data.name,
            fullData: data,
          });
        } else if (row.type === 'Categories + Brands') {
          return finalData.push({
            type: row.type,
            name: data.name,
            fullData: data,
          });
        } else {
          return finalData.push({
            type: row.type,
            name: data,
          });
        }
      });
      searchTerm = searchTerm.concat(finalData);
      return searchTerm;
    },
    []
  );

  if (searchItems.length > 0) {
    if (
      searchItems.length === 1 &&
      searchItems[0] === t(CLEAR_SEARCH_HISTORY_TITLE)
    ) {
      dispatch(CLEAR_SEARCH_HISTORY_ACTION());
    }
    const isAvailable = finalConstructedSearchData.find(
      (data: any) => data.name === t(CLEAR_SEARCH_HISTORY_TITLE)
    );

    if (!isAvailable) {
      finalConstructedSearchData.push({
        type: 'Search',
        name: t(CLEAR_SEARCH_HISTORY_TITLE),
      });
    }
  }

  const onChangeHanlder = (e: any, item: any) => {
    const currentInputValue = e.target?.value;

    if (item !== null && item !== undefined) {
      if (item.name === t(CLEAR_SEARCH_HISTORY_TITLE)) {
        clearSearchHistoryHandler();
      }
      // Check for keyboard navigation
      // If enter is pressed compare input values
      // if they differ - set input value for search
      // Blur the input tell the popper to close
      if (
        hasKeyboardNavigated === false &&
        (e?.code === 'Enter' || e?.code === 'NumpadEnter') &&
        currentInputValue !== item?.name
      ) {
        setCurrentValueFromSuggestions(currentInputValue, false);

        inputRef.current.blur();
        setPopperOpen(false);
        return;
      }

      if (
        item?.type &&
        item?.name &&
        item?.name !== t(CLEAR_SEARCH_HISTORY_TITLE)
      ) {
        setCurrentValue(decode(item.name));

        item?.type === 'Products'
          ? setCurrentValueFromSuggestions(item.fullData.seo.href, true)
          : item?.fullData?.fullPath
              .toLowerCase()
              .includes(CATEGORIES.toLowerCase())
          ? setCurrentValueFromSuggestions(
              CATEGORY_PATH + item.fullData.seo.href,
              true
            )
          : item?.fullData?.fullPath
              .toLowerCase()
              .includes(BRANDS.toLowerCase())
          ? setCurrentValueFromSuggestions(
              BRAND_PATH + item.fullData.seo.href,
              true
            )
          : item.name &&
            setCurrentValueFromSuggestions(decode(item.name), false);
      } else {
        if (item.name !== t(CLEAR_SEARCH_HISTORY_TITLE)) {
          item && setCurrentValueFromSuggestions(item, false);
        }
      }

      inputRef.current.blur();
    }
  };

  return (
    <div className={`nte_primary_search_bar ${condensedSearchBar}`}>
      <div className='search-bar__suggestion-anchor' ref={suggestionRef}></div>

      <form name='searchBar' onSubmit={(e) => e.preventDefault()}>
        <Autocomplete
          id='product-search'
          options={finalConstructedSearchData}
          role='combobox'
          className={
            activeState + ' ' + currentValue ? 'state-active-clear' : ''
          }
          groupBy={(option: any) => option.type}
          getOptionLabel={(option: any) => decode(option?.name)}
          getOptionSelected={(option, value) => option.name === value.name}
          disablePortal={true}
          aria-live='assertive'
          aria-autocomplete='list'
          aria-haspopup='listbox'
          clearOnBlur={false}
          onFocus={() => {
            setPopperOpen(true);
          }}
          onBlur={() => {
            setPopperOpen(false);
            setActiveState('');
          }}
          open={
            (currentValue.length > 0 || searchItems.length > 0) && popperOpen
          }
          onKeyDown={(event: any) => {
            if (event.key === 'ArrowDown') {
              setHasKeyboardNavigated(true);
            }
            if (event.key === 'Escape') {
              inputRef.current.blur();
            }
          }}
          selectOnFocus={false}
          autoHighlight={false}
          openOnFocus
          filterOptions={(options: any) => options}
          onChange={(e: any, item: any) => {
            onChangeHanlder(e, item);
          }}
          inputValue={currentValue}
          onInputChange={(e, newInputValue, reason) => {
            if (reason === 'input') {
              setCurrentValue(newInputValue);
            }
            if (reason === 'clear') {
              setCurrentValue('');
            }
          }}
          noOptionsText={null}
          freeSolo={true}
          renderOption={(option, { inputValue }) => {
            return (
              <>
                {option.type.toLowerCase() === searchLabel.toLowerCase() ? (
                  <>
                    {option.name !== t(CLEAR_SEARCH_HISTORY_TITLE) ? (
                      <>
                        <HistoryIcon className='custom-search-icon' />

                        <Typography variant='body2' className='highlight-text'>
                          {highLightText(
                            decode(option.name),
                            decode(inputValue)
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        onClick={clearSearchHistoryHandler}
                        tabIndex={0}
                        component='a'
                        variant='body2'>
                        {t(CLEAR_SEARCH_HISTORY_TITLE)}
                      </Typography>
                    )}
                  </>
                ) : (
                  <div>
                    <div className='fb'>
                      <SearchIcon className='custom-search-icon' />
                      <Typography variant='body2' className='highlight-text'>
                        {highLightText(decode(option.name), decode(inputValue))}
                      </Typography>
                    </div>
                    {option.type.toLowerCase() ===
                      t(PRODUCTS).toLowerCase() && (
                      <p className='pxs'>Item# {option.fullData.partNumber}</p>
                    )}
                  </div>
                )}
              </>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                variant='outlined'
                aria-describedby='instructions'
                aria-haspopup='listbox'
                className='search-input-wrapper'
                label={searchLabel}
                onChange={(e) => handleSearchEvent(e)}
                onFocus={(e) => handleFocus(e)}
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <div className='animation-stage'>
                        {currentValue && (
                          <NteIconButton
                            aria-label='cancel search'
                            onClick={handleCancelIcon}
                            className='close-icon plain-icon-button'>
                            <CloseIcon aria-hidden='true' />
                          </NteIconButton>
                        )}
                        <NteIconButton
                          type='submit'
                          aria-label='search'
                          onClick={(e) => {
                            e.preventDefault();

                            setBlur(true);
                            setActiveState('');

                            if (currentValue !== searchQueryParam) {
                              executeSiteSearch();
                            }
                          }}
                          className='search-icon-gray plain-icon-button'>
                          <SearchIcon fontSize='large' aria-hidden='true' />
                        </NteIconButton>
                      </div>
                    </>
                  ),
                }}
              />
              <div id='instructions' style={{ display: 'none' }}>
                Begin typing to search, use arrow keys to navigate, Enter to
                select
              </div>
            </>
          )}
        />
      </form>
    </div>
  );
};

export { NtePrimarySearchBar };
