import { Dispatch } from 'react';
import { SiteInfoService, SiteInfoArgs, SiteInfo } from './SiteInfoService';
//Redux

/**
 * @method getSite - returns site information
 * @returns SiteInfo - Information to contact backend.
 */
function getSite(): SiteInfo | null {
  return SiteInfoService.getSiteInfo().getSiteValue();
}

/**
 * @method initSite - Initializes site state.
 * @param siteInfo - Containe site information for Initialization.
 * @param dispatch - To dispatch Redux event to set site state.
 */
function initSite(
  siteInfo: SiteInfoArgs,
  dispatch: Dispatch<any>
): Promise<void> {
  return SiteInfoService.getSiteInfo().setSite(siteInfo, dispatch);
}

export { getSite, initSite };
