import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../../../../../redux/selectors/categories.selector';
import { IProductListFacets } from '../../../../../_foundation/interface/ProductList/IProductList';

/**
 * @hook useLeafPage
 * Responsible to get displayable facet based in leaf page
 */

const useLeafPage = (facetValues: IProductListFacets[]) => {
  const { leafNodes, currentCategoryId } = useSelector(categoriesSelector);

  const [displayableFacet, setDisplayableFacet] = useState<
    IProductListFacets[]
  >([]);

  const initDisplayableFacet = useCallback((): void => {
    let displayableFacet: IProductListFacets[] = [];

    const isLeafPage = leafNodes.includes(
      currentCategoryId ? currentCategoryId : ''
    );

    if (!isLeafPage) {
      const MandatoryFacet = [
        'sold-at-store',
        'path',
        'manufacturer.raw',
        'Rating',
        'Promotion',
        'New Arrival',
      ];

      const facetWithoutSpec = facetValues?.filter((facet) => {
        if (facet?.extendedData) {
          if (MandatoryFacet?.includes(facet?.extendedData?.propertyvalue)) {
            return facet;
          }
        } else if (facet?.value === 'price_usd') {
          return facet;
        }

        return '';
      });

      displayableFacet = [...facetWithoutSpec];
    } else {
      displayableFacet = [...facetValues];
    }

    setDisplayableFacet(displayableFacet);
  }, [currentCategoryId, facetValues, leafNodes]);

  useEffect(() => {
    initDisplayableFacet();
  }, [initDisplayableFacet]);

  return {
    displayableFacet,
  };
};

export { useLeafPage };
