
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IWishlistResponse from './IWishlistResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IWishlistResponse };

  export const IWishlistResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IGiftListItem": {
            "properties": {
                "accessSpecifier": {
                    "type": "string"
                },
                "createdTime": {
                    "type": "string"
                },
                "description": {
                    "type": "string"
                },
                "descriptionName": {
                    "type": "string"
                },
                "event": {
                    "type": "string"
                },
                "externalIdentifier": {
                    "type": "string"
                },
                "giftCardAccepted": {
                    "type": "string"
                },
                "guestAccessKey": {
                    "type": "string"
                },
                "item": {
                    "items": {
                        "$ref": "#/definitions/IItemItem"
                    },
                    "type": "array"
                },
                "lastUpdate": {
                    "type": "string"
                },
                "location": {
                    "type": "string"
                },
                "optForEmail": {
                    "type": "string"
                },
                "registry": {
                    "type": "string"
                },
                "registryAccessKey": {
                    "type": "string"
                },
                "state": {
                    "type": "string"
                },
                "storeId": {
                    "type": "string"
                },
                "storeName": {
                    "type": "string"
                },
                "storeOwnerID": {
                    "type": "string"
                },
                "uniqueID": {
                    "type": "string"
                }
            },
            "required": [
                "accessSpecifier",
                "createdTime",
                "description",
                "descriptionName",
                "event",
                "externalIdentifier",
                "giftCardAccepted",
                "guestAccessKey",
                "lastUpdate",
                "location",
                "optForEmail",
                "registry",
                "registryAccessKey",
                "state",
                "storeId",
                "storeName",
                "storeOwnerID",
                "uniqueID"
            ],
            "type": "object"
        },
        "IItemItem": {
            "properties": {
                "giftListItemID": {
                    "type": "string"
                },
                "itemCreatedTime": {
                    "type": "string"
                },
                "itemLastUpdate": {
                    "type": "string"
                },
                "location": {
                    "type": "string"
                },
                "partNumber": {
                    "type": "string"
                },
                "productId": {
                    "type": "string"
                },
                "productOwnerID": {
                    "type": "string"
                },
                "quantityBought": {
                    "type": "string"
                },
                "quantityRequested": {
                    "type": "string"
                }
            },
            "required": [
                "giftListItemID",
                "itemCreatedTime",
                "itemLastUpdate",
                "location",
                "partNumber",
                "productId",
                "productOwnerID",
                "quantityBought",
                "quantityRequested"
            ],
            "type": "object"
        }
    },
    "properties": {
        "GiftList": {
            "items": {
                "$ref": "#/definitions/IGiftListItem"
            },
            "type": "array"
        },
        "recordSetComplete": {
            "type": "string"
        },
        "recordSetCount": {
            "type": "string"
        },
        "recordSetStartNumber": {
            "type": "string"
        },
        "recordSetTotal": {
            "type": "string"
        },
        "resourceId": {
            "type": "string"
        },
        "resourceName": {
            "type": "string"
        }
    },
    "required": [
        "GiftList",
        "recordSetComplete",
        "recordSetCount",
        "recordSetStartNumber",
        "recordSetTotal",
        "resourceId",
        "resourceName"
    ],
    "type": "object"
}

;

  export const isIWishlistResponse = ajv.compile(IWishlistResponseSchema) as ValidateFunction<
    IWishlistResponse
  >;

  export default function validateIWishlistResponse(value: unknown): IWishlistResponse {
    if (isIWishlistResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIWishlistResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IWishlistResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIWishlistResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IWishlistResponse' },
        ) +
          inspect(value)
      );
    }
  }