import { checkTruthy } from '../../utils/utils';

/**
 * @method manipulateAccordion
 * Responsible for adding/removing "open" class to the accordion container
 */
const manipulateAccordion = (): void => {
  const accordions = document.getElementsByClassName('accordion');

  // Loop through HTML collection to operate on header and parent
  Array.from(accordions).forEach((accordion) => {
    const clickHeader: HTMLElement = accordion.getElementsByClassName(
      'header'
    )[0] as HTMLElement;

    const accordionButton = accordion.getElementsByTagName('button');

    if (
      accordionButton &&
      accordionButton[0] &&
      accordionButton[0].getAttribute('id')
    ) {
      accordionButton[0].setAttribute(
        'aria-controls',
        accordionButton[0].getAttribute('id') as string
      );

      accordionButton[0].setAttribute(
        'aria-label',
        accordionButton[0].innerHTML
      );

      accordionButton[0].setAttribute('aria-expanded', 'false');
    } else {
      accordion.setAttribute('aria-expanded', 'false');

      if (accordion?.getAttribute('id')) {
        accordion.setAttribute(
          'aria-controls',
          accordion?.getAttribute('id') as string
        );
      } else {
        accordion.setAttribute('aria-controls', 'information-accordion');
      }
    }

    /**
     * Evalate child element header for listener status / classname
     * Ensuring event listener is only bound once.
     */

    if (!clickHeader.classList.contains('listener')) {
      clickHeader.addEventListener('click', (e) => {
        /**
         * Find current Dom element / parent based on event
         * toggle class name in parent element
         *
         */
        const currentElement = e.currentTarget as HTMLElement;
        const accordionParent = currentElement.parentNode as HTMLElement;

        accordionParent.classList.toggle('open');

        if (
          accordionButton &&
          accordionButton[0] &&
          accordionButton[0].getAttribute('aria-expanded')
        ) {
          const ariaExpanded = !checkTruthy(
            accordionButton[0].getAttribute('aria-expanded')?.toString()
          );

          accordionButton[0].setAttribute(
            'aria-expanded',
            ariaExpanded.toString()
          );
        } else if (accordionParent?.getAttribute('aria-expanded')) {
          const ariaExpanded = !checkTruthy(
            accordionParent.getAttribute('aria-expanded')?.toString()
          );

          accordionParent.setAttribute(
            'aria-expanded',
            ariaExpanded.toString()
          );
        }

        e.stopPropagation();
        e.preventDefault();
      });

      clickHeader.classList.add('listener');
    }
  });

  // Remove the open class for accordions with the class mobile-collapse at mobile
  if (window.innerWidth <= 959) {
    // width should mirror value from src/utils/utils.ts line 1314
    const mobileCollapsed = document.getElementsByClassName(
      'accordion mobile-collapsed'
    );

    for (let i = 0; i < mobileCollapsed.length; i++) {
      mobileCollapsed[i].classList.remove('open');
    }
  }
};

/**
 * @method manipulateTabs
 * Responsible for adding/removing "active" class to the left tab navigation and the "tab-content" pane.
 */
const manipulateTabs = (): void => {
  const tabs: HTMLCollectionOf<Element> =
    document.getElementsByClassName('tab');

  const selectTabs: NodeListOf<Element> =
    document.querySelectorAll('select.tabs');

  const tabContent: HTMLCollectionOf<Element> =
    document.getElementsByClassName('tab-content');

  const removeActiveTab = (index: number): void => {
    for (let i = 0; i < tabs.length; i++) {
      if (index !== i) {
        tabs[i].classList.remove('active');
      }
    }
  };

  const removeActiveTabContent = (): void => {
    for (let i = 0; i < tabContent.length; i++) {
      tabContent[i].classList.remove('active');
    }
  };

  const switchTabs = (tabId: string): void => {
    removeActiveTabContent();

    for (let i = 0; i < tabContent.length; i++) {
      const currentTab = tabContent[i].getAttribute('id');

      if (currentTab === tabId) {
        tabContent[i].classList.add('active');
      }
    }
  };

  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', function (e) {
      removeActiveTab(i);
      e.stopPropagation();

      tabs[i].classList.add('active');

      if (tabs[i].classList.contains('active')) {
        const currentTabId = tabs[i].getAttribute('data-target-id');

        if (currentTabId) {
          switchTabs(currentTabId);
        }
      }
    });
  }

  for (let i = 0; i < selectTabs.length; i++) {
    selectTabs[i].addEventListener('change', function (e) {
      e.stopPropagation();
      let target = (e.target as HTMLSelectElement).selectedIndex;
      let currentTabId = (e.target as HTMLSelectElement).options[target].dataset
        .targetId;

      if (currentTabId) {
        switchTabs(currentTabId);
      }
    });
  }
};

const manipulateModals = (): void => {
  const modalTriggers: HTMLCollectionOf<Element> =
    document.getElementsByClassName('modal-trigger');

  const modalContainers: HTMLCollectionOf<Element> =
    document.getElementsByClassName('modal-container');

  const setCloseListeners = (activeModalContainer: Element): void => {
    const backdrop: HTMLCollectionOf<Element> =
      document.getElementsByClassName('backdrop');

    const activeModalTrigger: HTMLCollectionOf<Element> =
      document.getElementsByClassName('modal-trigger active');

    activeModalContainer
      .getElementsByClassName('close_icon')[0]
      .addEventListener('click', function (e) {
        removeActiveModal(
          activeModalContainer,
          backdrop[0],
          activeModalTrigger[0]
        );
        e.stopPropagation();
      });

    backdrop[0].addEventListener('click', function (e) {
      removeActiveModal(
        activeModalContainer,
        backdrop[0],
        activeModalTrigger[0]
      );
      e.stopPropagation();
    });
  };

  const openModal = (modalId: string): void => {
    for (let i = 0; i < modalContainers.length; i++) {
      const modalContainerId = modalContainers[i].getAttribute('id');

      if (modalContainerId === modalId) {
        const activeModalContainer = modalContainers[i];

        activeModalContainer.classList.add('active');
        document.body.classList.add('backdrop-active');

        addCloseButton(activeModalContainer);
        setCloseListeners(activeModalContainer);
      }
    }
  };

  for (let i = 0; i < modalTriggers.length; i++) {
    modalTriggers[i].addEventListener('click', function () {
      modalTriggers[i].classList.add('active');

      if (modalTriggers[i].classList.contains('active')) {
        const modalId = modalTriggers[i].getAttribute('data-target-id');

        if (modalId) {
          openModal(modalId);
        }
      }
    });
  }
};

const addCloseButton = (activeModalContainer: Element): void => {
  if (!activeModalContainer.querySelector('.close_icon')) {
    let closeButton = document.createElement('button');
    closeButton.ariaLabel = 'close';
    closeButton.className = 'close_icon';
    activeModalContainer.prepend(closeButton);
  }
};

const removeActiveModal = (
  activeModalContainer: Element,
  backdrop: Element,
  activeModalTrigger: Element
): void => {
  document.body.classList.remove('backdrop-active');
  activeModalContainer.classList.remove('active');
  activeModalTrigger.classList.remove('active');

  activeModalContainer
    .getElementsByClassName('close_icon')[0]
    .removeEventListener('click', function () {});
  backdrop.removeEventListener('click', function () {});
};

const manipulateVideos = (): void => {
  const modalTriggers: HTMLCollectionOf<Element> =
    document.getElementsByClassName('video-trigger');

  const setCloseListeners = (activeModalContainer: Element): void => {
    const backdrop: HTMLCollectionOf<Element> =
      document.getElementsByClassName('backdrop');

    const activeModalTrigger: HTMLCollectionOf<Element> =
      document.getElementsByClassName('modal-trigger active');

    activeModalContainer
      .getElementsByClassName('close_icon')[0]
      .addEventListener('click', function (e) {
        removeActiveModal(
          activeModalContainer,
          backdrop[0],
          activeModalTrigger[0]
        );
        e.stopPropagation();
      });

    backdrop[0].addEventListener('click', function () {
      removeActiveModal(
        activeModalContainer,
        backdrop[0],
        activeModalTrigger[0]
      );
    });
  };

  const openModal = (videoUrl: string): void => {
    if (!document.getElementById('video-modal-container')) {
      let html = document.createElement('div');
      html.id = 'video-modal-container';
      html.className = 'modal-container';

      document.body.appendChild(html);
    }

    // @ts-ignore
    const activeModalContainer: HTMLElement = document.getElementById(
      'video-modal-container'
    );
    activeModalContainer.innerHTML = '';

    const iframe = document.createElement('iframe');
    iframe.src = videoUrl;
    iframe.title = 'Northern Tools video player';
    iframe.frameBorder = '0';
    iframe.allowFullscreen = true;
    iframe.allow = 'encrypted-media';
    iframe.width = '960';
    iframe.height = '540';

    activeModalContainer.appendChild(iframe);
    activeModalContainer.classList.add('active');
    document.body.classList.add('backdrop-active');

    addCloseButton(activeModalContainer);
    setCloseListeners(activeModalContainer);
  };

  for (let i = 0; i < modalTriggers.length; i++) {
    modalTriggers[i].addEventListener('click', function (e) {
      modalTriggers[i].classList.add('active');
      e.stopPropagation();

      if (modalTriggers[i].classList.contains('active')) {
        const videoUrl = modalTriggers[i].getAttribute('data-video-url');

        if (videoUrl) {
          openModal(videoUrl);
        }
      }
    });
  }
};

export {
  manipulateAccordion,
  manipulateTabs,
  manipulateModals,
  manipulateVideos,
};
