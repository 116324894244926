import { createAction } from '@reduxjs/toolkit';
import { ICustReviewObj } from '../../components/PDP/CustomerReview/customer-review-gallery';
import { IProductVideos } from '../../components/PDP/MediaModalVideos';

import * as ACTION_TYPES from '../action-types/mediaModal.action-types';

export const UPDATE_BRIGHTCOVE_VIDEOS_ACTION = createAction<[IProductVideos]>(
  ACTION_TYPES.UPDATE_BRIGHTCOVE_VIDEOS
);

export const UPDATE_PARENT_PRODUCT_VIDEO_ACTION = createAction<
  [IProductVideos]
>(ACTION_TYPES.UPDATE_PARENT_PRODUCT_VIDEOS);

export const RESET_BRIGHTCOVE_VIDEOS_ACTION = createAction(
  ACTION_TYPES.RESET_BRIGHTCOVE_VIDEOS
);

export const RESET_PARENT_PRODUCT_VIDEOS_ACTION = createAction(
  ACTION_TYPES.RESET_PARENT_PRODUCT_VIDEOS
);

export const UPDATE_BV_REVIEWS_ACTION = createAction<ICustReviewObj[]>(
  ACTION_TYPES.UPDATE_BV_REVIEWS
);

export const RESET_BV_REVIEWS_ACTION = createAction(
  ACTION_TYPES.RESET_BV_REVIEWS
);

export const RESET_MEDIA_MODAL_ACTION = createAction(
  ACTION_TYPES.RESET_MEDIA_MODAL
);
