import { createReducer, current } from '@reduxjs/toolkit';
import { ProductUtility } from '../../utils/product-utility';
import { checkTruthy } from '../../utils/utils';
import { IProductListContents } from '../../_foundation/interface/ProductList/IProductList';
import { IMerchandisingAssociations } from '../../_foundation/interface/Responses/ICartProductsResponse';
import {
  RESET_PRODUCT_DETAIL_ACTION,
  UPDATE_PRODUCT_DETAIL_ACTION,
} from '../actions/productDetail.action';

/**
 * @interface IProductDetailState
 */
export interface IProductDetailState {
  productDetails: IProductListContents;
  loading: boolean;
  isTempNotAvailable: boolean;
  isStoreOnly: boolean;
  isNoLongerAvailable: boolean;
  parentMerchandisingAssociations: IMerchandisingAssociations[];
}

const productDetails: IProductListContents = {
  attributes: [],
  auxDescription1: '',
  availabilityDate: new Date(),
  buyable: '',
  catalogEntryTypeCode: '',
  fullImageRaw: '',
  hasSingleSKU: '',
  id: '',
  longDescription: '',
  name: '',
  numberOfSKUs: 0,
  parentCatalogGroupID: '',
  partNumber: '',
  price: [],
  sKUs: [],
  seo: { href: '' },
  shortDescription: '',
  storeID: '',
  thumbnail: '',
  thumbnailRaw: '',
  type: '',
  uniqueID: '',
  UserData: [{ eOfferPrice: [] }],
  alt: '',
  fullImage: '',
  images: [],
  merchandisingAssociations: [],
};

const initProductDetailsState: IProductDetailState = {
  productDetails,
  loading: true,
  isTempNotAvailable: false,
  isStoreOnly: false,
  isNoLongerAvailable: false,
  parentMerchandisingAssociations: [],
};

const productDetailReducer = createReducer(
  initProductDetailsState,
  (builder) => {
    /**
     * @action UPDATE_PRODUCT_DETAIL_ACTION
     * Updates the currently viewed Product Details to the redux state.
     */
    builder.addCase(
      UPDATE_PRODUCT_DETAIL_ACTION,
      (state: IProductDetailState, action: any) => {
        if (action.payload) {
          const productDetails = action.payload as IProductListContents;

          const { parentMerchandisingAssociations } = current(state);

          let currentMerchandisingAssociations = [
            ...parentMerchandisingAssociations,
          ];

          if (
            productDetails.type.toLowerCase() === 'product' &&
            productDetails.merchandisingAssociations
          ) {
            currentMerchandisingAssociations = [
              ...productDetails.merchandisingAssociations,
            ];
          }

          const storeStockStatus =
            productDetails &&
            productDetails.attributes?.find(
              (attribute) => attribute.name === 'stock-status'
            )?.values[0]?.value;

          const isTempNotAvailable =
            (storeStockStatus === 'J' || storeStockStatus === 'Z') &&
            !checkTruthy(productDetails?.buyable);

          const isNoLongerAvailable =
            storeStockStatus === 'O' && !checkTruthy(productDetails?.buyable);

          const isStoreOnly = ProductUtility.isStoreOnly(productDetails);

          return {
            ...state,
            productDetails,
            loading: false,
            isTempNotAvailable,
            isNoLongerAvailable,
            isStoreOnly,
            parentMerchandisingAssociations: currentMerchandisingAssociations,
          };
        }
      }
    );

    /**
     * @action RESET_PRODUCT_DETAIL_ACTION
     * Updates the currently viewed Product Details to the initial redux state.
     */
    builder.addCase(
      RESET_PRODUCT_DETAIL_ACTION,
      (state: IProductDetailState) => {
        return initProductDetailsState;
      }
    );
  }
);

export { productDetailReducer };
