import { decode } from 'html-entities';
import React from 'react';
import { formateProductSpecAttributeValue } from '../../../utils/utils';
import { PRODUCT_ATTRIBUTE_CONSTANTS } from './ProductAttributeConstants';

interface Props {
  /**
   * Determines how may items to show in UI
   */
  noOfItems?: number;

  /**
   * Descriptive Items
   */
  items: Array<any>;
}

/**
 * Responsible to render the descriptive attribute of a product
 */
const ProductAttribute: React.FC<Props> = ({ noOfItems = 0, items }) => {
  /**
   *  Need to add logic for scenario 2
   */

  const { IDENTIFIER_VALUE_SEPARATOR } = PRODUCT_ATTRIBUTE_CONSTANTS;

  if (!noOfItems) {
    noOfItems = items.length;
  }

  return (
    <>
      {items.length > 0 && (
        <ul className='nte-ul-list product_attribute-list'>
          {items.map((e: any, index) => {
            if (index >= noOfItems) {
              return null;
            }
            return (
              <li key={`li_${e.identifier}`}>
                <span
                  key={`span_name_${e.identifier}`}
                  id={`product_desc_attribute_name_${e.identifier}`}>
                  {decode(e.name)}
                  {IDENTIFIER_VALUE_SEPARATOR}
                </span>
                {e.values.map((value: any) => (
                  <span
                    id={`product_attribute_value_${value.identifier}`}
                    key={value.identifier}>
                    {formateProductSpecAttributeValue(decode(value.value))}
                  </span>
                ))}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export { ProductAttribute };
