import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_NETWORK_ERROR_ACTION } from '../../redux/actions/network-errors.actions';
import { networkErrorSelector } from '../../redux/selectors/network-errors.selector';
import { useSignout } from '../../utils/hooks/sign-out/sign-out';
import { checkMembership, getLocalStorage } from '../../utils/utils';
import { PREV_USER_TYPE } from '../../_foundation/constants/cookie';
import { UserType } from '../../_foundation/enum/User/UserType';
import { useNteModal } from '../NteModal/hooks/NteModalHooks';
import { SignInModal } from '../Widgets/SignInModal/SignInModal';
import {
  SessionInvalidConstants,
  SESSION_INVALID_ERROR_CODES,
  SESSION_INVALID_ERROR_KEYS,
} from './SessionInvalidConstants';

/**
 * @component SessionInvalid renders the session invalid modal component.
 */
const SessionInvalid: React.FC = () => {
  const { SESSION_EXPIRATION_TITLE_MODAL, SIGN_IN_ALERT_MODAL } =
    SessionInvalidConstants;

  const { errorCode, errorKey } = useSelector(networkErrorSelector);

  const isInvalidSession =
    SESSION_INVALID_ERROR_CODES.includes(errorCode ? errorCode : '') ||
    SESSION_INVALID_ERROR_KEYS.includes(errorKey ? errorKey : '');

  const { RenderModal, nteModalClickHandler, showNteModal } = useNteModal({
    ariaLabelledById: 'session-invalid-dialog',
    id: 'session-invalid',
  });

  const dispatch = useDispatch();

  const { signout } = useSignout();

  /**
   * @callback initSessionInvalidModal Triggers session invalid modal.
   */
  const initSessionInvalidModal = useCallback((): void => {
    if (isInvalidSession) {
      nteModalClickHandler();
    }
  }, [isInvalidSession, nteModalClickHandler]);

  useEffect(() => {
    initSessionInvalidModal();
  }, [initSessionInvalidModal, isInvalidSession]);

  /**
   * @callback removeInvalidUser This removes the user from cookie and redux state
   * as soon as the user becomes an invalid user ex: 401 response.
   *
   * Changes have been made to the previous code where it was redirected to the homepage. Now it stays on the screen.
   */
  const removeInvalidUser = useCallback(
    (isInvalidSession: boolean): void => {
      if (isInvalidSession) {
        signout(true);
      }
    },
    [signout]
  );

  useEffect(() => {
    removeInvalidUser(isInvalidSession);
  }, [isInvalidSession, removeInvalidUser]);

  /**
   * @method onSessionModalDismiss resets the network error state
   * and deletes the user session cookie.
   */
  const onSessionModalDismiss = (): void => {
    if (isInvalidSession) {
      dispatch(RESET_NETWORK_ERROR_ACTION());
    }
  };

  /**
   * @method alertMessage is assigned based on the user type.
   */
  const alertMessage = (): string => {
    const userType = getLocalStorage(PREV_USER_TYPE) as UserType;

    const { isMembership } = checkMembership(userType);

    if (isMembership) {
      return SIGN_IN_ALERT_MODAL;
    }

    return '';
  };

  return (
    <>
      {showNteModal && (
        <RenderModal
          showModal={showNteModal}
          modalDismissHandler={onSessionModalDismiss}
          nteModalCloseHandler={nteModalClickHandler}>
          <SignInModal
            title={SESSION_EXPIRATION_TITLE_MODAL}
            signInCloseHandler={nteModalClickHandler}
            alertMessage={alertMessage()}
          />
        </RenderModal>
      )}
    </>
  );
};

export { SessionInvalid };
