import { createAction } from '@reduxjs/toolkit';
import {
  IOrderLookUp,
  IOrderLookUpRequest,
} from '../../_foundation/interface/Order/IOrderLookUp';
import * as ACTION_TYPES from '../action-types/orderLookUp.action-types';

export const FETCH_ORDER_LOOKUP_REQUEST_ACTION =
  createAction<IOrderLookUpRequest>(ACTION_TYPES.FETCH_ORDER_LOOKUP_REQUEST);

export const FETCH_ORDER_LOOKUP_SUCCESS_ACTION = createAction<IOrderLookUp>(
  ACTION_TYPES.FETCH_ORDER_LOOKUP_SUCCESS
);

export const RESET_FETCH_ORDER_LOOKUP_ACTION = createAction(
  ACTION_TYPES.RESET_FETCH_ORDER_LOOKUP
);
