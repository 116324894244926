import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { enterKeyPressHandler } from '../../../../utils/utils';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { useTextInput } from '../../../Forms/TextInput/hooks/TextInputHooks';
import { TextInput } from '../../../Forms/TextInput/TextInput';
import { useSaveToListMenu } from '../hooks/SaveToListMenuHooks';
import { SaveToListMenuConstants } from '../SaveToListMenuConstants';
import { saveToListSelector } from '../selector/save-to-list.selector';

/**
 * @interface ICreateListItem
 */
interface ICreateListItem {
  /**
   * @prop cancelHandler closes the form.
   */
  cancelHandler: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * @prop id of the current product from which the save to list is initiated.
   */
  productData: IProductListContents;
}

/**
 * @component CreateListItem renders the create list item form.
 *
 * @param ICreateListItem
 */
const CreateListItem: React.FC<ICreateListItem> = ({
  cancelHandler,
  productData,
}) => {
  const {
    ITEM_TEXT_FIELD_VALIDATION_MESSAGE,
    DEFAULT_TEXT_FIELD_VALUE,
    CANCEL,
  } = SaveToListMenuConstants;

  const { currentValue, onInputChange } = useTextInput();

  const [disableSave, setDisableSave] = useState<boolean>(true);

  const [validationText, setValidationText] = useState<string>('');

  const { saveToList } = useSaveToListMenu({ productData });

  const { listMenuItems } = useSelector(saveToListSelector);

  const saveHandler = (): void => {
    saveToList(currentValue.trim());

    cancelHandler(false);
  };

  const validateItemTextField = useCallback((): void => {
    
    const value = currentValue.trim();
    if (value) {
      const currentIndex = listMenuItems.findIndex(
        (listItem) => listItem.item.toLowerCase() === value.toLowerCase()
      );

      const isValueAlreadyAvailable = currentIndex !== -1;

      if (isValueAlreadyAvailable) {
        setDisableSave(isValueAlreadyAvailable);

        setValidationText(ITEM_TEXT_FIELD_VALIDATION_MESSAGE);
      } else {
        setValidationText(DEFAULT_TEXT_FIELD_VALUE);
      }
    } else {
      setValidationText(DEFAULT_TEXT_FIELD_VALUE);
    }
  }, [
    DEFAULT_TEXT_FIELD_VALUE,
    ITEM_TEXT_FIELD_VALIDATION_MESSAGE,
    currentValue,
    listMenuItems,
  ]);

  useEffect(() => {

    setDisableSave(!Boolean(currentValue && currentValue.trim()));

    validateItemTextField();
  }, [validateItemTextField, currentValue]);

  return (
    <>
      <TextInput
        id='add-list'
        label='List Name'
        name='listName'
        type='text'
        error={Boolean(validationText)}
        helperText={validationText}
        onChange={onInputChange}
        value={currentValue}
        className='field-margin'
        autoFocus
      />

      <div className='button-row'>
        <NteButton
          onClick={saveHandler}
          disabled={disableSave}
          variant='outlined'
          translationKey='Save'
          size='small'
          type='button'
        />

        <Typography
          tabIndex={0}
          onClick={() => cancelHandler(false)}
          onKeyDown={(e: any) =>
            enterKeyPressHandler(e, () => {
              cancelHandler(false);
            })
          }
          role='link'
          className='link-effect cursor-pointer'>
          {CANCEL}
        </Typography>
      </div>
    </>
  );
};

export { CreateListItem };
