import { createReducer } from '@reduxjs/toolkit';
import { ICustReviewObj } from '../../components/PDP/CustomerReview/customer-review-gallery';
import { IProductVideos } from '../../components/PDP/MediaModalVideos';
import { videoSorting } from '../../utils/utils';
import {
  RESET_BRIGHTCOVE_VIDEOS_ACTION,
  RESET_BV_REVIEWS_ACTION,
  RESET_MEDIA_MODAL_ACTION,
  RESET_PARENT_PRODUCT_VIDEOS_ACTION,
  UPDATE_BRIGHTCOVE_VIDEOS_ACTION,
  UPDATE_BV_REVIEWS_ACTION,
  UPDATE_PARENT_PRODUCT_VIDEO_ACTION,
} from '../actions/mediaModal.actions';

/** @interface for media modal videos and reviews*/
export interface IMediaModal {
  productVideos: IProductVideos[];
  productReviews: ICustReviewObj[];
  parentProductVideos: IProductVideos[];
}

const initMediaModalState: IMediaModal = {
  productVideos: [],
  productReviews: [],
  parentProductVideos: [],
};

/**
 * this is the reducer for storing BV reviews and BC videos from pdp pages.
 */
const mediaModalReducer = createReducer(initMediaModalState, (builder) => {
  /**
   * @action UPDATE_BRIGHTCOVE_VIDEOS_ACTION
   * responsible to update videos for products
   */
  builder.addCase(
    UPDATE_BRIGHTCOVE_VIDEOS_ACTION,
    (state: IMediaModal, action: any) => {
      const productThumbNail = videoSorting(action.payload);

      return {
        ...state,
        productVideos: productThumbNail?.length > 0 ? productThumbNail : [],
      };
    }
  );

  /**
   * @action UPDATE_PARENT_PRODUCT_VIDEO_ACTION
   * responsible to update videos for parent product
   */
  builder.addCase(
    UPDATE_PARENT_PRODUCT_VIDEO_ACTION,
    (state: IMediaModal, action: any) => {
      const productThumbNail = videoSorting(action.payload);

      return {
        ...state,
        parentProductVideos:
          productThumbNail?.length > 0 ? productThumbNail : [],
      };
    }
  );

  /**
   * @action UPDATE_BV_REVIEWS_ACTION
   * responsible to update product reviews for products
   */
  builder.addCase(
    UPDATE_BV_REVIEWS_ACTION,
    (state: IMediaModal, action: any) => {
      return { ...state, productReviews: action.payload };
    }
  );

  /**
   * @action RESET_BRIGHTCOVE_VIDEOS_ACTION
   * responsible to reset product videos
   */
  builder.addCase(RESET_BRIGHTCOVE_VIDEOS_ACTION, (state: IMediaModal) => {
    return { ...state, productVideos: [] };
  });

  /**
   * @action RESET_PARENT_PRODUCT_VIDEOS_ACTION
   * responsible to reset parent product videos
   */
  builder.addCase(RESET_PARENT_PRODUCT_VIDEOS_ACTION, (state: IMediaModal) => {
    return { ...state, parentProductVideos: [] };
  });

  /**
   * @action RESET_BV_REVIEWS_ACTION
   * responsible to reset reviews for product
   */
  builder.addCase(RESET_BV_REVIEWS_ACTION, (state: IMediaModal) => {
    return { ...state, productReviews: [] };
  });

  builder.addCase(RESET_MEDIA_MODAL_ACTION, (state: IMediaModal) => {
    return { ...initMediaModalState };
  });
});

export { mediaModalReducer };
