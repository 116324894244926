const translationKey = 'ERROR_CARD';

const ErrorConstants = {
  ERROR_TITLE: `${translationKey}.ERROR_TITLE`,
  CONTACT_US_FOR_HELP: `${translationKey}.CONTACT_US_FOR_HELP`,
  SALES: `${translationKey}.SALES`,
  SALES_NUMBER: `${translationKey}.SALES_NUMBER`,
  CUSTOMER_CARE: `${translationKey}.CUSTOMER_CARE`,
  CUSTOMER_CARE_NUMBER: `${translationKey}.CUSTOMER_CARE_NUMBER`,
  CHAT: `${translationKey}.CHAT`,
  EMAIL: `${translationKey}.EMAIL`,
  EMAIL_CONTACT_US: `${translationKey}.EMAIL_CONTACT_US`,
  TEALIUM_ERROR_PAGE_EVENT: {
    PAGE_NAME: 'error page',
    PAGE_TYPE: 'error',
    PAGE_SITE_SECTION: 'error page',
  },
  ERROR_TITLE_FALLBACK: "We're Sorry, An Error Occurred",
  CONTACT_US_FOR_HELP_FALLBACK: 'Need Help with Your Order?',
  SALES_FALLBACK: 'Sales',
  SALES_NUMBER_FALLBACK: '1-800-221-0516',
  CUSTOMER_CARE_FALLBACK: 'Customer Care',
  CUSTOMER_CARE_NUMBER_FALLBACK: '1-800-222-5381',
  CHAT_FALLBACK: 'Chat',
  EMAIL_FALLBACK: 'Email',
  EMAIL_CONTACT_US_FALLBACK: 'contact-us#email-us',
  ERROR_LOGIN_EXPIRED:
    'Your current password has expired. Please use the forgot password link to create a new password.',
  ERROR_LOGIN_INCORRECT: 'The specified logon ID or password are not correct. Verify the information and try again.',
};

export { ErrorConstants };
