import { takeLatest } from 'redux-saga/effects';
import {
  FETCH_BRAND_BY_IDENTIFIER_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_ACTION,
  FETCH_INVENTORY_INFO_ACTION,
  FETCH_PRIVATE_BRANDS_ACTION,
  FETCH_SUB_CATEGORIES_ACTION,
  GET_PRODUCT_LIST_ACTION,
  GET_PRODUCT_LIST_STORE_ACTION,
  SEARCH_DISPLAY_REQUEST_ACTION,
  SEARCH_PRODUCT_LIST_ACTION,
  UPDATE_FACETS_ACTION,
  UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION,
  UPDATE_SEARCH_PRODUCT_LIST_ACTION,
} from '../../actions/productList.actions';
import {
  filterProductList,
  filterProductListForShopStore,
  getBrandByIdentifier,
  getCategoryByIdentifier,
  getInventoryInfo,
  getPrivateBrands,
  getProductList,
  getProductListByKeyword,
  getProductListForStore,
  getSearchDisplayByKeyword,
  getSubCategories,
  updateProductListByKeyword,
} from '../workers/productList.workers';

export function* watchSaga() {
  yield takeLatest(GET_PRODUCT_LIST_ACTION, getProductList);

  yield takeLatest(GET_PRODUCT_LIST_STORE_ACTION, getProductListForStore);

  yield takeLatest(UPDATE_FACETS_ACTION, filterProductList);

  yield takeLatest(
    UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION,
    filterProductListForShopStore
  );

  yield takeLatest(FETCH_PRIVATE_BRANDS_ACTION, getPrivateBrands);

  yield takeLatest(SEARCH_PRODUCT_LIST_ACTION, getProductListByKeyword);

  yield takeLatest(
    UPDATE_SEARCH_PRODUCT_LIST_ACTION,
    updateProductListByKeyword
  );

  yield takeLatest(SEARCH_DISPLAY_REQUEST_ACTION, getSearchDisplayByKeyword);

  yield takeLatest(FETCH_INVENTORY_INFO_ACTION, getInventoryInfo);

  yield takeLatest(FETCH_SUB_CATEGORIES_ACTION, getSubCategories);

  yield takeLatest(
    FETCH_INVENTORY_BY_IDENTIFIER_ACTION,
    getCategoryByIdentifier
  );

  yield takeLatest(FETCH_BRAND_BY_IDENTIFIER_ACTION, getBrandByIdentifier);
}
