import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @interface IModalHeader
 */
interface IModalHeader {
  /**
   * @prop heading Modal heading text.
   */
  heading?: string;

  /**
   * @prop modalCloseHandler handles the modal close events.
   */
  modalCloseHandler?: () => void;

  /**
   * @prop id for Modal Header.
   */
  id: string;
}

/**
 * @component ModalHeader renders the header portion of the modal.
 *
 * @param IModalHeader
 */
const ModalHeader: React.FC<IModalHeader> = ({
  heading = '',
  id,
  modalCloseHandler,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {modalCloseHandler && (
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <IconButton
              className='cursor-pointer close_icon'
              aria-label='Close'
              onClick={modalCloseHandler}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {heading && (
        <Typography id={id} variant='h2'>
          {t(heading)}
        </Typography>
      )}
    </>
  );
};

export { ModalHeader };
