import { createReducer } from '@reduxjs/toolkit';
import { HIDE_BACKDROP, SHOW_BACKDROP } from '../action-types/backdrop';

export interface IBackdropState {
  showBackdrop: boolean;
}

const initBackdropState: IBackdropState = {
  showBackdrop: false,
};

const backdropReducer = createReducer(initBackdropState, (builder) => {
  builder.addCase(SHOW_BACKDROP, () => {
    return { showBackdrop: true };
  });

  builder.addCase(HIDE_BACKDROP, () => {
    return { ...initBackdropState };
  });
});

export { backdropReducer };
