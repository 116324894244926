const UsableShippingServiceConstant = {
  GET_USABLE_SHIPPING_INFO_DETAILS: (
    storeID: string,
    physicalStoreId: string,
    addressId?: string
  ) =>
    `/wcs/resources/store/${storeID}/cart/@self/usable_shipping_info?langId=-1&physicalStoreId=${physicalStoreId}${
      addressId ? `&addressId=${addressId}` : ''
    }`,
  GET_USABLE_SHIPPING_METHODS: (
    storeID: string,
    zipCode: string,
    orderID?: string,
    partNumbers?: string[],
    quantity?: string,
    partNumber?: string,
    physicalStoreId?: string,
    lineType?: string,
  ) =>
    `/wcs/resources/store/${storeID}/cart/@self/usable_shipping_mode/extended?zipCode=${zipCode}${
      orderID ? `&orderId=${orderID}` : ''
    }${quantity ? `&quantity=${quantity}` : ''}${
      partNumber ? `&partNumber=${partNumber}` : ''
    }${partNumbers ? `&partNumber=${partNumbers}` : ''}${
      physicalStoreId ? `&physicalStoreId=${physicalStoreId}` : ''
    }${
      lineType ? `&lineType=${lineType}` : ''
    }
    `,
};

export { UsableShippingServiceConstant };
