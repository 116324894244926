import { call, put } from 'redux-saga/effects';
import { PickupPersonService } from '../../../_foundation/apis/pickupPerson/pickupPerson.service';
import { CheckoutFlows } from '../../../components/Checkout/enum/Checkout';
import { DELIVERY_PAGE_API_LOADING } from '../../../components/Checkout/redux/actions/checkout.actions';
import {
  ADD_PICKUP_PERSON_SUCCESS_ACTION,
  DELETE_PICKUP_PERSON_SUCCESS_ACTION,
  GET_PICKUP_PERSON_ACTION,
  GET_PICKUP_PERSON_CONFIRMATION_SUCCESS_ACTION,
  GET_PICKUP_PERSON_REVIEW_SUCCESS_ACTION,
  GET_PICKUP_PERSON_SUCCESS_ACTION,
  UPDATE_PICKUP_PERSON_SUCCESS_ACTION,
} from '../../actions/pickupPerson.actions';

export function* getPickupPersonSaga(action: any): Generator {
  try {
    const response: any = yield call(
      new PickupPersonService().getPickupPerson,
      action.payload
    );

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(GET_PICKUP_PERSON_SUCCESS_ACTION(response));
    } else if (action.payload.checkoutFlow === CheckoutFlows.Review) {
      yield put(GET_PICKUP_PERSON_REVIEW_SUCCESS_ACTION(response));
    } else {
      yield put(GET_PICKUP_PERSON_CONFIRMATION_SUCCESS_ACTION(response));
    }
  } catch (error) {
    throw error;
  }
}

export function* addPickupPersonSaga(action: any): Generator {
  try {
    const response: any = yield call(
      new PickupPersonService().addPickupPerson,
      action.payload
    );

    if (response) {
      yield put(DELIVERY_PAGE_API_LOADING(false));
    }

    yield put(
      GET_PICKUP_PERSON_ACTION({
        orderId: action.payload.orderId,
        storeID: action.payload.storeId,
      })
    );

    yield put(
      ADD_PICKUP_PERSON_SUCCESS_ACTION({
        email1: action.payload.email1,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        nickName: action.payload.nickName,
        orderId: action.payload.orderId,
        phone1: action.payload.phone1,
        storeId: action.payload.storeId,
      })
    );
  } catch (error) {
    yield put(DELIVERY_PAGE_API_LOADING(false));
    throw error;
  }
}

export function* deletePickupPersonSaga(action: any): Generator {
  try {
    yield call(new PickupPersonService().deletePickupPerson, action.payload);

    yield put(
      GET_PICKUP_PERSON_ACTION({
        orderId: action.payload.orderId,
        storeID: action.payload.storeId,
      })
    );

    yield put(
      DELETE_PICKUP_PERSON_SUCCESS_ACTION({
        orderId: action.payload.orderId,
        storeId: action.payload.storeId,
        nickName: action.payload.nickName,
      })
    );
  } catch (error) {
    throw error;
  }
}

export function* updatePickupPersonSaga(action: any): Generator {
  try {
    yield call(new PickupPersonService().updatePickupPerson, action.payload);

    yield put(
      GET_PICKUP_PERSON_ACTION({
        orderId: action.payload.orderId,
        storeID: action.payload.storeId,
      })
    );

    yield put(
      UPDATE_PICKUP_PERSON_SUCCESS_ACTION({
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        nickName: action.payload.nickName,
        email1: action.payload.email1,
        phone1: action.payload.phone1,
        storeId: action.payload.storeId,
        orderId: action.payload.orderId,
      })
    );
  } catch (error) {
    throw error;
  }
}
