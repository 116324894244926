import { RootReducerState } from '../reducers';
import { IProductDetailState } from '../reducers/productDetail.reducer';

const productDetailSelector = (
  state: RootReducerState
): IProductDetailState => {
  return state.pdpData;
};

export { productDetailSelector };
