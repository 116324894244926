import { IFsdData } from '../_foundation/interface/StoreLocator/IStoreLocator';

import { formattedDateRange } from '../utils/utils';
import { IUsableShippingMode } from '../_foundation/interface/Shipping/IUsableShipping';

/**
 * @global ShippingUtility handles all utility methods for the store index static page.
 */
const ShippingUtility = {
  /**
   * @method formatIOMStoreId Updates Shipping Drawer format based on iomStoreId logic
   *
   * @param iomStoreId of the product
   * @param carrierName of the product
   * @param deliveryEstimate of the product for shipping
   * @param includeCarrier whether or not the name of the carrier should be included
   * @returns estimatedDelivery which is either a single date or date range based on if iomStoreId is present and if includeCarrier is true
   * @returns resultCarrier which will either be null or include the carrier
   */
  formatIOMStoreId(
    iomStoreId: string | undefined,
    carrierName: string | undefined,
    deliveryEstimate: IFsdData | undefined,
    includeCarrier: boolean
  ): { estimatedDelivery: string; resultCarrier: string } {
    let estimatedDelivery;
    if (
      deliveryEstimate?.expDeliveryLow === deliveryEstimate?.expDeliveryHigh
    ) {
      estimatedDelivery = deliveryEstimate
        ? formattedDateRange(deliveryEstimate).split('-')[0].trim()
        : '';
    } else {
      estimatedDelivery = deliveryEstimate
        ? formattedDateRange(deliveryEstimate)
        : '';
    }

    let resultCarrier;
    if (
      iomStoreId &&
      deliveryEstimate?.expDeliveryLow === deliveryEstimate?.expDeliveryHigh &&
      !includeCarrier
    ) {
      resultCarrier = '';
    } else {
      resultCarrier = carrierName ? carrierName : '';
    }

    return { estimatedDelivery, resultCarrier };
  },
};

/**
 * @method sortShippingMethods sorts the array based on frieght charges
 *  so that element with lowest freight charge
 *
 * @param shippingMethods this is the list of shippigMethods that needs to be sorted
 * @returns sortedShippingMethod which is a array sorted in ascending order based on
 * frieghtCharge of the shipping Method
 */

const sortShippingMethods = (shippingMethods: IUsableShippingMode[]) => {
  const sortedShippingMethod = shippingMethods
    .slice()
    .sort((a: IUsableShippingMode, b: IUsableShippingMode) =>
      Number(a.freightCharge) > Number(b.freightCharge) ? 1 : -1
    );

  return sortedShippingMethod;
};

export { ShippingUtility, sortShippingMethods };
