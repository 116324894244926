import { createReducer, current } from '@reduxjs/toolkit';
import { defaultLayouts } from '../../../../configs/default-layout/default-layout';
import { hasKey } from '../../../../utils/utils';
import { ProductPageType } from '../../../../_foundation/enum/ProductPage/ProductPage';
import {
  ISeoComponent,
  ISeoConfigPayload,
  ISeoResponse,
  ISeoState,
} from '../../../../_foundation/interface/Seo/ISeo';
import ProductListPage from '../../../Pages/PlpPage/PlpPage';
import {
  GET_SEO_CONFIG_SUCCESS_ACTION,
  NAVIGATE_TO_PLP_ACTION,
  REFRESH_SEO_ACTION,
} from '../actions/seo';

const initSeoState: ISeoState = {};

/**
 * @method getSeoConfigFromPayload Gets the seo config from the
 * payload and assigns a component based on the seo payload.
 */
const getSeoConfigFromPayload = ({
  identifier,
  response,
}: ISeoConfigPayload): ISeoState => {
  let seoConfig: ISeoComponent;

  if (response.status === 404 || !response.data) {
    //  Add Implementation for 404 component.
  }

  const { contents }: ISeoResponse = response.data;

  const seoContents = contents[0];

  seoConfig = {
    ...response.data.contents[0],
    page: {
      ...response.data.contents[0].page,
      tokenValue: response.data.contents[0].tokenValue,
      externalContext: {
        identifier: response.data.contents[0].tokenExternalValue,
      },
    },
  };

  const containerName = seoConfig?.layout?.name ? seoConfig?.layout?.name : '';

  const pageType = seoConfig?.page?.type;

  /**
   * Fallback layouts will be applied based on the container name incase if the layout object
   * or the slots array inside the layout object is missing.
   */
  if (
    (!seoContents?.layout || !seoConfig?.layout?.slots) &&
    hasKey(defaultLayouts, containerName)
  ) {
    seoConfig = {
      ...seoConfig,
      layout: defaultLayouts[containerName],
    };
  }

  const isPdpPage = Object.values(ProductPageType).includes(
    pageType as ProductPageType
  );

  /**
   * If the current page type is PDP page and the pdp page doesn't have layout
   * object or slots inside the layout object, append the default layout for the pdp page.
   */
  if (
    isPdpPage &&
    hasKey(defaultLayouts, pageType) &&
    (!seoContents?.layout || !seoConfig?.layout?.slots)
  ) {
    seoConfig = {
      ...seoConfig,
      layout: defaultLayouts[pageType],
    };
  }

  return { [identifier]: seoConfig };
};

const seoReducer = createReducer(initSeoState, (builder) => {
  /**
   * GET_SEO_CONFIG_SUCCESS_ACTION
   * Fetches the config meta data for the given category identifier.
   */
  builder.addCase(
    GET_SEO_CONFIG_SUCCESS_ACTION,
    (state: ISeoState, action: any) => {
      const { identifier, response } = action.payload;

      const seoConfig: ISeoState = getSeoConfigFromPayload({
        identifier,
        response,
      });

      return { ...state, ...seoConfig };
    }
  );

  /**
   * NAVIGATE_TO_PLP_ACTION
   * Navigates to the current category's Plp page.
   */
  builder.addCase(NAVIGATE_TO_PLP_ACTION, (state: ISeoState, action: any) => {
    let updatedState: ISeoState = current(state);

    const currentCategory = action.payload.currentCategory;

    let seoObjects = { ...updatedState };

    const currentObject = seoObjects[currentCategory];

    seoObjects = {
      ...seoObjects,
      [currentCategory]: {
        ...currentObject,
        component: ProductListPage,
      },
    };

    updatedState = {
      ...seoObjects,
    };

    return { ...updatedState };
  });

  /**
   * CASE REFRESH_SEO_ACTION
   * Resets the seo object.
   */
  builder.addCase(REFRESH_SEO_ACTION, () => {
    return { ...initSeoState };
  });
});

export { seoReducer };
