import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { categoryService } from '../../../_foundation/apis/search/category/category.service';
import { GET_ALL_CATEGORIES_SUCCESS_ACTION } from '../../actions/categories.actions';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../actions/network-errors.actions';

export function* getAllCategories(action: any): Generator {
  try {
    const response = yield call(
      categoryService.fetchAllCategories,
      action.payload
    );

    yield put(GET_ALL_CATEGORIES_SUCCESS_ACTION(response));
  } catch (e) {
    const { response }: AxiosError = e as AxiosError;

    yield put(
      REDIRECT_TO_ERROR_PAGE_ACTION({
        errorCode: response?.status.toString(),
        errorKey: response?.config.url,
        errorMessage: response?.data,
        redirectToErrorPage: true,
      })
    );
  }
}
