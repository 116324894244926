import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @interface IIconText
 */
interface IIconText {
  /**
   * @prop clickHandler Handles the click events.
   */
  clickHandler?: (value?: any) => void;

  /**
   * @prop icon renders the icon next to text.
   */
  icon?: JSX.Element;

  /**
   * @prop linkEffect adds a link effect.
   */
  linkEffect?: boolean;

  /**
   * @prop translationKey label translation key.
   */
  translationKey?: string;

  /**
   * @prop text
   */
  text?: string;

  /**
   * @prop labelElement JSX Element that can be rendered as a label.
   */
  labelElement?: JSX.Element;

  /**
   * @prop Optional class name(s).
   */
  className?: string;

  /**
   * @prop to disable the button
   */
  disable?: boolean;
}

/**
 * @component IconText renders a typography element with an icon.
 * It gives the flexibility for icon placement with respect to the typography element.
 *
 * @param IIconText
 */
const IconText: React.FC<IIconText> = ({
  clickHandler,
  icon = <></>,
  linkEffect = false,
  translationKey = '',
  className = '',
  labelElement,
  disable = false,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      className={`icon_text plain-icon-button ${className} ${
        linkEffect ? '' : 'no-link-effect'
      }`}
      onClick={clickHandler}
      startIcon={icon}
      disabled={disable}
      tabIndex={0}
      role={'button'}>
      {text
        ? text
        : translationKey
        ? t(translationKey)
        : labelElement
        ? labelElement
        : ''}
    </Button>
  );
};

export { IconText };
