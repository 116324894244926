import { createReducer } from '@reduxjs/toolkit';
import { Country } from '../../_foundation/apis/search/location/Ilocation';
import {
  UPDATE_BILLING_COUNTRY_LIST_ACTION,
  UPDATE_SHIPPING_COUNTRY_LIST_ACTION,
} from '../actions/countryList.action';

export interface ICountryListState {
  billingCountries: Array<Country>;
  shippingCountries: Array<Country>;
}

const initCountryListState: ICountryListState = {
  billingCountries: [],
  shippingCountries: [],
};

const countryListReducer = createReducer(initCountryListState, (builder) => {
  builder.addCase(
    UPDATE_BILLING_COUNTRY_LIST_ACTION,
    (state: ICountryListState, action: any) => {
      return { ...state, billingCountries: action.payload.countries };
    }
  );

  builder.addCase(
    UPDATE_SHIPPING_COUNTRY_LIST_ACTION,
    (state: ICountryListState, action: any) => {
      return { ...state, shippingCountries: action.payload.countries };
    }
  );
});

export { countryListReducer };
