import { createReducer, current } from '@reduxjs/toolkit';
import {
  INteAlertMessage,
  INteAlertState,
} from '../../../_foundation/interface/NteAlert/INteAlert';
import {
  CLOSE_NTE_ALERT_ACTION,
  SHOW_NTE_ALERT_ACTION,
} from '../actions/nte-alert.actions';

const initNteAlertState: INteAlertState = {
  currentMessages: [],
};

const nteAlertReducers = createReducer(initNteAlertState, (builder) => {
  /**
   * @action SHOW_NTE_ALERT_ACTION
   * Queues the alert message with it's severity and messageId.
   */
  builder.addCase(
    SHOW_NTE_ALERT_ACTION,
    (state: INteAlertState, action: any) => {
      const { message, messageId, severity } = action.payload;

      if (message) {
        const newMessage: INteAlertMessage = {
          message,
          messageId,
          severity,
        };
        return {
          ...state,
          currentMessages: [newMessage],
        };
      }
    }
  );

  /**
   * @action CLOSE_NTE_ALERT_ACTION
   * Removes the alert message from the queue based on the messageId.
   */
  builder.addCase(
    CLOSE_NTE_ALERT_ACTION,
    (state: INteAlertState, action: any) => {
      const { id } = action.payload;

      if (id) {
        const currentState = current(state);

        const currentMessages = [...currentState.currentMessages];

        const newMessages = currentMessages.filter(
          (currentMessage) => currentMessage.messageId !== id
        );

        return {
          ...state,
          currentMessages: newMessages,
        };
      } else {
        return {
          ...initNteAlertState,
        };
      }
    }
  );
});

export { nteAlertReducers };
