import {
  IProductListState,
  ISearchDisplayState,
} from '../../_foundation/interface/ProductList/IProductList';
import { RootReducerState } from '../reducers';

const productListSelector = (state: RootReducerState): IProductListState => {
  return state.productList;
};

const searchDisplaySelector = (
  state: RootReducerState
): ISearchDisplayState => {
  return state.searchDisplay;
};

export { productListSelector, searchDisplaySelector };
