import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  INIT_AUTHENTICATION_KEY_ACTION,
  TOKENIZE_CREDIT_DEBIT_CARD_ACTION,
} from '../../actions/payment.action';
import {
  fetchAuthenticationKey,
  tokenizeCreditOrDebitCard,
} from '../workers/payment.workers';

export function* watchSaga() {
  yield takeLatest(INIT_AUTHENTICATION_KEY_ACTION, fetchAuthenticationKey);

  yield takeEvery(TOKENIZE_CREDIT_DEBIT_CARD_ACTION, tokenizeCreditOrDebitCard);
}
