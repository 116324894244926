import React, { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router';
import { IRoute, routes } from '../../configs/routes';
import { pageComposerMap, PAGE_TYPE } from '../../constants/PageComposerRoutes';
import { CATEGORY_INDEX, V8_CATEGORY_REDIRECT } from '../../constants/routes';
import { RESET_CURRENT_CATEGORY_ID_ACTION } from '../../redux/actions/categories.actions';
import { RESET_SEARCH_DISPLAY_ACTION } from '../../redux/actions/productList.actions';
import { authenticationSelector } from '../../redux/selectors/auth.selector';
import { useRefresh } from '../../utils/hooks/refresh/use-refresh';
import { PageSkeleton } from '../Skeleton/PageSkeleton/PageSkeleton';
import { resetHeightValues } from '../TealiumUtag/hooks/ScrollListener';
import { useUtagAnalytics } from '../TealiumUtag/hooks/useUtagAnalytics';

/**
 * @component NteRouter renders component based on their specific route.
 */
const NteRouter: React.FC = () => {
  const { pushPageViewForUtag } = useUtagAnalytics();

  const history = useHistory();

  const dispatch = useDispatch();

  useRefresh();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const initCurrentCategoryId = useCallback((): void => {
    dispatch(RESET_CURRENT_CATEGORY_ID_ACTION());
  }, [dispatch]);

  useEffect(() => {
    initCurrentCategoryId();
  }, [initCurrentCategoryId, history.location.pathname]);

  const updateYottaPageTransition = () => {
    if (window.Yo) {
      window.Yo.pubsub.publish({
        topic: 'rum/spa/transition',
      });
    }
  };

  /**
   * Sends the Page View Data to Tealium analytics udata Layer.
   */
  const updateTealiumUtagPageTransition = useCallback(
    (route: IRoute) => {
      let utagPageType, utagPageName;
      if (!route.utagPageName) {
        const pageName = pageComposerMap.get(window.location.pathname);
        if (pageName) {
          utagPageType = PAGE_TYPE;
          utagPageName = pageName;
        }
      } else {
        utagPageType = route.utagPageType;
        utagPageName = route.utagPageName;
      }

      if (utagPageType && utagPageName) {
        pushPageViewForUtag({
          pageType: utagPageType,
          pageName: utagPageName,
          pageSiteSection: utagPageName,
        });
      }
    },
    [pushPageViewForUtag]
  );

  /**
   * Any logic that needs to be executed upon page transition can be plugged in here.
   */
  const pageTransition = (route: IRoute) => {
    /* Yotta page Transition needs to be updated upon route initialization. */
    updateYottaPageTransition();

    /* Tealium Udata Analytics Integration to send Page view information. */
    if (route.path.includes('search')) {
      if (Boolean(document.getElementById('product-results-feedback'))) {
        updateTealiumUtagPageTransition(route);
      }
    } else {
      updateTealiumUtagPageTransition(route);
    }

    // If the user is offline, reload the page so the service worker displays the offline page.
    if (!window.navigator.onLine) {
      window.location.reload();
    }
    resetHeightValues();
  };

  /**
   * @callback redirectUserToPreviousPath Redirects the user to the previous
   * authenticated path when they sign in after getting a session timeout.
   */
  const redirectUserToPreviousPath = useCallback((): void => {
    if (history.location.state && isUserAuthenticated) {
      const { pathname }: any = history.location.state;

      history.push(pathname);
    }
  }, [history, isUserAuthenticated]);

  useEffect(() => {
    redirectUserToPreviousPath();
  }, [redirectUserToPreviousPath]);

  useEffect(() => {
    dispatch(RESET_SEARCH_DISPLAY_ACTION());
  }, [history.location.pathname, history, dispatch]);

  /**
   * @callback redirectToAllCategories Redirects the user to the all categories page,
   * if the current url pathname has shop/tools
   */
  const redirectToAllCategories = useCallback((): void => {
    if (history.location.pathname.includes(V8_CATEGORY_REDIRECT)) {
      history.push(CATEGORY_INDEX);
    }
  }, [history]);

  useEffect(() => {
    redirectToAllCategories();
  }, [redirectToAllCategories]);

  return (
    <Suspense fallback={<PageSkeleton />}>
      <Switch>
        {routes.map((route: IRoute, index: number) => (
          <Route
            key={index}
            exact
            path={route.path}
            render={(props: RouteComponentProps) => {
              if (route.component) {
                const { component: Component } = route;

                pageTransition(route);

                return <Component {...props} />;
              }
            }}
          />
        ))}
        <Route exact render={() => <Redirect to='/*' />} />
      </Switch>
    </Suspense>
  );
};

export { NteRouter };
