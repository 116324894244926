
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IProductsByPartNumbersResponse from './IProductsByPartNumbersResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IProductsByPartNumbersResponse };

  export const IProductsByPartNumbersResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IAttributesItem": {
            "properties": {
                "comparable": {
                    "type": "string"
                },
                "displayable": {
                    "type": "string"
                },
                "facetable": {
                    "type": "string"
                },
                "id": {
                    "type": "string"
                },
                "identifier": {
                    "type": "string"
                },
                "merchandisable": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "searchable": {
                    "type": "string"
                },
                "sequence": {
                    "type": "string"
                },
                "storeDisplay": {
                    "type": "string"
                },
                "swatchable": {
                    "type": "string"
                },
                "usage": {
                    "type": "string"
                },
                "values": {
                    "items": {
                        "$ref": "#/definitions/IValuesItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "identifier",
                "name",
                "values"
            ],
            "type": "object"
        },
        "IContentsItem": {
            "properties": {
                "UserData": {
                    "items": {
                        "$ref": "#/definitions/IUserDataItem"
                    },
                    "type": "array"
                },
                "_ignored": {
                    "type": "string"
                },
                "attributes": {
                    "items": {
                        "$ref": "#/definitions/IAttributesItem"
                    },
                    "type": "array"
                },
                "availability": {
                    "type": "string"
                },
                "backorderDate": {
                    "type": "string"
                },
                "buyable": {
                    "type": "string"
                },
                "displayable": {
                    "type": "string"
                },
                "fullImage": {
                    "type": "string"
                },
                "fullImageRaw": {
                    "type": "string"
                },
                "hasSingleSKU": {
                    "type": "boolean"
                },
                "id": {
                    "type": "string"
                },
                "images": {
                    "items": {
                        "$ref": "#/definitions/IImagesItem"
                    },
                    "type": "array"
                },
                "manufacturer": {
                    "type": "string"
                },
                "merchandisingAssociations": {
                    "items": {
                        "$ref": "#/definitions/IMerchAssociations"
                    },
                    "type": "array"
                },
                "name": {
                    "type": "string"
                },
                "parentCatalogEntryID": {
                    "type": "string"
                },
                "parentCatalogGroupID": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "partNumber": {
                    "type": "string"
                },
                "price": {
                    "items": {
                        "$ref": "#/definitions/IPriceItem"
                    },
                    "type": "array"
                },
                "resourceId": {
                    "type": "string"
                },
                "salesPriceRestricted": {
                    "type": "string"
                },
                "seo": {
                    "$ref": "#/definitions/ISeo"
                },
                "shortDescription": {
                    "type": "string"
                },
                "storeID": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                },
                "thumbnailRaw": {
                    "type": "string"
                },
                "type": {
                    "type": "string"
                },
                "url": {
                    "type": "string"
                }
            },
            "required": [
                "id",
                "name",
                "partNumber",
                "price"
            ],
            "type": "object"
        },
        "IEOfferPriceItem": {
            "properties": {
                "maximumQuantity": {
                    "type": "string"
                },
                "minimumQuantity": {
                    "type": "string"
                },
                "offerPrice": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IImagesItem": {
            "properties": {
                "fullImage": {
                    "type": "string"
                },
                "fullImageRaw": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "sequence": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                },
                "thumbnailRaw": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IMerchAssociations": {
            "properties": {
                "UserData": {
                    "items": [
                        {
                            "properties": {
                                "eOfferPrice": {
                                    "items": {
                                        "$ref": "#/definitions/IEOfferPriceItem"
                                    },
                                    "type": "array"
                                }
                            },
                            "required": [
                                "eOfferPrice"
                            ],
                            "type": "object"
                        }
                    ],
                    "maxItems": 1,
                    "minItems": 1,
                    "type": "array"
                },
                "associationType": {
                    "type": "string"
                },
                "attributes": {
                    "items": {
                        "$ref": "#/definitions/IAttributesItem"
                    },
                    "type": "array"
                },
                "buyable": {
                    "type": "string"
                },
                "extendedBaseOffer": {
                    "type": "string"
                },
                "extendedOffer": {
                    "type": "string"
                },
                "hasSingleSKU": {
                    "type": "boolean"
                },
                "id": {
                    "type": "string"
                },
                "items": {
                    "items": {
                        "$ref": "#/definitions/IContentsItem"
                    },
                    "type": "array"
                },
                "manufacturer": {
                    "type": "string"
                },
                "name": {
                    "type": "string"
                },
                "parentCatalogGroupID": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "partNumber": {
                    "type": "string"
                },
                "price": {
                    "items": {
                        "$ref": "#/definitions/IPriceItem"
                    },
                    "type": "array"
                },
                "quantity": {
                    "type": "string"
                },
                "resourceId": {
                    "type": "string"
                },
                "salesPriceRestricted": {
                    "type": "string"
                },
                "seo": {
                    "$ref": "#/definitions/ISeo"
                },
                "shortDescription": {
                    "type": "string"
                },
                "storeID": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                },
                "thumbnailRaw": {
                    "type": "string"
                },
                "type": {
                    "type": "string"
                }
            },
            "required": [
                "associationType",
                "id",
                "name",
                "partNumber",
                "price",
                "quantity"
            ],
            "type": "object"
        },
        "IMetaData": {
            "properties": {
                "price": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IPriceItem": {
            "properties": {
                "contractId": {
                    "type": "string"
                },
                "currency": {
                    "type": "string"
                },
                "description": {
                    "type": "string"
                },
                "usage": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "usage",
                "value"
            ],
            "type": "object"
        },
        "ISeo": {
            "properties": {
                "href": {
                    "type": "string"
                }
            },
            "required": [
                "href"
            ],
            "type": "object"
        },
        "IUserDataItem": {
            "properties": {
                "eOfferPrice": {
                    "items": {
                        "$ref": "#/definitions/IEOfferPriceItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "eOfferPrice"
            ],
            "type": "object"
        },
        "IValuesItem": {
            "properties": {
                "id": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "identifier": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "sequence": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "unitID": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                },
                "unitOfMeasure": {
                    "type": "string"
                },
                "value": {
                    "anyOf": [
                        {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        {
                            "type": "string"
                        }
                    ]
                }
            },
            "required": [
                "id",
                "identifier",
                "value"
            ],
            "type": "object"
        }
    },
    "properties": {
        "contents": {
            "items": {
                "$ref": "#/definitions/IContentsItem"
            },
            "type": "array"
        },
        "metaData": {
            "$ref": "#/definitions/IMetaData"
        },
        "total": {
            "type": "number"
        }
    },
    "type": "object"
}

;

  export const isIProductsByPartNumbersResponse = ajv.compile(IProductsByPartNumbersResponseSchema) as ValidateFunction<
    IProductsByPartNumbersResponse
  >;

  export default function validateIProductsByPartNumbersResponse(value: unknown): IProductsByPartNumbersResponse {
    if (isIProductsByPartNumbersResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIProductsByPartNumbersResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IProductsByPartNumbersResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIProductsByPartNumbersResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IProductsByPartNumbersResponse' },
        ) +
          inspect(value)
      );
    }
  }