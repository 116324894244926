const NearByStoreTileConstants = {
  MILE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.MILE',
  MAKE_THIS_MY_STORE:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.MAKE_THIS_MY_STORE',
  AVAILABLE_WITHIN_1HR:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.AVAILABLE_WITHIN_1HR',
  SELECT_FOR_PICKUP:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.SELECT_FOR_PICKUP',
  MY_STORE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.MY_STORE',
  NOT_AVAILABLE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.NOT_AVAILABLE',
  IN_STOCK: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.IN_STOCK',
  CALL_STORE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.CALL_STORE',
  STORE_DETAILS: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.STORE_DETAILS',
  PLAIN: 'plain',
  STORE_HOURS: 'STOREHOURS',
  AVAILABLE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.AVAILABLE',
  X_URL_ERROR:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.ERROR_MESSAGE.X_URL_UNAVAILABLE',
  ATTRIBUTES: {
    LOCAL_DELIVERY: 'localDelivery',
    BOPIS_AVAILABILITY: 'bopisAvailability',
    STORE_WEB_STATUS: 'storeWebStatus',
    GENERAL_ANNOUNCEMENTS: 'nteGeneralAnnouncements',
  },
  DELIVERY_AVAILABLE: 'TRUE',
  TEMPORARILY_CLOSED: 'Temporarily Closed',
  PERMANENTLY_CLOSED: 'Permanently Closed',
  COMING_SOON: 'Coming Soon',
  D365_ATTRIBUTE: 'operatingInD365',
  D365_MESSAGE:
    'Buy online pickup in store same day is not available for this location.',
};

export { NearByStoreTileConstants };
