import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveFacet } from '../ActiveFacet/ActiveFacet';
import { Facet } from '../Facet/Facet';
import { useFacets } from '../Facet/hooks/FacetsHooks';
import { FacetMobileConstants } from '../FacetMobile/FacetMobileConstants';
import { FacetDesktopConstants } from './FacetDesktopConstants';

/**
 * @component FacetDesktop renders the desktop version of the facet component.
 */
const FacetDesktop: React.FC = () => {
  const { ACTIVE_FILTERS } = FacetDesktopConstants;

  const { FILTERS_TITLE } = FacetMobileConstants;

  const { facetValues } = useFacets();

  const { t } = useTranslation();

  return (
    <>
      <Typography variant='h3' className='nte-card-title'>
        {t(FILTERS_TITLE)}
      </Typography>

      <ActiveFacet title={ACTIVE_FILTERS} />

      <Facet facetValues={facetValues} />
    </>
  );
};

export { FacetDesktop };
