import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { ADVANTAGE } from '../../../_foundation/constants/category';
import { UserType } from '../../../_foundation/enum/User/UserType';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { productListSelector } from '../../../redux/selectors/productList.selector';
import { useCurrentPathName } from '../../../utils/hooks/current-pathname/CurrentPathName';
import {
  formatQueryParams,
  formatToTitleCase,
  removeQueryParamBackslash,
} from '../../../utils/utils';
import { seoSelector } from '../../Seo/redux/selector/seo';
import { FacetConstants } from '../../Widgets/Facets/Facet/FacetConstants';
import { breadcrumbSelector } from '../../../redux/selectors/breadcrumb.selector';
import { useFacets } from '../../Widgets/Facets/Facet/hooks/FacetsHooks';
import { useLeafPage } from '../../Widgets/Facets/Facet/hooks/LeafPageHook';
import { useBrandParams } from '../../../utils/hooks/brand-params/brand-params';

/**
 * @component PlpMetaTags adds the meta tags for the plp pages.
 */
const PlpMetaTags: React.FC = () => {
  const { FACET_QUERY_KEY, BRAND_FACET_KEY, CATEGORY_QUERY_KEY } =
    FacetConstants;

  const { currentPathName } = useCurrentPathName();

  const { pathname, search } = useLocation();

  const { userType } = useSelector(authenticationSelector);

  const { showMetaTags, selectedFacets, subCategory } =
    useSelector(productListSelector);

  const { facetValues } = useFacets();
  
  const { displayableFacet } = useLeafPage(facetValues);

  const { brands: brandNames } = useBrandParams({ seperator: '+' });

  const seoConfig = useSelector(seoSelector);

  const { breadCrumbTrailEntryView, loading: breadcrumbLoading } =
    useSelector(breadcrumbSelector);

  const [encodedFacetsParams] = useQueryParam(FACET_QUERY_KEY, StringParam);

  const facetsParams = encodedFacetsParams
    ? removeQueryParamBackslash(encodedFacetsParams)
    : '';

  const [categoryQueryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const isBrandPlp = seoConfig && seoConfig[formattedPath]?.isBrandPlp;

  const canonicalUrl = useMemo(() => {

    const displayableFacetBrands = displayableFacet?.filter((facet) => facet.name === 'Brand')[0]?.entry;

    const availableBrandNames = displayableFacetBrands?.map((brands) => brands.label)

    const selectedBrands = brandNames?.trim().split('+');

    const canonicalBrands = availableBrandNames?.filter((brand) => selectedBrands?.includes(brand)); // Filtering the brands to include in the canonical URL based on their availability

    const searchParam = canonicalBrands && canonicalBrands.length > 0 ? `?f=Brand${encodeURIComponent(':'+canonicalBrands.join("|"))}` : ''
    
    if (isBrandPlp && (facetsParams || categoryQueryParam)) {

      /* This code will allow canonical URL with facet groups, which is not required, hence commented.
      
      let facetGroups = formatQueryParams(' ').remove(facetsParams);

      const facetGroupValues: any[] = [];

      let canonicalUrl = window.origin + pathname + search;

      if (
        facetGroups &&
        facetGroups?.length > 1 &&
        facetGroups[0] &&
        facetGroups[1]
      ) {
        facetGroups = [facetGroups[0] + facetGroups[1].replace(/_/g, '')];
      }

      facetGroups?.forEach((facetGroup) => {
        const facetParams: (string | null)[] | null | undefined =
          formatQueryParams(':').remove(facetGroup);

        if (facetParams && facetParams.length !== 0) {
          facetGroupValues.push([...facetParams]);
        }
      });

      const facetParamObj: string[] = [];

      if (categoryQueryParam) {
        facetGroupValues.unshift([BRAND_FACET_KEY, currentPathName]);

        facetGroupValues.forEach((facetGroupValue) => {
          const facetLabel = facetGroupValue[1].replace(/ /g, '_');

          const facetParam = formatQueryParams(':').add([
            facetGroupValue[0].replace(/ /g, '_'),
            facetLabel,
          ]);

          if (facetParam) {
            facetParamObj.push(facetParam);
          }
        });

        const facetGroupParam = formatQueryParams('+').add(facetParamObj);

        canonicalUrl =
          window.origin +
          '/categories/' +
          subCategory.seo.replace(/_/g, '-').replace('/', '') +
          '?f=' +
          facetGroupParam;
      }
*/
    
      let canonicalUrl = window.origin + pathname; // other than brand no other filters are needed, hence removed search
      
      if (categoryQueryParam) {
        canonicalUrl =
          window.origin +
          '/categories/' +
          subCategory.seo.replace(/_/g, '-').replace('/', '') +
          '?f=Brand:' + currentPathName
      }
    
      return canonicalUrl;
    }

    if (seoConfig[formattedPath]?.page?.type === 'ProductListPage' && !seoConfig[formattedPath].isBrandPlp) {
      if (!pathname.includes('categories')) {
        if (!breadcrumbLoading && breadCrumbTrailEntryView.length !== 0) {
          let isCategory = false;
          breadCrumbTrailEntryView.forEach((breadcrumbItem) => {
            if (breadcrumbItem.label?.toLowerCase() === 'categories') {
              isCategory = true;
            }
          });
          if (isCategory) {
            return window.origin + '/categories' + pathname + searchParam
          }
        }
      }
    }
    return window.origin + pathname + searchParam;
  }, [BRAND_FACET_KEY, breadCrumbTrailEntryView, breadcrumbLoading, categoryQueryParam, currentPathName, facetsParams, formattedPath, isBrandPlp, pathname, search, seoConfig, subCategory, displayableFacet]);

  const isAdvantage = Boolean(currentPathName.match(ADVANTAGE));

  const isAdvantageFacetSelected = useMemo(() => {
    if (userType === UserType.Advantage) {
      return (
        selectedFacets.findIndex(({ label }) =>
          label.toLowerCase().includes(ADVANTAGE)
        ) !== -1
      );
    }

    return false;
  }, [selectedFacets, userType]);

  /**
   * @callback initMetaTags Removes the initial meta tags related to ROBOTS.
   */
  const initMetaTags = useCallback((): void => {
    const metaTag = document.querySelector(`meta[name="ROBOTS"]`);

    if (metaTag) {
      metaTag.remove();
    }
  }, []);

  useEffect(() => {
    initMetaTags();
  }, [initMetaTags, showMetaTags]);

  return (
    <Helmet>
      {showMetaTags || isAdvantage || isAdvantageFacetSelected ? (
        <meta name='ROBOTS' content='NOINDEX, NOFOLLOW' />
      ) : (
        <meta name='ROBOTS' content='INDEX, FOLLOW' />
      )}

      <link rel='canonical' href={canonicalUrl} />
    </Helmet>
  );
};

export { PlpMetaTags };
