import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { formatAriaLabel } from '../../../../utils/utils';

/**
 * @interface NteFormProps
 */
interface NteFormProps {
  /**
   * Name of the Form.
   */
  name: string;

  /**
   * Callback to be called with the form field values upon validation and submission.
   */
  // Update Type of Form values to be generic.
  onSubmit: (formValues: any) => void;

  /**
   * Initial Values for the form.
   */
  initialValues?: Partial<any>;

  /**
   * Function to execute custom form validation.
   */
  // Update Type of Form values to be generic.
  validate: (formValues: any) => Partial<any>;

  /**
   * To get the form object reference to call Form functions such as Validate or values.
   */
  getFormReference?: (form: FormApi<any>) => void;

  /**
   * Optional Mutator prop that allows you to modify Form state from outside.
   */
  mutators?: any;

  /**
   * @prop getFormInvalid Fetches the invalid form flag.
   */
  getFormInvalid?: (formInvalid: boolean) => void;

  /**
   * @prop getFormSubmit Fetches the form handle submit events.
   */
  getFormSubmit?: (formSubmit: any) => void;

  /**
   * @prop getFormValues responsible to get the Form Values.
   */
  getFormValues?: (values: any, hasValidationErrors: boolean) => void;
  /**
   * @prop class name for form.
   */
  className?: string;
}

/**
 *
 * @param NteFormProps
 * @returns NteForm Component.
 */
const NteForm: React.FC<NteFormProps> = ({
  children: formFields,
  getFormReference,
  mutators,
  getFormInvalid,
  getFormSubmit,
  getFormValues,
  className = '',
  ...formProps
}) => (
  <Form
    render={({ handleSubmit, form, hasValidationErrors, values }) => {
      if (getFormValues) {
        getFormValues(values, hasValidationErrors);
      }
      if (getFormInvalid) {
        getFormInvalid(hasValidationErrors);
      }

      if (getFormSubmit) {
        getFormSubmit(handleSubmit);
      }

      if (getFormReference) {
        getFormReference(form);
      }

      return (
        <form
          aria-label={formatAriaLabel(formProps.name)}
          onSubmit={handleSubmit}
          className={className}
          noValidate>
          {formFields}
        </form>
      );
    }}
    mutators={mutators}
    {...formProps}
  />
);

export { NteForm };
