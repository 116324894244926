const CartItemQuantityConstants = {
  DEFAULT_TEXT_QUANTITY: '10',
  SAVE_FOR_LATER: 'CHECKOUT.QUANTITY_INCR_DECR.SAVE_FOR_LATER',
  REMOVE: 'CHECKOUT.QUANTITY_INCR_DECR.REMOVE',
  QUANTITY: {
    name: 'quantity',
    id: 'quantity',
    label: 'Quantity',
  },
  QTY: {
    label: 'Qty',
  },
  UPDATE: 'CHECKOUT.QUANTITY_INCR_DECR.UPDATE_LABEL',
  SAVE_FOR_LATER_LABEL: 'Save for Later',
  PARTNUMBER: '14460',
  optionForDropdown: [
    {
      value: '0',
      label: '0 (Delete)',
    },
    {
      value: '1',
      label: 'Qty: 1',
    },
    {
      value: '2',
      label: 'Qty: 2',
    },
    {
      value: '3',
      label: 'Qty: 3',
    },
    {
      value: '4',
      label: 'Qty: 4',
    },
    {
      value: '5',
      label: 'Qty: 5',
    },
    {
      value: '6',
      label: 'Qty: 6',
    },
    {
      value: '7',
      label: 'Qty: 7',
    },
    {
      value: '8',
      label: 'Qty: 8',
    },
    {
      value: '9',
      label: 'Qty: 9',
    },
    {
      value: '10',
      label: '10+',
    },
  ],
  optionForPDPDropdown: [
    {
      value: '1',
      label: 'Qty: 1',
    },
    {
      value: '2',
      label: 'Qty: 2',
    },
    {
      value: '3',
      label: 'Qty: 3',
    },
    {
      value: '4',
      label: 'Qty: 4',
    },
    {
      value: '5',
      label: 'Qty: 5',
    },
    {
      value: '6',
      label: 'Qty: 6',
    },
    {
      value: '7',
      label: 'Qty: 7',
    },
    {
      value: '8',
      label: 'Qty: 8',
    },
    {
      value: '9',
      label: 'Qty: 9',
    },
    {
      value: '10',
      label: '10+',
    },
  ],
  PURCHASE_NOT_AVAILABLE:
    'Your request cannot be completed, as the products you wish to purchase are not available.',
  ADVANTAGE_PARTNUMBER: 'HL-R',
  GIFTCARD_PAY_METHOD: 'GiftCard',
};

export { CartItemQuantityConstants };
