import { createAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../action-types/search-history.action-types';

const ADD_SEARCH_HISTORY_ACTION = createAction<string>(
  ACTION_TYPES.ADD_SEARCH_HISTORY
);

const CLEAR_SEARCH_HISTORY_ACTION = createAction(
  ACTION_TYPES.CLEAR_SEARCH_HISTORY
);

const GET_SEARCH_HISTORY_ACTION = createAction(ACTION_TYPES.GET_SEARCH_HISTORY);

export {
  ADD_SEARCH_HISTORY_ACTION,
  CLEAR_SEARCH_HISTORY_ACTION,
  GET_SEARCH_HISTORY_ACTION,
};
