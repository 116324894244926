const getCardTypeFromNumber = (number: string) => {
  const amexID = 'amex';
  const discoverID = 'discover';
  const mastercardID = 'mastercard';
  const visaID = 'visa';

  if (typeof number !== 'string') {
    return '';
  }

  let strippedNumber = number.replace(/[^0-9 ]/, '');
  if (strippedNumber.length === 0 || strippedNumber !== number) {
    return '';
  }

  strippedNumber = strippedNumber.replace(/\D/, '');

  switch (strippedNumber[0]) {
    case '2': {
      // Mastercard 222100-272099
      let mcNumbers = +strippedNumber.substring(0, 6);
      if (mcNumbers >= 222100 && mcNumbers < 272100) {
        return mastercardID;
      }
      break;
    }

    case '3': {
      // AMEX 34 or 37
      if (strippedNumber[1] === '4' || strippedNumber[1] === '7') {
        return amexID;
      }
      break;
    }

    case '4': {
      // VISA, all 4's
      return visaID;
    }

    case '5': {
      // Mastercard 51-55
      let mcNumbers = +strippedNumber.substring(0, 2);
      if (mcNumbers >= 51 && mcNumbers < 56) {
        return mastercardID;
      }
      break;
    }

    case '6': {
      // Discover: 6011, 622126-622925, 644-649, 65
      if (
        strippedNumber.substring(0, 2) === '65' ||
        strippedNumber.substring(0, 4) === '6011'
      ) {
        return discoverID;
      }

      let dNumbers = +strippedNumber.substring(0, 3);
      if (dNumbers >= 644 && dNumbers <= 649) {
        return discoverID;
      }
      dNumbers = +strippedNumber.substring(0, 6);
      if (dNumbers >= 622126 && dNumbers <= 622925) {
        return discoverID;
      }
      break;
    }

    default:
      return '';
  }

  return '';
};

const getPatternFromNumber = (number: string, isCardValidation?: boolean) => {
  const patterns: { [key: string]: string } = {
    '0': '9999999999999999',
    '2': '9999 9999 9999 9999',
    '3': '9999 999999 99999',
    '4': '9999 9999 9999 9999',
    '5': '9999 9999 9999 9999',
    '6': '9999 9999 9999 9999',
  };

  const firstDigit = ('' + number)[0] || '0';

  let finalPattern = patterns[firstDigit] || patterns['0'];

  if (isCardValidation) {
    return finalPattern;
  }

  if (firstDigit !== '0') {
    finalPattern = finalPattern.substring(0, number.length + 4);
  }

  return finalPattern;
};

export { getCardTypeFromNumber, getPatternFromNumber };
