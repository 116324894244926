const SaveToListMenuConstants = {
  ITEM_ADDED_ALERT: (id: string, item: string) =>
    `Item #${id} was added to ${item}`,
  ITEM_TEXT_FIELD_VALIDATION_MESSAGE:
    'A list with this name already exists. Please choose a new name.',
  DEFAULT_TEXT_FIELD_VALUE: '',
  CANCEL: 'Cancel',
  CREATE_NEW_LIST: 'Create New List',
};

export { SaveToListMenuConstants };
