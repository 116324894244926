import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 * @component DeliveryOptionsChildrenSkeleton renders the skeleton loader for the shipping info on PLP and PDP.
 */
const DeliveryOptionsChildrenSkeleton: React.FC = () => (
  <Card className='prompt-margin'>
    <Typography variant='body2'>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Typography>
  </Card>
);

export { DeliveryOptionsChildrenSkeleton };
