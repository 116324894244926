import { call, put } from 'redux-saga/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import {
  CART_PROMO_CODE_APLLIED_TEALIUM,
  CART_PROMO_CODE_INVALID_TEALIUM,
} from '../../../constants/Tealium';
import i18n from '../../../i18n';
import {
  alertMessageId,
  getLocalStorage,
  sendTealiumData,
} from '../../../utils/utils';
import { CartService } from '../../../_foundation/apis/cart/cart.service';
import { promoCodeService } from '../../../_foundation/apis/promoCode/promoCode.service';
import { IOrderItem } from '../../../_foundation/interface/Cart/IOrder';
import {
  FETCH_CART_DETAILS_SUCCESS_ACTION,
  PROMO_APPLY_BUTTON_LOADING_ACTION,
  PROMO_REMOVE_BUTTON_LOADING_ACTION,
  REQUEST_ERROR_ACTION,
} from '../../actions/order.actions';
import { FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION } from '../../actions/orderDetails.action';
import { fetchItemDetailsByIds } from './orderDetails.workers';

export interface IProductParam {
  storeID: string;
  id: string[];
}

const translationKey = 'API_ERROR_HANDLING.CART_AND_CHECKOUT';

export function* addPromoCode(action: any): Generator {
  try {
    yield put(REQUEST_ERROR_ACTION(false));

    const response: any = yield call(
      promoCodeService.fetchAssociatedPromotionInfo,
      action.payload
    );

    const responseForCartService: any = yield call(
      CartService.getCartDetails,
      action.payload
    );

    const fetchCartDetailsResponse = {
      ...responseForCartService,
      partNumber: action.payload.partNumber,
    };

    if (fetchCartDetailsResponse) {
      let catentryIdList: string[] = [];

      if (fetchCartDetailsResponse) {
        const orderItems = fetchCartDetailsResponse?.orderItem;

        if (orderItems.length > 0) {
          orderItems.forEach((item: IOrderItem) => {
            if (!item?.comments) {
              catentryIdList.push(item.productId);
            }
          });
        }

        const storeId: string = fetchCartDetailsResponse?.storeUniqueID;

        if (catentryIdList.length > 0) {
          const paramsProduct: IProductParam = {
            storeID: storeId,
            id: catentryIdList,
          };

          const productDetails = yield call(
            fetchItemDetailsByIds,
            paramsProduct
          );

          yield put(
            FETCH_CART_DETAILS_SUCCESS_ACTION(fetchCartDetailsResponse)
          );

          yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(productDetails));
        }
      }
    }

    yield put(PROMO_APPLY_BUTTON_LOADING_ACTION(false));

    sendTealiumData({
      tealium_event: CART_PROMO_CODE_APLLIED_TEALIUM,
      product_id: getLocalStorage('PROMO_PRODUCT_IDS'),
      product_name: getLocalStorage('PROMO_PRODUCT_NAMES'),
      promo_code: response.promoCode,
    });

    return response;
  } catch (e: any) {
    yield put(PROMO_APPLY_BUTTON_LOADING_ACTION(false));

    yield put(REQUEST_ERROR_ACTION(true));

    if (e.response.status !== 400) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.ADD_PROMO_CODE.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }

    sendTealiumData({
      tealium_event: CART_PROMO_CODE_INVALID_TEALIUM,
      product_id: getLocalStorage('PROMO_PRODUCT_IDS'),
      product_name: getLocalStorage('PROMO_PRODUCT_NAMES'),
      promo_code: getLocalStorage('PROMO_CODE'),
    });

    console.error(e);
  }
}

export function* removePromoCode(action: any): Generator {
  try {
    yield put(
      PROMO_REMOVE_BUTTON_LOADING_ACTION({
        loading: true,
        promoCode: action.payload.promoCode,
      })
    );

    const response: any = yield call(
      promoCodeService.removeAssociatedPromotionInfo,
      action.payload
    );

    const responseForCartService: any = yield call(
      CartService.getCartDetails,
      action.payload
    );

    const fetchCartDetailsResponse = {
      ...responseForCartService,
      partNumber: action.payload.partNumber,
    };

    if (fetchCartDetailsResponse) {
      let catentryIdList: string[] = [];

      if (fetchCartDetailsResponse) {
        const orderItems = fetchCartDetailsResponse?.orderItem;

        if (orderItems.length > 0) {
          orderItems.forEach((item: IOrderItem) => {
            if (!item?.comments) {
              catentryIdList.push(item.productId);
            }
          });
        }

        const storeId: string = fetchCartDetailsResponse?.storeUniqueID;

        if (catentryIdList.length > 0) {
          const paramsProduct: IProductParam = {
            storeID: storeId,
            id: catentryIdList,
          };

          const productDetails = yield call(
            fetchItemDetailsByIds,
            paramsProduct
          );

          yield put(
            FETCH_CART_DETAILS_SUCCESS_ACTION(fetchCartDetailsResponse)
          );

          yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(productDetails));
        }
      }
    }

    yield put(
      PROMO_REMOVE_BUTTON_LOADING_ACTION({
        loading: false,
        promoCode: action.payload.promoCode,
      })
    );
    return response;
  } catch (e) {
    yield put(
      PROMO_REMOVE_BUTTON_LOADING_ACTION({
        loading: false,
        promoCode: action.payload.promoCode,
      })
    );
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.REMOVE_PROMO_CODE.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );

    console.error(e);
  }
}
