import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @interface INteButton
 */
export interface INteButton extends ButtonProps {
  /**
   * @prop translationKey
   * translation key for the button title.
   */
  translationKey: string;

  /**
   * @prop loading flag determines the button's
   * loading state.
   */
  loading?: boolean;

  /**
   * @prop anchor takes in the ref of any popper element.
   */
  anchor?: React.MutableRefObject<any>;

  /**
   * @prop image renders an image in the button
   */
  image?: any;
}

/**
 * @component NteButton renders the button with translated text.
 *
 * @param INteButton
 */
const NteButton: React.FC<INteButton> = ({
  translationKey,
  loading,
  anchor,
  image,
  ...buttonProps
}) => {
  const { t } = useTranslation();

  const loaderIcon = (
    <CircularProgress
      size={24}
      color='inherit'
      aria-label='loading-button'
      className='loader-icon'
    />
  );

  return (
    <Button
      {...buttonProps}
      {...(anchor && { ref: anchor })}
      {...(loading && { endIcon: loaderIcon, disabled: true })}>
      {t(translationKey)}
      {image}
    </Button>
  );
};

export { NteButton };
