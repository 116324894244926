import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PhysicalStoreDetails } from '../../../../../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { checkTruthy } from '../../../../../../../../utils/utils';
import { NteAlert } from '../../../../../../../NteAlert/NteAlert';
import { useStoreLocator } from '../../../hooks/StoreLocatorHooks';
import { NearByStoreTileConstants } from '../../StoreFinder/NearByStoreTile/NearByStoreTileConstants';
import { CurrentStoreAddressConstants } from './CurrentStoreAddressConstants';

/**
 * @interface ICurrentStoreAddress
 */
interface ICurrentStoreAddress {
  /**
   * @prop physicalStoreDetails holds the params for
   * displaying the current store details.
   */
  physicalStoreDetails: PhysicalStoreDetails;

  /**
   * @prop menuClickHandler handles the individual menu
   * item click events.
   */
  menuClickHandler: (selectedEvent?: any) => void;
}

/**
 * @component CurrentStoreAddress renders the CurrentStore Address section inside the StoreDetails component.
 *
 * @param ICurrentStoreAddress
 */
const CurrentStoreAddress: React.FC<ICurrentStoreAddress> = ({
  physicalStoreDetails,
  menuClickHandler,
}) => {
  const {
    STORE_HOURS,
    STORE,
    TAG_SEPERATOR,
    SDD_UNAVAILABLE,
    ATTRIBUTES: {
      STORE_HOURS_ATTRIBUTE,
      BOPIS_AVAILABILITY,
      GENERAL_ANNOUNCEMENTS,
    },
    STORE_HOURS_FALLBACK,
  } = CurrentStoreAddressConstants;

  const {
    TEMPORARILY_CLOSED,
    PERMANENTLY_CLOSED,
    COMING_SOON,
    ATTRIBUTES: { STORE_WEB_STATUS },
  } = NearByStoreTileConstants;

  const { t, i18n } = useTranslation();

  const {
    Attribute,
    addressLine,
    storeName,
    uniqueID,
    stateOrProvinceName,
    city,
    postalCode,
    telephone1,
    x_url,
  } = physicalStoreDetails;

  const storeHoursTitle = i18n.exists(STORE_HOURS).valueOf()
    ? t(STORE_HOURS)
    : STORE_HOURS_FALLBACK;

  const { storeClosed, storecloseMessage } = useStoreLocator();

  const storeWebStatus = physicalStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === STORE_WEB_STATUS.toLowerCase()
  )?.value;

  const storeStatusClosed =
    storeWebStatus &&
    (storeWebStatus.toLowerCase() === TEMPORARILY_CLOSED.toLowerCase() ||
      storeWebStatus.toLowerCase() === PERMANENTLY_CLOSED.toLowerCase());

  const storeComingSoon =
    storeWebStatus &&
    storeWebStatus.toLowerCase() === COMING_SOON.toLowerCase();

  const storeHours = Attribute.filter(
    (storeAttribute: any) => storeAttribute.name === STORE_HOURS_ATTRIBUTE
  );

  const storeAccouncements = Attribute.filter(
    (storeAttribute: any) =>
      storeAttribute.name.toLowerCase() === GENERAL_ANNOUNCEMENTS.toLowerCase()
  );

  const isBopisEligible = physicalStoreDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
  )?.value;

  const sddAvailable = checkTruthy(isBopisEligible);

  return (
    <div className='menu-list-body store-address'>
      <Box>
        <Typography variant='h3'>
          <Link
            to={x_url && `/store/${x_url}`}
            rel='nofollow'
            className='size18'
            onClick={menuClickHandler}>
            {storeName}
            {uniqueID ? ` (${STORE}${TAG_SEPERATOR}${uniqueID})` : ''}
          </Link>
        </Typography>

        <Typography variant='body2'>{addressLine[0]}</Typography>

        <Typography variant='body2'>
          {city}, {stateOrProvinceName}, {postalCode}
        </Typography>

        <Typography variant='body2'>{telephone1}</Typography>
      </Box>

      {isBopisEligible && !sddAvailable && (
        <NteAlert
          messages={[t(SDD_UNAVAILABLE)]}
          severity='warning'
          showClose={false}
        />
      )}

      {!storecloseMessage && storeComingSoon && (
        <NteAlert
          messages={[storeWebStatus]}
          severity='info'
          showClose={false}
        />
      )}

      {storeStatusClosed && (
        <NteAlert
          messages={[storeWebStatus]}
          severity='warning'
          showClose={false}
        />
      )}

      {storecloseMessage && !storeClosed && !storeComingSoon && (
        <NteAlert
          messages={[storecloseMessage]}
          severity='warning'
          showClose={false}
        />
      )}

      <Box marginTop={2} marginBottom={0}>
        <>
          {storeComingSoon && (
            <Typography variant='body2'>
              <div
                className='size14'
                dangerouslySetInnerHTML={{
                  __html: storeAccouncements[0]?.displayValue,
                }}
              />
            </Typography>
          )}
          <Typography variant='body1' className='bold-weight'>
            {storeHoursTitle}
          </Typography>

          <Typography variant='body2'>
            <div
              className='size14'
              dangerouslySetInnerHTML={{ __html: storeHours[0]?.displayValue }}
            />
          </Typography>
        </>
      </Box>
    </div>
  );
};

export { CurrentStoreAddress };
