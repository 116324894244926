window.LOQ = window.LOQ || [];

/**
 * @method trackUserEvent Tracks the user event via lucky orange.
 *
 * @param eventName Name of the event.
 * @param eventMsg Message of that event.
 */
const trackUserEvent = ({ eventMsg, eventName }) => {
  window.LOQ.push([
    'ready',
    async (LO) => {
      // Track an event
      await LO.$internal.ready('events');
      LO.events.track(eventName, eventMsg);
    },
  ]);
};

export { trackUserEvent };
