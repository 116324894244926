import { IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import NteIconButtonConstants from './NteIconButton.constants';

/**
 * @interface INteIconButton
 */
interface INteIconButton extends IconButtonProps {
  /**
   * @prop variant changes the style of the icon button according to the variant type provided.
   */
  variant?: string;

  /**
   * @prop className.
   */
  className?: string;
}

/**
 * @component NteIconButton renders the button with translated text.
 *
 * @param INteIconButton
 */
const NteIconButton: React.FC<INteIconButton> = ({
  variant = '',
  children,
  className = '',
  ...iconButtonProps
}) => {
  const { ICON_BUTTON } = NteIconButtonConstants;

  const variantClass = variant !== '' ? variant + ICON_BUTTON : '';
  return (
    <IconButton {...iconButtonProps} className={`${variantClass} ${className}`}>
      {children}
    </IconButton>
  );
};

export { NteIconButton };
