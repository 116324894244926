export const FETCH_CART_DETAILS_REQUEST = 'FETCH_CART_DETAILS_REQUEST';
export const FETCH_CART_DETAILS_SUCCESS = 'FETCH_CART_DETAILS_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const APPLY_BUTTON = 'APPLY_BUTTON';
export const REMOVE_BUTTON = 'REMOVE_BUTTON';
export const RESET_CART = 'RESET_CART';
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const GUEST_AND_CART_DETAILS = 'GUEST_AND_CART_DETAILS';
export const GUEST_AND_CATALOG_ORDER = 'GUEST_AND_CATALOG_ORDER';
export const GUEST_AND_CATALOG_ORDER_SUCCESS =
  'GUEST_AND_CATALOG_ORDER_SUCCESS';
export const RESET_GUEST_AND_CATALOG_ORDER = 'RESET_GUEST_AND_CATALOG_ORDER';
export const SHOW_CART_DRAWER = 'SHOW_CART_DRAWER';
export const UPDATE_ITEM_IN_CART = 'UPDATE_ITEM_IN_CART';
export const DELETE_ITEM_IN_CART = 'DELETE_ITEM_IN_CART';
export const GET_CART_PRODUCTS = 'GET_CART_PRODUCTS';
export const GET_CART_PRODUCTS_SUCCESS = 'GET_CART_PRODUCTS_SUCCESS';
export const SET_CART_PAGE_LOADING = 'SET_CART_PAGE_LOADING';
export const FETCH_SHIPPING_INFO = 'FETCH_SHIPPING_INFO';
export const FETCH_SHIPPING_INFO_SUCCESS = 'FETCH_SHIPPING_INFO_SUCCESS';
export const UPDATE_CHECKOUT_SHIPPING_ADDRESS =
  'UPDATE_CHECKOUT_SHIPPING_ADDRESS';
export const UPDATE_CHECKOUT_BILLING_ADDRESS =
  'UPDATE_CHECKOUT_BILLING_ADDRESS';
export const FETCH_GUEST_CONTACT_INFO = 'FETCH_GUEST_CONTACT_INFO';
export const UPDATE_GUEST_CONTACT_INFO = 'UPDATE_GUEST_CONTACT_INFO';
export const SET_ADD_TO_CART_LOADING = 'SET_ADD_TO_CART_LOADING';
export const UPDATE_SHIPPING_AND_BILLING_INFO =
  'UPDATE_SHIPPING_AND_BILLING_INFO';
export const SET_CURRENT_PART_NUMBER_CART = 'SET_CURRENT_PART_NUMBER_CART';
export const SET_CART_SPINNER = 'SET_CART_SPINNER';
export const FETCH_USABLE_PAYMENT_INFO = 'FETCH_USABLE_PAYMENT_INFO';
export const UPDATE_USABLE_PAYMENT_INFO = 'UPDATE_USABLE_PAYMENT_INFO';
export const FETCH_PAYMENT_INFO = 'FETCH_PAYMENT_INFO';
export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
export const SET_REMOVE_SPINNER = 'SET_REMOVE_SPINNER';
export const SET_SAVE_TO_LIST_SPINNER = 'SET_SAVE_TO_LIST_SPINNER';
export const SET_REPLACMENT_PLAN_SPINNER = 'SET_REPLACMENT_PLAN_SPINNER';
export const SET_CART_ITEMS_LOADING = 'SET_CART_ITEMS_LOADING';
export const RESET_CART_ITEMS = 'RESET_CART_ITEMS';
export const PRECHECKOUT_CART_ACTION_REQUEST =
  'PRECHECKOUT_CART_ACTION_REQUEST';
export const PRECHECKOUT_CART_SUCCESS = 'PRECHECKOUT_CART_SUCCESS';
export const APPLY_GIFT_CARD = 'APPLY_GIFT_CARD';
export const REMOVE_GIFT_CARD = 'REMOVE_GIFT_CARD';
export const SET_BACKORDERED_STATE = 'SET_BACKORDERED_STATE';
export const ORDER_CALCULATE = 'ORDER_CALCULATE';
export const ENABLE_DELIVERY_STEP = 'ENABLE_DELIVERY_STEP';
export const ALLOW_MULTIPLE_PAYMENTS = 'ALLOW_MULTIPLE_PAYMENTS';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';
export const SET_GIFTCARD_ERROR = 'SET_GIFTCARD_ERROR';
export const RESET_GIFTCARD_ERROR = 'RESET_GIFTCARD_ERROR';
