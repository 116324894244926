import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * @component RobotsMetaTags Adds the robots meta tags.
 */
const RobotsMetaTags: React.FC = () => (
  <Helmet>
    <meta name='ROBOTS' content='INDEX, FOLLOW' />
  </Helmet>
);

export { RobotsMetaTags };
