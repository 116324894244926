import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { enterKeyPressHandler } from '../../../utils/utils';
import { NteMenuList } from '../../Menu/NteMenuList/NteMenuList';
import { CreateListItem } from './CreateListItem/CreateListItem';
import { useSaveToListMenu } from './hooks/SaveToListMenuHooks';
import { SaveToListMenuConstants } from './SaveToListMenuConstants';
import { saveToListSelector } from './selector/save-to-list.selector';
import { NteButton } from '../../Forms/NteButton/NteButton';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';

/**
 * @interface ISaveToListMenu
 */
interface ISaveToListMenu {
  /**
   * @prop clickHandler handles the click events in save to list menu.
   */
  clickHandler: () => void;

  /**
   * @prop id of the current product from which the save to list is initiated.
   */
  id: string;

  productData: IProductListContents;
}

/**
 * @method SaveToListMenu renders the list menu with the create a new item form.
 *
 * @param ISaveToListMenu
 */
const SaveToListMenu: React.FC<ISaveToListMenu> = ({
  clickHandler,
  id,
  productData,
}) => {
  const { CREATE_NEW_LIST } = SaveToListMenuConstants;

  const [showAddList, setShowAddList] = useState(false);

  const { listMenuItems } = useSelector(saveToListSelector);

  const { saveToList, currentItem } = useSaveToListMenu({ productData });

  const onMenuItemClick = (selectedItem: any): void => {
    saveToList(selectedItem.currentItem);

    clickHandler();
  };

  return (
    <div className='save_to_list_menu'>
      <NteMenuList
        menuItems={listMenuItems}
        menuTitle='Choose a List'
        menuListCloseHandler={clickHandler}
        nteMenuClickHandler={onMenuItemClick}
        currentItem={currentItem}
        icon={<CheckCircleIcon />}
        id={`save-to-list-${id}`}
      />

      {!showAddList && (
        <NteButton
          onClick={() => setShowAddList(true)}
          onKeyDown={(e: any) =>
            enterKeyPressHandler(e, () => {
              setShowAddList(true);
            })
          }
          size='small'
          type='button'
          className='inline-text create-new-list field-margin-top'
          translationKey={CREATE_NEW_LIST}
        />
      )}

      {showAddList && (
        <CreateListItem
          productData={productData}
          cancelHandler={setShowAddList}
        />
      )}
    </div>
  );
};

export { SaveToListMenu };
