import { decode } from 'html-entities';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { REMOVE_PRODUCT_FROM_COMPARE_ACTION } from '../../../../../redux/actions/productList.actions';
import { ImageUrlUtility } from '../../../../../utils/image-url-utility';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { Thumbnail } from '../../../Thumbnail/Thumbnail';
import { Rating } from '../../Rating/Rating';

/**
 * @interface ProductCardSectionImageProps.
 */
export interface ProductCardSectionImageProps {
  productName: string;
  productImage: string;
  partNumber?: string;
  isCompareProduct?: boolean;
  isSimpleProductCard?: boolean;
  showRemove?: boolean;
  productUrl: string;
  isDisplayOnlyItem?: boolean;
  showRating?: boolean;
  showAdvantageExclusivesBadge?: boolean;
}

/**
 *
 * @param ProductCardSectionImageProps.
 * @returns ProductCardSectionImageProps Component.
 */
const ProductCardSectionImage = ({
  productName,
  productImage,
  partNumber,
  isCompareProduct,
  isSimpleProductCard,
  showRemove,
  productUrl,
  isDisplayOnlyItem,
  showRating = true,
  showAdvantageExclusivesBadge,
}: ProductCardSectionImageProps) => {
  const dispatch = useDispatch();

  const imageUrl = ImageUrlUtility.createImgSrc(productImage);

  /**
   * @method productRemoveHandler removes the current product from comparison page.
   */
  const productRemoveHandler = (id: string) => (): void => {
    dispatch(REMOVE_PRODUCT_FROM_COMPARE_ACTION({ id }));
  };

  return (
    <div className='image-cell product_card_section_image'>
      {isCompareProduct && showRemove && (
        <div className='align-right'>
          <NteButton
            className='text-only'
            translationKey='Remove'
            size='small'
            onClick={productRemoveHandler(partNumber!)}
            type='button'
          />
        </div>
      )}

      {isDisplayOnlyItem ? (
        <Thumbnail
          imgSrc={imageUrl}
          fullWidth
          size='large'
          imgAlt={decode(productName)}
          showAdvantageExclusivesBadge={showAdvantageExclusivesBadge}
        />
      ) : (
        <Link to={productUrl}>
          <Thumbnail
            imgSrc={imageUrl}
            fullWidth
            size='large'
            imgAlt={decode(productName)}
            showAdvantageExclusivesBadge={showAdvantageExclusivesBadge}
          />
        </Link>
      )}

      {/**
       * Once we pass the product rating id, Bazaar API will take care of updating the rating
       */}
      {showRating && (
        <Rating
          partNumber={partNumber}
          isSimpleProductCard={isSimpleProductCard}
          isCompareProduct={isCompareProduct}
        />
      )}
    </div>
  );
};

export { ProductCardSectionImage };
