/**
 * @return extra function call to make sure all JSS styles(used by Material UI) are print to HTML.
 */
const extractCSStoHTML = () => {
  if ((window as any).__isPrerender__) {
    let cssStyles = '';
    for (let i = 0; i < document.styleSheets.length - 1; i++) {
      const styleSheet: CSSStyleSheet = document.styleSheets[i];
      if (styleSheet.href == null) {
        const rules = styleSheet.cssRules;
        for (let item of rules) {
          if (item.cssText !== undefined) {
            cssStyles += item.cssText;
          }
        }
      }
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    let style = document.getElementById('styles-for-prerender');
    if (style) {
      style.setAttribute(
        'iteration',
        String(parseInt(style.getAttribute('iteration') || '0') + 1)
      );
      while (style.firstChild) {
        style.removeChild(style.firstChild);
      }
    } else {
      style = document.createElement('style');
      style.setAttribute('iteration', '1');
      head.appendChild(style);
      style.id = 'styles-for-prerender';
    }
    style.appendChild(document.createTextNode(cssStyles));
  }
};

/**
 * This class/method is called for every request/response needed to serve a BOT (managed by Prerender.io).
 * Overall logic:
 *    - An initial Timer of X seconds is set (Timer 1)
 *    - Before it times out, if a new request/response is intercepted this means much likely we're still fetching data. In that case...
 *    - The original Timer is canceled and a new Timer is created...
 *    - When Timer 1 times out without a new request/response this means that much likely all data was fetched. In that case...
 *    - A new Timer of X seconds (Timer 2) is set to inject the CSS and set the "prerenderFlag"
 *    - When the "prerenderReady" flag is set tp true that tells Prerender.io it can proceed to pull the HTML
 *
 */
export class PrerenderTimer {
  public static myTimer = new PrerenderTimer();

  private _myTimer: any = undefined;

  public setPrerenderTimer() {
    if (this._myTimer) {
      clearTimeout(this._myTimer);
    }
    if (this._myTimer !== null) {
      //console.log(`this._myTimer = ${this._myTimer} is not null. Getting new/FIRST Timer at ${new Date().toLocaleTimeString()} with ${process.env.REACT_APP_PRERENDER_TIMER!} ms.`);

      this._myTimer = setTimeout(() => {
        this._myTimer = null;

        //console.log('Timer is now null.');
        //console.log(`Window.prenrederReady = ${(window as { [key: string]: any })['prerenderReady']} at ${new Date().toLocaleTimeString()}`);
        //console.log(`About to set SECOND timeout at ${new Date().toLocaleTimeString()}.`);
           extractCSStoHTML();
          (window as { [key: string]: any })['prerenderReady'] = true;

          //console.log(`Window.prenrederReady = ${(window as { [key: string]: any })['prerenderReady']} at ${new Date().toLocaleTimeString()}`);
      }, +process.env.REACT_APP_PRERENDER_TIMER!);
    }
  }
}
