/**
 * @method getHighlightedText Highlights the specific letters in @text
 * with respect to the given @highlightText
 *
 * @param text Text for which the highligher needs to be applied.
 * @param highlightText Text with respect to which the letters need to be highlighed in @text
 * @param colorClass Text with respect to classname the color will be applied
 * @returns Highlighed text as JSX Element.
 */
const highLightText = (
  text: string,
  highlightText: string,
  colorClass?: string
): JSX.Element => {
  const parts = text.split(
    new RegExp(`(${highlightText.replace(/\\|\(|\)|\[|\}|\*|\+/g, '')})`, 'gi')
  );

  if (colorClass) {
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlightText.toLowerCase() ? (
            <span key={index} className={colorClass}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  }

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlightText.toLowerCase() ? (
          <b key={index}>{part}</b>
        ) : (
          part
        )
      )}
    </span>
  );
};

export { highLightText };
