const BASE_URL = '/wcs/resources/store';

const PromoCodeServiceConstant = {
  FETCH_ASSOCIATED_PROMOTION_URL: (storeID: string) =>
    `${BASE_URL}/${storeID}/cart/@self/assigned_promotion_code?langId=-1`,

  REMOVE_ASSOCIATED_PROMOTION_URL: (storeID: string, promoCode: string) =>
    `${BASE_URL}/${storeID}/cart/@self/assigned_promotion_code/${promoCode}?langId=-1`,
};

export { PromoCodeServiceConstant };
