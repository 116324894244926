import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Rating } from '@material-ui/lab';
import { decode } from 'html-entities';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import { FETCH_BREADCRUMB_ACTION } from '../../../../../redux/actions/breadcrumb.actions';
import {
  CLEAR_PRICE_RANGE_FACET_ACTION,
  RESET_PRODUCT_LIST_ACTION,
  SET_FACET_ACTION,
} from '../../../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../../../redux/selectors/breadcrumb.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { useCategoryFacetParam } from '../../../../../utils/hooks/category-facet-param/category-facet-param';
import { useFormattedPath } from '../../../../../utils/hooks/formatted-path/formatted-path';
import { scrollToTop } from '../../../../../utils/utils';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { IconText } from '../../../IconText/IconText';
import { ClearAllFacets } from '../../ClearAllFacets/ClearAllFacets';
import { FacetConstants } from '../../Facet/FacetConstants';
import RatingStar from '../../Facet/RatingsFacetIcon/RatingStar';
import RatingStarBorder from '../../Facet/RatingsFacetIcon/RatingStarBorder';

/**
 * @interface IActiveFacetBody
 */
interface IActiveFacetBody {
  showClearAll: boolean;
}

/**
 * @component ActiveFacetBody renders the list of currently selected filters.
 */
const ActiveFacetBody: React.FC<IActiveFacetBody> = ({ showClearAll }) => {
  const {
    FACET_QUERY_KEY,
    PRICE,
    PRICE_LABEL,
    RATINGS,
    PARENT_CATALOG_GROUP,
    CATEGORY,
    PROMOTION,
    MORE_SAVINGS,
    PRICE_RANGE,
  } = FacetConstants;

  const { mySite } = useSite();

  const { isCategoryFacetSelection, currentPathName } = useFormattedPath();

  const {
    selectedGroups,
    selectedFacets,
    categoryIdentifiers,
    currentBrandName,
    subCategory,
  } = useSelector(productListSelector);

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { removeActiveCategoryFacet, removeBrandCategoryFacet } =
    useCategoryFacetParam();

  const [activeFacets, setActiveFacets] = useState<IProductListFacetsEntry[]>(
    []
  );

  const [activeCategoryFacets, setActiveCategoryFacets] = useState<
    IProductListFacetsEntry[]
  >([]);

  const [, setFacetsParam] = useQueryParam(FACET_QUERY_KEY, StringParam);

  // Recently selected facet values will sit at the top.
  const facetKeys = Object.keys(selectedGroups).reverse();

  const facetValues = Object.values(selectedGroups).reverse();

  /**
   * @callback initActiveFacets Initializes the states for the active facets.
   */
  const initActiveFacets = useCallback((): void => {
    // Display the recently selected facet values at the top.
    const facetValues = Object.values(selectedGroups).reverse();

    const facetKeys = Object.keys(selectedGroups).reverse();

    const ratingKeyIndex = facetKeys.findIndex((value) => value === RATINGS);

    let selectedFacets: IProductListFacetsEntry[] = [];

    facetValues.forEach((facet, index) => {
      if (facetKeys[ratingKeyIndex] === facetKeys[index]) {
        const ratingsFacets: IProductListFacetsEntry[] = facet.map((value) => {
          const ratingFacet: IProductListFacetsEntry = {
            ...value,
            isRatingsFacet: true,
            facetLabel: facetKeys[index],
          };

          return ratingFacet;
        });

        selectedFacets = [...selectedFacets, ...ratingsFacets];
      } else {
        const currentFacets = facet.map((value) => {
          const currentFacet = {
            ...value,
            facetLabel: facetKeys[index],
          };

          return currentFacet;
        });

        selectedFacets = [...selectedFacets, ...currentFacets];
      }
    });

    setActiveFacets(selectedFacets);
    scrollToTop();
  }, [RATINGS, selectedGroups]);

  useEffect(() => {
    initActiveFacets();
  }, [initActiveFacets]);

  const initActiveCategoryFacets = useCallback((): void => {
    if (categoryIdentifiers.length === 0) {
      setActiveCategoryFacets([]);
    }

    if (
      (subCategory.identifier || currentPathName.includes('search')) &&
      categoryIdentifiers.length !== 0
    ) {
      const currentActiveCategoryFacets: IProductListFacetsEntry[] = [];

      const currentActiveCategoryFacet: IProductListFacetsEntry = {
        count: '',
        extendedData: {
          uniqueId: subCategory.id,
        },
        label: subCategory.label,
        value: subCategory.identifier,
        facetLabel: 'Category',
      };

      currentActiveCategoryFacets.push(currentActiveCategoryFacet);

      setActiveCategoryFacets(currentActiveCategoryFacets);

      return;
    }
  }, [categoryIdentifiers.length, currentPathName, subCategory]);

  useEffect(() => {
    initActiveCategoryFacets();
  }, [initActiveCategoryFacets]);

  /**
   * @method activeFacetRemoveHandler removes the currently selected facet.
   */
  const activeFacetRemoveHandler = (
    facet: IProductListFacetsEntry,
    isPriceFacet: boolean
  ): void => {
    if (
      isCategoryFacetSelection &&
      breadCrumbTrailEntryView.length !== 0 &&
      mySite &&
      facet.facetLabel === PARENT_CATALOG_GROUP &&
      !(facet.extendedData.parentIds && facet.extendedData.parentIds === '1351')
    ) {
      const currentBreadcrumbIndex = breadCrumbTrailEntryView.findIndex(
        ({ value }) => value === facet.extendedData.uniqueId
      );

      const selectedCategoryId =
        breadCrumbTrailEntryView[currentBreadcrumbIndex - 1].value;

      dispatch(
        FETCH_BREADCRUMB_ACTION({
          categoryId: selectedCategoryId,
          catalogId: mySite.catalogID,
          storeID: mySite.storeID,
        })
      );
    }

    const isActivePrice = facetKeys.some(
      (value) => value === PRICE || value === PRICE_LABEL
    );

    /**
     * Remove the query params from the url if we have one normal facet value and a price group facet.
     */
    if (facetKeys.length === 2 && facetValues.length === 2 && isActivePrice) {
      setFacetsParam(undefined, 'replaceIn');
    }

    /**
     * Remove the query params from the url if we have only one facetValue in the selectedFacets.
     */
    if (selectedFacets.length === 1) {
      setFacetsParam(undefined, 'replaceIn');

      dispatch(
        RESET_PRODUCT_LIST_ACTION({
          preserveSubCategories: true,
          preserveSelectedFacet: true,
        })
      );
    }

    if (isPriceFacet) {
      dispatch(CLEAR_PRICE_RANGE_FACET_ACTION());

      return;
    }

    let facetGroupIndex: number = -1;

    facetValues.forEach((facetValue, index) => {
      const foundIndex = facetValue.findIndex(
        ({ value }) => value === facet.value
      );

      if (foundIndex >= 0) {
        facetGroupIndex = index;
      }
    });

    dispatch(
      SET_FACET_ACTION({
        facetData: facet,
        isChecked: false,
        facetGroup: facetKeys[facetGroupIndex],
      })
    );
  };

  /**
   * @method fetchFacetLabel Retrives the facet label based on the facet type.
   */
  const fetchFacetLabel = ({
    label,
    count,
    isPriceFacet,
    facetLabel,
  }: IProductListFacetsEntry): string => {
    const currentFacetLabel = (facetLabel?: string): string => {
      if (facetLabel) {
        if (facetLabel === PARENT_CATALOG_GROUP) {
          return t(CATEGORY);
        }

        if (facetLabel === PROMOTION) {
          return t(MORE_SAVINGS);
        }

        if (facetLabel === PRICE_RANGE) {
          return t(PRICE);
        }

        return facetLabel;
      }

      return '';
    };

    if (!count && isPriceFacet) {
      const prices = label.split('-');

      const fromPriceLabel = prices[0];

      const toPriceLabel = prices[1];

      return `${currentFacetLabel(
        facetLabel
      )}: $${fromPriceLabel} - $${toPriceLabel}`;
    }

    return `${currentFacetLabel(facetLabel)}: ${decode(label)} ${
      count !== '0' && count ? `(${count})` : ''
    } `;
  };

  return (
    <ul>
      {activeFacets.map((facet: IProductListFacetsEntry, index: number) => (
        <li key={index}>
          <IconText
            {...(facet?.isRatingsFacet && {
              labelElement: (
                <div className='facet-rating'>
                  <Rating
                    value={Number(facet.label)}
                    className='facet-stars'
                    readOnly
                    precision={0.25}
                    icon={
                      <RatingStar
                        className='star filled'
                        role='img'
                        aria-label={`${facet.label} rating icon`}
                      />
                    }
                    emptyIcon={
                      <RatingStarBorder
                        className='star unfilled'
                        role='img'
                        aria-label={`rating icon`}
                      />
                    }
                  />

                  <Typography variant='body2'>
                    {Number(facet.label) < 5} ({facet.count})
                  </Typography>
                </div>
              ),
            })}
            {...(!facet?.isRatingsFacet && {
              text: fetchFacetLabel(facet),
            })}
            icon={<CloseIcon role='button' />}
            clickHandler={() =>
              activeFacetRemoveHandler(
                facet,
                facet?.isPriceFacet ? facet?.isPriceFacet : false
              )
            }
          />
        </li>
      ))}

      {activeCategoryFacets.map((activeCategoryFacet, index) => (
        <li key={index}>
          <IconText
            text={fetchFacetLabel(activeCategoryFacet)}
            icon={<CloseIcon role='button' />}
            clickHandler={() => removeActiveCategoryFacet(activeCategoryFacet)}
          />
        </li>
      ))}

      {currentBrandName && activeCategoryFacets.length !== 0 && (
        <li key='brandIndex'>
          <IconText
            text={`Brand: ${currentBrandName}`}
            icon={<CloseIcon role='button' />}
            clickHandler={removeBrandCategoryFacet}
          />
        </li>
      )}

      {showClearAll && (
        <li key='clearAll' className='clear-all'>
          <ClearAllFacets />
        </li>
      )}
    </ul>
  );
};

export { ActiveFacetBody };
