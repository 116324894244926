import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { STORE_DETAIL } from '../../../constants/routes';
import {
  storeLocatorSearchSelector,
  storeLocatorSelector,
} from '../../../redux/selectors/storeLocator';
import { useSearchStores } from '../../../utils/hooks/StoreLocator/search-stores/search-stores';
import { PhysicalStoreDetails } from '../../../_foundation/interface/StoreLocator/IStoreLocator';
import { useStoreLocator } from '../AppBar/SecondaryMenuItems/StoreLocator/hooks/StoreLocatorHooks';
import { FullWidthSectionSkeleton } from '../RecommendationLayouts/FullWidthSection/skeleton/FullWidthSectionSkeleton';
import { useSearchBar } from '../SearchBar/hooks/SearchBarHooks';
import { Map } from './Map';
import { Marker } from './Marker';
import { NteGoogleMapConstants } from './NteGoogleMap.constants';

/**
 * @return Render prop used to switch on the status.
 *
 */

const render = (status: Status) => {
  const { STATUS } = NteGoogleMapConstants;

  if (status === STATUS.LOADING) {
    return (
      <>
        <FullWidthSectionSkeleton />
      </>
    );
  }
  return <></>;
};

interface Props {
  /**
   * @prop storeFinderCloseHandler handles the store finder's
   * close button events.
   */
  storeFinderCloseHandler: () => void;

  storeDetail?: PhysicalStoreDetails[];
}

/**
 * @return NteGoogleMap
 *
 */
const NteGoogleMap: React.FC<Props> = ({
  storeFinderCloseHandler,
  storeDetail,
}) => {
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 37.09024,
    lng: -95.712891,
  });

  const { currentValue } = useSearchBar('');

  const { searchedStoreDetails } = useSelector(storeLocatorSearchSelector);

  const { storeDetails, usStoreDetails } = useSelector(storeLocatorSelector);

  const { currentStoreDetails } = useSearchStores({
    currentAddress: currentValue,
    storeDetails,
    searchedStoreDetails,
    storeFinderCloseHandler,
  });

  const { nearByStoreDetails } = useStoreLocator();

  const { t } = useTranslation();

  const { SR_ONLY_TEXT } = NteGoogleMapConstants;

  // initial zoom
  const [zoom, setZoom] = useState(4);

  const initStoreLocation = useCallback(() => {
    if (nearByStoreDetails?.geoCoordinates && !storeDetail) {
      setCenter({
        lat: Number(nearByStoreDetails.geoCoordinates.lat),
        lng: Number(nearByStoreDetails.geoCoordinates.lng),
      });
      setZoom(8);
    }

    if (currentStoreDetails.length !== 0 && !storeDetail) {
      setCenter({
        lat: Number(currentStoreDetails[0].latitude),
        lng: Number(currentStoreDetails[0].longitude),
      });
      setZoom(8);
    }

    if (storeDetail && storeDetail?.length !== 0) {
      setCenter({
        lat: Number(storeDetail[0]?.latitude),
        lng: Number(storeDetail[0]?.longitude),
      });
      setZoom(11);
    }
  }, [nearByStoreDetails?.geoCoordinates, currentStoreDetails, storeDetail]);

  useEffect(() => {
    initStoreLocation();
  }, [initStoreLocation]);

  return (
    <div className='nte_google_map elevate-1' role='region' aria-label='map'>
      <span className='sr-only'>{t(SR_ONLY_TEXT)}</span>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!}
        render={render}>
        <Map center={center} zoom={zoom} className='map_flex'>
          {nearByStoreDetails &&
            nearByStoreDetails.geoCoordinates &&
            currentStoreDetails.length === 0 &&
            !storeDetail && (
              <Marker
                position={{
                  lat: Number(nearByStoreDetails.geoCoordinates.lat),
                  lng: Number(nearByStoreDetails.geoCoordinates.lng),
                }}
                title={nearByStoreDetails.storeName}
              />
            )}

          {currentStoreDetails &&
            !storeDetail &&
            currentStoreDetails.map(
              (storeDetails: PhysicalStoreDetails, index: number) => (
                // add url to this one
                <Marker
                  key={index}
                  position={{
                    lat: Number(storeDetails.latitude),
                    lng: Number(storeDetails.longitude),
                  }}
                  title={storeDetails.storeName}
                  url={`${STORE_DETAIL}/${storeDetails.x_url}`}
                />
              )
            )}

          {storeDetail && storeDetail[0] && (
            <Marker
              position={{
                lat: Number(storeDetail[0].latitude),
                lng: Number(storeDetail[0].longitude),
              }}
              title={storeDetail[0].storeName}
            />
          )}
          {usStoreDetails &&
            !storeDetail &&
            (!currentStoreDetails || currentStoreDetails.length === 0) &&
            usStoreDetails.map(
              (storeDetails: PhysicalStoreDetails, index: number) => (
                <Marker
                  key={index}
                  position={{
                    lat: Number(storeDetails.latitude),
                    lng: Number(storeDetails.longitude),
                  }}
                  title={storeDetails.storeName}
                />
              )
            )}
        </Map>
      </Wrapper>
    </div>
  );
};

export { NteGoogleMap };
