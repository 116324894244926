import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupportMenuConstants } from '../SupportMenuConstants';

/**
 * @component SupportCustomerCareItem renders the
 * customer care number under the support menu.
 */
const SupportCustomerCareItem: React.FC = () => {
  const { CUSTOMER_CARE, CUSTOMER_CARE_NUMBER, CUSTOMER_CARE_FALLBACK } =
    SupportMenuConstants;

  const { t, i18n } = useTranslation();

  return (
    <>
      <Typography
        variant='body2'
        className='no-link-effect support_customer_care_item'>
        {i18n.exists(CUSTOMER_CARE).valueOf()
          ? t(CUSTOMER_CARE)
          : CUSTOMER_CARE_FALLBACK}
        &nbsp;
        <span tabIndex={0} role='link' className='number-style link-effect'>
          {CUSTOMER_CARE_NUMBER}
        </span>
      </Typography>
    </>
  );
};

export { SupportCustomerCareItem };
