const StoreFinderConstants = {
  SEARCH_BAR_PLACEHOLDER:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.SEARCH_BAR_PLACEHOLDER',
  SEARCH_BAR_LABEL: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.SEARCH_BAR_LABEL',
  STORE_PICKUP_INSTRUCTION:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.STORE_PICKUP_INSTRUCTION',
  STORE_FINDER_REQUEST_LENGTH: 2,
  STORE_FINDER_GUIDE:
    'The following text field filters the results that follow as you type. Use Tab to access the results.',
  STORE_FINDER_RESULT: ` results available. Use Tab to access.`,
  NO_STORES_FOUND: (currentValue: string) => [
    `There are no Northern Tool stores within 100 miles of ${currentValue}.
  Try different zip code, or city, state, or view Store Index
  Page`,
  ],
  NO_STORE_INVENTORY_AVAILALE: 'Store inventory is unavailable.',
  STORE_LOCATOR_GENERIC_ERROR:
    'The website was unable to complete your last request. Please try again later.',
  ACCORDION: 'accordion',
  VIEW_STORE_INDEX: 'Store Index.',
  ERROR_MESSAGE: {
    NO_NORTHERN_TOOL_STORES_ZIPCODE:
      'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.ERROR_MESSAGE.N0_NORTHERN_TOOL_STORES_ZIPCODE',
    NO_NORTHERN_TOOL_STORES_LOCATION:
      'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.ERROR_MESSAGE.N0_NORTHERN_TOOL_STORES_LOCATION',
  },
  SEARCH_BAR_PLACEHOLDER_FALLBACK: 'ZIP Code Or City, State',
  D365_ATTRIBUTE: 'operatingInD365',
  D365_MESSAGE:
    'Buy online pickup in store same day is not available for this location.',
  BOPIS_AVAILABILITY: 'bopisAvailability',
};

export { StoreFinderConstants };
