const BadgeHookConstants = {
  TOP_SELLER: 'Top Seller',
  RATING: 'Rating',
  TOP_RATED: 'Top Rated',
  DEAL_OF_THE_DAY: 'Deal of the Day',
  BADGE: 'Badge',
  ON_SALE: 'On Sale',
  CLEARANCE: 'Clearance',
  IS_PIECES: 'Pieces (qty.)',
  IS_PAIRS: 'Pairs (qty.)',
  IS_INCLUDE: 'Included (qty.)',
  PIECES: 'Pieces',
  PAIRS: 'Pairs',
  INCLUDED: 'Included',
  JUMBO: 'jumbo',
  EXCLUSIVE_ATTR: 'nteexclusive',
  EXCLUSIVE_VALUE: 'Exclusive',
  JUMBO_SIZE: 'Jumbo Size',
  badgeMap: [
    'New',
    'Top Seller',
    'Top Rated',
    'Deal of the Day',
    'On Sale',
    'Clearance',
    'Exclusive',
    '',
    'Jumbo Size',
  ],
  saleorClearance: ['On Sale', 'Clearance'],
  TOP_SELLER_COUNT: 3,
  RATING_LIMIT: 4.5,
};

export { BadgeHookConstants };
