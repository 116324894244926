/**
 * @enum UserType
 */
export enum UserType {
  NotSignedIn,
  SignedIn,
  Platinum,
  GreatPlains,
  NorthernPreferred,
  Advantage,
  AdvantageNotSignedIn,
}
export enum UserTypeString {
  ADVANTAGE_AUTH = 'AdvantageAuth',
  PLATINUM = 'Platinum',
  GREATPLAINS = 'GreatPlains',
  NORTHERNPREFERRED = 'NorthernPreferred',
}

export const ADVANTAGE_AUTH = 'AdvantageAuth';
export const PLATINUM = 'Platinum';
export const GREATPLAINS = 'GreatPlains';
export const NORTHERNPREFERRED = 'NorthernPreferred';
