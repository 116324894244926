import { site } from '../../../_foundation/constants/site';
import { getSite } from '../../../_foundation/hooks/usesite';

const PlplayoutConstants = {
  TRANSLATIONS: {
    LOAD_MORE: 'PLP_PAGE.LOAD_MORE',
    ALL_PRODUCTS_LOADED: 'PLP_PAGE.ALL_PRODUCTS_LOADED',
    SORT: 'PLP_PAGE.SORT',
    ZERO_PRODUCTS: 'PLP_PAGE.ZERO_PRODUCTS',
    REMOVE_SOME_FILTERS: 'PLP_PAGE.MESSAGE.WARNING.REMOVE_SOME_FILTERS',
    FILTER_MESSAGE: 'PLP_PAGE.FILTER_MESSAGE',
    EDIT_FILTER: 'PLP_PAGE.EDIT_FILTER',
    ALL_DEALS: 'All_Deals',
    NO_PRODUCT_MESSAGE: 'PLP_PAGE.MESSAGE.WARNING.NO_PRODUCT_MESSAGE',
  },
  getProductCountPerPage: () => {
    const mySite = getSite();
    if (mySite?.productCountPerPage) {
      return Number(mySite?.productCountPerPage);
    }
    return Number(site.productCountPerPage);
  },
  PROD_URL: 'https://www.northerntool.com',
  DEFAULT_SORT_INDEX: '1',
  ORDER_BY_PARAM: 'orderBy',
  SORT_OPTIONS: [
    {
      value: 1,
      label: 'Most Popular',
    },
    {
      value: 2,
      label: 'Top Rated',
    },
    {
      value: 3,
      label: 'Price Low to High',
    },
    {
      value: 4,
      label: 'Price High to Low',
    },
  ],
  SHOP_STORE: 'shop-store',
  GRID: 'grid',
  LIST: 'list',
  PLP_VIEW: 'plpView',
};

export { PlplayoutConstants };
