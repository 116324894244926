import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { IUtagData } from '../../../utils/utils';
import { UserType } from '../../../_foundation/enum/User/UserType';
import { TealiumUTagConstants } from '../TealiumUtagConstants';

/** This context helps with maintaing the last visited Page Name to
    avoid duplicate analytics page View count being sent to Tealium. 
    */
const LastVisitedRouteContext = React.createContext<any>({
  current: '',
});

/**
 *
 * @returns useUtagAnalytics hook to send Page Views to Utag Data layer for Tealium Integration.
 */
const useUtagAnalytics = () => {
  const { userInfo, isUserAuthenticated, userType } = useSelector(
    authenticationSelector
  );

  /** This helps in preventing to send unnecessary duplicate Analytics data when routes are rendered more than once. */
  const lastVisitedRouteRef = useContext(LastVisitedRouteContext);

  const pushPageViewForUtag = ({
    pageName,
    pageType,
    pageSiteSection,
  }: IUtagData) => {
    /* Check to not send Duplicate Analytics Data on rerendering Pages */
    if (window.utag && lastVisitedRouteRef.current !== pageName) {
      lastVisitedRouteRef.current = pageName;

      const utagPayload = {
        page_type: pageType,
        page_name: pageName,
        page_site_section: pageSiteSection,
        customer_id: userInfo?.userId,
        customer_email: userInfo?.email1,
        customer_email_sha256: userInfo?.email1, //an extension in tealium will encrypt this value
        customer_login_status: isUserAuthenticated
          ? TealiumUTagConstants.LOGGED_IN
          : TealiumUTagConstants.LOGGED_OUT,
        customer_type: TealiumUTagConstants.PERSONAL_ACCOUNT,
        customer_membership_type: UserType[userType],
      };

      utag.view(utagPayload);
    }
  };

  return { pushPageViewForUtag };
};

export { useUtagAnalytics, LastVisitedRouteContext };
