import { CATEGORY_INDEX } from '../../../constants/routes';

/**
 * Search Plp Layout Constants.
 */
const SearchLayoutConstants = {
  TRANSLATIONS: {
    SEARCH_PLP_HEADING: 'SEARCH_PLP_PAGE.HEADING',
    NO_RESULTS_HEADING: 'SEARCH_PLP_PAGE.NO_RESULTS_HEADING',
  },
  POPULAR_CATEGORIES_ESPOT: {
    categoryRecommendationHeader: {
      link: CATEGORY_INDEX,
      linkText: 'See All Categories',
      subTitle: 'Over 100,000 Products',
      title: 'Popular Categories',
    },
    id: 'NoResults_02',
    maxCols: 4,
  },
  SEARCH_QUERY_PARAM: 's',
};

export { SearchLayoutConstants };
