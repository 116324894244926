import { createReducer, current } from '@reduxjs/toolkit';
import { CookieUtility } from '../../../../utils/cookie-utility';
import { SEARCH_HISTORY } from '../../../../_foundation/constants/cookie';
import {
  ADD_SEARCH_HISTORY_ACTION,
  CLEAR_SEARCH_HISTORY_ACTION,
  GET_SEARCH_HISTORY_ACTION,
} from '../actions/search-history.actions';
import { ISearchHistoryState } from '../interface/ISearchHistory';

const initSearchHistory: ISearchHistoryState = {
  searchItems: [],
};

const searchHistoryReducer = createReducer(initSearchHistory, (builder) => {
  /**
   * Case GET_SEARCH_HISTORY_ACTION
   * Fetches the search history from the cookie and updates the state.
   */
  builder.addCase(GET_SEARCH_HISTORY_ACTION, (state: ISearchHistoryState) => {
    const { searchItems } = current(state);

    const searchHistoryItems: ISearchHistoryState =
      CookieUtility.getCookie(SEARCH_HISTORY);

    if (searchItems.length === 0 && searchHistoryItems) {
      return { ...searchHistoryItems };
    }
  });

  /**
   * Case ADD_SEARCH_HISTORY_ACTION
   * Adds a search item to the search history cookie and redux state if the value
   * is not added already to the searchItems list.
   */
  builder.addCase(
    ADD_SEARCH_HISTORY_ACTION,
    (state: ISearchHistoryState, action: any) => {
      const currentSearchItem: string = action.payload;

      if (currentSearchItem) {
        const currentSearchHistory = current(state);

        const currentSearchItems = [...currentSearchHistory.searchItems];

        if (currentSearchItems.length >= 5) {
          currentSearchItems.pop();
        }

        if (currentSearchItems.indexOf(currentSearchItem) === -1) {
          currentSearchItems.unshift(currentSearchItem);
        }

        const searchHistoryState: ISearchHistoryState = {
          ...state,
          searchItems: currentSearchItems,
        };

        const expirationDate = new Date().setTime(
          new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        );

        CookieUtility.setCookies(
          SEARCH_HISTORY,
          searchHistoryState,
          expirationDate
        );

        return { ...searchHistoryState };
      }
    }
  );

  /**
   * Case CLEAR_SEARCH_HISTORY_ACTION
   * Clears the search history items from the redux state and the cookie.
   */
  builder.addCase(CLEAR_SEARCH_HISTORY_ACTION, (state: ISearchHistoryState) => {
    CookieUtility.deleteCookie(SEARCH_HISTORY);

    return { ...state, searchItems: [] };
  });
});

export { searchHistoryReducer };
