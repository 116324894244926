export const CATEGORY_LAYOUT_CONSTANTS = {
  BASE_IMAGE_URL: '/categories/heroes',
  TOKEN_NAME: 'CategoryToken',
  DEALS: 'deals',
  DEALS_CONTENT_ESPOT_1: [
    {
      id: 'deals_CHP_01A',
    },
    {
      id: 'deals_CHP_01B',
    },
  ],
  // CHP_02A and CHP_02B are already used in the CategoryHeroPageConstants.ts
  DEALS_CONTENT_ESPOT_3: [
    {
      id: 'deals_CHP_03A',
    },
    {
      id: 'deals_CHP_03B',
    },
  ],
  DEALS_CONTENT_ESPOT_4: [
    {
      id: 'deals_CHP_04A',
    },
    {
      id: 'deals_CHP_04B',
    },
  ],
};
