import { Box } from '@material-ui/core';
import React from 'react';

/**
 * @interface TabPanelProps
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * @component TabPanel.
 */
const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`nte-tabpanel-${index}`}
      aria-labelledby={`nte-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export { TabPanel };
