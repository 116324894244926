import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { REMOVE_PRODUCT_FROM_COMPARE_ACTION } from '../../redux/actions/productList.actions';
import { enterKeyPressHandler } from '../../utils/utils';
import { IProductListContents } from '../../_foundation/interface/ProductList/IProductList';
import { NteSwitch } from '../NteSwitch/NteSwitch';
import { ProductCard } from '../Widgets/Plp/ProductCard/ProductCard';
import { ProductCompareConstants } from '../Widgets/Plp/ProductCompareModal/ProductCompare.constants';
import { ProductCompareTable } from '../Widgets/Plp/ProductCompareTable.tsx/ProductCompareTable';
import { PdpPageConstants } from './PdpPageConstants';

/**
 * @interface IProductCompareDetails
 */
interface IProductCompareDetails {
  /**
   * @prop compareProducts to display compareProducts.
   */
  compareProducts: IProductListContents[];

  /**
   * @prop isModal is a flag to check if the product details are rendered in the modal
   */
  isModal?: boolean;
}

/**
 * @component ProductCompareDetails renders the product cards and product compare table for selected products
 */
const ProductCompareDetails: React.FC<IProductCompareDetails> = ({
  compareProducts,
  isModal = false,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { t } = useTranslation();

  const { TRANSLATIONS } = ProductCompareConstants;

  const { REMOVE, CURRENTLY_VIEWING_PRODUCT } = PdpPageConstants;

  const dispatch = useDispatch();

  const [ref, inView] = useInView();

  /**
   * @method productRemoveHandler removes the current product from comparison bar.
   */
  const productRemoveHandler = (id: string) => (): void => {
    dispatch(REMOVE_PRODUCT_FROM_COMPARE_ACTION({ id }));
  };

  return (
    <>
      <div className='scrollable-region' ref={ref}>
        <ul className='section-row product_compare_details product-list'>
          {compareProducts.map(
            (product: IProductListContents, index: number) => (
              <li
                key={index}
                className='product-card-compare nte-border-card'
                data-product-id={product.partNumber}
                data-brand-name={product?.manufacturer}
                role='group'
                aria-label='product'>
                {isModal && (
                  <Typography
                    variant='body2'
                    tabIndex={0}
                    role='link'
                    className='remove-link link-effect align-right'
                    onClick={productRemoveHandler(product.partNumber)}
                    onKeyDown={(e) =>
                      enterKeyPressHandler(
                        e,
                        productRemoveHandler(product.partNumber)
                      )
                    }>
                    {t(REMOVE)}
                  </Typography>
                )}

                {!isModal && index === 0 ? (
                  <Typography variant='h6'>
                    {t(CURRENTLY_VIEWING_PRODUCT)}
                  </Typography>
                ) : (
                  <div className='header-spacer'></div>
                )}

                <ProductCard
                  productCard={product}
                  isCompareProduct={true}
                  index={index}
                  isProductCompare={true}
                />
              </li>
            )
          )}
        </ul>

        <Typography variant='h2' className='prompt-margin'>
          {t(TRANSLATIONS.SPECIFICATIONS_TITLE)}
        </Typography>

        <div className='prompt-margin'>
          <NteSwitch
            setCheckedState={setIsChecked}
            checkedState={isChecked}
            label={t(TRANSLATIONS.HIGHLIGHT_DIFFERENCES)}
          />
        </div>

        <ProductCompareTable
          compareProducts={compareProducts}
          isChecked={isChecked}
        />
      </div>
      {inView && (
        <div className='swipe-prompt fb'>
          <ArrowBackIcon />
          <Typography variant='body2'>Swipe to Explore</Typography>
          <ArrowForwardIcon />
        </div>
      )}
    </>
  );
};

export { ProductCompareDetails };
