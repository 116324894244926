import { productsService } from '../../../_foundation/apis/search/products/products.service';

export const fetchItemDetailsByIds = async (param: any) => {
  try {
    const response = await productsService.fetchProductById(param);

    return response;
  } catch (e) {
    throw e;
  }
};
