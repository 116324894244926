import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  HIDE_BACKDROP,
  SHOW_BACKDROP,
} from '../../../redux/action-types/backdrop';

/**
 * @method useTriggerBackdrop dispatches state updates for backdrop component.
 *
 * @param showBackdrop Flag which determines to show back drop.
 * @param hideBackdrop Totally hides the backdrop even when the popover or modal is open.
 */
const useTriggerBackdrop = (showBackdrop: boolean, hideBackdrop?: boolean) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const triggerBackdrop = useCallback((): void => {
    if (showBackdrop) {
      dispatch({ type: SHOW_BACKDROP });
    } else {
      dispatch({ type: HIDE_BACKDROP });
    }
  }, [dispatch, showBackdrop]);

  useEffect(() => {
    if (!hideBackdrop) {
      triggerBackdrop();

      history.listen(() => {
        dispatch({ type: HIDE_BACKDROP });
      });
    }
  }, [dispatch, hideBackdrop, history, showBackdrop, triggerBackdrop]);
};

export { useTriggerBackdrop };
