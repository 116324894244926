import { useSelector } from 'react-redux';
import { siteSelector } from '../../../redux/selectors/site';
import { SiteInfo } from './SiteInfoService';

/**
 * @interface useSiteResponse Defines the response of the use Site hook.
 */
interface useSiteResponse {
  /**
   * Contains all site information to contact backend.
   */
  mySite: SiteInfo | any;
}

/**
 * @method useSite hook can be used in React components to fetch site information.
 * @returns site information to contact backend.
 */
const useSite = (): useSiteResponse => {
  const mySite: SiteInfo | any = useSelector(siteSelector);

  return { mySite };
};

export { useSite };
