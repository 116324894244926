import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
  ProductSuggestion,
  SiteContent,
} from '../../../interface/SearchSiteContent/ISearchSiteContent';
import { SuggestTypeSuggestionView } from '../../../interface/SearchSiteContent/ISearchSuggestionContent';
import { makeRequest } from '../../axios/axiosConfig';
import { SearchSiteContentServiceConstants } from './searchSiteContent.service.constants';

/**
 * @interface IFetchSearchTerm
 */
export interface IFetchSearchTerm {
  storeID: string;
  term: string;
  catalogId?: string;
  cancelSource?: CancelTokenSource;
}

/**
 * Responsible to fetch the suggestion for the given term
 */
const siteContentService = {
  async fetchSuggestionBySearchTerm({
    term,
    storeID,
  }: IFetchSearchTerm): Promise<SiteContent> {
    const { SITE_CONTENT_SERVICE_URL } = SearchSiteContentServiceConstants;

    const SITE_CONTENT_PATH = SITE_CONTENT_SERVICE_URL(storeID, term);

    try {
      const request: AxiosRequestConfig = {
        url: SITE_CONTENT_PATH,
        method: 'GET',
      };

      const siteResponse = await makeRequest(request);

      return siteResponse;
    } catch (error) {
      throw error;
    }
  },

  async fetchProductSuggestionBySearchTerm({
    term,
    storeID,
    cancelSource,
  }: IFetchSearchTerm): Promise<ProductSuggestion> {
    const { PRODUCT_SUGGESTION_SERVICE_URL } =
      SearchSiteContentServiceConstants;

    const PRODUCT_SUGGESTION_CONTENT_PATH = PRODUCT_SUGGESTION_SERVICE_URL(
      storeID,
      term
    );

    try {
      const request: AxiosRequestConfig = {
        url: PRODUCT_SUGGESTION_CONTENT_PATH,
        method: 'GET',
        cancelToken: cancelSource?.token,
      };

      const productSuggestionResponse = await makeRequest(request);

      return productSuggestionResponse;
    } catch (error) {
      throw error;
    }
  },

  async fetchSuggestTypeCategoriesAndBrands({
    storeID,
    term,
    cancelSource,
  }: IFetchSearchTerm): Promise<SuggestTypeSuggestionView> {
    const { SUGGEST_TYPE_URL } = SearchSiteContentServiceConstants;

    const SUGGEST_TYPE_CONTENT_PATH = SUGGEST_TYPE_URL(storeID, term);

    try {
      const request: AxiosRequestConfig = {
        url: SUGGEST_TYPE_CONTENT_PATH,
        method: 'GET',
        cancelToken: cancelSource?.token,
      };

      const suggestTypeResponse = await makeRequest(request);

      return suggestTypeResponse;
    } catch (error) {
      throw error;
    }
  },
};

export { siteContentService };
