import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCheckout } from '../../hooks/CheckoutHooks';
import { useCheckoutStepper } from './hooks/CheckoutStepperHooks';

/**
 * @component renders the progress stepper in the checkout header.
 */
const CheckoutStepper: React.FC = () => {
  const { t } = useTranslation();

  const { checkoutStep } = useCheckout();

  const { currentSteps } = useCheckoutStepper();

  /**
   * @method isLinkClickable is responsible for enabling previous links
   * based on their completion status and whether they have been visited previously.
   */
  const isLinkClickable = (index: number): boolean => {
    if (index === checkoutStep || index < checkoutStep) {
      return currentSteps[index].disabled;
    }

    return index > checkoutStep;
  };

  return (
    <Stepper activeStep={checkoutStep}>
      {currentSteps.map(({ label, route }, index) => {
        return (
          <Step key={label}>
            <StepLabel>
              <Typography variant='body2'>
                <Link
                  {...(isLinkClickable(index) && {
                    style: { pointerEvents: 'none' },
                  })}
                  to={route}>
                  {t(label)}
                </Link>
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CheckoutStepper;
