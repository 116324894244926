import { IAuthenticationState } from '../../_foundation/interface/Authentication/IAuth';
import { RootReducerState } from '../reducers';

const authenticationSelector = (
  state: RootReducerState
): IAuthenticationState => {
  return state.authentication;
};

export { authenticationSelector };
