import { createReducer, current } from '@reduxjs/toolkit';
import {
  CART_COUNT_BEFORE_MERGING,
  USER,
} from '../../_foundation/constants/cookie';
import { IAuthenticationState } from '../../_foundation/interface/Authentication/IAuth';
import {
  ICartSpinner,
  ICartState,
  IMergeCartCount,
  IOrderItem,
  IPaymentInstruction,
  IPromoSpinner,
} from '../../_foundation/interface/Cart/IOrder';
import ICartProductsResponse from '../../_foundation/interface/Responses/ICartProductsResponse';
import IShippingInfoResponse from '../../_foundation/interface/Responses/IShippingInfoResponse';
import { IGetUsableShippingState } from '../../_foundation/interface/Shipping/IUsableShipping';
import { PAYMENTTYPE } from '../../components/Checkout/CheckoutPayment/ICheckoutPayment';
import { checkTruthy, getLocalStorage, setCookie } from '../../utils/utils';
import {
  ALLOW_MULTIPLE_PAYMENTS_ACTION,
  ENABLE_DELIVERY_STEP_ACTION,
  FETCH_CART_DETAILS_SUCCESS_ACTION,
  FETCH_SHIPPING_INFO_SUCCESS_ACTION,
  GET_CART_PRODUCTS_SUCCESS_ACTION,
  GUEST_AND_CATALOG_ORDER_SUCCESS_ACTION,
  ORDER_CALCULATE_ACTION,
  PRECHECKOUT_CART_SUCCESS_ACTION,
  PROMO_APPLY_BUTTON_LOADING_ACTION,
  PROMO_REMOVE_BUTTON_LOADING_ACTION,
  REQUEST_ERROR_ACTION,
  RESET_CART_ACTION,
  RESET_CART_ITEMS_ACTION,
  RESET_GIFTCARD_ERROR_ACTION,
  RESET_GUEST_AND_CATALOG_ORDER_ACTION,
  SET_ADD_TO_CART_LOADING_ACTION,
  SET_BACKORDERED_STATE_ACTION,
  SET_CART_ITEMS_LOADING_ACTION,
  SET_CART_PAGE_LOADING_ACTION,
  SET_CART_SPINNER_ACTION,
  SET_CURRENT_PART_NUMBER_CART_ACTION,
  SET_GIFTCARD_ERROR_ACTION,
  SET_PAYMENT_ERROR_ACTION,
  SET_REMOVE_SPINNER_ACTION,
  SET_REPLACMENT_PLAN_SPINNER_ACTION,
  SET_SAVE_TO_LIST_SPINNER_ACTION,
  UPDATE_CHECKOUT_BILLING_ADDRESS_ACTION,
  UPDATE_GUEST_CONTACT_INFO_ACTION,
} from '../actions/order.actions';

const initUsableShippingInfo: IGetUsableShippingState = {
  loading: true,
  restrictedItems: [],
  usableShippingMethodData: [],
  usableShippingMode: [],
  sameDayDelivery: false,
  isBopisEligible: false,
  physicalStoreId: '',
  iomStoreId: '',
};

const initCartState: ICartState = {
  orderItem: [],
  replacementItems: [],
  noOfCartItems: 0,
  grandTotal: '',
  totalProductPrice: '',
  cartProducts: [],
  loading: true,
  cartItemsLoading: true,
  orderSplitWarning: false,
  promotionCode: [],
  shippingInfo: [],
  usableShippingInfo: initUsableShippingInfo,
  billingAddress: null,
  guestContactInfo: {
    updatedContacts: false,
    contacts: [],
  },
  orderId: '',
  totalAdjustment: '',
  addToCartLoading: {},
  shippingMethods: {
    method: '',
    time: '',
    cost: '',
    shippingId: '',
    shipModeCode: '',
  },
  usablePaymentInfo: {
    paymentInfo: [],
    loaded: false,
  },
  adjustment: [],
  currentPartNumber: '',
  showCartSpinner: {},
  showRemoveSpinner: {},
  showSaveToListSpinner: {},
  replacementPlanSpinner: {},
  promoRemoveSpinner: {},
  paymentInstruction: [],
  poNumber: '',
  recordSetCount: '',
  hasBackorderedItems: false,
  bulkOrder: {
    orderId: '',
    success_PartNumbers: [],
    errors: {},
  },
  maxLineItems: 'false',
  maxQuantity: 'false',
  x_field1: '',
  x_physicalStoreName: '',
  x_physicalStoreId: '',
  enableDeliveryStep: false,
  allowMultiplePayments: false,
  giftCardDeductedAmount: -1,
  freeItems: {},
  x_taxesAppliedToOrder: 'false',
  x_taxesInternationalShipCountry: 'false',
  x_userTaxExempt: 'false',
  giftCardError: false,
  x_isCartBopisEligible: '',
  x_surchargeAmount: '0',
  userSignedIn: false,
  x_liftGateChargesApplied: 'false',
  x_freightDiscount: '0',
  giftCardErrorMessage: '',
};

const orderReducer = createReducer(initCartState, (builder) => {
  /**
   * @action FETCH_CART_DETAILS_SUCCESS_ACTION
   * Fetches the orderitems Details
   */
  builder.addCase(
    FETCH_CART_DETAILS_SUCCESS_ACTION,
    (state: ICartState, action: any) => {
      const {
        orderItem,
        grandTotal,
        totalProductPrice,
        promotionCode,
        totalAdjustment,
        orderId,
        partNumber,
        paymentInstruction,
        orderSplitWarning,
        adjustment,
        poNumber,
        recordSetCount,
        maxLineItems,
        maxQuantity,
        x_field1,
        x_physicalStoreName,
        x_physicalStoreId,
        totalSalesTax,
        totalShippingTax,
        totalShippingCharge,
        x_taxesAppliedToOrder,
        x_taxesInternationalShipCountry,
        x_userTaxExempt,
        x_isCartBopisEligible,
        x_surchargeAmount,
        signInProcessed,
        x_freightDiscount,
      } = action.payload;

      /**
       * @var noOfCartItems is determined based on the quantity of items in the count.
       *
       * Example: If the cart has 2 items, Item A - 1 Qty and Item B - 2 Qty
       * The total number of items in the cart will be 3 since Item A has 1 and Item B has 2 Qty.
       */
      const noOfCartItems = orderItem
        ? orderItem.reduce(
            (itemCount: number, item: IOrderItem) => +item.quantity + itemCount,
            0
          )
        : 0;

      const {
        showCartSpinner,
        showSaveToListSpinner,
        showRemoveSpinner,
        addToCartLoading,
        freeItems,
      } = current(state);

      let currentFreeItems = {};

      orderItem &&
        orderItem.forEach(({ freeGift, orderItemId }: IOrderItem) => {
          if (checkTruthy(freeGift)) {
            currentFreeItems = {
              ...freeItems,
              ...currentFreeItems,
              [orderItemId]: true,
            };
          }
        });

      const currentCartSpinners = { ...showCartSpinner };

      const currentSaveToListSpinner = { ...showSaveToListSpinner };

      const currentRemoveSpinner = { ...showRemoveSpinner };

      const currentAddToCartLoader = { ...addToCartLoading };

      delete currentAddToCartLoader[partNumber];

      delete currentCartSpinners[partNumber];

      delete currentSaveToListSpinner[partNumber];

      delete currentRemoveSpinner[partNumber];

      if (orderItem) {
        const replacementItems: IOrderItem[] = [];

        const orderItems: IOrderItem[] = [];

        /**
         * Retrive regular cart items and the replacement items.
         */
        orderItem.map((item: IOrderItem, index: number) => {
          if (item?.comments) {
            replacementItems.push(orderItem[index]);
          } else {
            orderItems.push(orderItem[index]);
          }

          return '';
        });

        const authenticationCookie: IAuthenticationState =
          getLocalStorage(USER);

        const { isGuest } = authenticationCookie;

        if (isGuest) {
          const mergeCartCookie: IMergeCartCount = {
            guestCartCount: noOfCartItems,
          };

          setCookie(CART_COUNT_BEFORE_MERGING, mergeCartCookie);
        }

        /**
         * This block of code is responsible for calculating the amount
         * deducted from grand total after applying gift card.
         */
        let giftCardDeductedAmount = -1;

        /**
         * By default allow multiple payments is set to false.
         *
         * This allows the regular payment flow by deleting the pi and add the new pi.
         */
        let allowMultiplePayments = false;

        if (paymentInstruction && paymentInstruction.length !== 0) {
          /**
           * Check if the payment instruction already has any gift card applied.
           */
          const hasGiftCard = paymentInstruction.findIndex(
            ({ piDescription }: IPaymentInstruction) =>
              piDescription === PAYMENTTYPE.GIFT_CARD
          );

          if (hasGiftCard !== -1) {
            allowMultiplePayments = true;

            let deductedAmount: number = 0;

            paymentInstruction.forEach(
              ({ piAmount }: IPaymentInstruction) =>
                (deductedAmount = Number(piAmount) + deductedAmount)
            );

            /**
             * Calculate the difference amount between grand total and the gift card amounts.
             */
            giftCardDeductedAmount = Math.abs(
              Number(grandTotal) - Number(deductedAmount)
            );
          }
        }

        /**
         * If the gift card pays for the entire grandTotal disable multiple payments.
         */
        if (giftCardDeductedAmount === 0) {
          allowMultiplePayments = false;
        }

        return {
          ...state,
          orderItem: orderItems,
          replacementItems,
          noOfCartItems,
          grandTotal: grandTotal,
          totalProductPrice: totalProductPrice,
          promotionCode: promotionCode,
          totalAdjustment: totalAdjustment,
          paymentInstruction,
          orderId,
          addToCartLoading: currentAddToCartLoader,
          showCartSpinner: currentCartSpinners,
          loading: false,
          showRemoveSpinner: currentRemoveSpinner,
          showSaveToListSpinner: currentSaveToListSpinner,
          orderSplitWarning: orderSplitWarning,
          adjustment: adjustment ? adjustment : [],
          poNumber: poNumber,
          recordSetCount: recordSetCount,
          maxLineItems: maxLineItems ? maxLineItems : state.maxLineItems,
          maxQuantity: maxQuantity ? maxQuantity : state.maxQuantity,
          x_field1: x_field1,
          x_physicalStoreName: x_physicalStoreName,
          x_physicalStoreId: x_physicalStoreId,
          giftCardDeductedAmount,
          totalSalesTax: totalSalesTax,
          totalShippingTax: totalShippingTax,
          totalShippingCharge: totalShippingCharge,
          x_surchargeAmount: x_surchargeAmount,
          x_freightDiscount: x_freightDiscount,
          /**
           * Allow multiple payments if we have a gift card
           * applied and it doesn't covers the entire cart balance.
           */
          allowMultiplePayments,
          freeItems: currentFreeItems,
          x_taxesAppliedToOrder: x_taxesAppliedToOrder
            ? x_taxesAppliedToOrder
            : 'false',
          x_taxesInternationalShipCountry: x_taxesInternationalShipCountry
            ? x_taxesInternationalShipCountry
            : 'false',
          x_userTaxExempt: x_userTaxExempt ? x_userTaxExempt : 'false',
          x_isCartBopisEligible,
          userSignedIn: signInProcessed,
        };
      } else {
        return {
          ...initCartState,
          orderId,
          loading: false,
          userSignedIn: signInProcessed,
        };
      }
    }
  );

  builder.addCase(
    GUEST_AND_CATALOG_ORDER_SUCCESS_ACTION,
    (state: ICartState, action: any) => {
      const response = action.payload;

      return {
        ...state,
        bulkOrder: response,
      };
    }
  );

  /**
   * @action REQUEST_ERROR_ACTION
   * sets the state for showError which is used to show error message in PromoCode component.
   */
  builder.addCase(REQUEST_ERROR_ACTION, (state: ICartState, action: any) => {
    const response = action.payload;
    return {
      ...state,
      showError: response,
    };
  });

  /**
   * @action PROMO_APPLY_BUTTON_LOADING_ACTION
   * used to set the state for Apply Button which is used to display loader for apply button in PromoCode component
   */
  builder.addCase(
    PROMO_APPLY_BUTTON_LOADING_ACTION,
    (state: ICartState, action: any) => {
      const response = action.payload;

      return {
        ...state,
        applyButton: response,
      };
    }
  );

  /**
   * @action PROMO_REMOVE_BUTTON_LOADING_ACTION
   * used to set the state for Remove Button which is used to display loader for remove button in PromoCode component
   */

  builder.addCase(
    PROMO_REMOVE_BUTTON_LOADING_ACTION,
    (state: ICartState, action: any) => {
      const { loading, promoCode } = action.payload as IPromoSpinner;

      return {
        ...state,
        promoRemoveSpinner: {
          ...state.promoRemoveSpinner,
          [promoCode]: loading,
        },
      };
    }
  );

  builder.addCase(RESET_GUEST_AND_CATALOG_ORDER_ACTION, (state: ICartState) => {
    return {
      ...state,
      bulkOrder: initCartState.bulkOrder,
    };
  });

  builder.addCase(RESET_CART_ACTION, () => {
    return { ...initCartState };
  });

  builder.addCase(
    GET_CART_PRODUCTS_SUCCESS_ACTION,
    (state: ICartState, action: any) => {
      const { contents } = action.payload as ICartProductsResponse;

      return {
        ...state,
        cartProducts: contents,
        loading: false,
        cartItemsLoading: false,
      };
    }
  );

  builder.addCase(
    SET_CART_PAGE_LOADING_ACTION,
    (state: ICartState, action: any) => {
      return { ...state, loading: action.payload };
    }
  );

  builder.addCase(
    FETCH_SHIPPING_INFO_SUCCESS_ACTION,
    (state: ICartState, action: any) => {
      const { products } = action.payload as IShippingInfoResponse;

      return { ...state, shippingInfo: products };
    }
  );

  builder.addCase(
    UPDATE_CHECKOUT_BILLING_ADDRESS_ACTION,
    (state: ICartState, action: any) => {
      const billingAddress = action.payload;

      return { ...state, billingAddress };
    }
  );

  builder.addCase(
    UPDATE_GUEST_CONTACT_INFO_ACTION,
    (state: ICartState, action: any) => {
      return {
        ...state,
        guestContactInfo: {
          updatedContacts: true,
          contacts: action.payload,
        },
      };
    }
  );

  builder.addCase(
    SET_ADD_TO_CART_LOADING_ACTION,
    (state: ICartState, action: any) => {
      const { loading, partNumber } = action.payload as ICartSpinner;

      return {
        ...state,
        addToCartLoading: { ...state.addToCartLoading, [partNumber]: loading },
      };
    }
  );

  builder.addCase(
    SET_CURRENT_PART_NUMBER_CART_ACTION,
    (state: ICartState, action: any) => {
      return { ...state, currentPartNumber: action.payload };
    }
  );

  /**
   * CASE SET_CART_SPINNER_ACTION
   * Sets the loading state for the cart quantity based on the current product id.
   */
  builder.addCase(SET_CART_SPINNER_ACTION, (state: ICartState, action: any) => {
    const { loading, partNumber } = action.payload as ICartSpinner;

    const { showCartSpinner } = current(state);

    let currentShowCartSpinner = { ...showCartSpinner };

    if (!loading && currentShowCartSpinner[partNumber]) {
      delete currentShowCartSpinner[partNumber];
    } else {
      currentShowCartSpinner = {
        ...currentShowCartSpinner,
        [partNumber]: loading,
      };
    }

    return {
      ...state,
      showCartSpinner: currentShowCartSpinner,
    };
  });

  /**
   * CASE SET_REMOVE_SPINNER_ACTION
   * Sets the loading state for the remove button for each cart item based on their product id.
   */
  builder.addCase(
    SET_REMOVE_SPINNER_ACTION,
    (state: ICartState, action: any) => {
      const { loading, partNumber } = action.payload as ICartSpinner;

      const { showRemoveSpinner } = current(state);

      let currentShowRemoveSpinner = { ...showRemoveSpinner };

      if (!loading && currentShowRemoveSpinner[partNumber]) {
        delete currentShowRemoveSpinner[partNumber];
      } else {
        currentShowRemoveSpinner = {
          ...currentShowRemoveSpinner,
          [partNumber]: loading,
        };
      }

      return {
        ...state,
        showRemoveSpinner: currentShowRemoveSpinner,
      };
    }
  );

  /**
   * CASE SET_SAVE_TO_LIST_SPINNER_ACTION
   * Sets the loading state for Save to list inside cart item card based on it's product id.
   */
  builder.addCase(
    SET_SAVE_TO_LIST_SPINNER_ACTION,
    (state: ICartState, action: any) => {
      const { loading, partNumber } = action.payload as ICartSpinner;

      const { showSaveToListSpinner } = current(state);

      let currentShowSaveToListSpinner = { ...showSaveToListSpinner };

      if (!loading && currentShowSaveToListSpinner[partNumber]) {
        delete currentShowSaveToListSpinner[partNumber];
      } else {
        currentShowSaveToListSpinner = {
          ...currentShowSaveToListSpinner,
          [partNumber]: loading,
        };
      }

      return {
        ...state,
        showSaveToListSpinner: currentShowSaveToListSpinner,
      };
    }
  );

  /**
   * CASE SET_REPLACMENT_PLAN_SPINNER_ACTION
   * Sets the loading state for Add to cart / remove button for replacement plan based
   * on it parent product id.
   */
  builder.addCase(
    SET_REPLACMENT_PLAN_SPINNER_ACTION,
    (state: ICartState, action: any) => {
      const { loading, partNumber } = action.payload as ICartSpinner;

      const { replacementPlanSpinner } = current(state);

      let currentReplacementPlanSpinner = { ...replacementPlanSpinner };

      if (!loading && currentReplacementPlanSpinner[partNumber]) {
        delete currentReplacementPlanSpinner[partNumber];
      } else {
        currentReplacementPlanSpinner = {
          ...currentReplacementPlanSpinner,
          [partNumber]: loading,
        };
      }

      return {
        ...state,
        replacementPlanSpinner: currentReplacementPlanSpinner,
      };
    }
  );

  builder.addCase(
    SET_CART_ITEMS_LOADING_ACTION,
    (state: ICartState, action: any) => {
      return { ...state, cartItemsLoading: action.payload };
    }
  );

  builder.addCase(PRECHECKOUT_CART_SUCCESS_ACTION, (_, action: any) => {
    return { ...initCartState };
  });

  builder.addCase(RESET_CART_ITEMS_ACTION, (state: ICartState) => {
    return { ...state, cartItemsLoading: true, cartProducts: [] };
  });

  builder.addCase(
    SET_BACKORDERED_STATE_ACTION,
    (state: ICartState, action: any) => {
      return { ...state, hasBackorderedItems: action.payload };
    }
  );

  builder.addCase(ORDER_CALCULATE_ACTION, (state: ICartState, action: any) => {
    return {
      ...state,
      orderId: action.payload.storeID,
    };
  });

  builder.addCase(
    ENABLE_DELIVERY_STEP_ACTION,
    (state: ICartState, action: any) => {
      return { ...state, enableDeliveryStep: action.payload };
    }
  );

  builder.addCase(
    ALLOW_MULTIPLE_PAYMENTS_ACTION,
    (state: ICartState, action: any) => {
      return {
        ...state,
        allowMultiplePayments: action.payload,
      };
    }
  );

  builder.addCase(
    SET_PAYMENT_ERROR_ACTION,
    (state: ICartState, action: any) => {
      return {
        ...state,
        giftCardError: action.payload,
      };
    }
  );
  builder.addCase(
    SET_GIFTCARD_ERROR_ACTION,
    (state: ICartState, action: any) => {
      return {
        ...state,
        giftCardError: action.payload.giftCardError,
        giftCardErrorMessage: action.payload.giftCardErrorMessage || '',
      };
    }
  );

  builder.addCase(RESET_GIFTCARD_ERROR_ACTION, (state: ICartState) => {
    return {
      ...state,
      giftCardError: false,
      giftCardErrorMessage: '',
    };
  });
});

export { orderReducer };
