export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const GUEST_LOGIN_REQUESTED = 'GUEST_LOGIN_REQUESTED';
export const GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCEEDED = 'UPDATE_PASSWORD_SUCCEEDED';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';
export const UPDATE_USER_INFO_SUCCEEDED = 'UPDATE_USER_INFO_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const SIGN_OUT = 'SIGN_OUT';
export const INVALID_USER_CREDENTIALS = 'INVALID_USER_CREDENTIALS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const RESET_REGISTRATION_ERRORS = 'RESET_REGISTRATION_ERRORS';
export const GET_USER_CONTACT_INFO = 'GET_USER_CONTACT_INFO';
export const GET_USER_CONTACT_INFO_SUCCEEDED =
  'GET_USER_CONTACT_INFO_SUCCEEDED';
export const GET_RECENT_ORDERS = 'GET_RECENT_ORDERS';
export const GET_RECENT_ORDERS_SUCCESS = 'GET_RECENT_ORDERS_SUCCESS';
export const UPDATE_USER_REGISTRATION = 'UPDATE_USER_REGISTRATION';
export const UPDATE_USER_REGISTRATION_SUCCESS =
  'UPDATE_USER_REGISTRATION_SUCCESS';
export const UPDATE_USER_REGISTRATION_ERROR = 'UPDATE_USER_REGISTRATION_ERROR';
export const RESET_UPDATE_USER_REGISTRATION_ERROR =
  'RESET_UPDATE_USER_REGISTRATION_ERROR';
export const ADD_USER_SAVED_CARD_DETAILS = 'ADD_SAVED_CARD_DETAILS';
export const UPDATE_USER_SAVED_CARD_DETAILS = 'UPDATE_SAVED_CARD_DETAILS';
export const FETCH_USER_SAVED_CARD_DETAILS = 'FETCH_SAVED_CARD_DETAILS';
export const ERROR_UPDATING_SAVED_CARD_DETAILS =
  'ERROR_UPDATING_SAVED_CARD_DETAILS';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
