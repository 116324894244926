import { createReducer } from '@reduxjs/toolkit';
import { IAssests } from '../../components/Widgets/Orders/OrderDetail/OrderBody/OrderItem/OrderItem';
import { checkTruthy } from '../../utils/utils';
import { IProductAssets } from '../../_foundation/apis/pdp/product-detail.service';
import {
  RESET_ASSETS_ACTION,
  UPDATE_ASSETS_ACTION,
} from '../actions/productAssets.actions';

/**
 * initial state of the productAssets
 */
const productAssets: IProductAssets = {
  images: [],
  pdfs: [],
};

/**
 * this is the reducer for storing BV reviews and BC videos from pdp pages.
 */
const productAssetsReducer = createReducer(productAssets, (builder) => {
  builder.addCase(
    UPDATE_ASSETS_ACTION,
    (state: IProductAssets, action: any) => {
      const productThumbnailImage: IAssests[] = action.payload.images;

      const sortedThumbnailImage = productThumbnailImage
        .sort((a, b) => a.sequence - b.sequence)
        .filter(
          (data) => !checkTruthy(data.primaryFlag) && data.name.length > 0
        );

      const primaryImage = productThumbnailImage.find((data) =>
        checkTruthy(data.primaryFlag)
      );

      return {
        ...state,
        images: primaryImage
          ? [primaryImage, ...sortedThumbnailImage]
          : [...sortedThumbnailImage],
        pdfs: action.payload.pdfs,
      };
    }
  );

  builder.addCase(RESET_ASSETS_ACTION, (state: IProductAssets) => {
    return { ...productAssets };
  });
});

export { productAssetsReducer };
