const PlpPageConstants = {
  PLP_ESPOT_1: {
    espotId: 'PLP_01',
  },
  PLP_ESPOT_1A: {
    espotId: 'PLP_01A',
  },
  PLP_ESPOT_2: {
    espotId: 'PLP_02',
  },
  SEO_SUB_HEAD: {
    espotId: 'PLP_03',
  },
  BRAND_ATTRIBUTE: 'Brand',
  CONSOLE_WARNING: 'Keyword meta tag could not be generated due to failure.',
  BRAND_DESCRIPTION: {
    TEXT_ONE: 'PRODUCT_LIST_PAGE.META_TAGS.BRAND_DESCRIPTION.TEXT_ONE',
    TEXT_TWO: 'PRODUCT_LIST_PAGE.META_TAGS.BRAND_DESCRIPTION.TEXT_TWO',
  },
  CATEGORY_DESCRIPTION: {
    TEXT_ONE: 'PRODUCT_LIST_PAGE.META_TAGS.CATEGORY_DESCRIPTION.TEXT_ONE',
    TEXT_TWO: 'PRODUCT_LIST_PAGE.META_TAGS.CATEGORY_DESCRIPTION.TEXT_TWO',
    TEXT_THREE: 'PRODUCT_LIST_PAGE.META_TAGS.CATEGORY_DESCRIPTION.TEXT_THREE',
    TEXT_FOUR: 'PRODUCT_LIST_PAGE.META_TAGS.CATEGORY_DESCRIPTION.TEXT_FOUR',
  },
};

export { PlpPageConstants };
