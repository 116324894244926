import { removeQueryParamBackslash } from '../../../../utils/utils';

const BASE_URL = '/search/resources/store/';

const SearchSiteContentServiceConstants = {
  SITE_CONTENT_SERVICE_URL: (storeID: string, term: string) =>
    `${BASE_URL}${storeID}/sitecontent/keywordSuggestionsByTerm/${encodeURIComponent(
      removeQueryParamBackslash(term)
    )}`,

  SEARCH_PRODUCTS_SERVICE_URL: (storeID: string, term: string) =>
    `${BASE_URL}${storeID}/productview/bySearchTerm/*?searchTerm=${encodeURIComponent(
      removeQueryParamBackslash(term)
    )}&_wcf.search.filter.expr=buyable:true&profileName=${
      process.env.REACT_APP_SEARCH_BY_SEARCH_TERM_PROFILE_NAME
    }`,

  PRODUCT_SUGGESTION_SERVICE_URL: (storeId: string, term: string) =>
    `${BASE_URL}${storeId}/sitecontent/productSuggestionsBySearchTerm/${encodeURIComponent(
      removeQueryParamBackslash(term)
    )}?profileName=${
      process.env.REACT_APP_FIND_NAVIGATION_SUGGESTION_PRODUCTS
    }`,

  SUGGEST_TYPE_URL: (storeID: string, searchTerm: string) =>
    `${BASE_URL}${storeID}/sitecontent/suggestions?suggestType=Category&langId=-1&searchTerm=${encodeURIComponent(
      removeQueryParamBackslash(searchTerm)
    )}`,

  SEARCH_DISPLAY_SERVICE_URL: (
    storeID: string,
    term: string,
    catalogId: string
  ) =>
    `/wcs/resources/store/${storeID}/searchdisplay/bySearchTerm?searchTerm=${encodeURIComponent(
      removeQueryParamBackslash(term)
    )}&${catalogId && `catalogId=${catalogId}`}&langId=-1`,
};

export { SearchSiteContentServiceConstants };
