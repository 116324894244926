import { AxiosRequestConfig } from 'axios';
import IWishListResponse from '../../../interface/Responses/IWishlistResponse';
import validateIWishlistResponse from '../../../interface/Responses/IWishlistResponse.validator';
import {
  IAddItemToWishList,
  IAddWishListRequest,
  IAddWishListResponse,
  IDeleteRemoveWishListRequest,
  IFetchSharedWishList,
  IShareListRequest,
  IWishListProductRequest,
  IWishListRequest,
} from '../../../interface/WishList/IWishList';
import { makeRequest } from '../../axios/axiosConfig';
import { WishListServiceConstants } from './wish-list.service.constants';

const wishListService = {
  /**
   * @method fetchWishList Fetches the wishlist items from the backend service.
   *
   * @param IWishListRequest
   */
  async fetchWishList({
    storeID,
  }: IWishListRequest): Promise<IWishListResponse> {
    const { GET_WISH_LIST_URL } = WishListServiceConstants;

    const wishListRequest: AxiosRequestConfig = {
      url: GET_WISH_LIST_URL(storeID),
      method: 'GET',
    };

    const wishListResponse: IWishListResponse = validateIWishlistResponse(
      await makeRequest(wishListRequest)
    );

    return wishListResponse;
  },

  /**
   * @method createAddWishListItem Creates a new wishlist item or add an new item into wishlist.
   *
   * @param IAddWishListRequest
   */
  async createAddWishListItem({
    currentItemId,
    currentProduct,
    storeID,
    item,
  }: IAddWishListRequest): Promise<IAddWishListResponse> {
    const { ADD_DELETE_WISH_LIST } = WishListServiceConstants;

    const isAddItem = Boolean(currentItemId && currentProduct);

    const wishListBody = isAddItem
      ? {
          item: [
            {
              partNumber: currentProduct?.partNumber,
              quantityRequested: '1',
              location: 'online',
              productId: currentProduct?.uniqueID,
            },
          ],
        }
      : {
          descriptionName: item,
          description: item,
          ...{
            ...(currentProduct?.partNumber &&
              (currentProduct?.uniqueID ||currentProduct?.id ) && {
                item: [
                  {
                    partNumber: currentProduct?.partNumber,
                    quantityRequested: '1',
                    location: 'online',
                    productId: currentProduct?.uniqueID ||currentProduct?.id,
                  },
                ],
              }),
          },
        };

    const wishListRequest: AxiosRequestConfig = {
      url: ADD_DELETE_WISH_LIST(
        storeID,
        currentItemId ? currentItemId : '',
        isAddItem
      ),
      method: currentItemId ? 'PUT' : 'POST',
      data: wishListBody,
    };

    const wishListResponse: IAddWishListResponse = await makeRequest(
      wishListRequest
    );

    return wishListResponse;
  },

  /**
   * @method addItemToWishList
   * Responsible to add the item to save to later wishlist
   * @param IAddItemToWishList
   */
  async addItemToWishList({
    storeID,
    item,
    partNumber,
    wishListItemId,
    itemQuantity,
  }: IAddItemToWishList): Promise<any> {
    const { ADD_ITEM_TO_WISHLIST } = WishListServiceConstants;

    const wishListBodyPayload = {
      item: [
        {
          partNumber: partNumber,
          quantityRequested: itemQuantity,
          location: 'online',
        },
      ],
    };

    const addItemToWishListRequest: AxiosRequestConfig = {
      url: ADD_ITEM_TO_WISHLIST(storeID, wishListItemId),
      method: 'PUT',
      data: wishListBodyPayload,
    };

    const addItemToWishListResponse: IAddWishListResponse = await makeRequest(
      addItemToWishListRequest
    );

    return addItemToWishListResponse;
  },

  /**
   * @method deleteRemoveWishListItem Deletes an entire list or removes a specific item from the list.
   *
   * @param IDeleteRemoveWishListRequest
   */
  async deleteRemoveWishListItem({
    wishListId,
    itemId,
    productId,
    storeID,
  }: IDeleteRemoveWishListRequest): Promise<any> {
    const { DELETE_WISH_LIST } = WishListServiceConstants;

    const wishListRequest: AxiosRequestConfig = {
      url: DELETE_WISH_LIST(storeID, wishListId),
      method: 'DELETE',
      params: {
        itemId,
        productId,
      },
    };

    const wishListResponse: IAddWishListResponse = await makeRequest(
      wishListRequest
    );

    return wishListResponse;
  },

  /**
   * @method shareWishList Makes request to share the wishlist.
   *
   * @param IShareListRequest
   */
  async shareWishList({
    announcement,
    giftListId,
    storeID,
    x_reCaptchaToken,
  }: IShareListRequest): Promise<any> {
    try {
      const { SHARE_WISH_LIST } = WishListServiceConstants;

      const shareListRequest: AxiosRequestConfig = {
        url: SHARE_WISH_LIST(storeID, giftListId),
        method: 'POST',
        data: {
          announcement,
          x_reCaptchaToken,
        },
      };

      const shareListResponse: any = await makeRequest(shareListRequest);

      return shareListResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchWishListPlp Fetches the plp items under a specific wishlist.
   *
   * @param IWishListProductRequest
   */
  async fetchWishListPlp({
    storeID,
    pageNumber,
    pageSize,
    wishListId,
  }: IWishListProductRequest): Promise<any> {
    try {
      if (pageNumber && pageSize && wishListId) {
        const { FETCH_WISH_LIST_PLP } = WishListServiceConstants;

        const fetchWishListPlpRequest: AxiosRequestConfig = {
          url: FETCH_WISH_LIST_PLP(storeID, wishListId, pageNumber, pageSize),
        };

        const fetchWishListPlpResponse = await makeRequest(
          fetchWishListPlpRequest
        );

        return fetchWishListPlpResponse;
      }
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchSharedListDetails Fetches the shared wishlist data from the BE.
   */
  async fetchSharedListDetails({
    guestAccessKey,
    id,
    storeID,
    cancelToken,
    pageNumber,
    pageSize,
  }: IFetchSharedWishList): Promise<any> {
    try {
      const { FETCH_SHARED_WISHLIST_DETAILS } = WishListServiceConstants;

      const fetchSharedListDetailsRequest: AxiosRequestConfig = {
        url: FETCH_SHARED_WISHLIST_DETAILS(storeID, id),
        params: {
          guestAccessKey,
          pageNumber,
          pageSize,
        },
        cancelToken,
      };

      const fetchSharedListDetailsResponse = await makeRequest(
        fetchSharedListDetailsRequest
      );

      return fetchSharedListDetailsResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { wishListService };
