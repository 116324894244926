export const FETCH_ORDER_CONFIRMATION_ACTION_REQUEST =
  'FETCH_ORDER_CONFIRMATION_ACTION_REQUEST';
export const FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION =
  'FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION';
export const SET_ORDER_CONFIRMATION_LOADING_ACTION_REQUEST =
  'SET_ORDER_CONFIRMATION_LOADING_ACTION_REQUEST';
export const SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION_REQUEST =
  'SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION_REQUEST';
export const RESET_FETCH_CART_ITEM_DETAILS = 'RESET_FETCH_ORDER_DETAILS';
export const RESET_ORDER_CONFIRMATION_ACTION_REQUEST =
  'RESET_ORDER_CONFIRMATION_ACTION_REQUEST';
export const SET_ORDER_CONFIRMATION_ERROR_ACTION_REQUEST =
  'SET_ORDER_CONFIRMATION_ERROR_ACTION_REQUEST';
export const ADD_PO_NUMBER_ACTION_REQUEST = 'ADD_PO_NUMBER_ACTION_REQUEST';
export const UPDATE_DELIVERY_SHIPPING_METHOD_DATA =
  'UPDATE_DELIVERY_SHIPPING_METHOD_DATA';
export const FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION =
  'FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION';
