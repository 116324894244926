import { Box, PopperPlacementType } from '@material-ui/core';
import FocusTrap from 'focus-trap-react';
import React, { useState } from 'react';
import { useEscapeHandler } from '../../../../utils/hooks/escape-handler/EscapeHandler';
import { useTriggerBackdrop } from '../../../../utils/hooks/trigger-backdrop/TriggerBackdrop';
import { NtePopper } from '../NtePopper';

/**
 * @interface NtePopperProps
 */
interface NtePopperProps {
  /**
   * @prop anchor Pass in the reference of the anchor element
   * with which the popper will be rendered as a reference.
   */
  anchor: React.MutableRefObject<any>;

  /**
   * @prop children Children elements that needs to be rendered
   * inside the NTE popper wrapper.
   */
  children: JSX.Element;

  /**
   * @prop ntePopperCloseHandler is responsible for closing
   * popper component.
   */
  ntePopperCloseHandler: () => void;

  /**
   * @prop showPopper flag is responsible for displaying popper.
   */
  showPopper: any;

  /**
   * @prop flip flag flips the popper when the popper gets clipped from the screen.
   */
  flip?: boolean;
}

/**
 * @interface NteHookProps
 */
interface NteHookProps {
  /**
   * @prop id for the Nte popper component.
   */
  id: string;

  /**
   * @prop dismissHandler will be called once
   * the Nte popper is closed.
   */
  dismissHandler?: () => void;

  /**
   * @prop mountHandler will be called once
   * the Nte popper is opened.
   */
  mountHandler?: () => void;

  /**
   * @prop focusTrap adds focus trap for tabbing elements inside the menu.
   */
  focusTrap?: boolean;

  /**
   * @prop popperPlacement Indicates the placement of the popper with respect
   * to it's anchor element.
   */
  popperPlacement?: PopperPlacementType;

  /**
   * @prop ariaLabelledById for NtePopper Hooks.
   */
  ariaLabelledById?: string;

  /**
   * @prop className for NtePopper Wrapper.
   */
  className?: string;

  /**
   * @prop hideBackdrop Totally hides the backdrop even when the popover or modal is open.
   */
  hideBackdrop?: boolean;

  /**
   * @prop disable portal flag disables the react portal functionality of the popper
   * https://reactjs.org/docs/portals.html
   * https://mui.com/material-ui/api/popper/
   */
  disablePortal?: boolean;
}

/**
 * @hooks useNtePopper is responsible for rendering, handling click events
 * and displaying the Nte Popper component.
 *
 * @object object of type NteHookProps,
 * @param dismissHandler dismissHandler will be called once the Nte popper is closed.
 * @param id id for the Nte Popper component.
 */
const useNtePopper = ({
  dismissHandler,
  id,
  mountHandler,
  focusTrap = true,
  popperPlacement = 'bottom',
  ariaLabelledById,
  className = '',
  hideBackdrop,
  disablePortal,
}: NteHookProps) => {
  const [showPopper, setShowPopper] = useState(false);

  /**
   * Trigger backdrop state changes whenever
   *  the popper get opened or closed.
   */
  useTriggerBackdrop(showPopper, hideBackdrop);

  /**
   * @callback closePopperOnEscape closes the popper component.
   */
  const closePopperOnEscape = (): void => setShowPopper(false);

  useEscapeHandler(closePopperOnEscape);

  /**
   * @callback ntePopperClickHandler handles showing/hiding the nte
   * popper when the user performs a desired click operation.
   */
  const ntePopperClickHandler = (): void =>
    setShowPopper((showPopper) => !showPopper);

  /**
   * @callback ntePopperMountHandler triggers the mountHandler from
   * the Nte popper component.
   */
  const ntePopperMountHandler = (): void => {
    if (mountHandler) {
      mountHandler();
    }
  };

  /**
   * @callback ntePopperDismissHandler triggers the dismissHandler from
   * the Nte popper component.
   */
  const ntePopperDismissHandler = (): void => {
    if (dismissHandler) {
      dismissHandler();
    }
  };

  /**
   * @wrapperComponent RenderPopper wrapper contains the NtePopper component's
   * UI implementation, this wrapper can be used
   * to instantiate the Nte popper.
   */
  const RenderPopper: React.FC<NtePopperProps> = ({
    anchor,
    children,
    ntePopperCloseHandler,
    showPopper,
    flip = false,
  }) => (
    <NtePopper
      anchor={anchor}
      className={className}
      closeHandler={ntePopperCloseHandler}
      dismissHandler={ntePopperDismissHandler}
      id={id}
      popperOnMount={ntePopperMountHandler}
      popperPlacement={popperPlacement}
      ariaLabelledById={ariaLabelledById}
      showPopper={showPopper}
      flip={flip}
      disablePortal={disablePortal}>
      {focusTrap ? (
        <FocusTrap>
          <Box className={`content-wrapper ${className}`}>{children}</Box>
        </FocusTrap>
      ) : (
        children
      )}
    </NtePopper>
  );

  return { ntePopperClickHandler, showPopper, RenderPopper, setShowPopper };
};

export { useNtePopper };
