import { createAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../action-types/search.action-types';

const UPDATE_SEARCH_KEYWORD_ACTION = createAction<string>(
  ACTION_TYPES.UPDATE_SEARCH_KEYWORD
);

const RESET_SEARCH_KEYWORD_ACTION = createAction(
  ACTION_TYPES.RESET_SEARCH_KEYWORD
);

export { UPDATE_SEARCH_KEYWORD_ACTION, RESET_SEARCH_KEYWORD_ACTION };
