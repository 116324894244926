import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../actions/wish-list.actions';
import * as WORKERS from '../workers/wish-list.workers';

export function* watchSaga() {
  yield takeLatest(ACTION_TYPES.GET_WISH_LIST_ACTION, WORKERS.fetchWishList);

  yield takeLatest(
    ACTION_TYPES.ADD_REMOVE_WISH_LIST_ACTION,
    WORKERS.createAddWishListItem
  );

  yield takeEvery(
    ACTION_TYPES.DELETE_REMOVE_WISH_LIST_ACTION,
    WORKERS.deleteRemoveWishListItem
  );

  yield takeEvery(
    ACTION_TYPES.FETCH_WISH_LIST_PRODUCTS_ACTION,
    WORKERS.fetchWishListProducts
  );

  yield takeLatest(ACTION_TYPES.SHARE_WISH_LIST_ACTION, WORKERS.shareWishList);

  yield takeLatest(
    ACTION_TYPES.FETCH_WISH_LIST_ITEMS_ACTION,
    WORKERS.fetchWishListPlp
  );

  yield takeEvery(
    ACTION_TYPES.ADD_ITEM_TO_WISHLIST_ACTION,
    WORKERS.addItemToWishList
  );

  yield takeEvery(
    ACTION_TYPES.FETCH_SHARED_WISHLIST_DETAILS_ACTION,
    WORKERS.fetchSharedWishlistDetails
  );
}
