import { Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  MY_STORE_COOKIE,
  NEAR_BY_STORES,
} from '../../../../../../../../_foundation/constants/cookie';
import { useSite } from '../../../../../../../../_foundation/hooks/usesite/useSite';
import {
  MyStoreCookie,
  PhysicalStoreDetails,
} from '../../../../../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { IAssignStoreParams } from '../../../../../../../../_foundation/interface/StorePickup/IStorePickup';
import {
  CART_DELIVERY_STORE_SELECTED_TEALIUM,
  STORE_NAME_NUM,
  STORE_SELECTED,
} from '../../../../../../../../constants/Tealium';
import { ASSIGN_STORE_ACTION } from '../../../../../../../../redux/actions/storePickup.actions';
import { orderDetailsSelector } from '../../../../../../../../redux/selectors/orderDetails.selector';
import { storeLocatorSelector } from '../../../../../../../../redux/selectors/storeLocator';
import {
  checkTruthy,
  sendTealiumData,
} from '../../../../../../../../utils/utils';
import { CheckoutFlows } from '../../../../../../../Checkout/enum/Checkout';
import { deliverySelector } from '../../../../../../../Checkout/redux/selectors/checkoutDelivery.selector';
import { NteButton } from '../../../../../../../Forms/NteButton/NteButton';
import { NteAlert } from '../../../../../../../NteAlert/NteAlert';
import { useNteAlert } from '../../../../../../../NteAlert/hooks/NteAlertHooks';
import { NteCollapse } from '../../../../../../Collapse/NteCollapse';
import { NearByStoreTileConstants } from './NearByStoreTileConstants';

/**
 * @interface NearByStoreTitle
 */
interface INearByStoreTitle {
  /**
   * @prop makeMyStoreClickHandler is responsible for
   * selecting specific store id and name
   */
  makeMyStoreClickHandler: (storeDetails: MyStoreCookie) => void;

  /**
   * @prop storeDetails carries each item's detail params.
   */
  storeDetails: PhysicalStoreDetails;

  /**
   * @prop Flag to determine to show/hide inventory details.
   */
  showInventory: boolean;

  /**
   * @prop displayStoreDetailsMode determines the way to show store details
   */
  displayStoreDetailsMode?: string;

  /**
   * @prop displayStoreDetailsMode determines the way to show store details
   */
  displayAllStoreDetails?: boolean;

  /**
   * flag responsible to determine the storePickip
   */
  storePickup: boolean;

  /**
   * @prop method to close the store finder
   */
  storeFinderCloseHandler?: () => void;

  /**
   * Sets the store data of selected store from the see more stores drawer
   */
  setNearbyStoreData?: (nearbyStoreData: PhysicalStoreDetails) => void;

  /**
   * Sets the store name as the radio button value
   */
  setStoreValue?: (storeValue: string) => void;

  /**
   * Boolean value to indicate after setting the anchor store and clicking on select for pickup
   */
  setStoreSelected?: (storeSelected: boolean) => void;

  /**
   * Boolean value to indicate before setting the anchor store and clicking on select for pickup
   */
  setIsInitialSelectPickup?: (isInitialSelectPickup: boolean) => void;
}

/**
 * @component NearByStoreTitle renders the near by store tiles inside the StoreFinder component.
 *
 * @param NearByStoreTitle
 */
const NearByStoreTitle: React.FC<INearByStoreTitle> = ({
  makeMyStoreClickHandler,
  storeDetails,
  showInventory,
  displayStoreDetailsMode = 'accordion',
  displayAllStoreDetails = true,
  storePickup,
  storeFinderCloseHandler,
  setNearbyStoreData,
  setStoreValue,
  setStoreSelected,
  setIsInitialSelectPickup,
}) => {
  const {
    MILE,
    MAKE_THIS_MY_STORE,
    MY_STORE,
    NOT_AVAILABLE,
    IN_STOCK,
    STORE_DETAILS,
    STORE_HOURS,
    AVAILABLE,
    SELECT_FOR_PICKUP,
    AVAILABLE_WITHIN_1HR,
    TEMPORARILY_CLOSED,
    PERMANENTLY_CLOSED,
    COMING_SOON,
    ATTRIBUTES: { STORE_WEB_STATUS, BOPIS_AVAILABILITY, GENERAL_ANNOUNCEMENTS },
    X_URL_ERROR,
    D365_ATTRIBUTE,
    D365_MESSAGE,
    CALL_STORE,
  } = NearByStoreTileConstants;

  const { t } = useTranslation();

  const myStoreCookie = sessionStorage.getItem(MY_STORE_COOKIE);

  const currentStoreId = myStoreCookie && JSON.parse(myStoreCookie)?.storeId;

  const isAlreadySelected = currentStoreId === storeDetails?.uniqueID;

  const { mySite } = useSite();

  const { makeMyStoreSpinner, currentAnchorStoreName } =
    useSelector(storeLocatorSelector);

  const makeMyStoreLoading = makeMyStoreSpinner[storeDetails?.uniqueID];

  const dispatch = useDispatch();

  const {
    cartItems: { orderId },
  } = useSelector(deliverySelector);

  const storeHours = storeDetails.Attribute.filter(
    (storeAttribute: any) => storeAttribute.name === STORE_HOURS
  );

  const storeAccouncements = storeDetails.Attribute.filter(
    (storeAttribute: any) =>
      storeAttribute.name.toLowerCase() === GENERAL_ANNOUNCEMENTS.toLowerCase()
  );

  const productInventory = Boolean(storeDetails?.inventory > 0);

  // const productInventoryNegative = Boolean(Number(storeDetails?.inventory) < 0);

  const orderInventory =
    storeDetails.orderInventory?.filter((item) => item.inventory === 0)
      .length === 0;

  const storeWebStatus = storeDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === STORE_WEB_STATUS.toLowerCase()
  )?.value;

  const storeTemporarilyClosed =
    storeWebStatus &&
    storeWebStatus.toLowerCase() === TEMPORARILY_CLOSED.toLowerCase();

  const storePermanentlyClosed =
    storeWebStatus &&
    storeWebStatus.toLowerCase() === PERMANENTLY_CLOSED.toLowerCase();

  const storeComingSoon =
    storeWebStatus &&
    storeWebStatus.toLowerCase() === COMING_SOON.toLowerCase();

  const { contents } = useSelector(orderDetailsSelector);

  const productNames = contents.map(({ name }) => name);

  const partnumbers = contents.map(({ partNumber }) => partNumber);

  const orderInventoryCheck = !storeDetails.quantityUnAvailable;

  const isInventorynotAvailable =
    !storeDetails?.quantityUnAvailable &&
    storeDetails?.orderInventory &&
    storeDetails?.orderInventory?.length === 0;

  const isD365Store = storeDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === D365_ATTRIBUTE.toLowerCase()
  )?.value;

  const isNotBopis = storeDetails?.Attribute.find(
    (attribute) =>
      attribute.displayName.toLowerCase() === BOPIS_AVAILABILITY.toLowerCase()
  )?.value;

  const showD365AdditionalMessage =
    checkTruthy(isD365Store) &&
    (!checkTruthy(isNotBopis) || !checkTruthy(mySite?.globalBopis));

  const storeClickHandler = () => {
    sendTealiumData({
      tealium_event: STORE_SELECTED,
      storefinder_store_id: STORE_NAME_NUM(
        storeDetails.city,
        storeDetails.uniqueID
      ),
    });

    makeMyStoreClickHandler({
      storeId: storeDetails.uniqueID,
      storeName: storeDetails.storeName,
    });

    sessionStorage.removeItem(NEAR_BY_STORES);

    /**
     * Tealium integration
     */
    if (myStoreCookie && JSON.parse(myStoreCookie).postalCode) {
      sendTealiumData({
        tealium_event: CART_DELIVERY_STORE_SELECTED_TEALIUM,
        product_id: partnumbers,
        product_name: productNames,
        cart_shipping_zipcode: JSON.parse(myStoreCookie).postalCode,
      });
    }
  };

  const dispatchStoreDetails = () => {
    if (storeDetails) {
      const fsdEstimatedDelivery = storeDetails?.fsdEstimatedDelivery;

      const bopisAvailability = storeDetails?.Attribute.find(
        (attribute) => attribute.displayName === BOPIS_AVAILABILITY
      )?.value?.toUpperCase();

      const assignStorePayload: IAssignStoreParams = {
        storeID: mySite?.storeID,
        orderID: Number(orderId),
        storeLocatorId: Number(storeDetails?.uniqueID),
        storeName: storeDetails.storeName || '',
        isFSD:
          fsdEstimatedDelivery && fsdEstimatedDelivery.length > 0
            ? true
            : false,
        isBOPIS: checkTruthy(bopisAvailability),
        checkoutFlow: CheckoutFlows.Delivery,
        x_estimatedDelivery: fsdEstimatedDelivery || '',
        isCheckout: true,
      };

      dispatch(ASSIGN_STORE_ACTION(assignStorePayload));
    }
  };

  const selectStoreHandler = () => {
    if (currentAnchorStoreName && setIsInitialSelectPickup) {
      setIsInitialSelectPickup(false);

      if (storeDetails && storeFinderCloseHandler) {
        makeMyStoreClickHandler({
          storeId: storeDetails.uniqueID,
          storeName: storeDetails.storeName,
        });

        storeFinderCloseHandler();
      }

      if (setNearbyStoreData && storeDetails) {
        setNearbyStoreData(storeDetails);
      }

      if (setStoreValue) {
        setStoreValue(storeDetails?.Description[0]?.displayStoreName);
      }

      if (setStoreSelected) {
        setStoreSelected(true);
      }

      if (storeFinderCloseHandler) {
        storeFinderCloseHandler();
      }

      dispatchStoreDetails();
    } else {
      if (setIsInitialSelectPickup) {
        setIsInitialSelectPickup(true);

        storeClickHandler();
      }
    }
  };

  let isDisabled = false;

  const { setAlert } = useNteAlert();

  const storeLinkClickHandler = () => {
    if (storeDetails?.x_url.length === 0) {
      setAlert({
        message: t(X_URL_ERROR),
        severity: 'error',
      });
    }
    if (storeFinderCloseHandler) {
      storeFinderCloseHandler();
    }
  };

  return (
    <div className='near_by_store_tile'>
      <Grid container justifyContent='space-between' className='store-name-row'>
        <Grid item>
          <Typography
            variant='body1'
            className={`store-name ${isAlreadySelected && 'is-selected'}`}>
            <Link
              to={storeDetails?.x_url ? `/store/${storeDetails?.x_url}` : ''}
              rel='nofollow'
              onClick={storeLinkClickHandler}>
              {storeDetails.Description[0].displayStoreName}
            </Link>
          </Typography>
        </Grid>

        {storeDetails?.distance && (
          <Grid item>
            <Typography variant='body1' className='miles-distant'>
              {Number(storeDetails.distance).toFixed(1)} {t(MILE)}
            </Typography>
          </Grid>
        )}
      </Grid>

      {showInventory &&
        !storePickup &&
        (productInventory || (orderInventory && orderInventoryCheck) ? (
          <>
            <Typography variant='body1' className='in-stock'>
              {productInventory
                ? `${storeDetails.inventory} ${t(IN_STOCK)}`
                : `${t(AVAILABLE)}`}
            </Typography>
            {showD365AdditionalMessage &&
              (productInventory || (orderInventory && orderInventoryCheck)) && (
                <Typography variant='body2' className='color-brown d365'>
                  {D365_MESSAGE}
                </Typography>
              )}
          </>
        ) : isInventorynotAvailable ? (
          <Typography variant='body2' className='out-of-stock'>
            {t(CALL_STORE)}
          </Typography>
        ) : (
          <Typography variant='body1' className='out-of-stock'>
            {t(NOT_AVAILABLE)}
          </Typography>
        ))}

      {storePickup &&
        (storeDetails.fsdEstimatedDelivery ? (
          <Typography
            variant='body1'
            className='estimated-delivery field-margin'>
            {storeDetails.fsdEstimatedDelivery}
          </Typography>
        ) : orderInventory && orderInventoryCheck ? (
          <Typography variant='body1' className='in-stock field-margin'>
            {t(AVAILABLE_WITHIN_1HR)}
          </Typography>
        ) : (
          <>
            <Typography variant='body1' className='out-of-stock'>
              {t(NOT_AVAILABLE)}
            </Typography>
            {(isDisabled = true)}
          </>
        ))}

      {!storePermanentlyClosed && !storeComingSoon ? (
        <>
          {!isAlreadySelected ? (
            <NteButton
              className='my-store-button'
              translationKey={
                storePickup ? t(SELECT_FOR_PICKUP) : t(MAKE_THIS_MY_STORE)
              }
              variant='outlined'
              onClick={storePickup ? selectStoreHandler : storeClickHandler}
              size='small'
              disabled={isDisabled}
              loading={makeMyStoreLoading}
              type='button'
            />
          ) : (
            <Grid
              container
              item
              alignItems='center'
              className='icon-inline my-store'>
              <CheckCircleIcon className='menu_icon' /> {t(MY_STORE)}
            </Grid>
          )}
          {storeTemporarilyClosed && (
            <NteAlert
              messages={[storeWebStatus]}
              severity='warning'
              showClose={false}
            />
          )}
        </>
      ) : storeComingSoon ? (
        <>
          {isAlreadySelected && (
            <Grid
              container
              item
              alignItems='center'
              className='icon-inline prompt-margin'>
              <CheckCircleIcon className='menu_icon' /> {t(MY_STORE)}
            </Grid>
          )}
          <NteAlert
            messages={[storeWebStatus]}
            severity='info'
            showClose={false}
          />
        </>
      ) : (
        <>
          {isAlreadySelected && (
            <Grid
              container
              item
              alignItems='center'
              className='icon-inline prompt-margin'>
              <CheckCircleIcon className='menu_icon' /> {t(MY_STORE)}
            </Grid>
          )}
          <NteAlert
            messages={[storeWebStatus]}
            severity='warning'
            showClose={false}
          />
        </>
      )}

      <NteCollapse
        collapseTitle={t(STORE_DETAILS)}
        showCollapse={false}
        className='store-details--button'>
        <>
          <Link
            to={{
              pathname: `https://maps.google.com/?q=Northern Tool ${storeDetails.addressLine[0]}, ${storeDetails.city}, ${storeDetails.stateOrProvinceName}
              ${storeDetails.postalCode}`,
            }}
            target='_blank'
            rel='nofollow'>
            <Typography variant='body1'>
              {storeDetails.addressLine[0]}
            </Typography>

            <Typography variant='body1'>
              {storeDetails.city}, {`${storeDetails.stateOrProvinceName} `}
              {storeDetails.postalCode}
            </Typography>
          </Link>
          {storeComingSoon && (
            <Typography variant='body1' className='announcements-block'>
              <span
                dangerouslySetInnerHTML={{
                  __html: storeAccouncements[0]?.displayValue,
                }}
              />
            </Typography>
          )}
          <Typography variant='body1' className='hours-block'>
            <span
              dangerouslySetInnerHTML={{ __html: storeHours[0].displayValue }}
            />
          </Typography>

          <Typography variant='body1' className='phone'>
            <Link to='/' rel='nofollow'>
              {storeDetails.telephone1}
            </Link>
          </Typography>
        </>
      </NteCollapse>
    </div>
  );
};

export { NearByStoreTitle };
