import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as ROUTES from '../../../../../constants/routes';
import { ADD_PRODUCTS_TO_COMPARE_ACTION } from '../../../../../redux/actions/productList.actions';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { wishListSelector } from '../../../../../redux/selectors/wish-list.selector';
import { enterKeyPressHandler } from '../../../../../utils/utils';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { NteCheckbox } from '../../../../Forms/NteCheckbox/NteCheckbox';
import { useNteAlert } from '../../../../NteAlert/hooks/NteAlertHooks';
import { ProductCompareConstants } from './ProductCompareConstants';

/**
 * @interface IProductCompare
 */
interface IProductCompare {
  /**
   * @prop id of the current product card component.
   */
  id: string;

  /**
   * @prop name of the current product.
   */
  name: string;
}

/**
 * @component ProductCompare renders the compare checkbox.
 *
 * @param IProductCompare
 */
const ProductCompare: React.FC<IProductCompare> = ({ id, name }) => {
  const { COMPARE, MAX_LIMIT_MESSAGE, UPDATE_ALERT_MESSAGE } =
    ProductCompareConstants;

  const { pathname } = useLocation();

  const {
    productComparison: { enableCompare, compareProducts },
  } = useSelector(productListSelector);

  const {
    wishListProductPage: { wishListProducts },
  } = useSelector(wishListSelector);

  const dispatch = useDispatch();

  const [checked, setChecked] = useState<boolean>(false);

  const { setAlert } = useNteAlert();

  const { t } = useTranslation();

  /**
   * @method productCompareHandler adds the current product to the comparison list.
   */
  const productCompareHandler = (e: any): void => {
    setChecked(e.target.checked);

    dispatch(
      ADD_PRODUCTS_TO_COMPARE_ACTION({
        id,
        isChecked: e.target.checked,
        name,
        ...{ ...(wishListProducts.length !== 0 && { wishListProducts }) },
      })
    );
  };

  /**
   * @method checkBoxClickHandler is triggered only when the
   * checkbox goes to a disabled state.
   */
  const checkBoxClickHandler = (): void => {
    setAlert({
      message: t(MAX_LIMIT_MESSAGE),
      severity: 'error',
    });
  };

  /**
   * @method syncCheckBox Syncs checkbox states between comparison bar and the product card.
   */
  const syncCheckBox = useCallback(
    (compareProducts: IProductListContents[]): void => {
      const currentIndex = compareProducts.findIndex(
        (product) => product?.partNumber === id
      );

      setChecked(currentIndex !== -1);
    },
    [id]
  );

  useEffect(() => {
    syncCheckBox(compareProducts);
  }, [syncCheckBox, compareProducts]);

  // Check if the compare checkbox is disabled.
  const isCompareDisabled = !enableCompare && !checked;

  const isCartPage = pathname === ROUTES.SHOPPING_CART;

  return (
    <>
      {!isCartPage && (
        <>
          <span className='sr-only'>{t(UPDATE_ALERT_MESSAGE)}</span>

          <NteCheckbox
            {...(isCompareDisabled && { onClick: checkBoxClickHandler })}
            checked={checked}
            disabled={isCompareDisabled}
            id={`compare-product-${id}`}
            label={COMPARE}
            labelPlacement='end'
            onKeyDown={(e: any) =>
              enterKeyPressHandler(e, () => {
                productCompareHandler({
                  target: {
                    checked: !checked,
                  },
                });
              })
            }
            onChange={productCompareHandler}
          />
        </>
      )}
    </>
  );
};

export { ProductCompare };
