import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NteAccordion } from '../../../Widgets/Accordion/NteAccordion';
import { FooterLinksConstants } from '../FooterLinksConstants';
import { useEspotFooterLinks } from '../Hooks/useEspotFooterLinks';

const ResourcesLinks: React.FC = () => {
  const espotName = 'FOOTER_01B';

  const { t, i18n } = useTranslation();

  const { RESOURCES, RESOURCES_FALLBACK } = FooterLinksConstants;

  const title = i18n.exists(RESOURCES).valueOf()
    ? t(RESOURCES)
    : RESOURCES_FALLBACK;

  const { parsedEspotLinks: resourcesLinks } = useEspotFooterLinks(espotName);

  return (
    <Box className='footer_links_block resources'>
      <div className='tablet-only'>
        <Typography variant='h3'>{title}</Typography>
        <ul>{resourcesLinks.map((link: JSX.Element) => link)}</ul>
      </div>
      <Hidden smUp>
        <NteAccordion
          accordionTitle={title}
          defaultExpanded={false}
          className='no-elevation'>
          <ul>{resourcesLinks.map((link: JSX.Element) => link)}</ul>
        </NteAccordion>
      </Hidden>
    </Box>
  );
};

export default ResourcesLinks;
