import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EXIT_LINK } from '../../../constants/Tealium';
import { ReactComponent as FaceBookIcon } from '../../../theme/assets/Facebook_circle_pictogram.svg';
import { ReactComponent as InstagramIcon } from '../../../theme/assets/instagram-circle.svg';
import { ReactComponent as PinterestIcon } from '../../../theme/assets/pinterest-round.svg';
import { ReactComponent as TwitterIcon } from '../../../theme/assets/twitter.svg';
import { ReactComponent as YouTubeIcon } from '../../../theme/assets/YouTube_social_dark_circle.svg';
import { sendTealiumData } from '../../../utils/utils';
import Confidence from '../../Widgets/Confidence';
import { SocialMediaLinksConstants } from './SocialMediaLinksConstants';

/**
 * @component SocialMediaLinks renders the social media icons and links.
 */
const SocialMediaLinks: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { CONNECT_WITH_US, CONNECT_WITH_US_FALLBACK } =
    SocialMediaLinksConstants.TRANSLATION_CONSTANTS;

  const { LINKS: socialMediaLinks } = SocialMediaLinksConstants;

  const sendAnalyticEvent = (exitLink: string) => {
    sendTealiumData({
      tealium_event: EXIT_LINK,
      exit_link_name: exitLink,
    });
  };

  const title = i18n.exists(CONNECT_WITH_US).valueOf()
    ? t(CONNECT_WITH_US)
    : CONNECT_WITH_US_FALLBACK;

  return (
    <Grid container className='footer-social-section'>
      <Grid item className='connect-with-us'>
        <Typography variant='h3'>{title}</Typography>
        <Grid container className='social-icons' component='ul'>
          <Grid item className='social-media-icon' component='li'>
            <a
              target='_blank'
              aria-label='Visit our Facebook page.'
              href={socialMediaLinks.FACEBOOK}
              rel='nofollow noreferrer'
              onClick={() => {
                sendAnalyticEvent(socialMediaLinks.FACEBOOK);
              }}>
              <FaceBookIcon role={'img'} aria-label='facebook icon' />
            </a>
          </Grid>
          <Grid item className='social-media-icon' component='li'>
            <a
              target='_blank'
              aria-label='Visit our Twitter page.'
              href={socialMediaLinks.TWITTER}
              rel='nofollow noreferrer'
              onClick={() => {
                sendAnalyticEvent(socialMediaLinks.TWITTER);
              }}>
              <TwitterIcon role={'img'} aria-label='twitter icon' />
            </a>
          </Grid>
          <Grid item component='li' className='social-media-icon'>
            <a
              target='_blank'
              aria-label='Visit our YouTube page.'
              href={socialMediaLinks.YOUTUBE}
              rel='nofollow noreferrer'
              onClick={() => {
                sendAnalyticEvent(socialMediaLinks.YOUTUBE);
              }}>
              <YouTubeIcon role={'img'} aria-label='youtube icon' />
            </a>
          </Grid>
          <Grid item component='li' className='social-media-icon'>
            <a
              target='_blank'
              aria-label='Visit our Pinterest page.'
              href={socialMediaLinks.PINTEREST}
              rel='nofollow noreferrer'
              onClick={() => {
                sendAnalyticEvent(socialMediaLinks.PINTEREST);
              }}>
              <PinterestIcon role={'img'} aria-label='pinterest icon' />
            </a>
          </Grid>
          <Grid item component='li' className='social-media-icon'>
            <a
              target='_blank'
              aria-label='Visit our Instagram page.'
              href={socialMediaLinks.INSTAGRAM}
              rel='nofollow noreferrer'
              onClick={() => {
                sendAnalyticEvent(socialMediaLinks.INSTAGRAM);
              }}>
              <InstagramIcon role={'img'} aria-label='instagram icon' />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className='confidence-logo'>
        <Confidence
          role={'img'}
          aria-label='over 40 years icon'
          aria-labelledby='over-forty-years'
        />
      </Grid>
    </Grid>
  );
};

export default SocialMediaLinks;
