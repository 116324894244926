import { createReducer } from '@reduxjs/toolkit';
import {
  IRviResponse,
  IRviState,
} from '../../../../../_foundation/interface/Rvi/IRvi';
import {
  FETCH_RVI_SUCCESS_ACTION,
  RESET_RVI_ACTION,
} from '../actions/rvi.actions';

const initRviState: IRviState = {
  rviProducts: [],
  rviDataUpdated: true,
};

const rviReducer = createReducer(initRviState, (builder) => {
  builder.addCase(FETCH_RVI_SUCCESS_ACTION, (state: IRviState, action: any) => {
    const { contents } = action.payload as IRviResponse;

    return { rviDataUpdated: false, rviProducts: contents };
  });

  builder.addCase(RESET_RVI_ACTION, (state: IRviState, action: any) => {
    return initRviState;
  });
});

export { rviReducer };
