const CurrentStoreAddressConstants = {
  STORE_HOURS: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.STORE_HOURS',
  STORE: 'Store',
  TAG_SEPERATOR: ' #',
  CLOSED_TODAY: 'Closed today',
  SDD_UNAVAILABLE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.SDD_UNAVAILABLE',
  ATTRIBUTES: {
    STORE_HOURS_ATTRIBUTE: 'STOREHOURS',
    BOPIS_AVAILABILITY: 'bopisAvailability',
    GENERAL_ANNOUNCEMENTS: 'nteGeneralAnnouncements',
  },
  STORE_HOURS_FALLBACK: 'Store Hours',
};

export { CurrentStoreAddressConstants };
