import { createAction } from '@reduxjs/toolkit';
import {
  IAddPickupPersonPayload,
  IDeletePickupPersonParams,
  IGetPickupPersonParams,
} from '../../_foundation/interface/PickupPerson/IPickupPerson';
import * as ACTION_TYPES from '../action-types/pickupPerson.action-types';

export const GET_PICKUP_PERSON_ACTION = createAction<IGetPickupPersonParams>(
  ACTION_TYPES.GET_PICKUP_PERSON
);

export const ADD_PICKUP_PERSON_ACTION = createAction<IAddPickupPersonPayload>(
  ACTION_TYPES.ADD_PICKUP_PERSON
);

export const DELETE_PICKUP_PERSON_ACTION =
  createAction<IDeletePickupPersonParams>(ACTION_TYPES.DELETE_PICKUP_PERSON);

export const UPDATE_PICKUP_PERSON_ACTION =
  createAction<IAddPickupPersonPayload>(ACTION_TYPES.UPDATE_PICKUP_PERSON);

export const GET_PICKUP_PERSON_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.GET_PICKUP_PERSON_SUCCESS
);

export const ADD_PICKUP_PERSON_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.ADD_PICKUP_PERSON_SUCCESS
);

export const DELETE_PICKUP_PERSON_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.DELETE_PICKUP_PERSON_SUCCESS
);

export const UPDATE_PICKUP_PERSON_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_PICKUP_PERSON_SUCCESS
);

export const GET_PICKUP_PERSON_REVIEW_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.GET_PICKUP_PERSON_REVIEW_SUCCESS
);

export const GET_PICKUP_PERSON_CONFIRMATION_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.GET_PICKUP_PERSON_CONFIRMATION_SUCCESS
);
