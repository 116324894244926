import { List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorConstants } from './ErrorConstants';
import './scss/Error.scss';

/**
 * @componentn ContactUsforHelp renders the Contact us.
 */
const ContactUsforHelp: React.FC = () => {
  const {
    CHAT,
    CONTACT_US_FOR_HELP,
    CUSTOMER_CARE,
    CUSTOMER_CARE_NUMBER,
    EMAIL,
    SALES,
    SALES_NUMBER,
    EMAIL_CONTACT_US,
    CHAT_FALLBACK,
    CONTACT_US_FOR_HELP_FALLBACK,
    CUSTOMER_CARE_FALLBACK,
    CUSTOMER_CARE_NUMBER_FALLBACK,
    EMAIL_FALLBACK,
    SALES_FALLBACK,
    SALES_NUMBER_FALLBACK,
    EMAIL_CONTACT_US_FALLBACK,
  } = ErrorConstants;

  const { t, i18n } = useTranslation();

  return (
    <div className='contact-column'>
      <Typography variant='h3'>
        {i18n.exists(CONTACT_US_FOR_HELP).valueOf()
          ? t(CONTACT_US_FOR_HELP)
          : CONTACT_US_FOR_HELP_FALLBACK}
      </Typography>

      <List className='help-list'>
        <ListItem
          divider={true}
          role='listitem'
          className='error-padding-bottom'>
          <a
            className='menuStyle'
            href={
              `tel:` + i18n.exists(SALES_NUMBER).valueOf()
                ? t(SALES_NUMBER)
                : SALES_NUMBER_FALLBACK
            }
            rel='nofollow'>
            <span className='textStyle'>
              {i18n.exists(SALES).valueOf() ? t(SALES) : SALES_FALLBACK}
              &nbsp;
            </span>
            <span className='numberStyle'>
              {i18n.exists(SALES_NUMBER).valueOf()
                ? t(SALES_NUMBER)
                : SALES_NUMBER_FALLBACK}
            </span>
          </a>
        </ListItem>

        <ListItem
          divider={true}
          role='listitem'
          className='error-padding-bottom'>
          <a
            className='menuStyle'
            href={
              `tel:` + i18n.exists(CUSTOMER_CARE_NUMBER).valueOf()
                ? t(CUSTOMER_CARE_NUMBER)
                : CUSTOMER_CARE_NUMBER_FALLBACK
            }
            rel='nofollow'>
            <span className='textStyle'>
              {i18n.exists(CUSTOMER_CARE).valueOf()
                ? t(CUSTOMER_CARE)
                : CUSTOMER_CARE_FALLBACK}
              &nbsp;
            </span>
            <span className='numberStyle'>
              {i18n.exists(CUSTOMER_CARE_NUMBER).valueOf()
                ? t(CUSTOMER_CARE_NUMBER)
                : CUSTOMER_CARE_NUMBER_FALLBACK}
            </span>
          </a>
        </ListItem>

        <ListItem
          divider={true}
          role='listitem'
          className='error-padding-bottom'>
          <Link
            className='menuStyle'
            rel='nofollow'
            to='#'
            onClick={(e) => {
              window.openMChat();
            }}>
            <span className='textStyle'>
              {i18n.exists(CHAT).valueOf() ? t(CHAT) : CHAT_FALLBACK}
            </span>
          </Link>
        </ListItem>

        <ListItem
          divider={true}
          role='listitem'
          className='error-padding-bottom'>
          <Link
            className='menuStyle'
            rel='nofollow'
            to={
              i18n.exists(EMAIL_CONTACT_US).valueOf()
                ? t(EMAIL_CONTACT_US)
                : EMAIL_CONTACT_US_FALLBACK
            }>
            <span className='textStyle'>
              {i18n.exists(EMAIL) ? t(EMAIL) : EMAIL_FALLBACK}
            </span>
          </Link>
        </ListItem>
      </List>
    </div>
  );
};

export { ContactUsforHelp };
