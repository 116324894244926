import { call, put } from 'redux-saga/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import i18n from '../../../i18n';
import { alertMessageId } from '../../../utils/utils';
import { PaymentService } from '../../../_foundation/apis/payment/payment.service';
import { UPDATE_USER_SAVED_CARD_DETAILS_ACTION } from '../../actions/auth.actions';

const translationKey = 'API_ERROR_HANDLING.MY_ACCOUNT.PAYMENT_INFORMATION';

export function* fetchCardDetails(action: any): Generator {
  try {
    const savedCardDetailsResponse: any = yield call(
      PaymentService.getSavedCardDetails,
      action.payload
    );

    yield put(UPDATE_USER_SAVED_CARD_DETAILS_ACTION(savedCardDetailsResponse));
  } catch (e) {
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.CARD_DETAILS.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}
