import { createReducer } from '@reduxjs/toolkit';
import { IStorePickupState } from '../../_foundation/interface/StorePickup/IStorePickup';

const initialState: IStorePickupState = {
  storeName: '',
  storeLocatorId: '',
  isFSD: false,
  isBOPIS: false,
};

const storePickupReducer = createReducer(initialState, (builder) => {});

export { storePickupReducer };
