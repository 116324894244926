import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogMenuConstants } from '../../../../ExpandableMenu/CatalogMenu/CatalogMenuConstants';
import { useSupportMenu } from '../../../../ExpandableMenu/SupportMenu/hooks/SupportMenuHooks';
import { SupportMenuConstants } from '../../../../ExpandableMenu/SupportMenu/SupportMenuConstants';
import { IHamburgerMenuItem } from '../../interface/IMenuItem';

/**
 * @hooks useHamburgerMenu is responsible for setting up
 * the list of menu items to be loaded from the deals
 * hamburger menu.
 */
const useHamburgerMenu = (items: any) => {
  const [hamburgerMenuItems, setHamburgerMenuItems] = useState<
    IHamburgerMenuItem[]
  >([]);

  const { ORDER_FROM_CATALOG, REQUEST_FREE_CATALOG, CATALOG_TITLE } =
    CatalogMenuConstants;

  const { SUPPORT_TITLE } = SupportMenuConstants;

  const { t } = useTranslation();

  const { supportMenuItems } = useSupportMenu();

  /**
   * @callback initCategoriesAndBrands
   * This callback strips categories, brands and deals
   * from the response and orders them to render in the
   * hamburger menu.
   */
  const initCategoriesAndBrands = useCallback(() => {
    const categoriesAndBrands: IHamburgerMenuItem[] = [];

    items.forEach((menuData: any) => {
      const menuItem: IHamburgerMenuItem = {
        children: menuData.children,
        identifier: menuData.identifier,
        name: menuData.menuName,
      };

      categoriesAndBrands.push(menuItem);
    });

    return categoriesAndBrands;
  }, [items]);

  /**
   * @callback initCatalog
   * This callback constructs the catalog items to
   * be rendered in the hamburger menu.
   */
  const initCatalog = useCallback(() => {
    const catalogItems: IHamburgerMenuItem[] = [];

    const catalog: IHamburgerMenuItem = {
      children: [
        { name: t(REQUEST_FREE_CATALOG) },
        { name: t(ORDER_FROM_CATALOG) },
      ],
      name: t(CATALOG_TITLE),
    };

    catalogItems.push(catalog);

    return catalogItems;
  }, [CATALOG_TITLE, ORDER_FROM_CATALOG, REQUEST_FREE_CATALOG, t]);

  /**
   * @callback initSupportItems
   * This callback strips the support items
   * based on the userType to render it in
   * the hamburger menu.
   */
  const initSupportItems = useCallback(() => {
    const supportItems: IHamburgerMenuItem[] = [];

    const updatedSupportItems: any[] = [];

    supportMenuItems.forEach((supportMenuItem) => {
      const supportItem = {
        name: supportMenuItem.item,
        href: supportMenuItem.href,
        identifier: supportMenuItem.identifier,
        isTel: supportMenuItem.isTel,
      };

      updatedSupportItems.push(supportItem);
    });

    const support: IHamburgerMenuItem = {
      children: updatedSupportItems,
      name: t(SUPPORT_TITLE),
    };

    supportItems.push(support);

    return supportItems;
  }, [SUPPORT_TITLE, supportMenuItems, t]);

  /**
   * @callback addDealsMenu
   * This callback constructs the hamburger menu items.
   */
  const addHamburgerMenuItems = useCallback(() => {
    const categoriesAndBrands = initCategoriesAndBrands();

    const catalog = initCatalog();

    const support = initSupportItems();

    const menuItems: IHamburgerMenuItem[] = [
      ...categoriesAndBrands,
      ...catalog,
      ...support,
    ];

    setHamburgerMenuItems(menuItems);
  }, [initCatalog, initCategoriesAndBrands, initSupportItems]);

  useEffect(() => {
    addHamburgerMenuItems();

    return () => {
      setHamburgerMenuItems([]);
    };
  }, [addHamburgerMenuItems]);

  return { hamburgerMenuItems };
};

export { useHamburgerMenu };
