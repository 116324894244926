import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { DealsMenuItem } from './DealsMenuItem/DealsMenuItem';
import { useNavBarMenu } from './hooks/NavBarMenuHooks';
import { NavBarMenuConstants } from './NavBarMenuConstants';
import './NavBarMenuStyles.scss';
import { RenderNestedList } from './RenderNestedList/RenderNestedList';

/**
 * @component NavBarMenu is responsible for rendering the
 * nav bar menus.
 */
const NavBarMenu: React.FC = () => {
  const { DEALS } = NavBarMenuConstants;

  const { menuItems, loading, dealsMenu, dealsHtml, showDealsMenu } =
    useNavBarMenu();

  return (
    <>
      {!loading ? (
        <>
          {menuItems.map((item, index) => {
            if (item.identifier === DEALS) {
              if (showDealsMenu && dealsMenu && item) {
                return (
                  <RenderNestedList
                    key={index}
                    menuItem={showDealsMenu && dealsMenu && item}
                  />
                );
              }
              return (
                <DealsMenuItem
                  dealsHtml={dealsHtml}
                  showDealsMenu={showDealsMenu}
                  key={index}
                />
              );
            }

            return <RenderNestedList key={index} menuItem={item} />;
          })}
        </>
      ) : (
        <>
        <Grid container item className='nav-bar-skeleton' component='li'>
          <Skeleton className='nav-bar-skeleton-bg' />
          </Grid>
          <Grid container item className='nav-bar-skeleton' component='li'>
          <Skeleton className='nav-bar-skeleton-bg' />
          </Grid>
          <Grid container item className='nav-bar-skeleton' component='li'>
          <Skeleton className='nav-bar-skeleton-bg' />
        </Grid>
        </>
      )}
    </>
  );
};

export { NavBarMenu };
