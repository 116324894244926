import {createReducer} from '@reduxjs/toolkit';
import { setFalse, setTrue } from '../action-types/billingAddressForm.action-types';


export interface FormShowState{
    showBillingForm: boolean;
}

const initialState: FormShowState ={
    showBillingForm: false,
};

const billingAddressFormReducer = createReducer(initialState,(builder)  =>{
    builder
        .addCase(setTrue, (state) =>{
            state.showBillingForm =true;
        })
        .addCase(setFalse, (state) =>{
            state.showBillingForm =false;
        })
});

export {billingAddressFormReducer};