/** Defines Values for Geolocation object being returend from Yotta service. */
export interface GeoLocationDetails {
  /* City of the user */
  city: string;
  /* Region of the user */
  region: string;
  /* Region Code of the user */
  regionCode: string;
  /* Country of the user */
  country: string;
  /* Country Code of the user */
  countryCode: string;
  /* Latitude of the user */
  lat: number;
  /* Longitude of the user */
  long: number;
  /* Zip code of the user */
  zipCode: string;
}

/** Yotta Service For fetching all yotta related information such as geo location. */
const yottaService = {
  /**
   * @method getGeoLocation returns all the geo location details of the user.
   * @returns GeoLocationDetails.
   */
  getGeoLocation(): Partial<GeoLocationDetails> {
    const geoLocationDetails: Partial<GeoLocationDetails> = {};
    try {
      const yoGeo = window.Yo.geo();

      if (yoGeo !== undefined) {
        geoLocationDetails.city = yoGeo.city;
        geoLocationDetails.region = yoGeo.region;
        geoLocationDetails.regionCode = yoGeo.regionCode;
        geoLocationDetails.country = yoGeo.country;
        geoLocationDetails.countryCode = yoGeo.countryCode;
        geoLocationDetails.lat = yoGeo.latitude;
        geoLocationDetails.long = yoGeo.longitude;
        if (yoGeo.zipCode === '-') {
          geoLocationDetails.zipCode = '55337'; // Default Geo Location to be considered.
        } else {
          geoLocationDetails.zipCode = yoGeo.zipCode;
        }
      } else {
        console.log('Error retrieving Geo Location information');
      }

      return geoLocationDetails;
    } catch (err) {
      console.log('Error retrieving Geo Location information');
      return geoLocationDetails;
    }
  },
};

export { yottaService };
