const FacetBodyConstants = {
  DEFAULT_FILTER_VALUES: 5,
  SEE_MORE: 'FACETS.SEE_MORE',
  SEE_LESS: 'FACETS.SEE_LESS',
  PROMOTION: 'FACETS.PROMOTION',
  BRANDS: 'FACETS.EXCLUDED_LABEL.BRANDS',
  CATEGORIES: 'FACETS.EXCLUDED_LABEL.CATEGORIES',
};

export { FacetBodyConstants };
