const TRANSLATION_KEY = 'CHECKOUT.DELIVERY.SHIPPING_INFO';

const TRANSLATION_KEY_ERROR = 'API_ERROR_HANDLING.MY_ACCOUNT.SHIPPING_ADDRESS';

const ShippingInfoConstants = {
  TRANSLATIONS: {
    SHIPPING_TITLE: `${TRANSLATION_KEY}.SHIPPING.TITLE`,
    SHIPPING_SUBTITLE: `${TRANSLATION_KEY}.SHIPPING.SUBTITLE`,
    CREATE_ADDRESS_BTN: `${TRANSLATION_KEY}.BUTTON_LABELS.CREATE`,
    SHIPPING_ADDRESS: `${TRANSLATION_KEY}.SHIPPING.SHIPPING_ADDRESS`,
    LIFT_GATE_LABEL: `${TRANSLATION_KEY}.LIFT_GATE_MODAL.CHECKBOX_LABEL`,
    SEE_DETAILS: 'CHECKOUT.PROMOCODE.SEE_DETAILS',
    ADD_ADDRESS_ERROR: `${TRANSLATION_KEY_ERROR}.ADD_ADDRESS.ERROR`,
    UPDATE_ADDRESS_ERROR: `${TRANSLATION_KEY_ERROR}.UPDATE_ADDRESS.ERROR`,
  },
  ALT_TEXT: {
    SHIPPING_TRUCK_ICON_ALT: `${TRANSLATION_KEY}.ALT_TEXT.SHIPPING_TRUCK_ICON`,
  },
  DELIVERY_ESPOT: 'DELIVERY_LIFTGATE',
  ORDER_SPLIT_WARNING: 'CHECKOUT.DELIVERY.ORDER_SPLIT_WARNING',
  BOPIS: 'BOPIS',
  BOPIS_VALUE: 'true',
  ADVANTAGE_MEMBERSHIP: `${TRANSLATION_KEY}.MEMBER_PROGRAMS.ADVANTAGE`,
  ADVANTAGE_PROMO_PARTNUMBER: 'HL-R',
  TEALIUM_TAG: 'checkout | create shipping address',
  TEALIUM_PAGE_TYPE: 'checkout',
  STORE_PICKUP: 'store pickup',
};

export default ShippingInfoConstants;
