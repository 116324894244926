// Home
export const HOME = '/';

// Category
export const CATEGORY = '/category';
export const CATEGORY_INDEX = `/all-categories`;
export const CATEGORY_PLP = `/category-plp`;

// Brands
export const BRAND_INDEX = '/all-brands';

// Offline
export const SITE_OFFLINE = '/offline';

// My Account
export const MY_ACCOUNT = '/my-account';

export const MY_ACCOUNT_NESTED = '/my-account/*';

export const ACCOUNT_DASHBOARD = `${MY_ACCOUNT}/account-dashboard`;
export const ACCOUNT_PROFILE = `${MY_ACCOUNT}/account-profile`;
export const ACCOUNT_PROFILE_UPDATE_CUSTOMER_NUMBER = `${ACCOUNT_PROFILE}/update-customer-number`;
export const ACCOUNT_PROFILE_ADD_CUSTOMER_NUMBER = `${ACCOUNT_PROFILE}/add-customer-number`;
export const ACCOUNT_PROFILE_UPDATE_EMAIL_ADDRESS = `${ACCOUNT_PROFILE}/update-email-address`;
export const CHANGE_PASSWORD = '/my-account/account-profile/change-my-password';
export const BILLING_ADDRESS = `${MY_ACCOUNT}/billing-address`;
export const UPDATE_BILLING_ADDRESS = `${MY_ACCOUNT}/billing-address/update-billing-address`;
export const ADD_BILLING_ADDRESS = `${MY_ACCOUNT}/billing-address/add-billing-address`;
export const SHIPPING_ADDRESS = `${MY_ACCOUNT}/shipping-address`;
export const ADD_SHIPPING_ADDRESS = `${MY_ACCOUNT}/shipping-address/add-shipping-address`;
export const UPDATE_SHIPPING_ADDRESS = `${MY_ACCOUNT}/shipping-address/update-shipping-address`;
export const SAVED_PAYMENT = `${MY_ACCOUNT}/saved-payment`;
export const MY_TAX_INFORMATION = `${MY_ACCOUNT}/my-tax-information`;
export const MY_ORDERS = `${MY_ACCOUNT}/my-orders`;
export const GUEST_USER_ORDER_HISTORY = `/track-order/order-history`;
export const MY_SAVED_LISTS = `${MY_ACCOUNT}/my-saved-lists`;
export const WISH_LISTS = `${MY_ACCOUNT}/my-saved-lists/*`;

// Registration
export const REGISTER_USER = '/my-account/create-account';

// Search
export const SEARCH_PRODUCT = '/search';

// Forgot Password.
export const FORGOT_PASSWORD = '/forgot-password';

// Sign In Page
export const SIGN_IN = '/sign-in';

// Error Page.
export const ERROR_PAGE = '/error-page';

//My Order Guest
export const TRACK_ORDER = '/track-order';
export const MY_ORDER_GUEST = '/my-order-guest';

// Contact us
export const CONTACT_US = '/contact-us';

//Shopping Cart
export const SHOPPING_CART = '/cart';

// Checkout
export const CHECKOUT = '/checkout';

export const CHECKOUT_SIGN_IN = `${CHECKOUT}/sign-in`;

export const CHECKOUT_DELIVERY = `${CHECKOUT}/delivery`;

export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`;
export const CHECKOUT_REVIEW = `${CHECKOUT}/review`;

export const PAYPAL_PROCESSING_PAGE = `${CHECKOUT}/payment-processing`;

export const TREVIPAY_PROCESSING_PAGE = `${CHECKOUT}/trevipay-payment-processing`;

export const TREVIPAY_MY_ACCOUNT_PROCESSING_PAGE = `${MY_ACCOUNT}/trevipay-my-account-processing`;

export const SINGIN_PROCESSING_PAGE = `${CHECKOUT}/signin-processing`;

export const ORDER_CONFIRMATION = '/order-confirmation';

// CMC Templates Sandbox
export const CMC_TEMPLATES = '/cmc-templates';

export const CUSTOMER_HELP = '/customer-help';

export const REQUEST_FREE_CATALOG = '/request-free-catalog';

export const ORDER_FROM_CATALOG = '/order-from-catalog';

export const STORE_LOCATOR = '/store-locator';

export const STORE_INDEX = '/all-stores';

export const STORE_DETAIL = '/store';

export const SHARED_WISHLIST = '/sharedWishList';

export const SEE_ALL_DEALS = '/deals';

export const SITE_MAP_LIST = '/sitemap';

export const SHOP_STORE = '/shop-store';

export const SHOP_ALL = '/categories/shop-all';

export const COLLECTIONS = '/collections';

export const PROMOTIONS = '/promotions';

export const TERMS_AND_CONDITIONS = '/terms-and-conditions';

export const PRIVACY_POLICY = '/privacy-policy';

export const TAX_INFORMATION_FAQ = '/tax-information';

// MemberShip Pages
export const PLATINUM_MEMBERSHIP = '/platinum';

export const GREAT_PLAINS_MEMBERSHIP = '/great-plains';

export const NORTHERN_PREFERRED_MEMBERSHIP = '/northern-preferred';

//Adding this to fix 89625
export const V8_CATEGORY_REDIRECT = `shop/tools`;

export const STORE_MENU_GET_PARTS = '/parts-service-repair';
