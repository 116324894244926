import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupportMenuConstants } from '../SupportMenuConstants';

/**
 * @interface ISupportSalesItem
 */
interface ISupportSalesItem {
  /**
   * @prop salesNumber to be displayed
   * in the support menu list.
   */
  salesNumber: string;
}

/**
 * @component SupportSalesItem renders the sales contact number
 * under the support menu.
 *
 * @param ISupportSalesItem
 */
const SupportSalesItem: React.FC<ISupportSalesItem> = ({ salesNumber }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Typography variant='body2' className='no-link-effect support_sales_item'>
        {i18n.exists(SupportMenuConstants.SALES).valueOf()
          ? t(SupportMenuConstants.SALES)
          : SupportMenuConstants.SALES_FALLBACK}
        &nbsp;
        <span tabIndex={0} role='link' className='number-style link-effect'>
          {salesNumber}
        </span>
      </Typography>
    </>
  );
};

export { SupportSalesItem };
