import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  IProductListContentAttributes,
  IProductListContents,
} from '../../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../../_foundation/interface/Responses/ICartProductsResponse';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { BadgesUtility } from '../../../../../utils/badges-utility';
import { PlplayoutConstants } from '../../../../Layouts/Plp/PlpLayoutConstants';
import { BadgeHookConstants } from './BadgeHooksConstants';

export interface IBadgeObj {
  label: string;
}

/**
 * @hook responsible to sequence the badge for each productcards
 */
const useBadge = (
  productCard: IProductListContents | ICartContentsItem,
  index: number,
  reviewCount: number,
  rating: number,
  isPdp?: boolean,
  sortIndex?: number
) => {
  const { ORDER_BY_PARAM } = PlplayoutConstants;

  const {
    BADGE,
    DEAL_OF_THE_DAY,
    INCLUDED,
    IS_INCLUDE,
    IS_PAIRS,
    IS_PIECES,
    ON_SALE,
    CLEARANCE,
    PAIRS,
    PIECES,
    TOP_SELLER,
    saleorClearance,
    TOP_SELLER_COUNT,
    JUMBO,
    EXCLUSIVE_ATTR,
  } = BadgeHookConstants;

  const [orderByParam] = useQueryParam(ORDER_BY_PARAM, StringParam);

  const [badgeSequence, setBadgeSequence] = useState<IBadgeObj[]>([]);

  const [pdpBadgeSequence, setPdpBadgeSequence] = useState<IBadgeObj[]>([]);

  const [parentChildPdpBadgeSequence, setParentChildPdpBadgeSequence] =
    useState<IBadgeObj[]>([]);

  const { userType } = useSelector(authenticationSelector);

  /**
   * Responsible to remove the onSale when clearance badge is present
   */
  const removeOnsaleBadge = useCallback(
    (badgeList: IBadgeObj[]) => {
      if (badgeList?.length > 1) {
        const isOnSale = badgeList?.find(({ label }) => {
          if (
            label &&
            typeof label === 'string' &&
            label?.toLowerCase() === CLEARANCE.toLowerCase()
          ) {
            return true;
          } else {
            return false;
          }
        });

        const isClearance = badgeList?.find(
          ({ label }) =>
            typeof label === 'string' &&
            label?.toLowerCase() === CLEARANCE.toLowerCase()
        );

        if (isOnSale && isClearance) {
          const removeOnSale = badgeList?.filter(
            ({ label }) =>
              typeof label === 'string' &&
              label?.toLowerCase() !== ON_SALE.toLowerCase()
          );

          return removeOnSale;
        } else {
          return badgeList;
        }
      } else {
        return badgeList;
      }
    },
    [CLEARANCE, ON_SALE]
  );

  const modifyBadgeSequence = useCallback(
    (productCard, index) => {
      /**
       * Badge sequence
       */
      let badgeMap = [
        'New',
        'Top Seller',
        'Top Rated',
        'Deal of the Day',
        'On Sale',
        'Clearance',
        'Exclusive',
        'Jumbo Size',
      ];

      let badgeList: IBadgeObj[] = [];

      let pdpBadgeList: IBadgeObj[] = [];

      let parentChildPdpBadges: IBadgeObj[] = [];

      const productChildItems =
        productCard && productCard.items && productCard.items.length > 0;

      const productChildSkus =
        productCard && productCard.sKUs && productCard.sKUs.length > 0;

      const productChildren = productChildItems
        ? productCard.items
        : productCard.sKUs;

      const parentChildData = productChildItems || productChildSkus;

      /**
       * To get the badges only for products with children items
       */
      parentChildData &&
        productChildren.forEach((item: any) => {
          const dealOfTheDay = item?.attributes?.find(
            (attribute: any) => attribute.identifier === DEAL_OF_THE_DAY
          );

          const badges = item?.attributes?.find(
            (attribute: any) => attribute.name === 'Badge'
          );

          const jumboBadge = item?.attributes?.find(
            (attribute: any) => attribute.identifier.toLowerCase() === JUMBO
          );

          const exclusiveBadge = item?.attributes?.find(
            (attribute: any) =>
              attribute.identifier.toLowerCase() === EXCLUSIVE_ATTR
          );

          if (jumboBadge) {
            const jumboAttrResponse =
              BadgesUtility.getJumboSizeBadge(jumboBadge);

            parentChildPdpBadges.push.apply(
              parentChildPdpBadges,
              jumboAttrResponse
            );
          }

          if (exclusiveBadge) {
            const exclusiveAttrResponse =
              BadgesUtility.getExclusiveBadge(exclusiveBadge);

            parentChildPdpBadges.push.apply(
              parentChildPdpBadges,
              exclusiveAttrResponse
            );
          }

          if (badges) {
            const badgesAttrResponse = BadgesUtility.getByBadgeAttr(
              badges,
              userType
            );

            parentChildPdpBadges.push.apply(
              parentChildPdpBadges,
              badgesAttrResponse
            );
          }

          const ratingAttrResponse = BadgesUtility.getByRatingAttr(
            rating,
            reviewCount
          );

          parentChildPdpBadges.push.apply(
            parentChildPdpBadges,
            ratingAttrResponse
          );

          if (dealOfTheDay) {
            const dealOfTheDayAttrResponse =
              BadgesUtility.getByDealOfTheDayAttr(dealOfTheDay);

            dealOfTheDayAttrResponse &&
              parentChildPdpBadges.push.apply(
                parentChildPdpBadges,
                dealOfTheDayAttrResponse
              );
          }
        });

      let parentChildPdpBadgeList =
        BadgesUtility.getUniqueBadges(parentChildPdpBadges);

      /**
       * Top Seller Badge
       * orderByParam === 1 and the the first three product
       */
      if (
        (Number(orderByParam) === 1 || sortIndex === 1) &&
        Number(index) < TOP_SELLER_COUNT
      ) {
        let badgeObj: IBadgeObj;
        badgeObj = {
          label: TOP_SELLER,
        };
        badgeList.push(badgeObj);
        pdpBadgeList.push(badgeObj);
      }

      /**
       * Top Rated Badge
       * particular product with rating attr and the value should be greater than 4.5
       */
      const ratingAttrResponse = BadgesUtility.getByRatingAttr(
        rating,
        reviewCount
      );

      badgeList.push.apply(badgeList, ratingAttrResponse);

      pdpBadgeList.push.apply(pdpBadgeList, ratingAttrResponse);

      /**
       * Deal of the Day Badge
       * particular product with dealoftheday attr and the value should be same of today's date
       */
      const dealOfTheDayAttr = productCard?.attributes?.filter(
        (dealOfTheDay: IProductListContentAttributes) =>
          dealOfTheDay.identifier === DEAL_OF_THE_DAY
      );

      const dealOfTheDayAttrResponse =
        BadgesUtility.getByDealOfTheDayAttr(dealOfTheDayAttr);

      if (dealOfTheDayAttrResponse) {
        badgeList.push.apply(badgeList, dealOfTheDayAttrResponse);

        pdpBadgeList.push.apply(pdpBadgeList, dealOfTheDayAttrResponse);
      }

      /**
       * New, OnSale, Jumbo Size, Clerance, Exclusive
       * OnSale -- only for guest and normal registered users
       * product having Badge attribute and the value would be any of the above
       */
      const badgeAttr = productCard?.attributes?.filter(
        (badge: IProductListContentAttributes) => badge.identifier === BADGE
      );

      const jumboBadgeAttr = productCard?.attributes?.filter(
        (badge: IProductListContentAttributes) =>
          badge.identifier.toLowerCase() === JUMBO
      );

      const exclusiveBadgeAttr = productCard?.attributes?.filter(
        (badge: IProductListContentAttributes) =>
          badge.identifier.toLowerCase() === EXCLUSIVE_ATTR
      );

      if (jumboBadgeAttr && jumboBadgeAttr.length > 0) {
        const jumboBadgeAttrResponse =
          BadgesUtility.getJumboSizeBadge(jumboBadgeAttr);

        pdpBadgeList.push.apply(pdpBadgeList, jumboBadgeAttrResponse);

        badgeList.push.apply(badgeList, jumboBadgeAttrResponse);
      }

      if (exclusiveBadgeAttr && exclusiveBadgeAttr.length > 0) {
        const exclusiveBadgeAttrResponse =
          BadgesUtility.getExclusiveBadge(exclusiveBadgeAttr);

        pdpBadgeList.push.apply(pdpBadgeList, exclusiveBadgeAttrResponse);

        badgeList.push.apply(badgeList, exclusiveBadgeAttrResponse);
      }

      if (badgeAttr && badgeAttr.length > 0) {
        let badgeObj: IBadgeObj;

        isPdp &&
          badgeAttr.forEach((badge: IProductListContentAttributes) =>
            badge.values?.forEach((badgeValue: any) => {
              const badgesAttrResponse = BadgesUtility.getByBadgeAttr(
                badgeValue,
                userType
              );

              pdpBadgeList.push.apply(pdpBadgeList, badgesAttrResponse);
            })
          );

        badgeAttr.forEach((badge: IProductListContentAttributes) =>
          badge.values?.forEach((value) => {
            if (value.value === ON_SALE) {
              if (userType === 0 || userType === 1) {
                badgeObj = {
                  label: value.value,
                };
                badgeList.push(badgeObj);
              }
            }
            if (typeof value.value === 'string' && value.value !== ON_SALE) {
              badgeObj = {
                label: value.value,
              };
              badgeList.push(badgeObj);
            } else if (typeof value.value === 'object') {
              const badgeData = value.value as unknown as Array<string>;
              badgeData.forEach((badge) => {
                badgeObj = {
                  label: badge,
                };
                badgeList.push(badgeObj);
              });
            }
          })
        );
      }

      /**
       * Pieces or Pairs or Includes
       * paricular product has all the three it will sort the max of three
       */
      const piecePairsAttr = productCard?.attributes?.filter(
        (attr: IProductListContentAttributes) =>
          attr.name.includes(IS_PIECES) ||
          attr.name.includes(IS_PAIRS) ||
          attr.name.includes(IS_INCLUDE)
      );

      if (piecePairsAttr && piecePairsAttr.length > 0) {
        const sortByMaxAttr = piecePairsAttr.sort(
          (
            ela: IProductListContentAttributes,
            elb: IProductListContentAttributes
          ) => {
            if (Number(ela.values[0].value) > Number(elb.values[0].value)) {
              return -1;
            }
            if (Number(ela.values[0].value) < Number(elb.values[0].value)) {
              return 1;
            }
            return 0;
          }
        );

        /**
         * add suffix as pieces/pairs/included with values
         */
        const attrLabel = sortByMaxAttr.map(
          (attribute: IProductListContentAttributes) => {
            if (Number(attribute.values[0].value) > 0) {
              if (attribute.name.includes(IS_PIECES)) {
                return {
                  label: `${attribute.values[0].value} ${PIECES}`,
                };
              }
              if (attribute.name === IS_INCLUDE) {
                return {
                  label: `${attribute.values[0].value} ${INCLUDED}`,
                };
              }
              if (attribute.name.includes(IS_PAIRS)) {
                return {
                  label: `${attribute.values[0].value} ${PAIRS}`,
                };
              }
            }
            return {
              label: '',
            };
          }
        );

        const piecePairsInludedList = attrLabel.map(
          (badge: IBadgeObj) => badge.label
        );

        /**
         * Adding the sort sequence is piece, pairs or included is present
         */
        if (attrLabel.length > 0) {
          badgeMap.splice(7, 0, ...piecePairsInludedList);
        }

        /**
         * if piece and pairs exist in BadgeList -- negelect the least value
         */
        const pieceOrPairsList: IBadgeObj[] = [];
        let hasPieces: boolean = false;
        let hasPairs: boolean = false;
        attrLabel.forEach((ele: IBadgeObj) => {
          if (ele.label.length > 0) {
            if (ele.label.includes(PIECES)) {
              hasPieces = true;
              if (!hasPairs) {
                pieceOrPairsList.push(ele);
              }
            } else if (ele.label.includes(PAIRS)) {
              hasPairs = true;
              if (!hasPieces) {
                pieceOrPairsList.push(ele);
              }
            } else {
              pieceOrPairsList.push(ele);
            }
          }
        });

        badgeList = [...badgeList, ...pieceOrPairsList];
        pdpBadgeList = [...pdpBadgeList, ...pieceOrPairsList];
        parentChildPdpBadgeList = [
          ...parentChildPdpBadgeList,
          ...pieceOrPairsList,
        ];
      }

      /**
       * Responsible to sort the badge by specified sequence
       */
      const badgeOrdered = [...badgeMap].reverse();

      const badgeSorter = (a: IBadgeObj, b: IBadgeObj) => {
        return badgeOrdered.indexOf(a.label) - badgeOrdered.indexOf(b.label);
      };

      /**
       * product is available for Deal of the day -- neglecting the on Sale and Clearnce Badge
       */
      if (badgeList.length > 0) {
        badgeList.forEach((badgeName) => {
          const isDealOfTheDayAvilable =
            badgeName.label.includes(DEAL_OF_THE_DAY);

          if (isDealOfTheDayAvilable) {
            const updatedBadgeList = badgeList.filter(
              (badge) => !saleorClearance.includes(badge.label)
            );

            setBadgeSequence(updatedBadgeList);
          } else {
            setBadgeSequence(badgeList);
          }
        });
      }

      badgeList.sort(badgeSorter);
      badgeList.reverse();

      pdpBadgeList.sort(badgeSorter);
      pdpBadgeList.reverse();

      parentChildPdpBadgeList.sort(badgeSorter);
      parentChildPdpBadgeList.reverse();

      setBadgeSequence(
        BadgesUtility.getUniqueBadges(removeOnsaleBadge(badgeList))
      );

      pdpBadgeList.sort(badgeSorter);
      pdpBadgeList.reverse();

      setPdpBadgeSequence(
        BadgesUtility.getUniqueBadges(removeOnsaleBadge(pdpBadgeList))
      );

      parentChildPdpBadgeList.sort(badgeSorter);
      parentChildPdpBadgeList.reverse();

      setParentChildPdpBadgeSequence(
        removeOnsaleBadge(parentChildPdpBadgeList)
      );
    },
    [
      orderByParam,
      sortIndex,
      TOP_SELLER_COUNT,
      rating,
      reviewCount,
      removeOnsaleBadge,
      DEAL_OF_THE_DAY,
      JUMBO,
      EXCLUSIVE_ATTR,
      userType,
      TOP_SELLER,
      BADGE,
      isPdp,
      ON_SALE,
      IS_PIECES,
      IS_PAIRS,
      IS_INCLUDE,
      PIECES,
      INCLUDED,
      PAIRS,
      saleorClearance,
    ]
  );

  useEffect(() => {
    modifyBadgeSequence(productCard, index);
  }, [modifyBadgeSequence, productCard, index]);

  return { badgeSequence, pdpBadgeSequence, parentChildPdpBadgeSequence };
};

export { useBadge };
