import { useLocation } from 'react-router-dom';
import { CHECKOUT } from '../../../constants/routes';
import { CheckoutHooksConstants } from './CheckoutHooks.constants';

/**
 * Custom hook for functions related to the checkout flow.
 */
const useCheckout = () => {
  const { pathname } = useLocation();

  const { CHECKOUT_FLOWS } = CheckoutHooksConstants;

  const isCheckout = pathname.includes(CHECKOUT);

  const checkoutStep = CHECKOUT_FLOWS.indexOf(pathname);

  return { isCheckout, checkoutStep };
};

export { useCheckout };
