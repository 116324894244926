import { createAction } from '@reduxjs/toolkit';
import {
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  IResetPasswordRequest,
} from '../../_foundation/interface/Authentication/IAuth';
import * as ACTION_TYPES from '../action-types/forgot-reset-password.action-types';

const REQUEST_VERIFICATION_CODE_ACTION = createAction<IForgotPasswordRequest>(
  ACTION_TYPES.REQUEST_VERIFICATION_CODE
);

const VERIFICATION_CODE_RECEIVED_ACTION = createAction<any>(
  ACTION_TYPES.VERIFICATION_CODE_RECEIVED
);

const VERIFICATION_CODE_ERROR_ACTION = createAction<IForgotPasswordResponse>(
  ACTION_TYPES.VERIFICATION_CODE_ERROR
);

const RESET_VERIFICATION_CODE_ACTION = createAction(
  ACTION_TYPES.RESET_VERIFICATION_CODE
);

const RESET_PASSWORD_ACTION = createAction<IResetPasswordRequest>(
  ACTION_TYPES.RESET_PASSWORD
);

const RESET_PASSWORD_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.RESET_PASSWORD_SUCCESS
);

const TOGGLE_PASSWORD_COMPONENT_ACTION = createAction<boolean>(
  ACTION_TYPES.TOGGLE_PASSWORD_COMPONENT
);

const ADD_FORGOT_PASSWORD_EMAIL_ADDRESS_ACTION = createAction<string>(
  ACTION_TYPES.ADD_FORGOT_PASSWORD_EMAIL_ADDRESS
);

const RESET_FORGOT_RESET_PASSWORD_ACTION = createAction(
  ACTION_TYPES.RESET_FORGOT_RESET_PASSWORD
);

export {
  REQUEST_VERIFICATION_CODE_ACTION,
  VERIFICATION_CODE_RECEIVED_ACTION,
  VERIFICATION_CODE_ERROR_ACTION,
  RESET_VERIFICATION_CODE_ACTION,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  TOGGLE_PASSWORD_COMPONENT_ACTION,
  ADD_FORGOT_PASSWORD_EMAIL_ADDRESS_ACTION,
  RESET_FORGOT_RESET_PASSWORD_ACTION,
};
