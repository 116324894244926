import HelpIcon from '@material-ui/icons/Help';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CONTACT_US,
  CUSTOMER_HELP,
  MY_ORDERS,
  TRACK_ORDER,
} from '../../../../../constants/routes';
import {
  SUPPORT_CHAT_TEALIUM,
  SUPPORT_CUSTOMER_CARE_TEALIUM,
  SUPPORT_EMAIL_TEALIUM,
  SUPPORT_FAQ_TEALIUM,
  SUPPORT_MENU_CLICK_TEALIUM,
  SUPPORT_SALES_TEALIUM,
} from '../../../../../constants/Tealium';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { sendTealiumData } from '../../../../../utils/utils';
import { NteMenuList } from '../../../../Menu/NteMenuList/NteMenuList';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../../AppBar/NavMenuItem/NavMenuItem';
import { useSupportMenu } from './hooks/SupportMenuHooks';
import './scss/SupportMenu.scss';
import { SupportMenuConstants } from './SupportMenuConstants';

/**
 * @Component SupportMenu renders the Support Menu.
 */
const SupportMenu: React.FC = () => {
  const {
    TRACKING,
    CUSTOMER_HELP_TEXT,
    SALES,
    CUSTOMER_CARE,
    CHAT,
    EMAIL,
    SUPPORT_TITLE,
    SUPPORT_TITLE_FALLBACK,
  } = SupportMenuConstants;

  const { t, i18n } = useTranslation();

  const {
    RenderPopper: RenderSupportMenu,
    ntePopperClickHandler: supportClickHandler,
    showPopper: showSupportMenu,
  } = useNtePopper({
    id: 'support-menu',
    ariaLabelledById: 'support-menu-list',
  });

  const { supportMenuItems } = useSupportMenu();

  const supportRef = useRef(null);

  const history = useHistory();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const menuItemClickHandler = (selectedEvent: any): void => {
    if (typeof selectedEvent.currentItemIdentifier === 'string') {
      sendTealiumData({
        tealium_event: SUPPORT_MENU_CLICK_TEALIUM,
        nav_header_element: selectedEvent?.currentItemIdentifier,
      });
    }

    //  Update tealium event accordingly
    if (selectedEvent.currentItem === t(CUSTOMER_HELP_TEXT)) {
      sendTealiumData({
        tealium_event: SUPPORT_FAQ_TEALIUM,
      });
    }

    if (selectedEvent.currentItem === t(SALES)) {
      sendTealiumData({
        tealium_event: SUPPORT_SALES_TEALIUM,
      });
    }

    if (selectedEvent.currentItem === t(CUSTOMER_CARE)) {
      sendTealiumData({
        tealium_event: SUPPORT_CUSTOMER_CARE_TEALIUM,
      });
    }

    if (selectedEvent.currentItem === t(CHAT)) {
      sendTealiumData({
        tealium_event: SUPPORT_CHAT_TEALIUM,
      });

      setTimeout(() => {
        window.openMChat();
      }, 500);
    }

    if (selectedEvent.currentItem === t(EMAIL)) {
      sendTealiumData({
        tealium_event: SUPPORT_EMAIL_TEALIUM,
      });
    }

    /**
     * Redirect a signed in user to
     * My Orders page when Track Orders
     * is selected from the menu.
     */
    if (selectedEvent.currentItem === t(TRACKING)) {
      if (isUserAuthenticated) {
        history.push(MY_ORDERS);
      } else {
        history.push(TRACK_ORDER);
      }
    }

    if (selectedEvent.currentItem === t(CUSTOMER_HELP_TEXT)) {
      history.push(CUSTOMER_HELP);
    }

    if (selectedEvent.currentItem === t(EMAIL)) {
      history.push(CONTACT_US);
    }

    // Close the support menu when an item is clicked.
    supportClickHandler();
  };

  const supportMenuTitle = i18n.exists(SUPPORT_TITLE).valueOf()
    ? t(SUPPORT_TITLE)
    : SUPPORT_TITLE_FALLBACK;

  return (
    <>
      <NavMenuItem
        imgAlt='support-icon'
        menuAnchor={supportRef}
        menuIconSvg={<HelpIcon className='menu_icon' />}
        menuTitle={supportMenuTitle}
        onClick={supportClickHandler}
        disablePopperAction={showSupportMenu}
      />

      {showSupportMenu && (
        <RenderSupportMenu
          showPopper={showSupportMenu}
          ntePopperCloseHandler={supportClickHandler}
          anchor={supportRef}>
          <NteMenuList
            menuItems={supportMenuItems}
            menuListCloseHandler={supportClickHandler}
            menuTitle={supportMenuTitle}
            nteMenuClickHandler={menuItemClickHandler}
            id='support-menu-list'
          />
        </RenderSupportMenu>
      )}
    </>
  );
};

export { SupportMenu };
