import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the email address input field.
 */
const EmailValidator = {
  EMAIL: {
    name: 'email',
    id: 'email-address',
    label: 'Email Address',
    type: 'email',
    required: true,
    autoComplete: 'email',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Email address is required.'),
      (value: string) =>
        FieldValidations.UNICODE_EMAIL(value, 'Invalid character used.'),
      (value: string) =>
        FieldValidations.EMAIL(value, 'Email format is invalid.')
    ),
    fieldParse: FieldParsing.EMAIL,
    sensitiveData: true,
  },
};

const AlternateEmailValidator = {
  EMAIL: {
    name: 'email',
    id: 'email-address',
    label: 'Email Address',
    type: 'email',
    required: false,
    autoComplete: 'email',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.UNICODE_EMAIL(value, 'Invalid character used.'),
      (value: string) =>
        FieldValidations.EMAIL(value, 'Email format is invalid.')
    ),
    fieldParse: FieldParsing.EMAIL,
    sensitiveData: true,
  },
};

export { EmailValidator, AlternateEmailValidator };
