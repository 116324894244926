import { Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StorePickUpIcon } from '../../../../../../../theme/assets/delivery-icon-store-pickup.svg';
import { NteDrawer } from '../../../../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../../../../Forms/NteButton/NteButton';
import { StoreFinder } from '../../../../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/StoreFinder';
import { ProductCardConstants } from '../../../ProductCardConstants';
import { StoreStockConstants } from './StoreStockConstants';

interface IStoreStock {
  partNumber: string;
  productName?: string;
  showStoreStockDrawer: boolean;
  storeStockCloseHandler: () => void;
  label: any;
  isPDP?: boolean;
  showStoreOnly: boolean;
  soldAtStores: boolean;
  storePickupLoading?: boolean;
}

/**
 *
 * @returns Product Store Stock Component for Buy section.
 */
const StoreStock: React.FC<IStoreStock> = ({
  partNumber,
  productName,
  showStoreStockDrawer,
  storeStockCloseHandler,
  label,
  isPDP,
  showStoreOnly,
  soldAtStores,
  storePickupLoading,
}) => {
  const { t } = useTranslation();

  const { CHECK_STORES_PICKUP, STORE_PICKUP, IN_STORE_ONLY } =
    StoreStockConstants;

  const myStore = sessionStorage.getItem(
    t(ProductCardConstants.PRODUCT_CARD_SECTION3.STORE_PICKUP.MYSTORE)
  );

  const storeId = myStore && JSON.parse(myStore).storeId;

  return (
    <>
      {!storePickupLoading && label ? (
        <>
          <li className='icon-row store_stock'>
            <StorePickUpIcon
              className='store_pick_up_icon'
              role={'img'}
              aria-label='store pickup icon'
            />
            <div className='label-wrapper'>
              <h3 className='store-pickup'>
                {showStoreOnly ? t(IN_STORE_ONLY) : t(STORE_PICKUP)}&nbsp;
              </h3>
              <div className='store-stock-label'>{label}</div>
              {soldAtStores && (
                <>
                  {isPDP && (
                    <NteButton
                      onClick={() => {
                        storeStockCloseHandler();
                      }}
                      size='small'
                      className='field-margin check-store-stock'
                      translationKey={t(StoreStockConstants.CHECK_STORE_STOCK)}
                      variant='outlined'
                      type='button'
                    />
                  )}

                  {!isPDP && (
                    <>
                      {(Boolean(showStoreOnly) && Boolean(storeId)) ||
                        (Boolean(soldAtStores) && Boolean(storeId)) ? (
                        <button
                          className='inline-text link-effect'
                          onClick={storeStockCloseHandler}
                          type='button'>
                          {t(
                            ProductCardConstants.PRODUCT_CARD_SECTION3
                              .STORE_PICKUP.CHECK_STORE_STOCK
                          )}
                        </button>
                      ) : (
                        <button
                          className='inline-text link-effect'
                          onClick={storeStockCloseHandler}
                          type='button'>
                          {t(
                            ProductCardConstants.PRODUCT_CARD_SECTION3
                              .STORE_PICKUP.CHECK_STORES
                          )}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </li>

          <NteDrawer
            anchor='right'
            id='store-stock-drawer'
            className='store_stock-drawer'
            ariaLabelledById='store-locator-header'
            nteDrawerCloseHandler={storeStockCloseHandler}
            showNteDrawer={showStoreStockDrawer}>
            <Divider className='nte_popper-divider' />
            <div className='nte_popper-paper'>
              <StoreFinder
                menuTitle={t(CHECK_STORES_PICKUP)}
                storeFinderCloseHandler={storeStockCloseHandler}
                partNumber={partNumber}
                storePickup={true}
              />
            </div>
          </NteDrawer>
        </>
      ) : (
        <Typography>
          <Skeleton />
        </Typography>
      )}
    </>
  );
};

export { StoreStock };
