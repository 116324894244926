import { createAction } from '@reduxjs/toolkit';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { IContentsItem } from '../../../../../_foundation/interface/Responses/IProductsByPartNumbersResponse';
import { IDefiningAttributes } from '../../interface/ISwatch';
import * as ACTION_TYPES from '../action-types/swatch.action-types';

export const UPDATE_RESOLVED_SKU_PARTNUMBER_ACTION = createAction<string>(
  ACTION_TYPES.UPDATE_RESOLVED_SKU_PARTNUMBER
);

export const UPDATE_SKU_PRODUCT_DETAIL_ACTION = createAction<
  IContentsItem | IProductListContents | null
>(ACTION_TYPES.UPDATE_SKU_PRODUCT_DETAIL);

export const RESET_SWATCH_SKU_ACTION = createAction(
  ACTION_TYPES.RESET_SWATCH_SKU
);

export const UPDATE_DEFINING_ATTRIBUTES_ACTION = createAction<
  IDefiningAttributes[]
>(ACTION_TYPES.UPDATE_DEFINING_ATTRIBUTES);

export const RESET_DEFINING_ATTRIBUTES_ACTION = createAction(
  ACTION_TYPES.RESET_DEFINING_ATTRIBUTES
);
