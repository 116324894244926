import { Button } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  GREAT_PLAINS_MEMBERSHIP,
  NORTHERN_PREFERRED_MEMBERSHIP,
  PLATINUM_MEMBERSHIP,
} from '../../../../../../constants/routes';
import { HEADER_MENU_CLICK_TEALIUM } from '../../../../../../constants/Tealium';
import { authenticationSelector } from '../../../../../../redux/selectors/auth.selector';
import { sendTealiumData } from '../../../../../../utils/utils';
import { UserType } from '../../../../../../_foundation/enum/User/UserType';
import { NteMenuList } from '../../../../../Menu/NteMenuList/NteMenuList';
import { useNtePopper } from '../../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../../NavMenuItem/NavMenuItem';
import { DealsMenuItemConstants } from './DealsMenuItemConstants';

/**
 * @interface IDealsMenuItem
 */
interface IDealsMenuItem {
  /**
   * @prop showDealsMenu Determines whether to render deals menu or not.
   */
  showDealsMenu: boolean;

  /**
   * @prop dealsHtml Html that needs to be rendered.
   */
  dealsHtml: string | JSX.Element | JSX.Element[];
}

/**
 * @component DealsMenuItem loads the menu
 * items for the deals from espot.
 *
 * @param IDealsMenuItem
 */
const DealsMenuItem: React.FC<IDealsMenuItem> = ({
  dealsHtml,
  showDealsMenu,
}) => {
  const { DEALS_TITLE } = DealsMenuItemConstants;

  const menuRef = useRef(null);

  const history = useHistory();

  const { userType } = useSelector(authenticationSelector);

  const { RenderPopper, ntePopperClickHandler, showPopper, setShowPopper } =
    useNtePopper({
      id: `deals-menu`,
      ariaLabelledById: 'deals-menu-list',
    });

  const { t } = useTranslation();

  /**
   * @method memberShipHandler
   *
   * Responsible to redirect corresponding membership page
   */
  const memberShipHandler = () => {
    if (userType === UserType.Platinum) {
      history.push(PLATINUM_MEMBERSHIP);
    }

    if (userType === UserType.GreatPlains) {
      history.push(GREAT_PLAINS_MEMBERSHIP);
    }

    if (userType === UserType.NorthernPreferred) {
      history.push(NORTHERN_PREFERRED_MEMBERSHIP);
    }
  };

  useEffect(() => {
    /**
     * Close the deals menu popper when the user navigates to another route.
     */
    return () => {
      history.listen(() => {
        setShowPopper(false);
      });
    };
  }, [history, ntePopperClickHandler, setShowPopper]);

  /**
   * @method menuItemClickHandler is responsible for handling
   * the click events when the menu item is clicked.
   */
  const menuItemClickHandler = (selectedEvent?: any): void => {
    if (!selectedEvent?.backAction) {
      sendTealiumData({
        tealium_event: HEADER_MENU_CLICK_TEALIUM,
        nav_header_element: selectedEvent?.currentItemIdentifier,
      });
    }
  };

  return (
    <>
      {showDealsMenu ? (
        <NavMenuItem
          menuAnchor={menuRef}
          menuTitle={t(DEALS_TITLE)}
          onClick={ntePopperClickHandler}
          titleVariant='h2'
          disablePopperAction={showPopper}
        />
      ) : (
        <li className={`nav_menu_item cursor-pointer`}>
          <Button
            onClick={memberShipHandler}
            className='no-link-effect menu-button'>
            <div ref={menuRef}>
              <span dangerouslySetInnerHTML={{ __html: t(DEALS_TITLE) }} />
            </div>
          </Button>
        </li>
      )}

      {showPopper && (
        <RenderPopper
          anchor={menuRef}
          showPopper={showPopper}
          ntePopperCloseHandler={ntePopperClickHandler}>
          <NteMenuList
            menuItems={dealsHtml}
            menuListCloseHandler={ntePopperClickHandler}
            menuTitle={t(DEALS_TITLE)}
            nteMenuClickHandler={menuItemClickHandler}
            isRootMenu={false}
            id='deals-menu-list'
          />
        </RenderPopper>
      )}
    </>
  );
};

export { DealsMenuItem };
