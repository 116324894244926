const TRANSLATION_KEY = 'CHECKOUT.DELIVERY.SHIPPING_INFO.DELIVERY_OPTIONS';
const ADVANTAGE_PROMO = 'CHECKOUT.ADVANTGAE_PROMO';

const ShippingMethodOptionsConstants = {
  TRANSLATIONS: {
    FREIGHT: `${TRANSLATION_KEY}.FREIGHT`,
    BEFORE_1PM: `CHECKOUT.DELIVERY.SHIPPING_INFO.SDD_MESSAGES.BEFORE_1PM`,
    AFTER_1PM: `CHECKOUT.DELIVERY.SHIPPING_INFO.SDD_MESSAGES.AFTER_1PM`,
  },
  ADVANTAGE_PROMO: {
    STANDARD: {
      SAVE_X: `${ADVANTAGE_PROMO}.STANDARD.SAVE_X`,
      SAVE_X_IOM: `${ADVANTAGE_PROMO}.STANDARD.SAVE_X_IOM`,
      ADVANTAGE_MEMBERSHIP_PLAN: `${ADVANTAGE_PROMO}.STANDARD.ADVANTAGE_MEMBERSHIP_PLAN`,
      ADVANTAGE_MEMBERSHIP_UPSELL_LINE: `${ADVANTAGE_PROMO}.STANDARD.ADVANTAGE_MEMBERSHIP_UPSELL_LINE`,
    },
    FREIGHT: {
      FRIEGHT_SAVE_X: `${ADVANTAGE_PROMO}.FREIGHT.FRIEGHT_SAVE_X`,
      BENEFITS: `${ADVANTAGE_PROMO}.FREIGHT.BENEFITS`,
      MEMBER_SHIP_PLAN: `${ADVANTAGE_PROMO}.FREIGHT.MEMBER_SHIP_PLAN`,
      PRICE: `${ADVANTAGE_PROMO}.FREIGHT.PRICE`,
    },
    REMOVE_PROMO: {
      MEMBER_SHIP_ADDED: `${ADVANTAGE_PROMO}.REMOVE_PROMO.MEMBER_SHIP_ADDED`,
      MEMBERSHIP_PLAN: `${ADVANTAGE_PROMO}.REMOVE_PROMO.MEMBERSHIP_PLAN`,
    },
  },
  ADD_TO_CART: `${ADVANTAGE_PROMO}.ADD_TO_CART`,
  REMOVE: `${ADVANTAGE_PROMO}.REMOVE`,
  SEE_DETAILS: `${ADVANTAGE_PROMO}.SEE_DETAILS`,
  STANDARD_MODE: 'Standard',
  ADVANTAGE_PROMO_PARTNUMBER: 'HL-R',
  ADVANTAGE_TOOGLE: 'true',
  FREE_COST: 'FREE',
  ADVANTAGE_MEMBERSHIP_APPLIED:
    'CHECKOUT.DELIVERY.SHIPPING_INFO.MEMBER_PROGRAMS.ADVANTAGE',
  CONT: 'CONT',
  NULL: 'null',
  SDD_METHOD: 'Same Day Delivery',
  GIFTCARD_PAY_METHOD: 'GiftCard',
  TRUCK: 'TRCK',
  PARCEL: 'PARCEL',
  FACTORY_SHIPPED: 'DELIVERY_OPTION.FACTORY_SHIPPED',
  TRUCK_METHOD: 'truck',
};

export default ShippingMethodOptionsConstants;
