import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NteAccordion } from '../../../Widgets/Accordion/NteAccordion';
import { FooterLinksConstants } from '../FooterLinksConstants';
import { useEspotFooterLinks } from '../Hooks/useEspotFooterLinks';

const CustomerCareLinks: React.FC = () => {
  const E_SPOT_NAME = 'FOOTER_01A';

  const { parsedEspotLinks: customerCareLinks } =
    useEspotFooterLinks(E_SPOT_NAME);

  /* Initialize Translation */
  const { t, i18n } = useTranslation();

  const { CUSTOMER_CARE, CUSTOMER_CARE_FALLBACK } = FooterLinksConstants;

  const title = i18n.exists(CUSTOMER_CARE).valueOf()
    ? t(CUSTOMER_CARE)
    : CUSTOMER_CARE_FALLBACK;
  return (
    <Box className='footer_links_block customer-care'>
      <div className='tablet-only'>
        <Typography variant='h3'>{title}</Typography>
        <ul>{customerCareLinks.map((link: JSX.Element) => link)}</ul>
      </div>
      <Hidden smUp>
        <NteAccordion
          accordionTitle={title}
          defaultExpanded={false}
          className='no-elevation'>
          <ul className='link-list'>
            {customerCareLinks.map((link: JSX.Element) => link)}
          </ul>
        </NteAccordion>
      </Hidden>
    </Box>
  );
};

export default CustomerCareLinks;
