import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductPrice } from '../../../utils/product-utility';
import { checkTruthy } from '../../../utils/utils';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { PdpPageConstants } from '../../PDP/PdpPageConstants';
import { EntryTypeCode } from '../../Widgets/Plp/ProductCard/ProductCardSection';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';

interface ISeeCartPrice {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  productPrice?: IProductPrice;
}

const SeeCartPrice: React.FC<ISeeCartPrice> = ({
  productCard,
  productPrice,
}) => {
  const { SALE_PRICE_CART, FROM_REGULAR_PRICE } =
    ProductCardSectionMessagesConstants;

  const {
    ATTRIBUTES: { PRODUCT },
  } = PdpPageConstants;

  const { t } = useTranslation();

  const showSeeCartPriceMsg = checkTruthy(productCard.salesPriceRestricted);

  const noOfferPrice =
    productPrice &&
    (!productPrice.formattedOfferPrice ||
      productPrice.formattedOfferPrice.length === 0);

  const isParentChildProduct =
    productCard &&
    (productCard.type?.toLowerCase() === PRODUCT ||
      productCard.catalogEntryTypeCode === EntryTypeCode.PRODUCTBEAN);

  const noSwatchPrice = isParentChildProduct && productPrice?.swatchNoPrice;

  const swatchableItems =
    productCard && productCard?.sKUs ? productCard?.sKUs : productCard?.items;

  const buyableWithNoPrice = useMemo(
    () =>
      (checkTruthy(productCard?.buyable) && swatchableItems
        ? noSwatchPrice
        : Boolean(noOfferPrice)) ||
      !checkTruthy(productCard?.buyable) ||
      Boolean(!productCard?.attributes),
    [
      noOfferPrice,
      noSwatchPrice,
      productCard?.attributes,
      productCard?.buyable,
      swatchableItems,
    ]
  );

  const isParentChildNoSkus = isParentChildProduct
    ? !Boolean(productCard?.items)
      ? !Boolean(productCard?.sKUs)
      : productCard.sKUs?.length === 0
      ? productCard.items?.length === 0
      : false
    : false;

  return (
    <>
      {showSeeCartPriceMsg && (
        <div className={`price-cell ${showSeeCartPriceMsg ? 'block' : ''}`}>
          <div className='nte-price24 price product-offer-price offer-price'>
            {t(SALE_PRICE_CART)}
          </div>
          {!buyableWithNoPrice && !isParentChildNoSkus && (
            <p className='sub-product-offer-price pxs reg-save-pricing'>
              {t(FROM_REGULAR_PRICE, {
                formattedOfferPrice: productPrice?.formattedOfferPrice,
              })}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export { SeeCartPrice };
