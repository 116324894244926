const StoreStockConstants = {
  CHECK_STORES_PICKUP:
    'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.CHECK_STORES_PICKUP',
  STORE_PICKUP:
    'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRODUCT_SHIPPING.STORE_PICKUP',
  CHECK_STORE_STOCK: 'DELIVERY_OPTION.TRANSLATION_KEY.CHECK_STORE_STOCK',
  IN_STORE_ONLY:
    'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRODUCT_SHIPPING.IN_STORE_ONLY',
};

export { StoreStockConstants };
