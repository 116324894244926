const ProductCompareConstants = {
  TRANSLATIONS: {
    SPECIFICATIONS_TITLE: 'PRODUCT_COMPARE.SPECIFICATIONS_TITLE',
    HIGHLIGHT_DIFFERENCES: 'PRODUCT_COMPARE.HIGHLIGHT_DIFFERENCES',
    COMPARE_PRODUCTS_TITLE: 'PRODUCT_COMPARE.COMPARE_PRODUCTS_TITLE',
  },
  PRODUCT_COMPARE_PAGE_NUMBER: 1,
  PRODUCT_COMPARE_PAGE_SIZE: 3,
  PROFILE_NAME: process.env.REACT_APP_LIMITED_PRODUCT_DATA_PROFILE_NAME,
  SEE_ALL: 'PRODUCT_LIST_PAGE.PRODUCT_COMPARISON_BAR.SEE_ALL',
};

export { ProductCompareConstants };
