/**
 * These are dummy Values as they will fetched from Espot.
 */
export const FooterConstants = {
  CUSTOMER_CARE: [
    'MY Account',
    'MY Orders',
    'MY Saved Items',
    'Help',
    'Contact Us',
  ],
  RESOURCES: [
    'Site Map',
    'All Products',
    'All Maps',
    'ALl Deals',
    'All Brands',
    'Find a Store',
    'Free Catalog',
  ],
  FOOTER_BOTTOM_TEXT_1: 'FOOTER.FOOTER_BOTTOM_TEXT_1',
  FOOTER_BOTTOM_TEXT_2: 'FOOTER.FOOTER_BOTTOM_TEXT_2',
  FOOTER_BOTTOM_CONTACT_NO: 'FOOTER.FOOTER_BOTTOM_CONTACT_NO',
  FOOTER_CONTACT_NO: '18002210516',
  FOOTER_BOTTOM_TEXT_1_FALLBACK:
    '2800 Southcross Drive W | Burnsville, MN 55306 |',
  FOOTER_BOTTOM_TEXT_2_FALLBACK:
    'Copyright © Northern Tool + Equipment. All Rights Reserved.',
  FOOTER_BOTTOM_CONTACT_NO_FALLBACK: '1-800-221-0516',
};
