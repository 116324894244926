import {
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { enterKeyPressHandler } from '../../../../../../utils/utils';


/**
 * @interface IProductPricingTable
 */
interface IProductPricingTable {
  itemQuantities: string[];
  itemPrices: string[];
  closeHandler?: () => void;
  isPDP?: boolean;
}

/**
 * @component ProductPricingTable renders the pricing table for a cart item.
 *
 * @param IProductPricingTable
 */

const ProductPricingTable: React.FC<IProductPricingTable> = ({
  itemPrices,
  itemQuantities,
  closeHandler,
  isPDP,
}) => {
  let rowData = [];

  for (let i = 0; i < itemPrices.length; i++) {
    rowData.push(
      <TableRow>
        <TableCell className='align-center' key={i}>
          {itemQuantities[i]} units
        </TableCell>
        <TableCell key={i + 1}>
          <span className='price price18'>{itemPrices[i]}</span>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {!isPDP && (
        <div className='align-right'>
          <IconButton
            onClick={closeHandler}
            onKeyDown={(e) => enterKeyPressHandler(e, closeHandler)}
            className='cursor-pointer close_icon'
            aria-label='close'>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {rowData.length > 0 && (
        <Table className='nte_table cart_pricing_table'>
          <TableContainer>{rowData}</TableContainer>
        </Table>
      )}
    </>
  );
};
export { ProductPricingTable };
