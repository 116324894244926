import React, { useEffect } from 'react';
import {
  SCROLL_100,
  SCROLL_25,
  SCROLL_50,
  SCROLL_75,
} from '../../../constants/Tealium';
import { sendTealiumData } from '../../../utils/utils';

/**
 * @component Reset scroll data events
 */
const resetHeightValues = () => {
  sent25 = false;
  sent50 = false;
  sent75 = false;
  sent100 = false;
};
let sent25: boolean = false;
let sent50: boolean = false;
let sent75: boolean = false;
let sent100: boolean = false;

/**
 * @component Send scroll data events
 */
const ScrollDataListner: React.FC = () => {
  useEffect(() => {
    window.addEventListener('scroll', function (event) {
      let scrollPos = (
        document.documentElement.scrollTop /
        (document.body.scrollHeight - window.innerHeight)
      ).toFixed(1);
      if (!sent25 && scrollPos === '0.2') {
        sent25 = true;
        sendTealiumData({
          tealium_event: SCROLL_25,
        });
      }
      if (!sent50 && scrollPos === '0.5') {
        sent50 = true;
        sendTealiumData({
          tealium_event: SCROLL_50,
        });
      }
      if (!sent75 && scrollPos === '0.8') {
        sent75 = true;
        sendTealiumData({
          tealium_event: SCROLL_75,
        });
      }
      if (!sent100 && scrollPos === '1.0') {
        sent100 = true;
        sendTealiumData({
          tealium_event: SCROLL_100,
        });
      }
    });
  }, []);

  return null;
};

export { ScrollDataListner, resetHeightValues };
