import { OutlinedInputProps, OutlinedTextFieldProps } from '@material-ui/core';
import { Field } from 'react-final-form';
import { DefaultValidator } from '../../../../utils/validators/default.validation';
import { TextInput } from '../../TextInput/TextInput';
import { IFieldProps } from './INteInputField';

/**
 * @interface NteInputFieldProps
 */
export interface NteInputFieldProps {
  name: string;

  id: string;

  label: string;

  required?: boolean;

  type: string;

  /**
  *To identify whether input is from shipping adress or not
  */
  isShippingAddress?: boolean;

  /**
   * Custom Validate Function for the Input field.
   */
  fieldValidate?: (value: string) => string | undefined;

  /**
   * Format Function for the Input field.
   */
  fieldFormat?: (value: string) => string;

  /**
   * Parse Function to parse the input value before validation and sent to backend.
   */
  fieldParse?: (value: string) => string;

  /**
   * @prop disabled states whether to disable the text Input.
   */
  disabled?: boolean;

  /**
   * @prop getCurrentValue Fetches the current field value.
   */
  getCurrentValue?: (value: string) => void;

  /**
   * @prop getCurrentValue Fetches the current field value.
   */
  className?: string;

  /**
   * @prop anchor Pass in the reference of the anchor element
   * with which the popper will be rendered as a reference.
   */
  anchor?: React.MutableRefObject<any>;

  /**
   * onFocus callback
   */
  onFocus?: () => void;

  /**
   * onBlur callback
   */
  onBlur?: () => void;

  /**
   * onKeyPress callback
   */
  onKeyPress?: (e: any) => void;

  /**
   * onChange callback
   */
  onChange?: OutlinedTextFieldProps['onChange'];

  /**
   * onBlur callback
   */
  disableAutoComplete?: boolean;

  /**
   * minLength for field
   */
  minLength?: number;

  /**
   * maxLength for field
   */
  maxLength?: number;

  /**
   * autocomplete for field
   */
  autoComplete?: string;

  sensitiveData?: boolean;

  InputProps?: Partial<OutlinedInputProps>;

  /**
   * setting for auto focus on initial load
   */
  autoFocus?: boolean;

  keyBoardType?:
    | 'search'
    | 'none'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | undefined;
  // Living Standard
  /**
   * Hints at the type of data that might be entered by the user while editing the element or its contents
   * @see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
}

/**
 *
 * @param NteFormInputFieldProps
 * @returns NteFormInputField Component.
 */
const NteInputField: React.FC<NteInputFieldProps> = ({
  name,
  id,
  isShippingAddress,
  fieldValidate,
  fieldFormat,
  fieldParse,
  label,
  required,
  anchor,
  disabled,
  type,
  onBlur,
  onFocus,
  onChange,
  getCurrentValue,
  onKeyPress,
  disableAutoComplete,
  className = '',
  maxLength,
  minLength,
  autoComplete,
  autoFocus,
  InputProps,
  sensitiveData = false,
  keyBoardType,
  inputMode,
  ...params
}) => {
  const {
    INPUT_VALIDATIONS: {
      specialInputCharacters,
      maxInputCharacters,
      maxInputCharactersWithValues,
      numberValidation,
      phoneNumberValidation,
      phoneOutsideUS,
      emailInputValidation,
      alphaNumericValidation,
      cityValidation,
      addressValidation,
    },
  } = DefaultValidator;

  /**
   *
   * @param value
   * @returns validation error string when criteria is not met and an empty string when criteria is met.
   */
  const validateHandler = (value: string): string | undefined => {
    if (name.includes('city')) {
      if (fieldValidate && cityValidation(value) === '')
        return fieldValidate(value);
      return cityValidation(value);
    }
    if (name.includes('phoneOutsideUS')) {
      if (fieldValidate && phoneOutsideUS(value) === '')
        return fieldValidate(value);
      return phoneOutsideUS(value);
    }

    if (name === 'orderNumber') {
      if (fieldValidate && alphaNumericValidation(value) === '')
        return fieldValidate(value);
    } else if (name.includes('Number')) {
      if (name.includes('phoneNumber')) {
        if (fieldValidate && phoneNumberValidation(value) === '')
          return fieldValidate(value);
        return phoneNumberValidation(value);
      }
      if (fieldValidate && numberValidation(value) === '')
        return fieldValidate(value);
      return numberValidation(value);
    } else if (
      name.includes('catalog') ||
      name.includes('item') ||
      name.includes('qty')
    ) {
      if (fieldValidate && alphaNumericValidation(value) === '')
        return fieldValidate(value);
      return alphaNumericValidation(value);
    } else if (name.includes('email')) {
      if (fieldValidate && emailInputValidation(value) === '')
        return fieldValidate(value);
      return emailInputValidation(value);
    } else if (name.includes('streetAddress')) {
      if (
        fieldValidate &&
        addressValidation(value, '', isShippingAddress) === ''
      )
        return fieldValidate(value);
      return addressValidation(value, '', isShippingAddress);
    } else if (fieldValidate && specialInputCharacters(value) === '') {
      return fieldValidate(value);
    }
    return specialInputCharacters(value);
  };

  /**
   *
   * @param value
   * @returns an input field where a predetermined number of characters are to be entered.
   */

  const validateParse = (value: string): any => {
    if (maxLength) return maxInputCharactersWithValues(maxLength, value);
    else if (name.includes('customerNumber'))
      return maxInputCharactersWithValues(8, value);
    else if (name.includes('giftCardNumber'))
      return maxInputCharactersWithValues(19, value.replace(/ /g, ''));
    else if (name.includes('giftCardPin'))
      return maxInputCharactersWithValues(4, value);
    else if (name.includes('phoneNumber'))
      return maxInputCharactersWithValues(20, value);
    else if (fieldParse) return fieldParse(value);
    else return maxInputCharacters(value);
  };

  return (
    <Field
      name={name}
      className={className}
      validate={(value) => validateHandler(value)}
      format={fieldFormat}
      parse={(value) => validateParse(value)}>
      {({ input, meta }) => {
        const fieldProps: IFieldProps = {
          id,
          error: Boolean(
            (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
            meta.touched
          ),
          helperText:
            meta.touched &&
            (meta.error || (!meta.dirtySinceLastSubmit && meta.submitError)),
          onChange: (e) => {
            if (onChange) {
              onChange(e);
            }
            input.onChange(e);
          },
          value: input.value,
          onKeyPress: (e) => {
            if (onKeyPress) {
              onKeyPress(e);
            }
          },
          onBlur: () => {
            if (onBlur) {
              onBlur();
            }
            input.onBlur();
          },
          name: input.name,

          label,
          type,
          ...{
            ...(disableAutoComplete && {
              autoComplete: 'new-password',
            }),
            ...(!disableAutoComplete && {
              autoComplete: autoComplete,
            }),
          },
        };

        if (getCurrentValue) {
          getCurrentValue(input.value);
        }

        return (
          <TextInput
            autoFocus={autoFocus}
            onFocus={onFocus}
            anchor={anchor}
            {...fieldProps}
            InputProps={{ ...InputProps }}
            disabled={disabled}
            className={className}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
            keyBoardType={keyBoardType}
            inputMode={inputMode}
            {...params}
            autoComplete={autoComplete}
            sensitiveData={sensitiveData}
          />
        );
      }}
    </Field>
  );
};

export { NteInputField };
