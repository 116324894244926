export const PLP_COMPARE_BAR_TEALIUM = 'plp_compare_view';
export const PLP_COMPARE_BAR_FINDING_METHOD_TEALIUM = 'comparison tool';

export const PLP_FILTER_TEALIUM = 'plp_filter';
export const PLP_FILTER_FINDING_METHOD_TEALIUM = 'filter';

export const PLP_COMPARE_SELECT_TEALIUM = 'plp_compare_select';

export const PLP_SAVE_LIST_TEALIUM = 'plp_save_to_list';

export const PLP_SORT_TEALIUM = 'plp_sort';
export const PLP_SORT_FINDING_METHOD_TEALIUM = 'sort';

export const PLP_LOAD_MORE_TEALIUM = 'plp_load_more';
export const PLP_SHOP_ALL_TEALIUM = 'plp_shop_all';

export const CHECKOUT_SIGNIN_TEALIUM = 'checkout_sign-in';

export const CHECKOUT_DELIVERY_TEALIUM = 'checkout_delivery-options';

export const CHECKOUT_GUEST_CREATE_SHIPPING_ADDRESS_TEALIUM =
  'checkout_guest_create_shipping_address';

export const CHECKOUT_PAYMENT_METHOD_TEALIUM = 'checkout_payment_method';

export const CHECKOUT_REVIEW_TEALIUM = 'checkout_review';

export const CHECKOUT_CUSTOMER_TYPE = 'returning';

export const CHECKOUT_GUEST_CUSTOMER_TYPE = 'guest';

export const CART_ADD_TEALIUM = 'cart_add';

export const CART_REMOVE_TEALIUM = 'cart_remove';

export const CART_VIEW_TEALIUM = 'cart_view';

export const CART_MINI_VIEW_TEALIUM = 'cart_mini_view';

export const CART_SEE_PLAN_BENEFITS_TEALIUM = 'cart_see_plan_benefits';

export const CART_SAVE_FOR_LATER_TEALIUM = 'cart_save_for_later';

export const CART_COMPLETE_YOUR_SOLUTION_TEALIUM =
  'cart_complete_your_solution';

export const CART_DELIVERY_SHIPPING_CHECK_TEALIUM =
  'cart_delivery_shipping_check';

export const CART_DELIVERY_CHECK_STORES_TEALIUM = 'cart_delivery_check_stores';

export const CART_DELIVERY_STORE_SELECTED_TEALIUM =
  'cart_delivery_store_selected';

export const CART_DELVERY_VIEW_PICKUP_DETAILS_TEALIUM =
  'cart_delivery_view_pickup_details';

export const CART_PROMO_CODE_APLLIED_TEALIUM = 'cart_promo_code_applied';

export const CART_PROMO_CODE_INVALID_TEALIUM = 'cart_promo_code_invalid';
export const STORE_SELECTED = 'storefinder_store_selected';
export const STORE_FIND_ZIP = 'storefinder_search_by_zip';
export const STORE_FIND_STATE = 'storefinder_search_by_state';
export const STORE_NAME_NUM = (city: string, storeNumber: string) => {
  return city + ' (Store #' + storeNumber + ')';
};
export const FORM_START = 'form_start';
export const FORM_SUBMIT = 'form_submit';
export const FORM_FIELD_CHANGE = 'form_field_change';
export const FIRST_NAME_FIELD = 'first_name';
export const LAST_NAME_FIELD = 'last_name';
export const ADDRESS_FIELD = 'address';
export const EMAIL_ADDRESS_FIELD = 'email_address';
export const PHONE_NUMBER_FIELD = 'phone_number';

export const CREATE_ACCOUNT = 'create account';
export const ACCOUNT_LOGIN = 'account_login';
export const ACCOUNT_EDIT_BILLING = 'account_edit_billing_address';
export const ACCOUNT_ADD_NET_30 = 'account_add_net_30';
export const ACCOUNT_ADD_CARD = 'account_add_card';
export const ACCOUNT_CHANGE_PASSWORD = 'account_change_password';
export const ACCOUNT_PAST_ORDERS = 'account_view_past_orders';
export const FORM_ERROR = 'form_error';

export const REQ_CATALOG = 'request catalog';
export const ORDER_CATALOG = 'order from catalog';
export const ORDER_CAT_ERROR = 'a valid number is required';

export const PURCHASE_TEALIUM = 'purchase';
export const MEDIA_MODAL_OPEN_TEALIUM = 'media_modal_open';
export const MEDIA_MODAL_TAB_CHANGE_TEALIUM = 'media_modal_tab_change';
export const MEDIA_MODAL_IMAGE_SELECT = 'media_modal_image_select';

export const FOOTER_EMAIL_SIGNUP_TEALIUM = 'email deals';

export const PWA_INSTALL_TEALIUM = 'nte_app_download';
export const PWA_LAUNCHED_TEALIUM = 'nte_app_launch';

export const HEADER_MENU_CLICK_TEALIUM = 'nav_header_click';
export const SUPPORT_MENU_CLICK_TEALIUM = 'nav_header_click';
export const FOOTER_MENU_CLICK_TEALIUM = 'nav_footer_click';

export const PROMO_IMPRESSION_TEALIUM = 'promo_impression';
export const PROMO_CLICK_TEALIUM = 'promo_click';

export const SUPPORT_FAQ_TEALIUM = 'support_faq_interaction';
export const SUPPORT_SALES_TEALIUM = 'support_sales_phone_link';
export const SUPPORT_CUSTOMER_CARE_TEALIUM = 'support_customer_care_phone_link';
export const SUPPORT_TEXT_TEALIUM = 'support_text_link';
export const SUPPORT_CHAT_TEALIUM = 'support_chat_start';
export const SUPPORT_EMAIL_TEALIUM = 'support_email_start';

export const FORM_SUBMIT_TEALIUM = 'form_submit';
export const FORM_ERROR_TEALIUM = 'form_error';
export const GUEST_ORDER_FORM_TEALIUM = 'guest order lookup';
export const SIGN_IN_FORM_TEALIUM = 'sign in form';

export const SCROLL_25 = 'scroll_25';
export const SCROLL_50 = 'scroll_50';
export const SCROLL_75 = 'scroll_75';
export const SCROLL_100 = 'scroll_100';
export const EXIT_LINK = 'exit_link';
export const DOWNLOAD_DOC = 'download_document';
export const MANUAL_TYPE = 'manual';
export const CHART_TYPE = 'chart';
export const MSDS_TYPE = 'msds';

export const SITE_SEARCH = 'site_search';
export const SITE_SEARCH_RESULTS = 'site_search_results';
export const SITE_SEARCH_ZERO = 'site_search_zero_results';
export const SITE_SEARCH_PAGE = 'site_search_pagination_click';
export const SITE_SEARCH_CLICK_AFTER = 'site_search_click_after_search';

export const PDP_CHECK_STORE_STOCK = 'product_view_check_store_stock';
export const PDP_PRINT = 'product_view_print_product_detais';
export const PDP_VIEW_IMAGE_SELECT = 'product_view_media_image_select';
export const PDP_VIEW_DESCRIPTION = 'product_view_full_description_view';
export const PDP_VIEW_DETAILS = 'product_view_details_view';
export const PDP_CHECK_SHIPPING = 'product_view_check_shipping';
export const PDP_VIEW_CLICK_TYPE = 'keyspecs';
export const PDP_VIEW_EVENT = 'product_view';
export const PDP_MERCH_TYPE = 'brand';
