import { useCallback, useEffect, useState } from 'react';
import { yottaService } from '../../../../_foundation/apis/transaction/yotta/yotta.service';
import {
  CANADA_COUNTRY_CODE,
  MEXICO_COUNTRY_CODE,
  UNITED_STATES_COUNTRY_CODE,
} from '../../../../_foundation/constants/countryCodes';
import { useNteModal } from '../../../NteModal/hooks/NteModalHooks';

/**
 * @hook useEdealsModal
 *  responsible for determining eligibility+display of the edeals signup modal
 */
const useEdealsModal = () => {
  const [isEligible, setIsEligible] = useState<boolean>(false);

  const { countryCode } = yottaService.getGeoLocation();

  const edealsCookie = localStorage.getItem('emailSignUpLayer');

  /**
   * @method
   * Responsible to identify the user eligibility
   */
  const setUserEligible = useCallback(() => {
    const isUserEligible =
      countryCode === UNITED_STATES_COUNTRY_CODE ||
      countryCode === CANADA_COUNTRY_CODE ||
      countryCode === MEXICO_COUNTRY_CODE;

    setIsEligible(isUserEligible);
  }, [countryCode]);

  useEffect(() => {
    setUserEligible();
  }, [setUserEligible]);

  const edealsEligible = useCallback((): boolean => {
    if (!edealsCookie && isEligible) {
      const expireDate = new Date();
      const time = expireDate.getTime();
      const expireTime = time + 1000 * 15552000;
      expireDate.setTime(expireTime);

      let data = { isEligible: isEligible, expire: expireTime };
      localStorage.setItem('emailSignUpLayer', JSON.stringify(data));

      return true;
    } else {
      if (edealsCookie) {
        let expireTime = JSON.parse(edealsCookie || '').expire;
        let currentTime = new Date().getTime();

        if (currentTime > expireTime) {
          const expireTime = currentTime + 1000 * 15552000;
          let data = { isEligible: isEligible, expire: expireTime };
          localStorage.setItem('emailSignUpLayer', JSON.stringify(data));
          return true;
        }
      }
    }
    return false;
  }, [isEligible, edealsCookie]);

  const {
    RenderModal: RenderEdealsModal,
    nteModalClickHandler: edealsClickHandler,
    showNteModal: showEdealsModal,
  } = useNteModal({
    ariaLabelledById: 'edeals-signup',
    id: 'edeals-signup-modal',
  });

  useEffect(() => {
    if (edealsEligible()) {
      edealsClickHandler();
    }
  }, [edealsClickHandler, edealsEligible]);

  return { RenderEdealsModal, edealsClickHandler, showEdealsModal };
};

export default useEdealsModal;
