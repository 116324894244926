import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * @component ScrollToTop scrolls the page to the top on path change.
 */
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return null;
};

export { ScrollToTop };
