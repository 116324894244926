import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the city input field.
 */
const CityValidator = {
  CITY: {
    name: 'city',
    id: 'city',
    label: 'City*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) => FieldValidations.REQUIRED(value, 'City is required.'),
      (value: string) =>
        FieldValidations.CITY(
          value,
          'City can only be of maximum 30 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.CITY,
    disableAutoComplete: true,
    sensitiveData: true,
  },
};

export default CityValidator;
