import { AxiosRequestConfig } from 'axios';
import { trackUserEvent } from '../../../utils/lucky-orange';
import {
  IAddPickupPersonPayload,
  IDeletePickupPersonParams,
  IGetPickupPersonParams,
} from '../../interface/PickupPerson/IPickupPerson';
import { makeRequest } from '../axios/axiosConfig';
import { PickupPersonServiceConstants } from './pickupPerson.service.constants';

class PickupPersonService {
  /**
   * @method getPickupPerson makes a get request to get the pickup person details
   * @param IGetPickupPersonParams
   */
  public async getPickupPerson({
    storeID,
    orderId,
    history,
    isCheckout,
  }: IGetPickupPersonParams) {
    const { GET_PICKUP_PERSON_URL } = PickupPersonServiceConstants;

    try {
      const getPickupPersonRequest: AxiosRequestConfig = {
        url: GET_PICKUP_PERSON_URL(storeID, orderId),
      };

      const getPickupPersonResponse = await makeRequest(
        getPickupPersonRequest,
        undefined,
        history,
        isCheckout
      );

      return getPickupPersonResponse;
    } catch (error) {
      trackUserEvent({ eventMsg: error, eventName: 'pickupPersonGet' });

      throw error;
    }
  }

  /**
   * @method addPickupPerson makes a post request to add a pickup person
   * @param IAddPickupPersonPayload
   */
  public async addPickupPerson({
    firstName,
    lastName,
    email1,
    phone1,
    nickName,
    storeId,
    orderId,
    history,
  }: IAddPickupPersonPayload) {
    const { ADD_PICKUP_PERSON_URL } = PickupPersonServiceConstants;

    try {
      const addPickupPersonPayload: IAddPickupPersonPayload = {
        firstName,
        lastName,
        email1,
        phone1,
        nickName,
        orderId,
        storeId,
      };

      const addPickupPersonRequest: AxiosRequestConfig = {
        url: ADD_PICKUP_PERSON_URL(storeId),
        method: 'POST',
        data: addPickupPersonPayload,
      };

      const addPickUpPersonResponse = await makeRequest(
        addPickupPersonRequest,
        undefined,
        history
      );

      return addPickUpPersonResponse;
    } catch (error) {
      trackUserEvent({
        eventMsg: error,
        eventName: 'deliveryPage-pickupPersonPost',
      });

      throw error;
    }
  }

  /**
   * @method deletePickupPerson makes a put request to delete a pickup person
   * @param IDeletePickupPersonParams
   */
  public async deletePickupPerson({
    storeId,
    orderId,
    nickName,
    history,
  }: IDeletePickupPersonParams) {
    const { DELETE_PICKUP_PERSON_URL } = PickupPersonServiceConstants;

    try {
      const deletePickupPersonRequest: AxiosRequestConfig = {
        url: DELETE_PICKUP_PERSON_URL(storeId),
        method: 'PUT',
        data: {
          orderId,
          nickName,
        },
      };

      await makeRequest(deletePickupPersonRequest, undefined, history);
    } catch (error) {
      trackUserEvent({
        eventMsg: error,
        eventName: 'deliveryPage-pickupPersonDelete',
      });

      throw error;
    }
  }

  /**
   * @method updatePickupPerson makes a put request to update a pickup person
   * @param IAddPickupPersonPayload
   */
  public async updatePickupPerson({
    firstName,
    lastName,
    email1,
    phone1,
    nickName,
    storeId,
    orderId,
    history,
  }: IAddPickupPersonPayload) {
    const { ADD_PICKUP_PERSON_URL } = PickupPersonServiceConstants;

    try {
      const updatePickupPersonPayload: IAddPickupPersonPayload = {
        firstName,
        lastName,
        email1,
        phone1,
        nickName,
        orderId,
        storeId,
      };

      const updatePickupPersonRequest: AxiosRequestConfig = {
        url: ADD_PICKUP_PERSON_URL(storeId),
        method: 'PUT',
        data: updatePickupPersonPayload,
      };

      await makeRequest(updatePickupPersonRequest, undefined, history);
    } catch (error) {
      trackUserEvent({
        eventMsg: error,
        eventName: 'deliveryPage-pickupPersonPut',
      });

      throw error;
    }
  }
}

export { PickupPersonService };
