import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CUSTOMER_HELP,
  MY_ORDERS,
  TRACK_ORDER,
} from '../../../../../../constants/routes';
import { authenticationSelector } from '../../../../../../redux/selectors/auth.selector';
import { hasKey } from '../../../../../../utils/utils';
import { SupportCustomerCareItem } from '../SupportCustomerCareItem/SupportCustomerCareItem';
import { SupportMenuConstants } from '../SupportMenuConstants';
import { SupportSalesItem } from '../SupportSalesItem/SupportSalesItem';
import { SupportTextItem } from '../SupportTextItem/SupportTextItem';

/**
 * @hooks useSupportMenu is responsible for constructing the
 * support menu items based on the userType.
 */
const useSupportMenu = () => {
  const [supportMenuItems, setSupportMenuItems] = useState<any[]>([]);

  const { userType, isUserAuthenticated } = useSelector(authenticationSelector);

  const { t, i18n } = useTranslation();

  const { USER_TYPE } = SupportMenuConstants;

  const initializePhoneNumber = useCallback(() => {
    const {
      TRACKING,
      CUSTOMER_HELP_TEXT,
      CHAT,
      TEXT,
      SALES,
      CUSTOMER_CARE,
      CUSTOMER_CARE_NUMBER,
      SMS_NUMBER,
      CHAT_FALLBACK,
      CUSTOMER_HELP_TEXT_FALLBACK,
    } = SupportMenuConstants;

    if (hasKey(USER_TYPE, userType)) {
      const salesNumber = USER_TYPE[userType];

      const supportMenuItems: any[] = [
        {
          item: t(TRACKING),
          identifier: t(TRACKING),
          ...{
            ...(isUserAuthenticated
              ? { href: MY_ORDERS }
              : { href: TRACK_ORDER }),
          },
          isTel: false,
        },
        {
          item: i18n.exists(CUSTOMER_HELP_TEXT).valueOf()
            ? t(CUSTOMER_HELP_TEXT)
            : CUSTOMER_HELP_TEXT_FALLBACK,
          identifier: t(CUSTOMER_HELP_TEXT),
          href: CUSTOMER_HELP,
          isTel: false,
        },
        {
          item: <SupportSalesItem salesNumber={salesNumber} />,
          href: `tel:+${salesNumber}`,
          identifier: t(SALES),
          isTel: true,
        },
        {
          item: <SupportCustomerCareItem />,
          href: `tel:+${CUSTOMER_CARE_NUMBER}`,
          identifier: t(CUSTOMER_CARE),
          isTel: true,
        },
        {
          item: i18n.exists(CHAT).valueOf() ? t(CHAT) : CHAT_FALLBACK,
          identifier: t(CHAT),
          isTel: false,
        },
        {
          item: <SupportTextItem />,
          href: `sms:+${SMS_NUMBER}`,
          identifier: t(TEXT),
          isTel: true,
        },
      ];

      setSupportMenuItems(supportMenuItems);
    }
  }, [USER_TYPE, isUserAuthenticated, t, userType, i18n]);

  useEffect(() => {
    initializePhoneNumber();
  }, [initializePhoneNumber, userType]);

  return { supportMenuItems };
};

export { useSupportMenu };
