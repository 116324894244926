/**
 * @interface IAddAltAttributes
 */
interface IAddAltAttributes {
  htmlString: string;
  className: string;
  attributeName: string;
}

/**
 * @method useEspotAccessibility hook which has all the methods related to espot accesibility.
 */
const useEspotAccessibility = () => {
  /**
   * @method addAltAttributesToImageCells Adds the alt atribute to an image-cell div
   */
  const addAltAttributesToImageCells = ({
    htmlString,
    attributeName,
    className,
  }: IAddAltAttributes): string => {
    const tempElement = document.createElement('div');

    tempElement.innerHTML = htmlString;

    const elements = tempElement.getElementsByClassName(className);

    for (let i = 0; i < elements.length; i++) {
      const attributeValue = elements[i].getAttribute('title');

      elements[i].setAttribute(
        attributeName,
        attributeValue ? attributeValue : ''
      );
    }

    const modifiedHtmlString = tempElement.innerHTML;

    return modifiedHtmlString;
  };

  return { addAltAttributesToImageCells };
};

export { useEspotAccessibility };
