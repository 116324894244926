import { createReducer, current } from '@reduxjs/toolkit';
import { PAYMENT_METHOD, PAYPAL } from '../../_foundation/constants/payment';
import {
  ICartState,
  IOrderItem,
  IPaymentInstruction,
  IProtocolDataItem,
} from '../../_foundation/interface/Cart/IOrder';
import { IPickupPersonState } from '../../_foundation/interface/PickupPerson/IPickupPerson';
import IGetUsableShippingResponse from '../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { IShippingMethods } from '../../_foundation/interface/Responses/IShippingInfoResponse';
import { IGetUsableShippingState } from '../../_foundation/interface/Shipping/IUsableShipping';
import { checkTruthy } from '../../utils/utils';
import {
  FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION,
  FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  RESET_ORDER_CONFIRMATION_ACTION,
  SET_ORDER_CONFIRMATION_ERROR_ACTION,
  SET_ORDER_CONFIRMATION_LOADING_ACTION,
  UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION,
} from '../actions/orderConfirmation.action';
import { GET_PICKUP_PERSON_CONFIRMATION_SUCCESS_ACTION } from '../actions/pickupPerson.actions';

const initUsableShippingInfo: IGetUsableShippingState = {
  loading: true,
  restrictedItems: [],
  usableShippingMethodData: [],
  usableShippingMode: [],
  sameDayDelivery: false,
  isBopisEligible: false,
  physicalStoreId: '',
  iomStoreId: '',
};

const initConfirmationState: ICartState = {
  orderItem: [],
  replacementItems: [],
  shipModeDescription: '',
  cartProducts: [],
  noOfCartItems: 0,
  loading: true,
  orderSplitWarning: false,
  orderConfirmationError: false,
  promotionCode: [],
  shippingInfo: [],
  billingAddress: null,
  orderId: '',
  totalAdjustment: '',
  totalProductPrice: '',
  grandTotal: '',
  totalShippingTax: '',
  totalShippingCharge: '',
  paymentMethodDescription: '',
  currentPartNumber: '',
  showCartSpinner: {},
  showRemoveSpinner: {},
  showSaveToListSpinner: {},
  replacementPlanSpinner: {},
  cartItemsLoading: true,
  guestContactInfo: {
    updatedContacts: false,
    contacts: [],
  },
  addToCartLoading: {},
  promoRemoveSpinner: {},
  usableShippingInfo: initUsableShippingInfo,
  shippingMethods: {
    method: '',
    time: '',
    cost: '',
    shippingId: '',
    shipModeCode: '',
    iomStoreId: '',
  },
  adjustment: [],
  usablePaymentInfo: {
    paymentInfo: [],
    loaded: false,
  },
  paymentInstruction: [],
  poNumber: '',
  recordSetCount: '',
  hasBackorderedItems: false,
  bulkOrder: {
    orderId: '',
    success_PartNumbers: [],
    errors: {},
  },
  maxLineItems: 'false',
  maxQuantity: 'false',
  x_field1: '',
  x_physicalStoreName: '',
  x_physicalStoreId: '',
  allowMultiplePayments: false,
  giftCardDeductedAmount: 0,
  freeItems: {},
  x_taxesAppliedToOrder: '',
  x_taxesInternationalShipCountry: '',
  x_userTaxExempt: '',
  x_estimatedDelivery: '',
  x_liftGateChargesApplied: '',
  x_freightDiscount: '0',
  x_dropShippedOnly: '',
};

const orderConfirmationReducer = createReducer(
  initConfirmationState,
  (builder) => {
    /**
     * @action FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION
     * Fetches all the confirmed order
     */
    builder.addCase(
      FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION,
      (state: ICartState, action: any) => {
        const { response, productDetails } = action.payload;

        const {
          orderItem,
          paymentInstruction,
          totalShippingTax,
          totalShippingCharge,
          totalProductPrice,
          grandTotal,
          totalAdjustment,
          promotionCode,
          adjustment,
          x_poNumber,
          totalSalesTax,
          x_taxesAppliedToOrder,
          x_taxesInternationalShipCountry,
          x_userTaxExempt,
          orderId,
          x_carrierCode,
          x_carrierEstimateDelivery,
          x_physicalStoreName,
          x_physicalStoreId,
          x_code,
          x_estimatedDelivery,
          x_field1,
          x_surchargeAmount,
          x_liftGateChargesApplied,
          x_freightDiscount,
          x_dropShippedOnly,
          x_iom,
        } = response;

        const { freeItems } = current(state);

        let currentFreeItems = { ...freeItems };

        orderItem.forEach(({ orderItemId, freeGift }: IOrderItem) => {
          if (checkTruthy(freeGift)) {
            currentFreeItems = {
              ...currentFreeItems,
              [orderItemId]: true,
            };
          }
        });

        const replacementItems: IOrderItem[] = [];

        const orderItems: IOrderItem[] = [];

        /**
         * Retrive regular cart items and the replacement items.
         */
        orderItem.map((item: IOrderItem, index: number) => {
          if (item?.comments) {
            replacementItems.push(orderItem[index]);
          } else {
            orderItems.push(orderItem[index]);
          }

          return '';
        });

        /**
         * @var noOfCartItems is determined based on the quantity of items in the count.
         *
         * Example: If the cart has 2 items, Item A - 1 Qty and Item B - 2 Qty
         * The total number of items in the cart will be 3 since Item A has 1 and Item B has 2 Qty.
         */
        const noOfCartItems = orderItem
          ? orderItem.reduce(
              (itemCount: number, item: IOrderItem) =>
                +item.quantity + itemCount,
              0
            )
          : 0;

        let billingAddress = {
          addressId: paymentInstruction[0].billing_address_id,
          addressLine: paymentInstruction[0].addressLine,
          city: paymentInstruction[0].city,
          country: paymentInstruction[0].country,
          email1: paymentInstruction[0].email1,
          firstName: paymentInstruction[0].firstName,
          lastName: paymentInstruction[0].lastName,
          nickName: paymentInstruction[0].nickName,
          phone1: paymentInstruction[0].phone1,
          zipCode: paymentInstruction[0].postalCode,
          state: paymentInstruction[0].state,
          addressType: '',
          primary: '',
          xcont_lastUpdate: '',
          xcont_field2: '',
        };

        /**
         * Fetch the paypal payment instruction if
         * exists to display the paypal billing address
         * in the billing address section.
         */
        const paypalPaymentInstruction = paymentInstruction.find(
          ({ protocolData }: IPaymentInstruction) =>
            protocolData.some(
              ({ name, value }: IProtocolDataItem) =>
                name.toLowerCase() === PAYMENT_METHOD.toLowerCase() &&
                value.toLowerCase() === PAYPAL.toLowerCase()
            )
        );

        if (paypalPaymentInstruction) {
          billingAddress = {
            addressId: paypalPaymentInstruction.billing_address_id,
            addressLine: paypalPaymentInstruction.addressLine,
            city: paypalPaymentInstruction.city,
            country: paypalPaymentInstruction.country,
            email1: paypalPaymentInstruction.email1,
            firstName: paypalPaymentInstruction.firstName,
            lastName: paypalPaymentInstruction.lastName,
            nickName: paypalPaymentInstruction.nickName,
            phone1: paypalPaymentInstruction.phone1,
            zipCode: paypalPaymentInstruction.postalCode,
            state: paypalPaymentInstruction.state,
            addressType: '',
            primary: '',
            xcont_lastUpdate: '',
            xcont_field2: '',
          };
        }

        const shippingAddress = {
          addressId: orderItem[0].addressId,
          addressLine: orderItem[0].addressLine,
          city: orderItem[0].city,
          country: orderItem[0].country,
          email1: orderItem[0].email1,
          firstName: orderItem[0].firstName,
          lastName: orderItem[0].lastName,
          nickName: orderItem[0].nickName,
          phone1: orderItem[0].phone1,
          zipCode: orderItem[0].zipCode,
          state: orderItem[0].state,
          addressType: '',
          primary: '',
          xcont_lastUpdate: '',
          xcont_field2: '',
        };

        if (productDetails && response) {
          return {
            ...state,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            totalShippingTax: totalShippingTax,
            totalShippingCharge: totalShippingCharge,
            totalProductPrice: totalProductPrice,
            grandTotal: grandTotal,
            totalAdjustment: totalAdjustment,
            shipModeDescription: orderItem[0].shipModeDescription,
            noOfCartItems: noOfCartItems,
            orderItem: orderItems,
            replacementItems,
            paymentMethodDescription: paymentInstruction[0].piDescription,
            promotionCode: promotionCode,
            cartProducts: productDetails.contents,
            adjustment: adjustment ? adjustment : [],
            poNumber: x_poNumber,
            loading: false,
            freeItems: currentFreeItems,
            totalSalesTax: totalSalesTax,
            orderId: orderId,
            x_taxesAppliedToOrder: x_taxesAppliedToOrder
              ? x_taxesAppliedToOrder
              : 'false',
            x_liftGateChargesApplied: x_liftGateChargesApplied
              ? x_liftGateChargesApplied
              : 'false',
            x_taxesInternationalShipCountry: x_taxesInternationalShipCountry
              ? x_taxesInternationalShipCountry
              : 'false',
            x_userTaxExempt: x_userTaxExempt ? x_userTaxExempt : 'false',
            x_dropShippedOnly: x_dropShippedOnly ? x_dropShippedOnly : 'false',
            paymentInstruction,
            shippingMethods: {
              method: x_code,
              time: x_carrierEstimateDelivery,
              cost: '',
              shippingId: '',
              shipModeCode: x_carrierCode,
            },
            x_physicalStoreName,
            x_physicalStoreId,
            x_estimatedDelivery,
            x_field1,
            x_surchargeAmount,
            x_freightDiscount,
            x_iom,
          };
        }
      }
    );

    builder.addCase(
      SET_ORDER_CONFIRMATION_LOADING_ACTION,
      (state: ICartState, action: any) => {
        return {
          ...state,
          loading: action.payload,
        };
      }
    );

    builder.addCase(
      SET_ORDER_CONFIRMATION_ERROR_ACTION,
      (state: ICartState, action: any) => {
        return {
          ...state,
          orderConfirmationError: action.payload,
        };
      }
    );

    builder.addCase(
      FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
      (state: ICartState, action: any) => {
        const response = action.payload as IGetUsableShippingResponse;

        const restrictedItems = response.restrictedItems
          ? response.restrictedItems
          : [];

        const currentState = current(state);

        if (
          response.orderItem[0] &&
          response.orderItem[0]?.usableShippingMode
        ) {
          const usableShippingMode = response.orderItem[0].usableShippingMode;

          const updatedState: ICartState = {
            ...currentState,
            usableShippingInfo: {
              ...currentState.usableShippingInfo,
              usableShippingMode,
              loading: false,
              sameDayDelivery: response.sameDayDelivery,
              restrictedItems,
            },
          };

          return {
            ...updatedState,
          };
        }
      }
    );

    builder.addCase(
      UPDATE_DELIVERY_SHIPPING_METHOD_DATA_ACTION,
      (state: ICartState, action: any) => {
        const shippingMethodData = action.payload as IShippingMethods;

        if (shippingMethodData) {
          const { method, time, cost, shippingId, shipModeCode, iomStoreId } =
            shippingMethodData;

          const shippingMethods: IShippingMethods = {
            method,
            time,
            cost,
            shippingId,
            shipModeCode,
            iomStoreId,
          };

          const currentState = current(state);

          const updatedState: ICartState = {
            ...currentState,
            shippingMethods,
          };

          return { ...updatedState };
        }
      }
    );

    builder.addCase(
      GET_PICKUP_PERSON_CONFIRMATION_SUCCESS_ACTION,
      (state: ICartState, action: any) => {
        const { pickupPerson } = action.payload as IPickupPersonState;

        const currentState = current(state);

        if (pickupPerson && Array.isArray(pickupPerson)) {
          const updatedState: ICartState = {
            ...currentState,
            pickupPerson,
          };

          return {
            ...updatedState,
          };
        } else {
          const updatedState: ICartState = {
            ...currentState,
            pickupPerson: [],
          };

          return {
            ...updatedState,
          };
        }
      }
    );

    builder.addCase(RESET_ORDER_CONFIRMATION_ACTION, (state: ICartState) => {
      return { ...initConfirmationState };
    });
  }
);

export { orderConfirmationReducer };
