import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { FETCH_BREADCRUMB_ACTION } from '../../../../redux/actions/breadcrumb.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  CLEAR_ALL_FACET_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  RESET_PRODUCT_LIST_ACTION,
} from '../../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../../redux/selectors/breadcrumb.selector';
import { productListSelector } from '../../../../redux/selectors/productList.selector';
import { useFormattedPath } from '../../../../utils/hooks/formatted-path/formatted-path';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { SearchLayoutConstants } from '../../../Layouts/Search/SearchLayout.constants';
import { REFRESH_SEO_ACTION } from '../../../Seo/redux/actions/seo';
import { IconText } from '../../IconText/IconText';
import { FacetConstants } from '../Facet/FacetConstants';
import { ClearAllFacetsConstants } from './ClearAllFacetsConstants';

/**
 * @component ClearAllFacets renders the clear all element
 * which is responsible for clearing the selected filters.
 */
const ClearAllFacets: React.FC = () => {
  const { FACET_QUERY_KEY, CATEGORY_QUERY_KEY, BRAND_QUERY_KEY } =
    FacetConstants;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const { CLEAR_ALL } = ClearAllFacetsConstants;

  const { mySite } = useSite();

  const { selectedGroups, categoryFacetValues } =
    useSelector(productListSelector);

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const dispatch = useDispatch();

  const history = useHistory();

  const { isCategoryFacetSelection } = useFormattedPath();

  const isFacetsSelected = Object.keys(selectedGroups).length !== 0;

  /**
   * useQueryParam handles the history out of the box.
   * setFacetsParam can be used to set a specific facet param for the key "f"
   */
  const [, setFacetsParams] = useQueryParam(FACET_QUERY_KEY, StringParam);

  const [categoryParam, setCategoryParams] = useQueryParam(
    CATEGORY_QUERY_KEY,
    StringParam
  );

  const [, setBrandParam] = useQueryParam(BRAND_QUERY_KEY, StringParam);

  const [searchQueryParam] = useQueryParam(SEARCH_QUERY_PARAM, StringParam);

  /**
   * @method clearAllFacetHandler Clears all the query params from url for facets
   * and then clears all the facets from redux memory.
   */
  const clearAllFacetHandler = (): void => {
    if (
      isCategoryFacetSelection &&
      breadCrumbTrailEntryView?.length !== 0 &&
      mySite &&
      categoryFacetValues?.length !== 0 &&
      categoryParam
    ) {
      const selectedCategoryId =
        breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 2].value;

      const currentCategoryPath =
        breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 1].seo.href;

      dispatch(
        FETCH_BREADCRUMB_ACTION({
          categoryId: selectedCategoryId,
          catalogId: mySite.catalogID,
          storeID: mySite.storeID,
        })
      );

      history.push({
        pathname: currentCategoryPath,
        search: history.location.search,
      });
    }

    dispatch(CLEAR_ALL_FACET_ACTION());

    if (categoryParam) {
      dispatch(RESET_PRODUCT_LIST_ACTION({}));

      dispatch(REFRESH_SEO_ACTION());
    } else {
      dispatch(
        RESET_PRODUCT_LIST_ACTION({
          preserveSubCategories: true,
          preserveSelectedFacet: true,
        })
      );
    }

    if (!searchQueryParam) {
      // Clear all the facet params from the url.
      setFacetsParams(undefined, 'replace');

      setCategoryParams(undefined, 'replace');

      setBrandParam(undefined, 'replace');
    }

    if (searchQueryParam) {
      // Clear all the facet params from the url.
      setFacetsParams(undefined, 'replaceIn');

      setCategoryParams(undefined, 'replaceIn');

      setBrandParam(undefined, 'replaceIn');
    }

    dispatch(ADD_CATEGORY_IDS_ACTION([]));

    dispatch(CURRENT_BRAND_IDENTIFIER_ACTION(''));

    dispatch(
      CURRENT_BRAND_INFO_ACTION({
        brandName: '',
        seo: '',
      })
    );
  };

  return (
    <>
      {isFacetsSelected && (
        <IconText
          clickHandler={clearAllFacetHandler}
          translationKey={CLEAR_ALL}
          icon={<CloseIcon />}
        />
      )}
    </>
  );
};

export { ClearAllFacets };
