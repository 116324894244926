import { createAction } from '@reduxjs/toolkit';
import { CountryResponse } from '../../_foundation/apis/search/location/Ilocation';
import * as ACTION_TYPES from '../action-types/countryList.action-type';

export const UPDATE_BILLING_COUNTRY_LIST_ACTION = createAction<CountryResponse>(
  ACTION_TYPES.UPDATE_BILLING_COUNTRY_LIST
);

export const UPDATE_SHIPPING_COUNTRY_LIST_ACTION =
  createAction<CountryResponse>(ACTION_TYPES.UPDATE_SHIPPING_COUNTRY_LIST);
