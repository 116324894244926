import { createAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../action-types/categories.action-type';

export const GET_ALL_CATEGORIES_ACTION = createAction<any, string>(
  ACTION_TYPES.GET_ALL_CATEGORIES
);

export const GET_ALL_CATEGORIES_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.GET_ALL_CATEGORIES_SUCCESS
);

export const GET_CURRENT_CATEGORY_ID_ACTION = createAction<{
  currentCategory?: string;
  currentCategoryIdentifier?: string;
}>(ACTION_TYPES.GET_CURRENT_CATEGORY_ID);

export const RESET_CURRENT_CATEGORY_ID_ACTION = createAction(
  ACTION_TYPES.RESET_CURRENT_CATEGORY_ID
);

export const RESET_SEO_HREF_ACTION = createAction(ACTION_TYPES.RESET_SEO_HREF);

export const SET_CURRENT_CATEGORY_ID_ACTION = createAction<{
  categoryId: string;
}>(ACTION_TYPES.SET_CURRENT_CATEGORY_ID);

export const SET_CATEGORIES_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_CATEGORIES_LOADING
);
