import axios, { CancelTokenSource } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { SeoConstants } from '../../../components/Seo/SeoConstants';
import { siteContentService } from '../../../_foundation/apis/search/search-siteContent/siteContent.service';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { ProductEntry } from '../../../_foundation/interface/SearchSiteContent/ISearchSiteContent';
import { checkTruthy, formatSearchTerm } from '../../utils';
import debounce from 'lodash/debounce';

/**
 * @method useProductSuggestionSiteContent
 * Responsible for making call to BackEnd Search Suggestion API
 */
const useProductSuggestionSiteContent = ({
  currentValue,
  showSuggestionMenu,
}: any) => {
  const [productSuggestionList, setProductSuggestionList] = useState<
    ProductEntry[]
  >([]);

  const [productLoading, setProductLoading] = useState(true);

  const { mySite } = useSite();

  const [cancelSource, setCancelSource] = useState<CancelTokenSource>();

  const { PRODUCT_PATH } = SeoConstants;

  const triggerProductSeach =
    mySite && checkTruthy(mySite.triggerProductSearchSuggestion);

  useEffect(() => {
    /**
     * Initialize the cancel token to cancel the suggestion calls
     * when the user hits enter or clicks an item from suggestion list.
     */
    if (showSuggestionMenu) {
      const cancelToken = axios.CancelToken;

      const cancelSource = cancelToken.source();

      setCancelSource(cancelSource);
    }
  }, [showSuggestionMenu]);

  const searchProductSuggestion = useMemo(
    () =>
      debounce(async (currentValue): Promise<void> => {
        if (currentValue.length === 0) {
          setProductSuggestionList([]);
        }

        if (currentValue && mySite) {
          const searchRequest = {
            term: formatSearchTerm(currentValue),
            storeID: mySite?.storeID,
            cancelSource,
          };

          try {
            setProductLoading(true);

            const productSuggestionResponse =
              await siteContentService.fetchProductSuggestionBySearchTerm(
                searchRequest
              );

            const { suggestionView } = productSuggestionResponse;

            const { entry } = suggestionView[0];

            entry.forEach((suggestion: ProductEntry) => {
              if (suggestion?.seo?.href) {
                suggestion.seo.href = PRODUCT_PATH + suggestion.seo.href;
              }
            });

            setProductSuggestionList(entry);

            setProductLoading(false);
          } catch (e) {
            setProductLoading(false);

            setProductSuggestionList([]);
          }
        }
      }, +mySite?.searchDebounceTime),
    [PRODUCT_PATH, cancelSource, mySite]
  );

  useEffect(() => {
    if (triggerProductSeach && currentValue.length >= 3) {
      searchProductSuggestion(currentValue);
    }
  }, [currentValue, searchProductSuggestion, triggerProductSeach]);

  return { productSuggestionList, productLoading, cancelSource };
};

export { useProductSuggestionSiteContent };
