import { Hidden, Toolbar } from '@material-ui/core';
import React from 'react';
import { CatalogMenu } from '../ExpandableMenu/CatalogMenu/CatalogMenu';
import { SupportMenu } from '../ExpandableMenu/SupportMenu/SupportMenu';
import { Account } from '../SecondaryMenuItems/Account/Account';
import { CartLink } from '../SecondaryMenuItems/CartLink/CartLink';
import { StoreLocator } from '../SecondaryMenuItems/StoreLocator/StoreLocator';

/**
 * @component SecondaryNavBar renders the secondary nav bar.
 */
const SecondaryNavBar: React.FC = () => (
  <Toolbar disableGutters={true}>
    <nav aria-label='secondary navigation' className='secondary nav_bar'>
      <ul className='top-level-menu-list'>
        <StoreLocator />

        <CatalogMenu />
      </ul>
      <ul className='top-level-menu-list'>
        <Hidden xsDown>
          <SupportMenu />
        </Hidden>

        <Account />

        <CartLink />
      </ul>
    </nav>
  </Toolbar>
);

export { SecondaryNavBar };
