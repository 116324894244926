import {
  IDeliveryOptionAndStorePickUp,
  IStoreLocatorFailState,
  IStoreLocatorSearchState,
  IStoreLocatorState,
} from '../../_foundation/interface/StoreLocator/IStoreLocator';
import { RootReducerState } from '../reducers';

const storeLocatorSelector = (state: RootReducerState): IStoreLocatorState => {
  return state.storeLocator;
};

const storeLocatorSearchSelector = (
  state: RootReducerState
): IStoreLocatorSearchState => {
  return state.storeLocatorSearch;
};

const deliverOptionAndStorePickUpSelector = (
  state: RootReducerState
): IDeliveryOptionAndStorePickUp => {
  return state.deliveryOptionAndStorePickUp;
};

const storeLocatorFailSelector = (
  state: RootReducerState
): IStoreLocatorFailState => {
  return state.storelocatorFail;
};

export {
  storeLocatorSelector,
  storeLocatorSearchSelector,
  deliverOptionAndStorePickUpSelector,
  storeLocatorFailSelector,
};
