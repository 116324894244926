const BASE_URL = '/wcs/resources/store/';

const StoreLocatorServiceConstants = {
  STORE_DETAILS_URL: (
    storeID: string,
    latitude: number,
    longitude: number,
    radius?: string
  ) =>
    `${BASE_URL}${storeID}/storelocator/latitude/${latitude}/longitude/${longitude}?maxItems=10&siteLevelStoreSearch=false&responseFormat=json&radius=${radius}`,
  STORE_DETAILS_SEARCH_URL: (storeID: string, address: string) =>
    `${BASE_URL}${storeID}/storelocator?address=${address}&siteLevelStoreSearch=false`,
  STORE_INVENTORY_DETAILS_ADDRESS_URL: (
    storeID: string,
    address: string,
    partNumber: string
  ) =>
    `${BASE_URL}${storeID}/storelocator/inventory?address=${address}&partNumber=${partNumber}&siteLevelStoreSearch=false`,
  STORE_INVENTORY_DETAILS_ADDRESS_URL_WITH_ORDERID: (
    storeID: string,
    address: string,
    orderId: string
  ) =>
    `${BASE_URL}${storeID}/storelocator/inventory?address=${address}&orderId=${orderId}&siteLevelStoreSearch=false`,
  STORE_INVENTORY_DETAILS_LAT_LONG_URL: (
    storeID: string,
    latitude: number,
    longitude: number,
    partNumber: string,
    radius?: string
  ) =>
    `${BASE_URL}${storeID}/storelocator/inventory?latitude=${latitude}&longitude=${longitude}&partNumber=${partNumber}&siteLevelStoreSearch=false&radius=${radius}`,
  STORE_PICKUP_INVENTORY_DETAILS_LAT_LONG_URL: (
    storeID: string,
    latitude: number,
    longitude: number,
    orderId: string,
    anchorStore: string,
    radius?: string
  ) =>
    `${BASE_URL}${storeID}/storelocator/inventory?siteLevelStoreSearch=false&latitude=${latitude}&longitude=${longitude}&orderId=${orderId}${
      anchorStore ? `&anchorStore=${anchorStore}` : ''
    }&radius=${radius}`,
  STORES_BY_LOCATION_URL: (storeID: string, country: string) =>
    `${BASE_URL}${storeID}/storelocator/location?country=${country}`,
  STORES_BY_STORE_ID: (storeID: string, id: string) =>
    `${BASE_URL}${storeID}/storelocator/byStoreId/${id}`,
  US: 'US',
  USA: 'USA',
};

export { StoreLocatorServiceConstants };
