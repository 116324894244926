import { CircularProgress, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SHOPPING_CART } from '../../../../../constants/routes';
import {
  DELETE_ITEM_IN_CART_ACTION,
  FETCH_SHIPPING_INFO_ACTION,
  SET_CART_SPINNER_ACTION,
  UPDATE_ITEM_IN_CART_ACTION,
} from '../../../../../redux/actions/order.actions';
import { orderSelector } from '../../../../../redux/selectors/order.selector';
import { orderConfirmationSelector } from '../../../../../redux/selectors/orderConfirmation.selector';
import { storeLocatorSelector } from '../../../../../redux/selectors/storeLocator';
import { IOrderItem } from '../../../../../_foundation/interface/Cart/IOrder';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import {
  checkTruthy,
  deleteCookie,
  scrollToTop,
} from '../../../../../utils/utils';
import { CartService } from '../../../../../_foundation/apis/cart/cart.service';
import { CART_COUNT_BEFORE_MERGING } from '../../../../../_foundation/constants/cookie';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import {
  IDeleteItemInCart,
  IUpdateCartDetails,
} from '../../../../../_foundation/interface/Cart/IOrder';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { NteSelect, SelectOption } from '../../../../Forms/NteSelect/NteSelect';
import { TextInput } from '../../../../Forms/TextInput/TextInput';
import { CartSaveLaterRemove } from '../CartSaveLaterRemove/CartSaveLaterRemove';
import { CartItemQuantityConstants } from './CartItemQuantityConstants';

/**
 * @interface ICartItemQuantity
 */
interface ICartItemQuantity {
  /**
   * partNumber of the particular item
   */
  id: string;

  /**
   * @prop isFromPDP
   * Determines whether the component is used in PDP.
   */
  isFromPDP?: boolean;

  /**
   * Advantage Member to the cart
   */
  isAdvantage?: boolean;

  /**
   * Show quantity of each present item on the review page.
   */

  isReview?: boolean;

  /**
   * Show quantity of present item on the Order Confirmation Page.
   */

  isConfirmation?: boolean;

  /**
   * @prop setItemQuantity
   * Manually set item quantity state without allowing the component to handle it.
   */
  setItemQuantity?: React.Dispatch<React.SetStateAction<string>>;

  /**
   * @prop itemQuantity
   * Current item quantity,
   */
  itemQuantity?: string;
  onSelectQuantityChange?: any;
  isShippingInfoDrawer?: boolean;
  quantityChangeEvent?: any;

  /**
   * ClassName
   */
  className?: string;

  /**
   *  @prop passed from AddTocartbtn comp
   * To check for any error in qty input field
   */
  quantityErrorCall?: (value: boolean) => void;

  index: number;
  isCartCard?: boolean;
}

/**
 * @component CartItemQuantity
 * Responsible to render the Quantity Component
 */
const CartItemQuantity: React.FC<ICartItemQuantity> = ({
  id,
  isAdvantage,
  isReview,
  isConfirmation,
  isFromPDP,
  setItemQuantity,
  itemQuantity,
  onSelectQuantityChange,
  quantityChangeEvent,
  isShippingInfoDrawer,
  className = '',
  quantityErrorCall,
  index,
  isCartCard,
}) => {
  const {
    DEFAULT_TEXT_QUANTITY,
    QUANTITY,
    optionForDropdown,
    UPDATE,
    QTY,
    ADVANTAGE_PARTNUMBER,
    optionForPDPDropdown,
    GIFTCARD_PAY_METHOD,
  } = CartItemQuantityConstants;

  const { pathname } = useCurrentPathName();

  const {
    orderItem,
    orderId,
    showCartSpinner,
    showRemoveSpinner,
    showSaveToListSpinner,
    freeItems,
    paymentInstruction,
  } = useSelector(orderSelector);

  const {
    orderItem: orderItemForOrderConfirmation,
    freeItems: freeItemForOrderConfirmation,
  } = useSelector(orderConfirmationSelector);

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const isCartPage = pathname === SHOPPING_CART;

  const keys = !isConfirmation
    ? orderItem.at(index!)
    : orderItemForOrderConfirmation.at(index!);

  const isFreeItem = !isConfirmation
    ? freeItems[keys?.orderItemId!] !== undefined
      ? freeItems[keys?.orderItemId!]
      : false
    : freeItemForOrderConfirmation[keys?.orderItemId!] !== undefined
    ? freeItemForOrderConfirmation[keys?.orderItemId!]
    : false;

  const cartQuantityLoading: boolean = showCartSpinner[id];

  const removeLoading: boolean = showRemoveSpinner[id];

  const saveToListLoading: boolean = showSaveToListSpinner[id];

  const disableQuantity = removeLoading || saveToListLoading;

  const quantityOption: SelectOption[] = [...optionForDropdown];

  const quantityPDPOption: SelectOption[] = [...optionForPDPDropdown];

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const initQuantity = itemQuantity ? itemQuantity : '1';

  const [quantity, setQuantity] = useState<string>(initQuantity);

  const [orderItemId, setOrderItemId] = useState<string>('');

  const [showTextField, setShowTextField] = useState(false);

  const [quantityInput, setQuantityInput] = useState(DEFAULT_TEXT_QUANTITY);

  const [quantityError, setQuantityError] = useState(false);

  const initCartQuantity = useCallback((): void => {
    /**
     *When the page is on Order Confirmation Page, we are resetting the value of orderItem in orderselector. 
     To get the quantity of each ordered item,we use the values from orderConfirmation Selector. For this reason, we are using a ternary operator to see if the value is present in orderSelector then we use that, else from the orderConfirmationSelector.
     */
    const currentItemQuantity =
      orderItem.length !== 0
        ? orderItem.filter(
            ({ partNumber, freeGift }) =>
              partNumber === id && isFreeItem === checkTruthy(freeGift)
          )[0]
        : orderItemForOrderConfirmation?.filter(
            ({ partNumber, freeGift }) =>
              partNumber === id && isFreeItem === checkTruthy(freeGift)
          )[0];

    setOrderItemId(currentItemQuantity?.orderItemId);

    if (!isFromPDP) {
      const quantity = Number(currentItemQuantity?.quantity).toFixed();

      setQuantity(String(quantity));

      setQuantityInput(String(quantity));

      if (Number(quantity) >= 10) {
        setShowTextField(true);
      } else {
        setShowTextField(false);
      }
    }
  }, [orderItem, orderItemForOrderConfirmation, isFromPDP, id, isFreeItem]);

  useEffect(() => {
    initCartQuantity();
  }, [initCartQuantity, id, isFromPDP]);

  /**
   * @method quantityChangeHandler
   * Responsible to update the state for dropdown and showing the textfield
   *
   */

  const physicalStoreId = currentStoreDetails
    ? currentStoreDetails.uniqueID
    : '';

  const quantityChangeHandler = (
    event: React.ChangeEvent<{ value: string }>
  ): void => {
    setQuantity(event.target.value);

    if (isFromPDP && onSelectQuantityChange) {
      onSelectQuantityChange(event.target.value);
    }

    if (event.target.value === DEFAULT_TEXT_QUANTITY) {
      setQuantityInput(DEFAULT_TEXT_QUANTITY);
      if (onSelectQuantityChange) {
        onSelectQuantityChange(DEFAULT_TEXT_QUANTITY);
      }
      setShowTextField(true);

      return;
    }

    if (isFromPDP && setItemQuantity) {
      setItemQuantity(event.target.value);

      setQuantity(event.target.value);

      return;
    }

    if (event.target.value !== DEFAULT_TEXT_QUANTITY && !isFromPDP) {
      dispatch(SET_CART_SPINNER_ACTION({ loading: true, partNumber: id }));
    }

    if (event.target.value === '0') {
      if (paymentInstruction) {
        const giftCardPaymentInstruction = paymentInstruction?.filter(
          ({ payMethodId }) =>
            payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
        );

        if (giftCardPaymentInstruction?.length > 0) {
          giftCardPaymentInstruction.forEach(({ piId }) =>
            CartService.removeGiftCard({
              storeId: mySite.storeID,
              paymentInstructionId: piId,
            })
          );
        }
      }

      const samePartnumberItems: IOrderItem[] = orderItem.filter(
        (obj) => obj.partNumber === id
      );

      samePartnumberItems.forEach((obj: IOrderItem) => {
        if (!checkTruthy(obj.freeGift)) {
          return setOrderItemId(obj.orderItemId);
        }
      });

      const deleteCartPayload: IDeleteItemInCart = {
        storeID: mySite.storeID,
        orderItemId,
        partNumber: id,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      dispatch(DELETE_ITEM_IN_CART_ACTION(deleteCartPayload));
      dispatch(
        FETCH_SHIPPING_INFO_ACTION({
          storeID: mySite.storeID,
          orderId,
          physicalStoreId: physicalStoreId ? physicalStoreId : '',
        })
      );

      scrollToTop();

      return;
    }

    if (
      currentStoreDetails?.uniqueID &&
      currentStoreDetails?.latitude &&
      currentStoreDetails?.longitude &&
      orderId
    ) {
      const updateCartPayload: IUpdateCartDetails = {
        orderItemId,
        partNumber: id,
        quantity: event.target.value,
        storeID: mySite.storeID,
        latitude: Number(currentStoreDetails.latitude),
        longitude: Number(currentStoreDetails.longitude),
        orderId,
        anchorStore: currentStoreDetails.uniqueID,
        radius: mySite.storeLocatorDefaultRadius,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      if (!isFromPDP) {
        /**
         * responsible to delete the Gift card Payment instruction
         * when user coming back from checkout flow and doing additional changes to cart
         */
        if (paymentInstruction) {
          const giftCardPaymentInstruction = paymentInstruction?.filter(
            ({ payMethodId }) =>
              payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
          );

          if (giftCardPaymentInstruction?.length > 0) {
            giftCardPaymentInstruction.forEach(({ piId }) =>
              CartService.removeGiftCard({
                storeId: mySite.storeID,
                paymentInstructionId: piId,
              })
            );
          }
        }

        dispatch(UPDATE_ITEM_IN_CART_ACTION(updateCartPayload));
        dispatch(
          FETCH_SHIPPING_INFO_ACTION({
            storeID: mySite.storeID,
            orderId,
            physicalStoreId: physicalStoreId ? physicalStoreId : '',
          })
        );

        deleteCookie(CART_COUNT_BEFORE_MERGING);
      }
    } else {
      const updateCartPayload: IUpdateCartDetails = {
        orderItemId,
        partNumber: id,
        quantity: event.target.value,
        storeID: mySite.storeID,
        ...{ ...(isCartPage && { isCartPage }) },
      };
      if (!isFromPDP) {
        /**
         * responsible to delete the Gift card Payment instruction
         * when user coming back from checkout flow and doing additional changes to cart
         */
        if (paymentInstruction) {
          const giftCardPaymentInstruction = paymentInstruction?.filter(
            ({ payMethodId }) =>
              payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
          );

          if (giftCardPaymentInstruction?.length > 0) {
            giftCardPaymentInstruction.forEach(({ piId }) =>
              CartService.removeGiftCard({
                storeId: mySite.storeID,
                paymentInstructionId: piId,
              })
            );
          }
        }

        dispatch(UPDATE_ITEM_IN_CART_ACTION(updateCartPayload));
        dispatch(
          FETCH_SHIPPING_INFO_ACTION({
            storeID: mySite.storeID,
            orderId,
            physicalStoreId: physicalStoreId ? physicalStoreId : '',
          })
        );

        deleteCookie(CART_COUNT_BEFORE_MERGING);
      }
    }

    setShowTextField(false);
  };

  /**
   * @method quantityInputChangeHandler
   * Responsible to update the state for inputfield and showing the textfield
   */
  const quantityInputChangeHandler = (
    event: React.ChangeEvent<{ value: string }>
  ): void => {
    if (event.target.value === '') {
      setQuantityInput(event.target.value);
      setQuantityError(true);
      if (quantityErrorCall) {
        quantityErrorCall(true);
      }
    } else {
      setQuantityError(false);
      if (quantityErrorCall) {
        quantityErrorCall(false);
      }
      if (event.target.value.length <= 3) {
        setQuantityInput(event.target.value);

        if (isFromPDP && onSelectQuantityChange) {
          onSelectQuantityChange(event.target.value);
        }
      }
    }
  };

  const updatedQuantityHandler = (): void => {
    if (isFromPDP && setItemQuantity) {
      setItemQuantity(quantityInput);

      return;
    }

    dispatch(SET_CART_SPINNER_ACTION({ loading: true, partNumber: id }));

    if (
      currentStoreDetails?.uniqueID &&
      currentStoreDetails?.latitude &&
      currentStoreDetails?.longitude &&
      orderId
    ) {
      const updateCartPayload: IUpdateCartDetails = {
        orderItemId,
        partNumber: id,
        quantity: quantityInput,
        storeID: mySite.storeID,
        latitude: Number(currentStoreDetails.latitude),
        longitude: Number(currentStoreDetails.longitude),
        orderId,
        anchorStore: currentStoreDetails.uniqueID,
        radius: mySite.storeLocatorDefaultRadius,
        ...{ ...(isCartPage && { isCartPage }) },
      };
      if (!isFromPDP) {
        /**
         * responsible to delete the Gift card Payment instruction
         * when user coming back from checkout flow and doing additional changes to cart
         */
        if (paymentInstruction) {
          const giftCardPaymentInstruction = paymentInstruction?.filter(
            ({ payMethodId }) =>
              payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
          );

          if (giftCardPaymentInstruction?.length > 0) {
            giftCardPaymentInstruction.forEach(({ piId }) =>
              CartService.removeGiftCard({
                storeId: mySite.storeID,
                paymentInstructionId: piId,
              })
            );
          }
        }

        dispatch(UPDATE_ITEM_IN_CART_ACTION(updateCartPayload));
        dispatch(
          FETCH_SHIPPING_INFO_ACTION({
            storeID: mySite.storeID,
            orderId,
            physicalStoreId: physicalStoreId ? physicalStoreId : '',
          })
        );

        deleteCookie(CART_COUNT_BEFORE_MERGING);
      }
    } else {
      const updateCartPayload: IUpdateCartDetails = {
        orderItemId,
        partNumber: id,
        quantity: quantityInput,
        storeID: mySite.storeID,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      if (!isFromPDP) {
        /**
         * responsible to delete the Gift card Payment instruction
         * when user coming back from checkout flow and doing additional changes to cart
         */
        if (paymentInstruction) {
          const giftCardPaymentInstruction = paymentInstruction?.filter(
            ({ payMethodId }) =>
              payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
          );

          if (giftCardPaymentInstruction?.length > 0) {
            giftCardPaymentInstruction.forEach(({ piId }) =>
              CartService.removeGiftCard({
                storeId: mySite.storeID,
                paymentInstructionId: piId,
              })
            );
          }
        }

        dispatch(UPDATE_ITEM_IN_CART_ACTION(updateCartPayload));
        dispatch(
          FETCH_SHIPPING_INFO_ACTION({
            storeID: mySite.storeID,
            orderId,
            physicalStoreId: physicalStoreId ? physicalStoreId : '',
          })
        );

        deleteCookie(CART_COUNT_BEFORE_MERGING);
      }
    }
  };

  /**
   * @method quantityInputFieldHandler
   * Responsible to update the state for quantity and displaying the dropdown field
   */
  const quantityInputFieldHandler = (
    event: React.ChangeEvent<{ value: string }>
  ): void => {
    if (event.target.value && event.target.value.length < 2 && isFromPDP) {
      setQuantity(event.target.value);

      setQuantityInput(DEFAULT_TEXT_QUANTITY);

      setShowTextField(false);
    }
  };

  return (
    <div className={`cart_item_quantity ${className}`}>
      {isReview || isConfirmation ? (
        <Typography variant='body1'>
          {QTY.label}: {quantity}
        </Typography>
      ) : !isFreeItem ? (
        <>
          {!isAdvantage ? (
            showTextField ? (
              <div className={isFromPDP ? 'qty-cell' : 'button-row qty-cell'}>
                <TextInput
                  error={quantityError}
                  id='quantity'
                  label=''
                  name='quantity'
                  type='number'
                  ariaLabel='quantity'
                  value={quantityInput}
                  onChange={(e) => {
                    let regex = /^[0-9]*$/;
                    regex.test(e.target.value) &&
                      e.target.value !== '0' &&
                      quantityInputChangeHandler(e);
                  }}
                  onBlur={(e) => quantityInputFieldHandler(e)}
                  helperText={
                    quantityError ? 'Please enter valid quantity' : ''
                  }
                  onKeyPress={(e) =>
                    ['e', 'E'].includes(e.key) && e.preventDefault()
                  }
                />
                {!isFromPDP && (
                  <NteButton
                    variant='outlined'
                    translationKey={t(UPDATE)}
                    onClick={updatedQuantityHandler}
                    loading={cartQuantityLoading}
                    disabled={disableQuantity}
                    type='button'
                  />
                )}
              </div>
            ) : (
              <div className='qty-cell'>
                {!cartQuantityLoading ? (
                  <NteSelect
                    selectFormId='quantity'
                    label={QUANTITY.label}
                    className='quantity-field'
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={(e: any) => {
                      quantityChangeHandler(e);
                    }}
                    options={
                      isFromPDP || isShippingInfoDrawer
                        ? quantityPDPOption
                        : quantityOption
                    }
                    value={quantity}
                    variant='outlined'
                    disabled={disableQuantity || id === ADVANTAGE_PARTNUMBER}
                  />
                ) : (
                  <div className='progress-wrapper field-margin' role='alert'>
                    <span className='sr-only'>Updating quantity</span>
                    <CircularProgress size={24} color='primary' />
                  </div>
                )}
              </div>
            )
          ) : (
            <div className='qty-cell'>
              {!cartQuantityLoading ? (
                <>
                  {quantity !== 'NaN' && (
                    <Typography variant='body1' className='field-margin'>
                      {QTY.label}: {quantity}
                    </Typography>
                  )}
                </>
              ) : (
                <div className='progress-wrapper field-margin'>
                  <CircularProgress size={24} color='primary' />
                </div>
              )}
            </div>
          )}

          {!isFromPDP && !isShippingInfoDrawer && (
            <CartSaveLaterRemove
              id={id}
              isAdvantage={isAdvantage}
              itemQuantity={quantity}
            />
          )}
        </>
      ) : (
        <Typography variant='body1'>
          {QTY.label}: {quantity}
        </Typography>
      )}
    </div>
  );
};

export { CartItemQuantity };
