import { StringParam, useQueryParam } from 'use-query-params';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { SearchLayoutConstants } from '../../../../Layouts/Search/SearchLayout.constants';
import { FacetConstants } from '../FacetConstants';
import { useAppendFacetQuery } from './AppendFacetQueryHooks';

/**
 * @interface IConstructCheckboxUrl
 */
interface IConstructCheckboxUrl {
  currentFacetData: IProductListFacetsEntry;
  facetGroup: string;
  checked: boolean;
}

/**
 * @method useFacetLink Constructs the query params for a facet link.
 */
const useFacetLink = () => {
  const { CATEGORY_QUERY_KEY } = FacetConstants;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const [categoryQueryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const [searchQueryParam] = useQueryParam(SEARCH_QUERY_PARAM, StringParam);

  const { constructQueryParams } = useAppendFacetQuery();

  /**
   * @method constructFacetUrl Construct the facet url for the current selected facet.
   */
  const constructFacetUrl = ({
    currentFacetData,
    facetGroup,
    checked,
  }: IConstructCheckboxUrl): string => {
    const currentFacetParam = constructQueryParams({
      currentFacetData,
      facetGroup,
      checked,
    });

    if (searchQueryParam && currentFacetParam) {
      return `s=${searchQueryParam}&f=${currentFacetParam}`;
    }

    if (categoryQueryParam && currentFacetParam) {
      return `c=${categoryQueryParam}&f=${currentFacetParam}`;
    }

    if (currentFacetParam) {
      return `f=${currentFacetParam}`;
    }

    if (categoryQueryParam) {
      return `c=${categoryQueryParam}`;
    }

    if (searchQueryParam) {
      return `s=${searchQueryParam}`;
    }

    return '';
  };

  return { constructFacetUrl };
};

export { useFacetLink };
