import { Card, CardActions, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const RviCardSkeleton = () => {
  return (
    <Card className='rvi-carousel-slide nte-card'>
      <CardContent className='rvi-card-container'>
        <div className='align-right'></div>

        <Skeleton variant='rect' width={150} height={80} />

        <div className='rvi-card-text'>
          <Skeleton variant='rect' width={150} height={80} />
          <Skeleton variant='rect' width={150} height={80} />

          <Skeleton variant='rect' width={150} height={80} />
        </div>
      </CardContent>

      <CardActions className='flex-align-center'>
        <Skeleton variant='rect' width={150} height={80} />
      </CardActions>
    </Card>
  );
};

export { RviCardSkeleton };
