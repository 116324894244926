import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as ShippingIcon } from '../../../../theme/assets/shipping.svg';
import { checkTruthy } from '../../../../utils/utils';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { PdpPageConstants } from '../../../PDP/PdpPageConstants';
import { ProductPricing } from '../../../ProductPricing/ProductPricing';
import { ProductCardConstants } from './ProductCardConstants';
import { EntryTypeCode } from './ProductCardSection';
import { ProductCardSectionDescription } from './ProductCardSectionDescription/ProductCardSectionDescription';
import { ProductCardSectionImage } from './ProductCardSectionImage/ProductCardSectionImage';
import { ProductCardSectionMessages } from './ProductCardSectionMessages/ProductCardSectionMessages';
import { ProductCardSectionMessagesConstants } from './ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { wishListSelector } from '../../../../redux/selectors/wish-list.selector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProductCardSaveList } from './ProductCardSaveList/ProductCardSaveList';

/**
 * @interface ProductCardProps
 */
export interface ProductCardProps {
  productCard: IProductListContents;
  removeFromList?: boolean;
  isCompareProduct?: boolean;
  index: number;
  sharedList?: boolean;
  showRemove?: boolean;
  className?: string;
  isProductCompare?: boolean;
  sortIndex?: number;
  isGroupedInventory?: boolean;
  displayCompareCheckbox?: boolean;
  /**
   * This prop is passed to hide the compare
   *  checkbox on custom landing pages
   */
  hideCompareCheckbox?: boolean;
  isWishlistCard?: boolean;
}

/**
 * @component ProductCard renders the product card sections for a specific product.
 *
 * @param ProductCardProps
 */
const ProductCard = ({
  productCard,
  removeFromList,
  isCompareProduct,
  index,
  sharedList,
  showRemove = true,
  className = '',
  isProductCompare,
  sortIndex,
  isGroupedInventory,
  displayCompareCheckbox = true,
  hideCompareCheckbox,
  isWishlistCard,
}: ProductCardProps) => {
  const history = useHistory();

  const { ProductShippingConstants } = ProductCardSectionMessagesConstants;

  const { giftListIds, giftList, partNumberQuantityMap } =
    useSelector(wishListSelector);

  const { t } = useTranslation();

  const {
    ATTRIBUTES: { ITEM },
  } = PdpPageConstants;

  const [buyableWithNoPrice, setBuyableWithNoPrice] = useState(false);

  const seeDetailsClickHandler = () => {
    history.push(productCard?.seo?.href);
  };
  const productId = productCard.uniqueID
    ? productCard.uniqueID
    : productCard.id;

  const descName: string = giftList.find(
    (wishList) => wishList?.uniqueID === giftListIds[productId]
  )?.descriptionName!;

  const productName = productCard.name;

  const isCategorySku =
    productCard &&
    (productCard.type?.toLowerCase() === ITEM ||
      productCard.catalogEntryTypeCode === EntryTypeCode.ITEMBEAN);

  const categorySkuNoPrice =
    isCategorySku && checkTruthy(productCard.buyable) === false;

  const unavailableProductDescription = (
    <>
      <div className='price-cell product_shipping'>
        <div className='product-shipping-list product-shipping-list--sold-out'>
          <div className='icon-row shipping'>
            <ShippingIcon />
            <div id='PDP_FREIGHT_INFO' className='label-wrapper'>
              <Typography variant='body2'>
                Shipping:&nbsp;
                <span className={ProductShippingConstants.MESSAGES.GREY_COLOR}>
                  {ProductCardSectionMessagesConstants.SOLD_OUT}
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className='cta-cell'>
        <NteButton
          classes={{ root: 'Add-to-cart-button' }}
          variant='outlined'
          translationKey={
            ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS
          }
          onClick={seeDetailsClickHandler}
          fullWidth={true}
          size='small'
          type='button'
        />

        {
          <div className='field-labels'>
            <ProductCardSaveList
              id={productId}
              productData={productCard}
              name={productName}
              partNumber={productCard.partNumber}
              descriptionName={`${t(descName)}`}
            />
          </div>
        }
      </div>
    </>
  );

  const productPricingAndMessage = (
    <>
      <ProductPricing
        productCard={productCard}
        setBuyableWithNoPrice={setBuyableWithNoPrice}
        isCompareProduct={isCompareProduct}
        requestedQuantity={partNumberQuantityMap[productCard.partNumber]}
        isWishlistCard={isWishlistCard}
      />

      <ProductCardSectionMessages
        productCard={productCard}
        partNumber={productCard.partNumber}
        removeFromList={removeFromList}
        isCompareProduct={isCompareProduct}
        sharedList={sharedList}
        isProductCompare={isProductCompare}
        buyableWithNoPrice={buyableWithNoPrice}
        isGroupedInventory={isGroupedInventory}
        isWishlistCard={isWishlistCard}
        displayCompareCheckbox={
          hideCompareCheckbox ? !hideCompareCheckbox : displayCompareCheckbox
        }
        requestedQuantity={partNumberQuantityMap[productCard.partNumber]}
      />
    </>
  );

  return (
    <>
      {!isCompareProduct ? (
        <div
          className={`product_card nte-card ${className}`}
          role='group'
          aria-label='product'>
          <ProductCardSectionImage
            productName={productCard.name}
            productImage={
              productCard.fullImageRaw
                ? productCard.fullImageRaw
                : productCard.thumbnailRaw
                ? productCard.thumbnailRaw
                : (process.env.REACT_APP_PRODUCT_NO_IMAGE as string)
            }
            partNumber={productCard.partNumber}
            isCompareProduct={isCompareProduct}
            showRemove={showRemove}
            productUrl={
              productCard?.seo?.href.includes(ProductCardConstants.PRODUCT_URL)
                ? productCard?.seo?.href
                : ProductCardConstants.PRODUCT_URL + productCard?.seo?.href
            }
          />

          <ProductCardSectionDescription
            productCard={productCard}
            partNumber={productCard.partNumber}
            isCompareProduct={isCompareProduct}
            isProductCompare={isProductCompare}
            index={index}
            sortIndex={sortIndex}
            isWishlistCard={isWishlistCard}
            buyableWithNoPrice={buyableWithNoPrice}
            categorySkuNoPrice={categorySkuNoPrice}
          />

          {buyableWithNoPrice || categorySkuNoPrice
            ? unavailableProductDescription
            : productPricingAndMessage}
        </div>
      ) : (
        <div className='product_card compare'>
          <ProductCardSectionImage
            productName={productCard.name}
            productImage={
              productCard.fullImageRaw
                ? productCard.fullImageRaw
                : productCard.thumbnailRaw
                ? productCard.thumbnailRaw
                : (process.env.REACT_APP_PRODUCT_NO_IMAGE as string)
            }
            partNumber={productCard.partNumber}
            isCompareProduct={isCompareProduct}
            productUrl={
              productCard?.seo?.href.includes(ProductCardConstants.PRODUCT_URL)
                ? productCard?.seo?.href
                : ProductCardConstants.PRODUCT_URL + productCard?.seo?.href
            }
          />

          <ProductCardSectionDescription
            productCard={productCard}
            partNumber={productCard.partNumber}
            isCompareProduct={isCompareProduct}
            isProductCompare={isProductCompare}
            index={index}
            sortIndex={sortIndex}
            isWishlistCard={isWishlistCard}
          />

          {buyableWithNoPrice || categorySkuNoPrice
            ? unavailableProductDescription
            : productPricingAndMessage}
        </div>
      )}
    </>
  );
};

export { ProductCard };
