export const UPDATE_BRIGHTCOVE_VIDEOS = 'UPDATE_BRIGHTCOVE_VIDEOS';

export const UPDATE_PARENT_PRODUCT_VIDEOS = 'UPDATE_PARENT_PRODUCT_VIDEOS';

export const UPDATE_BV_REVIEWS = 'UPDATE_BV_REVIEWS';

export const RESET_BRIGHTCOVE_VIDEOS = 'RESET_BRIGHTCOVE_VIDEOS';

export const RESET_PARENT_PRODUCT_VIDEOS = 'RESET_PARENT_PRODUCT_VIDEOS';

export const RESET_BV_REVIEWS = 'RESET_BV_REVIEWS';

export const RESET_MEDIA_MODAL = 'RESET_MEDIA_MODAL';
