const MiniCartConstants = {
  NO_ITEMS_FOUND: 'No Items Found.',
  MAX_ITEMS_FLAG: 'true',
  MAX_LINE_QTY: 'SHOPPING_CART.MERGE_CART.MAX_LINE_QTY',
  MAX_ITEM_QTY: 'SHOPPING_CART.MERGE_CART.MAX_ITEM_QTY',
  MESSAGE: {
    ERROR: {
      SHOPPING_CART_MAX_ITEMS_ERROR_CODE: 'CART_001',
    },
  },
};

export { MiniCartConstants };
