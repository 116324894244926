import { createAction } from '@reduxjs/toolkit';
import { IContactInformation } from '../../_foundation/interface/Authentication/IAuth';
import {
  IAddGiftCard,
  IAddtoCart,
  IBulkOrder,
  ICartSpinner,
  ICheckout,
  IDeleteItemInCart,
  IGetCartDetails,
  IGetCartProductsRequest,
  IGuestAndCatalog,
  IPromoSpinner,
  IRemoveGiftCard,
  IShippingInfoRequest,
  IUpdateCartDetails,
} from '../../_foundation/interface/Cart/IOrder';
import ICartProductsResponse from '../../_foundation/interface/Responses/ICartProductsResponse';
import IShippingInfoResponse from '../../_foundation/interface/Responses/IShippingInfoResponse';
import { IShippingAndBillingInfo } from '../../_foundation/interface/Shipping/IUsableShipping';
import * as ACTION_TYPES from '../action-types/order.action-types';

interface AddToCartParam {
  storeID: string;
  partNumber: string;
  WCTokenDetail?: string;
  WCTrustedTokenDetail?: string;
  comment?: string;
}

export const FETCH_CART_DETAILS_REQUEST_ACTION = createAction<IGetCartDetails>(
  ACTION_TYPES.FETCH_CART_DETAILS_REQUEST
);

/**
 *  Need to add proper Interface instead of any, string
 */
export const FETCH_CART_DETAILS_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.FETCH_CART_DETAILS_SUCCESS
);

export const REQUEST_ERROR_ACTION = createAction<boolean>(
  ACTION_TYPES.REQUEST_ERROR
);

export const PROMO_APPLY_BUTTON_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.APPLY_BUTTON
);

export const PROMO_REMOVE_BUTTON_LOADING_ACTION = createAction<IPromoSpinner>(
  ACTION_TYPES.REMOVE_BUTTON
);

export const RESET_CART_ACTION = createAction(ACTION_TYPES.RESET_CART);

export const ADD_TO_CART_REQUEST_ACTION = createAction<AddToCartParam>(
  ACTION_TYPES.ADD_TO_CART_REQUEST
);

export const GUEST_AND_CART_DETAILS_ACTION = createAction<IAddtoCart>(
  ACTION_TYPES.GUEST_AND_CART_DETAILS
);

export const GUEST_AND_CATALOG_ORDER_ACTION = createAction<IGuestAndCatalog>(
  ACTION_TYPES.GUEST_AND_CATALOG_ORDER
);

export const GUEST_AND_CATALOG_ORDER_SUCCESS_ACTION = createAction<IBulkOrder>(
  ACTION_TYPES.GUEST_AND_CATALOG_ORDER_SUCCESS
);

export const RESET_GUEST_AND_CATALOG_ORDER_ACTION = createAction(
  ACTION_TYPES.RESET_GUEST_AND_CATALOG_ORDER
);

export const SHOW_CART_DRAWER_ACTION = createAction<boolean>(
  ACTION_TYPES.SHOW_CART_DRAWER
);

export const UPDATE_ITEM_IN_CART_ACTION = createAction<IUpdateCartDetails>(
  ACTION_TYPES.UPDATE_ITEM_IN_CART
);

export const DELETE_ITEM_IN_CART_ACTION = createAction<IDeleteItemInCart>(
  ACTION_TYPES.DELETE_ITEM_IN_CART
);

export const GET_CART_PRODUCTS_ACTION = createAction<IGetCartProductsRequest>(
  ACTION_TYPES.GET_CART_PRODUCTS
);

export const GET_CART_PRODUCTS_SUCCESS_ACTION =
  createAction<ICartProductsResponse>(ACTION_TYPES.GET_CART_PRODUCTS_SUCCESS);

export const SET_CART_PAGE_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_CART_PAGE_LOADING
);

export const FETCH_SHIPPING_INFO_ACTION = createAction<IShippingInfoRequest>(
  ACTION_TYPES.FETCH_SHIPPING_INFO
);

export const FETCH_SHIPPING_INFO_SUCCESS_ACTION =
  createAction<IShippingInfoResponse>(ACTION_TYPES.FETCH_SHIPPING_INFO_SUCCESS);

export const UPDATE_CHECKOUT_BILLING_ADDRESS_ACTION =
  createAction<IContactInformation>(
    ACTION_TYPES.UPDATE_CHECKOUT_BILLING_ADDRESS
  );

export const UPDATE_SHIPPING_AND_BILLING_INFO_ACTION =
  createAction<IShippingAndBillingInfo>(
    ACTION_TYPES.UPDATE_SHIPPING_AND_BILLING_INFO
  );

export const UPDATE_GUEST_CONTACT_INFO_ACTION = createAction<
  IContactInformation[]
>(ACTION_TYPES.UPDATE_GUEST_CONTACT_INFO);
createAction<IShippingInfoResponse>(
  ACTION_TYPES.UPDATE_CHECKOUT_BILLING_ADDRESS
);

export const SET_ADD_TO_CART_LOADING_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_ADD_TO_CART_LOADING
);

export const FETCH_GUEST_CONTACT_INFO_ACTION = createAction<{
  storeID: string;
  WCToken: string;
  WCTrustedToken: string;
}>(ACTION_TYPES.FETCH_GUEST_CONTACT_INFO);
export const SET_CURRENT_PART_NUMBER_CART_ACTION = createAction<string>(
  ACTION_TYPES.SET_CURRENT_PART_NUMBER_CART
);

export const SET_CART_SPINNER_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_CART_SPINNER
);

export const FETCH_USABLE_PAYMENT_INFO_ACTION = createAction<{
  storeID: string;
  history?: any;
}>(ACTION_TYPES.FETCH_USABLE_PAYMENT_INFO);

export const UPDATE_USABLE_PAYMENT_INFO_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_USABLE_PAYMENT_INFO
);

export const FETCH_PAYMENT_INFO_ACTION = createAction(
  ACTION_TYPES.FETCH_PAYMENT_INFO
);

export const UPDATE_PAYMENT_INFO_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_PAYMENT_INFO
);

export const SET_REMOVE_SPINNER_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_REMOVE_SPINNER
);

export const SET_SAVE_TO_LIST_SPINNER_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_SAVE_TO_LIST_SPINNER
);

export const SET_REPLACMENT_PLAN_SPINNER_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_REPLACMENT_PLAN_SPINNER
);

export const SET_CART_ITEMS_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_CART_ITEMS_LOADING
);

export const RESET_CART_ITEMS_ACTION = createAction(
  ACTION_TYPES.RESET_CART_ITEMS
);

export const PRECHECKOUT_CART_ACTION = createAction<ICheckout>(
  ACTION_TYPES.PRECHECKOUT_CART_ACTION_REQUEST
);

export const PRECHECKOUT_CART_SUCCESS_ACTION = createAction<ICheckout>(
  ACTION_TYPES.PRECHECKOUT_CART_SUCCESS
);

export const ADD_GIFT_CARD_ACTION = createAction<IAddGiftCard>(
  ACTION_TYPES.APPLY_GIFT_CARD
);

export const REMOVE_GIFT_CARD_ACTION = createAction<IRemoveGiftCard>(
  ACTION_TYPES.REMOVE_GIFT_CARD
);

export const SET_BACKORDERED_STATE_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_BACKORDERED_STATE
);

export const ORDER_CALCULATE_ACTION = createAction<{
  storeID: string;
  history?: any;
}>(ACTION_TYPES.ORDER_CALCULATE);

export const ENABLE_DELIVERY_STEP_ACTION = createAction<boolean>(
  ACTION_TYPES.ENABLE_DELIVERY_STEP
);

export const ALLOW_MULTIPLE_PAYMENTS_ACTION = createAction<boolean>(
  ACTION_TYPES.ALLOW_MULTIPLE_PAYMENTS
);

export const SET_PAYMENT_ERROR_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_PAYMENT_ERROR
);

export const SET_TREVIPAY_RESPONSE_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_PAYMENT_ERROR
);

export const SET_GIFTCARD_ERROR_ACTION = createAction<any>(
  ACTION_TYPES.SET_GIFTCARD_ERROR
);

export const RESET_GIFTCARD_ERROR_ACTION = createAction(
  ACTION_TYPES.RESET_GIFTCARD_ERROR
);
