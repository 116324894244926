import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * @method useWidth calculates and returns the current
 * width of the screen.
 *
 * @returns currentWidth of the screen.
 */
const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();

  const currentWidth =
    keys.reduce((output: any, key) => {
      /**
       * matches is responsible for fetching the applications
       * breakpoint and check if it matches the current
       * screen break point using the useMediaQuery hook,
       */
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs';

  return currentWidth;
};

export { useWidth };
