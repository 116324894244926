import React from 'react';

/**
 * @interface IScreenReaderOnly
 */
interface IScreenReaderOnly {
  message: string;
  ariaLabelledBy?: string;
}

/**
 * @component ScreenReaderOnly adds a screen reader text.
 *
 * @param IScreenReaderOnly
 */
const ScreenReaderOnly: React.FC<IScreenReaderOnly> = ({
  message,
  ariaLabelledBy,
}) => (
  <span aria-labelledby={ariaLabelledBy} className='sr-only'>
    {message}
  </span>
);

export { ScreenReaderOnly };
