import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { authenticationSelector } from '../../../../../../redux/selectors/auth.selector';
import { useSignout } from '../../../../../../utils/hooks/sign-out/sign-out';
import { MenuListBody } from '../../../../Menu/MenuListBody/MenuListBody';
import { MenuListHeader } from '../../../../Menu/MenuListHeader/MenuListHeader';
import * as ROUTES from '../../../../../../constants/routes';
import { AccountListConstants } from './AccountListConstants';

interface Props {
  /**
   * @prop accountListCloseHandler handles the
   * popper component close events.
   */
  accountListCloseHandler: () => void;

  /**
   * @prop menuItems has the items to be
   * rendered as the list.
   */
  menuItems: any;
}

/**
 * @component AccountList is responsible for rendering the
 * account list menu inside the Account Menu Popper component.
 */
const AccountList: React.FC<Props> = ({
  accountListCloseHandler,
  menuItems,
}) => {
  const history = useHistory();

  const { signout } = useSignout();

  const { pathname } = useLocation();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const { SIGN_OUT } = AccountListConstants;

  /**
   * @method onMenuListItemClickHandler is responsible for handling
   * the click events from the menu items.
   */
  const onMenuListItemClickHandler = (selectedEvent: any): void => {
    const page = menuItems.find(
      (menu: any) => menu.item === selectedEvent.currentItem
    );

    if (page.name === SIGN_OUT) {
      accountListCloseHandler();

      signout();

      if (isUserAuthenticated && pathname === ROUTES.ORDER_CONFIRMATION) {
        history.push(ROUTES.HOME);
      }

      if (isUserAuthenticated && pathname === ROUTES.PLATINUM_MEMBERSHIP) {
        history.push(ROUTES.HOME);
      }

      if (isUserAuthenticated && pathname === ROUTES.GREAT_PLAINS_MEMBERSHIP) {
        history.push(ROUTES.HOME);
      }

      if (
        isUserAuthenticated &&
        pathname === ROUTES.NORTHERN_PREFERRED_MEMBERSHIP
      ) {
        history.push(ROUTES.HOME);
      }

      return;
    }

    // remove this logic when advantage membership benefit page is done.
    if (page.name !== 'ADVANTAGE_MEMEBERSHIP_BENEFITS') {
      history.push(page.route);
    }

    // Close the Account Menu Popper.
    accountListCloseHandler();
  };

  return (
    <>
      {/* Menu list header. */}
      <MenuListHeader
        closeHandler={accountListCloseHandler}
        menuTitle='Account'
        id='account-heading'
      />

      {/* Menu list body. */}
      <MenuListBody
        clickHandler={onMenuListItemClickHandler}
        menuItems={menuItems}
      />
    </>
  );
};

export { AccountList };
