import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 *
 * @returns Product Offer Skeleton.
 */
const ProductPromotionSkeleton = () => {
  return (
    <div className='skeleton-container'>
      <Typography variant='h6'>
        <Skeleton className='icon-skeleton' animation='wave' variant='circle' />
        <Skeleton className='content-skeleton' animation='wave' />
      </Typography>
    </div>
  );
};

export { ProductPromotionSkeleton };
