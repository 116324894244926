import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StoreLocatorConstants } from '../../../../components/Widgets/AppBar/SecondaryMenuItems/StoreLocator/StoreLocatorConstants';
import { SHOW_FIND_STORE } from '../../../../redux/action-types/storeLocator';
import {
  GET_STORES_ACTION,
  STORE_LOADER_ACTION,
} from '../../../../redux/actions/storeLocator';
import { yottaService } from '../../../../_foundation/apis/transaction/yotta/yotta.service';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import {
  IGeoCoordinates,
  MyStoreCookie,
} from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { allowGeoCoordinates } from '../../../utils';

/**
 * @method useInitStoreLocator Initializes the geoCoordinates and fetches store details
 * data based on the user's coordinates.
 */
const useInitStoreLocator = () => {
  const { MY_STORE_COOKIE } = StoreLocatorConstants;

  const { mySite } = useSite();

  const dispatch = useDispatch();

  const [geoCoordinates, setGeoCoordinates] = useState<IGeoCoordinates>();

  /**
   * @method initStoreLocator Fetches the store details data for the store locator.
   */
  const initStoreLocator = useCallback((): void => {
    if (geoCoordinates) {
      const storeCookie = sessionStorage.getItem(MY_STORE_COOKIE);

      if (storeCookie) {
        const parsedStoreDetail: MyStoreCookie = JSON.parse(storeCookie);

        if (parsedStoreDetail.storeName) {
          dispatch(STORE_LOADER_ACTION({ loading: false }));
        }

        if (mySite) {
          if (geoCoordinates || parsedStoreDetail) {
            const { storeID, storeLocatorDefaultRadius } = mySite;

            dispatch(
              GET_STORES_ACTION({
                storeID,
                latitude: parsedStoreDetail?.latitude
                  ? parsedStoreDetail?.latitude
                  : geoCoordinates.lat,
                longitude: parsedStoreDetail?.longitude
                  ? parsedStoreDetail?.longitude
                  : geoCoordinates.lng,
                radius: storeLocatorDefaultRadius,
              })
            );
          }
        }
      }
    }
  }, [MY_STORE_COOKIE, dispatch, geoCoordinates, mySite]);

  useEffect(() => {
    initStoreLocator();
  }, [mySite, initStoreLocator, geoCoordinates]);

  /**
   * @method getCurrentCoordinates is invokes as success response when the user allows the
   * geoLocation API and sets the state for the current geoCoordinates.
   */
  const getCurrentCoordinates = useCallback(
    (position: GeolocationPosition) => {
      const storeCookie = sessionStorage.getItem(MY_STORE_COOKIE);

      if (storeCookie) {
        const parsedStoreCookie = JSON.parse(storeCookie);

        const updatedStoreCookie: MyStoreCookie = {
          ...parsedStoreCookie,
          geoCoordinates: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        };

        sessionStorage.setItem(
          MY_STORE_COOKIE,
          JSON.stringify(updatedStoreCookie)
        );
      } else {
        const updatedStoreCookie = {
          geoCoordinates: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        };

        sessionStorage.setItem(
          MY_STORE_COOKIE,
          JSON.stringify(updatedStoreCookie)
        );
      }

      setGeoCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    },
    [MY_STORE_COOKIE]
  );

  /**
   * @method initYotta is responsible for initializing the Yotta.
   */
  const initYotta = useCallback(async (): Promise<void> => {
    const { lat: latitude, long: longitude } = yottaService.getGeoLocation();

    // Update Cookie with the Latest Location information from yotta.
    const storeCookie = sessionStorage.getItem(MY_STORE_COOKIE);

    if (storeCookie) {
      const parsedStoreCookie = JSON.parse(storeCookie);

      const updatedStoreCookie: MyStoreCookie = {
        ...parsedStoreCookie,
        geoCoordinates: {
          lat: latitude,
          lng: longitude,
        },
      };

      sessionStorage.setItem(
        MY_STORE_COOKIE,
        JSON.stringify(updatedStoreCookie)
      );
    }

    if (!storeCookie && latitude && longitude) {
      const updatedStoreCookie = {
        geoCoordinates: {
          lat: latitude,
          lng: longitude,
        },
      };

      sessionStorage.setItem(
        MY_STORE_COOKIE,
        JSON.stringify(updatedStoreCookie)
      );
    }

    if (latitude && longitude) {
      setGeoCoordinates({
        lat: latitude,
        lng: longitude,
      });
    } else {
      // Enable Store look up screen.
      dispatch({ type: SHOW_FIND_STORE });
    }
  }, [MY_STORE_COOKIE, dispatch]);

  /**
   * @method geoLocationPositionError is responsible for hanlding error for
   * geoLocation in case if user denies permission this
   * method will be executed.
   */
  const geoLocationPositionError = useCallback(
    (geoLocationPositionError: GeolocationPositionError) => {
      /**
       * Call Yotta to fetch the geoCoordinates once the
       * user denies the permission for the geoLocation API.
       */
      if (
        geoLocationPositionError.PERMISSION_DENIED ===
        geoLocationPositionError.code
      ) {
        initYotta();
      }
    },
    [initYotta]
  );

  /**
   * @method checkCookie Checks the whether the store details are already available in the cookie.
   */
  const checkCookie = useCallback((): void => {
    const storeCookie = sessionStorage.getItem(MY_STORE_COOKIE);

    if (storeCookie) {
      const parsedStoreCookie: MyStoreCookie = JSON.parse(storeCookie);

      if (parsedStoreCookie?.geoCoordinates) {
        const {
          geoCoordinates: { lat, lng },
        } = parsedStoreCookie;

        setGeoCoordinates({
          lat,
          lng,
        });
      }
    } else {
      allowGeoCoordinates(getCurrentCoordinates, geoLocationPositionError);
    }
  }, [MY_STORE_COOKIE, geoLocationPositionError, getCurrentCoordinates]);

  useEffect(() => {
    checkCookie();
  }, [checkCookie]);
};

export { useInitStoreLocator };
