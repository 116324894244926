const TRANSLATION_KEY = 'INCOMPATIBLE_BROWSER_ALERT';

export const BrowserAlertConstants = {
  TRANSLATIONS: {
    LINE_1: `${TRANSLATION_KEY}.LINE_1`,
    LINE_2: `${TRANSLATION_KEY}.LINE_2`,
    LINE_3: `${TRANSLATION_KEY}.LINE_3`,
    BROWSERS: {
      CHROME: `${TRANSLATION_KEY}.BROWSERS.CHROME`,
      FIREFOX: `${TRANSLATION_KEY}.BROWSERS.FIREFOX`,
      EDGE: `${TRANSLATION_KEY}.BROWSERS.EDGE`,
      SAFARI: `${TRANSLATION_KEY}.BROWSERS.SAFARI`,
    },
  },
  DOWNLOAD_URLS: {
    CHROME_URL: 'https://www.google.com/chrome/',
    FIREFOX_URL: 'https://www.mozilla.org/en-US/firefox/new/',
    EDGE_URL: 'https://www.microsoft.com/en-us/edge',
    SAFARI_URL: 'https://www.apple.com/safari/',
  },
};

export const SESSION_STORAGE_KEY = 'sessionID';

export const SESSION_STORAGE_UTM_SOURCE_KEY = 'utm_source';

export const LOCALSTORAGE_CJEVENT_KEY = 'cjevent';
export const COOKIE_CJEVENT_KEY = 'cje';
