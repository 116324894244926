import { createReducer } from '@reduxjs/toolkit';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { IDefiningAttributes, ISwatchState } from '../../interface/ISwatch';
import {
  RESET_DEFINING_ATTRIBUTES_ACTION,
  RESET_SWATCH_SKU_ACTION,
  UPDATE_DEFINING_ATTRIBUTES_ACTION,
  UPDATE_RESOLVED_SKU_PARTNUMBER_ACTION,
  UPDATE_SKU_PRODUCT_DETAIL_ACTION,
} from '../actions/swatch.actions';

const productDetails: IProductListContents = {
  attributes: [],
  auxDescription1: '',
  availabilityDate: new Date(),
  buyable: '',
  catalogEntryTypeCode: '',
  fullImageRaw: '',
  hasSingleSKU: '',
  id: '',
  longDescription: '',
  name: '',
  numberOfSKUs: 0,
  parentCatalogGroupID: '',
  partNumber: '',
  price: [],
  sKUs: [],
  seo: { href: '' },
  shortDescription: '',
  storeID: '',
  thumbnail: '',
  thumbnailRaw: '',
  type: '',
  uniqueID: '',
  UserData: [{ eOfferPrice: [] }],
  alt: '',
  fullImage: '',
  images: [],
  merchandisingAssociations: [],
};

const initSwatchState: ISwatchState = {
  partNumber: '',
  skuProductDetails: productDetails,
  skuProductDetailsLoading: true,
  definingAttribures: [],
};

const swatchReducer = createReducer(initSwatchState, (builder) => {
  builder.addCase(
    UPDATE_RESOLVED_SKU_PARTNUMBER_ACTION,
    (state: ISwatchState, action: any) => {
      return {
        ...state,
        partNumber: action.payload,
        skuProductDetails: productDetails,
        skuProductDetailsLoading: true,
      };
    }
  );

  builder.addCase(
    UPDATE_SKU_PRODUCT_DETAIL_ACTION,
    (state: ISwatchState, action: any) => {
      if (action.payload) {
        return {
          ...state,
          skuProductDetails: action.payload,
          skuProductDetailsLoading: false,
          definingAttribures: [],
        };
      }
    }
  );

  builder.addCase(
    UPDATE_DEFINING_ATTRIBUTES_ACTION,
    (state: ISwatchState, action: any) => {
      const definingAttribures = action.payload as IDefiningAttributes[];

      return { ...state, definingAttribures };
    }
  );

  builder.addCase(RESET_DEFINING_ATTRIBUTES_ACTION, (state: ISwatchState) => {
    return { ...state, definingAttribures: [] };
  });

  builder.addCase(RESET_SWATCH_SKU_ACTION, () => {
    return { ...initSwatchState };
  });
});

export { swatchReducer };
