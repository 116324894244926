/**
 * key is url pathname value is composer name
 */

export const pageComposerMap = new Map<string, string>([
  ['/storefeedback', 'Store Feedback'],
]);

export const PAGE_TYPE = 'Composer Page';

export const SEO_PAGE_LIST = ['partsservicerepair'];

export const PRODUCT_LIST_PAGE = 'ProductListPage';
export const CATEGORY_PAGE = 'CategoryPage';
export const CONTENT_PAGE = 'ContentPage';
