
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IBreadcrumbsResponse from './IBreadcrumbsResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IBreadcrumbsResponse };

  export const IBreadcrumbsResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IBreadCrumbTrailEntryViewItem": {
            "properties": {
                "label": {},
                "seo": {
                    "$ref": "#/definitions/ISeo"
                },
                "type_": {
                    "type": "string"
                },
                "value": {
                    "type": "string"
                }
            },
            "required": [
                "label",
                "seo",
                "type_",
                "value"
            ],
            "type": "object"
        },
        "ISeo": {
            "properties": {
                "href": {
                    "type": "string"
                }
            },
            "required": [
                "href"
            ],
            "type": "object"
        }
    },
    "properties": {
        "breadCrumbTrailEntryView": {
            "items": {
                "$ref": "#/definitions/IBreadCrumbTrailEntryViewItem"
            },
            "type": "array"
        },
        "total": {}
    },
    "required": [
        "breadCrumbTrailEntryView"
    ],
    "type": "object"
}

;

  export const isIBreadcrumbsResponse = ajv.compile(IBreadcrumbsResponseSchema) as ValidateFunction<
    IBreadcrumbsResponse
  >;

  export default function validateIBreadcrumbsResponse(value: unknown): IBreadcrumbsResponse {
    if (isIBreadcrumbsResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIBreadcrumbsResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IBreadcrumbsResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIBreadcrumbsResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IBreadcrumbsResponse' },
        ) +
          inspect(value)
      );
    }
  }