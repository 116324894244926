import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CHECKOUT_DELIVERY,
  CHECKOUT_PAYMENT,
  CHECKOUT_REVIEW,
  SHOPPING_CART,
} from '../../../../../constants/routes';
import { getLocalStorage } from '../../../../../utils/utils';
import {
  PAYMENT_METHOD,
  PAYPAL,
} from '../../../../../_foundation/constants/payment';
import { deliverySelector } from '../../../redux/selectors/checkoutDelivery.selector';
import { checkoutPaymentSelector } from '../../../redux/selectors/checkoutPayment.selector';
import { checkoutReviewSelector } from '../../../redux/selectors/checkoutReview.selector';
import CheckoutStepperConstants, {
  ICheckoutSteps,
} from '../CheckoutStepper.constants';

/**
 * @method useCheckoutStepper Handles the checkout navigation based on the current wizard step.
 */
const useCheckoutStepper = () => {
  const { steps } = CheckoutStepperConstants;

  const initialSteps: ICheckoutSteps[] = useMemo(() => {
    const initialSteps = JSON.parse(JSON.stringify(steps));

    return initialSteps ? initialSteps : [];
  }, [steps]);

  const {
    cartItems: { orderItem: deliveryOrderItem },
    cartItemsLoading: deliveryLoading,
    isContinueEnabled: isDeliveryEnabled,
  } = useSelector(deliverySelector);

  const {
    cartItems: { paymentInstruction: paymentStepPaymentInstruction },
    cartItemsLoading: paymentLoading,
  } = useSelector(checkoutPaymentSelector);

  const {
    cartItems: { paymentInstruction: reviewPaymentInstruction },
    cartItemsLoading: reviewLoading,
  } = useSelector(checkoutReviewSelector);

  const [currentSteps, setCurrentSteps] =
    useState<ICheckoutSteps[]>(initialSteps);

  const history = useHistory();

  let noOfCartItems = 0;

  let loading = true;

  if (history.location.pathname === CHECKOUT_DELIVERY) {
    noOfCartItems = deliveryOrderItem?.length;

    loading = deliveryLoading;
  }

  if (history.location.pathname === CHECKOUT_PAYMENT) {
    loading = paymentLoading;
  }

  if (history.location.pathname === CHECKOUT_REVIEW) {
    loading = reviewLoading;
  }

  /**
   * @callback disableWizardStep Disables the wizard step based on the routes.
   */
  const disableWizardStep = useCallback(
    (routes: string[]): void => {
      const updatedSteps: ICheckoutSteps[] = [...initialSteps];

      updatedSteps.forEach(({ route }, index) => {
        if (routes.includes(route)) {
          updatedSteps[index].disabled = false;
        }
      });

      setCurrentSteps(updatedSteps);
    },
    [initialSteps]
  );

  /**
   * @callback initWizardSteps Initializes the wizard steps based on the cart flow completion.
   */
  const initWizardSteps = useCallback((): void => {
    if (!loading) {
      /**
       * When there are no items in the cart we need to initialize
       * the steps and redirect the user back to the cart.
       */
      if (noOfCartItems === 0) {
        setCurrentSteps(initialSteps);
      }

      /**
       * When there are items in the cart and no payment instruction,
       * we need to keep the user in the delivery page.
       */
      if (noOfCartItems > 0 && paymentStepPaymentInstruction?.length === 0) {
        disableWizardStep([CHECKOUT_DELIVERY]);
      }

      /**
       * On completion of the delivery step, we need to allow the user to the payment step.
       */
      if (isDeliveryEnabled) {
        disableWizardStep([CHECKOUT_DELIVERY, CHECKOUT_PAYMENT]);
      }

      const isPaypal = getLocalStorage(PAYMENT_METHOD);

      /**
       * When the user adds a payment method, we can allow the user to the review step.
       */
      if (
        (reviewPaymentInstruction && reviewPaymentInstruction?.length !== 0) ||
        isPaypal === PAYPAL
      ) {
        disableWizardStep([
          CHECKOUT_DELIVERY,
          CHECKOUT_PAYMENT,
          CHECKOUT_REVIEW,
        ]);
      }
    }
  }, [
    disableWizardStep,
    isDeliveryEnabled,
    loading,
    noOfCartItems,
    paymentStepPaymentInstruction,
    reviewPaymentInstruction,
    initialSteps,
  ]);

  useEffect(() => {
    initWizardSteps();
  }, [initWizardSteps]);

  /**
   * @callback redirectToCart Redirect the user to the cart if all the flows are disabled.
   */
  const redirectToCart = useCallback((): void => {
    if (!loading) {
      const currentStep = currentSteps.filter(
        ({ route }) => history.location.pathname === route
      )[0];

      if (currentStep && currentStep.disabled) {
        history.push(SHOPPING_CART);
      }
    }
  }, [currentSteps, history, loading]);

  useEffect(() => {
    redirectToCart();
  }, [redirectToCart, loading]);

  useEffect(() => {
    return () => {
      setCurrentSteps([...steps]);
    };
  }, [steps]);

  return { currentSteps };
};

export { useCheckoutStepper };
