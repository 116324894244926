import { STORE_MENU_GET_PARTS } from '../../../../../../constants/routes';

const translationKey = 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR';

// Constants for MyStore Component.
const MyStoreConstants = {
  FIND_A_STORE: `${translationKey}.FIND_A_STORE`,
  FIND_MY_STORE: `${translationKey}.FIND_MY_STORE`,
  CHANGE_MY_STORE: `${translationKey}.CHANGE_MY_STORE`,
  SEE_OTHER_STORE: `${translationKey}.SEE_OTHER_STORE`,
  GET_DIRECTIONS: `${translationKey}.GET_DIRECTIONS`,
  STORE_AD: `${translationKey}.STORE_AD`,
  GET_PARTS: `${translationKey}.GET_PARTS`,
  ERR_STORE_AD: `${translationKey}.ERROR_MESSAGE.ERR_STORE_AD`,
  URLS: {
    GOOGLE_MAPS_BASE_URL: 'https://www.google.com/maps/place/',
    GET_PARTS_PATH: STORE_MENU_GET_PARTS,
  },
  REGION_AD_ATTRIBUTE: 'adRegionDesc',
  FIND_A_STORE_FALLBACK: 'Find a Store',
  FIND_MY_STORE_FALLBACK: 'Find My Store',
  CHANGE_MY_STORE_FALLBACK: 'Change My Store',
};

export { MyStoreConstants };
