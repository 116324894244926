import { useDispatch } from 'react-redux';
import {
  RESET_ASSIGN_STORE_ACTION,
  RESET_DELIVERY_STEP_ACTION,
  RESET_PAYMENT_STEP_ACTION,
  RESET_RESTRICTED_PRODUCTS_ACTION,
  RESET_REVIEW_STEP_ACTION,
  RESET_SHIPPING_OPTIONS_ACTION,
} from '../../../components/Checkout/redux/actions/checkout.actions';
import { RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION } from '../../../components/Widgets/SaveToListMenu/actions/save-to-list.actions';
import { SIGN_OUT_ACTION } from '../../../redux/actions/auth.actions';
import { RESET_CART_ACTION } from '../../../redux/actions/order.actions';
import { RESET_ORDER_CONFIRMATION_ACTION } from '../../../redux/actions/orderConfirmation.action';
import { RESET_FETCH_CART_ITEM_DETAILS_ACTION } from '../../../redux/actions/orderDetails.action';
import { RESET_WISH_LIST_ACTION } from '../../../redux/actions/wish-list.actions';

/**
 * @method useSignout is responsible for clearing user auth
 * values and refreshing the redux state.
 */
const useSignout = () => {
  const dispatch = useDispatch();

  const signout = (isSessionInvalid = false): void => {
    dispatch(SIGN_OUT_ACTION(isSessionInvalid));

    dispatch(RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION());

    dispatch(RESET_WISH_LIST_ACTION());

    dispatch(RESET_CART_ACTION());

    dispatch(RESET_FETCH_CART_ITEM_DETAILS_ACTION());

    dispatch(RESET_ORDER_CONFIRMATION_ACTION());

    dispatch(RESET_SHIPPING_OPTIONS_ACTION());

    dispatch(RESET_DELIVERY_STEP_ACTION());

    dispatch(RESET_RESTRICTED_PRODUCTS_ACTION());

    dispatch(RESET_PAYMENT_STEP_ACTION());

    dispatch(RESET_REVIEW_STEP_ACTION());

    dispatch(RESET_ASSIGN_STORE_ACTION());
  };

  return { signout };
};

export { useSignout };
