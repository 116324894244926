import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_MERGE_CART_ERROR_ACTION } from '../../redux/actions/network-errors.actions';
import {
  FETCH_CART_DETAILS_REQUEST_ACTION,
  SHOW_CART_DRAWER_ACTION,
} from '../../redux/actions/order.actions';
import { cartSelector } from '../../redux/selectors/cart.selector';
import { orderSelector } from '../../redux/selectors/order.selector';
import { useSite } from '../../_foundation/hooks/usesite/useSite';
import { NteDrawer } from '../Drawers/NteDrawer/NteDrawer';
import { MiniCart } from './MiniCart';
import { MiniCartConstants } from './MiniCartConstants';

/**
 * @component MiniCartDrawer responsible to show cart items
 */
const MiniCartDrawer: React.FC = () => {
  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { showMiniCart } = useSelector(cartSelector);

  const { maxLineItems, maxQuantity } = useSelector(orderSelector);

  const { MAX_ITEMS_FLAG } = MiniCartConstants;

  /**
   * @method miniCartCloseHandler handles mini cart close events.
   */
  const miniCartCloseHandler = (): void => {
    dispatch(SHOW_CART_DRAWER_ACTION(false));
    dispatch(RESET_MERGE_CART_ERROR_ACTION());

    if (
      (maxLineItems === MAX_ITEMS_FLAG || maxQuantity === MAX_ITEMS_FLAG) &&
      mySite
    ) {
      dispatch(
        FETCH_CART_DETAILS_REQUEST_ACTION({
          storeID: mySite.storeID,
        })
      );
    }
  };

  return (
    <NteDrawer
      anchor='right'
      id='mini-cart-drawer'
      ariaLabelledById='mini-cart-header'
      nteDrawerCloseHandler={miniCartCloseHandler}
      showNteDrawer={showMiniCart}>
      <MiniCart
        id='mini-cart-header'
        miniCartCloseHandler={miniCartCloseHandler}
      />
    </NteDrawer>
  );
};

export { MiniCartDrawer };
