import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

/**
 * @interface NteEspotSkeletonProps.
 */
export interface NteEspotSKeletonProps {
  /**
   * ClassName for the Espot Skeleton
   */
  className?: string;
}

/**
 * @component NteEspotSkeleton renders the skeleton for NteESpot component.
 */
const NteEspotSkeleton: React.FC<NteEspotSKeletonProps> = ({
  className = '',
}) => (
  <div className={className}>
    <Paper>
      <Typography variant='h1'>
        <Skeleton />
      </Typography>

      <Typography variant='body1'>
        <Skeleton />
      </Typography>

      <Typography variant='body1'>
        <Skeleton />
      </Typography>
    </Paper>
  </div>
);

export { NteEspotSkeleton };
