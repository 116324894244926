import { createAction } from '@reduxjs/toolkit';
import { IFetchSearchTerm } from '../../_foundation/apis/search/search-siteContent/siteContent.service';
import * as ACTION_TYPES from '../action-types/suggestion.action-type';

export const FETCH_SUGGEST_TYPE_REQUEST_ACTION = createAction<IFetchSearchTerm>(
  ACTION_TYPES.FETCH_SUGGESTION_TYPE_REQUEST
);

export const FETCH_SUGGEST_TYPE_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.FETCH_SUGGESTION_TYPE_SUCCESS
);
