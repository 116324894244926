import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the street address input field.
 */
const StreetAddressValidator = {
  STREET_ADDRESS: {
    name: 'streetAddress',
    id: 'street-address',
    label: 'Street Address*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Street address is required.'),
      (value: string) =>
        FieldValidations.ADDRESS(
          value,
          'Street address can only be of maximum 35 characters.'
        )
    ),
    fieldParse: FieldParsing.NAME,
    sensitiveData: true,
  },
};

/**
 * Validator for the street address 2 input field.
 */
const StreetAddress2Validator = {
  STREET_ADDRESS2: {
    name: 'streetAddress2',
    id: 'street-address2',
    label: 'Street Address 2',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.ADDRESS(
          value,
          'Street address can only be of maximum 35 characters.'
        ),
      (value: string) =>
        FieldValidations.UNICODE(value, 'Invalid character used.')
    ),
    fieldParse: FieldParsing.NAME,
    sensitiveData: true,
  },
};

export { StreetAddressValidator, StreetAddress2Validator };
