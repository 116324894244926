import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import {
  ADD_ITEM_TO_MENU_LIST_ACTION,
  GET_ITEM_FROM_MENU_LIST_ACTION,
} from '../../../components/Widgets/SaveToListMenu/actions/save-to-list.actions';
import i18n from '../../../i18n';
import { alertMessageId, isNetworkError } from '../../../utils/utils';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { wishListService } from '../../../_foundation/apis/transaction/wish-list/wishList.service';
import { IShareListRequest } from '../../../_foundation/interface/WishList/IWishList';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import { TIER_1_ERROR_ACTION } from '../../actions/network-errors.actions';
import {
  ADD_REMOVE_WISH_LIST_SUCCESS_ACTION,
  DELETE_REMOVE_WISH_LIST_SUCCESS_ACTION,
  FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION,
  FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION,
  GET_WISH_LIST_ACTION,
  GET_WISH_LIST_SUCCESS_ACTION,
  REFRESH_WISH_LIST_PLP_ACTION,
  SHARE_WISH_LIST_SUCCESS_ACTION,
} from '../../actions/wish-list.actions';

const translationKey = 'API_ERROR_HANDLING.MY_ACCOUNT.MY_SAVED_LISTS';

export function* fetchWishList(action: any): Generator {
  try {
    const response: any = yield call(
      wishListService.fetchWishList,
      action.payload
    );

    yield put(GET_WISH_LIST_SUCCESS_ACTION(response));

    yield put(GET_ITEM_FROM_MENU_LIST_ACTION(response));
  } catch (e) {
    if (action.payload.isWishListPage) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.FETCH_WISHLIST.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }
  }
}

export function* createAddWishListItem(action: any): Generator {
  try {
    const { isAlreadySaved, currentProduct, currentItemId, storeID, item } =
      action.payload;

    const addRemoveResponse: any = yield call(
      wishListService.createAddWishListItem,
      {
        isAlreadySaved,
        currentProduct,
        storeID,
        currentItemId,
        item,
      }
    );

    const wishListResponse: any = yield call(
      wishListService.fetchWishList,
      action.payload
    );

    yield put(GET_WISH_LIST_SUCCESS_ACTION(wishListResponse));

    yield put(ADD_REMOVE_WISH_LIST_SUCCESS_ACTION(addRemoveResponse));

    if (action.payload.currentProduct) {
      yield put(
        ADD_ITEM_TO_MENU_LIST_ACTION({
          ...addRemoveResponse,
          descriptionName: item,
          productId:
            action.payload.currentProduct.uniqueID ||
            action.payload.currentProduct.id,
          isAlreadySaved: action.payload.currentProduct.isAlreadySaved,
          partNumber: action.payload.currentProduct.partNumber,
        })
      );
    }

    if (currentProduct) {
      const itemId = currentProduct.partNumber
        ? currentProduct.partNumber
        : currentProduct.uniqueID;

      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Item #${itemId} was added to ${item}`,
          messageId: alertMessageId(),
          severity: 'success',
        })
      );
    }

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.ADD_WISHLIST.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );

    console.error(e);
  }
}

export function* deleteRemoveWishListItem(action: any): Generator {
  try {
    const { descriptionName, productPartNumber, deleteListName } =
      action.payload;

    const deleteRemoveResponse: any = yield call(
      wishListService.deleteRemoveWishListItem,
      action.payload
    );

    const wishListResponse: any = yield call(
      wishListService.fetchWishList,
      action.payload
    );

    if (action.payload.wishListPlp) {
      yield put(REFRESH_WISH_LIST_PLP_ACTION());
    }

    yield put(DELETE_REMOVE_WISH_LIST_SUCCESS_ACTION(deleteRemoveResponse));

    yield put(GET_WISH_LIST_SUCCESS_ACTION(wishListResponse));

    if (!action.payload.wishListPlp) {
      yield put(
        ADD_ITEM_TO_MENU_LIST_ACTION({
          productId: action.payload.productId,
          isAlreadySaved: true,
          descriptionName: action.payload.descriptionName,
        })
      );
    }

    if (productPartNumber && descriptionName) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Item <em>#${productPartNumber}</em> was removed from <em>${descriptionName}</em>`,
          messageId: alertMessageId(),
          severity: 'success',
        })
      );
    } else {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Saved List <em>${deleteListName}</em> was deleted.`,
          messageId: alertMessageId(),
          severity: 'success',
        })
      );
    }
  } catch (e) {
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.DELETE_WISHLIST.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}

export function* fetchWishListProducts(action: any): Generator {
  try {
    const fetchWishListProductsResponse: any = yield call(
      productsService.fetchProductsByPartNumbers,
      action.payload
    );

    if (action.payload.wishListPage) {
      yield put(
        FETCH_WISH_LIST_PRODUCTS_PAGE_SUCCESS_ACTION({
          ...fetchWishListProductsResponse,
          currentItemId: action.payload.currentItemId,
        })
      );
    } else {
      yield put(
        FETCH_WISH_LIST_PRODUCTS_SUCCESS_ACTION({
          ...fetchWishListProductsResponse,
          currentItemId: action.payload.currentItemId,
        })
      );
    }
  } catch (e) {}
}

export function* shareWishList(action: any): Generator {
  try {
    yield call(wishListService.shareWishList, action.payload);

    const { announcement } = action.payload as IShareListRequest;

    const { emailRecipient } = announcement[0];

    const { recipientEmail } = emailRecipient[0];

    yield put(
      SHARE_WISH_LIST_SUCCESS_ACTION({ recipientEmail, isSuccess: true })
    );

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    const { announcement } = action.payload as IShareListRequest;

    const { emailRecipient } = announcement[0];

    const { recipientEmail } = emailRecipient[0];

    yield put(
      SHARE_WISH_LIST_SUCCESS_ACTION({ recipientEmail, isSuccess: false })
    );

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.SHARE_WISHLIST.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}

export function* fetchWishListPlp(action: any): Generator {
  try {
    const fetchWishListPlpResponse: any = yield call(
      wishListService.fetchWishListPlp,
      action.payload
    );

    if (fetchWishListPlpResponse) {
      yield put(
        FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION({
          ...fetchWishListPlpResponse,
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
        })
      );
    }
  } catch (e) {}
}

export function* addItemToWishList(action: any): Generator {
  try {
    const response: any = yield call(
      wishListService.addItemToWishList,
      action.payload
    );

    const { partNumber } = action.payload;

    if (response) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `#${partNumber} was saved for later.`,
          messageId: alertMessageId(),
          severity: 'success',
        })
      );

      yield put(GET_WISH_LIST_ACTION({ storeID: action.payload.storeID }));

      yield put(REFRESH_WISH_LIST_PLP_ACTION());
    }
  } catch (err) {
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.ADD_WISHLIST.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}

export function* fetchSharedWishlistDetails(action: any): Generator {
  try {
    const response: any = yield call(
      wishListService.fetchSharedListDetails,
      action.payload
    );

    yield put(
      FETCH_WISH_LIST_ITEMS_SUCCESS_ACTION({
        ...response,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      })
    );
  } catch (e) {
    const axiosError = e as AxiosError;

    if (isNetworkError(axiosError)) {
      yield put(
        TIER_1_ERROR_ACTION({
          api: axiosError.response?.config?.url,
          apiResponseHttpCode: axiosError.response?.status.toString(),
          apiResponsePayload: JSON.stringify(axiosError?.response?.data),
        })
      );
    }
  }
}
