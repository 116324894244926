import { createReducer } from '@reduxjs/toolkit';
import {
  ITaxInformationResponse,
  ITaxInformationState,
} from '../../_foundation/interface/TaxInformation/ITaxInformation';
import {
  GET_TAX_INFORMATION_SUCCESS_ACTION,
  RESET_TAX_INFORMATION_ACTION,
} from '../actions/tax-information.actions';

const initTaxInformationState: ITaxInformationState = {
  isTaxExempt: false,
  loading: true,
  taxInformation: [],
};

const taxInformationReducer = createReducer(
  initTaxInformationState,
  (builder) => {
    builder.addCase(
      GET_TAX_INFORMATION_SUCCESS_ACTION,
      (state: ITaxInformationState, action: any) => {
        const { taxExempt, taxInformation } =
          action.payload as ITaxInformationResponse;

        let isTaxExempt: boolean = false;

        if (taxExempt.toLowerCase() === 'yes') {
          isTaxExempt = true;
        }

        return {
          ...state,
          isTaxExempt,
          loading: false,
          taxInformation,
        };
      }
    );

    builder.addCase(RESET_TAX_INFORMATION_ACTION, () => {
      return { ...initTaxInformationState };
    });
  }
);

export { taxInformationReducer };
