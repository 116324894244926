import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useSeoState } from '../../utils/hooks/seo-state/seo-state';
import { IProductListContents } from '../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../_foundation/interface/Responses/ICartProductsResponse';
import useInViewPort from '../Widgets/ProductOffer/ViewPort/useInViewPort';
import { ProductPricingTable } from '../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricingTable/ProductPricingTable';
import { useCallStoreMessage } from './CallStoreMessage/CallStoreMessage';
import { FreeItem } from './FreeItem/FreeItem';
import { useProcessPricing } from './ProcessPricing/ProcessPricing';
import { ProductNotOnSalePrice } from './ProductNotOnSalePrice/ProductNotOnSalePrice';
import { ProductOnSalePrice } from './ProductOnSalePrice/ProductOnSalePrice';
import { ProductPricingSkeleton } from './ProductPricingSkeleton/ProductPricingSkeleton';
import { QuantityPricing } from './QuantityPricing/QuantityPricing';
import { SwatchablePrice } from './SwatchablePrice/SwatchablePrice';
import { useSelector } from 'react-redux';
import { orderSelector } from '../../redux/selectors/order.selector';
import { orderConfirmationSelector } from '../../redux/selectors/orderConfirmation.selector';
import { CartItemQuantityConstants } from '../Widgets/ShoppingCart/CartItems/CartItemQuantity/CartItemQuantityConstants';

interface IProductPricing {
  productCard:
  | ICartContentsItem
  | IProductListContents
  | IMerchandisingAssociations;
  setUnitPrice?: (unitPrice: number) => void;
  setBuyableWithNoPrice?: Dispatch<SetStateAction<boolean>>;
  showQtyPopper?: boolean;
  isCartCard?: boolean;
  isCompareProduct?: boolean;
  isRvi?: boolean;
  index?: number;
  isConfirmation?: boolean;
  requestedQuantity?: string;
  isWishlistCard?: boolean;
}

const ProductPricing: React.FC<IProductPricing> = ({
  productCard,
  setUnitPrice,
  showQtyPopper,
  isCartCard,
  isCompareProduct,
  isConfirmation,
  isRvi,
  index,
  requestedQuantity,
  isWishlistCard,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const { inViewport } = useInViewPort(scrollRef);

  const { productPrice, showSavings, quantity, extendedPriceLoading } =
    useProcessPricing({
      productCard,
      setUnitPrice,
      inViewport,
      isRvi,
      isCartCard,
    });

  const { orderItem, freeItems } = useSelector(orderSelector);
  const {
    orderItem: orderConfirmationItem,
    freeItems: orderConfirmationfreeItems,
  } = useSelector(orderConfirmationSelector);

  const { CallStoreMessage, isShowCallStoreMsg } = useCallStoreMessage({
    productCard,
  });

  const keys = !isConfirmation
    ? orderItem.at(index!)
    : orderConfirmationItem.at(index!);

  const isFreeItem = isCartCard
    ? freeItems[keys?.orderItemId!] !== undefined
      ? freeItems[keys?.orderItemId!]
      : isConfirmation
        ? orderConfirmationfreeItems[keys?.orderItemId!] !== undefined
          ? orderConfirmationfreeItems[keys?.orderItemId!]
          : false
        : false
    : false;

  const { isPdpPage } = useSeoState();

  const { QTY } = CartItemQuantityConstants;

  if (extendedPriceLoading) {
    return (
      <div id='scroll-ref' ref={scrollRef}>
        <ProductPricingSkeleton />
      </div>
    );
  }

  if (isShowCallStoreMsg) {
    return <CallStoreMessage />;
  }

  if (isFreeItem) {
    return <FreeItem productPrice={productPrice} />;
  }

  return (
    <div id='scroll-ref' ref={scrollRef}>
      {!extendedPriceLoading && productPrice && (
        <>
          {productPrice?.swatchable && (
            <SwatchablePrice
              productCard={productCard}
              showSavings={showSavings}
              productPrice={productPrice}
            />
          )}

          {productPrice?.isProductOnSale && (
            <ProductOnSalePrice
              productCard={productCard}
              quantity={quantity}
              showSavings={showSavings}
              productPrice={productPrice}
              setUnitPrice={setUnitPrice}
              showQtyPopper={showQtyPopper}
              isCartCard={isCartCard}
              isCompareProduct={isCompareProduct}
              isWishlistCard={isWishlistCard}
              requestedQuantity={requestedQuantity}
            />
          )}

          {!productPrice?.isProductOnSale && (
            <ProductNotOnSalePrice
              productCard={productCard}
              quantity={quantity}
              showSavings={showSavings}
              productPrice={productPrice}
              setUnitPrice={setUnitPrice}
              isWishlistCard={isWishlistCard}
              requestedQuantity={requestedQuantity}
            />
          )}

          {isPdpPage && !showQtyPopper && (
            <ProductPricingTable
              itemPrices={
                productPrice?.itemPrices ? productPrice?.itemPrices : []
              }
              itemQuantities={
                productPrice?.itemQuantities ? productPrice?.itemQuantities : []
              }
              isPDP={isPdpPage}
            />
          )}

          {showQtyPopper && isPdpPage && (
            <QuantityPricing
              productPrice={productPrice}
              showSavings={showSavings}
              showQtyPopper
            />
          )}
          {isWishlistCard && requestedQuantity && (
            <div>
              {QTY.label} : {+requestedQuantity}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { ProductPricing };
