import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { orderSelector } from '../../../../redux/selectors/order.selector';
import { ReactComponent as CART_GRAY } from '../../../../theme/assets/cart_gray.svg';
import { formatToDollars } from '../../../../utils/utils';
import { ScreenReaderOnly } from '../../ScreenReaderOnly/ScreenReaderOnly';
import { MiniCartHeaderSubSection } from '../MiniCartHeaderSubsection/MiniCartHeaderSubsection';
import { MiniCartHeaderConstants } from './MiniCartHeaderConstants';

/**
 * @interface MiniCartHeaderProps
 */
interface MiniCartHeaderProps {
  /**
   * @prop cartHeaderCloseHandler
   * Handles drawer close events when the cart
   * is open.
   */
  cartHeaderCloseHandler?: () => void;

  /**
   * @prop id for Mini Cart Header.
   */
  id: string;
}

/**
 * @component MiniCartHeader renders
 * the cart header component.
 */
const MiniCartHeader: React.FC<MiniCartHeaderProps> = ({
  cartHeaderCloseHandler,
  id,
}) => {
  const { CART, PRODUCT_SUB_TOTAL } = MiniCartHeaderConstants;

  const { t } = useTranslation();

  const { noOfCartItems, totalProductPrice, totalAdjustment } = useSelector(orderSelector);

  /**
   * cartItems -- responsible to store the number of items present in the cart
   */
  const cartItems = noOfCartItems ? noOfCartItems : '0';
  
  const totalGCFreeAdjustment = totalAdjustment ? totalAdjustment : '0';

  const totalPriceAfterAdjustment = Number(totalProductPrice)- Number(totalGCFreeAdjustment);

  return (
    <div className='mini-cart-header-wrapper'>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <IconButton
            aria-label='close'
            onClick={cartHeaderCloseHandler}
            className='close_icon'>
            <CloseOutlinedIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={3}>
          <Box padding={2}>
            <Badge
              classes={{ badge: 'cart_icon_badge' }}
              badgeContent={cartItems}
              color='primary'>
              <CART_GRAY role='img' aria-label='cart icon' />
              <ScreenReaderOnly message={`${cartItems} items in cart`} />
            </Badge>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Typography id={id} variant='h6'>
                <Box fontWeight='fontWeightBold'>{t(CART)}</Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography aria-label='' variant='body1'>
                {t(PRODUCT_SUB_TOTAL)} {formatToDollars(Number(totalPriceAfterAdjustment))}
                <ScreenReaderOnly
                  message={`${t(PRODUCT_SUB_TOTAL)} ${formatToDollars(
                    Number(totalPriceAfterAdjustment)
                  )}`}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MiniCartHeaderSubSection
        cartHeaderCloseHandler={cartHeaderCloseHandler}
      />

      <Divider />
    </div>
  );
};

export { MiniCartHeader };
