import { AxiosRequestConfig } from 'axios';
import { makeRequest } from '../axios/axiosConfig';
import { AuthenticationServiceConstants } from './authentication.service.constants';

export interface IGuestParam {
  storeID: string;
}

export interface IGuestUserResponse {
  personalizationID: string;
  resourceName: string;
  WCToken: string;
  userId: string;
  WCTrustedToken: string;
}

const GuestService = {
  /**
   * @method
   * Responsible to fetch the cookie for guest user
   */
  async fetchGuestUser({ storeID }: IGuestParam): Promise<IGuestUserResponse> {
    const { GUEST_IDENTITY_URL } = AuthenticationServiceConstants;

    try {
      const guestIdentityRequest: AxiosRequestConfig = {
        url: GUEST_IDENTITY_URL(storeID),
        method: 'POST',
      };

      const guestIdentityResponse: IGuestUserResponse = await makeRequest(
        guestIdentityRequest
      );

      return guestIdentityResponse;
    } catch (err) {
      throw err;
    }
  },
};

export { GuestService };
