import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../../redux/interface';
import {
  INIT_MENU_STATE,
  RESET_MENU_STATE,
  TOGGLE_NEXT_MENU,
} from '../redux/action/menu-list.action';

interface NteNestedMenuProps {
  showPopper: boolean;
  initMenuData: any;
}

/**
 * @hooks useNteNestedMenu acts as a wrapper for performing
 * redux dispatch operations in case of utilising the NteMenuList
 * component as a Nested menu.
 */
const useNteNestedMenu = ({
  showPopper = false,
  initMenuData,
}: NteNestedMenuProps) => {
  const dispatch = useDispatch();

  const menuState = useSelector((state: Store) => state.menuListReducer);

  /**
   * This method is responsible for handling the click events of
   * the nested Menu list items.
   * @param selectedEvent
   */
  const clickEventsHandler = (selectedEvent?: any): void => {
    if (selectedEvent.isNestedMenu) {
      /**
       * If the current menu Item is nested
       * dispatch toggle next to load the next set
       * of menu items.
       */
      dispatch({
        type: TOGGLE_NEXT_MENU,
        payload: { currentItem: selectedEvent.currentItem },
      });
    } else if (selectedEvent?.backAction) {
      /**
       * If the current item is back dispatch
       * backAction to load the previous set of
       * menu items.
       */
      dispatch({
        type: selectedEvent?.backAction,
      });
    }
  };

  /**
   * This method is responsible for both
   * initalizing menu items and reseting the
   * current list of menu items when the popper
   * closes.
   */
  const initMenuItems = useCallback(() => {
    if (showPopper) {
      /**
       * When the popper opens initialize the
       * menu items.
       */
      dispatch({
        type: INIT_MENU_STATE,
        payload: { initMenuData },
      });
    } else {
      /**
       * When the popper closes reset the current
       * menu items.
       */
      dispatch({
        type: RESET_MENU_STATE,
      });
    }
  }, [dispatch, initMenuData, showPopper]);

  useEffect(() => {
    /**
     * Invoke this method based on
     * showPopper flag state changes.
     */
    initMenuItems();
  }, [showPopper, initMenuItems]);

  return { menuState, clickEventsHandler };
};

export { useNteNestedMenu };
