import { AxiosRequestConfig } from 'axios';
import { trackUserEvent } from '../../../utils/lucky-orange';
import {
  IAssignStoreParams,
  IAssignStorePayload,
} from '../../interface/StorePickup/IStorePickup';
import { makeRequest } from '../axios/axiosConfig';
import { StorePickupServiceConstants } from './storePickup.service.constants';

class StorePickupService {
  /**
   * @method assignStore makes a post request to assign the selected pickup store
   * @param IAssignStorePayload
   */
  public async assignStore({
    storeLocatorId,
    orderID,
    storeID,
    x_estimatedDelivery,
    history,
    isCheckout,
  }: IAssignStoreParams) {
    const { ASSIGN_STORE_URL } = StorePickupServiceConstants;

    try {
      const assignStorePayload: IAssignStorePayload = {
        storeLocatorId,
        orderId: orderID,
        estimatedDelivery: x_estimatedDelivery,
      };

      const assignStoreRequest: AxiosRequestConfig = {
        url: ASSIGN_STORE_URL(storeID),
        method: 'POST',
        data: assignStorePayload,
      };

      const assignStoreResponse = await makeRequest(
        assignStoreRequest,
        undefined,
        history,
        isCheckout
      );

      return assignStoreResponse;
    } catch (error) {
      trackUserEvent({
        eventMsg: error,
        eventName: 'deliveryPage-assignStorePost',
      });

      throw error;
    }
  }
}

export { StorePickupService };
