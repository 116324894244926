import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import React from 'react';
import { formateProductSpecAttributeValue } from '../../../../utils/utils';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';

/**
 * @interface IProductCompareTableProps
 */
interface IProductCompareTableProps {
  compareProducts: IProductListContents[];
  isChecked: boolean;
}

/**
 * @component ProductCompareTable renders the product compare table.
 *
 * @param IProductCompareTableProps
 */

const ProductCompareTable: React.FC<IProductCompareTableProps> = ({
  compareProducts,
  isChecked,
}) => {
  const _getMatchingValue = (values: any) => {
    let matches = [];
    for (let value of values) {
      matches.push(value.value);
    }
    return matches.length ? matches.join(', ') : '-';
  };

  const processData = () => {
    let attributes: any = {};

    let length = compareProducts.length;

    for (let i = 0; i < length; i++) {
      if (compareProducts[i].attributes) {
        for (let attribute of compareProducts[i].attributes) {
          if (attribute.comparable === 'true') {
            if (!Array.isArray(attributes[attribute.name])) {
              attributes[attribute.name] = new Array(length).fill('-');
            }
            attributes[attribute.name][i] = _getMatchingValue(attribute.values);
          }
        }
      }
    }
    return attributes;
  };

  const rowVal = processData();

  return (
    <div className='nte-border-card product_compare_table' role='table'>
      <span className='sr-only'>Specifications</span>
      <div className='table-header' role='row'>
        {compareProducts.map((product: IProductListContents, idx: number) => (
          <div className='table-cell' role='columnheader' key={idx}>
            <span className='cell-content'>{decode(product.name)}</span>
          </div>
        ))}
      </div>
      <div className='table-body'>
        {rowVal &&
          Object.keys(rowVal).map((rowData: any, index: number) => (
            <div key={index}>
              <div
                key={index}
                className='table-row background-color-c8'
                role='rowheader'>
                <span className='product-compare_table-cell'>
                  {decode(rowData)}
                </span>
              </div>
              <div
                role='cell'
                className={`table-row ${
                  isChecked &&
                  !rowVal[rowData].every(
                    (data: any) => data === rowVal[rowData][0]
                  )
                    ? 'highlight'
                    : ''
                }`}>
                <span className='sr-only'>
                  {isChecked ? 'Different' : 'Same'}
                </span>
                {rowVal[rowData].map((data: any, idx: any) => (
                  <span className='product-compare_table-cell' key={idx}>
                    <Typography
                      variant='body1'
                      className='product-comapre_table-cell-data'>
                      {formateProductSpecAttributeValue(decode(data))}
                    </Typography>
                  </span>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export { ProductCompareTable };
