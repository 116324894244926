const RVI_CARD_KEY = 'RVI.RVI_CARD';

const RviCardConstants = {
  REMOVE: `${RVI_CARD_KEY}.REMOVE`,
  ITEM_NO: `${RVI_CARD_KEY}.ITEM_NO`,
  ADD_TO_CART: `${RVI_CARD_KEY}.ADD_TO_CART`,
  SEE_ALL: `${RVI_CARD_KEY}.SEE_ALL`,
  SEE_OPTIONS: `${RVI_CARD_KEY}.SEE_OPTIONS`,
  SEE_DETAILS: `${RVI_CARD_KEY}.SEE_DETAILS`,
  PRODUCT: 'product',
};

export { RviCardConstants };
