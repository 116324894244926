const TRANSLATION_KEY = 'CHECKOUT.PROMOCODE';

export const PromoCodeConstants = {
  PROMOCODE: {
    TITLE: `${TRANSLATION_KEY}.TITLE`,
    LABEL: `${TRANSLATION_KEY}.LABEL`,
    BUTTON_LABEL: `${TRANSLATION_KEY}.BUTTON_LABEL`,
    SEE_DETAILS: `${TRANSLATION_KEY}.SEE_DETAILS`,
    REMOVE: `${TRANSLATION_KEY}.REMOVE`,
    PROMO_CODE_DETAILS: `${TRANSLATION_KEY}.PROMO_CODE_DETAILS`,
    PROMO_CODE_COMBINED: `This promo code cannot be combined with any other promotions.`,
    CANCEL: `${TRANSLATION_KEY}.CANCEL`,
    CONTINUE: `${TRANSLATION_KEY}.CONTINUE`,
    ERROR: `${TRANSLATION_KEY}.ERROR_MESSAGE`,
  },
};
