import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { SearchLayoutConstants } from '../../../components/Layouts/Search/SearchLayout.constants';
import {
  MY_ORDERS,
  SEARCH_PRODUCT,
  TRACK_ORDER,
} from '../../../constants/routes';
import {
  RESET_PRODUCT_LIST_ACTION,
  RESET_SEARCH_DISPLAY_ACTION,
} from '../../../redux/actions/productList.actions';
import {
  RESET_SEARCH_KEYWORD_ACTION,
  UPDATE_SEARCH_KEYWORD_ACTION,
} from '../../../redux/actions/search.actions';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { searchDisplaySelector } from '../../../redux/selectors/productList.selector';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { SearchProductsContentConstant } from './SearchProductsContent.constant';

/**
 * @hook
 * Responsible for making call to Search Product API
 */
const useSearchProductSiteContent = () => {
  const { SearchDisplayViewTaskView } = SearchProductsContentConstant;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const { mySite } = useSite();

  const dispatch = useDispatch();

  const { viewTaskName, redirecturl, storeId, searchTerm, pdpRedirectUrl } =
    useSelector(searchDisplaySelector);

  const history = useHistory();

  const [searchQueryParam] = useQueryParam(SEARCH_QUERY_PARAM, StringParam);

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  /**
   * @method searchSiteContent
   * Responsible to find the search term belongs to redirection or search plp
   */
  const searchSiteContent = useCallback((): void => {
    if (searchQueryParam === searchTerm) {
      return;
    }

    dispatch(RESET_SEARCH_KEYWORD_ACTION());

    /**
     * Search redirection
     */
    if (viewTaskName && redirecturl) {
      if (redirecturl.length > 0) {
        if (redirecturl.includes(TRACK_ORDER) && isUserAuthenticated) {
          history.push(MY_ORDERS);
          return;
        }

        const finalUrl = redirecturl.endsWith('?*=')
          ? redirecturl.replace('?*=', '')
          : redirecturl;

        history.push(finalUrl);
      }
    }

    /**
     * Serach PLP
     */
    if (
      searchTerm &&
      mySite &&
      storeId &&
      viewTaskName === SearchDisplayViewTaskView &&
      !redirecturl
    ) {
      if (storeId.length > 0) {
        const searchRequest = {
          term: searchTerm,
          storeID: mySite?.storeID,
        };

        if (searchQueryParam !== searchRequest.term || !searchQueryParam) {
          dispatch(RESET_PRODUCT_LIST_ACTION({}));
        }

        dispatch(UPDATE_SEARCH_KEYWORD_ACTION(searchRequest.term));

        history.push(SEARCH_PRODUCT);

        dispatch(RESET_SEARCH_DISPLAY_ACTION());
      }
    }
  }, [
    viewTaskName,
    redirecturl,
    searchTerm,
    mySite,
    storeId,
    SearchDisplayViewTaskView,
    isUserAuthenticated,
    history,
    dispatch,
    searchQueryParam,
  ]);

  useEffect(() => {
    searchSiteContent();
  }, [searchSiteContent]);

  const redirectToPdp = useCallback((): void => {
    if (pdpRedirectUrl) {
      history.push(pdpRedirectUrl);
    }
  }, [history, pdpRedirectUrl]);

  useEffect(() => {
    redirectToPdp();
  }, [redirectToPdp]);
};

export { useSearchProductSiteContent };
