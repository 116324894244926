import {
  CHECKOUT_DELIVERY,
  CHECKOUT_PAYMENT,
  CHECKOUT_REVIEW,
  SHOPPING_CART,
} from '../../../../constants/routes';

const STEPPER_KEY = 'CHECKOUT.HEADER.STEPPER';

/**
 * @interface ICheckoutSteps
 */
export interface ICheckoutSteps {
  label: string;
  route: string;
  disabled: boolean;
}

const CheckoutStepperConstants = {
  steps: [
    { label: `${STEPPER_KEY}.STEP_1`, route: SHOPPING_CART, disabled: false },
    {
      label: `${STEPPER_KEY}.STEP_2`,
      route: CHECKOUT_DELIVERY,
      disabled: false,
    },
    {
      label: `${STEPPER_KEY}.STEP_3`,
      route: CHECKOUT_PAYMENT,
      disabled: true,
    },
    { label: `${STEPPER_KEY}.STEP_4`, route: CHECKOUT_REVIEW, disabled: true },
  ],
};

export default CheckoutStepperConstants;
