import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NteDrawer } from '../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { ActiveFacet } from '../ActiveFacet/ActiveFacet';
import { Facet } from '../Facet/Facet';
import { useFacets } from '../Facet/hooks/FacetsHooks';
import { FacetMobileConstants } from './FacetMobileConstants';

/**
 * @interface IFacetMobile
 */
interface IFacetMobile {
  /**
   * @props translationKey
   */
  translationKey?: string;
}

/**
 * @component FacetMobile renders the facet component for the mobile screen.
 */
const FacetMobile: React.FC<IFacetMobile> = ({ translationKey = 'Filter' }) => {
  const { ACTIVE_FILTERS, FILTER } = FacetMobileConstants;

  const [showFilter, setShowFilter] = useState(false);

  const { facetValues } = useFacets();

  const { t } = useTranslation();

  const filterDrawerHandler = (): void => {
    setShowFilter((showFilter) => !showFilter);
  };

  return (
    <>
      <NteButton
        variant={translationKey === FILTER ? 'outlined' : 'text'}
        className='mobile-filters-button'
        translationKey={translationKey}
        onClick={filterDrawerHandler}
        type='button'
      />

      <NteDrawer
        anchor='left'
        id='mobile-filter'
        ariaLabelledById='facets-heading'
        nteDrawerCloseHandler={filterDrawerHandler}
        showNteDrawer={showFilter}>
        <div className='nte_drawer-paper nte_popper-paper facet_mobile'>
          <div className='header-region'>
            <Grid container item justifyContent='flex-end'>
              <CloseIcon
                className='cursor-pointer'
                onClick={filterDrawerHandler}
              />
            </Grid>
          </div>
          <div className='scrollable-region'>
            <ActiveFacet title={t(ACTIVE_FILTERS)} />

            <Facet defaultExpanded={false} facetValues={facetValues} />

            <NteButton
              variant='outlined'
              className='view-results'
              translationKey='View Results'
              onClick={filterDrawerHandler}
              type='button'
            />
          </div>
        </div>
      </NteDrawer>
    </>
  );
};

export { FacetMobile };
