export const INIT_AUTHENTICATION_KEY = 'INIT_AUTHENTICATION_KEY';

export const INIT_AUTHENTICATION_KEY_SUCCESS =
  'INIT_AUTHENTICATION_KEY_SUCCESS';

export const RESET_AUTHENTICATION_KEY = 'RESET_AUTHENTICATION_KEY';

export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';

export const TOKENIZE_CREDIT_DEBIT_CARD = 'TOKENIZE_CREDIT_DEBIT_CARD';

export const TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS =
  'TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS';

export const TOKENIZE_CREDIT_DEBIT_CARD_FAILURE =
  'TOKENIZE_CREDIT_DEBIT_CARD_FAILURE';
