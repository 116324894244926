import { List } from '@material-ui/core';
import React from 'react';
import { MenuListItem } from '../MenuListItem/MenuListItem';

/**
 * @interface IMenuListBody
 */
interface IMenuListBody {
  /**
   * @prop menuItems Menu items to be diplayed in the body.
   */
  menuItems: any[];

  /**
   * @prop children
   * Custom menu items can be rendered
   * by passing them as a children.
   */
  children?: JSX.Element | JSX.Element[] | string;

  /**
   * @prop clickHandler handles the click events for the menu
   * list items rendered inside the menu list body.
   */
  clickHandler?: (selectedEvent?: any) => void;

  /**
   * @prop icon to be rendered in the list item.
   */
  icon?: JSX.Element;

  /**
   * @prop current item to be compared to current menu list item.
   */
  currentItem?: string | string[];
}

/**
 * @component MenuListBody renders the menu list body.
 *
 * @param IMenuListBody
 */
const MenuListBody: React.FC<IMenuListBody> = ({
  menuItems,
  children,
  clickHandler,
  currentItem,
  icon,
}) =>
  !children ? (
    <List role='list' component='ul' className='menu_list_body'>
      {menuItems.map((menuItem, index: number) => (
        <MenuListItem
          key={index}
          clickHandler={clickHandler}
          currentItemIndex={index}
          menuItem={menuItem}
          isNestedMenu={menuItem.isNested}
          children={children}
          currentItem={currentItem}
          icon={icon}
        />
      ))}
    </List>
  ) : (
    <>{children}</>
  );

export { MenuListBody };
