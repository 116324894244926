import { AnyAction, createReducer, current } from '@reduxjs/toolkit';
import { stripSeeAllItemCount } from '../../../../../utils/utils';
import {
  INIT_MENU_STATE,
  MENU_NAVIGATE_BACK,
  RESET_MENU_STATE,
  TOGGLE_NEXT_MENU,
} from '../action/menu-list.action';

/**
 * @interface NteMenuListState
 */
export interface NteMenuListState {
  /**
   * @prop active Currently active menu.
   */
  active: string;

  /**
   * @prop items Currently active menu items
   */
  items?: any[];

  /**
   * @prop isRootMenu flag which determines is currently
   * active menu is root menu or not
   */
  isRootMenu?: boolean;

  /**
   * @prop prevItem List of previous fetched items.
   */
  prevItem?: any[];

  /**
   * @prop currentIndex Currently selected menu index to
   * grab the value during on click.
   */
  currentIndex?: number;

  /**
   * @prop childrenElement
   * Renders JSX elements instead of list of values.
   */
  childrenElement?: JSX.Element | JSX.Element[] | string;
}

const initialMenuState: NteMenuListState = {
  active: '',
  currentIndex: -1,
  isRootMenu: false,
  items: [],
  prevItem: [],
};

/**
 * @reducer menuListReducer is responsible for maintaining the nested menu states.
 */
const menuListReducer = createReducer(initialMenuState, (builder) => {
  /**
   * This case is responsible for handling the logic
   * when user clicks on nested menu item
   */
  builder.addCase(TOGGLE_NEXT_MENU, (state, action: AnyAction) => {
    const currentState = current(state);

    // Filter the current menu item's children.
    const currentItemChildren = currentState.items?.filter(
      (value: { item: string; isNested: boolean }) =>
        value.item === action.payload.currentItem
    );

    let prevStateItems: any[] = [];

    let newStateItems: any[] = [];

    let childrenElement;

    if (currentItemChildren && !currentItemChildren[0].children) {
      childrenElement = currentItemChildren[0].childrenElement;
    } else if (currentItemChildren) {
      const newItems = currentItemChildren[0].children;

      const newChildren: {
        children?: any[];
        item: string;
        isNested: boolean;
        seo: { href?: string };
        isTel?: boolean;
      }[] = [];

      // Loop through new items to construct the new Item children.
      newItems?.forEach((storeMenu: any) => {
        // Initalize the isNested flag.
        let isNested: boolean = false;

        if (storeMenu.children) {
          // If the current item has children set isNested as true.
          isNested = storeMenu.children?.length !== 0;
        }

        // Construct a new Item to be rendered.
        const newItem = {
          item: storeMenu.name,
          href: storeMenu.href,
          identifier: storeMenu.identifier,
          isNested,
          ...{ ...(isNested && { children: storeMenu?.children }) },
          seo: { href: storeMenu.seo?.href },
          isTel: storeMenu.isTel,
        };

        // Push new item into newChildren.
        newChildren.push(newItem);
      });

      newStateItems = [...newChildren];
    }

    if (currentState.prevItem) {
      // Push prev items.
      prevStateItems.push(...currentState.prevItem);
    }

    if (currentState?.items) {
      // Push current items as prev items.
      prevStateItems.push([...currentState.items]);
    }

    // Set new state and return it.
    return {
      ...currentState,
      active: stripSeeAllItemCount(action.payload.currentItem),
      items: newStateItems,
      childrenElement,
      prevItem: prevStateItems,
      isRootMenu: true,
    };
  });

  /**
   * This case is responsible for handling the logic for the
   * menu items initialization.
   */
  builder.addCase(INIT_MENU_STATE, (state, action: AnyAction) => {
    const intiMenuItems: {
      item: string;
      isNested: boolean;
      identifier: string;
      childrenElement: any;
      seo: { href?: string };
    }[] = [];

    // Retrieve the menu data from the payload.
    const initMenuData = action.payload.initMenuData;

    // Iterate through the response data from payload.
    initMenuData.forEach((storeMenu: any) => {
      let isNested: boolean = false;

      if (storeMenu.children) {
        // If the current item has children set isNested as true.
        isNested = storeMenu.children?.length !== 0;
      }

      // Construct a new Item to be rendered.
      const menuItem = {
        item: storeMenu.name,
        identifier: storeMenu.identifier,
        isNested,
        childrenElement: storeMenu.childrenElement,
        ...{ ...(isNested && { children: storeMenu?.children }) },
        seo: { href: storeMenu.seo?.href },
      };

      // Push new item into intiMenuItems.
      intiMenuItems.push(menuItem);
    });

    // Set new state and return it.
    return {
      active: '',
      items: intiMenuItems,
      isRootMenu: false,
      prevItem: [],
    };
  });

  /**
   * This case is responsible for handling the logic for
   * back navigation of the menu states.
   */
  builder.addCase(MENU_NAVIGATE_BACK, (state) => {
    const currentState = current(state);

    let newItemState: any[] = [];

    let newPrevState: any[] = [];

    let isRootMenu: boolean = true;

    if (currentState.prevItem) {
      // Set the current prevItem as prevState
      const prevState = [...currentState.prevItem];

      // Pop the prevState item into newItemState.
      newItemState = prevState.pop();

      // Assign current prevState items to newPrevState.
      newPrevState = [...prevState];

      // Assign isRootMenu bool based on newPrevState length.
      isRootMenu = newPrevState.length !== 0;
    }

    // Set new state and return it.
    return {
      ...currentState,
      items: newItemState,
      prevItem: newPrevState,
      isRootMenu,
      childrenElement: '',
      active: '',
    };
  });

  /**
   * This case is responsible handling the logic for
   * reseting the menu state once the menu closes.
   */
  builder.addCase(RESET_MENU_STATE, () => {
    // Set new state and return it.
    return {
      ...initialMenuState,
    };
  });
});

export { menuListReducer };
