import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { backdropSelector } from '../../redux/selectors/backdrop';

/**
 * @component NteBackdrop renders backdrop whenever a portal component is active.
 */
const NteBackdrop: React.FC = () => {
  const { showBackdrop } = useSelector(backdropSelector);

  const backdropActive = showBackdrop ? 'backdrop-active' : '';

  useEffect(() => {
    if (showBackdrop) {
      document.body.classList.add('backdrop-active');
    } else {
      document.body.classList.remove('backdrop-active');
    }
  }, [showBackdrop]);

  return <div className={`backdrop ${backdropActive}`}></div>;
};

export { NteBackdrop };
