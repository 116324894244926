import React from 'react';
import { useSelector } from 'react-redux';
import { INteAlertMessage } from '../../_foundation/interface/NteAlert/INteAlert';
import { NteAlert } from './NteAlert';
import { nteAlertSelector } from './selector/nte-alert.selector';

/**
 * @component RenderNteAlert renders the list of Nte Alert component from the currentMessages queue.
 */
const RenderNteAlert: React.FC = () => {
  const { currentMessages } = useSelector(nteAlertSelector);

  return (
    <>
      {currentMessages.map((alertMessage: INteAlertMessage, index: number) => (
        <NteAlert
          autoClose={4000}
          currentMessageIndex={alertMessage.messageId}
          key={index}
          messages={[alertMessage.message]}
          severity={alertMessage.severity}
        />
      ))}
    </>
  );
};

export { RenderNteAlert };
