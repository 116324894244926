import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import {
  RESEND_VERIFICATION_CODE_BUTTON,
  RESET_PASSWORD_BUTTON_SUCCESS,
  SET_PASSWORD_BUTTON,
} from '../../../constants/ButtonType';
import i18n from '../../../i18n';
import { alertMessageId } from '../../../utils/utils';
import { AuthenticationService } from '../../../_foundation/apis/auth/authentication.service';
import { LOGIN_REQUESTED_ACTION } from '../../actions/auth.actions';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import {
  RESET_PASSWORD_SUCCESS_ACTION,
  VERIFICATION_CODE_ERROR_ACTION,
  VERIFICATION_CODE_RECEIVED_ACTION,
} from '../../actions/forgot-reset-password.actions';

const translationKey = 'RESET_PASSWORD.MESSAGE.SUCCESS';

export function* requestVerificationCode(action: any): Generator {
  try {
    const response = yield call(
      new AuthenticationService().getVerificationCode,
      action.payload
    );

    yield put(VERIFICATION_CODE_RECEIVED_ACTION(response));

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: false,
        buttonType: RESEND_VERIFICATION_CODE_BUTTON,
      })
    );

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.RECEIVED_REQUEST_EMAIL`),
        messageId: alertMessageId(),
        severity: 'success',
      })
    );
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: false,
        buttonType: RESEND_VERIFICATION_CODE_BUTTON,
      })
    );

    yield put(VERIFICATION_CODE_ERROR_ACTION(response?.data));
  }
}

export function* resetPassword(action: any): Generator {
  try {
    const response = yield call(
      new AuthenticationService().resetPassword,
      action.payload
    );

    const { logonId, logonPassword, storeID } = action.payload;

    yield put(RESET_PASSWORD_SUCCESS_ACTION(response));

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.RESET_PASSWORD_SUCCESS`),
        messageId: alertMessageId(),
        severity: 'success',
      })
    );

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: false,
        buttonType: RESET_PASSWORD_BUTTON_SUCCESS,
      })
    );

    yield put(
      LOGIN_REQUESTED_ACTION({
        logonId,
        logonPassword,
        storeID,
      })
    );
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    yield put(
      SET_BUTTON_LOADING_ACTION({
        loading: false,
        buttonType: SET_PASSWORD_BUTTON,
      })
    );

    yield put(VERIFICATION_CODE_ERROR_ACTION(response?.data));
  }
}
