import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SupportMenuConstants } from '../SupportMenuConstants';

/**
 * @component SupportTextItem is renders the
 * texting number under the support menu.
 */
const SupportTextItem: React.FC = () => {
  const { TEXT, SMS_NUMBER, TEXT_FALLBACK } = SupportMenuConstants;

  const { t, i18n } = useTranslation();

  return (
    <>
      <Typography variant='body2' className='no-link-effect support_text_item'>
        {i18n.exists(TEXT).valueOf() ? t(TEXT) : TEXT_FALLBACK}
        &nbsp;
        <span tabIndex={0} role='link' className='number-style link-effect'>
          {SMS_NUMBER}
        </span>
      </Typography>
    </>
  );
};

export { SupportTextItem };
