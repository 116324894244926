import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * @interface IRobotsMetaTagsNoFollow
 */
interface IRobotsMetaTagsNoFollow {
  addPrerenderTag?: boolean;
}

/**
 * @component RobotsMetaTagsNoFollow adds the robots meta tags with noindex and nofollow.
 */
const RobotsMetaTagsNoFollow: React.FC<IRobotsMetaTagsNoFollow> = ({
  addPrerenderTag = false,
}) => (
  <Helmet>
    <meta name='ROBOTS' content='NOINDEX, NOFOLLOW' />

    {addPrerenderTag && (
      <meta name='prerender-status-code' content='404'></meta>
    )}
  </Helmet>
);

export { RobotsMetaTagsNoFollow };
