import { createAction } from '@reduxjs/toolkit';
import {
  IAddItemAction,
  IGetItemAction,
} from '../../../../_foundation/interface/SaveToList/ISaveToList';
import * as ACTION_TYPES from '../action-types/save-to-list.action-types';

const GET_ITEM_FROM_MENU_LIST_ACTION = createAction<IGetItemAction>(
  ACTION_TYPES.GET_ITEM_FROM_MENU_LIST
);

const ADD_ITEM_TO_MENU_LIST_ACTION = createAction<IAddItemAction>(
  ACTION_TYPES.ADD_ITEM_TO_MENU_LIST
);

const RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION = createAction(
  ACTION_TYPES.RESET_SAVE_TO_LIST_MENU_ITEMS
);

export {
  GET_ITEM_FROM_MENU_LIST_ACTION,
  ADD_ITEM_TO_MENU_LIST_ACTION,
  RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION,
};
