import React, { useEffect, useRef, useState, useCallback } from 'react';
import { findDOMNode } from 'react-dom';

import { defaultOptions, defaultConfig, defaultProps } from './constants';

import type { ViewPortConfig, ViewPortProps, ViewPortOptions } from './types';

const useInViewPort = (
  target: React.RefObject<HTMLDivElement>,
  options: ViewPortOptions = defaultOptions,
  config: ViewPortConfig = defaultConfig,
  props: ViewPortProps = defaultProps
) => {
  const { onEnterViewport, onLeaveViewport } = props;

  const [, forceUpdate] = useState(false);

  const observer: React.MutableRefObject<
    IntersectionObserver | undefined | null
  > = useRef<IntersectionObserver>();

  const inViewportRef = useRef<boolean>(false);

  const intersected = useRef<boolean>(false);

  const enterCountRef = useRef<number>(0);

  const leaveCountRef = useRef<number>(0);

  const [node, setNode] = useState<HTMLDivElement>();

  useEffect(() => {
    if (target?.current) {
      setNode(findDOMNode(target.current) as HTMLDivElement);
    }
  }, [target]);

  /**
   * @callback getNode sets the node;
   */
  const getNode = useCallback(
    (myNode) => {
      if (!target && myNode !== null) {
        setNode(myNode);
      }
    },
    [target]
  );

  /**
   * @callback startObserver observe the node in viewport;
   */
  const startObserver = useCallback(() => {
    if (node && observer.current) {
      observer.current.observe(node);
    }
  }, [observer, node]);

  /**
   * @callback stopObserver unobserve the node not in viewport;
   */
  const stopObserver = useCallback(() => {
    if (node && observer.current) {
      observer.current.unobserve(node);

      observer.current.disconnect();

      observer.current = null;
    }
  }, [observer, node]);

  /**
   * @callback handleIntersection handles the intersection of a node in viewport and not in viewport
   */
  const handleIntersection = useCallback(
    (entries: any) => {
      const entry = entries[0] || {};

      const { isIntersecting, intersectionRatio } = entry;

      const isInViewport =
        typeof isIntersecting !== 'undefined'
          ? isIntersecting
          : intersectionRatio > 0;

      // When node enters the viewport
      if (!intersected.current && isInViewport) {
        intersected.current = true;

        onEnterViewport?.();

        enterCountRef.current += 1;

        inViewportRef.current = isInViewport;

        forceUpdate(isInViewport);
        return;
      }

      // When node leaves the viewport
      if (intersected.current && !isInViewport) {
        intersected.current = false;

        onLeaveViewport?.();

        if (config.disconnectOnLeave && observer.current) {
          // disconnect obsever on leave
          observer.current.disconnect();
        }
        leaveCountRef.current += 1;

        inViewportRef.current = isInViewport;
        forceUpdate(isInViewport);
      }
    },
    [config.disconnectOnLeave, onEnterViewport, onLeaveViewport]
  );

  /**
   * @callback initIntersectionObserver initial observation.
   */
  const initIntersectionObserver = useCallback(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(handleIntersection, options);
    }
  }, [handleIntersection, options]);

  useEffect(() => {
    initIntersectionObserver();
    startObserver();
    return () => {
      stopObserver();
    };
  }, [initIntersectionObserver, startObserver, stopObserver]);

  return {
    inViewport: inViewportRef.current,
    enterCount: enterCountRef.current,
    leaveCount: leaveCountRef.current,
    getNode,
  };
};

export default useInViewPort;
