import { useState } from 'react';

/**
 * @method useSearchBar is used to set current value and
 * handles the onChange events of the search field.
 *
 * @param value Current value in the search field.
 * @returns
 * @param currentValue of the search field.
 * @param searchBarOnChangeHandler change handler for the search field.
 */
const useSearchBar = (value: string) => {
  const [currentValue, setCurrentValue] = useState(value);

  const searchBarOnChangeHandler = (event: any): void => {
    setCurrentValue(event.target.value);
  };

  return { currentValue, searchBarOnChangeHandler, setCurrentValue };
};

export { useSearchBar };
