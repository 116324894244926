import { Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

interface INteCollapse {
  /**
   * Ttile of the collapse
   */
  collapseTitle?: string;

  /**
   * children should be displayed after the collapse is expanded
   */
  children: JSX.Element;

  /**
   * Responsible to set the collapse initial state
   */
  showCollapse: boolean;

  /**
   * className prop
   */
  className?: string;
}

/**
 * @component
 * Responsible to render the Collapse Component
 */
const NteCollapse: React.FC<INteCollapse> = ({
  collapseTitle,
  children,
  showCollapse,
  className,
}) => {
  const [expanded, setExpanded] = React.useState(showCollapse);

  /**
   * @method
   * Responsible for handling the expandable state
   */
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <IconButton
        className={`nte_collapse ${className} ${expanded ? 'expanded' : ''}`}
        onClick={handleExpandClick}
        size='small'
        aria-expanded={expanded}>
        {collapseTitle && (
          <Typography variant='body1'>{collapseTitle}</Typography>
        )}
        <ExpandMore />
      </IconButton>
      <Collapse
        in={expanded}
        timeout='auto'
        unmountOnExit
        className={className}>
        <CardContent>{children}</CardContent>
      </Collapse>
    </>
  );
};

export { NteCollapse };
