import { decode } from 'html-entities';
import { useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SITE_SEARCH_CLICK_AFTER } from '../../../../constants/Tealium';
import { sendTealiumData } from '../../../../utils/utils';
import { PlplayoutConstants } from '../../../Layouts/Plp/PlpLayoutConstants';
import { SHOPPING_CART } from '../../../../constants/routes';
export interface IProductTitle {
  href?: string;
  name?: string;
}

const ProductTitle = ({ href = '', name }: IProductTitle) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  const { pathname } = useLocation();

  const history = useHistory();

  const { GRID, PLP_VIEW } = PlplayoutConstants;

  const isGridView = sessionStorage.getItem(PLP_VIEW) === GRID;

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const getCurrentSearchTerm = () => {
    let pathValues = pathname.split('/');
    let searchTerm = pathValues[pathValues.length - 1];
    return searchTerm.toLowerCase();
  };

  const sendAnalyticsClick = () => {
    if (window.location.pathname.includes('/search')) {
      sendTealiumData({
        tealium_event: SITE_SEARCH_CLICK_AFTER,
        site_search_keyphrase: getCurrentSearchTerm(),
        site_result_clicked: name,
      });
    }
  };
  return (
    <h3
      className={`product-name product-name-ellipsis ${
        isGridView && !isCartPage ? 'product-name-grid' : ''
      }`}>
      <Link to={href} ref={linkRef} onClick={sendAnalyticsClick}>
        {decode(name)}
      </Link>
    </h3>
  );
};

export { ProductTitle };
