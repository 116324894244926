import { Box } from '@material-ui/core';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useState } from 'react';
import { INteModal, NteModal } from '../NteModal';

/**
 * @interface INteModalHooks
 */
interface INteModalHooks {
  /**
   * @prop showModal Determines whether to show the modal or not.
   */
  showModal?: boolean;

  /**
   * @prop id for NteModal
   */
  id: string;

  /**
   * @prop ariaLabelledById for NteModal.
   */
  ariaLabelledById: string;

  /**
   * @prop ariaLabel for NteModal.
   */
  ariaLabel?: string;

  /**
   * @prop className for NteModal.
   */
  className?: string;
}

/**
 * @method useNteModal Provides the state management logic for the NteModal.
 *
 * @param INteModalHooks
 */
const useNteModal = ({
  showModal = false,
  id,
  ariaLabelledById,
  ariaLabel,
  className = '',
}: INteModalHooks) => {
  const [showNteModal, setShowNteModal] = useState(false);

  useEffect(() => {
    setShowNteModal(showModal);
  }, [showModal]);

  /**
   * @method nteModalClickHandler handles showing/hiding the nte
   * modal when the user performs a desired click operation.
   */
  const nteModalClickHandler = (): void =>
    setShowNteModal((showModal) => !showModal);

  /**
   * @Element NteModalFallbackFocus acts as focus trap fallback.
   */
  const NteModalFallbackFocus: JSX.Element = <div tabIndex={-1}></div>;

  /**
   * @wrapperComponent RenderModal wrapper contains the NteModal component's
   * UI implementation, this wrapper can be used
   * to instantiate the Nte Modal.
   */
  const RenderModal: React.FC<INteModal> = ({
    children,
    modalDismissHandler,
    nteModalCloseHandler,
    showModal,
  }) => (
    <NteModal
      modalDismissHandler={modalDismissHandler}
      nteModalCloseHandler={nteModalCloseHandler}
      id={id}
      className={className}
      ariaLabelledById={ariaLabelledById}
      ariaLabel={ariaLabel}
      showModal={showModal}>
      <FocusTrap
        focusTrapOptions={{
          fallbackFocus: NteModalFallbackFocus.type,
        }}>
        <Box>{children}</Box>
      </FocusTrap>
    </NteModal>
  );

  return { RenderModal, showNteModal, nteModalClickHandler, setShowNteModal };
};

export { useNteModal };
