import { createReducer } from '@reduxjs/toolkit';
import { IOrderLookUp } from '../../_foundation/interface/Order/IOrderLookUp';
import {
  FETCH_ORDER_LOOKUP_SUCCESS_ACTION,
  RESET_FETCH_ORDER_LOOKUP_ACTION,
} from '../actions/orderLookUp.action';

const initOrderLookup: IOrderLookUp = {
  orders: [],
  customerName: '',
};

const orderLookUpReducer = createReducer(initOrderLookup, (builder) => {
  /**
   * @action FETCH_ORDER_DETAILS_SUCCESS_ACTION
   * Fetches all the productDetails for an order
   */

  builder.addCase(FETCH_ORDER_LOOKUP_SUCCESS_ACTION, (_, action: any) => {
    const { orders, customerName } = action.payload;
    if (customerName) {
      return {
        customerName: customerName,
        orders: orders,
      };
    }
  });

  builder.addCase(RESET_FETCH_ORDER_LOOKUP_ACTION, () => {
    return { ...initOrderLookup };
  });
});

export { orderLookUpReducer };
