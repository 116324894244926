const ProductHotbarConstant = [
  { label: 'Description', value: 'description' },
  { label: 'Key Specs', value: 'keyspecs' },
  { label: 'Disclaimers', value: 'disclaimers' },
  { label: 'Reviews', value: 'reviews' },
  { label: 'Q & A', value: 'qa' },
  { label: 'Accessories', value: 'accessories' },
  { label: 'Parts', value: 'parts' },
  { label: 'Warranty', value: 'warranty' },
  { label: 'Compare', value: 'compare' },
];

const BrightCovePlayerConfig = {
  ACCOUNT_ID: process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID,
  PLAYER_ID: process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID,
  PARAMS: {
    grant_type: 'client_credentials',
    client_id: process.env.REACT_APP_BRIGHTCOVE_CLIENT_ID,
    client_secret: process.env.REACT_APP_BRIGHTCOVE_CLIENT_TOKEN,
  },
  OUATH: process.env.REACT_APP_BRIGHTCOVE_AUTHURL,
  BASE_URL: process.env.REACT_APP_BRIGHTCOVE_BASEURL,
  TOKEN: '/v3/access_token',
  VIDEO_URL: `/v1/accounts/${process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}/videos`,
};

const BVCustomerConfig = {
  BASE_URL: process.env.REACT_APP_BV_BASEURL,
  PARAMS: {
    apiversion: process.env.REACT_APP_BV_API_VERSION,
    passkey: process.env.REACT_APP_BV_PASSKEY,
    Filter: 'HasPhotos:true',
    Sort: 'SubmissionTime:desc',
    Rating: 'desc',
  },
};

const BVReviewConfig = {
  BASE_URL: process.env.REACT_APP_BV_SEO_BASEURL,
  PARAMS: {
    passkey: process.env.REACT_APP_BV_SEO_PASSKEY,
  },
};

const ProductAssetsURL = {
  ASSET_LIST_API: process.env.REACT_APP_ASSESTLIST,
  IMAGE_URL: process.env.REACT_APP_ASSET_HOST,
  HEADERS: {
    'NTE-CorrelationID': process.env.REACT_APP_NTE_CORRELATION_ID,
    producer: process.env.REACT_APP_PRODUCER_NAME,
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUBS_KEY,
  },
};

const WidgetInfoConstant = {
  LOCAL: 'local',
  GLOBAL: 'global',
};

const LabelsStrings = {
  REVIEWS: 'Reviews',
  QA: 'Questions & Answers',
  PRODUCT_SUMMARY: 'Product Summary',
  PDP_ACCESSORIES: 'ACCESSORY',
  WHAT_INCLUDED: "What's Included",
};

export {
  ProductHotbarConstant,
  BrightCovePlayerConfig,
  LabelsStrings,
  WidgetInfoConstant,
  BVCustomerConfig,
  ProductAssetsURL,
  BVReviewConfig,
};
