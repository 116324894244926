import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { checkTruthy } from '../../../../../utils/utils';
import {
  IProductListContentAttributes,
  IProductListContents,
} from '../../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../../_foundation/interface/Responses/ICartProductsResponse';
import ShippingInfoConstants from '../../../../Checkout/ShippingInfo/ShippingInfo.constants';
import { BadgeProps } from '../../../Badge/Badge';
import { ProductAttribute } from '../../../ProductAttribute/ProductAttribute';
import { PlpBadge } from '../../PlpBadge/PlpBadge';
import { ProductTitle } from '../../ProductTitle/ProductTitle';
import { ProductCardConstants } from '../ProductCardConstants';
import { ProductUtility } from '../../../../../utils/product-utility';
import { EntryTypeCode } from '../ProductCardSection';
import { ProductShipping } from '../ProductCardSectionMessages/ProductShipping/ProductShipping';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import { useCalculateStockStatus } from '../hooks/CalculateStockStatusHooks';
import { SHOPPING_CART } from '../../../../../constants/routes';
import { useGetPageType } from '../../../../../utils/hooks/get-page-type/GetPageType';
import { useStorePickupMessageHook } from '../hooks/StorePickupMessageHook';

/**
 * @interface ProductCardSectionDescriptionProps.
 */
export interface ProductCardSectionDescriptionProps {
  productCard: IProductListContents | ICartContentsItem;
  partNumber: string;
  isHidden?: boolean;
  isCompareProduct?: boolean;
  isProductCompare?: boolean;
  isAdvantage?: boolean;
  index: number;
  isSimpleProductCard?: boolean;
  isCart?: boolean;
  sortIndex?: number;
  isWishlistCard?: boolean;
  buyableWithNoPrice?: boolean;
  categorySkuNoPrice?: boolean;
}

interface IProductCardSectionDescription {
  /* Badges for a product */
  badges: Array<BadgeProps>;

  /* Name of the product */
  productName: string;

  /* Tops specs for a product. */
  topSpecs: Array<any>;

  /* PartNumber of a product */
  partNumber: string;

  /*isHidden prop */
  isHidden: boolean;
}
/**
 *
 * @param ProductCardSectionDescriptionProps.
 * @returns ProductCardSectionDescription Component.
 */
const ProductCardSectionDescription = ({
  productCard,
  isHidden,
  isCompareProduct,
  isProductCompare,
  isAdvantage,
  index,
  isSimpleProductCard,
  isCart = false,
  sortIndex,
  isWishlistCard,
  buyableWithNoPrice,
  categorySkuNoPrice,
}: ProductCardSectionDescriptionProps) => {
  const {
    DESCRIPTIVE,
    CONFIRMATION_PAGE,
    REVIEW_PAGE,
    PRODUCT_CARD_SECTION2: {
      ITEM_PARTNUMBER,
      DISPLAY_ONLY,
      ADVANTAGE_PURCHASE_LABEL,
    },
    SIMPLE_PRODUCT_CARD: { RECALL_BUTTON },
    PRODUCT_URL,
  } = ProductCardConstants;

  const { ADVANTAGE_PROMO_PARTNUMBER } = ShippingInfoConstants;

  const { t } = useTranslation();

  const history = useHistory();

  const { stockStatus } = useCalculateStockStatus(productCard);

  const [productSection2, setProductSection2] =
    useState<IProductCardSectionDescription>({
      badges: [],
      partNumber: '',
      productName: '',
      topSpecs: [],
      isHidden: false,
    });

  const linkRef = useRef<HTMLAnchorElement>(null);

  const { lastPartNumber } = useSelector(productListSelector);

  const viewportRefData = useRef<HTMLDivElement>(null);

  const currentPath = history.location.pathname;

  const isReviewPageOrConfirmation =
    currentPath === REVIEW_PAGE || currentPath === CONFIRMATION_PAGE;

  const { ITEM, PRODUCT } = ProductCardConstants;

  const showStoreOnly = ProductUtility.isStoreOnly(productCard);

  const catalogEntryTypeCode = productCard?.catalogEntryTypeCode;

  const productCardType = productCard?.type;

  const { pathname } = useCurrentPathName();

  const { storePickUpLabel, inStockMessage } =
    useStorePickupMessageHook(productCard, isProductCompare);

  const isCartPage = pathname === SHOPPING_CART;

  const { pageType } = useGetPageType();

  /**
   * Added || condition -- we are using v1 and v2 API
   */
  const hideProductShippingMessages =
    (catalogEntryTypeCode === EntryTypeCode.ITEMBEAN ||
      showStoreOnly ||
      productCardType?.toLowerCase() === ITEM) &&
    (catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN ||
      productCardType?.toLowerCase() !== PRODUCT);

  const { shippingMessage, sameDayShippingMessage } =
    ProductUtility.processShippingMessage(
      productCard,
      stockStatus,
      !isCartPage ? pageType : 'CartPage'
    );

  const productId = productCard.uniqueID
    ? productCard.uniqueID
    : productCard.id;

  const [showStoreStockDrawer, setShowStoreStockDrawer] =
    useState<boolean>(false);

  const storeStockCloseHandler = (): void => {
    setShowStoreStockDrawer(
      (showStoreStockDrawer: boolean) => !showStoreStockDrawer
    );
  };

  const parseSection2Details = useCallback(
    (productDetails: IProductListContents) => {
      // Initialize Description Details.
      const section2Details: IProductCardSectionDescription = {
        productName: productDetails.name,
        topSpecs: [],
        badges: [],
        partNumber: productCard?.partNumber,
        isHidden: isHidden ? isHidden : false,
      };

      if (!isCart) {
        productDetails.attributes?.forEach(
          (attribute: IProductListContentAttributes) => {
            if (
              checkTruthy(attribute.displayable) &&
              attribute.usage === DESCRIPTIVE &&
              !checkTruthy(attribute.storeDisplay) &&
              checkTruthy(attribute.comparable)
            ) {
              section2Details.topSpecs.push(attribute);
            }
          }
        );
      }

      setProductSection2(section2Details);
    },
    [isHidden, productCard?.partNumber, DESCRIPTIVE, isCart]
  );

  useEffect(() => {
    parseSection2Details(productCard as IProductListContents);
  }, [parseSection2Details, productCard]);

  const FreeItem =
    productCard &&
    productCard?.attributes?.find(
      ({ identifier }) => identifier === DISPLAY_ONLY
    );

  const isFreeItem = FreeItem?.values[0].value
    ? checkTruthy(FreeItem?.values[0].value)
    : false;

  const focusOnTitle = useCallback((): void => {
    if (
      lastPartNumber === productCard.partNumber &&
      linkRef &&
      linkRef.current
    ) {
      linkRef.current.focus();
    }
  }, [lastPartNumber, productCard.partNumber]);

  useEffect(() => {
    focusOnTitle();
  }, [focusOnTitle]);

  return (
    <div
      className='description-cell product_cart_section_description'
      ref={viewportRefData}>
      <div className='badge-compare-spacer'>
        {!productSection2?.isHidden &&
          !isSimpleProductCard &&
          !isCart &&
          !isCompareProduct && (
            <PlpBadge
              productCard={productCard}
              index={index}
              sortIndex={sortIndex}
            />
          )}
      </div>

      {isSimpleProductCard || isFreeItem ? (
        <h3 className='product-name'>{decode(productSection2?.productName)}</h3>
      ) : (
        <div className='product-name'>
          {!isAdvantage ? (
            <ProductTitle
              href={
                productCard?.seo?.href.includes(PRODUCT_URL)
                  ? productCard?.seo?.href
                  : PRODUCT_URL + productCard?.seo?.href
              }
              name={productSection2?.productName}
            />
          ) : (
            <Typography variant='body1' className='part-number'>
              {decode(productSection2?.productName)}
            </Typography>
          )}
        </div>
      )}

      <Typography variant='body2' className='part-number'>
        {ITEM_PARTNUMBER}
        {productCard.partNumber}
      </Typography>
      {productCard.partNumber === ADVANTAGE_PROMO_PARTNUMBER &&
        isReviewPageOrConfirmation && (
          <Typography variant='body2' className='part-number'>
            {ADVANTAGE_PURCHASE_LABEL}
          </Typography>
        )}

      {isSimpleProductCard && (
        <Link to={`/recall-details-${productCard.partNumber}`}>
          {t(RECALL_BUTTON)}
        </Link>
      )}

      {!isCompareProduct &&
        !isSimpleProductCard &&
        productSection2?.topSpecs.length > 0 &&
        !productSection2?.isHidden &&
        !isWishlistCard && (
          <ProductAttribute items={productSection2?.topSpecs} noOfItems={3} />
        )}

      {hideProductShippingMessages &&
        isWishlistCard &&
        !buyableWithNoPrice &&
        !categorySkuNoPrice && (
          <ProductShipping
            shippingMessage={shippingMessage}
            sameDayDeliveryMessage={sameDayShippingMessage}
            storePickupMessage={storePickUpLabel?.label}
            partNumber={productCard.partNumber}
            productName={productCard.name}
            showStoreStockDrawer={showStoreStockDrawer}
            storeStockCloseHandler={storeStockCloseHandler}
            productId={productId}
            productCard={productCard}
            isProductCompare={isCompareProduct}
            isStockMessage={inStockMessage}
            lineType={ProductUtility.getLineType(productCard)}
          />
        )}
    </div>
  );
};

export { ProductCardSectionDescription };
