import { useMediaQuery } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SHOPPING_CART } from '../../../../../constants/routes';
import { CART_ADD_TEALIUM } from '../../../../../constants/Tealium';
import {
  GUEST_AND_CART_DETAILS_ACTION,
  SET_ADD_TO_CART_LOADING_ACTION,
} from '../../../../../redux/actions/order.actions';
import { DELETE_REMOVE_WISH_LIST_ACTION } from '../../../../../redux/actions/wish-list.actions';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { orderSelector } from '../../../../../redux/selectors/order.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { wishListSelector } from '../../../../../redux/selectors/wish-list.selector';
import { ReactComponent as AdvantageIcon } from '../../../../../theme/assets/adv_color.svg';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import { useGetPageType } from '../../../../../utils/hooks/get-page-type/GetPageType';
import { ProductUtility } from '../../../../../utils/product-utility';
import {
  checkMembership,
  checkTruthy,
  sendTealiumData,
} from '../../../../../utils/utils';
import { CartService } from '../../../../../_foundation/apis/cart/cart.service';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { NteCollapse } from '../../../Collapse/NteCollapse';
import { useCalculateStockStatus } from '../hooks/CalculateStockStatusHooks';
import { useStorePickupMessageHook } from '../hooks/StorePickupMessageHook';
import { ProductCardConstants } from '../ProductCardConstants';
import { ProductCardSaveList } from '../ProductCardSaveList/ProductCardSaveList';
import { EntryTypeCode } from '../ProductCardSection';
import { ProductShipping } from '../ProductCardSectionMessages/ProductShipping/ProductShipping';
import { ProductCompare } from '../ProductCompare/ProductCompare';

/** @interface ProductCardSectionMessagesProps */
interface ProductCardSectionMessagesProps {
  productCard: IProductListContents;
  partNumber: string;
  removeFromList?: boolean;
  isCompareProduct?: boolean;
  sharedList?: boolean;
  isProductCompare?: boolean;
  buyableWithNoPrice?: boolean;
  isGroupedInventory?: boolean;
  displayCompareCheckbox?: boolean;
  requestedQuantity?: string;
  isWishlistCard?: boolean;
}

/** @interface CtaButton */
export interface CtaButton {
  label: string;
  variant: ButtonProps['variant'];
}

/**
 * @param ProductCardSectionMessagesProps.
 * @returns ProductCardSectionMessages Component.
 */
const ProductCardSectionMessages = ({
  productCard,
  partNumber,
  removeFromList,
  isCompareProduct,
  sharedList,
  isProductCompare,
  buyableWithNoPrice,
  isGroupedInventory,
  displayCompareCheckbox,
  requestedQuantity,
  isWishlistCard,
}: ProductCardSectionMessagesProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { mySite } = useSite();

  const { isUserAuthenticated, userType } = useSelector(authenticationSelector);

  const { isSearchPlp } = useSelector(productListSelector);

  const { addToCartLoading, paymentInstruction } = useSelector(orderSelector);

  const { giftItemIds, giftListIds, giftList } = useSelector(wishListSelector);

  const { stockStatus } = useCalculateStockStatus(productCard);

  const cartButtonLoading = addToCartLoading[partNumber];

  const { pathname } = useCurrentPathName();

  const { storePickUpLabel, inStockMessage } = useStorePickupMessageHook(
    productCard,
    isProductCompare
  );

  const isCartPage = pathname === SHOPPING_CART;

  const isMobileDevice = useMediaQuery('(max-width:600px)');

  const showAccordion = isMobileDevice && !isCartPage;

  const { pageType } = useGetPageType();

  const { ITEM, PRODUCT, GIFTCARD_PAY_METHOD } = ProductCardConstants;

  const { shippingMessage, sameDayShippingMessage } =
    ProductUtility.processShippingMessage(
      productCard,
      stockStatus,
      !isCartPage ? pageType : 'CartPage'
    );

  const isAdvantageExclusives =
    ProductUtility.processAdvantageExclusives(productCard);

  const showStoreOnly = ProductUtility.isStoreOnly(productCard);

  const productId = productCard.uniqueID
    ? productCard.uniqueID
    : productCard.id;

  const descName: string = giftList.find(
    (wishList) => wishList?.uniqueID === giftListIds[productId]
  )?.descriptionName!;

  const productName = productCard.name;

  const catalogEntryTypeCode = productCard?.catalogEntryTypeCode;

  const productCardType = productCard?.type;

  /**
   * Added || condition -- we are using v1 and v2 API
   */
  const showProductShippingMessages =
    (catalogEntryTypeCode === EntryTypeCode.ITEMBEAN ||
      showStoreOnly ||
      productCardType?.toLowerCase() === ITEM) &&
    (catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN ||
      productCardType?.toLowerCase() !== PRODUCT);

  const { isAdvantage } = checkMembership(userType);

  const showAdvantageExclusivesBadge =
    isUserAuthenticated && isAdvantage && isAdvantageExclusives;

  const [buttonProps, setButtonProps] = useState<CtaButton>({
    label: t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.ADD_TO_CART),
    variant: 'contained',
  });

  const [showStoreStockDrawer, setShowStoreStockDrawer] =
    useState<boolean>(false);

  const storeStockCloseHandler = (): void => {
    setShowStoreStockDrawer(
      (showStoreStockDrawer: boolean) => !showStoreStockDrawer
    );
  };

  const addToCartDetails = () => {
    /**
     * Executes only if Add to Cart button is clicked.
     * Item will be added to Cart.
     */
    if (
      catalogEntryTypeCode !== EntryTypeCode.PRODUCTBEAN &&
      !(
        buttonProps.label ===
        t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS)
      )
    ) {
      dispatch(
        SET_ADD_TO_CART_LOADING_ACTION({
          loading: true,
          partNumber: productCard?.partNumber,
        })
      );

      /**
       * responsible to delete the Gift card Payment instruction
       * when user coming back from checkout flow and doing additional changes to cart
       */
      if (paymentInstruction) {
        const giftCardPaymentInstruction = paymentInstruction?.filter(
          ({ payMethodId }) =>
            payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
        );

        if (giftCardPaymentInstruction?.length > 0) {
          giftCardPaymentInstruction.forEach(({ piId }) =>
            CartService.removeGiftCard({
              storeId: mySite.storeID,
              paymentInstructionId: piId,
            })
          );
        }
      }

      dispatch(
        GUEST_AND_CART_DETAILS_ACTION({
          storeID: mySite.storeID,
          partNumber: partNumber,
          quantityParam: requestedQuantity,
          ...{ ...(isCartPage && { isCartPage }) },
        })
      );

      sendTealiumData({
        tealium_event: CART_ADD_TEALIUM,
        product_id: partNumber,
        product_name: productCard.name,
      });
    }

    /**
     * Executes only if See Options or See Details button is clicked.
     * Navigates to PDP page.
     */
    if (
      catalogEntryTypeCode === EntryTypeCode.PRODUCTBEAN ||
      buttonProps.label ===
        t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS)
    ) {
      history.push(productCard?.seo?.href);
    }

    if (removeFromList && giftItemIds[productId] && giftListIds[productId]) {
      dispatch(
        DELETE_REMOVE_WISH_LIST_ACTION({
          storeID: mySite.storeID,
          wishListId: giftListIds[productId],
          itemId: giftItemIds[productId],
          productId,
          wishListPlp: true,
          descriptionName: `${t(descName)}`,
          productPartNumber: partNumber,
        })
      );
    }
  };

  /**
   * @method
   * Responsible to set the ButtonProps based on requirements
   */
  const setCtaButtonProps = useCallback((): void => {
    if (
      showStoreOnly ||
      (!checkTruthy(productCard?.buyable) && isSearchPlp) ||
      buyableWithNoPrice
    ) {
      setButtonProps({
        label: t(ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_DETAILS),
        variant: 'outlined',
      });
      return;
    }
    switch (catalogEntryTypeCode) {
      case EntryTypeCode.ITEMBEAN:
        if (!showStoreOnly)
          setButtonProps({
            label: t(
              ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.ADD_TO_CART
            ),
            variant: 'contained',
          });
        break;
      case EntryTypeCode.PRODUCTBEAN:
        if (!showStoreOnly)
          setButtonProps({
            label: t(
              ProductCardConstants.PRODUCT_CARD_SECTION3.BUTTON.SEE_OPTIONS
            ),
            variant: 'outlined',
          });
        break;
    }
  }, [
    buyableWithNoPrice,
    catalogEntryTypeCode,
    isSearchPlp,
    productCard?.buyable,
    showStoreOnly,
    t,
  ]);

  useEffect(() => {
    setCtaButtonProps();
  }, [catalogEntryTypeCode, setCtaButtonProps, showStoreOnly]);

  const shippingMessageContent = (
    <>
      {showProductShippingMessages && !isWishlistCard && (
        <ProductShipping
          shippingMessage={shippingMessage}
          sameDayDeliveryMessage={sameDayShippingMessage}
          storePickupMessage={storePickUpLabel?.label}
          partNumber={partNumber}
          productName={productCard.name}
          showStoreStockDrawer={showStoreStockDrawer}
          storeStockCloseHandler={storeStockCloseHandler}
          productId={productId}
          isCompareProduct={isCompareProduct}
          productCard={productCard}
          isProductCompare={isProductCompare}
          isStockMessage={inStockMessage}
          lineType={ProductUtility.getLineType(productCard)}
        />
      )}
      <div
        className={
          'cta-cell' + +(!showProductShippingMessages ? 'message-collapse' : '')
        }>
        <NteButton
          classes={{ root: 'Add-to-cart-button' }}
          variant={buttonProps.variant}
          translationKey={buttonProps.label}
          onClick={addToCartDetails}
          fullWidth={true}
          size='small'
          loading={cartButtonLoading}
          type='button'
        />

        <div className='field-labels'>
          {showProductShippingMessages &&
            !sharedList &&
            displayCompareCheckbox && (
              <ProductCompare id={partNumber} name={productName} />
            )}

          {showProductShippingMessages && !sharedList && (
            <ProductCardSaveList
              id={productId}
              productData={productCard}
              name={productName}
              partNumber={partNumber}
              descriptionName={`${t(descName)}`}
            />
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      {showAdvantageExclusivesBadge && (
        <AdvantageIcon
          className='advantage-badge'
          role='img'
          aria-label='advantage badge'
        />
      )}
      {!isCompareProduct ? (
        showAccordion ? (
          <NteCollapse showCollapse={false}>
            {shippingMessageContent}
          </NteCollapse>
        ) : (
          shippingMessageContent
        )
      ) : (
        <>
          {showProductShippingMessages && (
            <ProductShipping
              shippingMessage={shippingMessage}
              sameDayDeliveryMessage={sameDayShippingMessage}
              storePickupMessage={storePickUpLabel?.label}
              partNumber={partNumber}
              productName={productCard.name}
              showStoreStockDrawer={showStoreStockDrawer}
              storeStockCloseHandler={storeStockCloseHandler}
              productId={productId}
              isCompareProduct={isCompareProduct}
              productCard={productCard}
              isProductCompare={isProductCompare}
              isStockMessage={inStockMessage}
              lineType={ProductUtility.getLineType(productCard)}
            />
          )}
          <div className='cta-cell'>
            <NteButton
              classes={{ root: 'Add-to-cart-button ' }}
              variant='contained'
              translationKey={buttonProps.label}
              onClick={addToCartDetails}
              fullWidth={true}
              loading={cartButtonLoading}
              type='button'
            />

            <div className='field-labels'>
              {showProductShippingMessages && !sharedList && (
                <ProductCardSaveList
                  id={productId}
                  name={productName}
                  productData={productCard}
                  partNumber={partNumber}
                  descriptionName={`${t(descName)}`}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { ProductCardSectionMessages };
