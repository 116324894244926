import { call, put } from 'redux-saga/effects';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import i18n from '../../../i18n';
import { alertMessageId } from '../../../utils/utils';
import { taxInformationService } from '../../../_foundation/apis/transaction/tax-information/tax-information.service';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../actions/network-errors.actions';
import { GET_TAX_INFORMATION_SUCCESS_ACTION } from '../../actions/tax-information.actions';

const translationKey = 'API_ERROR_HANDLING.MY_ACCOUNT.TAX_INFORMATION';

export function* fetchTaxInformation(action: any): Generator {
  try {
    const response: any = yield call(
      taxInformationService.fetchTaxInformation,
      action.payload
    );

    /**
     * If the tax information is not available/null/undefined or if the
     * data is not an array redirect the users to an error page.
     */
    if (response?.taxInformation && Array.isArray(response?.taxInformation)) {
      yield put(GET_TAX_INFORMATION_SUCCESS_ACTION(response));
    } else if (
      response?.taxExempt &&
      response?.taxExempt.toLowerCase() === 'no'
    ) {
      yield put(GET_TAX_INFORMATION_SUCCESS_ACTION(response));
    } else {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          redirectToErrorPage: true,
        })
      );
    }
  } catch (e) {
    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.TAX_EXEMPT.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}
