import { AxiosError } from 'axios';
import HTMLReactParser from 'html-react-parser';
import { call, put } from 'redux-saga/effects';
import { AuthenticationService } from '../../../_foundation/apis/auth/authentication.service';
import { CartErrorMessage } from '../../../_foundation/apis/cart/cart.message.constants';
import { CartService } from '../../../_foundation/apis/cart/cart.service';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { SHIPPING_OPTION_FAILED } from '../../../_foundation/constants/error';
import { PRE_CHECKOUT_SUCCESS_ACTION } from '../../../_foundation/constants/payment';
import {
  IDeleteItemInCart,
  IOrderItem,
} from '../../../_foundation/interface/Cart/IOrder';
import { CheckoutFlows } from '../../../components/Checkout/enum/Checkout';
import {
  CARD_DENIED_ACTION,
  DELIVERY_PAGE_API_LOADING,
  FETCH_CART_PRODUCTS_REVIEW_ACTION,
  FETCH_CHECKOUT_CART_DETAILS_ACTION,
  FETCH_CHECKOUT_CART_DETAILS_DELIVERY,
  RESTRICTED_CART_PRODUCTS_SUCCESS_ACTION,
  SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING_ACTION,
} from '../../../components/Checkout/redux/actions/checkout.actions';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import { OrderReviewConstants } from '../../../components/Pages/CheckoutPage/OrderReviewPage/OrderReviewConstants';
import { CHECKOUT_REVIEW, SHOPPING_CART } from '../../../constants/routes';
import i18n from '../../../i18n';
import {
  alertMessageId,
  customAxiosError,
  hasKey,
  is400Error,
  is500SeriesError,
  isNetworkError,
} from '../../../utils/utils';
import { GUEST_LOGIN_SUCCESS_ACTION } from '../../actions/auth.actions';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import {
  MERGE_CART_ERROR_ACTION,
  NETWORK_ERROR_ACTION,
  TIER_1_ERROR_ACTION,
} from '../../actions/network-errors.actions';
import {
  FETCH_CART_DETAILS_REQUEST_ACTION,
  FETCH_CART_DETAILS_SUCCESS_ACTION,
  FETCH_SHIPPING_INFO_SUCCESS_ACTION,
  GET_CART_PRODUCTS_SUCCESS_ACTION,
  GUEST_AND_CATALOG_ORDER_SUCCESS_ACTION,
  SET_ADD_TO_CART_LOADING_ACTION,
  SET_CART_SPINNER_ACTION,
  SET_GIFTCARD_ERROR_ACTION,
  SET_PAYMENT_ERROR_ACTION,
  SET_REMOVE_SPINNER_ACTION,
  SET_REPLACMENT_PLAN_SPINNER_ACTION,
  SHOW_CART_DRAWER_ACTION,
  UPDATE_GUEST_CONTACT_INFO_ACTION,
  UPDATE_USABLE_PAYMENT_INFO_ACTION,
} from '../../actions/order.actions';
import {
  FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION,
  SET_ORDER_CONFIRMATION_ERROR_ACTION,
  SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION,
  SET_ORDER_CONFIRMATION_LOADING_ACTION,
} from '../../actions/orderConfirmation.action';
import {
  FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION,
  RESET_FETCH_CART_ITEM_DETAILS_ACTION,
} from '../../actions/orderDetails.action';
import { REMOVE_PROMO_CODE_ACTION } from '../../actions/promoCode.actions';
// import { STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION } from '../../actions/storeLocator';
import {
  FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION,
  RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION,
} from '../../actions/usable-shipping-info';
import { fetchItemDetailsByIds } from './orderDetails.workers';

export interface IProductParam {
  storeID: string;
  id: string[];
}

const translationKey = 'API_ERROR_HANDLING.CART_AND_CHECKOUT';

const cartMessage = CartErrorMessage;

export function* fetchCartDetails(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.getCartDetails,
      action.payload
    );

    const fetchCartDetailsResponse = {
      ...response,
      partNumber: action.payload.partNumber,
      signInProcessed: action.payload?.signInProcessed,
    };

    yield put(FETCH_CART_DETAILS_SUCCESS_ACTION(fetchCartDetailsResponse));

    if (fetchCartDetailsResponse) {
      const catentryIdList: string[] = [];
      fetchCartDetailsResponse?.orderItem?.forEach((item: IOrderItem) => {
        catentryIdList.push(item.productId);
      });

      const storeId: string = fetchCartDetailsResponse?.storeUniqueID;
      if (catentryIdList) {
        const paramsProduct: IProductParam = {
          storeID: storeId,
          id: catentryIdList,
        };

        const productDetails = yield call(fetchItemDetailsByIds, paramsProduct);
        let content = JSON.stringify(productDetails);
        let pdetails = JSON.parse(content);
        let finalPdetials: any = [];
        catentryIdList.forEach((id) => {
          if (typeof productDetails === 'object' && productDetails !== null) {
            if (Object.hasOwn(productDetails, 'contents')) {
              pdetails.contents.forEach((obj: any) => {
                if (id === obj.id) {
                  finalPdetials.push(obj);
                }
              });
            }
          }
        });
        let finaldetails: any = {};
        finaldetails.contents = finalPdetials;
        finaldetails.metaData = { ...pdetails.metaData }; //{ ...userDetails }
        finaldetails.total = finalPdetials.length;
        yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(finaldetails));
        // yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(productDetails));
      }
    }

    if (response?.maxLineItems === 'true' || response?.maxQuantity === 'true') {
      yield put(SHOW_CART_DRAWER_ACTION(true));
    }
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status !== 404 && action.payload.partNumber) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Product #${action.payload.partNumber} is currently unavailable and was removed from your cart.`,
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }
  }
}

export function* addToCart(action: any): Generator {
  try {
    const response = yield call(CartService.addToCart, action.payload);

    return response;
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    const errorKey = response?.data?.errors
      ? response?.data?.errors[0]?.errorKey
      : '_GENERIC_ERROR';

    if (isNetworkError(axiosError) && hasKey(cartMessage, errorKey)) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(cartMessage[errorKey]),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    } else {
      if (
        response?.status === 400 &&
        response?.data?.errors[0]?.errorKey.includes('GC_AMOUNT_ERROR')
      ) {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: response?.data?.errors[0]?.errorKey,
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
        yield put(
          SET_ADD_TO_CART_LOADING_ACTION({
            loading: false,
            partNumber: action.payload.partNumber,
          })
        );
      } else {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(`${translationKey}.UPDATE_ORDER_ITEM.ERROR`),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
      }
    }
  }
}

export function* updateItemInCart(action: any): Generator {
  try {
    const response = yield call(CartService.updateCartDetails, action.payload);

    if (response) {
      yield put(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());
    }

    yield put(
      FETCH_CART_DETAILS_REQUEST_ACTION({
        storeID: action.payload.storeID,
        showSpinner: true,
        partNumber: action.payload.partNumber,
        isCartPage: action.payload.isCartPage,
      })
    );

    // if (action.payload.orderId) {
    //   yield put(
    //     STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION({
    //       storeID: action.payload.storeID,
    //       latitude: action.payload.latitude,
    //       longitude: action.payload.longitude,
    //       orderId: action.payload.orderId,
    //       anchorStore: action.payload.anchorStore,
    //       radius: action.payload.radius,
    //     })
    //   );
    // }

    if (action.payload.isCartPage) {
      yield put(SHOW_CART_DRAWER_ACTION(false));
      yield put(
        SET_GIFTCARD_ERROR_ACTION({
          giftCardError: false,
          giftCardErrorMessage: '',
        })
      );
    } else {
      yield put(SHOW_CART_DRAWER_ACTION(true));
    }
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    const errorKey = response?.data?.errors
      ? response?.data?.errors[0]?.errorKey
      : '_GENERIC_ERROR';

    if (isNetworkError(axiosError) && hasKey(cartMessage, errorKey)) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(cartMessage[errorKey]),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    } else {
      if (
        response?.status === 400 &&
        response?.data?.errors[0]?.errorKey.includes('GC_AMOUNT_ERROR')
      ) {
        if (action.payload.isCartPage) {
          yield put(
            SET_GIFTCARD_ERROR_ACTION({
              giftCardError: true,
              giftCardErrorMessage: response?.data?.errors[0]?.errorCode,
            })
          );
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: response?.data?.errors[0]?.errorCode,
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        } else {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: response?.data?.errors[0]?.errorCode,
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        }
      } else {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(`${translationKey}.UPDATE_ORDER_ITEM.ERROR`),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
      }
    }

    action.payload.isCartPage
      ? yield put(
          SET_CART_SPINNER_ACTION({
            loading: false,
            partNumber: action.payload.partNumber,
          })
        )
      : yield put(
          SET_ADD_TO_CART_LOADING_ACTION({
            loading: false,
            partNumber: action.payload.partNumber,
          })
        );

    console.error(e);
  }
}

export function* deleteItemInCart(action: any): Generator {
  try {
    const response = yield call(CartService.deleteItemInCart, action.payload);

    if (response) {
      yield put(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());
    }

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        FETCH_CHECKOUT_CART_DETAILS_ACTION({
          storeID: action.payload.storeID,
          checkoutFlow: CheckoutFlows.Delivery,
        })
      );
    } else {
      yield put(
        FETCH_CART_DETAILS_REQUEST_ACTION({
          storeID: action.payload.storeID,
          partNumber: action.payload.partNumber,
          isCartPage: action.payload.isCartPage,
        })
      );
    }

    const { isObsolete, isUnavailable, partNumber, isRestrictedItem } =
      action.payload as IDeleteItemInCart;

    if (isRestrictedItem) {
      yield put(
        FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION({
          storeID: action.payload.storeID,
          physicalStoreId: action.payload.storeUniqueID,
          checkoutFlow: CheckoutFlows.Delivery,
          addressId: action.payload.addressId,
        })
      );
    }

    if (!isObsolete && !isUnavailable && partNumber) {
      yield put(
        SET_REMOVE_SPINNER_ACTION({
          loading: false,
          partNumber,
        })
      );

      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Item ${partNumber} was removed from your shopping cart.`,
          messageId: alertMessageId(),
          severity: 'success',
        })
      );
    }

    if (action.payload.isAdvantageMember) {
      yield put(DELIVERY_PAGE_API_LOADING(true));

      yield put(
        FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION({
          storeID: action.payload.storeID,
          physicalStoreId: action.payload.physicalStoreId,
          checkoutFlow: CheckoutFlows.Delivery,
          addressId: action.payload.addressId,
        })
      );
    }

    // if (action.payload.orderId) {
    //   yield put(
    //     STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION({
    //       storeID: action.payload.storeID,
    //       latitude: action.payload.latitude,
    //       longitude: action.payload.longitude,
    //       orderId: action.payload.orderId,
    //       anchorStore: action.payload.anchorStore,
    //       radius: action.payload.radius,
    //     })
    //   );
    // }

    yield put(
      SET_ADD_TO_CART_LOADING_ACTION({
        loading: false,
        partNumber: action.payload.partNumber,
      })
    );
  } catch (e) {
    yield put(DELIVERY_PAGE_API_LOADING(false));

    yield put(
      SET_REMOVE_SPINNER_ACTION({
        loading: false,
        partNumber: action.payload.partNumber,
      })
    );

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.DELETE_ITEM_FROM_CART.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );

    console.error(e);
  }
}

export function* guestAndCartInfo(action: any): Generator {
  try {
    const { guestIdentityResponse, fetchCartDetailsresponse }: any = yield call(
      CartService.guestAndCartInfo,
      action.payload
    );

    if (guestIdentityResponse) {
      yield put(GUEST_LOGIN_SUCCESS_ACTION(guestIdentityResponse));
    }

    if (fetchCartDetailsresponse) {
      let catentryIdList: string[] = [];
      if (fetchCartDetailsresponse) {
        const orderItems = fetchCartDetailsresponse?.orderItem;

        if (orderItems.length > 0) {
          orderItems.forEach((item: IOrderItem) => {
            catentryIdList.push(item.productId);
          });
        }

        const storeId: string = fetchCartDetailsresponse?.storeUniqueID;

        if (catentryIdList.length > 0) {
          const paramsProduct: IProductParam = {
            storeID: storeId,
            id: catentryIdList,
          };

          const productDetails = yield call(
            fetchItemDetailsByIds,
            paramsProduct
          );

          if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
            yield put(
              FETCH_CHECKOUT_CART_DETAILS_DELIVERY(fetchCartDetailsresponse)
            );
          } else {
            yield put(
              FETCH_CART_DETAILS_SUCCESS_ACTION(fetchCartDetailsresponse)
            );
          }

          let content = JSON.stringify(productDetails);
          let pDetails = JSON.parse(content);

          let finalPdetails: any = [];
          catentryIdList.forEach((id) => {
            if (typeof productDetails === 'object' && productDetails !== null) {
              if (Object.hasOwn(productDetails, 'contents')) {
                pDetails.contents.forEach((obj: any) => {
                  if (id === obj.id) {
                    finalPdetails.push(obj);
                  }
                });
              }
            }
          });

          let finaldetails: any = {};
          finaldetails.contents = finalPdetails;
          finaldetails.metaData = { ...pDetails.metaData }; //{ ...userDetails }
          finaldetails.total = finalPdetails.length;

          yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(finaldetails));

          //if we select Advantage Membership
          if (action.payload?.isAdvantagePromo || action.payload?.isCartPage) {
            yield put(SHOW_CART_DRAWER_ACTION(false));
          } else {
            yield put(SHOW_CART_DRAWER_ACTION(true));
          }
        }
      }
    }

    if (action.payload.isAdvantageMember) {
      yield put(DELIVERY_PAGE_API_LOADING(true));

      yield put(
        FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION({
          storeID: action.payload.storeID,
          physicalStoreId: action.payload.physicalStoreId,
          checkoutFlow: CheckoutFlows.Delivery,
          addressId: action.payload.addressId,
        })
      );
    }

    yield put(
      SET_ADD_TO_CART_LOADING_ACTION({
        loading: false,
        partNumber: action.payload.partNumber,
      })
    );
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    const errorKey = response?.data?.errors
      ? response?.data?.errors[0]?.errorKey
      : '_GENERIC_ERROR';

    yield put(
      SET_ADD_TO_CART_LOADING_ACTION({
        loading: false,
        partNumber: action.payload.partNumber,
      })
    );

    if (
      response?.status === 400 &&
      response.data.errors[0].errorKey ===
      '_ERR_THRESHOLD_SHOPPING_CART_MAX_ITEMS'
    ) {
      yield put(MERGE_CART_ERROR_ACTION(axiosError));

      yield put(SHOW_CART_DRAWER_ACTION(true));
    }

    if (isNetworkError(axiosError) && hasKey(cartMessage, errorKey)) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(cartMessage[errorKey]),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    } else {
      if (
        response?.status === 400 &&
        response?.data?.errors[0]?.errorKey.includes('GC_AMOUNT_ERROR')
      ) {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: response?.data?.errors[0]?.errorCode,
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
      } else {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(`${translationKey}.ADD_TO_CART.ERROR`),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
      }
    }

    yield put(
      SET_REPLACMENT_PLAN_SPINNER_ACTION({
        loading: false,
        partNumber: action.payload.partNumber,
      })
    );
    yield put(
      SET_REPLACMENT_PLAN_SPINNER_ACTION({
        loading: false,
        partNumber: action.payload.comment,
      })
    );
  }
}

export function* guestAndCatalogOrderInfo(action: any): Generator {
  try {
    const { guestIdentityResponse, catalogOrderResponse }: any = yield call(
      CartService.guestAndCatalogOrder,
      action.payload
    );

    if (catalogOrderResponse) {
      yield put(GUEST_AND_CATALOG_ORDER_SUCCESS_ACTION(catalogOrderResponse));
    }

    if (guestIdentityResponse) {
      yield put(GUEST_LOGIN_SUCCESS_ACTION(guestIdentityResponse));
    }

    const fetchCartDetailsrespone: any = yield call(
      CartService.getCartDetails,
      action.payload
    );

    if (fetchCartDetailsrespone && catalogOrderResponse) {
      let catentryIdList: string[] = [];
      if (fetchCartDetailsrespone) {
        const orderItems = fetchCartDetailsrespone?.orderItem;

        if (orderItems.length > 0) {
          orderItems.forEach((item: IOrderItem) => {
            if (!item?.comments) {
              catentryIdList.push(item.productId);
            }
          });
        }

        const storeId: string = fetchCartDetailsrespone?.storeUniqueID;

        if (catentryIdList.length > 0) {
          const paramsProduct: IProductParam = {
            storeID: storeId,
            id: catentryIdList,
          };

          const productDetails = yield call(
            fetchItemDetailsByIds,
            paramsProduct
          );

          yield put(FETCH_CART_DETAILS_SUCCESS_ACTION(fetchCartDetailsrespone));

          yield put(FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION(productDetails));

          if (catalogOrderResponse.success_PartNumbers?.length > 0) {
            if (!action.payload.isCartPage)
              yield put(SHOW_CART_DRAWER_ACTION(true));
            yield put(
              SET_ADD_TO_CART_LOADING_ACTION({
                loading: false,
                partNumber: action.payload.partNumber,
              })
            );
          }

          yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
        }
      }
    }
    return { catalogOrderResponse };
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    console.error(e);

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.ADD_TO_CART.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}

export function* getCartProducts(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchProductsByPartNumbers,
      action.payload
    );

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(RESTRICTED_CART_PRODUCTS_SUCCESS_ACTION(response));
    } else if (action.payload.checkoutFlow === CheckoutFlows.Review) {
      yield put(FETCH_CART_PRODUCTS_REVIEW_ACTION(response));
    } else {
      yield put(GET_CART_PRODUCTS_SUCCESS_ACTION(response));
    }
  } catch (e) {
    const axiosError = e as AxiosError;

    if (isNetworkError(axiosError)) {
      yield put(
        TIER_1_ERROR_ACTION({
          api: axiosError.response?.config?.url,
          apiResponseHttpCode: axiosError.response?.status.toString(),
          apiResponsePayload: axiosError.response?.data,
        })
      );
    }
  }
}

export function* getShippingInfo(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.getShippingInfo,
      action.payload
    );

    yield put(FETCH_SHIPPING_INFO_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getGuestContactInfo(action: any): Generator {
  try {
    const response: any = yield call(
      new AuthenticationService().getUserInfo,
      action.payload
    );

    yield put(UPDATE_GUEST_CONTACT_INFO_ACTION(response.contact!));
  } catch (e) {
    console.log(e);

    yield put(UPDATE_GUEST_CONTACT_INFO_ACTION([]));
  }
}

export function* updateShippingAndBillingInfo(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.updateShippingAndBillingInfo,
      action.payload
    );

    if (response && !action.payload.isAdvantagePromo) {
      yield put(DELIVERY_PAGE_API_LOADING(false));
    }

    if (action.payload.reFetchShippingInfo) {
      yield put(DELIVERY_PAGE_API_LOADING(true));

      yield put(
        FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION({
          storeID: action.payload.storeID,
          physicalStoreId: action.payload.physicalStoreId,
          addressId: action.payload.addressId,
          checkoutFlow: action.payload.checkoutFlow,
        })
      );
    }

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        FETCH_CHECKOUT_CART_DETAILS_ACTION({
          storeID: action.payload.storeID,
          checkoutFlow: CheckoutFlows.Delivery,
        })
      );
    } else {
      yield put(
        FETCH_CART_DETAILS_REQUEST_ACTION({
          storeID: action.payload.storeID,
        })
      );
    }
  } catch (e) {
    console.error(e);

    const axiosError = e as AxiosError;

    yield put(DELIVERY_PAGE_API_LOADING(false));

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        SET_UPDATE_DELIVERY_SHIPPING_METHOD_LOADING_ACTION({
          updateShippingMethodLoading: false,
          isAddressUpdate: false,
        })
      );

      if (is500SeriesError(axiosError) || is400Error(axiosError)) {
        yield put(
          TIER_1_ERROR_ACTION({
            api: SHIPPING_OPTION_FAILED,
            apiResponseHttpCode: axiosError.response?.status.toString()
              ? axiosError.response?.status.toString()
              : '503',
            apiResponsePayload: JSON.stringify(axiosError?.response)
              ? JSON.stringify(axiosError?.response)
              : 'Service down',
            errorKey: 'ERR_SERVICE_DOWN',
          })
        );
      } else {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(`${translationKey}.UPDATE_SHIPPING_INFO.ERROR`),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );
      }
    } else {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.UPDATE_SHIPPING_INFO.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }
  }
}

export function* addGiftCard(action: any): Generator {
  try {
    const addGiftCardResponse: any = yield call(
      CartService.addGiftCard,
      action.payload
    );

    yield put(
      FETCH_CART_DETAILS_REQUEST_ACTION({
        storeID: action.payload.storeId,
        checkoutFlow: CheckoutFlows.Payment,
      })
    );

    yield put(
      FETCH_CHECKOUT_CART_DETAILS_ACTION({
        storeID: action.payload.storeId,
        checkoutFlow: CheckoutFlows.Payment,
      })
    );

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    return addGiftCardResponse;
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;
    const { errors } = response?.data;

    if (
      response?.status === 400 &&
      ((errors[0].errorCode === 'GIFT_001' &&
        errors[0].errorKey === '_ERR_GIFTCARD_ALREADY_USED') ||
        (errors[0].errorCode === 'GIFT_005' &&
          errors[0].errorKey === '_ERR_ZERO_GIFT_CARD_BALANCE'))
    ) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: errors[0].errorMessage,
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      yield put(SET_PAYMENT_ERROR_ACTION(true));
    } else if (
      response?.status === 400 &&
      errors[0].errorCode === 'Service Unavailable' &&
      errors[0].errorKey === 'Service Unavailable'
    ) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.PAYMENT.ERROR.CIRCUIT_ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      yield put(SET_PAYMENT_ERROR_ACTION(true));
    } else if (
      response?.status === 400 &&
      errors[0].errorCode === '_ERR_BAD_MISSING_CMD_PARAMETER' &&
      errors[0].errorParameters === 'billing_address_id'
    ) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `A Billing Address is required when using a Gift Card.  Please enter your Billing Address.`,
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      yield put(SET_PAYMENT_ERROR_ACTION(true));
    } else if (response?.status === 400) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Invalid Gift Card number or PIN. Please check the numbers and try again.`,
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      yield put(SET_PAYMENT_ERROR_ACTION(true));
    } else {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: `Unable to process Gift Card at this time.Please try again later.`,
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      yield put(SET_PAYMENT_ERROR_ACTION(true));
    }
  }
}

export function* removeGiftCard(action: any): Generator {
  try {
    const removeGiftCardResponse: any = yield call(
      CartService.removeGiftCard,
      action.payload
    );

    yield put(
      FETCH_CART_DETAILS_REQUEST_ACTION({
        storeID: action.payload.storeId,
        checkoutFlow: CheckoutFlows.Payment,
      })
    );

    yield put(
      FETCH_CHECKOUT_CART_DETAILS_ACTION({
        storeID: action.payload.storeId,
        checkoutFlow: CheckoutFlows.Payment,
      })
    );

    yield put(
      SET_REMOVE_SPINNER_ACTION({
        loading: false,
        partNumber: action.payload.paymentInstructionId,
      })
    );

    return removeGiftCardResponse;
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
    throw e;
  }
}

export function* fetchUsablePaymentInfo(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.fetchUsablePaymentInfo,
      action.payload
    );

    yield put(
      UPDATE_USABLE_PAYMENT_INFO_ACTION(response.usablePaymentInformation!)
    );
  } catch (e) {
    const axiosError = e as AxiosError;

    if (isNetworkError(axiosError)) {
      yield put(
        TIER_1_ERROR_ACTION({
          api: axiosError.response?.config?.url,
          apiResponseHttpCode: axiosError.response?.status.toString(),
          apiResponsePayload: JSON.stringify(axiosError?.response?.data),
        })
      );
    }

    yield put(UPDATE_USABLE_PAYMENT_INFO_ACTION([]));
  }
}

export function* preCheckoutandCheckoutInfo(action: any): Generator {
  try {
    try {
      if (action.payload.isPaypal) {
        yield call(CartService.preCheckoutinfo, action.payload);
      }

      yield call(CartService.checkoutInfo, action.payload);

      yield put(
        SET_BUTTON_LOADING_ACTION({
          loading: false,
          buttonType: PRE_CHECKOUT_SUCCESS_ACTION,
        })
      );
    } catch (e) {
      const { response }: AxiosError = e as AxiosError;

      const { errors } = response?.data;

      if (
        action.payload.promoFailureHistory &&
        response?.status === 400 &&
        errors[0].errorKey === 'ERR_PROMOTION_CODE_INVALID'
      ) {
        yield put(
          REMOVE_PROMO_CODE_ACTION({
            storeID: action.payload.storeID,
            promoCode: errors[0].errorParameters,
          })
        );

        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(
              `The promo code ${errors[0].errorParameters} is no longer valid and has been removed.`
            ),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );

        action.payload.promoFailureHistory.push(SHOPPING_CART);
      } else if (action.payload.currentHistory) {
        action.payload.currentHistory.push(CHECKOUT_REVIEW);

        const { response }: AxiosError = e as AxiosError;

        const { errors } = response?.data;

        if (
          response?.status === 400 &&
          errors[0].errorCode === 'EPP_001' &&
          errors[0].errorKey === '_ERR_PAYPAL_CANCELED'
        ) {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: i18n.t(`${translationKey}.PAYPAL.CANCEL`),
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        } else {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: i18n.t(`${translationKey}.PAYPAL.ERROR`),
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        }
      } else {
        const {
          BOPIS_ERROR_CODE,
          BOPIS_ERROR_KEY,
          CARD_DENIED_KEY,
          CARD_DENIED_BOPIS_KEY,
          TREVIPAY_DENIED_KEY,
          TREVIPAY_DENIED_BOPIS_KEY,
        } = OrderReviewConstants;

        /* this section checks the errors section of the response and controls whether the user is shown 
         * an error bar at the top of the payment page or the full "An error has occured" page by populating
         * the tier1 error details
         */
        const axiosError = e as AxiosError;

        yield put(
          SET_BUTTON_LOADING_ACTION({
            loading: action.payload.isPaypal,
            buttonType: '',
          })
        );

        if (
          axiosError.response?.data?.errors[0]?.errorKey === BOPIS_ERROR_KEY &&
          axiosError.response?.data?.errors[0]?.errorCode === BOPIS_ERROR_CODE
        ) {
          yield put(
            NETWORK_ERROR_ACTION({
              errorCode: BOPIS_ERROR_CODE,
              errorKey: BOPIS_ERROR_KEY,
            })
          );

          return;
        }

        if (
          axiosError.response?.data?.errors[0]?.errorKey === CARD_DENIED_KEY
          || axiosError.response?.data?.errors[0]?.errorKey === TREVIPAY_DENIED_KEY
        ) {
          yield put(
            NETWORK_ERROR_ACTION({
              errorCode: CARD_DENIED_KEY,
              errorKey: CARD_DENIED_KEY,
            })
          );
          return;
        }

        if (
          axiosError.response?.data?.errors[0]?.errorKey === CARD_DENIED_BOPIS_KEY
          || axiosError.response?.data?.errors[0]?.errorKey === TREVIPAY_DENIED_BOPIS_KEY
        ) {
          yield put(
            NETWORK_ERROR_ACTION({
              errorCode: CARD_DENIED_BOPIS_KEY,
              errorKey: CARD_DENIED_BOPIS_KEY,
            })
          );
          return;
        }

        if (isNetworkError(axiosError)) {
          yield put(
            TIER_1_ERROR_ACTION({
              api: axiosError.response?.config?.url,
              apiResponseHttpCode: axiosError.response?.status.toString(),
              apiResponsePayload: JSON.stringify(axiosError?.response?.data),
              errorKey: axiosError.response?.data?.errors[0]?.errorKey,
            })
          );
        }
      }
    }

    yield put(SET_ORDER_CONFIRMATION_LOADING_ACTION(false));

    yield put(RESET_FETCH_CART_ITEM_DETAILS_ACTION());
  } catch (e) {
    yield put(SET_ORDER_CONFIRMATION_LOADING_ACTION(false));

    yield put(SET_ORDER_CONFIRMATION_ERROR_ACTION(true));

    throw e;
  }
}

export function* getOrderConfirmationDetails(action: any): Generator {
  try {
    const response: any = yield call(
      CartService.getOrderConfirmationDetails,
      action.payload
    );

    if (response) {
      let catentryIdList: string[] = [];
      const orderItems = response?.orderItem;

      if (orderItems.length > 0) {
        orderItems.forEach((item: IOrderItem) => {
          catentryIdList.push(item.productId);
        });
      }

      const storeId: string = response?.storeUniqueID;

      if (catentryIdList.length > 0) {
        const paramsProduct: IProductParam = {
          storeID: storeId,
          id: catentryIdList,
        };

        const productDetails = yield call(fetchItemDetailsByIds, paramsProduct);

        const resp = {
          response,
          productDetails,
        };

        yield put(FETCH_ORDER_CONFIRMATION_SUCCESS_ACTION(resp));

        yield put(SET_ORDER_CONFIRMATION_ITEM_LOADED_ACTION(true));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* updatePoNumberInfo(action: any): Generator {
  try {
    yield call(CartService.updatePoNumberDetails, action.payload);
  } catch (e) {
    console.error(e);
  }
}

export function* updatePaymentInfo(action: any): Generator {
  try {
    const paymentInfoResponse: any = yield call(
      CartService.updatePaymentInfo,
      action.payload
    );

    yield put(CARD_DENIED_ACTION(false));

    if (paymentInfoResponse?.errorCode === 300) {
      yield put(CARD_DENIED_ACTION(true));

      throw customAxiosError(
        i18n.t(`${translationKey}.PAYMENT.ERROR._ERR_CARD_DENIED_DC`),
        300
      );
    }
  } catch (e) {
    console.error(e);

    const axiosError = e as AxiosError;

    if (isNetworkError(axiosError)) {
      if (axiosError?.response?.status === 300) {
        yield put(CARD_DENIED_ACTION(true));

        yield put(
          SET_BUTTON_LOADING_ACTION({ loading: false, buttonType: '' })
        );

        if (
          action.payload.d365Store &&
          (axiosError?.response?.data?.errors[0].errorKey === OrderReviewConstants.CARD_DENIED_D365_KEY
            || axiosError?.response?.data?.errors[0].errorKey === OrderReviewConstants.TREVIPAY_DENIED_D365_KEY)
        ) {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: HTMLReactParser(
                i18n.t(
                  `${translationKey}.PAYMENT.ERROR._ERR_CARD_DENIED_D365`,
                  {
                    storeName: action.payload.storeName
                      ? action.payload.storeName
                      : '',
                  }
                )
              ),
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        } else if (action.payload.bopisStore) {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: HTMLReactParser(
                i18n.t(
                  `${translationKey}.PAYMENT.ERROR._ERR_CARD_DENIED_BOPIS`,
                  {
                    storeName: action.payload.storeName
                      ? action.payload.storeName
                      : '',
                  }
                )
              ),
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        } else {
          yield put(
            SHOW_NTE_ALERT_ACTION({
              message: i18n.t(
                `${translationKey}.PAYMENT.ERROR._ERR_CARD_DENIED_DC`
              ),
              messageId: alertMessageId(),
              severity: 'error',
            })
          );
        }
      } else {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(`${translationKey}.CARD_DETAILS.ERROR`),
            messageId: alertMessageId(),
            severity: 'error',
          })
        );

        yield put(
          TIER_1_ERROR_ACTION({
            api: axiosError.response?.config?.url,
            apiResponseHttpCode: axiosError.response?.status.toString(),
            apiResponsePayload: JSON.stringify(axiosError?.response?.data),
            errorKey: axiosError.response?.data?.errors[0]?.errorKey,
          })
        );
      }
    }
  }
}

export function* calculateOrder(action: any): Generator {
  try {
    const response = yield call(CartService.orderCalculate, action.payload);

    if (response) {
      yield put(
        SET_BUTTON_LOADING_ACTION({
          loading: false,
          buttonType: 'ORDER_CALCULATE_ACTION_SUCCESS',
        })
      );
      yield put(DELIVERY_PAGE_API_LOADING(false));
    }
  } catch (e) {
    yield put(DELIVERY_PAGE_API_LOADING(false));
    console.error(e);
  }
}
