import { takeLatest } from 'redux-saga/effects';
import { FETCH_USER_SAVED_CARD_DETAILS_ACTION } from '../../actions/auth.actions';
import * as WORKERS from '../workers/savedCardDetails.workers';

export function* watchSaga() {
  yield takeLatest(
    FETCH_USER_SAVED_CARD_DETAILS_ACTION,
    WORKERS.fetchCardDetails
  );
}
