import { call, put } from '@redux-saga/core/effects';
import { getLocalStorage } from '../../../../../../utils/utils';
import { IFetchOrderItemDetails } from '../../../../../../_foundation/apis/order/order.service';
import { productsService } from '../../../../../../_foundation/apis/search/products/products.service';
import { RECENTLY_VISITED_LINKS } from '../../../../../../_foundation/constants/cookie';
import { RecentlyVisitedLinks } from '../../../../ShoppingCart/ContinueShoppingLinks/IContinueShoppingLinks';
import { FETCH_RVI_SUCCESS_ACTION } from '../../actions/rvi.actions';

export function* fetchRvi(action: any): Generator {
  try {
    const rviLinks: RecentlyVisitedLinks = getLocalStorage(
      RECENTLY_VISITED_LINKS
    );

    if (rviLinks?.visitedLinks.length > 0) {
      const payload: IFetchOrderItemDetails = {
        storeID: action.payload.storeID,
        partNumbers: rviLinks.visitedLinks
          .filter(({ productPartNumber }) => Boolean(productPartNumber))
          .map(({ productPartNumber }) => productPartNumber),
      };

      const response: any = yield call(
        productsService.fetchProductsByPartNumbers,
        payload
      );

      yield put(FETCH_RVI_SUCCESS_ACTION(response));
    } else {
      yield put(
        FETCH_RVI_SUCCESS_ACTION({
          contents: [],
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}
