import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CartService } from '../../../../_foundation/apis/cart/cart.service';
import { RECENTLY_VISITED_LINKS } from '../../../../_foundation/constants/cookie';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { IMerchandisingAssociations } from '../../../../_foundation/interface/Responses/ICartProductsResponse';
import { CART_ADD_TEALIUM } from '../../../../constants/Tealium';
import { SHOPPING_CART } from '../../../../constants/routes';
import {
  GUEST_AND_CART_DETAILS_ACTION,
  SET_ADD_TO_CART_LOADING_ACTION,
} from '../../../../redux/actions/order.actions';
import { RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION } from '../../../../redux/actions/usable-shipping-info';
import { orderSelector } from '../../../../redux/selectors/order.selector';
import { useCurrentPathName } from '../../../../utils/hooks/current-pathname/CurrentPathName';
import { ImageUrlUtility } from '../../../../utils/image-url-utility';
import {
  getLocalStorage,
  sendTealiumData,
  setLocalStorage,
} from '../../../../utils/utils';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { ProductPricing } from '../../../ProductPricing/ProductPricing';
import { ProductCardSectionMessagesConstants } from '../../Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { RecentlyVisitedLinks } from '../../ShoppingCart/ContinueShoppingLinks/IContinueShoppingLinks';
import { Thumbnail } from '../../Thumbnail/Thumbnail';
import { FETCH_RVI_ACTION } from '../redux/actions/rvi.actions';
import { RviCardConstants } from './RviCardConstants';

/**
 * @interface IRviCard
 */
export interface IRviCard {
  itemName: string;
  productPartNumber: string;
  thumbnail: string;
  categoryName?: string;
  categoryLink?: string;
  seo: { href: string };
  isStoreOnly?: boolean;
  displayRemoveLink?: boolean;
  manufacturer?: string;
  showSeeCartPriceMsg?: boolean;
  productType?: string;
  productData: IProductListContents | IMerchandisingAssociations;
}

/**
 * @component RviCard renders the Rvi card component.
 */
const RviCard: React.FC<IRviCard> = ({
  itemName,
  productPartNumber,
  thumbnail,
  categoryName,
  categoryLink,
  seo,
  isStoreOnly,
  displayRemoveLink = true,
  manufacturer,
  productType,
  productData,
}) => {
  const {
    ADD_TO_CART,
    ITEM_NO,
    REMOVE,
    SEE_ALL,
    SEE_OPTIONS,
    SEE_DETAILS,
    PRODUCT,
  } = RviCardConstants;

  const { GIFTCARD_PAY_METHOD } = ProductCardSectionMessagesConstants;

  const { mySite } = useSite();

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { addToCartLoading, paymentInstruction } = useSelector(orderSelector);

  const { pathname } = useCurrentPathName();

  const isCartPage = pathname === SHOPPING_CART;

  const isRvi = isCartPage;

  

  const cartButtonLoading = addToCartLoading[productPartNumber];

  const isProduct = productType === PRODUCT;

  const removeProduct = () => {
    const rviData: RecentlyVisitedLinks = getLocalStorage(
      RECENTLY_VISITED_LINKS
    );

    if (rviData) {
      const productIndex = rviData.visitedLinks.findIndex(
        (visitedLink) => visitedLink.productPartNumber === productPartNumber
      );

      rviData.visitedLinks.splice(productIndex, 1);

      setLocalStorage(RECENTLY_VISITED_LINKS, rviData);

      dispatch(
        FETCH_RVI_ACTION({
          storeID: mySite?.storeID,
        })
      );
    }
  };

  /**
   * @interface seeOptionsHandler Navigates to PDP page.
   */
  const seeOptionsHandler = (): void => {
    history.push(seo.href);
  };

  /**
   * @interface addToCartHandler Adds an item to the cart.
   */
  const addToCartHandler = (): void => {
    if (mySite) {
      dispatch(
        SET_ADD_TO_CART_LOADING_ACTION({
          loading: true,
          partNumber: productPartNumber,
        })
      );

      /**
       * responsible to delete the Gift card Payment instruction
       * when user coming back from checkout flow and doing additional changes to cart
       */
      if (paymentInstruction) {
        const giftCardPaymentInstruction = paymentInstruction?.filter(
          ({ payMethodId }) =>
            payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
        );

        if (giftCardPaymentInstruction?.length > 0) {
          giftCardPaymentInstruction.forEach(({ piId }) =>
            CartService.removeGiftCard({
              storeId: mySite.storeID,
              paymentInstructionId: piId,
              history,
            })
          );
        }
      }

      dispatch(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());

      dispatch(
        GUEST_AND_CART_DETAILS_ACTION({
          storeID: mySite.storeID,
          partNumber: productPartNumber,
          ...{ ...(isCartPage && { isCartPage }) },
          history,
        })
      );

      sendTealiumData({
        tealium_event: CART_ADD_TEALIUM,
        product_id: productPartNumber,
        product_name: itemName,
      });
    }
  };

  const imageUrl = ImageUrlUtility.createImgSrc(thumbnail);

  return (
    <div
      className='rvi-carousel-slide nte-card product-card'
      role='group'
      aria-label='product'
      data-product-id={productPartNumber}
      data-brand-name={manufacturer}>
      <div className='remove'>
        {categoryName && categoryLink ? (
          <Link to={categoryLink}>
            {t(SEE_ALL)} {categoryName}
          </Link>
        ) : (
          displayRemoveLink && (
            <button
              type='button'
              aria-label='Remove'
              className='inline-text'
              onClick={removeProduct}>
              {t(REMOVE)}
            </button>
          )
        )}
      </div>

      <div className='rvi-card-container'>
        <div className='rvi-top image-cell'>
          <Link to={seo.href}>
            <Thumbnail imgSrc={imageUrl} imgAlt={decode(itemName)} />
          </Link>

          {productPartNumber && (
            <div
              className='rating-container'
              data-bv-show='inline_rating'
              data-bv-seo='true'
              data-bv-product-id={productPartNumber}></div>
          )}
        </div>

        <div className='rvi-bottom description-cell aligned-bottom-card'>
          <div className='product-info-cell'>
            <Typography variant='h2' className='product-name'>
              <Link to={seo.href}>{itemName}</Link>
            </Typography>

            <Typography variant='body2' className='part-number'>
              {t(ITEM_NO)} {productPartNumber}
            </Typography>

            <ProductPricing isRvi = {isRvi} showQtyPopper productCard={productData} />
          </div>

          {isProduct ? (
            <NteButton
              className='rvi-card-button'
              translationKey={SEE_OPTIONS}
              variant='outlined'
              size='small'
              onClick={seeOptionsHandler}
              loading={cartButtonLoading}
              type='button'
            />
          ) : (
            <NteButton
              className='rvi-card-button'
              translationKey={isStoreOnly ? SEE_DETAILS : ADD_TO_CART}
              variant={isStoreOnly ? 'outlined' : 'contained'}
              size='small'
              onClick={isStoreOnly ? seeOptionsHandler : addToCartHandler}
              loading={cartButtonLoading}
              type='button'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { RviCard };

