import { createReducer } from '@reduxjs/toolkit';
import IGetExtendedUsableShippingResponse from '../../_foundation/interface/Responses/IGetExtendedUsableShippingResponse';
import IGetUsableShippingResponse from '../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { IGetUsableShippingState } from '../../_foundation/interface/Shipping/IUsableShipping';
import {
  FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION,
} from '../actions/usable-shipping-info';

const initusableShippingInfo: IGetUsableShippingState = {
  usableShippingMode: [],
  usableShippingMethodData: [],
  restrictedItems: [],
  loading: true,
  isBopisEligible: false,
  physicalStoreId: '',
  backOrderedProducts: [],
  iomStoreId: '',
};

const usableShippingReducer = createReducer(
  initusableShippingInfo,
  (builder) => {
    /**
     * @action FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION
     * Fetches all the usable shipping info
     */

    builder.addCase(
      FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
      (state: IGetUsableShippingState, action: any) => {
        const response = action.payload as IGetUsableShippingResponse;

        const restrictedItems = response.restrictedItems
          ? response.restrictedItems
          : [];

        if (
          response.orderItem[0] &&
          response.orderItem[0]?.usableShippingMode
        ) {
          const usableShippingMode = response.orderItem[0].usableShippingMode;

          return {
            ...state,
            usableShippingMode,
            restrictedItems,
            loading: false,
            sameDayDelivery: response.sameDayDelivery,
          };
        }

        return {
          ...state,
          restrictedItems,
          loading: false,
          sameDayDelivery: response.sameDayDelivery,
          isBopisEligible: response.isBopisEligible,
          physicalStoreId: response.physicalStoreId,
          iomStoreId: response.iomStoreId, 
        };
      }
    );

    /**
     * @action FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION
     * Fetches all the usable shipping info from the extended endpoint
     */

    builder.addCase(
      FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
      (state: IGetUsableShippingState, action: any) => {
        const response = action.payload as IGetExtendedUsableShippingResponse;
        
        if (response.shipping_methods) {
          const usableShippingMethodData = response.shipping_methods;
          const backOrderedProducts = response.backOrderedProducts;
          const dropShippedOnly = response?.dropShippedOnly;
          const restrictedItems = response.restrictedItems?response.restrictedItems :[];
          return {
            ...state,
            usableShippingMethodData,
            backOrderedProducts,
            dropShippedOnly,
            restrictedItems,
            loading: false,
          };
        } 
      }
    );

    builder.addCase(
      RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION,
      (state: IGetUsableShippingState) => {
        return {
          ...state,
          usableShippingMethodData: [],
          backOrderedProducts: [],
          restrictedItems:[],
          loading: true,
        };
      }
    );
  }
);

export { usableShippingReducer };
