const ProductCardConstants = {
  DESCRIPTIVE: 'Descriptive',
  ITEM: 'item',
  PRODUCT: 'product',
  D365_ATTRIBUTE: 'operatingInD365',
  D365_MESSAGE:
    'Buy online pickup in store same day is not available for this location.',
  BOPIS_AVAILABILITY: 'bopisAvailability',
  PRODUCT_CARD_SECTION2: {
    ITEM_PARTNUMBER: 'Item # ',
    DISPLAY_ONLY: 'DisplayOnly',
    ADVANTAGE_PURCHASE_LABEL: 'Available to Shop Online within 2 days.',
  },
  REVIEW_PAGE: '/checkout/review',
  CONFIRMATION_PAGE: '/order-confirmation',
  PRODUCT_CARD_SECTION3: {
    BUTTON: {
      ADD_TO_CART: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.ADD_TO_CART',
      SEE_DETAILS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.SEE_DETAILS',
      SEE_OPTIONS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.SEE_OPTIONS',
    },
    STORE_PICKUP: {
      SOLD_ONLY_PREFIX:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.SOLD_ONLY_PREFIX',
      CHECK_STORE_STOCK:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.CHECK_STORE_STOCK',
      CHECK_STORES:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.CHECK_STORES',
      NO_STORES_NEAR_YOU:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.NO_STORES_NEAR_YOU',
      NOT_AVAILABLE:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.NOT_AVAILABLE',
      MYSTORE: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.STORE_PICKUP.MYSTORE',
      PRODUCT_CARD_SECTION3: {
        BUTTON: {
          ADD_TO_CART: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.ADD_TO_CART',
          SEE_DETAILS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.SEE_DETAILS',
          SEE_OPTIONS: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.SEE_OPTIONS',
          PREORDER: 'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PREORDER',
        },
      },
    },
    PRODUCT_SHIPPING: {
      STORE_PICKUP:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRODUCT_SHIPPING.STORE_PICKUP',
      SHIPPING:
        'PRODUCT_LIST_PAGE.PRODUCT_CARD.SECTION_3.PRODUCT_SHIPPING.SHIPPING',
    },

    STORE_ONLY: 'storeonly',
    SOLD_AT_STORES: 'Sold in Stores',
    OUT_OF_STOCK: 'Out of stock at ',
    OUT_OF_STOCK_BACKORDERED: 'Order today for estimated pickup ',
    IN_STOCK: ' in stock at ',
    BACKORDERED: 'Backordered - Expected to ship',
  },
  SIMPLE_PRODUCT_CARD: {
    RECALL_BUTTON:
      'PRODUCT_LIST_PAGE.PRODUCT_CARD.SIMPLE_PRODUCT_CARD.RECALL_BUTTON',
  },
  GIFTCARD_PAY_METHOD: 'GiftCard',
  PRODUCT_URL: '/products',
};

export { ProductCardConstants };
