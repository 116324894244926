import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WishListProductsConstants } from '../../../components/Widgets/WishListProducts/WishListProductsConstants';
import {
  CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION,
  GET_WISH_LIST_ACTION,
} from '../../../redux/actions/wish-list.actions';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { wishListSelector } from '../../../redux/selectors/wish-list.selector';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { useCurrentPathName } from '../current-pathname/CurrentPathName';

/**
 * @method useInitWishList Initializes the wishlist saga effects based on the authentication status.
 */
const useInitWishList = () => {
  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const { giftList } = useSelector(wishListSelector);

  const { currentPathName } = useCurrentPathName();

  const { MY_SAVED_LISTS } = WishListProductsConstants;

  const isWishListPage = currentPathName === MY_SAVED_LISTS;

  const initWishlistData = useCallback((): void => {
    if (mySite) {
      if (isUserAuthenticated && giftList.length === 0) {
        dispatch(
          GET_WISH_LIST_ACTION({ storeID: mySite.storeID, isWishListPage })
        );
      }
    }
  }, [dispatch, giftList.length, isUserAuthenticated, isWishListPage, mySite]);

  useEffect(() => {
    initWishlistData();
  }, [isUserAuthenticated, giftList, initWishlistData]);

  useEffect(() => {
    dispatch(CLEAR_WISH_LIST_PRODUCT_PAGE_ACTION());
  }, [dispatch]);
};

export { useInitWishList };
