import React, { useCallback, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { getLocalStorage } from '../../utils/utils';
import { USER } from '../../_foundation/constants/cookie';
import { useNteModal } from '../NteModal/hooks/NteModalHooks';
import { SignInModal } from '../Widgets/SignInModal/SignInModal';
import { AccountSignInConstants } from './AccountSignInConstants';

/**
 * @component AccountSignIn renders the sign in modal based
 * on the query params and the previous user type.
 */
const AccountSignIn: React.FC = () => {
  const {
    SIGN_IN_ALERT_MODAL,
    SIGN_IN_PARAM_KEY,
    SIGN_IN_PARAM_VALUE,
    SIGN_IN_TITLE_MODAL,
  } = AccountSignInConstants;

  const [signInParams, setSignInParams] = useQueryParam(
    SIGN_IN_PARAM_KEY,
    StringParam
  );

  const { RenderModal, nteModalClickHandler, showNteModal, setShowNteModal } =
    useNteModal({
      ariaLabelledById: 'account-signin-dialog',
      id: 'account-signin',
    });

  /**
   * @callback initSignInModal responsible for opening the signin modal and clearing the query param.
   */
  const initSignInModal = useCallback((): void => {
    const user = getLocalStorage(USER);

    if (
      !user?.isUserAuthenticated &&
      signInParams &&
      signInParams === SIGN_IN_PARAM_VALUE
    ) {
      setShowNteModal(true);
    }

    if (user?.isUserAuthenticated && signInParams) {
      setSignInParams(undefined, 'replace');
    }
  }, [SIGN_IN_PARAM_VALUE, setShowNteModal, setSignInParams, signInParams]);

  useEffect(() => {
    initSignInModal();
  }, [initSignInModal]);

  /**
   * @callback signInCloseHandler Closes the signin modal and clears the query params.
   */
  const signInCloseHandler = (): void => {
    nteModalClickHandler();

    setSignInParams(undefined, 'replace');
  };

  return (
    <>
      {showNteModal && (
        <RenderModal
          showModal={showNteModal}
          nteModalCloseHandler={signInCloseHandler}>
          <SignInModal
            title={SIGN_IN_TITLE_MODAL}
            signInCloseHandler={signInCloseHandler}
            alertMessage={SIGN_IN_ALERT_MODAL}
          />
        </RenderModal>
      )}
    </>
  );
};

export { AccountSignIn };
