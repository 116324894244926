import { AxiosRequestConfig } from 'axios';
import { trackUserEvent } from '../../../utils/lucky-orange';
import IGetUsableShippingResponse from '../../interface/Responses/IGetUsableShippingResponse';
import {
  IGetUsableShippingDetails,
  IGetUsableShippingMethodDetails,
} from '../../interface/Shipping/IUsableShipping';
import { makeRequest } from '../axios/axiosConfig';
import { UsableShippingServiceConstant } from './usable-shipping.service.constants';
import { USER_IP } from '../../constants/cookie';

class UsableShippingService {
  public async getUsableShippingInfo({
    storeID,
    physicalStoreId,
    addressId,
  }: IGetUsableShippingDetails): Promise<IGetUsableShippingResponse> {
    const { GET_USABLE_SHIPPING_INFO_DETAILS } = UsableShippingServiceConstant;

    try {
      const getUsableShippingInfoRequest: AxiosRequestConfig = {
        url: GET_USABLE_SHIPPING_INFO_DETAILS(
          storeID,
          physicalStoreId,
          addressId
        ),
        data: { USER_IP: sessionStorage.getItem(USER_IP) },
      };

      const getUsableShippingInfoResponse: IGetUsableShippingResponse =
        await makeRequest(getUsableShippingInfoRequest);

      return getUsableShippingInfoResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'deliveryPage-usableShippingInfo',
      });

      throw e;
    }
  }

  public async getUsableShippingMethodDetails({
    storeID,
    zipCode,
    orderID,
    partNumbers,
    partNumber,
    quantity,
    physicalStoreId,
    lineType,
  }: IGetUsableShippingMethodDetails): Promise<IGetUsableShippingResponse> {
    const { GET_USABLE_SHIPPING_METHODS } = UsableShippingServiceConstant;

    try {
      const getUsableShippingMethodsRequest: AxiosRequestConfig = {
        url: GET_USABLE_SHIPPING_METHODS(
          storeID,
          zipCode,
          orderID,
          partNumbers,
          quantity,
          partNumber,
          physicalStoreId,
          lineType
        ),
        data: { USER_IP: sessionStorage.getItem(USER_IP) },
      };

      const getUsableShippingMethodsResponse: IGetUsableShippingResponse =
        await makeRequest(getUsableShippingMethodsRequest);

      return getUsableShippingMethodsResponse;
    } catch (e) {
      throw e;
    }
  }
}

export { UsableShippingService };
