const BASE_URL = '/wcs/resources/store/';

const SEARCH_BASE_URL = '/search/resources/api/v2/products';

const WishListServiceConstants = {
  GET_WISH_LIST_URL: (storeID: string): string =>
    `${BASE_URL}${storeID}/wishlist/@self`,
  ADD_DELETE_WISH_LIST: (
    storeID: string,
    wishListItemId: string,
    isAddItem: boolean
  ): string =>
    `${BASE_URL}${storeID}/wishlist${
      wishListItemId
        ? `/${wishListItemId}${isAddItem ? '?addItem=true' : ''}`
        : ''
    }`,
  DELETE_WISH_LIST: (storeID: string, wishListItemId: string): string =>
    `${BASE_URL}${storeID}/wishlist${
      wishListItemId ? `/${wishListItemId}` : ''
    }`,
  SHARE_WISH_LIST: (storeID: string, wishListItemId: string) =>
    `${BASE_URL}${storeID}/wishlist/${wishListItemId}?action=announce`,
  FETCH_WISH_LIST_PRODUCTS: (queryParams: any, storeID: string) =>
    `${SEARCH_BASE_URL}?${queryParams}&storeId=${storeID}`,
  FETCH_WISH_LIST_PLP: (
    storeID: string,
    wishListId: string,
    pageNumber: string,
    pageSize: string
  ) =>
    `${BASE_URL}${storeID}/wishlist/${wishListId}/item?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  ADD_ITEM_TO_WISHLIST: (storeID: string, wishListItemId: string) =>
    `${BASE_URL}${storeID}/wishlist/${wishListItemId}?addItem=true`,
  FETCH_SHARED_WISHLIST_DETAILS: (storeID: string, id: string) =>
    `${BASE_URL}${storeID}/wishlist/${id}`,
};

export { WishListServiceConstants };
