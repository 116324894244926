import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SHOPPING_CART } from '../../../../constants/routes';
import { MiniCartHeaderSubsectionConstants } from './MiniCartHeaderSubsectionConstants';

interface IMinicarSubSection {
  /**
   * @prop cartHeaderCloseHandler
   * Handles drawer close events when the cart
   * is open.
   */
  cartHeaderCloseHandler?: () => void;
}

/**
 * @component MiniCartHeaderSubSection is responsible for rendering the
 * cart header subsection component that has 2 buttons.
 */
const MiniCartHeaderSubSection: React.FC<IMinicarSubSection> = ({
  cartHeaderCloseHandler,
}) => {
  const { CONTINUE_SHOPPING, VIEW_CART_CHECKOUT } =
    MiniCartHeaderSubsectionConstants;

  const { t } = useTranslation();

  const history = useHistory();

  const onCartClickHandler = (): void => {
    history.push(SHOPPING_CART);

    if (cartHeaderCloseHandler) {
      cartHeaderCloseHandler();
    }
  };

  return (
    <>
      <Button
        onClick={onCartClickHandler}
        role='link'
        size='small'
        fullWidth={true}
        variant='contained'>
        {t(VIEW_CART_CHECKOUT)}
      </Button>

      <Button
        onClick={cartHeaderCloseHandler}
        role='link'
        size='small'
        fullWidth={true}
        className='fullWidth'
        variant='outlined'>
        {t(CONTINUE_SHOPPING)}
      </Button>
    </>
  );
};

export { MiniCartHeaderSubSection };
