const WISH_LIST_PRODUCTS_KEY = 'ACCOUNT.MY_SAVED_LISTS';

const WishListProductsConstants = {
  PRODUCTS: `${WISH_LIST_PRODUCTS_KEY}.PRODUCTS`,
  OF: `${WISH_LIST_PRODUCTS_KEY}.OF`,
  ZERO_PRODUCTS: `${WISH_LIST_PRODUCTS_KEY}.ZERO_PRODUCTS`,
  LOAD_MORE: `${WISH_LIST_PRODUCTS_KEY}.LOAD_MORE`,
  PARAM_ID: 'id',
  SAVE_FOR_LATER_ROUTER: 'save-for-later',
  GUEST_ACCESS_KEY: 'guestAccessKey',
  EXTERNAL_ID: 'externalId',
  MY_SAVED_LISTS: 'my-saved-lists'
};

export { WishListProductsConstants };
