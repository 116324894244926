import { IGetUsableShippingState } from '../../_foundation/interface/Shipping/IUsableShipping';
import { RootReducerState } from '../reducers';

const usableShippingInfoSelector = (
  state: RootReducerState
): IGetUsableShippingState => {
  return state.usableShipping;
};

export { usableShippingInfoSelector };
