import { Divider, Hidden } from '@material-ui/core';
import React from 'react';
import { useCheckout } from '../../Checkout/hooks/CheckoutHooks';
import EmailDeals from '../EmailDeals/EmailDeals';
import AboutUsLinks from './AboutUs/AboutUsLinks';
import CustomerCareLinks from './CustomerCare/CustomerCareLinks';
import ResourcesLinks from './Resources/ResourcesLinks';

const FooterLinks: React.FC = () => {
  const { isCheckout } = useCheckout();

  return (
    <div className='footer_grid_inner'>
      <Hidden mdUp>
        <Divider className='deal-divider' />
      </Hidden>
      {!isCheckout && (
        <>
          <CustomerCareLinks />
          <ResourcesLinks />
          <AboutUsLinks />
          <EmailDeals />
        </>
      )}
    </div>
  );
};

export default FooterLinks;
