import {
  Accordion,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 *
 * @returns Facet Skeleton.
 */

const FacetSkeleton = () => {
  return (
    <Accordion defaultExpanded={true}>
      <div>
        {/*To remove inline style with class level style */}
        <Skeleton variant="text" width='50%' height={50} style={{marginLeft : '10px' , marginBottom: '10px'}}/>
      </div>
        {[0, 1, 2 , 3].map((data, index) => (
          <li key={index}>
        {/*To remove inline style with class level style */}
            <Skeleton variant="text" width='80%' height={30} style={{marginBottom: '10px'}}/>
          </li>
        ))}
    </Accordion>
  );
};

export { FacetSkeleton };
