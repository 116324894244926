import { Badge, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SHOPPING_CART } from '../../../../../constants/routes';
import { orderSelector } from '../../../../../redux/selectors/order.selector';
import { ReactComponent as CART_GOLD } from '../../../../../theme/assets/cart_gold.svg';
import { ScreenReaderOnly } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { CartLinkConstants } from './CartLinkConstants';

/**
 * @component CartButton renders the cart button component.
 */
const CartLink: React.FC = () => {
  const { CART, CART_FALLBACK } = CartLinkConstants;

  const { t, i18n } = useTranslation();

  const { noOfCartItems } = useSelector(orderSelector);

  const cartItems = noOfCartItems ? noOfCartItems : '0';

  return (
    <li aria-label='Cart' className='cursor-pointer minicart-top'>
      {/**
       * Link instead of a button since the purpose of this Cart is not to function as a dropdown menu
       *  or a button to open drawer.
       * As per A360, Link should be used while trying to navigate between pages.
       */}
      <Link
        to={{
          pathname: SHOPPING_CART,
          state: {
            pathname: SHOPPING_CART,
          },
        }}
        rel='nofollow'
        className='cart_label no-link-effect'>
        <Hidden xsDown>
          <Typography variant='body2'>
            {i18n.exists(CART).valueOf() ? t(CART) : CART_FALLBACK}
          </Typography>
        </Hidden>

        <Badge
          classes={{
            badge:
              cartItems === '0'
                ? 'cart_icon_header_badge_0'
                : 'cart_icon_header_badge',
          }}
          badgeContent={cartItems}
          color='primary'>
          <CART_GOLD className='cart-icon' role='img' aria-label='cart icon' />
          <ScreenReaderOnly message={`${cartItems} items in cart`} />
        </Badge>

        <ScreenReaderOnly message={`items`} />
      </Link>
    </li>
  );
};

export { CartLink };
