import { useCheckout } from '../../Checkout/hooks/CheckoutHooks';
import { EdealsSignupModal } from './EdealsSignupModal';
import useEdealsModal from './Hooks/EdealsSignupModalHooks';

/**
 * @component DealsSignup renders the EdealsSignupModal when not in checkout flow.
 */
const DealsSignup: React.FC = () => {
  const { isCheckout } = useCheckout();

  const edealsCookie = localStorage.getItem('emailSignUpLayer');

  const { RenderEdealsModal, edealsClickHandler, showEdealsModal } =
    useEdealsModal();

  return (
    <>
      {!isCheckout && edealsCookie && (
        <RenderEdealsModal
          showModal={showEdealsModal}
          nteModalCloseHandler={edealsClickHandler}>
          <EdealsSignupModal
            eDealsSignupCloseHandler={edealsClickHandler}
            modalHeaderId=''
          />
        </RenderEdealsModal>
      )}
    </>
  );
};

export { DealsSignup };
