const translationKey = 'SESSION_EXPIRATION';

const SessionInvalidConstants = {
  GENERIC_USER_ERROR: 'USR.CWXFR0130E',
  EXPIRED_ACTIVITY_TOKEN_ERROR: 'CWXBB1011E',
  INVALID_COOKIE_ERROR_KEY: '_ERR_INVALID_COOKIE',
  INVALID_COOKIE_ERROR_CODE: 'CMN1039E',
  SESSION_TIMEOUT_ERROR: 'sessionTimeout',
  SESSION_INVALID_ERROR: 'sessionInvalid',
  ACCOUNT_LOCKOUT_ERROR: '2490',
  PASSWORD_EXPIRED_ERR_CODE: '2170',
  ACCOUNT_NOT_ACTIVATED: '2450',
  PARTIAL_AUTHENTICATION_ERROR_CODE: 'CWXFR0222E',
  ACTIVITY_TOKEN_ERROR_CODE: 'CWXBB1103E',

  CART_LOCKED: '_ERR_ORDER_IS_LOCKED',
  PASSWORD_EXPIRED: '_ERR_PASSWORD_EXPIRED',
  ACTIVITY_TOKEN_ERROR_KEY: '_ERR_ACTIVITY_TOKEN',
  PARTIAL_AUTHENTICATION_ERROR_KEY: 'ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWE',
  CMC_SESSION_ERROR_KEY: 'CMC_RELOGON',
  SESSION_EXPIRATION_TITLE_MODAL: `${translationKey}.SESSION_EXPIRATION_TITLE_MODAL`,
  SIGN_IN_ALERT_MODAL: `ACCOUNT.SIGN_IN_ALERT_MODAL`,
};

const {
  GENERIC_USER_ERROR,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_KEY,
  INVALID_COOKIE_ERROR_CODE,
  SESSION_TIMEOUT_ERROR,
  SESSION_INVALID_ERROR,
  ACCOUNT_LOCKOUT_ERROR,
  PASSWORD_EXPIRED_ERR_CODE,
  ACCOUNT_NOT_ACTIVATED,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  ACTIVITY_TOKEN_ERROR_CODE,
  CART_LOCKED,
  PASSWORD_EXPIRED,
  ACTIVITY_TOKEN_ERROR_KEY,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  CMC_SESSION_ERROR_KEY,
} = SessionInvalidConstants;

const SESSION_INVALID_ERROR_CODES = [
  GENERIC_USER_ERROR,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_KEY,
  INVALID_COOKIE_ERROR_CODE,
  SESSION_TIMEOUT_ERROR,
  SESSION_INVALID_ERROR,
  ACCOUNT_LOCKOUT_ERROR,
  PASSWORD_EXPIRED_ERR_CODE,
  ACCOUNT_NOT_ACTIVATED,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  ACTIVITY_TOKEN_ERROR_CODE,
];

const SESSION_INVALID_ERROR_KEYS = [
  CART_LOCKED,
  PASSWORD_EXPIRED,
  ACTIVITY_TOKEN_ERROR_KEY,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  CMC_SESSION_ERROR_KEY,
];

export {
  SessionInvalidConstants,
  SESSION_INVALID_ERROR_CODES,
  SESSION_INVALID_ERROR_KEYS,
};
