import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductPrice } from '../../../utils/product-utility';
import { checkTruthy } from '../../../utils/utils';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { PdpPageConstants } from '../../PDP/PdpPageConstants';
import { EntryTypeCode } from '../../Widgets/Plp/ProductCard/ProductCardSection';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';

interface ISwatchablePrice {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  productPrice?: IProductPrice;
  showSavings: boolean;
  setBuyableWithNoPrice?: Dispatch<SetStateAction<boolean>>;
}

const SwatchablePrice: React.FC<ISwatchablePrice> = ({
  productCard,
  productPrice,
  showSavings,
  setBuyableWithNoPrice,
}) => {
  const { FROM, SAVE_UPTO } = ProductCardSectionMessagesConstants;

  const {
    ATTRIBUTES: { PRODUCT },
  } = PdpPageConstants;

  const { t } = useTranslation();

  const isParentChildProduct =
    productCard &&
    (productCard.type?.toLowerCase() === PRODUCT ||
      productCard.catalogEntryTypeCode === EntryTypeCode.PRODUCTBEAN);
  const noSwatchPrice = isParentChildProduct && productPrice?.swatchNoPrice;

  const noOfferPrice =
    productPrice &&
    (!productPrice.formattedOfferPrice ||
      productPrice.formattedOfferPrice.length === 0);

  const swatchableItems =
    productCard && productCard?.sKUs ? productCard?.sKUs : productCard?.items;

  const buyableWithNoPrice = useMemo(
    () =>
      (checkTruthy(productCard?.buyable) && swatchableItems
        ? noSwatchPrice
        : Boolean(noOfferPrice)) ||
      !checkTruthy(productCard?.buyable) ||
      Boolean(!productCard?.attributes),
    [
      noOfferPrice,
      noSwatchPrice,
      productCard?.attributes,
      productCard?.buyable,
      swatchableItems,
    ]
  );

  const isParentChildNoSkus = isParentChildProduct
    ? !Boolean(productCard?.items)
      ? !Boolean(productCard?.sKUs)
      : productCard.sKUs?.length === 0
      ? productCard.items?.length === 0
      : false
    : false;

  useEffect(() => {
    if (setBuyableWithNoPrice) {
      setBuyableWithNoPrice(buyableWithNoPrice);
    }
  }, [buyableWithNoPrice, setBuyableWithNoPrice]);

  return (
    <>
      {!buyableWithNoPrice && !isParentChildNoSkus && (
        <div className='price-cell'>
          {productPrice?.isProductOnSale && (
            <>
              <p className='sub-product-offer-price from-price'>{t(FROM)}</p>
              <div className='nte-price24 price'>
                {productPrice?.formattedDisplayPrice}
              </div>
            </>
          )}

          {!productPrice?.isProductOnSale && (
            <>
              <p className='sub-product-offer-price psx'>{t(FROM)}</p>
              <p className='nte-price24 price product-offer-price field-margin'>
                {productPrice?.formattedDisplayPrice}
              </p>
            </>
          )}

          {showSavings && productPrice?.isProductOnSale && (
            <p className='sub-product-offer-price save-price'>
              {t(SAVE_UPTO, { saveAmount: productPrice?.saveAmount })}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export { SwatchablePrice };
