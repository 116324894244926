import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authenticationSelector } from '../../../../redux/selectors/auth.selector';
import { productListSelector } from '../../../../redux/selectors/productList.selector';
import { useCurrentPathName } from '../../../../utils/hooks/current-pathname/CurrentPathName';
import { useWidth } from '../../../../utils/hooks/use-width/use-width';
import { ImageUrlUtility } from '../../../../utils/image-url-utility';
import { formatToTitleCase, hasKey } from '../../../../utils/utils';
import { UserType } from '../../../../_foundation/enum/User/UserType';
import { seoSelector } from '../../../Seo/redux/selector/seo';
import { SeoConstants } from '../../../Seo/SeoConstants';
import { NteCarousel } from '../../Carousel/NteCarousel';
import { CategoryCarouselConstants } from './CategoryCarouselConstants';
import { CategoryCarouselSlide } from './CategoryCarouselSlide/CategoryCarouselSlide';

/**
 * Props for Category carousel.
 */
interface Props {
  /**
   * Title for the category carousel.
   */
  title: string;

  /**
   * Category Id.
   */
  categoryID: string;
}

const TRANSLATION_CONSTANTS = {
  CATEGORIES: 'CATEGORY_CAROUSEL.CATEGORIES',
};

/**
 *
 * @returns Category Carousel Component that displays a list of categories.
 */
const CategoryCarousel = ({ title, categoryID }: Props) => {
  const { VISIBLE_SLIDES, ADVANTAGE_EXCLUSIVE, CATEGORY_FACET } =
    CategoryCarouselConstants;

  const { userType } = useSelector(authenticationSelector);

  const { categoryFacetValues, facets, subCategoriesForCarousel } =
    useSelector(productListSelector);

  const { currentPathName } = useCurrentPathName();

  const seoConfig = useSelector(seoSelector);

  const [carouselSlides, setCarouselSlides] = useState<Array<JSX.Element>>([]);

  /**
   * @method formattedPath
   * Responsible to Format the current path like "/categories/power-tools" as PowerTools
   */
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const isBrandPlp = Boolean(seoConfig[formattedPath]?.isBrandPlp);

  const width = useWidth();

  const { t } = useTranslation();

  let visibleSlides = 7;

  const { BRAND_PATH } = SeoConstants;

  if (hasKey(VISIBLE_SLIDES, width)) {
    visibleSlides = VISIBLE_SLIDES[width];
  }

  /**
   * @method countFromCategoryFacet
   * Responsible to get the count from Category Facet
   */
  const countFromCategoryFacet = useCallback(
    (facetName: string) => {
      const categoryFacetGroup = facets?.find(
        ({ name }) => name === CATEGORY_FACET
      );

      if (categoryFacetGroup) {
        const filteredFacet = categoryFacetGroup.entry.find(
          ({ label }) => label.toLowerCase() === facetName.toLowerCase()
        );

        if (filteredFacet) {
          return filteredFacet?.count;
        }

        return '0';
      }

      return '0';
    },
    [CATEGORY_FACET, facets]
  );

  const initializeCarouselSlides = useCallback(async (): Promise<void> => {
    if (facets?.length !== 0 && subCategoriesForCarousel?.length !== 0) {
      /**
       * Fetches sub category from the parent category id.
       *
       * This scenario is incase of categories plp.
       */
      if (categoryFacetValues?.length === 0 && categoryID) {
        try {
          if (subCategoriesForCarousel) {
            const filterResponse =
              userType === UserType.Advantage
                ? subCategoriesForCarousel
                : subCategoriesForCarousel.filter((data: any) => {
                  return (
                    !data.name.includes(ADVANTAGE_EXCLUSIVE)
                  );
                });

            const filteredResponse = filterResponse.filter(
              (data: any) => countFromCategoryFacet(data.name) !== '0'
            );

            const carouselDataTiles = filteredResponse.map(
              (data: any, index: number) => (
                <CategoryCarouselSlide
                  imgSrc={
                    data.thumbnailRaw
                      ? ImageUrlUtility.createImgSrc(data.thumbnailRaw)
                      : data.thumbnail
                        ? ImageUrlUtility.createImgSrc(data.thumbnail)
                        : ''
                  }
                  imgAlt={data.name}
                  description={data.name}
                  categoryUrl={`?c=${data.identifier}`}
                  slideIndex={index}
                  quantity={countFromCategoryFacet(data.name)}
                  categoryIndentifier={data.identifier}
                />
              )
            );

            setCarouselSlides(carouselDataTiles);
          }
        } catch (err) {
          throw err;
        }
      }

      /**
       * Fetches sub category from the category facet values.
       *
       * This scenario is incase of brands plp.
       */
      if (categoryFacetValues?.length !== 0 && isBrandPlp) {
        try {
          if (subCategoriesForCarousel) {
            const carouselDataTiles = subCategoriesForCarousel.map(
              (
                { name, seo, thumbnail, UserData, product_count }: any,
                index: number
              ) => (
                <CategoryCarouselSlide
                  imgSrc={
                    thumbnail ? ImageUrlUtility.createImgSrc(thumbnail) : ''
                  }
                  imgAlt={name}
                  description={name}
                  categoryUrl={seo?.href ? BRAND_PATH + seo.href : ''}
                  slideIndex={index}
                  quantity={countFromCategoryFacet(name)}
                />
              )
            );

            setCarouselSlides(carouselDataTiles);
          }
        } catch (e) {
          throw e;
        }
      }
    }
  }, [
    ADVANTAGE_EXCLUSIVE,
    BRAND_PATH,
    categoryFacetValues,
    categoryID,
    countFromCategoryFacet,
    facets,
    isBrandPlp,
    subCategoriesForCarousel,
    userType,
  ]);

  useEffect(() => {
    initializeCarouselSlides();

    return () => {
      setCarouselSlides([]);
    };
  }, [initializeCarouselSlides]);

  return (
    <>
      {carouselSlides.length !== 0 && (
        <section className='page-row category-carousel'>
          <Typography variant='h2'>
            {`${title} ${t(TRANSLATION_CONSTANTS.CATEGORIES)}`}
          </Typography>

          <NteCarousel
            className='nte-category-carousel'
            slides={carouselSlides}
            slidesToShow={
              visibleSlides < carouselSlides.length
                ? visibleSlides
                : carouselSlides.length
            }
            slidesToScroll={visibleSlides}
            ariaLabel={'category carousel'}
          />
        </section>
      )}
    </>
  );
};

export { CategoryCarousel };
