import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

/**
 * @interface IBreadcrumbsSkeleton
 */
interface IBreadcrumbsSkeleton {
  textClass?: string;
}

/**
 * @component BreadcrumbsSkeleton renders the breadcrumbs skeleton.
 *
 * @param BreadcrumbsSkeleton
 */
const BreadcrumbsSkeleton: React.FC<IBreadcrumbsSkeleton> = ({
  textClass = 'text-dark',
}) => (
  <nav className='breadcrumb' aria-label='breadcrumbs'>
    <ol
      vocab='https://schema.org/'
      typeof='BreadcrumbList'
      className='bread-crumbs-container'>
      {[0, 1, 2].map((value, index) => (
        <li
          property='itemListElement'
          typeof='ListItem'
          className={`bread-crumbs-link ${textClass}`}
          key={index}>
          {index !== 0 && (
            <Typography
              variant='body2'
              component='span'
              className='bread-crumbs-link separator'>
              &nbsp;&nbsp;/&nbsp;&nbsp;
            </Typography>
          )}

          <Typography
            component='span'
            property='name'
            tabIndex={0}
            role='link'
            className={`link-effect bread-crumbs-link ${textClass}`}>
            <Skeleton />
          </Typography>

          <Typography
            property='name'
            component='span'
            variant='body2'
            className='bread-crumbs-link disable-link'>
            <Skeleton />
          </Typography>
        </li>
      ))}
    </ol>
  </nav>
);

export { BreadcrumbsSkeleton };
