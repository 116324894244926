import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

/**
 * @component FullWidthSectionSkeleton renders the skeleton for FullWidthSection component.
 */
const FullWidthSectionSkeleton: React.FC = () => (
  <div className='cmc-block-section'>
    <Paper>
      <Typography variant='h1'>
        <Skeleton />
      </Typography>

      <Typography variant='body1'>
        <Skeleton />
      </Typography>

      <Typography variant='body1'>
        <Skeleton />
      </Typography>
    </Paper>
  </div>
);

export { FullWidthSectionSkeleton };
