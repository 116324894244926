import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 *
 * @returns Product Card Skeleton.
 */
const ProductCardSkeleton: React.FC = () => (
  <li className='product-block page-row'>
    <Card className='product-card elevate-1' role='group' aria-label='product'>
      <div className='product-image-section'>
        <Skeleton variant='rect' width={150} height={150} />
        <div className='rating-container'>
          <Skeleton animation='wave' height={20} width='50%' />
        </div>
      </div>
      {/*To remove inline style with class level style with new class Name*/}
      <div className='product-name-skeleton'>
        <Skeleton
          animation='wave'
          height={40}
          width='100%'
          className='product-name'
        />
        <p className='product-name'>
          {/*To remove inline style with class level style */}
          <Skeleton
            animation='wave'
            height={30}
            width='50%'
            style={{ marginLeft: 0 }}
          />
        </p>
        <ul className='swatch-list'>
          {[0, 1, 2].map((data, idx) => (
            <Skeleton animation='wave' height={15} width='80%' key={idx} />
          ))}
        </ul>
      </div>
    </Card>
  </li>
);

export { ProductCardSkeleton };
