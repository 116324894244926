import { AxiosRequestConfig } from 'axios';
import {
  ITaxInformationRequest,
  ITaxInformationResponse,
} from '../../../interface/TaxInformation/ITaxInformation';
import { makeRequest } from '../../axios/axiosConfig';
import { TaxInformationServiceConstants } from './tax-information.service.constants';
import { USER_IP } from '../../../constants/cookie';

const taxInformationService = {
  /**
   * @method fetchTaxInformation Fetches the tax information from the backend.
   *
   * @param ITaxInformationRequest
   */
  async fetchTaxInformation({
    storeID,
    cancelToken,
  }: ITaxInformationRequest): Promise<ITaxInformationResponse> {
    const { TAX_INFORMATION_URL } = TaxInformationServiceConstants;

    try {
      const taxInformationRequest: AxiosRequestConfig = {
        url: TAX_INFORMATION_URL(storeID),
        data: { USER_IP: sessionStorage.getItem(USER_IP) },
        cancelToken,
      };

      const taxInformationResponse: ITaxInformationResponse = await makeRequest(
        taxInformationRequest
      );

      return taxInformationResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { taxInformationService };
