import Grid from '@material-ui/core/Grid';
import React from 'react';

/**
 * This component is responsible for rendering the Header Promotion
 */
const HeaderMessage: React.FC = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div id='headerPromotion'></div>
        </Grid>
      </Grid>
    </>
  );
};

export { HeaderMessage };
