import { ClickAwayListener, Modal, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

/**
 * @interface INteModal
 */
export interface INteModal {
  /**
   * @prop children element for the modal component.
   */
  children: JSX.Element;

  /**
   * @prop modalDismissHandler Handles the modal dismiss events.
   */
  modalDismissHandler?: () => void;

  /**
   * @prop nteModalCloseHandler Handles the modal close events.
   */
  nteModalCloseHandler: () => void;

  /**
   * @prop showModal Determines whether the modal is open or not.
   */
  showModal: boolean;

  /**
   * @prop id for NteModal
   */
  id?: string;

  /**
   * @prop ariaLabelledById for NteModal.
   */
  ariaLabelledById?: string;

  /**
   * @prop ariaLabel for NteModal.
   */
  ariaLabel?: string;

  /**
   * @prop className for NteModal.
   */
  className?: string;
}

/**
 * @component NteModal renders the modal component for the Nte application.
 *
 * @param INteModal
 */
const NteModal: React.FC<INteModal> = ({
  nteModalCloseHandler,
  showModal,
  children,
  modalDismissHandler,
  ariaLabelledById,
  id,
  className,
  ariaLabel,
}) => {
  useEffect(() => {
    return () => {
      /**
       * Triggers the modal dismiss handler.
       */
      if (modalDismissHandler) {
        modalDismissHandler();
      }
    };
  });

  return (
    <Modal
      id={id}
      aria-labelledby={ariaLabelledById}
      open={showModal}
      className={`nte_modal ${className}`}
      aria-modal={showModal}
      role='dialog'
      aria-label={ariaLabel}
      onClose={nteModalCloseHandler}>
      <ClickAwayListener
        mouseEvent='onMouseDown'
        onClickAway={nteModalCloseHandler}>
        <Paper className='nte_modal-paper' square variant='elevation'>
          {children}
        </Paper>
      </ClickAwayListener>
    </Modal>
  );
};

export { NteModal };
