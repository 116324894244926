import { takeLatest } from 'redux-saga/effects';
import * as ACTIONS from '../../action-types/auth.action-types';
import * as ACTION_TYPES from '../../actions/auth.actions';
//Redux
import * as WORKERS from '../workers/auth';

/**
 * Auth watch saga
 * watchers to intercept account actions
 */
export function* watchSaga() {
  yield takeLatest(ACTIONS.LOGIN_REQUESTED, WORKERS.login);

  yield takeLatest(ACTION_TYPES.GET_USER_INFO_ACTION, WORKERS.getUserInfo);

  yield takeLatest(ACTIONS.REGISTER_USER, WORKERS.registerUser);

  yield takeLatest(
    ACTIONS.GET_USER_CONTACT_INFO,
    WORKERS.getUserContactInformation
  );

  yield takeLatest(ACTIONS.UPDATE_PASSWORD, WORKERS.changeUserPassword);

  yield takeLatest(
    ACTION_TYPES.GET_RECENT_ORDERS_ACTION,
    WORKERS.getRecentOrders
  );

  yield takeLatest(
    ACTION_TYPES.UPDATE_USER_REGISTRATION_ACTION,
    WORKERS.addUpdateCustomerNumber
  );

  yield takeLatest(ACTION_TYPES.GUEST_LOGIN_REQUEST_ACTION, WORKERS.guestLogin);
}
