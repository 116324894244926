import MenuBookIcon from '@material-ui/icons/MenuBook';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  ORDER_FROM_CATALOG as orderFromCatalog,
  REQUEST_FREE_CATALOG as RequestFreeCatalog,
} from '../../../../../constants/routes';
import { NteMenuList } from '../../../../Menu/NteMenuList/NteMenuList';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../../AppBar/NavMenuItem/NavMenuItem';
import { CatalogMenuConstants } from './CatalogMenuConstants';

/**
 * @component CatalogMenu renders the Catalog Menu
 */
const CatalogMenu: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    RenderPopper: RenderCatalogMenu,
    ntePopperClickHandler: catalogClickHandler,
    showPopper: showCatalogMenu,
  } = useNtePopper({
    id: 'catalog-menu',
    ariaLabelledById: 'catalog-menu-list',
  });

  const history = useHistory();

  const catalogRef = useRef(null);

  const items = [
    {
      item: i18n.exists(CatalogMenuConstants.REQUEST_FREE_CATALOG).valueOf()
        ? t(CatalogMenuConstants.REQUEST_FREE_CATALOG)
        : CatalogMenuConstants.REQUEST_FREE_CATALOG_FALLBACK,
      href: RequestFreeCatalog,
    },
    {
      item: i18n.exists(CatalogMenuConstants.ORDER_FROM_CATALOG).valueOf()
        ? t(CatalogMenuConstants.ORDER_FROM_CATALOG)
        : CatalogMenuConstants.ORDER_FROM_CATALOG_FALLBACK,
      href: orderFromCatalog,
    },
  ];

  const { REQUEST_FREE_CATALOG, ORDER_FROM_CATALOG } = CatalogMenuConstants;

  const menuItemClickHandler = (selectedEvent: any): void => {
    // Close the catalog when an item is clicked.
    catalogClickHandler();

    if (selectedEvent.currentItem === t(REQUEST_FREE_CATALOG)) {
      history.push(RequestFreeCatalog);
    }
    if (selectedEvent.currentItem === t(ORDER_FROM_CATALOG)) {
      history.push(orderFromCatalog);
    }
  };

  const title = i18n.exists(CatalogMenuConstants.CATALOG_TITLE).valueOf()
    ? t(CatalogMenuConstants.CATALOG_TITLE)
    : CatalogMenuConstants.CATALOG_TITLE_FALLBACK;

  return (
    <>
      <NavMenuItem
        imgAlt='catalog-menu-icon'
        className='catalog_menu'
        menuAnchor={catalogRef}
        menuIconSvg={<MenuBookIcon className='menu_icon' />}
        menuTitle={title}
        onClick={catalogClickHandler}
        disablePopperAction={showCatalogMenu}
      />

      {showCatalogMenu && (
        <RenderCatalogMenu
          showPopper={showCatalogMenu}
          ntePopperCloseHandler={catalogClickHandler}
          anchor={catalogRef}>
          <NteMenuList
            menuItems={items}
            id='catalog-menu-list'
            menuListCloseHandler={catalogClickHandler}
            menuTitle={title}
            nteMenuClickHandler={menuItemClickHandler}
          />
        </RenderCatalogMenu>
      )}
    </>
  );
};

export { CatalogMenu };
