import { IFsdData } from '../_foundation/interface/StoreLocator/IStoreLocator';

/**
 *
 * @param items - array of line items
 * @param backOrderedProducts - array of backordered partnumbers
 * @returns - array of final line items for fetching date range
 */
const checkCartItemsForDateCalculation = (
  items: IFsdData[],
  backOrderedProducts?: Array<string>,
  cartCount?: Number,
  isTruckShipMode?: boolean
) => {
  /**
   * Responsible to handle when order has backorder products
   */
  if (backOrderedProducts && !isTruckShipMode) {
    if (items.length === backOrderedProducts.length) {
      return items;
    } else {
      /**
       * Responsible to filter the item without GC
       */
      let lineItems = items.filter(
        (lineItem) => !lineItem.partNumber.toLowerCase().includes('gc')
      );

      /**
       * cart contains only back order products
       */
      if (cartCount === lineItems?.length) {
        return lineItems;
        /**
         * cart contains only back order and GC items
         */
      } else if (lineItems.length > 0 && cartCount !== lineItems?.length) {
        /**
         * order has both gc and back order products after filtering the gc items
         */
        if (backOrderedProducts?.length === lineItems?.length) {
          return lineItems;
        } else {
          const result = lineItems.filter(
            (item) =>
              !backOrderedProducts.some(
                (partNumber) => item.partNumber === partNumber
              )
          );
          return result;
        }
      } else {
        return lineItems;
      }
    }
  } else {
    let lineItems = items.filter(
      (lineItem) => !lineItem.partNumber.toLowerCase().includes('gc')
    );
    if (lineItems.length > 0) {
      return lineItems;
    } else {
      return items;
    }
  }
};

 /*
  * @method getShipValueKey takes in the shipModeId, shipModeCode, and iomStoreId to create a unique key.
  * If iomStoreId is defined, it will return a concatenated key that includes shipModeId, shipModeCode, and iomStoreId.
  * Otherwise, it will return a concatenated key with only shipModeId and shipModeCode.
  */
 const getShipValueKey = (shipModeId?: string, shipModeCode?: string, iomStoreId?: string) => {
  if(shipModeId && shipModeCode && iomStoreId) {
    return shipModeId + '_' + shipModeCode + '_' + iomStoreId;
  }
  else {
    if(shipModeId && shipModeCode) {
      return shipModeId + '_' + shipModeCode;
    }
    else {
      if (shipModeId) {
        return shipModeId;
      }
      else {
        return '';
      }
    }
  }
}

export { 
  checkCartItemsForDateCalculation,
  getShipValueKey
};
