import HTMLReactParser from 'html-react-parser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailValidator } from '../../../utils/validators/email.validation';
import { EmailDealsService } from '../../../_foundation/apis/emailDeals/emailDeals.service';
import { EmailDealsServiceConstants } from '../../../_foundation/apis/emailDeals/emailDeals.service.constants';
import { useEspot } from '../../../_foundation/apis/transaction/espot/useEspot';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { NteButton } from '../../Forms/NteButton/NteButton';
import { NteForm } from '../../Forms/React-Form/NteForm/NteForm';
import { NteInputField } from '../../Forms/React-Form/NteInputField/NteInputField';
import { useNteAlert } from '../../NteAlert/hooks/NteAlertHooks';
import { NteAlert } from '../../NteAlert/NteAlert';
import { ModalBody } from '../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../Modals/ModalHeader/ModalHeader';
import { EdealsSignupModalConstants } from './EdealsSignupModal.constants';
import { EdealsSignupModalSkeleton } from './EdealsSignupModalSkeleton/EdealsSignupModalSkeleton';

/**
 * @interface IEdealsSignupModal
 */
interface IEdealsSignupModal {
  eDealsSignupCloseHandler: () => void;

  /**
   * Modal header id
   */
  modalHeaderId: string;
}

interface IEdealsSignupForm {
  email: string;
}

/**
 * @component renders the edeals signup modal.
 */
const EdealsSignupModal: React.FC<IEdealsSignupModal> = ({
  eDealsSignupCloseHandler,
  modalHeaderId,
}) => {
  const { t } = useTranslation();

  const { espotContent, isLoading } = useEspot(
    EdealsSignupModalConstants.EDEALS_ESPOT_NAME
  );

  const { TRANSLATIONS } = EdealsSignupModalConstants;

  const [errorMessage, setErrorMessage] = useState('');

  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);

  const { mySite } = useSite();

  const { setAlert } = useNteAlert();

  const handleSubmit = async (formValues: IEdealsSignupForm) => {
    setEmailSubmitted(true);

    setErrorMessage('');

    const { email } = formValues;

    //perform the API call and wait for response
    try {
      await EmailDealsService.optinEmailDeals({
        storeId: mySite?.storeID,
        emailOptDeals: {
          mailType: EmailDealsServiceConstants.MAIL_TYPES.EDEALS,
          data: [{ name: 'email', value: email }],
        },
      });

      eDealsSignupCloseHandler();

      setAlert({
        message: t(TRANSLATIONS.TRANSLATION_KEY.EDEALS_SIGNUP_SUCCESS),
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(t(TRANSLATIONS.TRANSLATION_KEY.EDEALS_SIGNUP_ERROR));
    }
  };

  const eDealsSignupFormValidation = (values: IEdealsSignupForm) => {
    if (!values.email) {
      return { email: t(TRANSLATIONS.EDEALS_SIGNUP_EMAIL_ERROR) };
    }
    return {};
  };

  return (
    <>
      <ModalHeader
        modalCloseHandler={eDealsSignupCloseHandler}
        heading={t(TRANSLATIONS.EDEALS_MODAL_TITLE)}
        id={modalHeaderId}
      />

      <ModalBody>
        <div className='edeals-signup-body cmc-content'>
          {!isLoading ? (
            espotContent && HTMLReactParser(espotContent)
          ) : (
            <EdealsSignupModalSkeleton />
          )}

          {emailSubmitted && errorMessage && (
            <NteAlert
              messages={[errorMessage]}
              severity='error'
              showClose={false}
            />
          )}
          <NteForm
            name='edeals-signup-form'
            className='edeals-signup-form'
            onSubmit={handleSubmit}
            validate={eDealsSignupFormValidation}>
            <NteInputField {...EmailValidator.EMAIL} />
            <NteButton
              translationKey={t(TRANSLATIONS.TRANSLATION_KEY.EDEALS_SIGNUP_CTA)}
              type='submit'
              variant='contained'
              fullWidth={false}
            />
          </NteForm>
        </div>
      </ModalBody>
    </>
  );
};

export { EdealsSignupModal };
