import { createReducer } from '@reduxjs/toolkit';
import { WidgetInfoConstant } from '../../../../constants/ProductHotBarConstant';
import { ProductPageType } from '../../../../_foundation/enum/ProductPage/ProductPage';
import { IWidgetInfo } from '../../../../_foundation/interface/Seo/ISeo';
import { RESET_WIDGET_INFO, SET_WIDGET_INFO } from '../actions/widget-info';

const initWidgetInfoState: IWidgetInfo = {};

const widgetInfoReducer = createReducer(initWidgetInfoState, (builder) => {
  /**
   * SET_WIDGET_INFO
   * Fetches the widget meta data for the given category identifier for product page.
   */
  builder.addCase(SET_WIDGET_INFO, (state: IWidgetInfo, action: any) => {
    const pageContents = action?.payload?.response?.data?.contents;
    let widgetInfoMap: IWidgetInfo = {};
    for (const pageContent of pageContents) {
      if (
        pageContent?.page?.type === ProductPageType.ProductPage ||
        pageContent?.page?.type === ProductPageType.ItemPage ||
        pageContent?.page?.type === ProductPageType.VariantPage
      ) {
        const slots = pageContent?.layout?.slots;
        if (slots) {
          for (const slot of slots) {
            const widgets = slot?.widgets;
            for (const widget of widgets) {
              const emsInfo = widget?.properties;
              if (
                emsInfo?.emsType?.toLowerCase() === WidgetInfoConstant.LOCAL
              ) {
                widgetInfoMap[widget.widgetName] =
                  pageContent?.tokenExternalValue + widget?.properties?.emsName;
              } else if (
                emsInfo?.emsType?.toLowerCase() === WidgetInfoConstant.GLOBAL
              ) {
                widgetInfoMap[widget.widgetName] = widget?.properties?.emsName;
              }
            }
          }
        }
      }
    }
    return { ...state, ...widgetInfoMap };
  });

  /**
   * RESET_WIDGET_INFO
   * Fetches the widget meta data for the given category identifier for product page.
   */
  builder.addCase(RESET_WIDGET_INFO, () => {
    return { ...initWidgetInfoState };
  });
});

export { widgetInfoReducer };
