const EmailDealsServiceConstants = {
  EMAIL_OPT_IN_URL: (storeId: string) =>
    `/wcs/resources/store/${storeId}/order/email-opt-in`,
  MAIL_TYPES: {
    FOOTER: 'Footer',
    EDEALS: 'eDeals',
    CHECKOUT: 'Checkout',
    ACCOUNT_CREATION: 'AccountCreation',
    RETAIL: 'Retail',
  },
};

export { EmailDealsServiceConstants };
