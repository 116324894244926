import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RouteGuards, routes } from '../../configs/routes';
import { CHECKOUT, HOME, MY_ACCOUNT } from '../../constants/routes';
import { authenticationSelector } from '../../redux/selectors/auth.selector';
import { useRedirectAfterSignIn } from '../hooks/guard/useRedirectAfterSignIn';

/**
 * Login Guard to redirect if the user if a given route is protected.
 * @returns LoginGuard Component.
 */
const LoginGuard: React.FC = () => {
  const { pathname } = useLocation();

  const history = useHistory();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  useRedirectAfterSignIn(pathname);

  /**
   * @callback redirectUnAuthenticatedRoutes Redirects the unauthenticated users to the
   * home page when they try to open a bookmarked authenticated url or hit an authenticated url.
   */
  const redirectUnAuthenticatedRoutes = useCallback((): void => {
    const matchedRoute = routes.find((route) => route.path === pathname);

    const isInvalidRoute =
      (isUserAuthenticated &&
        matchedRoute?.guard === RouteGuards.UNAUTHENTICATED) ||
      (!isUserAuthenticated &&
        matchedRoute?.guard === RouteGuards.AUTHENTICATED);

    const isAuthenticatedRoute = [MY_ACCOUNT, CHECKOUT];

    if (isInvalidRoute && !isAuthenticatedRoute.includes(matchedRoute?.path)) {
      history.push(HOME);
    }
  }, [history, isUserAuthenticated, pathname]);

  useEffect(() => {
    redirectUnAuthenticatedRoutes();
  }, [redirectUnAuthenticatedRoutes]);

  return <></>;
};

export { LoginGuard };
