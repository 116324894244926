import { createAction } from '@reduxjs/toolkit';
import { IAssignStoreParams } from '../../_foundation/interface/StorePickup/IStorePickup';
import * as ACTION_TYPES from '../action-types/storePickup.action-types';

export const ASSIGN_STORE_ACTION = createAction<IAssignStoreParams>(
  ACTION_TYPES.ASSIGN_STORE
);

export const DELIVERY_ASSIGN_STORE_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.DELIVERY_ASSIGN_STORE_SUCCESS
);

export const PAYMENT_ASSIGN_STORE_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.PAYMENT_ASSIGN_STORE_SUCCESS
);
