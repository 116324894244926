import { takeLatest } from 'redux-saga/effects';
import { FETCH_ORDER_LOOKUP_REQUEST_ACTION } from '../../actions/orderLookUp.action';
import { fetchOrderLookUp } from '../workers/orderLookUp.workers';

export function* watchSaga() {
  yield takeLatest(
    FETCH_ORDER_LOOKUP_REQUEST_ACTION,
    fetchOrderLookUp
  );
}
