import { useEffect, useState } from 'react';
import { useSite } from '../../../hooks/usesite/useSite';
import { eSpotService } from './eSpot.service';

/**
 *
 * @param espotName - Name of the Espot.
 * @returns Custom UseEspot Hook that can be plugged in to display espot content.
 */
const useEspot = (espotName: string) => {
  const { mySite } = useSite();

  const [espotContent, setEspotContent] = useState<any>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [hideEspot, setHideEspot] = useState<boolean>(false);

  useEffect(() => {
    if (mySite) {
      setIsLoading(true);
      const parameters: any = {
        storeID: mySite?.storeID,
        name: espotName,
        catalogId: mySite?.catalogID,
        $queryParameters: {
          DM_ReturnCatalogGroupId: true,
          DM_ReturnCatalogEntryId: true,
        },
      };

      eSpotService
        .fetchEspotData(parameters)
        .then((espotResponse: any) => {
          const espotContent =
            espotResponse?.MarketingSpotData[0]
              ?.baseMarketingSpotActivityData[0]?.marketingContentDescription[0]
              .marketingText;
          setEspotContent(espotContent);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          setHideEspot(true);

          console.error(err);
        });
    }
  }, [espotName, mySite]);

  return { espotContent, isLoading, hideEspot };
};

export { useEspot };
