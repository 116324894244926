import { AxiosRequestConfig, CancelToken } from 'axios';
import { USER_CONTRACT } from '../../constants/cookie';
import { makeRequest } from '../axios/axiosConfig';
import { PricingServiceConstants } from './pricing.service.constants';

/**
 * @interface IPricingPayload
 */
export interface IPricingPayload {
  storeID: string;
  partNumbers: Array<string>;
  cancelToken?: CancelToken;
}

const PricingService = {
  /**
   * @method fetchPricingDetails fetches the offer pricing details for products with an extended offer flag
   *
   * @param storeID - store id from my site.
   * @param partNumbers - part number of each product
   * @param cancelToken - To cancel request if user has navigated to another page.
   */
  async fetchPricingDetails({
    storeID,
    partNumbers,
    cancelToken,
  }: IPricingPayload): Promise<any> {
    const { PRICING_URL } = PricingServiceConstants;

    let queryParams = new URLSearchParams();

    partNumbers.forEach((partNumber) => {
      partNumber && queryParams.append('partNumber', partNumber);
    });

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    if (userContractId) {
      queryParams.append('contractId', userContractId);
    }

    try {
      const request: AxiosRequestConfig = {
        url: PRICING_URL(storeID),
        params: queryParams,
        method: 'GET',
        cancelToken,
      };

      const response = await makeRequest(request);

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};

export { PricingService };
