import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the phone number input field.
 */
const PhoneNumberValidator = {
  PHONE_NUMBER: {
    name: 'phoneNumber',
    id: 'phone-number',
    label: 'Phone Number*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Phone number is required.'),
      (value: string) =>
        FieldValidations.PHONE_NUMBER_US(
          value,
          'Please Enter a valid Phone number.'
        )
    ),
    fieldParse: FieldParsing.PHONE_NUMBER_US,
    sensitiveData: true,
  },
};

/**
 * Validator for the phone number outside US input field.
 */
const PhoneNumberOutsideUSValidator = {
  PHONE_NUMBER_OUTSIDE_US: {
    name: 'phoneOutsideUS',
    id: 'phone-number-non-us',
    label: 'Phone Number*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'Phone number is required.'),
      (value: string) =>
        FieldValidations.PHONE_NUMBER_OUTSIDE_US(
          value,
          'Please Enter a valid Phone number.'
        )
    ),
    fieldParse: FieldParsing.PHONE_NUMBER_OUTSIDE_US,
    disableAutoComplete: true,
    sensitiveData: true,
  },
};

export { PhoneNumberValidator, PhoneNumberOutsideUSValidator };
