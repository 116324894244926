import { useRef } from 'react';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../_foundation/interface/Responses/ICartProductsResponse';
import useInViewPort from '../../ProductOffer/ViewPort/useInViewPort';
import { useBadge } from '../ProductCard/ProductCardSectionDescription/BadgeHooks';
import { useReviewCount } from '../ProductCard/ProductCardSectionDescription/ReviewCount';
import { Badge, BadgeProps } from '../../Badge/Badge';

export interface PlpBadgeProps {
  productCard: IProductListContents | ICartContentsItem;
  index: number;
  sortIndex?: number;
}

/**
 * This component is used to display the product badges for Product Cards
 * @returns
 */
const PlpBadge = ({ productCard, index, sortIndex }: PlpBadgeProps) => {
  const viewportRefData = useRef<HTMLDivElement>(null);

  const { inViewport } = useInViewPort(viewportRefData);

  const { productReview: reviewCount, averageRating } = useReviewCount({
    partNumber: productCard?.partNumber,
    inViewport,
  });

  const { badgeSequence, parentChildPdpBadgeSequence } = useBadge(
    productCard,
    index as number,
    reviewCount,
    averageRating,
    false,
    sortIndex
  );

  const parentChildData =
    productCard && productCard.sKUs && productCard.sKUs.length > 0;

  const plpBadgeSequence = parentChildData
    ? parentChildPdpBadgeSequence
    : badgeSequence;

  return (
    <>
      {plpBadgeSequence && plpBadgeSequence.length > 0 && (
        <div className='badges'>
          {plpBadgeSequence
            .slice(0, 3)
            .map((badge: BadgeProps, index: number) => {
              if (badge.label instanceof Array) {
                return badge.label.map((label, index1) => {
                  return <Badge key={index1} label={label} />;
                });
              } else {
                return <Badge key={index} label={badge.label} />;
              }
            })}
        </div>
      )}
    </>
  );
};

export { PlpBadge };
