export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const SHOW_FIND_STORE = 'SHOW_FIND_STORE';
export const STORE_LOADER = 'STORE_LOADER';
export const SEARCH_STORE = 'SEARCH_STORE';
export const SEARCH_STORE_SUCCESS = 'SEARCH_STORE_SUCCESS';
export const SHOW_STORE_FINDER = 'SHOW_STORE_FINDER';
export const STORE_SEARCH_LOADER = 'STORE_SEARCH_LOADER';
export const RESET_STORE_SEARCH = 'RESET_STORE_SEARCH';
export const MAKE_MY_STORE = 'MAKE_MY_STORE';
export const RESET_MY_STORE = 'RESET_MY_STORE';
export const STORE_PICKUP_INVENTORY_REQUEST = 'STORE_PICKUP_INVENTORY_REQUEST';
export const STORE_PICKUP_INVENTORY_SUCCESS = 'STORE_PICKUP_INVENTORY_SUCCESS';
export const STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST =
  'STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST';
export const STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS =
  'STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS';
export const SET_ANCHOR_STORE_NAME = 'SET_ANCHOR_STORE_NAME';
export const GET_US_STORES = 'GET_US_STORES';
export const GET_US_STORES_SUCCESS = 'GET_US_STORES_SUCCESS';
export const FETCH_STORES_LOADER = 'FETCH_STORES_LOADER';
export const UPDATE_STORE_PICKUP_LOADING_STATE =
  'UPDATE_STORE_PICKUP_LOADING_STATE';
export const FETCH_STORES_FAILURE = 'FETCH_STORES_FAILURE';
export const FETCH_SEARCH_STORES_FAILURE = 'FETCH_SEARCH_STORES_FAILURE';
export const FETCH_STORES_THROWS_500 = 'FETCH_STORES_THROWS_500';
export const RESET_SEARCH_STORES_FAILURE = 'RESET_SEARCH_STORES_FAILURE';
export const SET_MAKE_MY_STORE_SPINNER = 'SET_MAKE_MY_STORE_SPINNER';
