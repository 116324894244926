// Constants for Email Deals.
const SocialMediaLinksConstants = {
  TRANSLATION_CONSTANTS: {
    CONNECT_WITH_US: 'FOOTER.SOCIAL_MEDIA.CONNECT_WITH_US',
    CONNECT_WITH_US_FALLBACK: 'Connect with Us',
  },
  LINKS: {
    FACEBOOK: 'https://www.facebook.com/northerntool',
    YOUTUBE: 'https://www.youtube.com/user/NorthernToolCo',
    INSTAGRAM: 'https://www.instagram.com/northern_tool/',
    PINTEREST: 'https://www.pinterest.com/northerntool/',
    TWITTER: 'https://twitter.com/NorthernTool',
  },
};

export { SocialMediaLinksConstants };
