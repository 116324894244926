import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PWA_INSTALL_TEALIUM,
  PWA_LAUNCHED_TEALIUM,
} from '../../../constants/Tealium';
import {
  getLocalStorage,
  isLaunchedAsPwa,
  sendTealiumData,
  setLocalStorage,
} from '../../../utils/utils';
import { NteButton } from '../../Forms/NteButton/NteButton';
import Logo from '../Logo';
import { PwaConstants } from './PwaInstallPromptConstants';

const PWA_TRANSLATION_CONSTANTS = {
  ADD_APP: 'PWA_PROMPT.ADD_APP',
};

/**
 *
 * @returns PwaInstalLPrompt Component.
 */
const PwaInstallPrompt: React.FC = () => {
  const { resolutionPx, PWA_INSTALL_ACCEPTED, IS_PWA_BANNER_DISMISSED } =
    PwaConstants;
  const [pwaInstallPrompt, setPwaInstallPrompt] = useState<any>();

  const [isPWABannerDismissed, setIsPWABannerDismissed] = useState<boolean>(
    Boolean(getLocalStorage(IS_PWA_BANNER_DISMISSED))
  );

  const { t } = useTranslation();

  // Prompt the banner for the devices having screen width higher than tablet
  const installAppPrompt = useCallback(() => {
    if (window.screen.width > resolutionPx) {
      window.addEventListener('beforeinstallprompt', (e: any) => {
        setPwaInstallPrompt(e);
      });
    }
    if (isLaunchedAsPwa) {
      sendTealiumData({
        tealium_event: PWA_LAUNCHED_TEALIUM,
      });
    }
  }, [resolutionPx]);

  useEffect(() => {
    if (!isPWABannerDismissed) {
      installAppPrompt();
    }
  }, [installAppPrompt, isPWABannerDismissed]);

  const installApp = () => {
    pwaInstallPrompt.prompt();

    pwaInstallPrompt.userChoice.then((choiceResult: { outcome: string }) => {
      if (choiceResult.outcome === PWA_INSTALL_ACCEPTED) {
        sendTealiumData({
          tealium_event: PWA_INSTALL_TEALIUM,
        });
      }
    });
  };

  return (
    <>
      {!isPWABannerDismissed && (
        <>
          {pwaInstallPrompt && (
            <div
              className='pwa-prompt-container'
              role='region'
              aria-label='Add to Home Screen'>
              <div className='inner-container'>
                <IconButton
                  onClick={() => {
                    setLocalStorage(IS_PWA_BANNER_DISMISSED, true);
                    setIsPWABannerDismissed(true);
                  }}
                  className='cursor-pointer pwa-close-icon'
                  aria-label='close'>
                  <CloseIcon />
                </IconButton>
                <Logo
                  className='logo'
                  role='img'
                  aria-label='nteLogoIconTitle nteLogoIconDesc'
                />
                <NteButton
                  translationKey={t(PWA_TRANSLATION_CONSTANTS.ADD_APP)}
                  variant='text'
                  onClick={installApp}
                  className='add-app-text'
                  type='button'
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { PwaInstallPrompt };
