import { useCallback, useEffect } from 'react';

const useRedirectToSection = (loading: boolean) => {
  const redirectToHash = useCallback((): void => {
    if (!loading) {
      const urlHash = window.location.hash;
      if (urlHash.length) {
        const element = document.getElementById(urlHash.substring(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [loading]);
  useEffect(() => {
    redirectToHash();
  }, [redirectToHash]);
};

export { useRedirectToSection };
