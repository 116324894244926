import { createReducer, current } from '@reduxjs/toolkit';
import { clearStoreSession, setStoreSession } from '../../utils/utils';
import { NEAR_BY_STORES } from '../../_foundation/constants/cookie';
import { ICartSpinner } from '../../_foundation/interface/Cart/IOrder';
import {
  IDeliveryOptionAndStorePickUp,
  IStoreLocatorFailState,
  IStoreLocatorSearchState,
  IStoreLocatorState,
  PhysicalStoreDetails,
  StoreAttribute,
  StoreDetails,
} from '../../_foundation/interface/StoreLocator/IStoreLocator';
import { SHOW_FIND_STORE } from '../action-types/storeLocator';
import {
  FETCH_SEARCH_STORES_FAILURE_ACTION,
  FETCH_STORES_FAILURE_ACTION,
  FETCH_STORES_LOADER_ACTION,
  FETCH_STORES_THROWS_500_ACTION,
  GET_STORES_SUCCESS_ACTION,
  GET_US_STORES_SUCCESS_ACTION,
  MAKE_MY_STORE_ACTION,
  RESET_MY_STORE_ACTION,
  RESET_SEARCH_STORES_FAILURE_ACTION,
  RESET_STORE_SEARCH_ACTION,
  SEARCH_STORE_SUCCESS_ACTION,
  SET_ANCHOR_STORE_NAME_ACTION,
  SET_MAKE_MY_STORE_SPINNER_ACTION,
  SHOW_STORE_FINDER_ACTION,
  STORE_LOADER_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
  STORE_PICKUP_INVENTORY_SUCCESS_ACTION,
  STORE_SEARCH_LOADER_ACTION,
  UPDATE_STORE_PICKUP_LOADING_STATE_ACTION,
} from '../actions/storeLocator';

const initStoreLocatorState: IStoreLocatorState = {
  storeDetails: [],
  loading: true,
  showFindStore: false,
  currentStoreDetails: undefined,
  isNoStoresAvailable: false,
  currentAnchorStoreName: '',
  usStoreDetails: [],
  fetchStoresLoading: false,
  dmEspotRegion: '',
  isInventoryFailed: false,
  makeMyStoreSpinner: {},
};

const initStoreLocatorSearchState: IStoreLocatorSearchState = {
  searchLoader: false,
  searchedStoreDetails: [],
};

const initDeliveryOptionAndStorePickUp: IDeliveryOptionAndStorePickUp = {
  storePickUpDetails: [],
  deliveryOptionDetails: [],
  loading: true,
  isNoStoreAvailable: false,
};

const initStoreLocatorFailState: IStoreLocatorFailState = {
  isInventoryFailed: false,
  isStoreLocatorApiThrow500: false,
};

/**
 * @method storeLocatorReducer Takes care of state changes for the store details api.
 */
const storeLocatorReducer = createReducer(initStoreLocatorState, (builder) => {
  builder.addCase(
    GET_STORES_SUCCESS_ACTION,
    (state: IStoreLocatorState, action: any) => {
      /**
       * hasPhysicalStore is used to determine
       * whether the response obtained is valid.
       */
      const hasPhysicalStore: boolean = action.payload.PhysicalStore
        ? action.payload.PhysicalStore.length > 0
        : false;

      if (hasPhysicalStore) {
        const storeDetails: PhysicalStoreDetails[] = [
          ...action.payload.PhysicalStore,
        ];

        const { currentAnchorStoreName } = current(state);

        const anchorStoreIndex: number = storeDetails.findIndex(
          (displayStoreName) =>
            displayStoreName.storeName === currentAnchorStoreName
        );

        if (anchorStoreIndex !== -1) {
          const anchorStore: PhysicalStoreDetails = storeDetails.splice(
            anchorStoreIndex,
            1
          )[0];

          storeDetails.unshift(anchorStore);
        }
        const loading = !action.payload.PhysicalStore;

        sessionStorage.setItem(NEAR_BY_STORES, JSON.stringify(storeDetails));

        return {
          ...state,
          storeDetails,
          loading,
          isNoStoresAvailable: false,
          isInventoryFailed: false,
        };
      }

      return {
        ...state,
        isNoStoresAvailable: true,
        showFindStore: true,
        loading: false,
        searchLoader: false,
        isInventoryFailed: false,
      };
    }
  );

  builder.addCase(
    GET_US_STORES_SUCCESS_ACTION,
    (state: IStoreLocatorState, action: any) => {
      const { PhysicalStore } = action.payload as StoreDetails;

      if (PhysicalStore) {
        const hasUSStores: boolean = action.payload.PhysicalStore
          ? PhysicalStore.length > 0
          : false;

        if (hasUSStores) {
          const usStoreDetails: PhysicalStoreDetails[] = [...PhysicalStore];

          const loading = !PhysicalStore;

          return {
            ...state,
            usStoreDetails,
            loading,
            isNoStoresAvailable: false,
          };
        }
      }

      return {
        ...state,
        isNoStoresAvailable: true,
        showFindStore: true,
        loading: false,
        searchLoader: false,
      };
    }
  );

  builder.addCase(SHOW_FIND_STORE, (state: IStoreLocatorState, action: any) => {
    return { ...state, loading: false, showFindStore: true };
  });

  builder.addCase(
    STORE_LOADER_ACTION,
    (state: IStoreLocatorState, action: any) => {
      return {
        ...state,
        loading: action.payload?.loading,
      };
    }
  );

  builder.addCase(
    SHOW_STORE_FINDER_ACTION,
    (state: IStoreLocatorState, action: any) => {
      return { ...state, showFindStore: action.payload };
    }
  );

  builder.addCase(
    MAKE_MY_STORE_ACTION,
    (state: IStoreLocatorState, action: any) => {
      const currentStoreDetails = action.payload;

      const regionAd: StoreAttribute[] =
        currentStoreDetails &&
        currentStoreDetails.Attribute &&
        currentStoreDetails.Attribute.filter(
          (attribute: StoreAttribute) => attribute.name === 'adRegion'
        );

      const dmEspotRegion = regionAd && regionAd[0]?.value.toUpperCase();

      setStoreSession(currentStoreDetails);

      return {
        ...state,
        currentStoreDetails,
        dmEspotRegion,
        currentAnchorStoreName: currentStoreDetails?.storeName,
      };
    }
  );

  builder.addCase(
    RESET_MY_STORE_ACTION,
    (state: IStoreLocatorState, action: any) => {

      clearStoreSession();

      return {
        ...state,
        ...initStoreLocatorState,
        currentStoreDetails: undefined,
        showFindStore: true,
        loading: false,
        isNoStoresAvailable: true,
        isInventoryFailed: false,
      };
    }
  );

  builder.addCase(
    SET_MAKE_MY_STORE_SPINNER_ACTION,
    (state: IStoreLocatorState, action: any) => {
      const { loading, partNumber } = action.payload as ICartSpinner;

      const { makeMyStoreSpinner } = current(state);

      let currentMakeMyStoreSpinner = { ...makeMyStoreSpinner };

      if (!loading && currentMakeMyStoreSpinner[partNumber]) {
        delete currentMakeMyStoreSpinner[partNumber];
      } else {
        currentMakeMyStoreSpinner = {
          ...currentMakeMyStoreSpinner,
          [partNumber]: loading,
        };
      }

      return {
        ...state,
        makeMyStoreSpinner: currentMakeMyStoreSpinner,
      };
    }
  );

  builder.addCase(
    SET_ANCHOR_STORE_NAME_ACTION,
    (state: IStoreLocatorState, action: any) => {
      const currentAnchorStoreName = action.payload;

      const { storeDetails } = current(state);

      const currentStoreDetails = storeDetails && [...storeDetails];

      const anchorStoreIndex: number =
        currentStoreDetails &&
        currentStoreDetails?.findIndex(
          (displayStoreName) =>
            displayStoreName.storeName === currentAnchorStoreName
        );

      if (anchorStoreIndex !== -1 && currentStoreDetails) {
        const anchorStore: PhysicalStoreDetails = currentStoreDetails?.splice(
          anchorStoreIndex,
          1
        )[0];

        currentStoreDetails.unshift(anchorStore);
      }

      return {
        ...state,
        currentAnchorStoreName,
        storeDetails: currentStoreDetails,
      };
    }
  );

  builder.addCase(
    FETCH_STORES_LOADER_ACTION,
    (state: IStoreLocatorState, action: any) => {
      return { ...state, fetchStoresLoading: action.payload };
    }
  );

  builder.addCase(
    FETCH_STORES_FAILURE_ACTION,
    (state: IStoreLocatorState, action: any) => {
      return { ...state, isInventoryFailed: action.payload };
    }
  );

  builder.addCase(
    UPDATE_STORE_PICKUP_LOADING_STATE_ACTION,
    (state: IStoreLocatorState, action: any) => {
      return { ...state, loading: action.payload };
    }
  );
});

/**
 * @method storeLocatorSearchReducer Takes care of state changes for the search store details api.
 */
const storeLocatorSearchReducer = createReducer(
  initStoreLocatorSearchState,
  (builder) => {
    builder.addCase(
      SEARCH_STORE_SUCCESS_ACTION,
      (state: IStoreLocatorSearchState, action: any) => {
        if (action.payload) {
          /**
           * hasPhysicalStore is used to determine
           * whether the response obtained is valid.
           */
          const hasPhysicalStore: boolean = action.payload.PhysicalStore
            ? action.payload.PhysicalStore.length > 0
            : false;

          if (hasPhysicalStore) {
            const { PhysicalStore } = action.payload;

            const searchedStoreDetails: PhysicalStoreDetails[] = [
              ...PhysicalStore,
            ];

            const searchLoader = !action.payload.PhysicalStore;

            return {
              searchedStoreDetails,
              searchLoader,
            };
          } else {
            return { ...initStoreLocatorSearchState };
          }
        }
      }
    );

    builder.addCase(
      STORE_SEARCH_LOADER_ACTION,
      (state: IStoreLocatorSearchState, action: any) => {
        return { ...state, searchLoader: action.payload };
      }
    );

    builder.addCase(
      FETCH_SEARCH_STORES_FAILURE_ACTION,
      (state: IStoreLocatorSearchState, action: any) => {
        return { ...state, searchStoreFailed: action.payload };
      }
    );

    builder.addCase(RESET_STORE_SEARCH_ACTION, () => {
      return { ...initStoreLocatorSearchState };
    });
  }
);

const deliveryOptionAndStorePickUpReducer = createReducer(
  initDeliveryOptionAndStorePickUp,
  (builder) => {
    /**
     * @action STORE_PICKUP_INVENTORY_SUCCESS_ACTION
     * Fetches all the store Pickup inventory details for a order
     */
    builder.addCase(
      STORE_PICKUP_INVENTORY_SUCCESS_ACTION,
      (state: IDeliveryOptionAndStorePickUp, action: any) => {
        const { PhysicalStore } = action.payload;
        if (PhysicalStore.length > 0) {
          return {
            ...initDeliveryOptionAndStorePickUp,
            storePickUpDetails: PhysicalStore,
            loading: false,
          };
        } else {
          return {
            ...initDeliveryOptionAndStorePickUp,
            isNoStoreAvailable: true,
            loading: false,
          };
        }
      }
    );

    /**
     * @action STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION
     * Fetches all the store Pickup inventory details for a order
     */
    builder.addCase(
      STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
      (state: IDeliveryOptionAndStorePickUp, action: any) => {
        const { PhysicalStore } = action.payload;
        if (PhysicalStore) {
          return {
            ...initDeliveryOptionAndStorePickUp,
            storePickUpDetails: PhysicalStore,
            loading: false,
          };
        }
      }
    );
  }
);

/**
 * @action FETCH_SEARCH_STORES_FAILURE_ACTION
 * responsible to detect the store locator api failure
 */
const storeLocatorFailReducer = createReducer(
  initStoreLocatorFailState,
  (builder) => {
    builder.addCase(
      FETCH_SEARCH_STORES_FAILURE_ACTION,
      (state: IStoreLocatorFailState, action: any) => {
        return {
          ...state,
          isInventoryFailed: action.payload,
        };
      }
    );

    /**
     * @action FETCH_STORES_THROWS_500_ACTION
     * responsible to detect the store locator api throws 500
     */
    builder.addCase(
      FETCH_STORES_THROWS_500_ACTION,
      (state: IStoreLocatorFailState, action: any) => {
        return {
          ...state,
          isStoreLocatorApiThrow500: action.payload,
        };
      }
    );

    /**
     * @action RESET_SEARCH_STORES_FAILURE_ACTION
     * responsible to reset the store locator failure state
     */
    builder.addCase(RESET_SEARCH_STORES_FAILURE_ACTION, () => {
      return { ...initStoreLocatorFailState };
    });
  }
);

export {
  storeLocatorReducer,
  storeLocatorSearchReducer,
  deliveryOptionAndStorePickUpReducer,
  storeLocatorFailReducer,
};
