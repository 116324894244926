import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CHECKOUT_REVIEW,
  ORDER_CONFIRMATION,
  SHOPPING_CART,
} from '../../../constants/routes';
import { orderSelector } from '../../../redux/selectors/order.selector';
import { orderConfirmationSelector } from '../../../redux/selectors/orderConfirmation.selector';
import { IProductPrice } from '../../../utils/product-utility';
import { checkoutReviewSelector } from '../../Checkout/redux/selectors/checkoutReview.selector';
import { Badge } from '../../Widgets/Badge/Badge';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { ProductPricingConstants } from '../../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricing.constants';
import { formatPrice } from '../../../utils/utils';

interface IFreeItem {
  productPrice?: IProductPrice;
}

const FreeItem: React.FC<IFreeItem> = ({ productPrice }) => {
  const { FREE } = ProductCardSectionMessagesConstants;

  const { REGULAR, SAVE } = ProductPricingConstants;

  const { t } = useTranslation();

  return (
    <div className='price-cell'>
      <p className='nte-price24 price color-green'>{t(FREE)}</p>

      <Grid
        container
        className='sub-product-offer-price pxs reg-save-pricing'
        justifyContent='space-around'
        alignItems='center'>
        <span className='strike-price'>
          {REGULAR}{' '}
          {formatPrice(
            productPrice?.unitPrice ? productPrice.unitPrice.toString() : ''
          )}
        </span>

        <Badge
          className='save-price-badge'
          label={`${SAVE} ${formatPrice(
            productPrice?.unitPrice ? productPrice?.unitPrice.toString() : ''
          )}`}
        />
      </Grid>
    </div>
  );
};

const useFreeItem = (partNumber: string) => {
  const { freeItems: reviewFreeItems } = useSelector(checkoutReviewSelector);

  const { freeItems: cartFreeItems } = useSelector(orderSelector);

  const { freeItems: orderConfirmationFreeItems } = useSelector(
    orderConfirmationSelector
  );

  const history = useHistory();

  const isFreeItem = useMemo(() => {
    if (history.location.pathname === CHECKOUT_REVIEW) {
      return reviewFreeItems[partNumber];
    } else if (history.location.pathname === SHOPPING_CART) {
      return cartFreeItems[partNumber];
    } else if (history.location.pathname === ORDER_CONFIRMATION) {
      return orderConfirmationFreeItems[partNumber];
    }
    return false;
  }, [
    cartFreeItems,
    history.location.pathname,
    orderConfirmationFreeItems,
    partNumber,
    reviewFreeItems,
  ]);

  return { isFreeItem, FreeItem };
};

export { FreeItem, useFreeItem };
