import React from 'react';
import { useTranslation } from 'react-i18next';
import { enterKeyPressHandler } from '../../../utils/utils';
import { IAssociatedPromotion } from '../../../_foundation/interface/ProductList/IProductList';
import { useNteModal } from '../../NteModal/hooks/NteModalHooks';
import { PromoCodeConstants } from '../ShoppingCart/PromoCode/PromoCodeConstants';
import { SeeDetails } from '../ShoppingCart/PromoCode/SeeDetails';

/**
 * @interface IProductPromoDescription
 */
interface IProductPromoDescription {
  promo: IAssociatedPromotion;
}

/**
 * @component ProductPromoDescription
 * This component is responsible for showing the see
 * details button on click of description for promocode.
 */
const ProductPromoDescription: React.FC<IProductPromoDescription> = ({
  promo,
}) => {
  const {
    RenderModal: RenderSeeDetails,
    nteModalClickHandler: seeDetailsClickHandler,
    showNteModal: showSeeDetailsModal,
  } = useNteModal({
    ariaLabelledById: 'see-details',
    id: 'see-details-modal',
  });
  const { PROMOCODE } = PromoCodeConstants;
  const { t } = useTranslation();

  return (
    <>
      <span
        tabIndex={0}
        role='link'
        className='link-effect'
        onClick={seeDetailsClickHandler}
        onKeyDown={(e) => enterKeyPressHandler(e, seeDetailsClickHandler)}>
        {t(PROMOCODE.SEE_DETAILS)}
      </span>
      {showSeeDetailsModal && (
        <RenderSeeDetails
          showModal={showSeeDetailsModal}
          nteModalCloseHandler={seeDetailsClickHandler}>
          <SeeDetails
            shortDescription={promo?.description?.shortDescription}
            seeDetailsCloseHandler={seeDetailsClickHandler}
            longDescription={promo?.description?.longDescription}
            promoCode={promo?.code}
          />
        </RenderSeeDetails>
      )}
    </>
  );
};

export { ProductPromoDescription };
