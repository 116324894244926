import { createReducer } from '@reduxjs/toolkit';
import { SuggestTypeSuggestionView } from '../../_foundation/interface/SearchSiteContent/ISearchSuggestionContent';
import { FETCH_SUGGEST_TYPE_SUCCESS_ACTION } from '../actions/suggestion.actions';

const initSuggestionType: SuggestTypeSuggestionView = {
  suggestionView: [],
  resourceHandler: {
    resourceName: '',
  },
};

const suggestionReducer = createReducer(initSuggestionType, (builder) => {
  /**
   * @action FETCH_SUGGEST_TYPE_SUCCESS_ACTION
   * Fetches all the suggestType from the worker and then caches it in the redux.
   */

  builder.addCase(
    FETCH_SUGGEST_TYPE_SUCCESS_ACTION,
    (state: SuggestTypeSuggestionView, action: any) => {
      const { suggestionView, resourceHandler } = action.payload;

      if (suggestionView) {
        return {
          suggestionView: suggestionView,
          resourceHandler: resourceHandler,
        };
      }
    }
  );
});

export { suggestionReducer };
