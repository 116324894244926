import React from 'react';
import ChatLink from './ChatLink/ChatLink';
import CheckoutStepper from './CheckoutStepper/CheckoutStepper';

/**
 * @component renders the header for the checkout page.
 */
const CheckoutHeader: React.FC = () => {
  return (
    <>
      <div className='checkout-header'>
        <CheckoutStepper />
      </div>
      <ChatLink />
    </>
  );
};

export default CheckoutHeader;
