import { createAction } from '@reduxjs/toolkit';
import { IFetchProduct } from '../../_foundation/apis/search/products/products.service';
import * as ACTION_TYPES from '../action-types/orderDetails.action-types';

export const FETCH_CART_ITEM_DETAILS_REQUEST_ACTION =
  createAction<IFetchProduct>(ACTION_TYPES.FETCH_CART_ITEM_DETAILS_REQUEST);

export const FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.FETCH_CART_ITEM_DETAILS_SUCCESS
);

export const RESET_FETCH_CART_ITEM_DETAILS_ACTION = createAction(
  ACTION_TYPES.RESET_FETCH_CART_ITEM_DETAILS
);
