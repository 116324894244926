import { createReducer } from '@reduxjs/toolkit';
import {
  RESET_SEARCH_KEYWORD_ACTION,
  UPDATE_SEARCH_KEYWORD_ACTION,
} from '../actions/search.actions';

/**
 * @interface ISearchState
 */
export interface ISearchState {
  searchKeyword: string;
}

const initSearchState: ISearchState = {
  searchKeyword: '',
};

const searchReducer = createReducer(initSearchState, (builder) => {
  builder.addCase(UPDATE_SEARCH_KEYWORD_ACTION, (_, action: any) => {
    return {
      searchKeyword: action.payload,
    };
  });

  builder.addCase(RESET_SEARCH_KEYWORD_ACTION, (state: ISearchState) => {
    return { ...state };
  });
});

export { searchReducer };
