import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuListHeader } from '../../../../Menu/MenuListHeader/MenuListHeader';
import { SignInForm } from '../SignInForm/SignInForm';
import { SignInAccountMenuConstants } from './SignInAccountMenuConstants';

interface Props {
  /**
   * @prop accountMenuCloseHandler handles the
   * popper component close events.
   */
  accountMenuCloseHandler: () => void;
}

/**
 * @component SignInAccountMenu is responsible for rendering
 * Account Menu that holds the SignIn form component.
 */
const SignInAccountMenu: React.FC<Props> = ({ accountMenuCloseHandler }) => {
  const { SIGN_IN_TITLE } = SignInAccountMenuConstants;

  const { t } = useTranslation();

  return (
    <div className='sign_in_account_menu'>
      <MenuListHeader
        closeHandler={accountMenuCloseHandler}
        menuTitle={t(SIGN_IN_TITLE)}
        id='sign-in-header'
      />
      <SignInForm signInFormCloseHandler={accountMenuCloseHandler} />
    </div>
  );
};

export { SignInAccountMenu };
