import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RESET_BREADCRUMB_ACTION } from '../../../../../redux/actions/breadcrumb.actions';
import { RESET_PRODUCT_LIST_ACTION } from '../../../../../redux/actions/productList.actions';
import { useCategoryFacetParam } from '../../../../../utils/hooks/category-facet-param/category-facet-param';
import { ScreenReaderOnly } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { Thumbnail } from '../../../Thumbnail/Thumbnail';

/**
 * @interface Props for Category Carousel Slide.
 */
interface Props {
  /**
   * Image Url for Category Slide.
   */
  imgSrc: string;

  /**
   * Category Description.
   */
  description: string;

  /** Alternate Image Description */
  imgAlt: string;

  /** Url for the Sub Category */
  categoryUrl: string;

  /** Url for the Sub Category */
  slideIndex: number;

  quantity?: string;

  categoryIndentifier?: string;
}

/**
 *
 * @param CategorySlideProps.
 * @returns CategoryCarouselSlide
 */
const CategoryCarouselSlide = ({
  imgSrc,
  description,
  imgAlt,
  categoryUrl,
  slideIndex,
  quantity,
  categoryIndentifier,
}: Props) => {
  const dispatch = useDispatch();

  const { setCategoryFacetParam } = useCategoryFacetParam();

  const slideClickHandler = (e: any): void => {
    e.preventDefault();

    if (categoryIndentifier) {
      dispatch(RESET_PRODUCT_LIST_ACTION({}));

      dispatch(RESET_BREADCRUMB_ACTION());

      setCategoryFacetParam({
        checked: true,
        currentValue: categoryIndentifier,
      });
    }
  };

  return (
    <div className='category_carousel_slide' role='group'>
      {quantity && (
        <Typography variant='body2' className='number_of_items pxs'>
          <ScreenReaderOnly message='number of products ' />(
          {quantity.split('.')[0]})
        </Typography>
      )}
      <Link
        className='link-wrapped-card'
        to={categoryUrl}
        rel='nofollow'
        onClick={slideClickHandler}
        aria-label={imgAlt ? imgAlt : `slide ${slideIndex + 1}`}>
        {[0].map((value, index) => (
          <Thumbnail
            key={index}
            description={description}
            imgSrc={imgSrc}
            imgAlt={description}
          />
        ))}
      </Link>
    </div>
  );
};

export { CategoryCarouselSlide };
