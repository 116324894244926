import { AxiosRequestConfig, CancelToken } from 'axios';
import { ProductEspotResponse } from '../../../../utils/hooks/enhanced-product-card/IProductCard';
import { getLocalStorage } from '../../../../utils/utils';
import { REGION_AD, USER_TYPE } from '../../../constants/cookie';
import { getSite } from '../../../hooks/usesite';
import IEspotResponse from '../../../interface/Responses/IEspotResponse';
import validateIEspotResponse from '../../../interface/Responses/IEspotResponse.validator';
import { makeRequest } from '../../axios/axiosConfig';
import { EspotParam } from './espotParams.model';

const eSpotService = {
  /**
   * @method fetchEspotData - Fetches all data for the corresponding espot name.
   * @param {EspotParam} eSpotParams All params related to the Espot being fetched.
   * @param {CancelToken} cancelToken Pass an optional cancel token to cancel a request to fetch espot data.
   */
  async fetchEspotData(
    eSpotParams: EspotParam,
    cancelToken?: CancelToken
  ): Promise<IEspotResponse> {
    try {
      const siteInfo = getSite();

      const espotUrl = `${siteInfo?.transactionContext}/store/${eSpotParams.storeID}/espot/${eSpotParams.name}`;

      const DMESpot_member = getLocalStorage(USER_TYPE);

      const queryParams = {
        ...{
          ...(eSpotParams.dmEspotRegion && {
            DMESpot_region: eSpotParams.dmEspotRegion,
          }),
        },
        ...{ ...(DMESpot_member && { DMESpot_member }) },
      };

      const request: AxiosRequestConfig = {
        url: espotUrl,
        method: 'GET',
        params: queryParams,
        cancelToken,
      };

      const espotResponse: IEspotResponse = validateIEspotResponse(
        await makeRequest(request)
      );

      return espotResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   *  WE need to come up with solution to overcome this kind of scenario
   */

  /**
   * @method fetchProductEspotData - Fetches all data for the corresponding espot name with respect to the widget.
   * @param {EspotParam} eSpotParams All params related to the Espot being fetched.
   * @param {CancelToken} cancelToken Pass an optional cancel token to cancel a request to fetch espot data.
   */
  async fetchProductEspotData(
    eSpotParams: EspotParam,
    cancelToken?: CancelToken
  ): Promise<ProductEspotResponse> {
    try {
      const siteInfo = getSite();

      const espotUrl = `${siteInfo?.transactionContext}/store/${eSpotParams.storeID}/espot/${eSpotParams.name}`;

      const DMESpot_region = sessionStorage.getItem(REGION_AD);

      const DMESpot_member = getLocalStorage(USER_TYPE);

      const queryParams = {
        ...{ ...(DMESpot_region && { DMESpot_region }) },
        ...{ ...(DMESpot_member && { DMESpot_member }) },
      };

      const request: AxiosRequestConfig = {
        url: espotUrl,
        method: 'GET',
        params: queryParams,
        cancelToken,
      };

      const espotResponse: ProductEspotResponse = await makeRequest(request);

      return espotResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { eSpotService };
