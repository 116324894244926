import React from 'react';
import { useHistory } from 'react-router';
import { SHOPPING_CART } from '../../../constants/routes';
import { useSeoState } from '../../../utils/hooks/seo-state/seo-state';
import { IProductPrice } from '../../../utils/product-utility';
import { QuantityPricingPopper } from './QuantityPricingPopper';

interface IQuantityPricing {
  productPrice?: IProductPrice;
  showSavings: boolean;
  showQtyPopper?: boolean;
}

const QuantityPricing: React.FC<IQuantityPricing> = ({
  productPrice,
  showSavings,
  showQtyPopper,
}) => {
  const history = useHistory();

  const { isPdpPage } = useSeoState();

  const isCartPage = history.location.pathname === SHOPPING_CART;

  return (
    <>
      {productPrice?.cartQtyMessage && (
        <>
          {productPrice?.isTier3Pricing &&
            (isCartPage || showQtyPopper) &&
            showSavings && (
              <p className='sub-product-offer-price pxs cart-qty-message'>
                {productPrice?.cartQtyMessage}
              </p>
            )}

          {(!isPdpPage || showQtyPopper) &&
            productPrice?.showQtyPopper &&
            showSavings && (
              <QuantityPricingPopper productPrice={productPrice} />
            )}
        </>
      )}
    </>
  );
};

export { QuantityPricing };
