import { Typography } from '@material-ui/core';
import React from 'react';
import { PhysicalStoreDetails } from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { NteEspot } from '../../../NteEspot/NteEspot';
import { NearByStoreTileConstants } from '../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/NearByStoreTile/NearByStoreTileConstants';
import { ModalBody } from '../../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../../Modals/ModalHeader/ModalHeader';

/**
 * @interface IStoreSeeDetailsModal
 */
interface IStoreSeeDetailsModal {
  /**
   * @prop modalHeaderText Header of the modal.
   */
  modalHeaderText: string;

  /**
   * @prop modalHeaderId Modal header's id.
   */
  modalHeaderId: string;

  /**
   * @prop modalCloseHandler Close handler.
   */
  modalCloseHandler: () => void;
  /**
   * @prop seePickUpIcons Icons.
   */

  storeDetailsData?: PhysicalStoreDetails;

  espotData: string;
}

/**
 * @component StoreSeeDetailsModal renders the StoreSeeDetails modal.
 */
const StoreSeeDetailsModal: React.FC<IStoreSeeDetailsModal> = ({
  modalCloseHandler,
  modalHeaderId,
  modalHeaderText,
  storeDetailsData,
  espotData,
}) => {
  const { STORE_HOURS } = NearByStoreTileConstants;

  const storeHours = storeDetailsData?.Attribute.filter(
    (storeAttribute: any) => storeAttribute.name === STORE_HOURS
  );

  return (
    <>
      <ModalHeader
        modalCloseHandler={modalCloseHandler}
        heading={modalHeaderText}
        id={modalHeaderId}
      />

      <ModalBody>
        <div>
          <div className='prompt-margin'>
            <Typography variant='body1'>
              {storeDetailsData?.addressLine[0]}
            </Typography>
            <Typography variant='body1'>
              {storeDetailsData?.city}, {storeDetailsData?.stateOrProvinceName}
              {storeDetailsData?.postalCode}
            </Typography>
          </div>
          <div className='section-row'>
            <Typography variant='body2'>
              {storeHours && (
                <div
                  dangerouslySetInnerHTML={{ __html: storeHours[0].displayValue }}
                />
              )}
            </Typography>
          </div>
          <NteEspot espotId={espotData} />
        </div>
      </ModalBody>
    </>
  );
};

export { StoreSeeDetailsModal };
