import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_REMOVE_WISH_LIST_ACTION,
  DELETE_REMOVE_WISH_LIST_ACTION,
} from '../../../../redux/actions/wish-list.actions';
import { wishListSelector } from '../../../../redux/selectors/wish-list.selector';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { IGiftList } from '../../../../_foundation/interface/WishList/IWishList';
import { saveToListSelector } from '../selector/save-to-list.selector';

/**
 * @interface ISaveToListMenuHooks
 */
interface ISaveToListMenuHooks {
  /**
   * id of the current product.
   */
  productData: IProductListContents;

  /**
   * Set state method to check if the current product is selected or not.
   */
  setIsSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @method useSaveToListMenu dispatches the redux event to save an item to the list.
 *
 * @param ISaveToListMenuHooks
 */
const useSaveToListMenu = ({
  productData,
  setIsSelected,
}: ISaveToListMenuHooks) => {
  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { listItems } = useSelector(saveToListSelector);

  const { giftList } = useSelector(wishListSelector);

  const [currentItem, setCurrentItem] = useState<string[]>([]);

  /**
   * @method setCurrentItemSelections method checks if the current product is
   * currently selected under a specific item.
   */
  const setCurrentItemSelections = useCallback((): void => {
    const listItemValues = Object.values(listItems);

    const listItemKeys = Object.keys(listItems);

    let currentItem: string[] = [];

    let currentListIndex = -1;

    listItemValues.forEach((listItemValue, index) => {
      currentListIndex = listItemValue.findIndex(
        (listItem) => listItem.partNumber === productData.partNumber
      );

      if (currentListIndex !== -1) {
        currentItem.push(listItemKeys[index]);
      }
    });

    if (currentItem) {
      setCurrentItem(currentItem);

      if (setIsSelected) {
        setIsSelected(currentItem.length !== 0);
      }
    }
  }, [productData, listItems, setIsSelected]);

  useEffect(() => {
    setCurrentItemSelections();
  }, [productData, listItems, setCurrentItemSelections]);

  /**
   * @method saveToList saves the product unders the selected item.
   *
   * @param item Current item under which the product needs to be saved.
   */
  const saveToList = (item: string): void => {
    const isAlreadySaved =
      currentItem.findIndex((currentItem) => currentItem === item) !== -1;

    let currentProduct: IProductListContents[] = [{ ...productData }];

    const currentGiftList = giftList.filter(
      (giftList: IGiftList) => giftList.descriptionName === item
    );

    if (mySite && !isAlreadySaved) {
      dispatch(
        ADD_REMOVE_WISH_LIST_ACTION({
          currentProduct: currentProduct[0],
          item,
          currentItemId: currentGiftList[0]?.externalIdentifier,
          storeID: mySite.storeID,
        })
      );
    }
    if (isAlreadySaved) {
      const giftItem = currentGiftList[0]?.item?.filter(
        (giftItem) => giftItem.partNumber === currentProduct[0].partNumber
      )[0];

      if (giftItem) {
        dispatch(
          DELETE_REMOVE_WISH_LIST_ACTION({
            storeID: mySite.storeID,
            wishListId: currentGiftList[0]?.uniqueID,
            itemId: giftItem?.giftListItemID,
            productId: giftItem?.productId,
            descriptionName: item,
            productPartNumber: currentProduct[0]?.partNumber,
          })
        );
      }
    }
  };

  return { saveToList, currentItem };
};

export { useSaveToListMenu };
