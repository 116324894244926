import { UserType } from '../../../../../_foundation/enum/User/UserType';

// Constants for Support Menu.
const SupportMenuConstants = {
  TRACKING: 'Track Orders',
  SUPPORT_TITLE: 'Support.Actions.TITLE',
  CUSTOMER_HELP_TEXT: 'Support.Actions.CUSTOMER_HELP',
  SALES: 'Support.Actions.SALES',
  CUSTOMER_CARE: 'Support.Actions.CUSTOMER_CARE',
  TEXT: 'Support.Actions.TEXT',
  CHAT: 'Support.Actions.CHAT',
  EMAIL: 'Support.Actions.EMAIL',
  TEXT_NUMBER: '01981',
  CUSTOMER_CARE_NUMBER: '1-800-222-5381',
  SMS_NUMBER: '1-800-221-0516',
  USER_TYPE: {
    [UserType.NotSignedIn]: '1-800-221-0516',
    [UserType.SignedIn]: '1-800-221-0516',
    [UserType.Advantage]: '1-800-288-9195',
    [UserType.Platinum]: '1-800-777-0640',
    [UserType.GreatPlains]: '1-800-777-0640',
    [UserType.NorthernPreferred]: '1-800-777-0640',
    [UserType.AdvantageNotSignedIn]: '1-800-838-0516',
  },
  SUPPORT_TITLE_FALLBACK: 'Support',
  CUSTOMER_HELP_TEXT_FALLBACK: 'Customer Help',
  SALES_FALLBACK: 'Sales',
  CUSTOMER_CARE_FALLBACK: 'Customer Care',
  TEXT_FALLBACK: 'Text',
  CHAT_FALLBACK: 'Chat',
  EMAIL_FALLBACK: 'Email',
};

export { SupportMenuConstants };
