import { createReducer, current } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  deleteCookie,
  getCookie,
  getLocalStorage,
  hasKey,
  removeLocalStorage,
  setLocalStorage,
} from '../../utils/utils';
import {
  CART_COUNT_BEFORE_MERGING,
  PREV_USER_TYPE,
  USER,
  USER_CONTRACT,
  USER_TYPE,
} from '../../_foundation/constants/cookie';
import { UserType } from '../../_foundation/enum/User/UserType';
import {
  IAuthenticationCookie,
  IAuthenticationResponse,
  IAuthenticationState,
  ILoginIdentity,
  IUpdateUserRegistrationResponse,
} from '../../_foundation/interface/Authentication/IAuth';
import { INetworkErrors } from '../../_foundation/interface/NetworkErrors/INetworkErrors';
import {
  CLEAR_LOGIN_ERROR,
  ERROR_UPDATING_SAVED_CARD_DETAILS,
  UPDATE_USER_SAVED_CARD_DETAILS,
} from '../action-types/auth.action-types';
import {
  GET_USER_DETAILS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INVALID_USER_CREDENTIALS_ACTION,
  LOGIN_SUCCEEDED_ACTION,
  REGISTRATION_FAILED_ACTION,
  RESET_REGISTRATION_ERRORS_ACTION,
  RESET_UPDATE_PASSWORD_ACTION,
  RESET_UPDATE_USER_REGISTRATION_ERROR_ACTION,
  SIGN_OUT_ACTION,
  UPDATE_PASSWORD_FAILED_ACTION,
  UPDATE_PASSWORD_SUCCEEDED_ACTION,
  UPDATE_USER_INFO_SUCCEEDED_ACTION,
  UPDATE_USER_REGISTRATION_ERROR_ACTION,
  UPDATE_USER_REGISTRATION_SUCCESS_ACTION,
} from '../actions/auth.actions';

const initialAuthState: IAuthenticationState = {
  loading: true,
  errorCode: '',
  errorMessage: '',
  metaData: {
    WCToken: '',
    WCTrustedToken: '',
    customerServiceNumber: '',
    logoUrl: '',
    customerNumber: '',
    personalizationID: '',
    programName: '',
    resourceName: '',
    userId: '',
  },
  userType: UserType.NotSignedIn,
  userInfo: {
    accountStatus: '',
    addressId: '',
    addressType: '',
    challengeQuestion: '',
    checkoutProfileUrl: '',
    contactUrl: '',
    contextAttribute: [],
    distinguishedName: '',
    email1: '',
    firstName: '',
    lastName: '',
    lastUpdate: '',
    logonId: '',
    nickName: '',
    organizationDistinguishedName: '',
    orgizationId: '',
    passwordExpired: '',
    preferredCurrency: '',
    preferredLanguage: '',
    primary: '',
    profileType: '',
    registrationApprovalStatus: '',
    registrationDateTime: '',
    registrationStatus: '',
    resourceId: '',
    resourceName: '',
    userId: '',
    country: '',
    addressLine: [],
    city: '',
    contact: [],
    gender: '',
    receiveEmailPreference: [],
    receiveSMSPreference: [],
    state: '',
    zipCode: '',
  },
  registrationErrors: [],
  updatePasswordErrors: [],
  updateRegistrationErrors: {},
  isUserAuthenticated: false,
  passwordUpdated: false,
  savedPaymentCards: {
    savedCardDetails: [],
    dataLoaded: false,
    errors: [],
  },
  isGuest: false,
  prevUserType: UserType.NotSignedIn,
  isSessionInvalid: false,
};

const MapUserTypes = {
  NA: UserType.SignedIn,
  Advantage: UserType.Advantage,
  Platinum: UserType.Platinum,
  GreatPlains: UserType.GreatPlains,
  NorthernPreferred: UserType.NorthernPreferred,
};

const MapProgramNameToUserType = {
  Advantage: 'AdvantageAuth',
  Platinum: 'Platinum',
  GreatPlains: 'GreatPlains',
  NorthernPreferred: 'Preferred',
  NA: 'RegularAuth',
};

const authenticationReducer = createReducer(initialAuthState, (builder) => {
  builder.addCase(GET_USER_DETAILS_ACTION, () => {
    const authentication: IAuthenticationState = getLocalStorage(USER);

    const authenticationState = authentication
      ? authentication
      : initialAuthState;

    return { ...authenticationState, isSessionInvalid: false };
  });

  builder.addCase(
    LOGIN_SUCCEEDED_ACTION,
    (state: IAuthenticationState, action: any) => {
      const authenticationResponse = action.payload as IAuthenticationResponse;

      const { loginIdentityResponse, userInfoResponse } =
        authenticationResponse;

      const { programName } = loginIdentityResponse;

      let userType: UserType = UserType.NotSignedIn;

      if (hasKey(MapUserTypes, programName)) {
        userType = MapUserTypes[programName];
      }

      const isUserAuthenticated = userType !== UserType.NotSignedIn;

      const authentication: IAuthenticationState = {
        loading: false,
        userType,
        metaData: loginIdentityResponse,
        userInfo: userInfoResponse,
        registrationErrors: state.registrationErrors,
        isUserAuthenticated,
        updatePasswordErrors: [],
        passwordUpdated: false,
        errorCode: '',
        errorMessage: '',
        savedPaymentCards: {
          savedCardDetails: [],
          dataLoaded: false,
          errors: [],
        },
        isGuest: false,
        prevUserType: userType,
        isSessionInvalid: false,
      };

      const authenticationCookie: IAuthenticationCookie = {
        userType,
        metaData: loginIdentityResponse,
        userInfo: userInfoResponse,
        isUserAuthenticated,
        isGuest: false,
      };

      setLocalStorage(USER, authenticationCookie);

      setLocalStorage(PREV_USER_TYPE, userType);

      if (hasKey(MapProgramNameToUserType, programName)) {
        const programNameCookie = MapProgramNameToUserType[programName];

        setLocalStorage(USER_TYPE, programNameCookie);
      }

      return { ...authentication, errorCode: '', errorMessage: '' };
    }
  );

  /**
   * CASE GUEST_LOGIN_SUCCESS_ACTION
   * update the identity response in redux and cookie
   */
  builder.addCase(
    GUEST_LOGIN_SUCCESS_ACTION,
    (state: IAuthenticationState, action: any) => {
      const guestIdentityResponse = action.payload;

      const authenticationCookie: IAuthenticationState = getLocalStorage(USER);

      const updatedAuthenticatedCookie: IAuthenticationCookie = {
        ...authenticationCookie,
        metaData: guestIdentityResponse,
        isGuest: true,
        isUserAuthenticated: false,
        userInfo: initialAuthState.userInfo,
        userType: initialAuthState.userType,
      };

      setLocalStorage(USER, updatedAuthenticatedCookie);

      const authentication: IAuthenticationState = {
        ...initialAuthState,
        metaData: guestIdentityResponse,
        isGuest: true,
        isUserAuthenticated: false,
        isSessionInvalid: false,
      };

      if (guestIdentityResponse) {
        return { ...authentication };
      }
    }
  );

  builder.addCase(
    SIGN_OUT_ACTION,
    (state: IAuthenticationState, action: any) => {
      const currentAuthenticationState = current(state);

      let programName: string = currentAuthenticationState.metaData.programName;

      const userTypeCookie = programName === 'Guest';

      sessionStorage.removeItem(USER_CONTRACT);

      removeLocalStorage(USER);

      setLocalStorage(USER_TYPE, userTypeCookie);

      if (getCookie(CART_COUNT_BEFORE_MERGING)) {
        deleteCookie(CART_COUNT_BEFORE_MERGING);
      }

      return { ...initialAuthState, isSessionInvalid: action.payload };
    }
  );

  builder.addCase(
    INVALID_USER_CREDENTIALS_ACTION,
    (state: IAuthenticationState, action: any) => {
      const { data } = action.payload.response as AxiosResponse;

      const errorData: INetworkErrors = data as INetworkErrors;

      const { errorCode, errorMessage } = errorData.errors[0];

      return { ...state, errorCode, errorMessage, isSessionInvalid: false };
    }
  );

  builder.addCase(
    REGISTRATION_FAILED_ACTION,
    (state: IAuthenticationState, action: any) => {
      const { data } = action.payload.response as AxiosResponse;

      return {
        ...state,
        registrationErrors: data.errors,
        isSessionInvalid: false,
      };
    }
  );

  builder.addCase(
    RESET_REGISTRATION_ERRORS_ACTION,
    (state: IAuthenticationState) => {
      return { ...state, registrationErrors: [] };
    }
  );

  builder.addCase(
    UPDATE_PASSWORD_SUCCEEDED_ACTION,
    (state: IAuthenticationState) => {
      return { ...state, passwordUpdated: true, isSessionInvalid: false };
    }
  );

  builder.addCase(
    UPDATE_PASSWORD_FAILED_ACTION,
    (state: IAuthenticationState, action: any) => {
      const { data } = action.payload.response as AxiosResponse;

      return { ...state, updatePasswordErrors: data.errors };
    }
  );

  builder.addCase(
    RESET_UPDATE_PASSWORD_ACTION,
    (state: IAuthenticationState) => {
      return { ...state, passwordUpdated: false, updatePasswordErrors: [] };
    }
  );

  /**
   * CASE UPDATE_USER_REGISTRATION_SUCCESS_ACTION
   * Updates the customer number in redux state and cookie.
   * Updates the corresponding programName and userType based on the programCode in redux and cookie.
   */
  builder.addCase(
    UPDATE_USER_REGISTRATION_SUCCESS_ACTION,
    (state: IAuthenticationState, action: any) => {
      const {
        addEditCustomerNumber: { customerNumber, message },
        userInfo,
      } = action.payload as IUpdateUserRegistrationResponse;

      const { programName } = userInfo;

      const currentAuthenticationState = current(state);

      let userType: UserType = UserType.NotSignedIn;

      const isUserAuthenticated = userType !== UserType.NotSignedIn;

      if (programName) {
        if (hasKey(MapUserTypes, programName)) {
          userType = MapUserTypes[programName];
        }
      }

      const authenticationCookie: IAuthenticationCookie = {
        userType,
        metaData: currentAuthenticationState.metaData,
        userInfo: userInfo,
        isUserAuthenticated,
        isGuest: false,
      };

      setLocalStorage(USER, authenticationCookie);

      setLocalStorage(PREV_USER_TYPE, userType);

      if (programName) {
        if (hasKey(MapProgramNameToUserType, programName)) {
          const programNameCookie = MapProgramNameToUserType[programName];

          setLocalStorage(USER_TYPE, programNameCookie);
        }
      }

      const metaData: ILoginIdentity = {
        ...currentAuthenticationState.metaData,
        ...{ ...(customerNumber && { customerNumber }) },
        ...{ ...(programName && { programName }) },
      };

      const authentication: IAuthenticationState = {
        ...state,
        errorCode: '',
        errorMessage: '',
        metaData,
        userInfo,
        userType,
        isSessionInvalid: false,
      };

      setLocalStorage(USER, authentication);

      const successMessage = message ? message : 'SUCCESS';

      return {
        ...authentication,
        metaData,
        updateRegistrationErrors: { errors: [], successMessage },
      };
    }
  );

  builder.addCase(
    UPDATE_USER_REGISTRATION_ERROR_ACTION,
    (state: IAuthenticationState, action: any) => {
      return { ...state, updateRegistrationErrors: action.payload };
    }
  );

  builder.addCase(
    RESET_UPDATE_USER_REGISTRATION_ERROR_ACTION,
    (state: IAuthenticationState) => {
      return {
        ...state,
        updateRegistrationErrors: { errors: [], successMessage: '' },
      };
    }
  );

  builder.addCase(
    UPDATE_USER_INFO_SUCCEEDED_ACTION,
    (state: IAuthenticationState, action: any) => {
      const authentication: IAuthenticationState = { ...state };

      authentication.userInfo = action.payload.userInfo;

      setLocalStorage(USER, authentication);

      return { ...authentication, errorCode: '', errorMessage: '' };
    }
  );

  builder.addCase(
    UPDATE_USER_SAVED_CARD_DETAILS,
    (state: IAuthenticationState, action: any) => {
      const { CheckoutProfile: profileData }: any = action.payload;

      const cardDetails = [];

      if (profileData && profileData[0]?.protocolData) {
        cardDetails.push(profileData[0]?.protocolData);
      }
      return {
        ...state,
        savedPaymentCards: {
          savedCardDetails: cardDetails,
          errors: [],
          dataLoaded: true,
        },
      };
    }
  );

  builder.addCase(
    ERROR_UPDATING_SAVED_CARD_DETAILS,
    (state: IAuthenticationState, action: any) => {
      return {
        ...state,
        savedPaymentCards: {
          savedCardDetails: [],
          errors: action.payload,
          dataLoaded: true,
        },
      };
    }
  );

  builder.addCase(
    CLEAR_LOGIN_ERROR,
    (state: IAuthenticationState, action: any) => {
      return {
        ...state,
        errorCode: '',
        errorMessage: '',
      };
    }
  );
});

export { authenticationReducer };
