import { RootReducerState } from '../reducers';

const siteSelector = (state: RootReducerState) => {
  return state.site.currentSite;
};

const siteOfflineSelector = (state: RootReducerState) => {
  return state.site.offlineStatus;
};
export { siteSelector, siteOfflineSelector };
