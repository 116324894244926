import { createAction } from '@reduxjs/toolkit';
import { IContentsItem } from '../../_foundation/interface/Responses/IProductsByPartNumbersResponse';
import * as ACTION_TYPES from '../action-types/productDetail.action-types';

export const UPDATE_PRODUCT_DETAIL_ACTION = createAction<IContentsItem | null>(
  ACTION_TYPES.UPDATE_PRODUCT_DETAIL
);

export const RESET_PRODUCT_DETAIL_ACTION = createAction(
  ACTION_TYPES.RESET_PRODUCT_DETAIL
);
