import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  EncodedQuery,
  QueryParamConfigMap,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { extractDecodedParams, hasKey } from '../../../../../utils/utils';
import { SearchLayoutConstants } from '../../../../Layouts/Search/SearchLayout.constants';
import { FacetConstants } from '../FacetConstants';

/**
 * @method usePreserveQueryParams is responsible for preserving custom query params like the
 * query params used from marketing and tracking urls this should be initiated from the PLP page.
 *
 * NOTE:
 * By default the PLP page will only accept four query params "f", "c", "s" and "b" (mentioned in index.tsx)
 * anything apart from that will be neglected. This has been put in place to prevent the page from making additional
 * network calls on facet selection or facet removal. Any additional parameters from these will not
 * be used in the PLP business logic therefore they're getting removed to keep additional query parameters
 * we need to initialize this preserveQueryParams hook in the PLP page.
 */
const usePreserveQueryParams = () => {
  const { CATEGORY_QUERY_KEY, FACET_QUERY_KEY, BRAND_QUERY_KEY } =
    FacetConstants;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const history = useHistory();

  const [queryKeys, setQueryKeys] = useState<QueryParamConfigMap>({});

  const [queryValues, setQueryValues] = useState({});

  const [, setCustomParams] = useQueryParams(queryKeys);

  /**
   * @callback extractParams Extracts query params from the search string.
   *
   * @returns
   * @paramConfig This callback returns paramConfig which initializes custom query param keys
   * for the useQueryParams hook from use-query-params package.
   *
   * @queryParamValues This callback returns query params key value pair object
   */
  const extractParams = useCallback(() => {
    let paramConfig: QueryParamConfigMap = {};

    let queryParamValues = {};

    if (history.location.search) {
      const query: EncodedQuery = extractDecodedParams(history.location.search);

      if (query) {
        Object.keys(query).forEach((queryKey) => {
          if (
            ![
              CATEGORY_QUERY_KEY,
              FACET_QUERY_KEY,
              BRAND_QUERY_KEY,
              SEARCH_QUERY_PARAM,
            ].includes(queryKey)
          ) {
            queryParamValues = {
              ...queryParamValues,
              [queryKey]: query[queryKey],
            };

            if (query && hasKey(query, queryKey) && query[queryKey]) {
              paramConfig = {
                ...paramConfig,
                [queryKey]: StringParam,
              };
            }
          }
        });
      }
    }

    return { paramConfig, queryParamValues };
  }, [
    BRAND_QUERY_KEY,
    CATEGORY_QUERY_KEY,
    FACET_QUERY_KEY,
    SEARCH_QUERY_PARAM,
    history.location.search,
  ]);

  /**
   * @callback initCustomQueryKeys is responsible for initializing the custom query keys
   * and the values for the useQueryParams hook.
   */
  const initCustomQueryKeys = useCallback((): void => {
    if (history.location.search && Object.values(queryKeys).length === 0) {
      const { paramConfig, queryParamValues } = extractParams();

      if (setQueryKeys && Object.keys(paramConfig).length !== 0) {
        setQueryKeys({
          ...paramConfig,
        });

        setQueryValues(queryParamValues);
      }
    }
  }, [extractParams, history.location.search, queryKeys]);

  useEffect(() => {
    initCustomQueryKeys();
  }, [initCustomQueryKeys]);

  /**
   * @callback initCustomQueryParams pushes the custom query param to the url.
   */
  const initCustomQueryParams = useCallback((): void => {
    if (history.location.search && Object.values(queryValues).length !== 0) {
      setCustomParams(queryValues);

      return;
    }
  }, [history.location.search, queryValues, setCustomParams]);

  useEffect(() => {
    initCustomQueryParams();
  }, [initCustomQueryParams]);
};

export { usePreserveQueryParams };
