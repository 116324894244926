import { createAction } from '@reduxjs/toolkit';
import { ICardTokenization } from '../../_foundation/apis/payment/IPayment';
import { ITokenexResponse } from '../../_foundation/apis/payment/payment.service';
import { PaymentMethod } from '../../_foundation/interface/Cart/IOrder';
import * as ACTION_TYPES from '../action-types/payment.action-types';

export const INIT_AUTHENTICATION_KEY_ACTION = createAction<any>(
  ACTION_TYPES.INIT_AUTHENTICATION_KEY
);

export const INIT_AUTHENTICATION_KEY_SUCCESS_ACTION =
  createAction<ICardTokenization>(ACTION_TYPES.INIT_AUTHENTICATION_KEY_SUCCESS);

export const RESET_AUTHENTICATION_KEY_ACTION = createAction(
  ACTION_TYPES.RESET_AUTHENTICATION_KEY
);

export const UPDATE_PAYMENT_METHOD_ACTION = createAction<PaymentMethod>(
  ACTION_TYPES.UPDATE_PAYMENT_METHOD
);

export const TOKENIZE_CREDIT_DEBIT_CARD_ACTION = createAction<ITokenexResponse>(
  ACTION_TYPES.TOKENIZE_CREDIT_DEBIT_CARD
);

export const TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS_ACTION =
  createAction<ITokenexResponse>(
    ACTION_TYPES.TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS
  );

export const TOKENIZE_CREDIT_DEBIT_CARD_FAILURE_ACTION =
  createAction<ITokenexResponse>(
    ACTION_TYPES.TOKENIZE_CREDIT_DEBIT_CARD_FAILURE
  );
