import { createAction } from '@reduxjs/toolkit';
import { ICartSpinner } from '../../_foundation/interface/Cart/IOrder';
import {
  IFetchStoreByAddressParam,
  IFetchStoreParams,
  IFetchUsStores,
  PhysicalStoreDetails,
  StoreDetails,
} from '../../_foundation/interface/StoreLocator/IStoreLocator';
import * as ACTION_TYPES from '../action-types/storeLocator';

/**
 *  Need to add proper interface instead of any, string
 */

const GET_STORES_ACTION = createAction<any, string>(ACTION_TYPES.GET_STORES);

const GET_US_STORES_ACTION = createAction<IFetchUsStores>(
  ACTION_TYPES.GET_US_STORES
);

const GET_STORES_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.GET_STORES_SUCCESS
);

const GET_US_STORES_SUCCESS_ACTION = createAction<StoreDetails>(
  ACTION_TYPES.GET_US_STORES_SUCCESS
);

const STORE_LOADER_ACTION = createAction<any, string>(
  ACTION_TYPES.STORE_LOADER
);

const SEARCH_STORE_ACTION = createAction<any, string>(
  ACTION_TYPES.SEARCH_STORE
);

const SEARCH_STORE_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.SEARCH_STORE_SUCCESS
);

const SHOW_STORE_FINDER_ACTION = createAction<boolean>(
  ACTION_TYPES.SHOW_STORE_FINDER
);

const STORE_SEARCH_LOADER_ACTION = createAction<boolean>(
  ACTION_TYPES.STORE_SEARCH_LOADER
);

const RESET_STORE_SEARCH_ACTION = createAction(ACTION_TYPES.RESET_STORE_SEARCH);

const MAKE_MY_STORE_ACTION = createAction<PhysicalStoreDetails>(
  ACTION_TYPES.MAKE_MY_STORE
);

const RESET_MY_STORE_ACTION = createAction(
  ACTION_TYPES.RESET_MY_STORE
);

const STORE_PICKUP_INVENTORY_REQUEST_ACTION =
  createAction<IFetchStoreByAddressParam>(
    ACTION_TYPES.STORE_PICKUP_INVENTORY_REQUEST
  );

const STORE_PICKUP_INVENTORY_SUCCESS_ACTION = createAction<StoreDetails>(
  ACTION_TYPES.STORE_PICKUP_INVENTORY_SUCCESS
);

const STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION =
  createAction<IFetchStoreParams>(
    ACTION_TYPES.STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST
  );

const STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION =
  createAction<StoreDetails>(
    ACTION_TYPES.STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS
  );

const SET_ANCHOR_STORE_NAME_ACTION = createAction<string>(
  ACTION_TYPES.SET_ANCHOR_STORE_NAME
);

const FETCH_STORES_LOADER_ACTION = createAction<boolean>(
  ACTION_TYPES.FETCH_STORES_LOADER
);

const UPDATE_STORE_PICKUP_LOADING_STATE_ACTION = createAction<boolean>(
  ACTION_TYPES.UPDATE_STORE_PICKUP_LOADING_STATE
);

const FETCH_STORES_FAILURE_ACTION = createAction<boolean>(
  ACTION_TYPES.FETCH_STORES_FAILURE
);

const FETCH_SEARCH_STORES_FAILURE_ACTION = createAction<boolean>(
  ACTION_TYPES.FETCH_SEARCH_STORES_FAILURE
);

const FETCH_STORES_THROWS_500_ACTION = createAction<boolean>(
  ACTION_TYPES.FETCH_STORES_THROWS_500
);

const RESET_SEARCH_STORES_FAILURE_ACTION = createAction(
  ACTION_TYPES.RESET_SEARCH_STORES_FAILURE
);

const SET_MAKE_MY_STORE_SPINNER_ACTION = createAction<ICartSpinner>(
  ACTION_TYPES.SET_MAKE_MY_STORE_SPINNER
);

export {
  GET_STORES_ACTION,
  GET_STORES_SUCCESS_ACTION,
  STORE_LOADER_ACTION,
  SEARCH_STORE_ACTION,
  SEARCH_STORE_SUCCESS_ACTION,
  SHOW_STORE_FINDER_ACTION,
  STORE_SEARCH_LOADER_ACTION,
  RESET_STORE_SEARCH_ACTION,
  MAKE_MY_STORE_ACTION,
  RESET_MY_STORE_ACTION,
  STORE_PICKUP_INVENTORY_REQUEST_ACTION,
  STORE_PICKUP_INVENTORY_SUCCESS_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_SUCCESS_ACTION,
  SET_ANCHOR_STORE_NAME_ACTION,
  GET_US_STORES_ACTION,
  GET_US_STORES_SUCCESS_ACTION,
  FETCH_STORES_LOADER_ACTION,
  UPDATE_STORE_PICKUP_LOADING_STATE_ACTION,
  FETCH_STORES_FAILURE_ACTION,
  SET_MAKE_MY_STORE_SPINNER_ACTION,
  FETCH_SEARCH_STORES_FAILURE_ACTION,
  FETCH_STORES_THROWS_500_ACTION,
  RESET_SEARCH_STORES_FAILURE_ACTION,
};
