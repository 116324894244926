import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreLocatorConstants } from '../../../../components/Widgets/AppBar/SecondaryMenuItems/StoreLocator/StoreLocatorConstants';
import { CHECKOUT_DELIVERY } from '../../../../constants/routes';
import {
  STORE_FIND_STATE,
  STORE_FIND_ZIP,
} from '../../../../constants/Tealium';
import {
  GET_STORES_ACTION,
  MAKE_MY_STORE_ACTION,
  RESET_STORE_SEARCH_ACTION,
  SEARCH_STORE_ACTION,
  SET_ANCHOR_STORE_NAME_ACTION,
  SET_MAKE_MY_STORE_SPINNER_ACTION,
  SHOW_STORE_FINDER_ACTION,
  STORE_SEARCH_LOADER_ACTION,
} from '../../../../redux/actions/storeLocator';
import { orderSelector } from '../../../../redux/selectors/order.selector';
import { storeLocatorSelector } from '../../../../redux/selectors/storeLocator';
import { CartService } from '../../../../_foundation/apis/cart/cart.service';
import { NEAR_BY_STORES } from '../../../../_foundation/constants/cookie';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import {
  MyStoreCookie,
  PhysicalStoreDetails,
} from '../../../../_foundation/interface/StoreLocator/IStoreLocator';
import { isStoreAvailable, sendTealiumData } from '../../../utils';
import { useCurrentPathName } from '../../current-pathname/CurrentPathName';

/**
 * @interface ISearchStores
 */
interface ISearchStores {
  currentAddress: string;
  storeDetails: PhysicalStoreDetails[];
  searchedStoreDetails: PhysicalStoreDetails[];
  storeFinderCloseHandler?: () => void;
  partNumber?: string;
  orderId?: string;
}

/**
 * @method useSearchStores Fetches the store details data based on
 * the zip code entered in the search field.
 *
 * @param ISearchStores
 */
const useSearchStores = ({
  currentAddress,
  storeDetails,
  searchedStoreDetails,
  storeFinderCloseHandler,
  partNumber,
  orderId,
}: ISearchStores) => {
  const {
    MY_STORE_COOKIE,
    STORE_FINDER_REQUEST_LENGTH,
    SAME_DAY_DELIVERY,
    STORE_PICKUP,
  } = StoreLocatorConstants;

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { isNoStoresAvailable } = useSelector(storeLocatorSelector);

  const { orderId: currentOrderId } = useSelector(orderSelector);

  const { pathname } = useCurrentPathName();

  const isDeliveryPage = pathname === CHECKOUT_DELIVERY;

  /**
   * If the searchedStoreDetails have value then set the current store
   * details as searched store details otherwise set the current store
   * details as storeDetails.
   */
  const currentStoreDetails =
    searchedStoreDetails?.length !== 0
      ? searchedStoreDetails
      : partNumber && searchedStoreDetails?.length !== 0
      ? searchedStoreDetails
      : currentAddress && searchedStoreDetails?.length === 0
      ? []
      : storeDetails?.length !== 0
      ? storeDetails
      : [];

  /**
   * @method setAnchorStore makes the API calls to set the anchor store
   */
  const setAnchorStore = (storeDetail: MyStoreCookie) => {
    const currentStoreId = storeDetail.storeId;

    const storeDetails = currentStoreDetails?.filter(
      (storeDetail: any) => storeDetail.uniqueID === currentStoreId
    );

    const currentAnchorStore = storeDetails[0];

    if (currentAnchorStore) {
      dispatch(
        SET_MAKE_MY_STORE_SPINNER_ACTION({
          loading: false,
          partNumber: storeDetail?.storeId,
        })
      );

      dispatch(MAKE_MY_STORE_ACTION(currentAnchorStore));

      dispatch(SHOW_STORE_FINDER_ACTION(false));

      dispatch(SET_ANCHOR_STORE_NAME_ACTION(currentAnchorStore.storeName));

      if (mySite) {
        dispatch(
          GET_STORES_ACTION({
            storeID: mySite.storeID,
            latitude: currentAnchorStore?.latitude
              ? currentAnchorStore?.latitude
              : currentAnchorStore?.geoCoordinates?.lat,
            longitude: currentAnchorStore.longitude
              ? currentAnchorStore.longitude
              : currentAnchorStore.geoCoordinates?.lng,
            radius: mySite.storeLocatorDefaultRadius,
          })
        );
      }

      if (storeFinderCloseHandler) {
        storeFinderCloseHandler();
      }
    }
  };

  /**
   * @method makeMyStoreClickHandler Handles the make my store button events.
   */
  const makeMyStoreClickHandler = async (storeDetail: MyStoreCookie) => {
    try {
      dispatch(
        SET_MAKE_MY_STORE_SPINNER_ACTION({
          loading: true,
          partNumber: storeDetail?.storeId,
        })
      );

      const getCartDetailsResponse = await CartService.getCartDetails({
        storeID: mySite.storeID,
      });

      if (getCartDetailsResponse) {
        const isStorePickupOrSDD =
          getCartDetailsResponse &&
          getCartDetailsResponse.orderItem[0] &&
          (getCartDetailsResponse.orderItem[0].shipModeCode.toLowerCase() ===
            STORE_PICKUP ||
            getCartDetailsResponse.orderItem[0].shipModeCode.toLowerCase() ===
              SAME_DAY_DELIVERY);

        if (mySite && currentOrderId && isStorePickupOrSDD && !isDeliveryPage) {
          try {
            const shippingAndBillingResponse =
              await CartService.updateShippingAndBillingInfo({
                orderId: currentOrderId,
                addressId: getCartDetailsResponse.orderItem[0]?.addressId,
                storeID: mySite.storeID,
                x_useDefaultOption: true,
              });
            if (shippingAndBillingResponse) {
              setAnchorStore(storeDetail);
            }
          } catch (error) {
            dispatch(
              SET_MAKE_MY_STORE_SPINNER_ACTION({
                loading: false,
                partNumber: storeDetail?.storeId,
              })
            );
            console.error(error);
          }
        } else {
          setAnchorStore(storeDetail);
        }
      } else {
        setAnchorStore(storeDetail);
      }
    } catch (error) {
      setAnchorStore(storeDetail);
    }
  };

  /**
   * @method fetchSearchData Fetches the store details.
   */
  const fetchSearchData = useCallback((): void => {
    const fetchStoresByLatLong =
      mySite &&
      isStoreAvailable() &&
      searchedStoreDetails.length === 0 &&
      !partNumber &&
      !orderId;

    if (fetchStoresByLatLong) {
      const myStore = sessionStorage.getItem(MY_STORE_COOKIE);

      const nearByStoreDetails = sessionStorage.getItem(NEAR_BY_STORES);
      /**
       * Fetch store details only if storeDetails doesn't have any value
       * and the getCoordinates have been set in the cookie.
       *
       * If we don't have any stores for current coordinates then don't make the call again.
       */

      const hasNoStoreDetails = !nearByStoreDetails && !isNoStoresAvailable;

      if (hasNoStoreDetails && myStore) {
        const parsedStoreDetail: MyStoreCookie = JSON.parse(myStore);
        if (
          parsedStoreDetail.latitude &&
          parsedStoreDetail.longitude &&
          !nearByStoreDetails
        ) {
          dispatch(STORE_SEARCH_LOADER_ACTION(true));

          dispatch(
            GET_STORES_ACTION({
              storeID: mySite.storeID,
              latitude: parsedStoreDetail.latitude,
              longitude: parsedStoreDetail.longitude,
              radius: mySite.storeLocatorDefaultRadius,
            })
          );
        }
      }
    }

    /**
     * Below condition is used to call the store stock API
     * by providing the latitude and longitude.
     */
    const callStoreStockApi =
      (orderId || partNumber) &&
      mySite &&
      searchedStoreDetails &&
      searchedStoreDetails.length === 0 &&
      !currentAddress;

    if (callStoreStockApi) {
      const myStore = sessionStorage.getItem(MY_STORE_COOKIE);

      if (myStore && !isNoStoresAvailable) {
        const parsedStoreDetail: MyStoreCookie = JSON.parse(myStore);

        if (
          partNumber &&
          parsedStoreDetail.latitude &&
          parsedStoreDetail.longitude
        ) {
          dispatch(STORE_SEARCH_LOADER_ACTION(true));

          dispatch(
            GET_STORES_ACTION({
              storeID: mySite.storeID,
              latitude: parsedStoreDetail.latitude,
              longitude: parsedStoreDetail.longitude,
              radius: mySite.storeLocatorDefaultRadius,
              partNumber,
            })
          );
        }

        if (
          orderId &&
          parsedStoreDetail.latitude &&
          parsedStoreDetail.longitude
        ) {
          dispatch(STORE_SEARCH_LOADER_ACTION(true));

          dispatch(
            GET_STORES_ACTION({
              storeID: mySite.storeID,
              latitude: parsedStoreDetail.latitude,
              longitude: parsedStoreDetail.longitude,
              radius: mySite.storeLocatorDefaultRadius,
              orderId,
              anchorStore: parsedStoreDetail?.uniqueID,
            })
          );
        }
      }
    }
  }, [
    mySite,
    searchedStoreDetails,
    partNumber,
    orderId,
    currentAddress,
    MY_STORE_COOKIE,
    isNoStoresAvailable,
    dispatch,
  ]);

  useEffect(() => {
    fetchSearchData();
  }, [fetchSearchData]);

  /**
   * @method searchStores Searches store for the given address.
   */
  const searchStores = useCallback((): void => {
    const hasValidAddress =
      currentAddress &&
      mySite &&
      currentAddress.length >= STORE_FINDER_REQUEST_LENGTH;

    if (hasValidAddress) {
      dispatch(STORE_SEARCH_LOADER_ACTION(true));

      /**
       * Below condition is used to call the store stock API
       * if the part number is present if not the default
       * change my store API is called.
       */
      if (partNumber) {
        dispatch(
          SEARCH_STORE_ACTION({
            address: currentAddress,
            storeID: mySite?.storeID,
            partNumber,
          })
        );
      } else if (orderId) {
        dispatch(
          SEARCH_STORE_ACTION({
            address: currentAddress,
            storeID: mySite?.storeID,
            orderId,
          })
        );
      } else {
        if (!isNaN(Number(currentAddress))) {
          sendTealiumData({
            tealium_event: STORE_FIND_ZIP,
            storefinder_zip: currentAddress,
          });
        } else {
          sendTealiumData({
            tealium_event: STORE_FIND_STATE,
            storefinder_state: currentAddress,
          });
        }
        dispatch(
          SEARCH_STORE_ACTION({
            address: currentAddress,
            storeID: mySite?.storeID,
          })
        );
      }
    }
  }, [
    currentAddress,
    mySite,
    STORE_FINDER_REQUEST_LENGTH,
    dispatch,
    partNumber,
    orderId,
  ]);

  useEffect(() => {
    searchStores();

    return () => {
      dispatch(RESET_STORE_SEARCH_ACTION());
    };
  }, [currentAddress, dispatch, searchStores, partNumber]);

  return { makeMyStoreClickHandler, currentStoreDetails };
};

export { useSearchStores };
