import * as ROUTES from '../../../../../../constants/routes';

const UnsignedSaveListConstants = {
  SIGN_IN_IDENTIFIER: 'signIn',
  CREATE_ACCOUNT_IDENTIFIER: 'createAccount',
  SAVE_LIST_ROUTES: {
    signIn: ROUTES.SIGN_IN,
    createAccount: ROUTES.REGISTER_USER,
  },
  MENU_ITEMS: [
    { item: 'Sign in', identifier: 'signIn' },
    {
      item: 'Create Account',
      identifier: 'createAccount',
    },
  ],
  SAVE_TO_LIST_NON_REGISTERED_MESSAGE:
    'PRODUCT_LIST_PAGE.MESSAGE.WARNING.SAVE_TO_LIST_NON_REGISTERED_MESSAGE',
  MY_SAVED_LISTS: 'PRODUCT_LIST_PAGE.MY_SAVED_LISTS',
};

export { UnsignedSaveListConstants };
