import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  INetworkError,
  ISessionInvalidError,
  Tier1Error,
} from '../../_foundation/interface/NetworkErrors/INetworkErrors';
import * as ACTION_TYPES from '../action-types/network-errors.action-types';

const SESSION_INVALID_ACTION = createAction<ISessionInvalidError>(
  ACTION_TYPES.SESSION_INVALID
);

const MERGE_CART_ERROR_ACTION = createAction<AxiosError>(
  ACTION_TYPES.MERGE_CART_ERROR
);

const RESET_MERGE_CART_ERROR_ACTION = createAction(
  ACTION_TYPES.RESET_MERGE_CART_ERROR
);

const RESET_NETWORK_ERROR_ACTION = createAction(
  ACTION_TYPES.RESET_NETWORK_ERROR
);

const REDIRECT_TO_ERROR_PAGE_ACTION = createAction<{
  redirectToErrorPage: boolean;
  errorCode?: string;
  errorMessage?: string;
  errorKey?: string;
}>(ACTION_TYPES.REDIRECT_TO_ERROR_PAGE);

const NETWORK_ERROR_ACTION = createAction<INetworkError>(
  ACTION_TYPES.NETWORK_ERROR
);

const TIER_1_ERROR_ACTION = createAction<Tier1Error>(ACTION_TYPES.TIER_1_ERROR);

const RESET_REDIRECTION_URL_ACTION = createAction(
  ACTION_TYPES.RESET_REDIRECTION_URL
);

const INVALID_URL_ACTION = createAction<boolean>(ACTION_TYPES.INVALID_URL);

export {
  SESSION_INVALID_ACTION,
  RESET_NETWORK_ERROR_ACTION,
  REDIRECT_TO_ERROR_PAGE_ACTION,
  NETWORK_ERROR_ACTION,
  TIER_1_ERROR_ACTION,
  MERGE_CART_ERROR_ACTION,
  RESET_MERGE_CART_ERROR_ACTION,
  RESET_REDIRECTION_URL_ACTION,
  INVALID_URL_ACTION,
};
