import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SET_CURRENT_PART_NUMBER_CART_ACTION } from '../../../../../../redux/actions/order.actions';
import { hasKey } from '../../../../../../utils/utils';
import { NteMenuList } from '../../../../../Menu/NteMenuList/NteMenuList';
import { INteAlert } from '../../../../../NteAlert/NteAlert';
import { UnsignedSaveListConstants } from './UnsignedSaveListConstants';

/**
 * @interface IUnsignedSaveList
 */
interface IUnsignedSaveList {
  /**
   * @prop clickHandler handles the click events in save to list menu.
   */
  clickHandler: () => void;

  /**
   * @prop id of the current product from which the save to list is initiated.
   */
  id: string;

  /**
   * @prop cartFlow determines whether the modal gets opened from cart page.
   */
  cartFlow?: boolean;
}

/**
 * @component UnsignedSaveList renders the saved list for unsigned users.
 *
 * @param IUnsignedSaveList
 */
const UnsignedSaveList: React.FC<IUnsignedSaveList> = ({
  clickHandler,
  id,
  cartFlow = false,
}) => {
  const {
    SAVE_LIST_ROUTES,
    MENU_ITEMS,
    SAVE_TO_LIST_NON_REGISTERED_MESSAGE,
    MY_SAVED_LISTS,
  } = UnsignedSaveListConstants;

  const { pathname, search } = useLocation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { t } = useTranslation();

  const onMenuItemClick = (selectedItem: any): void => {
    const selectedIdentifier = selectedItem?.currentItemIdentifier;

    if (hasKey(SAVE_LIST_ROUTES, selectedIdentifier)) {
      history.push(SAVE_LIST_ROUTES[selectedIdentifier], {
        pathname: pathname + search,
        showGuestSignIn: true,
      });
    }

    if (cartFlow) {
      dispatch(SET_CURRENT_PART_NUMBER_CART_ACTION(id));
    }

    clickHandler();
  };

  const alertProps: INteAlert = {
    messages: [t(SAVE_TO_LIST_NON_REGISTERED_MESSAGE)],
    showClose: false,
    severity: 'warning',
  };

  return (
    <div className='unsigned_save_list'>
      <NteMenuList
        alertProps={alertProps}
        currentItem={''}
        icon={<CheckCircleIcon />}
        id={`unsigned-save-list-${id}`}
        menuItems={MENU_ITEMS}
        menuListCloseHandler={clickHandler}
        menuTitle={t(MY_SAVED_LISTS)}
        nteMenuClickHandler={onMenuItemClick}
      />
    </div>
  );
};

export { UnsignedSaveList };
