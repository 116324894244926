import homePageLayout from './home-page.json';
import ntePDPLayout from './nte-product-details-page.json';

/**
 * @object defaultLayouts
 * Holds all the layout json with the layout name as a key, this object
 * will be used inside the seo reducer to server fallback data for layouts.
 */
const defaultLayouts = {
  test: homePageLayout.layout,
  ProductPage: ntePDPLayout.layout,
  ItemPage: ntePDPLayout.layout,
  VariantPage: ntePDPLayout.layout,
};

export { defaultLayouts };
