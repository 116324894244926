import { Typography } from '@material-ui/core';
import { decode } from 'html-entities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ImageUrlUtility } from '../../../../utils/image-url-utility';
import { checkTruthy, formatToDollars } from '../../../../utils/utils';
import { IOrderItem } from '../../../../_foundation/interface/Cart/IOrder';
import { IProductListContents } from '../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../_foundation/interface/Responses/ICartProductsResponse';
import { ProductCardConstants } from '../../Plp/ProductCard/ProductCardConstants';
import { Thumbnail } from '../../Thumbnail/Thumbnail';
import { MiniCartThumbNailListConstants } from './MiniCartThumbNailListConstants';

/**
 * @interface IMiniCartThumbNailList
 */
interface IMiniCartThumbNailList {
  /**
   * @prop cartItem
   * Cart Item object that contains all
   * the params required from the
   * current cart item
   */
  cartItem: IProductListContents | ICartContentsItem;

  /**
   * @prop miniCartCloseHandler is responsible for
   * closing the cart when the user clicks
   * the cart item title.
   */
  miniCartCloseHandler: () => void;

  /**
   * @prop responsible to hold the cart details
   */
  items: any;

  freeItem: boolean;

  /**
   * @prop responsible to hold the replacement plan details
   */
  replacementItems?: IOrderItem[];

  currentOrderItem?: IOrderItem | any;
}

/**
 * @component MiniCartThumbNailList is responsible for rendering the cart item
 * component in the mini cart component.
 *
 * @param IMiniCartThumbNailList
 */
const MiniCartThumbNailList: React.FC<IMiniCartThumbNailList> = ({
  cartItem,
  miniCartCloseHandler,
  items,
  freeItem,
  currentOrderItem,
  replacementItems,
}) => {
  const { QTY, FREE } = MiniCartThumbNailListConstants;

  const {
    PRODUCT_CARD_SECTION2: { DISPLAY_ONLY },
  } = ProductCardConstants;

  const { t } = useTranslation();

  /* let showItems;

  if (replacementItems && replacementItems.length > 0) {
    showItems = [...items, ...replacementItems];
  } else {
    showItems = items;
  }

  const orderItem = showItems?.filter((item: IOrderItem) => {
    return item.productId === cartItem.id;
  })[0];
  */

  /**
   * Check if the product has displayOnly attribute
   * and its value is true. If true do not display the
   * hyperlink to redirect to PDP.
   */
  const isDisplayOnlyItem = cartItem?.attributes?.find(
    ({ identifier, values }) =>
      identifier.toLowerCase() === DISPLAY_ONLY.toLowerCase() &&
      checkTruthy(values[0].value)
  );

  return (
    <li className='block-list-item'>
      {isDisplayOnlyItem ? (
        <Thumbnail
          imgSrc={
            cartItem.fullImageRaw
              ? ImageUrlUtility.createImgSrc(cartItem.fullImageRaw)
              : ''
          }
          placeholderImg=''
          imgAlt={decode(cartItem?.name)}
        />
      ) : (
        <Link to={cartItem?.seo?.href}>
          <Thumbnail
            imgSrc={
              cartItem.fullImageRaw
                ? ImageUrlUtility.createImgSrc(cartItem.fullImageRaw)
                : ''
            }
            placeholderImg=''
            imgAlt={decode(cartItem?.name)}
          />
        </Link>
      )}

      <div className='description-container'>
        <Typography className='ellipsis-3 product-name' variant='body2'>
          {isDisplayOnlyItem ? (
            decode(cartItem.name)
          ) : (
            <Link
              className='text-decoration-none'
              onClick={miniCartCloseHandler}
              rel='nofollow'
              to={cartItem?.seo?.href}>
              {decode(cartItem.name)}
            </Link>
          )}
        </Typography>
        <Typography variant='body2'>
          {t(QTY)} {currentOrderItem?.quantity.split('.', 1)}
        </Typography>

        {checkTruthy(currentOrderItem.freeGift) ? (
          <Typography variant='body1' className='nte-price18 color-green'>
            {t(FREE)}
          </Typography>
        ) : (
          <Typography variant='body1' className='nte-price18'>
            {formatToDollars(parseFloat(currentOrderItem?.orderItemPrice))}
          </Typography>
        )}
      </div>
    </li>
  );
};

export { MiniCartThumbNailList };
