const BASE_URL: string = '/search/resources/api/v2/urls?storeId=';

const SeoConstants = {
  SEO_URL: (storeID: string, seoHref: string, enrichedPlpUrl: string) =>
    `${BASE_URL}${storeID}&langId=-1&identifier=${seoHref}${
      enrichedPlpUrl ? `&profileName=${enrichedPlpUrl}` : ''
    }`,
};

export { SeoConstants, BASE_URL };
