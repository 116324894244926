import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import scssVars from './export.module.scss';

declare module '@material-ui/core/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

declare module '@material-ui/core/Button' {
  interface ButtonPropsVariantOverrides {
    reverse: true;
  }
}

let muitheme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: scssVars.cPrimary,
    },
    secondary: {
      main: scssVars.cSecondary,
    },
    error: {
      main: scssVars.cWarning,
    },
    warning: {
      main: scssVars.cWarning,
    },
    info: {
      main: scssVars.cInfo,
    },
    success: {
      main: scssVars.cSuccess,
    },
    action: {},
  },
  typography: {
    fontFamily: scssVars.familySans,
    h1: {
      fontFamily: scssVars.familyH1,
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: 1,
    },
    h2: {
      fontFamily: scssVars.familyHeaders,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: 1,
    },
    h3: {
      fontFamily: scssVars.familyHeaders,
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '24px',
    },
    h4: {
      fontFamily: scssVars.familyHeaders,
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: scssVars.familyHeaders,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: scssVars.familyHeaders,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1.5,
    },
    button: {
      fontFamily: scssVars.familySans,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: 0,
      textTransform: 'none',
    },
    body1: {
      fontFamily: scssVars.familySans,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    body2: {
      fontFamily: scssVars.familySans,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
  },
  shape: {
    borderRadius: 3,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080,
      lg: 1280,
      xl: 1440,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export const ntev9 = responsiveFontSizes(muitheme, {
  variants: ['h1', 'h2'],
});

export default ntev9;
