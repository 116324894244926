import { createAction } from '@reduxjs/toolkit';
import {
  ITaxInformationRequest,
  ITaxInformationResponse,
} from '../../_foundation/interface/TaxInformation/ITaxInformation';
import * as ACTION_TYPES from '../action-types/tax-information.action-types';

const GET_TAX_INFORMATION_ACTION = createAction<ITaxInformationRequest>(
  ACTION_TYPES.GET_TAX_INFORMATION
);

const GET_TAX_INFORMATION_SUCCESS_ACTION =
  createAction<ITaxInformationResponse>(
    ACTION_TYPES.GET_TAX_INFORMATION_SUCCESS
  );

const RESET_TAX_INFORMATION_ACTION = createAction(
  ACTION_TYPES.RESET_TAX_INFORMATION
);

export {
  GET_TAX_INFORMATION_SUCCESS_ACTION,
  GET_TAX_INFORMATION_ACTION,
  RESET_TAX_INFORMATION_ACTION,
};
