import { useRef } from 'react';
import useInViewPort from '../../ProductOffer/ViewPort/useInViewPort';

export interface IRating {
  partNumber?: string;
  isSimpleProductCard?: boolean;
  isCompareProduct?: boolean;
}

const Rating = ({
  partNumber,
  isSimpleProductCard,
  isCompareProduct,
}: IRating) => {
  const refData = useRef<HTMLDivElement>(null);

  const { inViewport } = useInViewPort(refData);
  return (
    <div ref={refData}>
      {partNumber && !isSimpleProductCard && inViewport && (
        <div
          className={
            isCompareProduct
              ? 'rating-container offline-page'
              : 'rating-container'
          }
          data-bv-show='inline_rating'
          data-bv-seo='true'
          data-bv-product-id={partNumber}></div>
      )}
    </div>
  );
};

export { Rating };
