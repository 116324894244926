import React from 'react';

/**
 * @interface IModalBody
 */
interface IModalBody {
  /**
   * @prop children elements for modal body.
   */
  children: JSX.Element;

  /**
   * @prop class name for modal body.
   */
  className?: string;
}

/**
 * @component ModalBody renders the modal body.
 */
const ModalBody: React.FC<IModalBody> = ({ children, className = '' }) => (
  <div className={`modal_body ${className}`}>{children}</div>
);

export { ModalBody };
