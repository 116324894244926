import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION } from '../../../redux/actions/usable-shipping-info';
import { usableShippingInfoSelector } from '../../../redux/selectors/usable-shipping-info.selector';
import { LineType } from '../../../_foundation/enum/ProductList/ProductList';
import { MenuListHeader } from '../Menu/MenuListHeader/MenuListHeader';
import { DeliveryOptionChildren } from '../Orders/DeliveryOptions/DeliveryOptionsChildren';
import { ShippingInfoDrawerConstants } from './ShippingInfoDrawer.constants';

/**
 * @interface IShippingInfoDrawer
 */
interface IShippingInfoDrawer {
  shippingInfoCloseHandler?: any;
  partNumber?: string;
  lineType: LineType;
}

/**
 * @component ShippingInfoDrawer renders the shipping information drawer for the PDPs
 */
const ShippingInfoDrawer: React.FC<IShippingInfoDrawer> = ({
  shippingInfoCloseHandler,
  partNumber,
  lineType,
}) => {
  const { SHIPPING_OPTIONS, LIFT_GATE, LIFT_GATE_MESSAGE } =
    ShippingInfoDrawerConstants;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { usableShippingMethodData } = useSelector(usableShippingInfoSelector);

  const ligtGateEligibleItem: string[] = [];

  usableShippingMethodData.forEach((mode) => {
    if (Number(mode?.totalLiftgate) > 0) {
      ligtGateEligibleItem.push(mode?.shipModeCode);
    }
    return '';
  });

  useEffect(() => {
    return () => {
      dispatch(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());
    };
  }, [dispatch]);

  return (
    <div className='shipping_info_drawer'>
      <MenuListHeader
        closeHandler={shippingInfoCloseHandler}
        menuTitle={t(SHIPPING_OPTIONS)}
        id='stock-locator-header'
      />
      <DeliveryOptionChildren
        isShippingInfoDrawer={true}
        partNumber={partNumber}
        lineType={lineType}
      />
      {ligtGateEligibleItem?.length > 0 && (
        <>
          <Typography variant='h5'>{t(LIFT_GATE)}</Typography>
          <Typography variant='body2'>{t(LIFT_GATE_MESSAGE)}</Typography>
        </>
      )}
    </div>
  );
};

export { ShippingInfoDrawer };
