import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RouteGuards, routes } from '../../../configs/routes';
import { SIGN_IN } from '../../../constants/routes';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';

/**
 * @method useRedirectAfterSignIn Redirects the user to the sign in page once the user's session get's
 * invalid or if the user is not logged in, on loggin again the user is redirected back to the desired
 * authenticated route.
 *
 * @param pathname Current component's route incase of nested routes current nested router's parent route.
 */
const useRedirectAfterSignIn = (pathname: string) => {
  const { pathname: redirectPath, search } = useLocation();

  const history = useHistory();

  const { isUserAuthenticated, isSessionInvalid } = useSelector(
    authenticationSelector
  );

  /**
   * @callback redirectAfterSignIn Redirects the user back to signin when not logged in
   * once the user logs in they get redirected to the desired route.
   */
  const redirectAfterSignIn = useCallback((): void => {
    const matchedRoute = routes.find((route) => pathname === route.path);

    const isUnAuthenticatedRoute =
      matchedRoute?.guard === RouteGuards.UNAUTHENTICATED ||
      matchedRoute?.guard === RouteGuards.COMMON;

    if (
      !isUserAuthenticated &&
      !isUnAuthenticatedRoute &&
      matchedRoute &&
      isSessionInvalid
    ) {
      history.push(SIGN_IN, { pathname: redirectPath + search });
    } else if (
      !isUserAuthenticated &&
      !isUnAuthenticatedRoute &&
      matchedRoute
    ) {
      history.push(SIGN_IN, { pathname: redirectPath + search });
    }
  }, [
    history,
    isSessionInvalid,
    isUserAuthenticated,
    pathname,
    redirectPath,
    search,
  ]);

  useEffect(() => {
    redirectAfterSignIn();
  }, [redirectAfterSignIn]);
};

export { useRedirectAfterSignIn };
