import { Box, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FOOTER_EMAIL_SIGNUP_TEALIUM,
  FORM_START,
  FORM_SUBMIT,
} from '../../../constants/Tealium';
import { sendTealiumData } from '../../../utils/utils';
import { EmailDealsService } from '../../../_foundation/apis/emailDeals/emailDeals.service';
import { EmailDealsServiceConstants } from '../../../_foundation/apis/emailDeals/emailDeals.service.constants';
import { yottaService } from '../../../_foundation/apis/transaction/yotta/yotta.service';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { EmailDealsConstants } from './EmailDealsConstants';
import { useNteAlert } from '../../NteAlert/hooks/NteAlertHooks';
import { FieldValidations } from '../../Forms/React-Form/FieldValidation/FieldValidation';
import { NteButton } from '../../Forms/NteButton/NteButton';
import { AxiosError, AxiosResponse } from 'axios';
import { IError } from '../../../_foundation/interface/NetworkErrors/INetworkErrors';
import { NteAlert } from '../../NteAlert/NteAlert';

interface IEmailDeals {
  initialErrorMessage?: string;
}

const EmailDeals: React.FC<IEmailDeals> = ({ initialErrorMessage = '' }) => {
  const {
    TITLE1,
    TITLE2,
    SIGNUP,
    CANADA,
    SUCCESS_MESSAGE,
    MAX_LENGTH,
    EMAIL_REQUIRED,
    INVALID_FORMAT,
    INVALID_CHARACTER,
    TITLE1_FALLBACK,
    TITLE2_FALLBACK,
    SIGNUP_FALLBACK,
  } = EmailDealsConstants;

  const [emailValue, setEmailValue] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>(initialErrorMessage);

  const [dirty, setDirty] = useState<string>(initialErrorMessage);

  const [errorDisplay, setErrorDisplay] = useState<boolean>(false);

  const [processingEmail, setProcessingEmail] = useState<boolean>(false);

  const [submissionErrors, setSubmissionErrors] = useState<Array<string>>([]);

  const { mySite } = useSite();

  // Initialize translation.
  const { t, i18n } = useTranslation();

  const { setAlert } = useNteAlert();

  const ref: any = useRef(null);

  const { countryCode } = yottaService.getGeoLocation();

  const onEmailChange = (e: any) => {
    setEmailValue(e.target.value);

    const invalid = FieldValidations.UNICODE_EMAIL(
      e.target.value,
      INVALID_CHARACTER
    );

    const required = FieldValidations.REQUIRED(e.target.value, EMAIL_REQUIRED);

    const invalidEmail = FieldValidations.EMAIL(e.target.value, INVALID_FORMAT);

    const error = invalid || required || invalidEmail;

    if (Boolean(error)) {
      setErrorDisplay(true);

      setErrorMessage(error);

      setDirty(initialErrorMessage);
    } else {
      setErrorDisplay(false);

      setErrorMessage(initialErrorMessage);

      setDirty(initialErrorMessage);
    }
  };

  const handleSubmit = async () => {
    try {
      if (Boolean(!emailValue)) {
        ref.current.focus();
        setErrorDisplay(true);

        setErrorMessage(EMAIL_REQUIRED);
      }

      if (errorDisplay) {
        ref.current.focus();
      }

      if (emailValue && !errorDisplay) {
        setProcessingEmail(true);

        await EmailDealsService.optinEmailDeals({
          storeId: mySite?.storeID,
          emailOptDeals: {
            mailType: EmailDealsServiceConstants.MAIL_TYPES.FOOTER,
            data: [{ name: 'email', value: emailValue }],
          },
        });

        sendTealiumData({
          tealium_event: FORM_SUBMIT,
          form_name: FOOTER_EMAIL_SIGNUP_TEALIUM,
        });

        setProcessingEmail(false);

        setAlert({
          message: t(SUCCESS_MESSAGE),
          severity: 'success',
        });
      }
    } catch (err) {
      console.error(err);

      const axiosError: AxiosError = err as AxiosError;

      const errors: Array<string> = [];

      const {
        data: { errors: responseErrors },
      } = axiosError?.response as AxiosResponse;

      responseErrors.forEach((signInError: IError) => {
        errors.push(signInError.errorMessage);
      });

      setSubmissionErrors(errors);

      setProcessingEmail(false);
    }
  };

  /**
   * @method
   * Send form event to tealium
   */
  const formDataCapture = () => {
    sendTealiumData({
      tealium_event: FORM_START,
      form_name: FOOTER_EMAIL_SIGNUP_TEALIUM,
    });
  };

  const onBlur = () => {
    if (errorMessage || emailValue) {
      setDirty(initialErrorMessage);
    } else {
      setErrorDisplay(true);

      setDirty(EMAIL_REQUIRED);
    }
  };

  return (
    <Box className='email_details'>
      {countryCode !== CANADA && (
        <>
          <Typography className='category-heading' variant='h3'>
            <Box textAlign='left' fontWeight='fontWeightBold'>
              {i18n.exists(TITLE1).valueOf() ? t(TITLE1) : TITLE1_FALLBACK}
            </Box>
          </Typography>
          <Typography className='category-links' variant='body2'>
            {i18n.exists(TITLE2).valueOf() ? t(TITLE2) : TITLE2_FALLBACK}
          </Typography>

          {submissionErrors.length > 0 && (
            <NteAlert
              messages={submissionErrors}
              severity='error'
              className='inline'
              alertCloseHandler={() => {
                setSubmissionErrors([]);
              }}
              showClose={true}
            />
          )}

          <form name='email-signup' onSubmit={(e) => e.preventDefault()}>
            <div className='email-signup-form input-button-pair'>
              <TextField
                label='Email Address'
                type='email'
                placeholder={'Enter Valid Email'}
                InputProps={{
                  autoComplete: 'off',
                  autoCorrect: 'off',
                }}
                inputProps={{
                  'aria-label': 'Email',
                  maxLength: MAX_LENGTH,

                  autoCapitalize: 'none',
                }}
                fullWidth={false}
                classes={{ root: 'email-input' }}
                variant='outlined'
                onFocus={formDataCapture}
                onChange={(e) => onEmailChange(e)}
                onBlur={() => onBlur()}
                required
                value={emailValue}
                autoComplete='email'
                inputRef={ref}
                error={errorDisplay}
                helperText={dirty ? t(dirty) : t(errorMessage)}
              />

              <NteButton
                loading={processingEmail}
                className='sign-up-button'
                translationKey={
                  i18n.exists(SIGNUP).valueOf() ? t(SIGNUP) : SIGNUP_FALLBACK
                }
                variant='outlined'
                type='button'
                onClick={handleSubmit}
              />
            </div>
          </form>
        </>
      )}
    </Box>
  );
};

export default EmailDeals;
