import { Skeleton } from '@material-ui/lab';

const ESpotModalSkeleton = () => {
  return (
    <div className='cg-cols-2 cmc-content' style={{ width: '300px' }}>
      <div className='right'>
        <Skeleton className='page-skeleton' variant='rect' />
      </div>

      <div className='left card-content'>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  );
};

export { ESpotModalSkeleton };
