import { isToday } from 'date-fns';
import { IBadgeObj } from '../components/Widgets/Plp/ProductCard/ProductCardSectionDescription/BadgeHooks';
import { BadgeHookConstants } from '../components/Widgets/Plp/ProductCard/ProductCardSectionDescription/BadgeHooksConstants';
import { UserType } from '../_foundation/enum/User/UserType';
import { checkTruthy } from './utils';

/**
 * @global Utility that handles logic related to badges.
 */
const BadgesUtility = {
  /**
   * @method getByBadgeAttr is responsible for getting all badges using the "BADGE" attribute.
   */
  getByBadgeAttr(badgeValue: any, userType: UserType) {
    const { ON_SALE } = BadgeHookConstants;

    let badgeObj: IBadgeObj;

    let badgeList: IBadgeObj[] = [];

    if (typeof badgeValue.value === 'string') {
      if (badgeValue.value.value === ON_SALE) {
        if (userType === 0 || userType === 1) {
          badgeObj = {
            label: badgeValue.value,
          };
          badgeList.push(badgeObj);
        }
      }

      if (badgeValue.value.value !== ON_SALE) {
        badgeObj = {
          label: badgeValue.value,
        };
        badgeList.push(badgeObj);
      }
    } else if (badgeValue.values) {
      for (let i = 0; i < badgeValue.values.length; i++) {
        if (badgeValue.values[i].value === ON_SALE) {
          if (userType === 0 || userType === 1) {
            badgeObj = {
              label: badgeValue.values[i].value,
            };
            badgeList.push(badgeObj);
          }
        }

        if (badgeValue.values[i].value !== ON_SALE) {
          badgeObj = {
            label: badgeValue.values[i].value,
          };
          badgeList.push(badgeObj);
        }
      }
    } else {
      for (let i = 0; i < badgeValue.value.length; i++) {
        if (badgeValue.value[i] === ON_SALE) {
          if (userType === 0 || userType === 1) {
            badgeObj = {
              label: badgeValue.value[i],
            };
            badgeList.push(badgeObj);
          }
        }

        if (badgeValue.value[i] !== ON_SALE) {
          badgeObj = {
            label: badgeValue.value[i],
          };
          badgeList.push(badgeObj);
        }
      }
    }

    return badgeList;
  },

  /**
   * @method getByDealOfTheDayAttr is responsible for getting all badges using the "DEAL OF THE DAY" attribute.
   */
  getByDealOfTheDayAttr(dealOfTheDay: any) {
    const { DEAL_OF_THE_DAY } = BadgeHookConstants;

    let badgeList: IBadgeObj[] = [];

    /**
     * Flag to determine if the
     * product should display
     * 'Deal of the Day' Badge.
     */
    let hasDealForToday = false;

    let badgeObj: IBadgeObj;

    if (dealOfTheDay && dealOfTheDay.length > 0) {
      /**
       * The 'Deal of the Day'
       * attribute can have multiple
       * attribute values as the expectation
       * is to show the badge if any attribute
       * value is equal to the current date.
       *
       * We use the flat method as the v2 product API
       * returns the value as an array. The Array.flat()
       * method flattens this array. eg - [['12/6/2022','1/1/2023']] => ['12/6/2022','1/1/2023']
       *
       */
      const dates = dealOfTheDay[0].values
        .map(({ value }: any) => value)
        .flat();

      /**
       * Iterate over each date and check
       * if it has the current date. If so
       * update the hasDealForToday flag to true.
       */
      for (let date of dates) {
        /**
         * We are checking whether the date value is an actual date.
         *
         * Date string values generally gets parsed properly in chrome, firefox and edge.
         * They do break in Safari and throws NaN, we are running this check to prevent
         * the site from breaking in safari.
         */
        if (Date.parse(date)) {
          const dealDate = new Date(date);

          if (isToday(dealDate)) {
            hasDealForToday = true;
            break;
          }
        }
      }
    }
    if (hasDealForToday) {
      badgeObj = {
        label: DEAL_OF_THE_DAY,
      };
      badgeList.push(badgeObj);
    }

    return badgeList;
  },
  /**
   * @method getByRatingAttr is responsible for getting all badges using the rating and review count value from BV
   */
  getByRatingAttr(rating: number, review: number) {
    const { TOP_RATED } = BadgeHookConstants;

    let badgeList: IBadgeObj[] = [];

    let badgeObj: IBadgeObj;

    if (rating >= 4.5 && review > 20) {
      badgeObj = {
        label: TOP_RATED,
      };
      badgeList.push(badgeObj);
    }
    return badgeList;
  },

  /**
   * @method getJumboSizeBadge
   * Responsible to get Jumbo Size badge
   */
  getJumboSizeBadge(jumboAttr: any) {
    const { JUMBO_SIZE } = BadgeHookConstants;

    let badgeList: IBadgeObj[] = [];

    if (jumboAttr && jumboAttr.length > 0) {
      let badgeObj: IBadgeObj;
      if (checkTruthy(jumboAttr[0]?.values[0]?.value)) {
        badgeObj = {
          label: JUMBO_SIZE,
        };
        badgeList.push(badgeObj);
      }
    }
    return badgeList;
  },

  /**
   * @method getExclusiveBadge
   * Responsible to get Exclusive Badge
   */
  getExclusiveBadge(exclusiveAttr: any) {
    const { EXCLUSIVE_VALUE } = BadgeHookConstants;

    let badgeList: IBadgeObj[] = [];

    if (exclusiveAttr && exclusiveAttr.length > 0) {
      let badgeObj: IBadgeObj;
      if (checkTruthy(exclusiveAttr[0]?.values[0]?.value)) {
        badgeObj = {
          label: EXCLUSIVE_VALUE,
        };
        badgeList.push(badgeObj);
      }
    }
    return badgeList;
  },

  /**
   * @method getUniqueBadges removes all duplicated badge values from the array.
   */
  getUniqueBadges(badges: IBadgeObj[]) {
    const uniqueBadges = new Set(badges.map((e) => JSON.stringify(e)));

    const uniqueValues = Array.from(uniqueBadges).map((e) => JSON.parse(e));

    return uniqueValues;
  },
};

export { BadgesUtility };
