import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TaglineHorizontalIcon } from '../../theme/assets/made-for-this.svg';
import { useCheckout } from '../Checkout/hooks/CheckoutHooks';
import { FooterConstants } from './Footer.constants';
import FooterLinks from './FooterLinks/FooterLinks';
import SocialMediaLinks from './SocialMedia/SocialMediaLinks';

/**
 * @component NteFooter renders the Footer for the website.
 */
const NteFooter: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    FOOTER_BOTTOM_TEXT_1,
    FOOTER_BOTTOM_TEXT_2,
    FOOTER_BOTTOM_CONTACT_NO,
    FOOTER_CONTACT_NO,
    FOOTER_BOTTOM_TEXT_1_FALLBACK,
    FOOTER_BOTTOM_TEXT_2_FALLBACK,
    FOOTER_BOTTOM_CONTACT_NO_FALLBACK,
  } = FooterConstants;

  const { isCheckout } = useCheckout();

  const bottomText1 = i18n.exists(FOOTER_BOTTOM_TEXT_1).valueOf()
    ? t(FOOTER_BOTTOM_TEXT_1)
    : FOOTER_BOTTOM_TEXT_1_FALLBACK;

  const bottomText2 = i18n.exists(FOOTER_BOTTOM_TEXT_2).valueOf()
    ? t(FOOTER_BOTTOM_TEXT_2)
    : FOOTER_BOTTOM_TEXT_2_FALLBACK;

  const bottomContactNo = i18n.exists(FOOTER_BOTTOM_CONTACT_NO).valueOf()
    ? t(FOOTER_BOTTOM_CONTACT_NO)
    : FOOTER_BOTTOM_CONTACT_NO_FALLBACK;

  return (
    <footer className='print-exclude footer-container'>
      <div className='footer_grid_outer'>
        <FooterLinks />
        {!isCheckout && (
          <>
            <Divider />
            <SocialMediaLinks />
          </>
        )}
      </div>

      <Grid container item xs={12} className='bottom_logo'>
        <TaglineHorizontalIcon role='img' aria-label='madeforthis icon' />
      </Grid>

      <Grid
        container
        justifyContent='space-between'
        item
        className='bottom-text full-width'>
        <Typography variant='body2'>
          {bottomText1}
          <a rel='nofollow' href={`tel:${FOOTER_CONTACT_NO}`}>
            <span className='footer-contact-no'>{bottomContactNo}</span>
          </a>
        </Typography>
        <Typography variant='body2' className='copyright'>
          {bottomText2}
        </Typography>
      </Grid>
    </footer>
  );
};

export default NteFooter;
