/**
 * @enum AddressType.
 */
export enum AddressType {
  SHIPPING = 'Shipping',
  BILLING = 'Billing',
}

export enum DeliveryType {
  RESIDENTIAL = 'residential',
  RESIDENTIAL_CHAR = 'H',
  BUSINESS = 'business',
  BUSINESS_CHAR = 'B',
}

/**
 * @interface IAddressFormProps
 */
export interface IAddressFormProps {
  /**
   * States the type of the form either shipping/billing.
   */
  formType: AddressType;

  /**
   * The addAddress prop is a function callback that is called  when the form is submitted.
   */
  addAddress: (values: any) => any;

  /**
   * disableCheckbox is used to disable the setDefault checkbox.
   */
  disableCheckbox?: boolean;

  /**
   * Preset values for the form incase if the user is editing the form.
   */
  defaultValues?: Partial<IAddressForm>;

  /**
   * States when the Loader should be shown if the form is submitted and processing.
   */
  processingForm: boolean;

  /**
   * callback to set whether to set the loading.
   */
  setProcessingForm: (show: boolean) => void;

  /**
   * States whether the form is used in the checkout flow.
   */
  checkout?: boolean;

  /**
   * callback when cancel button is pressed.
   */
  cancelCallback?: () => void;

  /**
   * States whether to show the manual address form.
   */
  showManualAddressForm?: boolean;

  /**
   * callback to set whether to show the manual address form.
   */
  setShowManualAddressForm?: (show: boolean) => void;

  /**
   * show the Add Shipping Address / Edit Shipping Address label
   */
  shippingAddressLabel?: string;

  /**
   * responsible to track the cancel button is clicked
   */
  setIsCancelClicked?: (isCancelButtonClicked: boolean) => void;
}

/**
 * @interface IAddressForm
 */
export interface IAddressForm {
  nickName?: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  streetAddress: string;
  streetAddress2?: string;
  setDefaultAddress?: boolean;
  city: string;
  country: string;
  state: string | null;
  zipCode: string;
  phoneNumber: string;
  email?: string;
  emailDeals?: boolean;
  useForBilling?: boolean;
  avsTypeAheadAddress?: string;
  isVerified?: boolean;
  xcont_lastUpdate?: string;
  phoneOutsideUS?: string;
  primary?: string;
  deliveryType: string;
}

export interface states {
  code: string;
  displayName: string;
}

export interface country {
  callingCode: string;
  code: string;
  displayName: string;
  states: states[];
}

export interface ICountries {
  country: country[];
}

export interface IAddressFormFields {
  nickName: string;
  firstName: string;
  lastName: string;
  businessName: string;
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  email: string;
  setDefaultAddress: string;
  phoneOutsideUS?: string;
}
