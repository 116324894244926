import { useState } from 'react';

/**
 * @method useTextInput is used to set current value and
 * handles the input change events of an text input field.
 */
const useTextInput = (initialValue: string = '') => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);

  const onInputChange = (e: any): void => {
    setCurrentValue(e.target.value);
  };

  const resetValue = (): void => {
    setCurrentValue(initialValue);
  };

  return { onInputChange, currentValue, resetValue};
};

export { useTextInput };
