import { AxiosRequestConfig } from 'axios';
import {
  IFetchStoreByAddressParam,
  IFetchStoreByIdParam,
  IFetchStoreParams,
  StoreDetails,
} from '../../interface/StoreLocator/IStoreLocator';
import { makeUnauthorizedRequest } from '../axios/axiosConfig';
import { StoreLocatorServiceConstants } from './storeLocation.service.constants';

const storeLocatorService = {
  /**
   * @method fetchStores Fetches the store details data for the given geoCoordinates.
   * @param {IFetchStoreParams} storeID store Id retrieved from site service.
   * @param {IFetchStoreParams} latitude of the current location.
   * @param {IFetchStoreParams} longitude of the current location.
   */
  async fetchStores({
    storeID,
    latitude,
    longitude,
    radius,
    partNumber,
    orderId,
    country,
    anchorStore,
  }: IFetchStoreParams): Promise<StoreDetails> {
    const {
      STORE_DETAILS_URL,
      STORE_INVENTORY_DETAILS_LAT_LONG_URL,
      STORE_PICKUP_INVENTORY_DETAILS_LAT_LONG_URL,
      STORES_BY_LOCATION_URL,
      US,
      USA,
    } = StoreLocatorServiceConstants;

    let STORE_PATH = STORE_DETAILS_URL(storeID, latitude, longitude, radius);

    /**
     * Store path updated if part number provided
     * to call inventory API to get the stock status.
     */
    if (partNumber) {
      STORE_PATH = STORE_INVENTORY_DETAILS_LAT_LONG_URL(
        storeID,
        latitude,
        longitude,
        partNumber,
        radius
      );
    }

    if (orderId) {
      STORE_PATH = STORE_PICKUP_INVENTORY_DETAILS_LAT_LONG_URL(
        storeID,
        latitude,
        longitude,
        orderId,
        anchorStore,
        radius
      );
    }

    if (country) {
      // Below check is added to handle BE mismatch on country code.
      if (country === US) {
        country = USA;
      }
      STORE_PATH = STORES_BY_LOCATION_URL(storeID, country);
    }

    try {
      const request: AxiosRequestConfig = {
        url: STORE_PATH,
        method: 'GET',
      };

      const storeResponse = await makeUnauthorizedRequest(request);

      return storeResponse;
    } catch (error) {
      /**
       * Error thrown will be handled by saga worker.
       */
      throw error;
    }
  },

  /**
   * @method fetchStoresByAddress Fetches the store details data for the given address.
   * @param {IFetchStoreByAddressParam} storeID store Id retrieved from site service.
   * @param {IFetchStoreByAddressParam} address Address of the store to be retrieved.
   */
  async fetchStoresByAddress({
    address,
    storeID,
    partNumber,
    orderId,
  }: IFetchStoreByAddressParam): Promise<StoreDetails> {
    const {
      STORE_DETAILS_SEARCH_URL,
      STORE_INVENTORY_DETAILS_ADDRESS_URL,
      STORE_INVENTORY_DETAILS_ADDRESS_URL_WITH_ORDERID,
    } = StoreLocatorServiceConstants;

    let STORE_PATH = STORE_DETAILS_SEARCH_URL(storeID, address);

    /**
     * Store path updated if part number provided
     * to call inventory API to get the stock status.
     */
    if (partNumber) {
      STORE_PATH = STORE_INVENTORY_DETAILS_ADDRESS_URL(
        storeID,
        address,
        partNumber
      );
    }

    if (orderId) {
      STORE_PATH = STORE_INVENTORY_DETAILS_ADDRESS_URL_WITH_ORDERID(
        storeID,
        address,
        orderId
      );
    }

    try {
      const request: AxiosRequestConfig = {
        url: STORE_PATH,
        method: 'GET',
      };

      const storeResponse = await makeUnauthorizedRequest(request);

      return storeResponse;
    } catch (error) {
      /**
       * Error thrown will be handled by saga worker.
       */
      throw error;
    }
  },

  /**
   * @method fetchStoresById Fetches the store details data for the given id.
   * @param {IFetchStoreByIdParam} storeID store Id retrieved from site service.
   * @param {IFetchStoreByIdParam} id tokenExternalValue of the store.
   */
  async fetchStoresById({
    id,
    storeID,
  }: IFetchStoreByIdParam): Promise<StoreDetails> {
    const { STORES_BY_STORE_ID } = StoreLocatorServiceConstants;

    const STORE_PATH = STORES_BY_STORE_ID(storeID, id);

    try {
      const request: AxiosRequestConfig = {
        url: STORE_PATH,
        method: 'GET',
      };

      const storeResponse = await makeUnauthorizedRequest(request);

      return storeResponse;
    } catch (error) {
      /**
       * Error thrown will be handled by saga worker.
       */
      throw error;
    }
  },
};

export { storeLocatorService };
