import { takeLatest } from 'redux-saga/effects';
import {
  CARDINAL_ORDER_REQUEST_ACTION,
  FETCH_CHECKOUT_CART_DETAILS_ACTION,
} from '../../actions/checkout.actions';
import {
  fetchCheckoutCartDetails,
  makeCardinalOrder,
} from '../workers/checkout.workers';

export function* watchSaga() {
  yield takeLatest(
    FETCH_CHECKOUT_CART_DETAILS_ACTION,
    fetchCheckoutCartDetails
  );

  yield takeLatest(CARDINAL_ORDER_REQUEST_ACTION, makeCardinalOrder);
}
