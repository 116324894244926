import CityValidator from '../../../utils/validators/city.validation';
import { EmailValidator } from '../../../utils/validators/email.validation';
import {
  BusinessNameValidator,
  FirstNameValidator,
  LastNameValidator,
  NickNameValidator,
} from '../../../utils/validators/name.validation';
import {
  PhoneNumberOutsideUSValidator,
  PhoneNumberValidator,
} from '../../../utils/validators/phone.validation';
import {
  StreetAddress2Validator,
  StreetAddressValidator,
} from '../../../utils/validators/streetAddress.validation';
import {
  ZipCodeCanadaValidator,
  ZipCodeOutsideUSCanadaValidator,
  ZipCodeUSValidator,
} from '../../../utils/validators/zipCode.validation';
import { FieldFormatting } from '../../Forms/React-Form/FieldFormatting/FieldFormatting';
import { FieldParsing } from '../../Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../Forms/React-Form/FieldValidation/FieldValidation';

const AddressFormConstants = {
  FORM_FIELDS: {
    AVS_ADDRESS_TYPE_AHEAD: {
      name: 'avsTypeAheadAddress',
      id: 'avs-address',
      label: 'Start typing your street address',
      type: 'text',
      sensitiveData: true,
    },
    COUNTRY: {
      name: 'country',
      id: 'country',
      selectLabel: 'Country/Region*',
      disableAutoComplete: true,

    },
    STATE: {
      name: 'state',
      id: 'state',
      selectLabel: 'State/Province*',
      type: 'text',
      sensitiveData: true,
      disableAutoComplete: true,

    },
    ZIP_CODE: {
      name: 'zipCode',
      id: 'zip-code',
      label: 'ZIP Code*',
      type: 'text',
      sensitiveData: true,
    },
    SET_DEFAULT_ADDRESS: {
      name: 'setDefaultAddress',
      id: 'set-default-address',
      label: '',
      ariaLabel: 'Set Address as default address',
    },
    USE_FOR_BILLING: {
      name: 'useForBilling',
      id: 'id-billing-address',
      label: '',
      ariaLabel: 'Use this same address for billing.',
    },
    EMAIL_DEALS: {
      name: 'emailDeals',
      id: 'sing-up-email-deals',
      label: '',
      ariaLabel: 'Sign up for email deals',
      sensitiveData: true,
    },
    EMAIL: {
      name: 'email',
      id: 'email-address',
      label: 'Email Address',
      type: 'email',
      fieldParse: FieldParsing.EMAIL,
      sensitiveData: true,
    },
    PHONE_NUMBER: {
      name: 'phoneNumber',
      id: 'phone-number',
      label: 'Phone Number',
      type: 'text',
      fieldParse: FieldParsing.PHONE_NUMBER_OUTSIDE_US,
      sensitiveData: true,
    },
    PHONE_NUMBER_OUTSIDE_US: {
      name: 'phoneOutsideUS',
      id: 'phone-number-non-us',
      label: 'Phone Number',
      type: 'text',
      fieldParse: FieldParsing.PHONE_NUMBER_OUTSIDE_US,
    },

  },
  TRANSLATIONS: {
    CREATE_ACCOUNT: 'ACCOUNT_REGISTRATION.CREATE_ACCOUNT',
    REQUIRED_FIELDS: 'ACCOUNT_REGISTRATION.REQUIRED_FIELDS',
    BILLING_ADDRESS: 'ACCOUNT_REGISTRATION.BILLING_ADDRESS',
    MATCH_CREDIT_CARD: 'ACCOUNT_REGISTRATION.MATCH_CREDIT_CARD',
    ADD_BUSINESS_NAME: 'ACCOUNT_REGISTRATION.ADD_BUSINESS_NAME',
    ADD_STREET_ADDRESS_2: 'ACCOUNT_REGISTRATION.ADD_STREET_ADDRESS_2',
    SAVE: 'ACCOUNT_REGISTRATION.SAVE',
    CANCEL: 'ACCOUNT_REGISTRATION.CANCEL',
    OUTSIDE_US_CANADA: 'ACCOUNT.BILLING_ADDRESS.OUTSIDE_US_CANADA',
    SET_DEFAULT_ADDRESS: 'ACCOUNT.SHIPPING_ADDRESS.SET_DEFAULT_ADDRESS',
    PO_BOX_ERROR: 'ACCOUNT.SHIPPING_ADDRESS.MESSAGE.ERROR.PO_BOX_ERROR',
    USE_SAME_FOR_BILLING_ADDRESS:
      'ACCOUNT.SHIPPING_ADDRESS.USE_SAME_FOR_BILLING_ADDRESS',
    EMAIL_DEALS: 'ACCOUNT.SHIPPING_ADDRESS.SIGN_UP_EMAIL_DEALS',
    ENTER_MANUALLY: 'CHECKOUT.FORMS.ADD_SHIPPING_ADDRESS.ENTER_MANUALLY',
    START_TYPING: 'ACCOUNT_REGISTRATION.START_TYPING',
    START_TYPING_STREET_ADDRESS:
      'ACCOUNT_REGISTRATION.START_TYPING_STREET_ADDRESS',
    PLEASE_USE_ARROW_STREET_ADDRESS:
      'ACCOUNT_REGISTRATION.PLEASE_USE_ARROW_STREET_ADDRESS',
    ADD_ADDRESS_ERROR:
      'API_ERROR_HANDLING.MY_ACCOUNT.SHIPPING_ADDRESS.ADD_ADDRESS.ERROR',
  },
  VALIDATORS: {
    BUSINESS_NAME: BusinessNameValidator.BUSINESS_NAME,
    CITY: CityValidator.CITY,
    FIRST_NAME: FirstNameValidator.FIRST_NAME,
    LAST_NAME: LastNameValidator.LAST_NAME,
    NICK_NAME: NickNameValidator.NICK_NAME,
    STREET_ADDRESS: StreetAddressValidator.STREET_ADDRESS,
    STREET_ADDRESS2: StreetAddress2Validator.STREET_ADDRESS2,
    ZIP_CODE_CANADA: ZipCodeCanadaValidator.ZIP_CODE_CANADA,
    ZIP_CODE_OUTSIDE_US_CANADA:
      ZipCodeOutsideUSCanadaValidator.ZIP_CODE_OUTSIDE_US_CANADA,
    ZIP_CODE_US: ZipCodeUSValidator.ZIP_CODE_US,
    PHONE_NUMBER_US: PhoneNumberValidator.PHONE_NUMBER,
    PHONE_NUMBER_OUTSIDE_US:
      PhoneNumberOutsideUSValidator.PHONE_NUMBER_OUTSIDE_US,
    EMAIL: EmailValidator.EMAIL,    
  },
  AVS_ADDRESS_FIELDS: [
    'streetAddress',
    'streetAddress2',
    'city',
    'zipCode',
    'state',
  ],
  FIELDS_ORDER: [
    'nickName',
    'firstName',
    'lastName',
    'businessName',
    'country',
    'streetAddress',
    'streetAddress2',
    'city',
    'state',
    'zipCode',
    'phoneNumber',
    'email',
  ],
  UNITED_STATES: 'United States',
  UNITED_STATES_ISO_CODE: 'USA',
  CANADA: 'Canada',
  CANADA_ISO_CODE: 'CAN',
  OUTSIDE_US_CANADA: 'None (Outside US and Canada)',
  ALABAMA_STATE_CODE: 'AL',
  ALBERTA_STATE_CODE: 'AB',
  EXCLUSION_ADDRESS_WARNING:
    'We are unable to ship to this province. Please create a new address.',
  /**
   * Mutators allow us to change the value of a form field from some other operations.
   * Responsible to mutate any part of the form state, which updates a field value in the form state.
   */
  MUTATORS: {
    setState: (args: Array<any>, state: any, utils: any) => {
      utils.changeValue(state, args[0], () => args[1]);
    },
  },
  ZIP_CODE_US_VALIDATION: {
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_US(value, 'Please enter a valid ZIP Code.')
    ),
    fieldFormat: FieldFormatting.ZIP_CODE_US,
    fieldParse: FieldParsing.ZIP_CODE_US,
  },
  ZIP_CODE_CANADA_VALIDATION: {
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_CANADA(
          value,
          'Please enter a valid ZIP Code.'
        )
    ),
    fieldParse: FieldParsing.ZIP_CODE_CANADA,
  },
  ZIP_CODE_OUTSIDE_US_CANADA_VALIDATION: {
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_OUTSIDE_US_CANADA(
          value,
          'Please enter a valid ZIP Code.'
        )
    ),
    fieldParse: FieldParsing.ZIP_CODE_OUTSIDE_US_CANADA,
  },
  PO_BOX_REGEX:
    /^ *((#\d{1,5})|((box|bin)[-. /\\]?\d{1,5})|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b| |$|(#|num)?\d{1,5}))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d{1,5})|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d{1,5}|(num|number|#) *\d{1,5})/i,
};

export { AddressFormConstants };
