import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CookieUtility } from '../../utils/cookie-utility';
import { SESSION_ID } from '../../_foundation/constants/cookie';
import { NteAlert } from '../NteAlert/NteAlert';
import { BrowserAlertConstants } from './BrowserAlert.constants';
import { SESSION_STORAGE_KEY } from './BrowserAlert.constants';

/**
 * @component BrowserAlert renders an alert snackbar message if the user opens the website on IE.
 */
const BrowserAlert: React.FC = () => {
  const { t } = useTranslation();

  const {
    TRANSLATIONS,
    DOWNLOAD_URLS: { CHROME_URL, FIREFOX_URL, EDGE_URL, SAFARI_URL },
  } = BrowserAlertConstants;

  const [alertClosed, setAlertClosed] = useState(false);

  const [cookiesCleared, setCookiesCleared] = useState(false);

  const sessionIdCookie = CookieUtility.getCookie(SESSION_ID);

  useEffect(() => {
    if (sessionStorage.getItem(SESSION_STORAGE_KEY) !== sessionIdCookie) {
      setCookiesCleared(true);
    }
  }, [sessionIdCookie]);

  const alertMessage = (
    <span>
      {t(TRANSLATIONS.LINE_1)}
      <a href={t(CHROME_URL)} rel='noreferrer' target='_blank'>
        {t(TRANSLATIONS.BROWSERS.CHROME)}
      </a>
      ,
      <a href={t(FIREFOX_URL)} rel='noreferrer' target='_blank'>
        {t(TRANSLATIONS.BROWSERS.FIREFOX)}
      </a>
      ,
      <a href={t(EDGE_URL)} rel='noreferrer' target='_blank'>
        {t(TRANSLATIONS.BROWSERS.EDGE)}
      </a>
      , {t(TRANSLATIONS.LINE_2)}
      <a href={t(SAFARI_URL)} rel='noreferrer' target='_blank'>
        {t(TRANSLATIONS.BROWSERS.SAFARI)}
      </a>
      {t(TRANSLATIONS.LINE_3)}
    </span>
  );

  return (
    <>
      {!alertClosed && cookiesCleared && (
        <NteAlert
          severity='warning'
          messages={[alertMessage]}
          showClose={true}
          alertCloseHandler={() => {
            setAlertClosed(true);
          }}
        />
      )}
    </>
  );
};

export default BrowserAlert;
