import useMediaQuery from '@material-ui/core/useMediaQuery';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_LOGIN_ERROR_ACTION } from '../../../../../redux/actions/auth.actions';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { ReactComponent as NteAdvantageLogo } from '../../../../../theme/assets/adv_gold.svg';
import { ReactComponent as NTE_GREAT_PLAINS_GOLD } from '../../../../../theme/assets/gp-bug-gold.svg';
import { ReactComponent as NTE_PLATINUM_GOLD } from '../../../../../theme/assets/plat-bug-gold.svg';
import { checkMembership } from '../../../../../utils/utils';
import { UserType } from '../../../../../_foundation/enum/User/UserType';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../NavMenuItem/NavMenuItem';
import { AccountConstants } from './AccountConstants';
import { AccountList } from './AccountList/AccountList';
import NtePreferredLogo from './NteLogo/NtePreferredLogo';
import { SignInAccountMenu } from './SignInAccountMenu/SignInAccountMenu';

/**
 * @component Account renders the account form and account list.
 */
const Account: React.FC = () => {
  const {
    ACCOUNT_TITLE,
    ACCOUNT_NAME,
    ACCOUNT_TITLE_FALLBACK,
    ACCOUNT_NAME_FALLBACK,
    MENU_ITEMS: { ITEMS },
  } = AccountConstants;

  const accountMenuRef = useRef(null);

  const {
    userType,
    userInfo: { firstName },
    errorMessage,
  } = useSelector(authenticationSelector);

  const {
    RenderPopper: RenderSignInForm,
    ntePopperClickHandler: signInFormClickHandler,
    showPopper: showSigninForm,
  } = useNtePopper({
    id: 'account-menu',
    ariaLabelledById: 'sign-in-header',
  });

  const {
    RenderPopper: RenderAccountListPopper,
    ntePopperClickHandler: accountListClickHandler,
    showPopper: showAccountList,
  } = useNtePopper({
    id: 'account-menu',
    ariaLabelledById: 'account-heading',
  });

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const menuItems = ITEMS;

  const isNormalUser =
    userType === UserType.SignedIn || userType === UserType.NotSignedIn;

  const { isNtePreferred, isPlatinum, isGreatPlains, isAdvantage } =
    checkMembership(userType);

  const showName = useMediaQuery('(min-width:400px)');

  const accountTitle = i18n.exists(ACCOUNT_TITLE).valueOf()
    ? t(ACCOUNT_TITLE)
    : ACCOUNT_TITLE_FALLBACK;

  const accountName = i18n.exists(ACCOUNT_NAME).valueOf()
    ? t(ACCOUNT_NAME)
    : ACCOUNT_NAME_FALLBACK;

  const navMenuTitle =
    firstName && showName ? `${firstName}${accountName}` : accountTitle;

  let menuIconSvg: JSX.Element = <></>;

  let menuIconName = '';

  if (isNormalUser) {
    menuIconSvg = <PersonIcon role='img' aria-label='user icon' />;

    menuIconName = '';
  } else if (isNtePreferred) {
    menuIconSvg = (
      <NtePreferredLogo role='img' aria-label='ntePreferred icon' />
    );

    menuIconName = '';
  } else if (isAdvantage) {
    menuIconSvg = <NteAdvantageLogo role='img' aria-label='Advantage icon' />;

    menuIconName = '';
  } else if (isPlatinum) {
    menuIconSvg = <NTE_PLATINUM_GOLD role='img' aria-label='platinum icon' />;

    menuIconName = '';
  } else if (isGreatPlains) {
    menuIconSvg = (
      <NTE_GREAT_PLAINS_GOLD role='img' aria-label='greatPlains icon' />
    );

    menuIconName = '';
  }

  const userLoggedIn = userType !== UserType.NotSignedIn;

  const isInvalidId = Boolean(document.getElementById('session-invalid'));

  useEffect(() => {
    if (!showSigninForm && errorMessage && !isInvalidId) {
      dispatch(CLEAR_LOGIN_ERROR_ACTION({}));
    }
  }, [dispatch, errorMessage, showSigninForm, isInvalidId]);

  return (
    <>
      <NavMenuItem
        {...((isNormalUser ||
          isNtePreferred ||
          isAdvantage ||
          isGreatPlains ||
          isPlatinum) && {
          menuIconSvg,
        })}
        imgAlt='account-icon'
        className='account-top-menu'
        menuAnchor={accountMenuRef}
        menuIconName={menuIconName}
        menuTitle={navMenuTitle}
        onClick={
          userLoggedIn ? accountListClickHandler : signInFormClickHandler
        }
        disablePopperAction={userLoggedIn ? showAccountList : showSigninForm}
      />

      <RenderSignInForm
        anchor={accountMenuRef}
        showPopper={showSigninForm}
        ntePopperCloseHandler={signInFormClickHandler}>
        <SignInAccountMenu accountMenuCloseHandler={signInFormClickHandler} />
      </RenderSignInForm>

      <RenderAccountListPopper
        anchor={accountMenuRef}
        showPopper={showAccountList}
        ntePopperCloseHandler={accountListClickHandler}>
        <AccountList
          menuItems={menuItems}
          accountListCloseHandler={accountListClickHandler}
        />
      </RenderAccountListPopper>
    </>
  );
};

export { Account };
