const CategoryIndexPageConstants = {
  categoryHeaderImg:
    'https://via.placeholder.com/1200x150?text=Category+Hero+Image',
  ALL_CATEGORIES_IDENTIFIER: 'Shop_by_Category',
  ALL_BRANDS_IDENTIFIER: 'Shop_Brand_Popular',
  SEO_SUB_HEAD: {
    espotId: 'CIP_01',
  },
  CATEGORY_INDEX_TITLE: 'CATEGORY_INDEX.SHOP_BY_CATEGORY',
  ESPOT_IDS: {
    CIP_02A: 'CIP_02A',
    CIP_02B: 'CIP_02B',
  },
  META_TITLE_TAG: 'All Categories | Northern Tool',
};

export { CategoryIndexPageConstants };
