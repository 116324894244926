import { useCallback, useEffect, useState } from 'react';
import {
  LineType,
  StockStatus,
} from '../../../../../_foundation/enum/ProductList/ProductList';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../../../_foundation/interface/ProductList/IProductList';
import { ICartContentsItem } from '../../../../../_foundation/interface/Responses/ICartProductsResponse';
import { ProductCardSectionMessagesConstants } from '../ProductCardSectionMessages/ProductCardSectionMessagesConstants';

/**
 * @method useCalculateStockStatus Calculates the stock available in the inventory for a given product.
 */
const useCalculateStockStatus = (
  productCard?: IProductListContents | ICartContentsItem
) => {
  const { LINE_TYPE_IDENTIFIER } = ProductCardSectionMessagesConstants;

  const { mySite } = useSite();

  const inventoryThresholdEnv = parseInt(
    process.env.REACT_APP_DEFAULT_INVENTORY_THRESHOLD as string
  );

  const inventoryThreshold = mySite
    ? parseInt(mySite.inventoryThreshold)
    : inventoryThresholdEnv;

  const [stockStatus, setStockStatus] = useState<StockStatus>(
    StockStatus.NonStock
  );

  /**
   * @method calculateStockStatus Calculates the stock status of the current product.
   */
  const calculateStockStatus = useCallback(
    (productCard: IProductListContents | ICartContentsItem): void => {
      if (mySite) {
        const inventories: any = productCard;

        const lineType = productCard?.attributes?.find(
          ({ identifier }) => identifier.toLowerCase() === LINE_TYPE_IDENTIFIER
        )?.values[0].value;

        let inventoryCount;
        let backorderDateAvailable;

        if (inventories[`inventories.total.quantity`]) {
          inventoryCount = Number(
            inventories[`inventories.${mySite?.onlineInventory}.quantity`]
          );
          backorderDateAvailable = inventories.backorderDate ? true : false;
        } else if (
          inventories[`inventories.${mySite?.onlineInventory}.quantity`]
        ) {
          inventoryCount = Number(
            inventories[`inventories.${mySite?.onlineInventory}.quantity`]
          );
          backorderDateAvailable = inventories.backorderDate ? true : false;
        }

        if (inventoryCount) {
          if (lineType && lineType === LineType.NS) {
            setStockStatus(StockStatus.NonStock);
          } else {
            if (inventoryCount >= inventoryThreshold) {
              setStockStatus(StockStatus.InStock);
            } else {
              setStockStatus(StockStatus.Backordered);
            }
          }
        } else if (!inventoryCount && backorderDateAvailable) {
          setStockStatus(StockStatus.Backordered);
        } else {
          setStockStatus(StockStatus.NotAvailable);
        }
      }
    },
    [mySite, LINE_TYPE_IDENTIFIER, inventoryThreshold]
  );

  useEffect(() => {
    if (productCard) {
      calculateStockStatus(productCard);
    }
  }, [calculateStockStatus, productCard, mySite]);

  return { stockStatus };
};

export { useCalculateStockStatus };
