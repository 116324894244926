import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CartService } from '../../../../../_foundation/apis/cart/cart.service';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import {
  IDeleteItemInCart,
  IOrderItem,
} from '../../../../../_foundation/interface/Cart/IOrder';
import { IGiftList } from '../../../../../_foundation/interface/WishList/IWishList';
import {
  CART_REMOVE_TEALIUM,
  CART_SAVE_FOR_LATER_TEALIUM,
} from '../../../../../constants/Tealium';
import { SHOPPING_CART } from '../../../../../constants/routes';
import {
  DELETE_ITEM_IN_CART_ACTION,
  SET_REMOVE_SPINNER_ACTION,
  SET_SAVE_TO_LIST_SPINNER_ACTION,
} from '../../../../../redux/actions/order.actions';
import { RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION } from '../../../../../redux/actions/usable-shipping-info';
import { ADD_ITEM_TO_WISHLIST_ACTION } from '../../../../../redux/actions/wish-list.actions';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { orderSelector } from '../../../../../redux/selectors/order.selector';
import { storeLocatorSelector } from '../../../../../redux/selectors/storeLocator';
import { wishListSelector } from '../../../../../redux/selectors/wish-list.selector';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import { checkTruthy, sendTealiumData } from '../../../../../utils/utils';
import { NteButton } from '../../../../Forms/NteButton/NteButton';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { UnsignedSaveList } from '../../../Plp/ProductCard/ProductCardSaveList/UnsignedSaveList/UnsignedSaveList';
import { CartItemQuantityConstants } from '../CartItemQuantity/CartItemQuantityConstants';

/**
 * @interface ICartSaveLaterRemove
 */
interface ICartSaveLaterRemove {
  id: string;
  isAdvantage?: boolean;
  itemQuantity?: string;
}

/**
 * @component CartSaveLaterRemove renders the save for later and remove buttons for the cart products.
 *
 * @param ICartSaveLaterRemove
 */
const CartSaveLaterRemove: React.FC<ICartSaveLaterRemove> = ({
  id,
  isAdvantage,
  itemQuantity,
}) => {
  const {
    SAVE_FOR_LATER_LABEL,
    REMOVE,
    SAVE_FOR_LATER,
    ADVANTAGE_PARTNUMBER,
    GIFTCARD_PAY_METHOD,
  } = CartItemQuantityConstants;

  const { mySite } = useSite();

  const { t } = useTranslation();

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const dispatch = useDispatch();

  const {
    orderItem,
    orderId,
    cartProducts,
    showCartSpinner,
    showRemoveSpinner,
    showSaveToListSpinner,
    paymentInstruction,
  } = useSelector(orderSelector);

  const { pathname } = useCurrentPathName();

  const isCartPage = pathname === SHOPPING_CART;

  const productNames = cartProducts.map(({ name }) => name);

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const { giftList } = useSelector(wishListSelector);

  const cartQuantityLoading: boolean = showCartSpinner[id];

  const removeLoading: boolean = showRemoveSpinner[id];

  const saveToListLoading: boolean = showSaveToListSpinner[id];

  const disableSaveToList = cartQuantityLoading || removeLoading;

  const disableRemove = cartQuantityLoading || saveToListLoading;

  const saveForLaterRef = useRef(null);

  const { RenderPopper, ntePopperClickHandler, showPopper, setShowPopper } =
    useNtePopper({
      id: `save-for-later`,
      ariaLabelledById: `save-for-later`,
    });

  const [orderItemId, setOrderItemId] = useState<string>('');

  /**
   * Fetch current product's order item id.
   */
  const initOrderItemId = useCallback((): void => {
    /* const currentItemQuantity = orderItem.filter(
      ({ partNumber }) => partNumber === id
    )[0];

    setOrderItemId(currentItemQuantity?.orderItemId);*/
    const samePartnumberItems: IOrderItem[] = orderItem.filter(
      (obj) => obj.partNumber === id
    );
    samePartnumberItems.forEach((obj: IOrderItem) => {
      if (!checkTruthy(obj.freeGift)) {
        return setOrderItemId(obj.orderItemId);
      }
    });
  }, [id, orderItem]);

  useEffect(() => {
    initOrderItemId();
  }, [initOrderItemId]);

  /**
   * @method removeFromCartHandler removes the product from the cart.
   */
  const removeFromCartHandler = (): void => {
    dispatch(SET_REMOVE_SPINNER_ACTION({ loading: true, partNumber: id }));

    dispatch(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());

    if (paymentInstruction) {
      const giftCardPaymentInstruction = paymentInstruction?.filter(
        ({ payMethodId }) =>
          payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
      );

      if (giftCardPaymentInstruction?.length > 0) {
        giftCardPaymentInstruction.forEach(({ piId }) =>
          CartService.removeGiftCard({
            storeId: mySite.storeID,
            paymentInstructionId: piId,
          })
        );
      }
    }

    if (
      currentStoreDetails?.uniqueID &&
      currentStoreDetails?.latitude &&
      currentStoreDetails?.longitude &&
      orderId
    ) {
      const deleteCartPayload: IDeleteItemInCart = {
        storeID: mySite.storeID,
        orderItemId,
        partNumber: id,
        latitude: Number(currentStoreDetails.latitude),
        longitude: Number(currentStoreDetails.longitude),
        orderId,
        anchorStore: currentStoreDetails.uniqueID,
        radius: mySite.storeLocatorDefaultRadius,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      dispatch(DELETE_ITEM_IN_CART_ACTION(deleteCartPayload));
    } else {
      const deleteCartPayload: IDeleteItemInCart = {
        storeID: mySite.storeID,
        orderItemId,
        partNumber: id,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      dispatch(DELETE_ITEM_IN_CART_ACTION(deleteCartPayload));
    }

    const removedProduct = cartProducts.find(
      (cartProduct) => cartProduct?.partNumber === id
    );

    const removedItem = orderItem.find((item) => item?.partNumber === id);

    sendTealiumData({
      tealium_event: CART_REMOVE_TEALIUM,
      product_id: id,
      product_name: removedProduct?.name,
      product_price: removedItem?.unitPrice,
      product_quantity: removedItem?.quantity,
    });
  };

  /**
   * @method saveForLaterClickHandler
   *
   * Responsible to store the item in Save For Later wishlist
   */
  const saveForLaterClickHandler = (): void => {
    if (!isUserAuthenticated) {
      setShowPopper(true);

      return;
    }

    dispatch(RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION());

    if (mySite && isUserAuthenticated) {
      dispatch(
        SET_SAVE_TO_LIST_SPINNER_ACTION({ loading: true, partNumber: id })
      );

      /**
       * responsible to delete the Gift card Payment instruction
       * when user coming back from checkout flow and doing additional changes to cart
       */
      if (paymentInstruction) {
        const giftCardPaymentInstruction = paymentInstruction?.filter(
          ({ payMethodId }) =>
            payMethodId.toLowerCase() === GIFTCARD_PAY_METHOD.toLowerCase()
        );

        if (giftCardPaymentInstruction?.length > 0) {
          giftCardPaymentInstruction.forEach(({ piId }) =>
            CartService.removeGiftCard({
              storeId: mySite.storeID,
              paymentInstructionId: piId,
            })
          );
        }
      }

      setShowPopper(false);

      const currentGiftList = giftList.filter(
        (giftList: IGiftList) =>
          giftList.descriptionName === SAVE_FOR_LATER_LABEL
      );

      dispatch(
        ADD_ITEM_TO_WISHLIST_ACTION({
          item: SAVE_FOR_LATER_LABEL,
          storeID: mySite.storeID,
          partNumber: id,
          wishListItemId: currentGiftList[0]?.externalIdentifier,
          itemQuantity: itemQuantity,
        })
      );

      sendTealiumData({
        tealium_event: CART_SAVE_FOR_LATER_TEALIUM,
        product_id: id,
        product_name: productNames,
      });

      const deleteCartPayload: IDeleteItemInCart = {
        storeID: mySite.storeID,
        orderItemId,
        partNumber: id,
        ...{ ...(isCartPage && { isCartPage }) },
      };

      dispatch(DELETE_ITEM_IN_CART_ACTION(deleteCartPayload));
    }
  };

  const isButtonRow = () => {
    return !isAdvantage && id !== ADVANTAGE_PARTNUMBER;
  };

  return (
    <div
      className={`${
        isButtonRow() && 'button-row'
      } cta-cell cart_save_for_later_remove`}>
      {!isAdvantage && id !== ADVANTAGE_PARTNUMBER && (
        <NteButton
          variant='text'
          size='small'
          className='inline-text'
          anchor={saveForLaterRef}
          onClick={saveForLaterClickHandler}
          translationKey={t(SAVE_FOR_LATER)}
          loading={saveToListLoading}
          disabled={disableSaveToList}
          type='button'
        />
      )}

      <NteButton
        variant='text'
        size='small'
        className='inline-text'
        translationKey={t(REMOVE)}
        onClick={removeFromCartHandler}
        loading={removeLoading}
        disabled={disableRemove}
        type='button'
      />

      {showPopper && (
        <RenderPopper
          anchor={saveForLaterRef}
          ntePopperCloseHandler={ntePopperClickHandler}
          showPopper={showPopper}>
          <UnsignedSaveList
            cartFlow={true}
            clickHandler={ntePopperClickHandler}
            id={id}
          />
        </RenderPopper>
      )}
    </div>
  );
};

export { CartSaveLaterRemove };
