import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { UsableShippingService } from '../../../_foundation/apis/shipping/usable-shipping.service';
import IGetUsableShippingResponse from '../../../_foundation/interface/Responses/IGetUsableShippingResponse';
import { CheckoutFlows } from '../../../components/Checkout/enum/Checkout';
import {
  DELIVERY_PAGE_API_LOADING,
  DELIVERY_USABLE_SHIPPING_INFO_LOADING_ACTION,
  DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
} from '../../../components/Checkout/redux/actions/checkout.actions';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import i18n from '../../../i18n';
import { alertMessageId } from '../../../utils/utils';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import { NETWORK_ERROR_ACTION } from '../../actions/network-errors.actions';
import {
  FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
  FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION,
} from '../../actions/usable-shipping-info';

const translationKey = 'API_ERROR_HANDLING.CART_AND_CHECKOUT';

export function* getUsableShippingInfoSaga(action: any): Generator {
  try {
    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(DELIVERY_USABLE_SHIPPING_INFO_LOADING_ACTION(true));
    }

    const response: any = yield call(
      new UsableShippingService().getUsableShippingInfo,
      action.payload
    );

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION(response));
    } else if (action.payload.checkoutFlow === CheckoutFlows.Payment) {
      yield put(PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION(response));
    } else if (action.payload.checkoutFlow === CheckoutFlows.Review) {
      yield put(REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION(response));
    } else {
      yield put(FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION(response));
    }

    if (response) {
      yield put(NETWORK_ERROR_ACTION({ hideComponent: false }));
      yield put(DELIVERY_PAGE_API_LOADING(false));
      return;
    }
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    const fallbackShippingInfo: IGetUsableShippingResponse = {
      backOrderedProducts: [],
      isBopisEligible: false,
      liftgateservice: false,
      orderId: '',
      orderItem: [
        {
          usableShippingMode: [
            {
              carrier: 'Contact me with ship options',
              carrierEstimateDelivery: '---',
              description: '',
              freightCharge: 0,
              freightDiscount: '0',
              freightLiftgateCharge: 0,
              language: '',
              shipModeCode: 'CONT',
              shipModeId: '715838587',
              totalCost: 0,
              lineItem: '',
              totalCostWithoutLiftgate: 0,
              totalLiftgate: 0,
              totalCostWithoutLiftGateAndDiscount: 0,
              totalCostWithoutDiscount: 0,
            },
          ],
          usableShippingAddress: [],
          usableShippingChargePolicy: [],
        },
      ],
      physicalStoreId: '',
      resourceId: '',
      resourceName: '',
      restrictedItems: [],
      sameDayDelivery: false,
      usableShippingAddress: [],
      iomStoreId: '',
    };

    yield put(DELIVERY_PAGE_API_LOADING(false));

    if (action.payload.checkoutFlow === CheckoutFlows.Delivery) {
      yield put(
        DELIVERY_USABLE_SHIPPING_INFO_SUCCESS_ACTION(fallbackShippingInfo)
      );
    } else if (action.payload.checkoutFlow === CheckoutFlows.Payment) {
      yield put(
        PAYMENT_USABLE_SHIPPING_INFO_SUCCESS_ACTION(fallbackShippingInfo)
      );
    } else if (action.payload.checkoutFlow === CheckoutFlows.Review) {
      yield put(
        REVIEW_USABLE_SHIPPING_INFO_SUCCESS_ACTION(fallbackShippingInfo)
      );
    } else {
      yield put(
        FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION(fallbackShippingInfo)
      );
    }

    const { response } = axiosError;

    if (response?.status !== 200) {
      yield put(NETWORK_ERROR_ACTION({ hideComponent: true }));
      return;
    }
    console.log(e);
  }
}

export function* getExtendedUsableShippingInfo(action: any): Generator {
  try {
    const shippingMethodsResponse: any = yield call(
      new UsableShippingService().getUsableShippingMethodDetails,
      action.payload
    );

    yield put(
      FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION(
        shippingMethodsResponse
      )
    );

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));
  } catch (e) {
    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    console.error(e);

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.SHIPPING_INFO.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}
