
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IEspotResponse from './IEspotResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IEspotResponse };

  export const IEspotResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IBaseMarketingSpotActivityDataItem": {
            "properties": {
                "MarketingContentDescription": {
                    "items": {
                        "$ref": "#/definitions/IMarketingContentDescriptionItem"
                    },
                    "type": "array"
                },
                "activityIdentifierID": {
                    "type": "string"
                },
                "activityPriority": {
                    "type": "string"
                },
                "baseMarketingSpotActivityID": {
                    "type": "string"
                },
                "baseMarketingSpotActivityName": {
                    "type": "string"
                },
                "baseMarketingSpotDataType": {
                    "type": "string"
                },
                "contentClickAction": {
                    "type": "string"
                },
                "contentFormatId": {
                    "type": "string"
                },
                "contentFormatName": {
                    "type": "string"
                },
                "contentId": {
                    "type": "string"
                },
                "contentName": {
                    "type": "string"
                },
                "contentStoreId": {
                    "type": "string"
                },
                "marketingContentDescription": {
                    "items": {
                        "$ref": "#/definitions/IMarketingContentDescriptionItem"
                    },
                    "type": "array"
                },
                "properties": {
                    "items": {
                        "$ref": "#/definitions/IPropertiesItem"
                    },
                    "type": "array"
                }
            },
            "required": [
                "MarketingContentDescription",
                "activityIdentifierID",
                "activityPriority",
                "baseMarketingSpotActivityID",
                "baseMarketingSpotActivityName",
                "baseMarketingSpotDataType",
                "contentClickAction",
                "contentFormatId",
                "contentFormatName",
                "contentId",
                "contentName",
                "contentStoreId",
                "marketingContentDescription",
                "properties"
            ],
            "type": "object"
        },
        "IDefaultContentDisplaySequenceItem": {
            "properties": {
                "displaySequence": {
                    "type": "string"
                },
                "resultFormat": {
                    "type": "string"
                },
                "resultId": {
                    "type": "string"
                }
            },
            "required": [
                "displaySequence",
                "resultFormat",
                "resultId"
            ],
            "type": "object"
        },
        "IFilteredResultItem": {
            "properties": {
                "filteredResultFormat": {
                    "type": "string"
                },
                "filteredResultId": {
                    "type": "string"
                }
            },
            "required": [
                "filteredResultFormat",
                "filteredResultId"
            ],
            "type": "object"
        },
        "IMarketingContentDescriptionItem": {
            "properties": {
                "language": {
                    "type": "string"
                },
                "marketingText": {
                    "type": "string"
                }
            },
            "type": "object"
        },
        "IMarketingSpotDataItem": {
            "properties": {
                "baseMarketingSpotActivityData": {
                    "items": {
                        "$ref": "#/definitions/IBaseMarketingSpotActivityDataItem"
                    },
                    "type": "array"
                },
                "behavior": {
                    "type": "string"
                },
                "defaultContentDisplaySequence": {
                    "items": {
                        "$ref": "#/definitions/IDefaultContentDisplaySequenceItem"
                    },
                    "type": "array"
                },
                "eSpotName": {
                    "type": "string"
                },
                "filteredResult": {
                    "items": {
                        "$ref": "#/definitions/IFilteredResultItem"
                    },
                    "type": "array"
                },
                "marketingSpotIdentifier": {
                    "type": "string"
                },
                "nextTimeLimit": {
                    "type": "string"
                }
            },
            "required": [
                "behavior",
                "eSpotName",
                "marketingSpotIdentifier",
                "nextTimeLimit"
            ],
            "type": "object"
        },
        "IPropertiesItem": {
            "properties": {
                "baseMarketingKey": {
                    "type": "string"
                },
                "baseMarketingValue": {
                    "type": "string"
                }
            },
            "required": [
                "baseMarketingKey"
            ],
            "type": "object"
        }
    },
    "properties": {
        "MarketingSpotData": {
            "items": {
                "$ref": "#/definitions/IMarketingSpotDataItem"
            },
            "type": "array"
        },
        "resourceId": {
            "type": "string"
        },
        "resourceName": {
            "type": "string"
        }
    },
    "required": [
        "MarketingSpotData",
        "resourceId",
        "resourceName"
    ],
    "type": "object"
}

;

  export const isIEspotResponse = ajv.compile(IEspotResponseSchema) as ValidateFunction<
    IEspotResponse
  >;

  export default function validateIEspotResponse(value: unknown): IEspotResponse {
    if (isIEspotResponse(value)) {
      return value;
    } else {
      console.warn(
        ajv.errorsText(
            isIEspotResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IEspotResponse' },
          ) +
            inspect(value),
    );
    
    
      return value as IEspotResponse
      
    }
  }