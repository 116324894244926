import { FieldFormatting } from '../../components/Forms/React-Form/FieldFormatting/FieldFormatting';
import { FieldParsing } from '../../components/Forms/React-Form/FieldParsing/FieldParsing';
import { FieldValidations } from '../../components/Forms/React-Form/FieldValidation/FieldValidation';

/**
 * Validator for the zip code input field.
 */
const ZipCodeUSValidator = {
  ZIP_CODE_US: {
    name: 'zipCode',
    id: 'zip-code',
    label: 'ZIP Code*',
    type: 'tel',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_US(value, 'Please enter a valid ZIP Code.')
    ),
    fieldFormat: FieldFormatting.ZIP_CODE_US,
    fieldParse: FieldParsing.ZIP_CODE_US,
    disableAutoComplete: false,
    autoComplete: 'postal-code',
    ariaLabel: 'postal-code',
    sensitiveData: true,
  },
};

/**
 * Validator for the delivery option zip code input field.
 */
const ZipCodeUSValidatorForDeliveryOptions = {
  ZIP_CODE_US: {
    name: 'zipCode',
    id: 'zip-code',
    label: 'ZIP Code',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_US(value, 'Please enter a valid ZIP Code.')
    ),
    fieldFormat: FieldFormatting.ZIP_CODE_US,
    fieldParse: FieldParsing.ZIP_CODE_US,
    disableAutoComplete: false,
    autoComplete: 'postal-code',
    ariaLabel: 'postal-code',
    sensitiveData: true,
    disabled: false,
  },
};

/**
 * Validator for the zip code canada input field.
 */
const ZipCodeCanadaValidator = {
  ZIP_CODE_CANADA: {
    name: 'zipCode',
    id: 'zip-code',
    label: 'ZIP Code*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_CANADA(
          value,
          'Please enter a valid ZIP Code.'
        )
    ),
    fieldParse: FieldParsing.ZIP_CODE_CANADA,
    disableAutoComplete: true,
    ariaLabel: 'postal-code',
    sensitiveData: true,
  },
};

/**
 * Validator for the zip code outside US and Canada input field.
 */
const ZipCodeOutsideUSCanadaValidator = {
  ZIP_CODE_OUTSIDE_US_CANADA: {
    name: 'zipCode',
    id: 'zip-code',
    label: 'ZIP Code*',
    type: 'text',
    fieldValidate: FieldValidations.COMPOSE_VALIDATORS(
      (value: string) =>
        FieldValidations.REQUIRED(value, 'ZIP Code is required.'),
      (value: string) =>
        FieldValidations.ZIP_CODE_OUTSIDE_US_CANADA(
          value,
          'Please enter a valid ZIP Code.'
        )
    ),
    fieldParse: FieldParsing.ZIP_CODE_OUTSIDE_US_CANADA,
    disableAutoComplete: true,
    ariaLabel: 'postal-code',
    sensitiveData: true,
  },
};

export {
  ZipCodeUSValidator,
  ZipCodeCanadaValidator,
  ZipCodeOutsideUSCanadaValidator,
  ZipCodeUSValidatorForDeliveryOptions,
};
