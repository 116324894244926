export const REQUEST_VERIFICATION_CODE = 'REQUEST_VERIFICATION_CODE';
export const VERIFICATION_CODE_RECEIVED = 'VERIFICATION_CODE_RECEIVED';
export const VERIFICATION_CODE_ERROR = 'VERIFICATION_CODE_ERROR';
export const RESET_VERIFICATION_CODE = 'RESET_VERIFICATION_CODE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const TOGGLE_PASSWORD_COMPONENT = 'TOGGLE_PASSWORD_COMPONENT';
export const ADD_FORGOT_PASSWORD_EMAIL_ADDRESS =
  'ADD_FORGOT_PASSWORD_EMAIL_ADDRESS';
export const RESET_FORGOT_RESET_PASSWORD = 'RESET_FORGOT_RESET_PASSWORD';
