import { takeEvery } from 'redux-saga/effects';
import {
  ADD_PROMO_CODE_ACTION,
  REMOVE_PROMO_CODE_ACTION,
} from '../../actions/promoCode.actions';

import { addPromoCode, removePromoCode } from '../workers/promoCode.workers';

export function* watchSaga() {
  yield takeEvery(ADD_PROMO_CODE_ACTION, addPromoCode);

  yield takeEvery(REMOVE_PROMO_CODE_ACTION, removePromoCode);
}
