const TOGGLE_NEXT_MENU = 'TOGGLE_NEXT_MENU';
const INIT_MENU_STATE = 'INIT_MENU_STATE';
const MENU_NAVIGATE_BACK = 'MENU_NAVIGATE_BACK';
const RESET_MENU_STATE = 'RESET_MENU_STATE';

export {
  TOGGLE_NEXT_MENU,
  INIT_MENU_STATE,
  MENU_NAVIGATE_BACK,
  RESET_MENU_STATE,
};
