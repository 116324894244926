import { useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import {
  formatQueryParams,
  removeQueryParamBackslash,
} from '../../../../../utils/utils';
import { FacetConstants } from '../FacetConstants';

/**
 * @interface IContructQueryParams
 */
interface IContructQueryParams {
  currentFacetData?: IProductListFacetsEntry;
  facetGroup?: string;
  checked?: boolean;
}

const useAppendFacetQuery = () => {
  const {
    FACET_QUERY_KEY,
    PRICE,
    PRICE_RANGE,
    PARENT_CATALOG_GROUP,
    CATEGORY,
  } = FacetConstants;

  const [encodedFacetParams, setFacetsParams] = useQueryParam(
    FACET_QUERY_KEY,
    StringParam
  );

  const facetsParams = encodedFacetParams
    ? removeQueryParamBackslash(encodedFacetParams)
    : '';

  const { selectedGroups } = useSelector(productListSelector);

  const constructQueryParams = (
    currentQueryParams?: IContructQueryParams
  ): string => {
    let currentlySelectedGroups = { ...selectedGroups };

    if (currentQueryParams) {
      const { currentFacetData, facetGroup, checked } = currentQueryParams;

      const currentFacetIndex = Object.keys(currentlySelectedGroups).findIndex(
        (currentFacetGroup) => currentFacetGroup === facetGroup
      );

      let currentFacetValues: IProductListFacetsEntry[] = [];

      if (!checked && currentFacetData) {
        if (currentFacetIndex !== -1) {
          currentFacetValues = [
            ...Object.values(currentlySelectedGroups)[currentFacetIndex],
            currentFacetData,
          ];
        } else {
          currentFacetValues?.push(currentFacetData);
        }
      }

      if (checked && currentFacetData) {
        if (currentFacetIndex !== -1) {
          const currentParamValues = [
            ...Object.values(currentlySelectedGroups)[currentFacetIndex],
          ];

          const updatedParamValues = currentParamValues.filter(
            ({ label }) => label !== currentFacetData.label
          );

          currentFacetValues = [...updatedParamValues];
        } else {
          currentFacetValues?.push(currentFacetData);
        }
      }

      if (facetGroup) {
        currentlySelectedGroups = {
          ...currentlySelectedGroups,
          ...{
            [facetGroup]: currentFacetValues,
          },
        };
      }
    }

    const facetKeys = Object.keys(currentlySelectedGroups);

    /**
     * If there are no values selected and no params already
     * available in the url clear the facets param.
     */
    if (facetKeys.length === 0 && !facetsParams) {
      setFacetsParams(undefined, 'replaceIn');

      return '';
    }

    const facetData = Object.values(currentlySelectedGroups);

    const facetParamObj: string[] = [];

    /**
     * Iterate the facetData to format them as ["A P E", "Arcan", "Blackline"] => A_P_E|Arcan|Blackline
     * and then add them to their respective facet group.
     *
     * Example:
     * { "Brand": ["A P E", "Arcan", "Blackline"] } => Brand:A_P_E|Arcan|Blackline
     */
    facetData.forEach((values: IProductListFacetsEntry[], index) => {
      if (facetKeys[index] !== PRICE && facetKeys[index] !== PRICE_RANGE) {
        const facetLabel = values.map(({ label }) => label.replace(/ /g, '_'));

        const delimitedLabels = formatQueryParams('|').add(facetLabel);

        if (delimitedLabels) {
          const facetParam = formatQueryParams(':').add([
            facetKeys[index] === PARENT_CATALOG_GROUP
              ? CATEGORY
              : facetKeys[index].replace(/ /g, '_'),
            delimitedLabels,
          ]);

          if (facetParam) {
            facetParamObj.push(facetParam.replace(/ /g, '+'));
          }
        }
      }
    });

    /**
     * Combine multiple facet groups with a "+" delimiter
     */
    const facetGroupParam = formatQueryParams('+').add(facetParamObj);

    if (facetGroupParam) {
      return facetGroupParam;
    }

    return '';
  };

  /**
   * @callback appendQueryParams add facets query params to the url.
   */
  const appendQueryParams = (): void => {
    const facetGroupParam = constructQueryParams();

    if (facetGroupParam) {
      setFacetsParams(facetGroupParam, 'replaceIn');
    }
  };

  return { appendQueryParams, constructQueryParams };
};

export { useAppendFacetQuery };
