import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_STORES_ACTION,
  RESET_SEARCH_STORES_FAILURE_ACTION,
  STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION,
} from '../../../../redux/actions/storeLocator';
import { storeLocatorSelector } from '../../../../redux/selectors/storeLocator';
import { NEAR_BY_STORES } from '../../../../_foundation/constants/cookie';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';

/**
 * @interface IStorePickUpStores
 */
interface IStorePickUpStores {
  orderId: string;
  isCartPage?: boolean;
}

/**
 * @hook useStorePickUpStores
 * Responsible to fetch the Store pickup inventory API with Lat and Long
 */
const useStorePickUpStores = ({ orderId, isCartPage }: IStorePickUpStores) => {
  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { isNoStoresAvailable, currentStoreDetails } =
    useSelector(storeLocatorSelector);

  /**
   * @method fetchStorePickUpLatAndLong
   * Responsible to fetch the storePickUp inventory Details
   */
  const fetchStorePickUpLatAndLong = useCallback((): void => {
    const nearByStoreDetails = sessionStorage.getItem(NEAR_BY_STORES);

    if (mySite && !isCartPage) {
      const fetchStorePickupLatAndLongFlag =
        !isNoStoresAvailable && orderId && currentStoreDetails;

      if (
        fetchStorePickupLatAndLongFlag &&
        currentStoreDetails &&
        currentStoreDetails.latitude &&
        currentStoreDetails.longitude &&
        !nearByStoreDetails &&
        orderId
      ) {
        dispatch(
          GET_STORES_ACTION({
            storeID: mySite.storeID,
            latitude: currentStoreDetails.latitude,
            longitude: currentStoreDetails.longitude,
            orderId,
            radius: mySite.storeLocatorDefaultRadius,
          })
        );
      }

      if (
        currentStoreDetails?.uniqueID &&
        currentStoreDetails?.latitude &&
        currentStoreDetails?.longitude &&
        orderId
      ) {
        dispatch(RESET_SEARCH_STORES_FAILURE_ACTION());

        dispatch(
          STORE_PICKUP_INVENTORY_LAT_LONG_REQUEST_ACTION({
            storeID: mySite.storeID,
            latitude: Number(currentStoreDetails.latitude),
            longitude: Number(currentStoreDetails.longitude),
            orderId,
            anchorStore: currentStoreDetails.uniqueID,
            radius: mySite.storeLocatorDefaultRadius,
          })
        );
      }
    }
  }, [
    currentStoreDetails,
    dispatch,
    isNoStoresAvailable,
    mySite,
    orderId,
    isCartPage,
  ]);

  useEffect(() => {
    fetchStorePickUpLatAndLong();
  }, [fetchStorePickUpLatAndLong]);
};

export { useStorePickUpStores };
