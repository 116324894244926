import React from 'react';
import { useHistory } from 'react-router';
import { SHOPPING_CART } from '../../../constants/routes';
import { useSeoState } from '../../../utils/hooks/seo-state/seo-state';
import { IProductPrice } from '../../../utils/product-utility';

interface IPlpPricingMessage {
  productPrice?: IProductPrice;
}

const PlpPricingMessage: React.FC<IPlpPricingMessage> = ({ productPrice }) => {
  const history = useHistory();

  const { isPdpPage } = useSeoState();

  const isCartPage = history.location.pathname === SHOPPING_CART;

  return (
    <>
      {productPrice?.plpQtyMessage && !isCartPage && !isPdpPage && (
        <p className='sub-product-offer-price pxs cart-qty-message'>
          {productPrice.plpQtyMessage}
        </p>
      )}
    </>
  );
};

export { PlpPricingMessage };
