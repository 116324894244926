import { createAction } from '@reduxjs/toolkit';
import { IPricing } from '../../_foundation/interface/Pricing/IPricing';
import * as ACTION_TYPES from '../action-types/extendedPricing.action-types';

export const UPDATE_PRODUCT_PRICING_ACTION = createAction<IPricing>(
  ACTION_TYPES.UPDATE_PRODUCT_PRICING
);

export const RESET_EXTENDED_PRICING_ACTION = createAction(
  ACTION_TYPES.RESET_EXTENDED_PRICING
);

export const UPDATE_PRODUCT_EXTENDED_PRICING_ACTION = createAction<IPricing>(
  ACTION_TYPES.UPDATE_PRODUCT_EXTENDED_PRICING
);
