import { createAction } from '@reduxjs/toolkit';
import {
  IBreadcrumbRequest,
  IFetchBreadcrumbProductDetails,
} from '../../_foundation/interface/Breadcrumb/IBreadcrumb';
import IBreadcrumbsResponse from '../../_foundation/interface/Responses/IBreadcrumbsResponse';
import * as ACTION_TYPES from '../action-types/breadcrumb.action-types';

const FETCH_BREADCRUMB_ACTION = createAction<IBreadcrumbRequest>(
  ACTION_TYPES.FETCH_BREADCRUMB
);

const FETCH_BREADCRUMB_SUCCESS_ACTION = createAction<IBreadcrumbsResponse>(
  ACTION_TYPES.FETCH_BREADCRUMB_SUCCESS
);

const FETCH_PRODUCT_BREADCRUMB_DETAILS_ACTION =
  createAction<IFetchBreadcrumbProductDetails>(
    ACTION_TYPES.FETCH_PRODUCT_BREADCRUMB_DETAILS
  );

const RESET_BREADCRUMB_ACTION = createAction(ACTION_TYPES.RESET_BREADCRUMB);

const SET_BREADCRUMB_ERROR_RESPONSE_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_BREADCRUMB_ERROR_RESPONSE
);

export {
  FETCH_BREADCRUMB_ACTION,
  FETCH_BREADCRUMB_SUCCESS_ACTION,
  FETCH_PRODUCT_BREADCRUMB_DETAILS_ACTION,
  RESET_BREADCRUMB_ACTION,
  SET_BREADCRUMB_ERROR_RESPONSE_ACTION,
};
