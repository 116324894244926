import { Typography } from '@material-ui/core';
import React from 'react';
import { INteAlert, NteAlert } from '../../NteAlert/NteAlert';
import { MenuListBody } from '../../Widgets/Menu/MenuListBody/MenuListBody';
import { MenuListHeader } from '../../Widgets/Menu/MenuListHeader/MenuListHeader';

/**
 * @interface INteMenuList
 */
interface INteMenuList {
  /**
   * @prop menuListCloseHandler for closing the menu component.
   */
  menuListCloseHandler: () => void;

  /**
   * @prop menuItems that needs to displayed.
   */
  menuItems?: any;

  /**
   * @prop children Custom component can be rendered inside the menu
   * list item by passing as a children.
   */
  children?: JSX.Element | JSX.Element[] | string;

  /**
   * @prop menuTitle of the menu list component.
   */
  menuTitle: string;

  /**
   * @prop isRootMenu flag determines if the current
   * menu is root menu or not.
   */
  isRootMenu?: boolean;

  /**
   * @prop nteMenuClickHandler handles the menu item click events.
   */
  nteMenuClickHandler?: (selectedEvent?: any) => void;

  /**
   * @prop icon to be rendered in the list item.
   */
  icon?: JSX.Element;

  /**
   * @prop current item to be compared to current menu list item.
   */
  currentItem?: string | string[];

  /**
   * @prop menu list item id.
   */
  id: string;

  /**
   * @prop alertProps Shows alert message when necessary props are passed.
   */
  alertProps?: INteAlert;
}

/**
 * @component NteMenuList is responsible for rendering the Nte Menu lists.
 *
 * @param INteMenuList
 */
const NteMenuList: React.FC<INteMenuList> = ({
  menuListCloseHandler,
  children,
  menuItems,
  menuTitle,
  isRootMenu = false,
  nteMenuClickHandler,
  icon,
  currentItem,
  id,
  alertProps,
}) =>
  menuItems?.length !== 0 || menuItems ? (
    <>
      <MenuListHeader
        backHandler={nteMenuClickHandler}
        closeHandler={menuListCloseHandler}
        menuTitle={menuTitle}
        showBack={isRootMenu}
        id={id}
      />

      {alertProps && <NteAlert {...alertProps} />}

      <MenuListBody
        clickHandler={nteMenuClickHandler}
        children={children}
        menuItems={menuItems}
        currentItem={currentItem}
        icon={icon}
      />
    </>
  ) : (
    <Typography>No items found</Typography>
  );

export { NteMenuList };
