const BASE_URL = '/search/resources/api/v2/categories';

const CategoryServiceConstants = {
  CATEGORY_URL: (storeID: string, depthAndLimit?: string) =>
    `${BASE_URL}?storeId=${storeID}&depthAndLimit=${depthAndLimit}&langId=-1&orderBy=1`,
  BRANDS_INDEX_URL: (storeID: string, categoryID: string) =>
    `${BASE_URL}?storeId=${storeID}&categoryId=${categoryID}&depthAndLimit=-1%2c-1&langId=-1&orderBy=1`,
  SUB_CATEGORIES_URL: (categoryID: string, storeID: string) =>
    `${BASE_URL}?parentCategoryId=${categoryID}&storeId=${storeID}&langId=-1&profileName=HCL_findSubCategories&orderBy=2`,
  SUB_CATEGORIES_FACET_URL: (storeID: string) =>
    `${BASE_URL}?storeId=${storeID}&langId=-1&profileName=HCL_findCategoryByUniqueIds&orderBy=1`,
};

export { CategoryServiceConstants };
