import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IGuestParam } from '../../_foundation/apis/auth/guest.service';
import {
  IAuthenticationResponse,
  IChangeUserPassword,
  IContactResponse,
  ILoginParam,
  IRegisterUser,
  ISubmissionError,
  IUpdateUserRegistrationRequest,
  IUpdateUserRegistrationResponse,
  IUserInfo,
} from '../../_foundation/interface/Authentication/IAuth';
import * as ACTION_TYPES from '../action-types/auth.action-types';

export const LOGIN_REQUESTED_ACTION = createAction<ILoginParam>(
  ACTION_TYPES.LOGIN_REQUESTED
);

export const LOGIN_SUCCEEDED_ACTION = createAction<IAuthenticationResponse>(
  ACTION_TYPES.LOGIN_SUCCEEDED
);

export const GUEST_LOGIN_REQUEST_ACTION = createAction<IGuestParam>(
  ACTION_TYPES.GUEST_LOGIN_REQUESTED
);

export const GUEST_LOGIN_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.GUEST_LOGIN_SUCCESS
);

export const GET_USER_DETAILS_ACTION = createAction(
  ACTION_TYPES.GET_USER_DETAILS
);

export const INVALID_USER_CREDENTIALS_ACTION = createAction<AxiosError>(
  ACTION_TYPES.INVALID_USER_CREDENTIALS
);

export const SIGN_OUT_ACTION = createAction<boolean>(ACTION_TYPES.SIGN_OUT);

export const GET_USER_INFO_ACTION = createAction<{ storeID: string }>(
  ACTION_TYPES.GET_USER_INFO
);

export const UPDATE_USER_INFO_SUCCEEDED_ACTION = createAction<{
  userInfo: IUserInfo;
}>(ACTION_TYPES.UPDATE_USER_INFO_SUCCEEDED);

export const REGISTER_USER_ACTION = createAction<IRegisterUser>(
  ACTION_TYPES.REGISTER_USER
);

export const REGISTRATION_FAILED_ACTION = createAction<any>(
  ACTION_TYPES.REGISTRATION_FAILED
);

export const RESET_REGISTRATION_ERRORS_ACTION = createAction(
  ACTION_TYPES.RESET_REGISTRATION_ERRORS
);

export const GET_USER_CONTACT_INFO_ACTION = createAction<{ storeID: string }>(
  ACTION_TYPES.GET_USER_CONTACT_INFO
);

export const UPDATE_PASSWORD_ACTION = createAction<IChangeUserPassword>(
  ACTION_TYPES.UPDATE_PASSWORD
);

export const UPDATE_PASSWORD_SUCCEEDED_ACTION = createAction(
  ACTION_TYPES.UPDATE_PASSWORD_SUCCEEDED
);

export const UPDATE_PASSWORD_FAILED_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_PASSWORD_FAILED
);

export const RESET_UPDATE_PASSWORD_ACTION = createAction(
  ACTION_TYPES.RESET_UPDATE_PASSWORD
);

export const GET_USER_CONTACT_INFO_SUCCEEDED_ACTION =
  createAction<IContactResponse>(ACTION_TYPES.GET_USER_CONTACT_INFO_SUCCEEDED);

export const GET_RECENT_ORDERS_ACTION = createAction<ILoginParam>(
  ACTION_TYPES.GET_RECENT_ORDERS
);

export const GET_RECENT_ORDERS_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.GET_RECENT_ORDERS_SUCCESS
);

export const UPDATE_USER_REGISTRATION_ACTION =
  createAction<IUpdateUserRegistrationRequest>(
    ACTION_TYPES.UPDATE_USER_REGISTRATION
  );

export const UPDATE_USER_REGISTRATION_SUCCESS_ACTION =
  createAction<IUpdateUserRegistrationResponse>(
    ACTION_TYPES.UPDATE_USER_REGISTRATION_SUCCESS
  );

export const UPDATE_USER_REGISTRATION_ERROR_ACTION = createAction<
  ISubmissionError[]
>(ACTION_TYPES.UPDATE_USER_REGISTRATION_ERROR);

export const RESET_UPDATE_USER_REGISTRATION_ERROR_ACTION = createAction(
  ACTION_TYPES.RESET_UPDATE_USER_REGISTRATION_ERROR
);

export const ADD_USER_SAVED_CARD_DETAILS_ACTION = createAction<any>(
  ACTION_TYPES.ADD_USER_SAVED_CARD_DETAILS
);

export const FETCH_USER_SAVED_CARD_DETAILS_ACTION = createAction<any>(
  ACTION_TYPES.FETCH_USER_SAVED_CARD_DETAILS
);

export const UPDATE_USER_SAVED_CARD_DETAILS_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_USER_SAVED_CARD_DETAILS
);

export const CLEAR_LOGIN_ERROR_ACTION = createAction<any>(
  ACTION_TYPES.CLEAR_LOGIN_ERROR
);
