import { Drawer, DrawerProps } from '@material-ui/core';
import React from 'react';
import './NteDrawerStyles.scss';

interface Props {
  /**
   * @prop anchor point from where the drawer needs
   * to be rendered.
   */
  anchor: DrawerProps['anchor'];

  /**
   * @prop children to be rendered inside the
   * drawer.
   */
  children: React.ReactNode;

  /**
   * @prop nteDrawerCloseHandler handles drawer
   * close events.
   */
  nteDrawerCloseHandler: () => void;

  /**
   * @prop showNteDrawer is responsible for opening
   * and closing the nte drawer component.
   */
  showNteDrawer: boolean;

  /**
   * @prop id for the Nte drawer component.
   */
  id: string;

  /**
   * @prop ariaLabelledById for Nte Drawer.
   */
  ariaLabelledById: string;

  /**
   * @prop class name for Nte Drawer.
   */
  className?: string;
}

/**
 * @component NteDrawer is responsible for rendering
 * the Nte drawer container you can pass in custom
 * components or child elements to be rendered inside
 * the Nte drawer component.
 */
const NteDrawer: React.FC<Props> = ({
  anchor,
  nteDrawerCloseHandler,
  showNteDrawer,
  children,
  id,
  ariaLabelledById,
  className = '',
}) => (
  <Drawer
    anchor={anchor}
    aria-modal={showNteDrawer}
    id={id}
    onClose={nteDrawerCloseHandler}
    open={showNteDrawer}
    aria-labelledby={ariaLabelledById}
    className={`nte_drawer ${className}`}
    role='dialog'
    disableEnforceFocus // Added this prop to ensure other focusable elements to be focused, which are opened on top of Drawer.
    >
    {children}
  </Drawer>
);

// Export.
export { NteDrawer };
