import { Hidden, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  PLP_COMPARE_BAR_FINDING_METHOD_TEALIUM,
  PLP_COMPARE_BAR_TEALIUM,
} from '../../../../constants/Tealium';
import { REMOVE_PRODUCT_FROM_COMPARE_ACTION } from '../../../../redux/actions/productList.actions';
import { productListSelector } from '../../../../redux/selectors/productList.selector';
import { sendTealiumData } from '../../../../utils/utils';
import { NteDrawer } from '../../../Drawers/NteDrawer/NteDrawer';
import { NteButton } from '../../../Forms/NteButton/NteButton';
import { IconText } from '../../IconText/IconText';
import { ProductCompareModal } from '../ProductCompareModal/ProductCompareModal';
import { ProductComparisonBarConstants } from './ProductComparisonBarConstants';
import { ProductComparisonCard } from './ProductComparisonCard/ProductComparisonCard';

/**
 * @component ProductComparisonBar renders the product comparison bar.
 */
const ProductComparisonBar: React.FC = () => {
  const { COMPARE, COMPARE_KEY, REMOVE_ALL } = ProductComparisonBarConstants;

  const {
    productComparison: { compareProducts },
  } = useSelector(productListSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  /**
   * @method removeAllHandler Removes all the comparison products from the list.
   */
  const removeAllHandler = (): void => {
    dispatch(REMOVE_PRODUCT_FROM_COMPARE_ACTION({}));
  };

  // Enable the compare button when more that 2 products are available to compare.
  const enableCompareButton = compareProducts.length >= 2;

  const [openCompareDrawer, setOpenCompareDrawer] = useState(false);

  const compareHandler = (): void => {
    setOpenCompareDrawer((openCompareDrawer) => !openCompareDrawer);

    const currentProductIds = compareProducts.map(
      ({ partNumber }) => partNumber
    );

    const currentProductNames = compareProducts.map(({ name }) => name);

    sendTealiumData({
      tealium_event: PLP_COMPARE_BAR_TEALIUM,
      product_id: currentProductIds,
      product_name: currentProductNames,
      product_finding_method: PLP_COMPARE_BAR_FINDING_METHOD_TEALIUM,
    });
  };

  return (
    <div
      role='region'
      aria-label='Product compare'
      aria-labelledby={t(COMPARE_KEY)}
      className='float compare-bar'>
      <Hidden smDown>
        <Typography variant='h3'>{t(COMPARE_KEY)}</Typography>

        <ProductComparisonCard />
      </Hidden>

      <IconText
        clickHandler={removeAllHandler}
        translationKey={REMOVE_ALL}
        linkEffect={true}
        className='remove-all'
      />

      <NteButton
        disabled={!enableCompareButton}
        translationKey={`${COMPARE} (${compareProducts.length})`}
        variant='outlined'
        size='small'
        onClick={() => compareHandler()}
        type='button'
      />

      {openCompareDrawer && (
        <NteDrawer
          anchor='bottom'
          className='open-compare-drawer compare-products-drawer'
          id=''
          ariaLabelledById=''
          nteDrawerCloseHandler={() =>
            setOpenCompareDrawer((openCompareDrawer) => !openCompareDrawer)
          }
          showNteDrawer={openCompareDrawer}>
          <ProductCompareModal
            modalCloseHandler={() =>
              setOpenCompareDrawer((openCompareDrawer) => !openCompareDrawer)
            }
            productCompareCloseHandler={setOpenCompareDrawer}
            modalHeaderId={'product-comparison'}
            compareProducts={compareProducts}
          />
        </NteDrawer>
      )}
    </div>
  );
};

export { ProductComparisonBar };
