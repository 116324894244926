import { createAction } from '@reduxjs/toolkit';
import { IFetchSubCategories } from '../../_foundation/apis/search/category/category.service';
import { ITopBrandsFacetsEntry } from '../../_foundation/interface/Brands/IBrands';
import { IInventoryInfoRequest } from '../../_foundation/interface/Cart/IOrder';
import {
  IFetchCategoryByIdentifier,
  IFetchProductList,
  IFetchSearchTerm,
  IProductCompare,
  IProductListSelectedFacet,
  IResetProductListAction,
  ISelectedFacetGroups,
} from '../../_foundation/interface/ProductList/IProductList';
import IShippingInfoResponse from '../../_foundation/interface/Responses/IShippingInfoResponse';
import * as ACTION_TYPES from '../action-types/productList.action-types';

const GET_PRODUCT_LIST_ACTION = createAction<IFetchProductList>(
  ACTION_TYPES.GET_PRODUCT_LIST
);

const GET_PRODUCT_LIST_STORE_ACTION = createAction<IFetchProductList>(
  ACTION_TYPES.GET_PRODUCT_LIST_STORE
);

const GET_PRODUCT_LIST_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.GET_PRODUCT_LIST_SUCCESS
);

const SEARCH_PRODUCT_LIST_ACTION = createAction<IFetchProductList>(
  ACTION_TYPES.SEARCH_PRODUCT_LIST
);

const UPDATE_SEARCH_PRODUCT_LIST_ACTION = createAction<IFetchProductList>(
  ACTION_TYPES.UPDATE_SEARCH_PRODUCT_LIST
);

const SEARCH_PRODUCT_LIST_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.SEARCH_PRODUCT_LIST_SUCCESS
);

const RESET_PRODUCT_LIST_ACTION = createAction<IResetProductListAction>(
  ACTION_TYPES.RESET_PRODUCT_LIST
);

const SET_FACET_ACTION = createAction<IProductListSelectedFacet>(
  ACTION_TYPES.SET_FACET
);

const CLEAR_ALL_FACET_ACTION = createAction(ACTION_TYPES.CLEAR_ALL_FACET);

const ADD_PRODUCTS_TO_COMPARE_ACTION = createAction<IProductCompare>(
  ACTION_TYPES.ADD_PRODUCTS_TO_COMPARE
);

const REMOVE_PRODUCT_FROM_COMPARE_ACTION = createAction<IProductCompare>(
  ACTION_TYPES.REMOVE_PRODUCT_FROM_COMPARE
);

const SET_PRODUCT_LIST_LOADER_ACTION = createAction<{ loading: boolean }>(
  ACTION_TYPES.SET_PRODUCT_LIST_LOADER
);

const FETCH_PRIVATE_BRANDS_ACTION = createAction<{ storeID: string }>(
  ACTION_TYPES.FETCH_PRIVATE_BRANDS
);

const FETCH_PRIVATE_BRANDS_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.FETCH_PRIVATE_BRANDS_SUCCESS
);

const UPDATE_FACETS_ACTION = createAction<IFetchProductList>(
  ACTION_TYPES.UPDATE_FACETS
);

const UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION =
  createAction<IFetchProductList>(ACTION_TYPES.UPDATE_FACETS_STORE_IDENTIFIER);

const UPDATE_FACETS_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.UPDATE_FACETS_SUCCESS
);

const FACET_LOADING_ACTION = createAction<boolean>(ACTION_TYPES.FACET_LOADING);

const FILTER_BY_PRICE_RANGE_ACTION = createAction<{
  minPrice: string;
  maxPrice: string;
}>(ACTION_TYPES.FILTER_BY_PRICE_RANGE);

const SHOW_PLP_META_TAGS_ACTION = createAction<boolean>(
  ACTION_TYPES.SHOW_PLP_META_TAGS
);

const SHOW_PLP_ERROR_ACTION = createAction<boolean>(
  ACTION_TYPES.SHOW_PLP_ERROR
);

const FACETS_STACK_ACTION = createAction<'PUSH' | 'POP'>(
  ACTION_TYPES.FACETS_STACK
);

const SET_FACET_FROM_PARAMS_ACTION = createAction<ISelectedFacetGroups>(
  ACTION_TYPES.SET_FACET_FROM_PARAMS
);

const CLEAR_PRICE_RANGE_FACET_ACTION = createAction(
  ACTION_TYPES.CLEAR_PRICE_RANGE_FACET
);

const SET_PLP_PAGE_LOADING_ACTION = createAction<boolean>(
  ACTION_TYPES.SET_PLP_PAGE_LOADING
);

const SEARCH_DISPLAY_REQUEST_ACTION = createAction<IFetchSearchTerm>(
  ACTION_TYPES.SEARCH_DISPLAY_REQUEST
);

const SEARCH_DISPLAY_SUCCESS_ACTION = createAction<any, string>(
  ACTION_TYPES.SEARCH_DISPLAY_SUCCESS
);

const REDIRECT_TO_PDP_ACTION = createAction<string>(
  ACTION_TYPES.REDIRECT_TO_PDP
);

const NO_SEARCH_RESULTS_FOUND_ACTION = createAction(
  ACTION_TYPES.NO_SEARCH_RESULTS_FOUND
);

const SEARCH_PRODUCT_REDIRECTION_ACTION = createAction<string>(
  ACTION_TYPES.SEARCH_PRODUCT_REDIRECTION
);

const RESET_SEARCH_DISPLAY_ACTION = createAction(
  ACTION_TYPES.RESET_SEARCH_DISPLAY
);

const SET_PROMO_PRODUCT_ID_ACTION = createAction<any>(
  ACTION_TYPES.SET_PROMO_PRODUCT_ID
);

const FETCH_INVENTORY_INFO_ACTION = createAction<IInventoryInfoRequest>(
  ACTION_TYPES.FETCH_INVENTORY_INFO
);

const FETCH_INVENTORY_INFO_SUCCESS_ACTION = createAction<IShippingInfoResponse>(
  ACTION_TYPES.FETCH_INVENTORY_INFO_SUCCESS
);

const TOP_BRANDS_SUCCESS_ACTION = createAction<ITopBrandsFacetsEntry[]>(
  ACTION_TYPES.TOP_BRANDS_SUCCESS
);

const RESET_TOP_BRANDS_ACTION = createAction(ACTION_TYPES.RESET_TOP_BRANDS);

const ADD_CATEGORY_IDS_ACTION = createAction<string[]>(
  ACTION_TYPES.ADD_CATEGORY_IDS
);

const CURRENT_BRAND_ID_ACTION = createAction<string>(
  ACTION_TYPES.CURRENT_BRAND_ID
);

const CURRENT_BRAND_IDENTIFIER_ACTION = createAction<string>(
  ACTION_TYPES.CURRENT_BRAND_IDENTIFIER
);

const CURRENT_BRAND_INFO_ACTION = createAction<{
  seo: string;
  brandName: string;
}>(ACTION_TYPES.CURRENT_BRAND_INFO);

const FETCH_SUB_CATEGORIES_ACTION = createAction<IFetchSubCategories>(
  ACTION_TYPES.FETCH_SUB_CATEGORIES
);

const FETCH_SUB_CATEGORIES_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.FETCH_SUB_CATEGORIES_SUCCESS
);

const FETCH_INVENTORY_BY_IDENTIFIER_ACTION =
  createAction<IFetchCategoryByIdentifier>(
    ACTION_TYPES.FETCH_INVENTORY_BY_IDENTIFIER
  );

const FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION = createAction<any>(
  ACTION_TYPES.FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS
);

const FETCH_BRAND_BY_IDENTIFIER_ACTION =
  createAction<IFetchCategoryByIdentifier>(
    ACTION_TYPES.FETCH_BRAND_BY_IDENTIFIER
  );

export {
  GET_PRODUCT_LIST_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION,
  SEARCH_PRODUCT_LIST_ACTION,
  SEARCH_PRODUCT_LIST_SUCCESS_ACTION,
  RESET_PRODUCT_LIST_ACTION,
  SET_FACET_ACTION,
  CLEAR_ALL_FACET_ACTION,
  ADD_PRODUCTS_TO_COMPARE_ACTION,
  REMOVE_PRODUCT_FROM_COMPARE_ACTION,
  SET_PRODUCT_LIST_LOADER_ACTION,
  FETCH_PRIVATE_BRANDS_ACTION,
  FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
  UPDATE_FACETS_ACTION,
  UPDATE_FACETS_SUCCESS_ACTION,
  FACET_LOADING_ACTION,
  FILTER_BY_PRICE_RANGE_ACTION,
  SHOW_PLP_META_TAGS_ACTION,
  SHOW_PLP_ERROR_ACTION,
  FACETS_STACK_ACTION,
  SET_FACET_FROM_PARAMS_ACTION,
  CLEAR_PRICE_RANGE_FACET_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
  SEARCH_DISPLAY_REQUEST_ACTION,
  SEARCH_DISPLAY_SUCCESS_ACTION,
  RESET_SEARCH_DISPLAY_ACTION,
  UPDATE_SEARCH_PRODUCT_LIST_ACTION,
  SEARCH_PRODUCT_REDIRECTION_ACTION,
  UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION,
  GET_PRODUCT_LIST_STORE_ACTION,
  FETCH_INVENTORY_INFO_ACTION,
  FETCH_INVENTORY_INFO_SUCCESS_ACTION,
  TOP_BRANDS_SUCCESS_ACTION,
  RESET_TOP_BRANDS_ACTION,
  REDIRECT_TO_PDP_ACTION,
  NO_SEARCH_RESULTS_FOUND_ACTION,
  ADD_CATEGORY_IDS_ACTION,
  CURRENT_BRAND_ID_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FETCH_SUB_CATEGORIES_ACTION,
  FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  FETCH_BRAND_BY_IDENTIFIER_ACTION,
  SET_PROMO_PRODUCT_ID_ACTION,
};
