import { call, put } from 'redux-saga/effects';
import { RESET_SEO_HREF_ACTION } from '../../../../../redux/actions/categories.actions';
import {
  INVALID_URL_ACTION,
  NETWORK_ERROR_ACTION,
} from '../../../../../redux/actions/network-errors.actions';
import { trackUserEvent } from '../../../../../utils/lucky-orange';
import { seoService } from '../../../../../_foundation/apis/seo/seo.service';
import {
  TIER1_ERROR_200_RESPONSE,
  TIER1_ERROR_NO_RESPONSE,
} from '../../../../../_foundation/constants/lo-events';
import { GET_SEO_CONFIG_SUCCESS_ACTION } from '../../actions/seo';
import { RESET_WIDGET_INFO, SET_WIDGET_INFO } from '../../actions/widget-info';

export function* getSEO(action: any): Generator {
  const { identifier, currentUrl } = action.payload;

  try {
    const response: any = yield call(seoService.fetchSEO, action.payload);

    if (response && response?.data && response?.data?.contents?.length === 0) {
      yield put(INVALID_URL_ACTION(true));

      const errorData = {
        api: response?.config?.url,
        apiResponsePayload: JSON.stringify(response?.data),
        apiResponseHttpCode: response?.status.toString(),
        url: currentUrl,
      };

      trackUserEvent({
        eventName: TIER1_ERROR_200_RESPONSE,
        eventMsg: errorData,
      });

      return;
    }

    yield put(RESET_SEO_HREF_ACTION());

    yield put(RESET_WIDGET_INFO());

    yield put(GET_SEO_CONFIG_SUCCESS_ACTION({ identifier, response }));

    yield put(SET_WIDGET_INFO({ identifier, response }));
  } catch (error: any) {
    const errorData = {
      api: error?.config?.url,
      apiResponsePayload: error?.data,
      apiResponseHttpCode: error?.status,
      url: currentUrl,
    };

    trackUserEvent({
      eventName: TIER1_ERROR_NO_RESPONSE,
      eventMsg: errorData,
    });

    if (error.isAxiosError && error.response && error.response.status === 404) {
      const response = error.response;

      yield put(GET_SEO_CONFIG_SUCCESS_ACTION({ identifier, response }));

      yield put(RESET_WIDGET_INFO());
    }

    yield put(
      NETWORK_ERROR_ACTION({
        hideComponent: true,
        errorCode: error?.status,
        errorKey: error?.config?.url,
        errorMessage: error?.data,
      })
    );
  }
}
