import { call, put } from 'redux-saga/effects';
import { siteContentService } from '../../../_foundation/apis/search/search-siteContent/siteContent.service';
import { FETCH_SUGGEST_TYPE_SUCCESS_ACTION } from '../../actions/suggestion.actions';

export function* fetchSuggestType(action: any): Generator {
  try {
    const response = yield call(
      siteContentService.fetchSuggestTypeCategoriesAndBrands,
      action.payload
    );

    yield put(FETCH_SUGGEST_TYPE_SUCCESS_ACTION(response));
  } catch (e) {
    //  Add Snackback dispatch for error.
    console.error(e);
  }
}
