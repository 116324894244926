import { AxiosRequestConfig, CancelToken } from 'axios';
import qs from 'qs';
import { SiteInfo } from '../../../hooks/usesite/SiteInfoService';
import { IFetchSubCategoriesFacetRequest } from '../../../interface/Category/ICategory';
import ICategoriesResponse from '../../../interface/Responses/ICategoriesResponse';
import validateICategoriesResponse from '../../../interface/Responses/ICategoriesResponse.validator';
import { makeRequest } from '../../axios/axiosConfig';
import { CategoriesService } from './categories.service';
import { CategoryServiceConstants } from './category.service.constants';

/**
 * @interface ICategoriesParam
 */
interface ICategoriesParam {
  depthAndLimit?: string;
  storeID: string;
}

/**
 * @interface IBrandsIndexParam
 */
interface IBrandsIndexParam {
  categoryID: string;
  storeID: string;
}

export interface IFetchSubCategories {
  categoryID: string;
  storeID: string;
  cancelToken?: CancelToken;
}

const categoryService = {
  /**
   * @method fetchAllCategories Fetches all the list of categories for the given store.
   *
   * @param ICategoriesParam
   */
  async fetchAllCategories({
    storeID,
    depthAndLimit = '-1%2C-1%2C-1%2C-1',
  }: ICategoriesParam): Promise<ICategoriesResponse> {
    const { CATEGORY_URL } = CategoryServiceConstants;

    const categoryUrl = CATEGORY_URL(storeID, depthAndLimit);

    try {
      const request: AxiosRequestConfig = {
        url: categoryUrl,
        method: 'GET',
      };

      const categoriesResponse: ICategoriesResponse =
        validateICategoriesResponse(await makeRequest(request));

      return categoriesResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchAllBrandIndex Fetches the brand index values for the given store.
   *
   * @param IBrandsIndexParam
   */
  async fetchAllBrandIndex({
    storeID,
    categoryID,
  }: IBrandsIndexParam): Promise<ICategoriesResponse> {
    const { BRANDS_INDEX_URL } = CategoryServiceConstants;

    const brandsIndexUrl = BRANDS_INDEX_URL(storeID, categoryID);

    try {
      const request: AxiosRequestConfig = {
        url: brandsIndexUrl,
        method: 'GET',
      };

      const brandsIndexResponse: ICategoriesResponse =
        validateICategoriesResponse(await makeRequest(request));

      return brandsIndexResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method
   * Responsible for making call to category.service
   */
  async findCategoriesByUniqueId(
    categoriesId: string[],
    mySite: SiteInfo
  ): Promise<any> {
    if (categoriesId && categoriesId.length > 0) {
      let requestParameters = {
        storeId: mySite?.storeID,
        id: categoriesId,
        catalogId: mySite.catalogID,
      };

      return CategoriesService.getV2CategoryResourcesUsingGET(requestParameters)
        .then((res: any) => {
          const categories = res.contents;

          if (categories) {
            return categories;
          }
        })
        .catch((e) => {
          throw e;
        });
    }
  },

  /**
   * @method
   * Responsible for fetching subcategories for category carousel.
   */
  async fetchAllSubcategoriesForCarousel({
    categoryID,
    storeID,
    cancelToken,
  }: IFetchSubCategories): Promise<any> {
    const { SUB_CATEGORIES_URL } = CategoryServiceConstants;

    const fetchSubCategoriesUrl = SUB_CATEGORIES_URL(categoryID, storeID);

    try {
      const request: AxiosRequestConfig = {
        url: fetchSubCategoriesUrl,
        method: 'GET',
        cancelToken,
      };

      const subCategoriesResponse = await makeRequest(request);

      return subCategoriesResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method fetchSubCategoryFromFacet Fetches sub categories based on the specific ids.
   *
   * @param IFetchSubCategoriesFacetRequest
   */
  async fetchSubCategoryFromFacet({
    cancelToken,
    ids,
    storeId,
  }: IFetchSubCategoriesFacetRequest): Promise<ICategoriesResponse> {
    try {
      const { SUB_CATEGORIES_FACET_URL } = CategoryServiceConstants;

      const idsQuery = ids
        ? `&${qs.stringify(
            { id: ids },
            {
              indices: false,
            }
          )}`
        : '';

      const request: AxiosRequestConfig = {
        url: SUB_CATEGORIES_FACET_URL(storeId) + idsQuery,
        cancelToken,
      };

      const subCategoriesResponse: ICategoriesResponse =
        validateICategoriesResponse(await makeRequest(request));

      return subCategoriesResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { categoryService };
