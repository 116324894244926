import { createAction } from '@reduxjs/toolkit';
import IEspotResponse from '../../_foundation/interface/Responses/IEspotResponse';
import * as ACTION_TYPES from '../action-types/espot.action-types';

export interface IEspotAction {
  [key: string]: IEspotResponse;
}

export const UPDATE_ESPOT_ACTION = createAction<IEspotAction>(
  ACTION_TYPES.UPDATE_ESPOT
);

export const RESET_ESPOT_ACTION = createAction(ACTION_TYPES.RESET_ESPOT);
