const RviConstants = {
  VISIBLE_SLIDES: {
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
    xs: 1,
  },
  RVI_TITLE: 'RVI.RVI_TITLE',
};

export { RviConstants };
