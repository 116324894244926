import { call, put } from 'redux-saga/effects';
import { PaymentService } from '../../../_foundation/apis/payment/payment.service';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import i18n from '../../../i18n';
import { trackUserEvent } from '../../../utils/lucky-orange';
import { alertMessageId } from '../../../utils/utils';
import {
  INIT_AUTHENTICATION_KEY_SUCCESS_ACTION,
  TOKENIZE_CREDIT_DEBIT_CARD_ACTION,
  TOKENIZE_CREDIT_DEBIT_CARD_FAILURE_ACTION,
  TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS_ACTION,
} from '../../actions/payment.action';

const API_ERROR_KEY_TOKENX =
  'API_ERROR_HANDLING.CART_AND_CHECKOUT.TOKENX.ERROR';

const API_ERROR_UNABLE_TO_SAVE =
  'API_ERROR_HANDLING.MY_ACCOUNT.PAYMENT_INFORMATION.CARD_DETAILS.ERROR';

export function* fetchAuthenticationKey(action: any): Generator {
  try {
    const response: any = yield call(
      PaymentService.getIframeAuthenticationKey,
      action.payload
    );

    if (response) {
      yield put(INIT_AUTHENTICATION_KEY_SUCCESS_ACTION(response));
      yield put(
        TOKENIZE_CREDIT_DEBIT_CARD_ACTION({
          storeID: action.payload.storeID,
          cardNumber: action.payload.cardNumber,
          ...response,
        })
      );
    }
  } catch (e) {
    trackUserEvent({
      eventName: 'generateAuthToken failure',
      eventMsg: e,
    });

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(API_ERROR_KEY_TOKENX),
        severity: 'error',
        messageId: alertMessageId(),
      })
    );

    console.error(e);
  }
}

export function* tokenizeCreditOrDebitCard(action: any): Generator {
  try {
    const response: any = yield call(
      PaymentService.tokenizeCreditOrDebitCard,
      action.payload
    );

    if (response) {
      yield put(TOKENIZE_CREDIT_DEBIT_CARD_SUCCESS_ACTION(response));
    }
  } catch (e: any) {
    yield put(TOKENIZE_CREDIT_DEBIT_CARD_FAILURE_ACTION(e));

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(API_ERROR_UNABLE_TO_SAVE),
        severity: 'error',
        messageId: alertMessageId(),
      })
    );
  }
}
