import { Typography } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { storeLocatorSelector } from '../../../../redux/selectors/storeLocator';
import { enterKeyPressHandler } from '../../../../utils/utils';
import { IProductListFacetsEntry } from '../../../../_foundation/interface/ProductList/IProductList';
import { NteAlert } from '../../../NteAlert/NteAlert';
import { StoreFinder } from '../../AppBar/SecondaryMenuItems/StoreLocator/MyStore/StoreFinder/StoreFinder';
import { FacetBody } from '../Facet/FacetBody/FacetBody';
import { SoldInStoreConstants } from './SoldInStoreConstants';

/**
 * @interface ISoldInStoreFacet
 */
interface ISoldInStoreFacet {
  filterData: IProductListFacetsEntry[];
  facetGroup: string;
}

/**
 * @component SoldInStoreFacet renders the sold in store facet and the store finder.
 *
 * @param ISoldInStoreFacet
 */
const SoldInStoreFacet: React.FC<ISoldInStoreFacet> = ({
  filterData,
  facetGroup,
}) => {
  const { CHANGE_MY_STORE, FIND_A_STORE, NO_STORES_WITHIN_100_MILES } =
    SoldInStoreConstants;

  const [storeFacets, setStoreFacets] = useState<IProductListFacetsEntry[]>([]);

  const [changeStoreView, setChangeStoreView] = useState<boolean>(false);

  const { storeDetails, currentAnchorStoreName, currentStoreDetails } =
    useSelector(storeLocatorSelector);

  const { t } = useTranslation();

  const storeFinderTitle: string =
    storeFacets.length !== 0 ? t(CHANGE_MY_STORE) : t(FIND_A_STORE);

  /**
   * @callback initStoreFacets Initializes the store facets by mapping them to their respective
   * nearby stores and orders them based on the current anchor store.
   */
  const initStoreFacets = useCallback((): void => {
    const currentStoreFacets: IProductListFacetsEntry[] = [];

    // Fetch near by stores from Redux.
    const nearByStoreDetails = [...storeDetails];

    if (nearByStoreDetails) {
      /**
       * Iterate through the 5 near by stores and map them to the current facet data value.
       */

      nearByStoreDetails
        .splice(0, 5)
        .forEach(({ storeName, Description, uniqueID }) => {
          let currentFacet = filterData.filter(
            ({ label }) => uniqueID === label
          )[0];

          if (currentFacet) {
            /**
             * Replace the facet label with the store name and set isAnchorStore flag
             * if the current store is an anchor store.
             */
            currentFacet = {
              ...currentFacet,
              label: Description[0]?.displayStoreName,
              ...{
                ...(storeName === currentAnchorStoreName && {
                  isAnchorStore: true,
                }),
              },
            };

            currentStoreFacets.push(currentFacet);

            let anchorStoreName: string = '';

            if (storeName === currentAnchorStoreName) {
              anchorStoreName = currentFacet.label;
            }

            /**
             * Move the anchor store to the top of the other checkboxes.
             */
            const anchorStoreIndex: number = currentStoreFacets.findIndex(
              ({ label }) => label === anchorStoreName
            );

            if (anchorStoreIndex !== -1) {
              const anchorStoreFacet: IProductListFacetsEntry =
                currentStoreFacets.splice(anchorStoreIndex, 1)[0];

              currentStoreFacets.unshift(anchorStoreFacet);
            }
          }
        });

      setStoreFacets([...currentStoreFacets]);
    }
  }, [currentAnchorStoreName, filterData, storeDetails]);

  useEffect(() => {
    initStoreFacets();
  }, [initStoreFacets, currentAnchorStoreName]);

  const moveToStoreFacet = useCallback((): void => {
    if (currentStoreDetails?.storeName) {
      setChangeStoreView(false);
    }
  }, [currentStoreDetails]);

  useEffect(() => {
    moveToStoreFacet();
  }, [moveToStoreFacet]);

  return (
    <>
      {!changeStoreView ? (
        <div className='sold_in_store'>
          {storeFacets.length !== 0 ? (
            <FacetBody facetGroup={facetGroup} filterData={storeFacets} />
          ) : (
            <NteAlert
              messages={[t(NO_STORES_WITHIN_100_MILES)]}
              severity='warning'
              showClose={false}
            />
          )}

          <Typography
            onClick={() => setChangeStoreView(true)}
            role='link'
            className='link-effect find-store'
            tabIndex={0}
            onKeyDown={(e: any) =>
              enterKeyPressHandler(e, () => {
                setChangeStoreView(true);
              })
            }
            variant='body1'>
            {storeFinderTitle} <ChevronRight />
          </Typography>
        </div>
      ) : (
        <StoreFinder
          menuTitle={storeFinderTitle}
          showBack={true}
          storeFinderBackHandler={() => setChangeStoreView(false)}
        />
      )}
    </>
  );
};

export { SoldInStoreFacet };
