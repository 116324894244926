import { createReducer } from '@reduxjs/toolkit';
import { IProductList } from '../../_foundation/interface/ProductList/IProductList';
import {
  FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION,
  RESET_FETCH_CART_ITEM_DETAILS_ACTION,
} from '../actions/orderDetails.action';

const initProductDetails: IProductList = {
  contents: [],
  total: 0,
};

const orderDetailsReducer = createReducer(initProductDetails, (builder) => {
  /**
   * @action FETCH_ORDER_DETAILS_SUCCESS_ACTION
   * Fetches all the productDetails for an order
   */
  builder.addCase(
    FETCH_CART_ITEM_DETAILS_SUCCESS_ACTION,
    (state: IProductList, action: any) => {
      const { contents, total } = action.payload;
      if (contents) {
        return {
          contents: contents,
          total: total,
        };
      }
    }
  );

  builder.addCase(RESET_FETCH_CART_ITEM_DETAILS_ACTION, () => {
    return { ...initProductDetails };
  });
});

export { orderDetailsReducer };
