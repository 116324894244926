import { AxiosRequestConfig } from 'axios';
import { makeRequest } from '../../../_foundation/apis/axios/axiosConfig';
import {
  ICardinalOrderRequest,
  ICardinalOrderResponse,
} from '../interface/ICheckoutState';
import { CheckoutServiceConstants } from './checkout.service.constants';

const CheckoutService = {
  /**
   * @method cardinalOrder Makes a cardinal request.
   *
   * @param ICardinalOrderRequest
   */
  async cardinalOrder({
    storeId,
    orderId,
    piAmount,
    history,
  }: ICardinalOrderRequest): Promise<ICardinalOrderResponse> {
    try {
      const { CARDINAL_ORDER_URL } = CheckoutServiceConstants;

      const verificationCodeRequest: AxiosRequestConfig = {
        url: CARDINAL_ORDER_URL(storeId),
        method: 'POST',
        data: {
          piAmount,
          orderId: orderId,
          payMethodId: 'PayPal',
        },
      };

      const verificationCodeResponse: ICardinalOrderResponse =
        await makeRequest(verificationCodeRequest, undefined, history);

      return verificationCodeResponse;
    } catch (e) {
      throw e;
    }
  },
};

export { CheckoutService };
