
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import ICategoriesResponse from './ICategoriesResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { ICategoriesResponse };

  export const ICategoriesResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "ICategoryChildren": {
            "properties": {
                "UserData": {
                    "items": [
                        {
                            "properties": {
                                "productCount": {
                                    "type": "string"
                                }
                            },
                            "required": [
                                "productCount"
                            ],
                            "type": "object"
                        }
                    ],
                    "maxItems": 1,
                    "minItems": 1,
                    "type": "array"
                },
                "children": {
                    "items": {
                        "$ref": "#/definitions/ICategoryChildren"
                    },
                    "type": "array"
                },
                "description": {
                    "type": "string"
                },
                "id": {
                    "type": "string"
                },
                "identifier": {
                    "type": "string"
                },
                "links": {
                    "properties": {
                        "children": {
                            "items": {},
                            "type": "array"
                        },
                        "parent": {
                            "properties": {
                                "href": {
                                    "type": "string"
                                }
                            },
                            "required": [
                                "href"
                            ],
                            "type": "object"
                        },
                        "self": {
                            "properties": {
                                "href": {
                                    "type": "string"
                                }
                            },
                            "required": [
                                "href"
                            ],
                            "type": "object"
                        }
                    },
                    "type": "object"
                },
                "name": {
                    "type": "string"
                },
                "product_count": {
                    "type": "string"
                },
                "seo": {
                    "properties": {
                        "href": {
                            "type": "string"
                        }
                    },
                    "required": [
                        "href"
                    ],
                    "type": "object"
                },
                "storeID": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                }
            },
            "required": [
                "name"
            ],
            "type": "object"
        },
        "ICategoryData": {
            "properties": {
                "UserData": {
                    "items": [
                        {
                            "properties": {
                                "productCount": {
                                    "type": "string"
                                }
                            },
                            "required": [
                                "productCount"
                            ],
                            "type": "object"
                        }
                    ],
                    "maxItems": 1,
                    "minItems": 1,
                    "type": "array"
                },
                "children": {
                    "items": {
                        "$ref": "#/definitions/ICategoryChildren"
                    },
                    "type": "array"
                },
                "description": {
                    "type": "string"
                },
                "id": {
                    "type": "string"
                },
                "identifier": {
                    "type": "string"
                },
                "links": {
                    "properties": {
                        "children": {
                            "items": {
                                "type": "string"
                            },
                            "type": "array"
                        },
                        "self": {
                            "properties": {
                                "href": {
                                    "type": "string"
                                }
                            },
                            "type": "object"
                        }
                    },
                    "type": "object"
                },
                "name": {
                    "type": "string"
                },
                "product_count": {
                    "type": "string"
                },
                "seo": {
                    "properties": {
                        "href": {
                            "type": "string"
                        }
                    },
                    "type": "object"
                },
                "storeID": {
                    "type": "string"
                },
                "thumbnail": {
                    "type": "string"
                }
            },
            "required": [
                "id",
                "identifier",
                "name",
                "storeID"
            ],
            "type": "object"
        }
    },
    "properties": {
        "contents": {
            "items": {
                "$ref": "#/definitions/ICategoryData"
            },
            "type": "array"
        }
    },
    "required": [
        "contents"
    ],
    "type": "object"
}

;

  export const isICategoriesResponse = ajv.compile(ICategoriesResponseSchema) as ValidateFunction<
    ICategoriesResponse
  >;

  export default function validateICategoriesResponse(value: unknown): ICategoriesResponse {
    if (isICategoriesResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isICategoriesResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'ICategoriesResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isICategoriesResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'ICategoriesResponse' },
        ) +
          inspect(value)
      );
    }
  }