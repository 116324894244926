import cookie from 'react-cookies';

/**
 * @method defaultCookieExpirationDate Returns the default cookie expiration time.
 */
const defaultCookieExpirationDate = (): number => {
  const date = new Date();

  return date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
};

/**
 * @global Utility that handles all functions related to cookies
 */
const CookieUtility = {
  /**
   * @method setCookie is responsible for setting cookie in the brower.
   *
   * @param key Send the key value for the cookie.
   * @param value Send the value/object to be stored for the given cookie key.
   * @param expires Send the expiration value for the cookie.
   */
  setCookies(
    key: string,
    value: any,
    expire: number = defaultCookieExpirationDate()
  ): void {
    cookie.save(key, value, { path: '/', expires: new Date(expire) });
  },

  /**
   * @method getCookie is responsible for getting the saved cookies or returns undefined.
   * @param key Send the key value for the cookie to be fetched.
   */
  getCookie(key: string): any {
    return cookie.load(key);
  },

  /**
   * @method deleteCookie is responsible for deleting an existing cookie.
   * @param key Send the key value for the cookie to be deleted.
   */
  deleteCookie(key: string): void {
    cookie.remove(key);
  },
};

export { CookieUtility };
