import { Button, CircularProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CUSTOMER_HELP,
  MY_ORDERS,
  TRACK_ORDER,
  PLATINUM_MEMBERSHIP,
  GREAT_PLAINS_MEMBERSHIP,
  NORTHERN_PREFERRED_MEMBERSHIP,
  ORDER_FROM_CATALOG as orderFromCatalog,
  REQUEST_FREE_CATALOG as RequestFreeCatalog,
} from '../../../../../../constants/routes';
import { HEADER_MENU_CLICK_TEALIUM } from '../../../../../../constants/Tealium';
import { authenticationSelector } from '../../../../../../redux/selectors/auth.selector';
import { useMenuNavigator } from '../../../../../../utils/hooks/menu-navigator/MenuNavigatorHooks';
import {
  enterKeyPressHandler,
  sendTealiumData,
} from '../../../../../../utils/utils';
import { UserType } from '../../../../../../_foundation/enum/User/UserType';
import { useNteNestedMenu } from '../../../../../Menu/NteMenuList/hooks/NteNestedMenuHooks';
import { NteMenuList } from '../../../../../Menu/NteMenuList/NteMenuList';
import { useNtePopper } from '../../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { CatalogMenuConstants } from '../../../ExpandableMenu/CatalogMenu/CatalogMenuConstants';
import { DealsMenuItemConstants } from '../../../../AppBar/PrimaryMenuItems/NavBarMenuItem/DealsMenuItem/DealsMenuItemConstants';
import { SupportMenuConstants } from '../../../ExpandableMenu/SupportMenu/SupportMenuConstants';
import { useNavBarMenu } from '../hooks/NavBarMenuHooks';
import { useHamburgerMenu } from './hooks/NavBarHamburgerMenuHooks';

/**
 * @component NavBarHamburgerMenu renders the Nte
 * Hamburger menu icon and the hamburger menu.
 */
const NavBarHamburgerMenu: React.FC = () => {
  const { RenderPopper, ntePopperClickHandler, showPopper } = useNtePopper({
    id: 'hamburger-menu',
    ariaLabelledById: 'hamburger-menu-list',
  });

  const { menuItems, loading } = useNavBarMenu();

  const { hamburgerMenuItems } = useHamburgerMenu(menuItems);

  const { navigateNestedMenu } = useMenuNavigator();

  const { REQUEST_FREE_CATALOG, ORDER_FROM_CATALOG } = CatalogMenuConstants;

  const {
    menuState: { active, isRootMenu, items, childrenElement },
    clickEventsHandler,
  } = useNteNestedMenu({
    showPopper,
    initMenuData: hamburgerMenuItems,
  });

  const menuRef = useRef(null);

  const history = useHistory();

  const { t } = useTranslation();

  const { isUserAuthenticated } = useSelector(authenticationSelector);

  const { userType } = useSelector(authenticationSelector);

  const { DEALS_TITLE } = DealsMenuItemConstants;

  const { TRACKING, CUSTOMER_HELP_TEXT, CHAT } = SupportMenuConstants;

  /**
   * @method menuItemClickHandler is responsible for handling
   * the click events when the menu item is clicked.
   */
  const menuItemClickHandler = (selectedEvent?: any): void => {
    if (!selectedEvent?.backAction) {
      sendTealiumData({
        tealium_event: HEADER_MENU_CLICK_TEALIUM,
        nav_header_element: selectedEvent?.currentItemIdentifier,
      });
    }

    if (
      typeof selectedEvent.currentItem === 'string' &&
      !selectedEvent.isNestedMenu &&
      !selectedEvent?.backAction
    ) {
      const currentPath = selectedEvent?.currentItem.toLowerCase();

      navigateNestedMenu({
        currentPath,
        currentItemIdentifier: selectedEvent?.currentItemIdentifier,
        ntePopperClickHandler,
        seoPath: selectedEvent.currentPath,
      });

      if (userType === UserType.Platinum) {
        if (selectedEvent.currentItem === t(DEALS_TITLE)) {
          history.push(PLATINUM_MEMBERSHIP);
        }
      }

      if (userType === UserType.GreatPlains) {
        if (selectedEvent.currentItem === t(DEALS_TITLE)) {
          history.push(GREAT_PLAINS_MEMBERSHIP);
        }
      }

      if (userType === UserType.NorthernPreferred) {
        if (selectedEvent.currentItem === t(DEALS_TITLE)) {
          history.push(NORTHERN_PREFERRED_MEMBERSHIP);
        }
      }

      if (selectedEvent.currentItem === TRACKING) {
        if (isUserAuthenticated) {
          history.push(MY_ORDERS);
        } else {
          history.push(TRACK_ORDER);
        }
      }

      if (selectedEvent.currentItem === CUSTOMER_HELP_TEXT) {
        history.push(CUSTOMER_HELP);
      }

      if (selectedEvent.currentItem === t(REQUEST_FREE_CATALOG)) {
        history.push(RequestFreeCatalog);
      }

      if (selectedEvent.currentItem === t(ORDER_FROM_CATALOG)) {
        history.push(orderFromCatalog);
      }
      if (selectedEvent.currentItem === t(CHAT)) {
        setTimeout(() => {
          window.openMChat();
        }, 500);
      }
      return;
    }

    // Trigger clickEventsHandler for nested menus.
    clickEventsHandler(selectedEvent);
  };

  const dealsHtml = childrenElement && (
    <ul className='deals'> {childrenElement as JSX.Element} </ul>
  );

  return !loading ? (
    <>
      <Button
        type='button'
        className={`reset-button ${showPopper ? 'pointer-events-none' : ''}`}
        onClick={ntePopperClickHandler}
        onKeyDown={(e: any) => enterKeyPressHandler(e, ntePopperClickHandler)}>
        <MenuIcon fontSize='large' ref={menuRef} />
        <span className='sr-only'>Open menu</span>
      </Button>

      {showPopper && (
        <RenderPopper
          anchor={menuRef}
          showPopper={showPopper}
          ntePopperCloseHandler={ntePopperClickHandler}>
          <NteMenuList
            isRootMenu={isRootMenu}
            menuItems={items}
            children={dealsHtml}
            menuListCloseHandler={ntePopperClickHandler}
            menuTitle={active ? active : 'Menu'}
            nteMenuClickHandler={menuItemClickHandler}
            id='hamburger-menu-list'
          />
        </RenderPopper>
      )}
    </>
  ) : (
    <CircularProgress
      size={24}
      className='hamburger-progressbar'
      color='secondary'
    />
  );
};

export { NavBarHamburgerMenu };
