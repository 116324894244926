import { Skeleton } from '@material-ui/lab';
import React from 'react';

/**
 * @component PageSkeleton renders skeleton for an entire page and
 * is used as a fallback for the NteRouter suspense.
 */
const PageSkeleton: React.FC = () => (
  <div className='page page-skeleton-container'>
    <section className='page-row'>
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
      <Skeleton className='page-skeleton' variant='rect' />
    </section>
  </div>
);

export { PageSkeleton };
