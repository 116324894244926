import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();

/**
 * Initialize the middleware for redux store.
 */
const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    immutableCheck: true,
    serializableCheck: false,
  }),
  sagaMiddleware,
];

/**
 * Configure the redux store.
 */
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: true,
  enhancers: [],
});

sagaMiddleware.run(rootSaga);

export default store;
