import {
  STORE_LOCATOR,
  STORE_MENU_GET_PARTS,
} from '../../../../../../../constants/routes';

const StoreDetailsConstants = {
  STORE_DETAILS_LIST: [
    { item: 'Change My Store', isNested: true },
    { item: 'Get Directions', isNested: false },
    { item: 'See Store Ad', isNested: false },
    {
      item: 'Get Parts, Service, or Repairs',
      isNested: false,
      href: STORE_MENU_GET_PARTS,
    },
    { item: 'See Other Stores', isNested: false, href: STORE_LOCATOR },
  ],
  MY_STORE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.MY_STORE',
  MY_STORE_FALLBACK: 'My Store',
};

export { StoreDetailsConstants };
