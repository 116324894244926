const ImageUrlUtility = {
  /**
   * @method modifyImageUrl strips the portion of the image url before 'images'
   * @param imageUrl original image url
   * @returns modifiedImageUrl
   */
  modifyImageUrl(imageUrl: string): string {
    const index: number = imageUrl ? imageUrl.indexOf('images') : 0;

    const modifiedImageUrl: string = index ? imageUrl.slice(index) : '';

    return modifiedImageUrl;
  },

  /**
   * @method createImgSrc
   * @param imageUrl
   * @returns image url from cloud fare
   */
  createImgSrc(imageUrl: string): string {
    const assetServer = process.env.REACT_APP_ASSET_HOST;
    const cloudFare = process.env.REACT_APP_CLOUDFARE;
    const fitScale = 'fit=scale-down,';
    let modifiedUrl;
    let updatedImageUrl;
    if (imageUrl) {
      const isHclStore = imageUrl.includes('/hclstore');

      modifiedUrl = isHclStore ? imageUrl.replace('/hclstore', '') : imageUrl;

      updatedImageUrl = modifiedUrl ? modifiedUrl : imageUrl;

      //87322: updated logic to prefix url with `/` incase it does not exists
      return `${assetServer}${cloudFare}${fitScale}width={width}${
        updatedImageUrl.startsWith('/')
          ? updatedImageUrl
          : `/${updatedImageUrl}`
      }`;
    } else {
      return '';
    }
  },
};

export { ImageUrlUtility };
