import { RefObject, useCallback, useEffect, useState } from 'react';
import { ProductDetailService } from '../../../../../_foundation/apis/pdp/product-detail.service';

interface IReviewCount {
  partNumber: string;
  inViewport?: boolean;
  refData?: RefObject<HTMLDivElement>;
}

/**
 * @hook responsible to get the review count for a particular product
 * @param partNumber
 */
const useReviewCount = ({ partNumber, inViewport }: IReviewCount) => {
  const [productReview, setProductReview] = useState<number>(0);

  const [averageRating, setAverageRating] = useState<number>(0);

  const [bvData, setBvData] = useState<any>();

  /**
   * @method getReviewForProduct
   * Responsible to get review for the product
   */
  const getReviewForProduct = useCallback(async () => {
    if (inViewport && !bvData) {
      const bvPdpData = await ProductDetailService.getProductReviewByPartNumber(
        partNumber
      );

      setBvData(bvPdpData);

      if (bvPdpData) {
        setProductReview(Number(bvPdpData?.reviewCount));

        setAverageRating(Number(bvPdpData?.ratingValue));
      } else {
        setProductReview(0);

        setAverageRating(0);
      }
    }
  }, [bvData, inViewport, partNumber]);

  useEffect(() => {
    getReviewForProduct();
  }, [getReviewForProduct]);

  return {
    productReview,
    averageRating,
  };
};

export { useReviewCount };
