import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Rating } from '@material-ui/lab';
import { decode } from 'html-entities';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import {
  RESET_PRODUCT_LIST_ACTION,
  SET_FACET_ACTION,
} from '../../../../../redux/actions/productList.actions';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import {
  formatFacetPrice,
  formateProductSpecAttributeValue,
  removeQueryParamBackslash,
} from '../../../../../utils/utils';
import { NteCheckbox } from '../../../../Forms/NteCheckbox/NteCheckbox';
import { ScreenReaderOnly } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { FacetConstants } from '../FacetConstants';
import RatingStar from '../RatingsFacetIcon/RatingStar';
import RatingStarBorder from '../RatingsFacetIcon/RatingStarBorder';
import { useFacetLink } from '../hooks/FacetLinkHooks';

/**
 * @interface IFacetCheckbox
 */
interface IFacetCheckbox {
  currentFacetData: IProductListFacetsEntry;
  isPriceFacet?: boolean;
  facetGroup: string;
  isRatings?: boolean;
}

/**
 * @component FacetCheckbox renders the checkbox component for the facets
 *
 * @param IFacetCheckbox
 */
const FacetCheckbox: React.FC<IFacetCheckbox> = ({
  currentFacetData,
  isPriceFacet,
  facetGroup,
  isRatings,
}) => {
  const {
    FACET_QUERY_KEY,
    PRICE,
    PRICE_LABEL,
    MY_STORE,
    PARENT_CATALOG_GROUP,
    ALL_DEALS_LABEL,
    BRAND_FACET_KEY,
  } = FacetConstants;

  const history = useHistory();

  const [checked, setChecked] = useState<boolean>(false);

  const { selectedGroups, selectedFacets } = useSelector(productListSelector);

  const dispatch = useDispatch();

  const [encodedFacetsParams, setFacetsParams] = useQueryParam(
    FACET_QUERY_KEY,
    StringParam
  );

  const { constructFacetUrl } = useFacetLink();

  const facetsParams = encodedFacetsParams
    ? removeQueryParamBackslash(encodedFacetsParams)
    : '';

  const { t } = useTranslation();

  const categoryGrpQueryString = 'path.tree%3A'.concat(currentFacetData.value);

  /**
   * @method facetCheckboxHandler Handles events from facet checkbox.
   */
  const facetCheckboxHandler = (): void => {
    const isSelected = !checked;

    setChecked(isSelected);

    const facetKeys = Object.keys(selectedGroups);

    const isActivePrice = facetKeys.some(
      (value) => value === PRICE || value === PRICE_LABEL
    );

    /**
     * Remove the query params from the url if we have one normal facet value and a price group facet.
     */
    if (
      selectedFacets.length === 2 &&
      facetsParams &&
      !isSelected &&
      isActivePrice
    ) {
      setFacetsParams(undefined, 'replaceIn');
    }

    /**
     * Remove the query params from the url if we have only one facetValue in the selectedFacets.
     */
    if (selectedFacets.length === 1 && facetsParams && !isSelected) {
      setFacetsParams(undefined, 'replaceIn');

      dispatch(
        RESET_PRODUCT_LIST_ACTION({
          preserveSubCategories: true,
          preserveSelectedFacet: true,
        })
      );
    }

    let selectedFacetValue = { ...currentFacetData };

    if (facetGroup === PARENT_CATALOG_GROUP) {
      selectedFacetValue = {
        ...selectedFacetValue,
        value:
          selectedFacetValue?.label === ALL_DEALS_LABEL
            ? selectedFacetValue.value
            : categoryGrpQueryString,
      };
    }

    /**
     * When an item is checked add it to the
     * redux selectedFacets list.
     */
    dispatch(
      SET_FACET_ACTION({
        facetData: selectedFacetValue,
        isChecked: isSelected,
        facetGroup,
        isPriceFacet,
      })
    );
  };

  /**
   * @method initCheckedState
   * Check whether an item has already been added to the
   * selectedFacets list and update it's checked state accordingly.
   */
  const initCheckedState = useCallback((): void => {
    if (selectedGroups[facetGroup] && selectedGroups[facetGroup].length !== 0) {
      const currentIndex = selectedGroups[facetGroup].findIndex(
        (selectedFacet: IProductListFacetsEntry) => {
          if (facetGroup === PARENT_CATALOG_GROUP) {
            return (
              selectedFacet?.value?.toLowerCase() ===
              categoryGrpQueryString?.toLowerCase()
            );
          } else {
            return (
              selectedFacet?.value?.toLowerCase() ===
              currentFacetData?.value?.toLowerCase()
            );
          }
        }
      );

      setChecked(currentIndex !== -1);
    } else {
      setChecked(false);
    }
  }, [
    PARENT_CATALOG_GROUP,
    categoryGrpQueryString,
    currentFacetData.value,
    facetGroup,
    selectedGroups,
  ]);

  useEffect(() => {
    initCheckedState();
  }, [initCheckedState]);

  const facetLabel = currentFacetData.label;

  const facetCount = currentFacetData.count;

  let priceFacetLabel = '';
  if (isPriceFacet) {
    priceFacetLabel = formatFacetPrice(currentFacetData.label);
  }

  const facetCheckboxLabel = `${
    priceFacetLabel !== '' ? priceFacetLabel : facetLabel
  } ${facetCount !== '0' ? `(${facetCount})` : ''} `;

  const checkboxLabel: React.ReactNode | string = isRatings ? (
    <div className='facet-rating'>
      <Rating
        value={Number(facetLabel)}
        className='facet-stars'
        readOnly
        precision={0.25}
        icon={
          <RatingStar
            className='star filled'
            role='img'
            aria-label={`${facetLabel} rating icon`}
          />
        }
        emptyIcon={
          <RatingStarBorder
            className='star unfilled'
            role='img'
            aria-label={`rating icon`}
          />
        }
      />

      <Typography variant='body2'>
        {Number(facetLabel) < 5} ({facetCount})
      </Typography>
    </div>
  ) : (
    formateProductSpecAttributeValue(decode(facetCheckboxLabel))
  );

  const classFlags = () => {
    if (currentFacetData.isAnchorStore) {
      return 'is-anchor-store';
    } else if (isRatings) {
      return 'is-ratings';
    } else {
      return '';
    }
  };

  return facetGroup === BRAND_FACET_KEY ? (
    <Link
      to={{
        pathname: history.location.pathname,
        search: constructFacetUrl({
          currentFacetData,
          facetGroup,
          checked,
        }),
      }}
      onClick={(e: any) => {
        e.preventDefault();

        facetCheckboxHandler();
      }}
      className='facet_checkbox'>
      <ScreenReaderOnly
        ariaLabelledBy={`${currentFacetData.label}-id`}
        message={`${facetCheckboxLabel} items`}
      />

      {currentFacetData && (
        <NteCheckbox
          labelPlacement='end'
          checked={checked}
          id={`${currentFacetData.label}-id`}
          label={checkboxLabel}
          value={currentFacetData.value}
          disabled={
            /**
             * Disables the facet checkbox when we have the product count 0 or less than 0
             */
            Number(currentFacetData?.count) <= 0
          }
        />
      )}

      {currentFacetData.isAnchorStore && (
        <p className='icon-row my-store'>
          <CheckCircleIcon /> {t(MY_STORE)}
        </p>
      )}
    </Link>
  ) : (
    <div className={`facet_checkbox ${classFlags()}`}>
      <ScreenReaderOnly
        ariaLabelledBy={`${currentFacetData.label}-id`}
        message={`${facetCheckboxLabel} items`}
      />

      {currentFacetData && (
        <NteCheckbox
          labelPlacement='end'
          onChange={facetCheckboxHandler}
          checked={checked}
          id={`${currentFacetData.label}-id`}
          label={checkboxLabel}
          value={currentFacetData.value}
          disabled={
            /**
             * Disables the facet checkbox when we have the product count 0 or less than 0
             */
            Number(currentFacetData?.count) <= 0
          }
        />
      )}

      {currentFacetData.isAnchorStore && (
        <p className='icon-row my-store'>
          <CheckCircleIcon /> {t(MY_STORE)}
        </p>
      )}
    </div>
  );
};

export { FacetCheckbox };
