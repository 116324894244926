/**
 * FieldValidation Utility Validates form fields based on business requirement.
 */

import { getPatternFromNumber } from '../../../Account/AccountDashboardPages/SavedPayment/AddNewSavedCard/AddDebitCreditCard/utils/cardUtils';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PASSWORD_SPACE_REGEX = /^\S(?!.*\s{2}).*?\S$/;

const ZIP_CODE_US_REGEX = /^\d{5}?(\d{4})?$/i;

const UNICODE_REGEX = /^[A-Za-z 0-9_.-]*$/;

const UNICODE_EMAIL_REGEX = /^[+A-Za-z0-9-_.@]*$/;

const ZIP_CODE_CANADA_REGEX = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

const FieldValidations = {
  REQUIRED: (value: string, message?: string) => {
    if (!value) {
      return message || 'This is a required field';
    }
    return '';
  },
  NAME: (value: string, message?: string) => {
    if (value?.length > 35) {
      return message || 'This field can be a max of only 35 characters';
    }

    return '';
  },
  GIFT_CARD_NUMBER: (value: string, message?: string) => {
    if (value?.length !== 19) {
      return (
        message ||
        'Invalid Gift Card number. Please check the number and try again.'
      );
    }

    return '';
  },
  GIFT_CARD_PIN: (value: string, message?: string) => {
    if (value?.length !== 4) {
      return (
        message ||
        'Invalid Gift Card PIN number. Please check the number and try again.'
      );
    }

    return '';
  },
  BUSINESS_NAME: (value: string, message?: string) => {
    if (value?.length > 40) {
      return message || 'This field can be a max of only 35 characters';
    }

    return '';
  },
  ADDRESS: (value: string, message?: string) => {
    if (value?.length > 35) {
      return message || 'This field can be a max of only 35 characters';
    }

    return '';
  },
  CITY: (value: string, message?: string) => {
    if (value?.length > 30) {
      return message || 'This field can be a max of only 30 characters';
    }

    return '';
  },
  ZIP_CODE_US: (value: string, message?: string) => {
    const noSpecialCharacters = value.replace(/[^\w ]/g, '');

    if (!ZIP_CODE_US_REGEX.test(noSpecialCharacters)) {
      return message || 'Please Enter a valid Zip Code.';
    }

    return '';
  },
  ZIP_CODE_CANADA: (value: string, message?: string) => {
    if (!ZIP_CODE_CANADA_REGEX.test(value)) {
      return message || 'Please Enter a valid Zip Code.';
    }

    return '';
  },
  ZIP_CODE_OUTSIDE_US_CANADA: (value: string, message?: string) => {
    if (value && value.length > 10) {
      return message || 'Please Enter a valid Zip Code.';
    }

    return '';
  },
  PHONE_NUMBER_US: (value: string, message?: string) => {
    const formattedPhoneNumber = value.replace(/ /g, '');

    if (formattedPhoneNumber.length !== 10) {
      return message || 'Please Enter a valid Phone Number.';
    }

    return '';
  },
  PHONE_NUMBER_OUTSIDE_US: (value: string, message?: string) => {
    if (value?.length > 20) {
      return message || 'Please Enter a valid Phone Number.';
    }

    return '';
  },
  EMAIL: (value: string, message?: string) => {
    if (!value) return '';

    if (!EMAIL_REGEX.test(value)) {
      return message || 'Invalid Email';
    }
    return '';
  },
  UNICODE: (value: string, message?: string) => {
    if (!value) return '';

    if (!UNICODE_REGEX.test(value)) {
      return message || 'Invalid character used.';
    }
    return '';
  },
  UNICODE_EMAIL: (value: string, message?: string) => {
    if (!value) return '';

    if (value.includes('/')) {
      return message || 'Invalid character used.';
    }

    if (!UNICODE_EMAIL_REGEX.test(value)) {
      return message || 'Invalid character used.';
    }
    return '';
  },
  PASSWORD_VALIDATIONS: {
    checkLengthRequirement: (password: string) => {
      if (password?.length >= 8) {
        return true;
      }

      return false;
    },
    checkNumberRequirement: (password: string) => {
      if (/\d/.test(password)) {
        return true;
      }

      return false;
    },
    checkLetterRequirement: (password: string) => {
      if (!password) return false;

      return /[a-zA-Z]/.test(password);
    },
    checkSpacesRequirement: (password: string) => {
      if (!password) return false;

      return PASSWORD_SPACE_REGEX.test(password);
    },
    checkletterTwiceRequirement: (password: string) => {
      if (!password) return false;

      return /([a-zA-Z0-9])\1{2,}/.test(password);
    },
  },
  CUSTOMER_NUMBER_VALIDATION: (value: string, message?: string) => {
    if (!value) return '';

    if (!/^\d+$/.test(value)) {
      return message || 'A valid customer number is required.';
    }

    return '';
  },
  CUSTOMER_NUMBER_LENGTH_VALIDATION: (value: string, message?: string) => {
    if (value?.length > 8) {
      return message || 'A valid customer number is required.';
    }

    return '';
  },
  QUANTITY_NUMBER: (value: string, message?: string) => {
    if (!value) return '';

    if (!/^\d+$/.test(value)) {
      return message || 'A valid quantity is required.';
    }

    return '';
  },
  COMPOSE_VALIDATORS: (...validators: any[]) => {
    return (value: any) => {
      return validators.reduce(
        (error, validator) => error || validator(value),
        ''
      );
    };
  },
  CREDIT_CARD_VALIDATOR: (value: string, message?: string) => {
    const patternLength = getPatternFromNumber(value, true).replace(
      /\s/g,
      ''
    ).length;
    if (value.length !== patternLength) {
      return 'Card number is not valid.';
    }
  },
  SECURITY_CODE_VALIDATION: (value: string, message?: string) => {
    if (!value) return '';

    if (!/^\d+$/.test(value)) {
      return message || 'Invalid security code';
    }

    return '';
  },
  SECURITY_CODE_LENGTH_VALIDATION: (value: string, message?: string) => {
    if (value?.length < 3) {
      return message || 'Invalid security code';
    }

    return '';
  },
};

export { FieldValidations };
