import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductPrice } from '../../../utils/product-utility';
import { enterKeyPressHandler } from '../../../utils/utils';
import { useNtePopper } from '../../Poppers/NtePopper/hooks/NtePopperHooks';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { ProductPricingTable } from '../../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricingTable/ProductPricingTable';

interface IQuantityPricingPopper {
  productPrice: IProductPrice;
}

const QuantityPricingPopper: React.FC<IQuantityPricingPopper> = ({
  productPrice,
}) => {
  const { SEE_QTY_PRICING } = ProductCardSectionMessagesConstants;

  const qtyPricePopper = useRef(null);

  const { t } = useTranslation();

  const { RenderPopper, ntePopperClickHandler, showPopper } = useNtePopper({
    id: 'qty-pricing-popper',
    ariaLabelledById: 'qty-pricing',
    focusTrap: false,
  });

  return (
    <>
      <p
        ref={qtyPricePopper}
        onClick={ntePopperClickHandler}
        onKeyDown={(e) => enterKeyPressHandler(e, ntePopperClickHandler)}
        tabIndex={0}
        role='link'
        className='sub-product-offer-price qty-pricing pxs link-effect'>
        {t(SEE_QTY_PRICING)}
      </p>

      <RenderPopper
        anchor={qtyPricePopper}
        ntePopperCloseHandler={ntePopperClickHandler}
        showPopper={showPopper}>
        <ProductPricingTable
          itemPrices={productPrice?.itemPrices ? productPrice?.itemPrices : []}
          itemQuantities={
            productPrice?.itemQuantities ? productPrice?.itemQuantities : []
          }
          closeHandler={ntePopperClickHandler}
        />
      </RenderPopper>
    </>
  );
};

export { QuantityPricingPopper };
