import {
  AddressType,
  IAddressForm,
} from '../../../../components/Widgets/AddressForm/IAddressForm';

/**
 * @interface IAddAddress
 */
export interface IAddAddress {
  addressDetails: IAddressForm;
  addressType: AddressType;
  setPrimary?: PrimaryFlag;
  storeID: string;
  useForBilling?: boolean;
  userAuthenticated?: boolean;
}

/**
 * @interface IUpdateAddress
 */
export interface IUpdateAddress {
  addressDetails: Partial<IAddressForm>;
  setPrimary: PrimaryFlag;
  storeID: string;
  useForBilling?: boolean;
  userAuthenticated?: boolean;
  isFromBilling?: boolean;
}

/**
 * @interface IDeleteAddress
 */
export interface IDeleteAddress {
  addressNickname: string;
  storeID: string;
}

/**
 * @interface ISetPrimaryAddress
 */
export interface ISetPrimaryAddress {
  addressNickname: string;
  setPrimary: PrimaryFlag;
  storeID: string;
}

/**
 * @interface ISetPrimaryAddress
 */
export interface IRemovePrimaryFlag {
  addressNickname: string;
  storeID: string;
  WCToken: string;
  WCTrustedToken: string;
}

/**
 * @enum PrimaryFlag
 */

export enum PrimaryFlag {
  true = 'true',
  false = 'false',
}

/**
 * @interface IContactUs
 */
export interface IContactUs {
  emailAddress: string;
  firstName: string;
  lastName: string;
  customerNumber?: string;
  subject: string;
  comments?: string;
  contactMe: boolean;
  storeID: string;
}
