/**
 * CSS class names that control whether a field is scrambled or not in lucky orange.
 * See https://developers.luckyorange.com/libraries/browser/privacy-tools#hide-sensitive-content
 */
const LUCKY_ORANGE_CONSTANTS = {
    /**
     * Mark element and any children as sensitve data in lucky orange.
     */
    SENSITIVE_DATA : "lo-sensitive",
    /**
     * Mark element as not sensitve in lucky orange, WILL NOT OVERRIDE A HIGHER LEVEL ELEMENT MARKED SENSITIVE.
     */
    NOT_SENSITIVE_DATA: "lo-not-sensitive"
}
export { LUCKY_ORANGE_CONSTANTS };