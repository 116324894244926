import { decode } from 'html-entities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import { RESET_BREADCRUMB_ACTION } from '../../../../../redux/actions/breadcrumb.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  RESET_PRODUCT_LIST_ACTION,
} from '../../../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../../../redux/selectors/breadcrumb.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { useCategoryFacetParam } from '../../../../../utils/hooks/category-facet-param/category-facet-param';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import {
  formatQueryParams,
  formatToTitleCase,
} from '../../../../../utils/utils';
import { CATEGORY_FACET_NAME } from '../../../../../_foundation/constants/category';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import { IProductListFacetsEntry } from '../../../../../_foundation/interface/ProductList/IProductList';
import { NteCheckbox } from '../../../../Forms/NteCheckbox/NteCheckbox';
import { seoSelector } from '../../../../Seo/redux/selector/seo';
import { ScreenReaderOnly } from '../../../ScreenReaderOnly/ScreenReaderOnly';
import { FacetConstants } from '../FacetConstants';

/**
 * @interface ICategoryFacet
 */
interface ICategoryFacet {
  currentFacetData: IProductListFacetsEntry;
  facetGroup: string;
}

/**
 * @component CategoryFacet renders the category facet for shop all pages.
 *
 * @param ICategoryFacet
 */
const CategoryFacet: React.FC<ICategoryFacet> = ({
  currentFacetData,
  facetGroup,
}) => {
  const { CATEGORY_QUERY_KEY, BRAND_QUERY_KEY } = FacetConstants;

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const [checked, setChecked] = useState<boolean>(false);

  const { categoryIdentifiers, subCategories } =
    useSelector(productListSelector);

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const seoConfig = useSelector(seoSelector);

  const [categoryParams] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const [, setBrandParam] = useQueryParam(BRAND_QUERY_KEY, StringParam);

  const { setCategoryFacetParam } = useCategoryFacetParam();

  const { currentPathName } = useCurrentPathName();

  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const facetLabel = currentFacetData.label;

  const facetCount = currentFacetData.count;

  const facetCheckboxLabel = `${facetLabel} ${
    facetCount !== '0' ? `(${facetCount})` : ''
  } `;

  const facetCheckboxHandler = async (e: any): Promise<void> => {
    if (mySite) {
      if (
        breadCrumbTrailEntryView.length !== 0 &&
        seoConfig &&
        seoConfig[formattedPath]
      ) {
        const hasBrands =
          breadCrumbTrailEntryView[0].seo.href.includes('brand');

        if (hasBrands && facetGroup === CATEGORY_FACET_NAME) {
          setBrandParam(
            seoConfig[formattedPath].tokenExternalValue,
            'replaceIn'
          );

          dispatch(
            CURRENT_BRAND_IDENTIFIER_ACTION(
              seoConfig[formattedPath].tokenExternalValue
            )
          );
        }
      }

      setChecked(e.target.checked);

      dispatch(RESET_PRODUCT_LIST_ACTION({ preserveSelectedFacet: true }));

      dispatch(RESET_BREADCRUMB_ACTION());

      let currentValue = '';

      if (categoryIdentifiers.length === 0 && subCategories.length === 0) {
        currentValue = e.target.value;
      } else {
        currentValue = subCategories?.filter(
          ({ id }) => id === e.target.value
        )[0]?.identifier;
      }

      setCategoryFacetParam({
        checked: e.target.checked,
        currentValue: currentValue,
      });
    }
  };

  const initCategoryFacetParam = useCallback((): void => {
    if (categoryParams && categoryIdentifiers.length === 0 && mySite) {
      const categoryIdentifiers: any =
        formatQueryParams('|').remove(categoryParams);

      if (categoryIdentifiers.length !== 0) {
        dispatch(RESET_BREADCRUMB_ACTION());

        dispatch(RESET_PRODUCT_LIST_ACTION({}));

        dispatch(ADD_CATEGORY_IDS_ACTION(categoryIdentifiers));
      }
    }
  }, [categoryParams, categoryIdentifiers, dispatch, mySite]);

  useEffect(() => {
    initCategoryFacetParam();
  }, [initCategoryFacetParam]);

  return (
    <div className='facet_checkbox'>
      <ScreenReaderOnly
        ariaLabelledBy={`${currentFacetData.label}-id`}
        message={`${facetLabel} items`}
      />

      <NteCheckbox
        labelPlacement='end'
        onChange={facetCheckboxHandler}
        checked={checked}
        id={`${currentFacetData.label}-id`}
        label={decode(facetCheckboxLabel)}
        value={currentFacetData.value}
      />
    </div>
  );
};

export { CategoryFacet };
