import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { FacetConstants } from '../../../components/Widgets/Facets/Facet/FacetConstants';
import { formatQueryParams } from '../../utils';

/**
 * @interface IBrandParamHook
 */
interface IBrandParamHook {
  seperator: string;
}

/**
 * @method useBrandParams responsible for extracting the brand names from the query params
 * and converts them into a string with a seperator passed in as an argument.
 */
const useBrandParams = (brandParams?: IBrandParamHook) => {
  const { FACET_QUERY_KEY, BRAND_FACET_KEY } = FacetConstants;

  const [brands, setBrands] = useState<string>('');

  const [facetQueryParams] = useQueryParam(FACET_QUERY_KEY, StringParam);

  /**
   * @callback initBrands Initializes the brands from the query params.
   */
  const initBrands = useCallback((): void => {
    if (facetQueryParams) {
      const facetGroups: any = formatQueryParams('+').remove(facetQueryParams);

      if (facetGroups && facetGroups?.length !== 0) {
        const brandFacet = facetGroups.filter((facet: string) =>
          facet.includes(BRAND_FACET_KEY)
        )[0];

        if (brandFacet) {
          const brandFacets: any = formatQueryParams(':').remove(brandFacet);

          if (brandFacets && brandFacets?.length !== 0) {
            const brands: any = formatQueryParams('|').remove(brandFacets[1]);

            const updatedBrands = brands.map((brand: string) =>
              brand.replaceAll('_', ' ')
            );

            if (brandParams?.seperator) {
              setBrands(updatedBrands.join(brandParams?.seperator) + ' ');
            } else {
              setBrands(updatedBrands.join(' - ') + ' ');
            }
          }
        } else {
          setBrands('');
        }
      }
    } else {
      setBrands('');
    }
  }, [BRAND_FACET_KEY, brandParams?.seperator, facetQueryParams]);

  useEffect(() => {
    initBrands();
  }, [initBrands]);

  return { brands };
};

export { useBrandParams };
