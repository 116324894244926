import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SHOPPING_CART } from '../../../constants/routes';
import { useSeoState } from '../../../utils/hooks/seo-state/seo-state';
import { IProductPrice } from '../../../utils/product-utility';
import { checkTruthy, formatPrice } from '../../../utils/utils';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { PlplayoutConstants } from '../../Layouts/Plp/PlpLayoutConstants';
import { EntryTypeCode } from '../../Widgets/Plp/ProductCard/ProductCardSection';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { ProductPricingConstants } from '../../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricing.constants';
import { PlpPricingMessage } from '../PlpPricingMessage/PlpPricingMessage';
import { QuantityPricing } from '../QuantityPricing/QuantityPricing';
import { SeeCartPrice } from '../SeeCartPrice/SeeCartPrice';

interface IProductNotOnSalePrice {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  setUnitPrice?: (unitPrice: number) => void;
  productPrice?: IProductPrice;
  showSavings: boolean;
  quantity: string;
  isWishlistCard?: boolean;
  requestedQuantity?: string;
}

const ProductNotOnSalePrice: React.FC<IProductNotOnSalePrice> = ({
  productCard,
  quantity,
  showSavings,
  productPrice,
  setUnitPrice,
  isWishlistCard,
  requestedQuantity,
}) => {
  const { QUANTITY_PRICING, REGULAR_PRICE, FROM, SALE_PRICE_CART } =
    ProductCardSectionMessagesConstants;

  const { GRID, PLP_VIEW } = PlplayoutConstants;

  const { EACH } = ProductPricingConstants;

  const { t } = useTranslation();

  const showSeeCartPriceMsg = checkTruthy(productCard.salesPriceRestricted);

  const history = useHistory();

  const { isPdpPage } = useSeoState();

  const showFrom =
    productCard?.catalogEntryTypeCode === EntryTypeCode.PRODUCTBEAN;

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const isGridView = sessionStorage.getItem(PLP_VIEW) === GRID;

  return (
    <>
      {!productPrice?.swatchable && (
        <div className={`price-cell ${showSeeCartPriceMsg ? 'block' : ''}`}>
          {isCartPage && !isPdpPage ? (
            <>
              <div className='nte-price24 price'>
                {productPrice?.formattedOfferPrice}
                {isWishlistCard &&
                  requestedQuantity &&
                  +requestedQuantity > 1 &&
                  EACH}
              </div>

              {Number(quantity) > 1 && showSavings && (
                <Typography variant='body2'>
                  {formatPrice(
                    productPrice?.productUnitPrice
                      ? productPrice.productUnitPrice.toString()
                      : ''
                  )}
                  {EACH}
                </Typography>
              )}
            </>
          ) : (
            <>
              {showFrom && (
                <p className='sub-product-offer-price psx'>{t(FROM)}</p>
              )}

              {showSeeCartPriceMsg && !isCartPage && (
                <>
                  {productPrice?.formattedDisplayPrice !==
                    productPrice?.formattedOfferPrice &&
                  productPrice?.isProductOnSale ? (
                    <>
                      <div className='nte-price24 price product-offer-price offer-price'>
                        {t(SALE_PRICE_CART)}
                      </div>

                      {showSavings &&
                        !productPrice?.cartQtyMessage &&
                        Boolean(productPrice?.minimumQuantity) &&
                        productPrice?.minimumQuantity === '0' && (
                          <p className='sub-product-offer-price pxs reg-save-pricing'>
                            {t(REGULAR_PRICE, {
                              formattedDisplayPrice:
                                productPrice?.formattedDisplayPrice,
                            })}
                          </p>
                        )}
                    </>
                  ) : (
                    <div className='nte-price24 price product-offer-price offer-price unit-price'>
                      {formatPrice(
                        productPrice?.unitPrice
                          ? productPrice.unitPrice.toString()
                          : ''
                      )}
                    </div>
                  )}
                </>
              )}

              {(!showSeeCartPriceMsg || isCartPage) && (
                <div className='nte-price24 price product-offer-price offer-price unit-price'>
                  {formatPrice(
                    productPrice?.unitPrice
                      ? productPrice.unitPrice.toString()
                      : ''
                  )}
                  {isWishlistCard &&
                    requestedQuantity &&
                    +requestedQuantity > 1 &&
                    EACH}
                </div>
              )}
            </>
          )}

          <PlpPricingMessage productPrice={productPrice} />

          {isGridView && !isWishlistCard && (
            <div className='empty_space'>&nbsp;</div>
          )}

          <QuantityPricing
            productPrice={productPrice}
            showSavings={showSavings}
          />

          {Number(productPrice?.minimumQuantity) > 0 && showSavings && (
            <p className='sub-product-offer-price pxs'>
              {t(QUANTITY_PRICING, {
                minimumQuantity: productPrice?.minimumQuantity,
                minimumOfferPrice: productPrice?.minimumOfferPrice,
              })}
            </p>
          )}
        </div>
      )}

      {showSeeCartPriceMsg && productPrice?.swatchable && (
        <SeeCartPrice productCard={productCard} productPrice={productPrice} />
      )}
    </>
  );
};

export { ProductNotOnSalePrice };
