import { createReducer, current } from '@reduxjs/toolkit';
import { SeoConstants } from '../../components/Seo/SeoConstants';
import { StoreMenu } from '../../components/Widgets/AppBar/PrimaryMenuItems/NavBarMenuItem/interface/StoreMenuInterface';
import {
  appendPLPPathToChildCategory,
  compareCategorySequence,
} from '../../utils/utils';
import {
  BRANDS_IDENTIFIER,
  CATEGORIES_IDENTIFIER,
} from '../../_foundation/constants/category';
import {
  ICategoryData,
  ICategoryState,
} from '../../_foundation/interface/Category/ICategoryCardData';
import {
  GET_ALL_CATEGORIES_SUCCESS_ACTION,
  GET_CURRENT_CATEGORY_ID_ACTION,
  RESET_CURRENT_CATEGORY_ID_ACTION,
  RESET_SEO_HREF_ACTION,
  SET_CATEGORIES_LOADING_ACTION,
  SET_CURRENT_CATEGORY_ID_ACTION,
} from '../actions/categories.actions';

const initCategories: ICategoryState = {
  categoriesData: [],
  loading: true,
  currentCategoryId: '',
  currentCategoryProductCount: '',
  currentCategoryData: [],
  categoryIndex: [],
  seoHref: '',
  currentTopCategoriesId: '',
  shopAllCategoryId: '',
  leafNodes: [],
};

/**
 * @interface ICurrentCategory
 */
interface ICurrentCategory {
  id: string;
  currentCategoryProductCount: string;
  currentCategoryData: ICategoryData[];
  seoHref: string;
}

let currentCategory: ICurrentCategory = {
  currentCategoryData: [],
  currentCategoryProductCount: '',
  id: '',
  seoHref: '',
};

/**
 * @method fetchCurrentCategory Fetches the current category id, count and children.
 *
 * @param childCategory child categories from which the id needs to be extracted.
 * @param categoryName categoryName for which the id needs to be extracted.
 * @returns current category id, product count and the current categories child data.
 */
const fetchCurrentCategory = (category: StoreMenu, categoryName: string) => {
  const seo = category.seo?.href?.split('/');
  let seoPath = '/';
  if (seo && Array.isArray(seo)) {
    seoPath = seoPath + seo[seo.length - 1];
  }
  if (seoPath === categoryName.toLocaleLowerCase()) {
    currentCategory = {
      currentCategoryData: category?.children as ICategoryData[],
      currentCategoryProductCount: category?.UserData
        ? Number(category?.UserData[0]?.productCount).toFixed()
        : category?.product_count
        ? Number(category?.product_count).toFixed()
        : '',
      id: category?.id ? category.id : '',
      seoHref: category?.seo?.href ? category.seo.href : '',
    };
  }

  if (category?.children && category?.children.length !== 0) {
    category?.children?.forEach((categoryChild) => {
      fetchCurrentCategory(categoryChild, categoryName);
    });
  }
};

const leafNodes: string[] = [];

/**
 * @method extractLeafNodes extracts all the leaf nodes from the categories list.
 */
const extractLeafNodes = (category: StoreMenu) => {
  if (category?.children && category?.children.length !== 0) {
    category?.children?.forEach((categoryChild) => {
      extractLeafNodes(categoryChild);
    });
  } else {
    if (
      category.id &&
      category &&
      (category?.children?.length === 0 || !category?.children)
    ) {
      leafNodes.push(category.id);
    }
  }
};

const categoriesReducer = createReducer(initCategories, (builder) => {
  /**
   * @action GET_ALL_CATEGORIES_SUCCESS_ACTION
   * Fetches all the categories from the worker and then caches it in the redux.
   */
  builder.addCase(
    GET_ALL_CATEGORIES_SUCCESS_ACTION,
    (state: ICategoryState, action: any) => {
      const { contents } = action.payload;

      let categoriesData = contents as ICategoryData[];

      const shopByCategory = categoriesData.filter(
        ({ identifier }) => identifier.toLowerCase() === CATEGORIES_IDENTIFIER
      )[0];

      const { BRAND_PATH, CATEGORY_PATH } = SeoConstants;

      let shopByCategoryData: ICategoryData[] = categoriesData.filter(
        ({ identifier }) => identifier.toLowerCase() === CATEGORIES_IDENTIFIER
      );

      if (shopByCategoryData && shopByCategoryData[0]?.children) {
        shopByCategoryData[0].children = appendPLPPathToChildCategory(
          shopByCategoryData[0].children,
          CATEGORY_PATH
        );
      }

      let shopByBrandData: ICategoryData[] = categoriesData.filter(
        ({ identifier }) => identifier.toLowerCase() === BRANDS_IDENTIFIER
      );

      if (shopByBrandData && shopByBrandData[0]?.children) {
        shopByBrandData[0].children = appendPLPPathToChildCategory(
          shopByBrandData[0].children,
          BRAND_PATH
        );
      }

      const otherCategoryData: ICategoryData[] = categoriesData.filter(
        ({ identifier }) =>
          identifier.toLowerCase() !== BRANDS_IDENTIFIER &&
          identifier.toLowerCase() !== CATEGORIES_IDENTIFIER
      );

      categoriesData = [
        ...shopByCategoryData,
        ...shopByBrandData,
        ...otherCategoryData,
      ];

      categoriesData.sort(compareCategorySequence);

      if (shopByCategory.children) {
        shopByCategory.children.forEach((category) => {
          if (category && category?.children) {
            category?.children.forEach((categoryChild) => {
              extractLeafNodes(categoryChild);
            });
          }
        });
      }

      if (contents) {
        return {
          categoriesData,
          loading: false,
          shopAllCategoryId: shopByCategory.id,
          leafNodes,
        };
      }
    }
  );

  /**
   * @action GET_CURRENT_CATEGORY_ID_ACTION
   * Gets the current category id, count and child categories for the respective category name.
   */
  builder.addCase(
    GET_CURRENT_CATEGORY_ID_ACTION,
    (state: ICategoryState, action: any) => {
      if (action.payload.currentCategory && state.categoriesData) {
        const categoryName = action.payload.currentCategory;

        const { categoriesData } = current(state);

        currentCategory = {
          currentCategoryData: [],
          currentCategoryProductCount: '',
          id: '',
          seoHref: '',
        };

        /**
         * Loop through the child categories to get the
         * category id based on the current category name.
         */
        categoriesData.forEach((category) => {
          const seo = category.seo?.href?.split('/');
          let seoPath = '/';
          if (seo && Array.isArray(seo)) {
            seoPath = seoPath + seo[seo.length - 1];
          }
          if (seoPath === categoryName) {
            currentCategory = {
              currentCategoryData: category?.children as ICategoryData[],
              currentCategoryProductCount: category?.UserData
                ? Number(category?.UserData[0]?.productCount).toFixed()
                : category?.product_count
                ? Number(category?.product_count).toFixed()
                : '',
              id: category.id,
              seoHref: category?.seo?.href ? category?.seo?.href : '',
            };
          } else {
            if (category?.children) {
              category?.children.forEach((categoryChild) => {
                fetchCurrentCategory(categoryChild, categoryName);
              });
            }
          }
        });

        const {
          currentCategoryData,
          currentCategoryProductCount,
          id,
          seoHref,
        } = currentCategory;

        return {
          ...state,
          currentCategoryId: id,
          currentCategoryProductCount,
          currentCategoryData,
          seoHref,
        };
      }

      if (action.payload.currentCategoryIdentifier && state.categoriesData) {
        const { categoriesData } = current(state);

        let categoryIndex: ICategoryData[] = [];

        const currentCategory = categoriesData.find(
          (category) =>
            category.identifier === action.payload.currentCategoryIdentifier
        );

        if (currentCategory?.children) {
          categoryIndex = [...currentCategory?.children] as ICategoryData[];
        }

        return {
          ...state,
          currentCategoryId: currentCategory?.id,
          categoryIndex,
        };
      }
    }
  );

  /**
   * @action SET_CURRENT_CATEGORY_ID_ACTION
   * Sets the current category id of the current category
   */
  builder.addCase(
    SET_CURRENT_CATEGORY_ID_ACTION,
    (state: ICategoryState, action: any) => {
      return {
        ...state,
        currentCategoryId: action.payload.categoryId,
      };
    }
  );

  builder.addCase(
    RESET_CURRENT_CATEGORY_ID_ACTION,
    (state: ICategoryState, action: any) => {
      return { ...state, currentCategoryId: '' };
    }
  );

  builder.addCase(RESET_SEO_HREF_ACTION, (state: ICategoryState) => {
    return { ...state, seoHref: '' };
  });

  builder.addCase(
    SET_CATEGORIES_LOADING_ACTION,
    (state: ICategoryState, action: any) => {
      return { ...state, loading: action.payload };
    }
  );
});

export { categoriesReducer };
