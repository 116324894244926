import { createReducer } from '@reduxjs/toolkit';
import { decode } from 'html-entities';
import {
  IBreadcrumbState,
  IFetchBreadcrumbProductDetails,
} from '../../_foundation/interface/Breadcrumb/IBreadcrumb';
import IBreadcrumbsResponse from '../../_foundation/interface/Responses/IBreadcrumbsResponse';
import {
  FETCH_BREADCRUMB_SUCCESS_ACTION,
  FETCH_PRODUCT_BREADCRUMB_DETAILS_ACTION,
  RESET_BREADCRUMB_ACTION,
  SET_BREADCRUMB_ERROR_RESPONSE_ACTION,
} from '../actions/breadcrumb.actions';

const initBreadcrumbState: IBreadcrumbState = {
  breadCrumbTrailEntryView: [],
  loading: true,
  name: '',
  parentCatalogGroupID: '',
  breadcrumbErrorResponse: false,
};

const breadcrumbReducer = createReducer(initBreadcrumbState, (builder) => {
  builder.addCase(
    FETCH_BREADCRUMB_SUCCESS_ACTION,
    (state: IBreadcrumbState, action: any) => {
      const { breadCrumbTrailEntryView } =
        action.payload as IBreadcrumbsResponse;

      return {
        ...state,
        breadCrumbTrailEntryView,
        loading: false,
        breadcrumbErrorResponse: false,
      };
    }
  );

  builder.addCase(
    FETCH_PRODUCT_BREADCRUMB_DETAILS_ACTION,
    (state: IBreadcrumbState, action: any) => {
      const { name, parentCatalogGroupID } =
        action.payload as IFetchBreadcrumbProductDetails;

      if (parentCatalogGroupID) {
        return {
          ...state,
          name: decode(name),
          parentCatalogGroupID,
          loading: true,
          breadcrumbErrorResponse: false,
        };
      }
    }
  );

  builder.addCase(
    SET_BREADCRUMB_ERROR_RESPONSE_ACTION,
    (state: IBreadcrumbState, action: any) => {
      return { ...state, breadcrumbErrorResponse: action.payload };
    }
  );

  builder.addCase(RESET_BREADCRUMB_ACTION, () => {
    return { ...initBreadcrumbState };
  });
});

export { breadcrumbReducer };
