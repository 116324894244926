import { createReducer, current } from '@reduxjs/toolkit';
import { decode } from 'html-entities';
import { SeoConstants } from '../../components/Seo/SeoConstants';
import { FacetConstants } from '../../components/Widgets/Facets/Facet/FacetConstants';
import {
  PLP_COMPARE_SELECT_TEALIUM,
  PLP_FILTER_FINDING_METHOD_TEALIUM,
  PLP_FILTER_TEALIUM,
} from '../../constants/Tealium';
import {
  checkTruthy,
  formatFacetPrice,
  sendTealiumData,
} from '../../utils/utils';
import {
  BRAND_FACET_NAME,
  CATEGORY_FACET_NAME,
  PRICE_FACET_NAME,
} from '../../_foundation/constants/category';
import { ADVANTAGE_AUTH } from '../../_foundation/enum/User/UserType';
import {
  IPrivateBrandsResponse,
  IProductListContents,
  IProductListFacets,
  IProductListFacetsEntry,
  IProductListSelectedFacet,
  IProductListState,
  ISearchDisplayState,
  ISelectedFacetGroups,
  ISubCategories,
} from '../../_foundation/interface/ProductList/IProductList';
import IShippingInfoResponse from '../../_foundation/interface/Responses/IShippingInfoResponse';
import {
  ADD_CATEGORY_IDS_ACTION,
  ADD_PRODUCTS_TO_COMPARE_ACTION,
  CLEAR_ALL_FACET_ACTION,
  CLEAR_PRICE_RANGE_FACET_ACTION,
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_ID_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FACET_LOADING_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
  FETCH_INVENTORY_INFO_SUCCESS_ACTION,
  FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
  FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
  FILTER_BY_PRICE_RANGE_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION,
  NO_SEARCH_RESULTS_FOUND_ACTION,
  REDIRECT_TO_PDP_ACTION,
  REMOVE_PRODUCT_FROM_COMPARE_ACTION,
  RESET_PRODUCT_LIST_ACTION,
  RESET_SEARCH_DISPLAY_ACTION,
  RESET_TOP_BRANDS_ACTION,
  SEARCH_DISPLAY_SUCCESS_ACTION,
  SEARCH_PRODUCT_LIST_SUCCESS_ACTION,
  SEARCH_PRODUCT_REDIRECTION_ACTION,
  SET_FACET_ACTION,
  SET_FACET_FROM_PARAMS_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
  SET_PRODUCT_LIST_LOADER_ACTION,
  SET_PROMO_PRODUCT_ID_ACTION,
  SHOW_PLP_ERROR_ACTION,
  SHOW_PLP_META_TAGS_ACTION,
  TOP_BRANDS_SUCCESS_ACTION,
  UPDATE_FACETS_SUCCESS_ACTION,
} from '../actions/productList.actions';

const initProductListState: IProductListState = {
  facets: [],
  productList: [],
  selectedFacets: [],
  priceFacet: {
    entry: [],
    name: '',
    value: '',
  },
  currentPageProductCount: 0,
  totalProducts: 0,
  productComparison: {
    compareProducts: [],
    enableCompare: true,
    showProductComparisonBar: false,
  },
  loading: true,
  breadCrumbTrailEntryView: [],
  privateBrands: [],
  facetLoading: false,
  noProductsFound: false,
  maxPrice: '',
  minPrice: '',
  showMetaTags: false,
  plpError: false,
  selectedGroups: {},
  pageLoading: false,
  redirectUrlPdp: '',
  categoryFacetValues: [],
  isSearchPlp: false,
  searchTermRedirectUrl: '',
  shippingInfo: {
    products: [],
  },
  partNumbers: [],
  lastPartNumber: '',
  topBrands: [],
  categoryIdentifiers: [],
  currentBrandId: '',
  currentBrandIdentifier: '',
  currentBrandName: '',
  currentBrandSeo: '',
  subCategories: [],
  subCategory: {
    id: '',
    identifier: '',
    seo: '',
    label: '',
  },
  subCategoriesForCarousel: [],
  promoProductIds: [],
};

const initSearchDisplayState: ISearchDisplayState = {
  redirecturl: '',
  viewTaskName: '',
  storeId: '',
  searchTerm: '',
  pdpRedirectUrl: '',
  noSearchResultsFound: false,
};

const { PARENT_CATALOG_GROUP } = FacetConstants;

const { PRODUCT_PATH } = SeoConstants;

/**
 * @method sortFacets Sorts the facets in the given sequential order.
 */
const sortFacets = (
  userType: string,
  facets?: IProductListFacets[]
): IProductListFacets[] => {
  if (facets) {
    const facetsMap = ['sold-at-store', 'path', 'manufacturer.raw'];

    const postKeySpec = ['New Arrival'];

    /**
     * Responsible to filter Price Facet
     */
    const priceFacet = facets.filter((facet) => facet.value === 'price_usd');

    /**
     * Responsible to filter Rating facet
     */
    const ratingFacet = facets.filter(
      (facet) => facet.extendedData?.propertyvalue === 'Rating'
    );

    /**
     * Responsible to filter Promotion Facet
     */
    const promotionFacet = facets.filter(
      (facet) => facet.extendedData?.propertyvalue === 'Promotion'
    );

    /**
     * Responsible to filter only the key spec values and upto first 6 values
     */
    const keySpecFacet = facets
      .filter((facet) => {
        if (facet?.extendedData) {
          if (
            !facetsMap.includes(facet?.extendedData?.propertyvalue) &&
            !postKeySpec.includes(facet?.extendedData?.propertyvalue) &&
            facet?.extendedData?.propertyvalue !== 'Rating' &&
            facet?.extendedData?.propertyvalue !== 'Promotion'
          ) {
            return facet;
          }
        }
        return '';
      })
      .slice(0, 6);

    /**
     * Below logic is only reponsible to filter the facet that is above the key spec
     *
     */
    const nonKeySpecFacetPre = facets.filter((facet) => {
      if (facet?.extendedData) {
        if (facetsMap.includes(facet?.extendedData?.propertyvalue)) {
          return facet;
        }
      }
      return '';
    });

    /**
     * Below logic is only responsible to filer the facet is below the key spec
     */
    const nonKeySpecFacetPost = facets.filter((facet) => {
      if (facet?.extendedData) {
        if (postKeySpec.includes(facet?.extendedData?.propertyvalue)) {
          return facet;
        }
      }
      return '';
    });

    /**
     * Below sorter will only apply for the facet that is above the key spec
     */
    const facetsOrdered = [...facetsMap].reverse();

    const facetsSorter = (a: IProductListFacets, b: IProductListFacets) => {
      return facetsOrdered.indexOf(a.name) - facetsOrdered.indexOf(b.name);
    };

    const facetPart1 = nonKeySpecFacetPre.sort(facetsSorter).reverse();

    const facetBeforeKeySpec = [...facetPart1, ...priceFacet, ...ratingFacet];

    /**
     *  Below sorter will only apply for the facet that is below the key spec
     */
    const postKeySpecOrdered = [...postKeySpec].reverse();

    const facetspostkeySorter = (
      a: IProductListFacets,
      b: IProductListFacets
    ) => {
      return (
        postKeySpecOrdered.indexOf(a.name) - postKeySpecOrdered.indexOf(b.name)
      );
    };

    const facetPart3 = nonKeySpecFacetPost.sort(facetspostkeySorter).reverse();

    /**
     * combining all three into single array
     * facetBeforeKeySpec --> facet before key spec
     * keySpecFacet --> key spec value upto 6
     * facetPart3 --> facet after key spec
     * Incase of an advantage auth add promotion at the top of facets list.
     */

    let facetList;

    if (userType === ADVANTAGE_AUTH) {
      facetList = [
        ...promotionFacet,
        ...facetBeforeKeySpec,
        ...keySpecFacet,
        ...facetPart3,
      ];
    } else {
      facetList = [
        ...facetBeforeKeySpec,
        ...keySpecFacet,
        ...promotionFacet,
        ...facetPart3,
      ];
    }

    return [...facetList];
  }

  return [];
};

const productListPageReducer = createReducer(
  initProductListState,
  (builder) => {
    /**
     * CASE GET_PRODUCT_LIST_SUCCESS_ACTION
     * Retrives all the product list data.
     */
    builder.addCase(
      GET_PRODUCT_LIST_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        if (action.payload) {
          const {
            facetView,
            catalogEntryView,
            breadCrumbTrailEntryView,
            recordSetCount,
            recordSetTotal,
            userType,
            isBrandPlp,
            searchPlp,
            pageNumber,
          } = action.payload;

          const { productList: currentProductList } = current(state);

          if (recordSetCount === 0) {
            return {
              ...state,
              loading: false,
              plpError: false,
              productList: [],
              totalProducts: recordSetTotal,
              pageLoading: false,
              isSearchPlp: searchPlp,
            };
          }

          let searchTermPdpUrl = '';

          const partNumbers = catalogEntryView?.map(
            ({ partNumber }: IProductListContents) => partNumber
          );

          /**
           * Responsible to store the url if search term is present in the response payload(partNumber)
           */
          if (action.payload.searchTerm) {
            const searchTermProduct = catalogEntryView?.find(
              ({ partNumber }: IProductListContents) =>
                partNumber.toLowerCase() ===
                action.payload.searchTerm.toLowerCase()
            );

            if (searchTermProduct && searchTermProduct.partNumber) {
              searchTermPdpUrl = PRODUCT_PATH + searchTermProduct.seo.href;
            } else {
              searchTermPdpUrl = '';
            }
          }

          /**
           * Responsible to filter the facets with only displayable is true
           */
          const filteredFacet = facetView
            .filter(
              (facets: IProductListFacets) => facets.name !== PRICE_FACET_NAME
            )
            .filter((facet: IProductListFacets) => {
              return facet?.extendedData?.displayable === 'true';
            });

          /**
           * We don't have displayable property for price facet
           */
          const offerPriceFacet = facetView.filter(
            ({ name }: IProductListFacets) => name === PRICE_FACET_NAME
          );

          const displayableFacet = [...filteredFacet, ...offerPriceFacet];

          const facets = sortFacets(userType, displayableFacet);

          const {
            productComparison,
            productList,
            categoryFacetValues: currentCategoryFacetValues,
          } = current(state);

          let priceFacet;

          let categoryFacetValues: string[] = [];

          if (currentCategoryFacetValues) {
            categoryFacetValues = [...categoryFacetValues];
          }

          if (facetView) {
            priceFacet = facetView.filter(
              ({ name }: IProductListFacets) => name === PRICE_FACET_NAME
            )[0];

            priceFacet = priceFacet
              ? priceFacet
              : initProductListState.priceFacet;
          }

          let updatedProductList: Array<IProductListContents[]> = [];

          /**
           * Responsible to filter the items that are buyable
           */
          let availableProducts =
            catalogEntryView &&
            !searchPlp &&
            catalogEntryView.filter((prod: IProductListContents) =>
              checkTruthy(prod.buyable)
            );

          if (searchPlp) {
            const buyableProducts = catalogEntryView.filter(
              (prod: IProductListContents) => checkTruthy(prod.buyable)
            );
            const nonBuyableProducts = catalogEntryView.filter(
              (prod: IProductListContents) => !checkTruthy(prod.buyable)
            );

            availableProducts = [...buyableProducts, ...nonBuyableProducts];

            /**
             * Append the /products path to all products
             * for the search PLP.
             * For the other PLP pages this is handled in
             * Service level.
             */
            availableProducts.forEach((product: IProductListContents) => {
              if (product?.seo?.href) {
                product.seo.href = PRODUCT_PATH + product.seo.href;
              }
            });
          }

          if (
            pageNumber === currentProductList.length &&
            currentProductList.length !== 0
          ) {
            updatedProductList = [catalogEntryView];
          } else if (
            action.payload.searchTerm &&
            searchPlp &&
            !catalogEntryView
          ) {
            updatedProductList = [];
          } else if (
            (productList.length !== 0 || catalogEntryView) &&
            availableProducts.length > 0
          ) {
            updatedProductList = [...productList, availableProducts];
          } else {
            updatedProductList = [...productList, catalogEntryView];
          }

          if (isBrandPlp) {
            const brandFacetIndex = facets.findIndex(
              ({ name }) => name === BRAND_FACET_NAME
            );

            facets.splice(brandFacetIndex, 1);

            categoryFacetValues = facets
              ?.filter(({ name }) => name === CATEGORY_FACET_NAME)[0]
              ?.entry?.map(({ value }) => value);
          }

          let lastPartNumber: string = '';

          if (updatedProductList.length > 1) {
            const lastPageContents: IProductListContents[] =
              updatedProductList[updatedProductList.length - 1];

            const firstProduct = lastPageContents[0];

            lastPartNumber = firstProduct.partNumber;
          }

          return {
            ...state,
            facets,
            priceFacet,
            facetLoading: false,
            breadCrumbTrailEntryView,
            loading: false,
            plpError: false,
            productList: updatedProductList,
            currentPageProductCount: recordSetCount,
            totalProducts: recordSetTotal,
            pageLoading: false,
            productComparison,
            isSearchPlp: searchPlp,
            searchTermRedirectUrl: searchTermPdpUrl,
            partNumbers,
            lastPartNumber,
            ...{ ...(isBrandPlp && { categoryFacetValues }) },
          };
        }
      }
    );

    /**
     * CASE SEARCH_PRODUCT_REDIRECTION_ACTION
     * updates state with the redirect url when recordset is 1
     */
    builder.addCase(
      SEARCH_PRODUCT_REDIRECTION_ACTION,
      (state: IProductListState, action: any) => {
        if (action.payload as string) {
          const redirectUrlPdp = action.payload;

          return {
            ...state,
            redirectUrlPdp,
          };
        }
      }
    );

    /**
     * CASE SEARCH_PRODUCT_LIST_SUCCESS_ACTION
     * Updates state with the products returned from Search api based on keyword.
     */
    builder.addCase(
      SEARCH_PRODUCT_LIST_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        if (action.payload) {
          const {
            facetView,
            catalogEntryView,
            recordSetCount,
            recordSetTotal,
            userType,
          } = action.payload;

          const facets = sortFacets(userType, facetView);

          if (catalogEntryView && catalogEntryView.length > 0) {
            return {
              ...state,
              facets,
              loading: false,
              productList: [catalogEntryView],
              currentPageProductCount: recordSetCount,
              totalProducts: recordSetTotal,
              noProductsFound: false,
            };
          } else {
            return {
              ...state,
              loading: false,
              noProductsFound: true,
            };
          }
        }
      }
    );

    /**
     * CASE SET_PRODUCT_LIST_LOADER_ACTION
     * Sets the state for the product list loading.
     */
    builder.addCase(
      SET_PRODUCT_LIST_LOADER_ACTION,
      (state: IProductListState, action: any) => {
        const { loading } = action.payload;

        return { ...state, loading };
      }
    );

    /**
     * CASE RESET_PRODUCT_LIST_ACTION
     * Resets back to the initial productList state.
     */
    builder.addCase(
      RESET_PRODUCT_LIST_ACTION,
      (state: IProductListState, action: any) => {
        const { preserveSelectedFacet, clearAll, preserveSubCategories } =
          action.payload;

        if (clearAll) {
          return { ...initProductListState };
        }

        const {
          privateBrands,
          productComparison,
          currentBrandId,
          currentBrandIdentifier,
          currentBrandName,
          currentBrandSeo,
          categoryIdentifiers,
          selectedFacets,
          selectedGroups,
          subCategoriesForCarousel,
          subCategory,
          subCategories,
          minPrice,
          maxPrice,
        } = current(state);

        return {
          ...initProductListState,
          privateBrands,
          productComparison,
          currentBrandId,
          currentBrandIdentifier,
          currentBrandName,
          currentBrandSeo,
          categoryIdentifiers,
          minPrice,
          maxPrice,
          ...{
            ...(preserveSelectedFacet && { selectedFacets, selectedGroups }),
          },
          ...{
            ...(preserveSubCategories && {
              subCategoriesForCarousel,
              subCategory,
              subCategories,
            }),
          },
        };
      }
    );

    builder.addCase(
      SET_FACET_ACTION,
      (state: IProductListState, action: any) => {
        const {
          // Currently selected facet object.
          facetData,
          // Whether the checkbox is checked or unchecked.
          isChecked,
          // Whether the selected facet is a price facet.
          isPriceFacet = false,
          // Currently selected facet's group.
          facetGroup,
        } = action.payload as IProductListSelectedFacet;

        const { selectedGroups } = current(state);

        if (facetData && facetGroup) {
          let currentSelectedFacetGroups = { ...selectedGroups };

          if (isChecked) {
            // Check if the currently selected facet is already available.
            const isFacetAlreadyAvailable = Object.keys(
              currentSelectedFacetGroups
            ).filter((value) => value === facetGroup)[0];

            if (isFacetAlreadyAvailable) {
              // If the currently selected facet is already available, make a new copy from existing state.
              const currentFacetGroupData =
                currentSelectedFacetGroups[facetGroup];
              let updatedFacetData: IProductListFacetsEntry = {
                ...facetData,
              };

              if (isPriceFacet) {
                const priceLabel = formatFacetPrice(facetData.label);

                updatedFacetData = {
                  ...facetData,
                  label: priceLabel,
                };
              }

              // Add the newly selected facet.
              const updatedSelectedFacet: ISelectedFacetGroups = {
                [facetGroup]: [...[updatedFacetData], ...currentFacetGroupData],
              };

              // Update the selected groups.
              currentSelectedFacetGroups = {
                ...selectedGroups,
                ...updatedSelectedFacet,
              };
            } else {
              // If the facet is not available create a facet data object with the currently selected facet.
              let updatedFacetData: IProductListFacetsEntry = {
                ...facetData,
              };

              if (isPriceFacet) {
                const priceLabel = formatFacetPrice(facetData.label);

                updatedFacetData = {
                  ...facetData,
                  label: priceLabel,
                };
              }

              const selectedFacet: ISelectedFacetGroups = {
                [facetGroup]: [...[updatedFacetData]],
              };

              currentSelectedFacetGroups = {
                ...selectedGroups,
                ...selectedFacet,
              };
            }
          }

          if (!isChecked) {
            // Check if the currently selected facet is available or not.
            const isAlreadyAvailable = Object.keys(
              currentSelectedFacetGroups
            ).filter((value) => value === facetGroup)[0];

            if (isAlreadyAvailable || isPriceFacet) {
              // If facet is already available remove that specific facet from it's group.
              const currentFacet = [...currentSelectedFacetGroups[facetGroup]];

              const removeIndex = currentFacet.findIndex(
                ({ label }) => label === facetData.label
              );

              currentFacet.splice(removeIndex, 1);

              if (currentFacet.length !== 0) {
                currentSelectedFacetGroups = {
                  ...currentSelectedFacetGroups,
                  [facetGroup]: currentFacet,
                };
              } else {
                // Remove the entire facet group
                delete currentSelectedFacetGroups[facetGroup];
              }
            }
          }

          const facetValues = Object.values(currentSelectedFacetGroups);

          let selectedFacets: IProductListFacetsEntry[] = [];

          facetValues.forEach((facet) => {
            selectedFacets = [...selectedFacets, ...facet];
          });

          /**
           * This block handles the tealium events for the facet selection.
           */
          const plpFilterNames: string[] = [];

          let plpFilterValues: string[] = [];

          const plpFilterGroups = Object.values(currentSelectedFacetGroups);

          const plpFilterGroupKeys = Object.keys(currentSelectedFacetGroups);

          plpFilterGroups.forEach((plpFilterGroup, index) => {
            const currentPlpFilterValue = plpFilterGroup.map(({ label }) => {
              plpFilterNames.push(plpFilterGroupKeys[index]);

              return decode(label.toLowerCase());
            });

            plpFilterValues = [...plpFilterValues, ...currentPlpFilterValue];
          });

          sendTealiumData({
            tealium_event: PLP_FILTER_TEALIUM,
            plp_filter_names: plpFilterNames.map((name) =>
              decode(name.toLowerCase())
            ),
            plp_filter_values: plpFilterValues,
            product_finding_method: PLP_FILTER_FINDING_METHOD_TEALIUM,
          });

          return {
            ...state,
            selectedGroups: currentSelectedFacetGroups,
            selectedFacets,
            selectedGroupNames: plpFilterNames,
          };
        }
      }
    );

    builder.addCase(
      SET_FACET_FROM_PARAMS_ACTION,
      (state: IProductListState, action: any) => {
        const selectedGroups = action.payload as ISelectedFacetGroups;

        let currentGroups = { ...selectedGroups };

        const facetValues = Object.values(selectedGroups);

        let selectedFacets: IProductListFacetsEntry[] = [];

        let prices: string[] = [];

        if (currentGroups[PARENT_CATALOG_GROUP]) {
          currentGroups[PARENT_CATALOG_GROUP].forEach(({ value }, index) => {
            const categoryGrpQueryString = 'path.tree%3A'.concat(value);

            currentGroups[PARENT_CATALOG_GROUP][index] = {
              ...currentGroups[PARENT_CATALOG_GROUP][index],
              value: categoryGrpQueryString,
            };
          });
        }

        facetValues.forEach((facet) => {
          if (facet[0]?.isPriceFacet) {
            prices = facet[0].label.split('-');
          } else {
            selectedFacets = [...selectedFacets, ...facet];
          }
        });

        return {
          ...state,
          selectedFacets,
          selectedGroups: currentGroups,
          minPrice: prices[0],
          maxPrice: prices[1],
        };
      }
    );

    /**
     * CASE CLEAR_ALL_FACET_ACTION
     * Clears all the facets for the selectedFacets list.
     */
    builder.addCase(CLEAR_ALL_FACET_ACTION, (state: IProductListState) => {
      return {
        ...state,
        selectedFacets: [],
        minPrice: '',
        maxPrice: '',
        selectedFacetsStack: [],
        selectedGroups: {},
      };
    });

    /**
     * CASE ADD_PRODUCTS_TO_COMPARE_ACTION
     * Add the current product to the comparison list or remove the current product
     * from the comparison list based on the flag isChecked.
     */
    builder.addCase(
      ADD_PRODUCTS_TO_COMPARE_ACTION,
      (state: IProductListState, action: any) => {
        const { id, isChecked, wishListProducts } = action.payload;

        const currentState = current(state);

        let productList = [...currentState.productList];

        if (wishListProducts && wishListProducts !== 0) {
          productList = [...wishListProducts];
        }

        const selectedProducts: IProductListContents[] = [
          ...currentState.productComparison.compareProducts,
        ];

        /**
         * Adds the current product to the comparison list
         * when the isChecked flag is true.
         */
        if (isChecked) {
          let selectedProduct: IProductListContents[] = [];

          productList.forEach((product) => {
            if (selectedProduct.length !== 0) {
              return;
            }

            selectedProduct = product.filter(
              (product) => product.partNumber === id
            );
          });

          selectedProducts.push(selectedProduct[0]);

          /**
           * This block handles the tealium events for product comparison checkbox.
           */
          const currentProducts = selectedProducts.map(
            ({ partNumber }) => partNumber
          );

          const currentProductNames = selectedProducts.map(({ name }) =>
            name.toLowerCase()
          );

          sendTealiumData({
            tealium_event: PLP_COMPARE_SELECT_TEALIUM,
            product_id: currentProducts,
            product_name: currentProductNames,
          });
        } else {
          /**
           * Removes the current product to the comparison list
           * when the isChecked flag is false.
           */
          const currentValue = selectedProducts.findIndex(
            (product: IProductListContents) => product.partNumber === id
          );

          if (currentValue !== -1) {
            selectedProducts.splice(currentValue, 1);
          }
        }

        return {
          ...state,
          productComparison: {
            ...state.productComparison,
            compareProducts: selectedProducts,
            // When the selectedProducts list is equal to or greated than 4 disable compare checkbox.
            enableCompare: selectedProducts.length !== 4,
            // When the selectedProducts list is empty hide the product comparison bar.
            showProductComparisonBar: selectedProducts.length > 0,
          },
        };
      }
    );

    /**
     * CASE REMOVE_PRODUCT_FROM_COMPARE_ACTION
     * Removes the current product from the comparison list if the id is provided,
     * else clears the entire comparison list.
     */
    builder.addCase(
      REMOVE_PRODUCT_FROM_COMPARE_ACTION,
      (state: IProductListState, action: any) => {
        const id = action.payload.id;

        if (id) {
          const currentState = current(state);

          const productList = [
            ...currentState.productComparison.compareProducts,
          ];

          const currentIndex = productList.findIndex(
            (product: IProductListContents) => product.partNumber === id
          );

          productList.splice(currentIndex, 1);

          const currentProducts = productList.map(
            ({ partNumber }) => partNumber
          );

          const currentProductNames = productList.map(({ name }) =>
            name.toLowerCase()
          );

          sendTealiumData({
            tealium_event: PLP_COMPARE_SELECT_TEALIUM,
            product_id: currentProducts,
            product_name: currentProductNames,
          });

          return {
            ...state,
            productComparison: {
              compareProducts: productList,
              enableCompare: productList.length !== 4,
              showProductComparisonBar: productList.length > 0,
            },
          };
        } else {
          return {
            ...state,
            productComparison: initProductListState.productComparison,
          };
        }
      }
    );

    /**
     * CASE FETCH_PRIVATE_BRANDS_SUCCESS_ACTION
     * Fetches the private brands response from worker and stores them in redux state.
     */
    builder.addCase(
      FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        if (action.payload) {
          const { private_labels } = action.payload as IPrivateBrandsResponse;

          return { ...state, privateBrands: private_labels };
        }
      }
    );

    /**
     * CASE FACET_LOADING_ACTION
     * Sets facet loading state when a facet action gets triggered.
     */
    builder.addCase(
      FACET_LOADING_ACTION,
      (state: IProductListState, action: any) => {
        return {
          ...state,
          facetLoading: action.payload,
          facets: [],
          currentPageProductCount: 0,
          totalProducts: 0,
        };
      }
    );

    /**
     * CASE UPDATE_FACETS_SUCCESS_ACTION
     * Updates the product list array based on the new values fetched after a succesful filter.
     */
    builder.addCase(
      UPDATE_FACETS_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        if (action.payload) {
          const {
            catalogEntryView,
            recordSetCount,
            recordSetTotal,
            facetView,
          } = action.payload.response;
          const { userType, pageNumber, searchPlp } = action.payload;

          const { productComparison } = current(state);

          /**
           * Responsible to filter the facets with only displayable is true
           */
          const filteredFacet = facetView
            .filter(
              (facets: IProductListFacets) => facets.name !== PRICE_FACET_NAME
            )
            .filter((facet: IProductListFacets) => {
              return facet?.extendedData?.displayable === 'true';
            });

          /**
           * We don't have displayable property for price facet
           */
          const offerPriceFacet = facetView.filter(
            ({ name }: IProductListFacets) => name === PRICE_FACET_NAME
          );

          const displayableFacet = [...filteredFacet, ...offerPriceFacet];

          const facets = sortFacets(userType, displayableFacet);

          let productListContents: Array<IProductListContents[]> = [];

          const { productList } = current(state);

          /**
           * Responsible to update the price facet
           */

          let priceFacet = initProductListState.priceFacet;

          if (facetView) {
            const priceFacetValues = facetView?.find(
              ({ name }: IProductListFacets) => name === PRICE_FACET_NAME
            );

            if (priceFacetValues) {
              priceFacet = priceFacetValues;
            }
          }

          /**
           * Responsible to filter the items that are buyable
           */
          const availableProducts =
            catalogEntryView &&
            !searchPlp &&
            catalogEntryView.filter((prod: IProductListContents) =>
              checkTruthy(prod.buyable)
            );

          const finalProductList = searchPlp
            ? catalogEntryView
            : availableProducts;

          if (productList.length !== 0 || catalogEntryView) {
            productListContents = [finalProductList];
          }

          if (pageNumber !== 1) {
            productListContents = [...productList, finalProductList];
          }

          return {
            ...state,
            productList: productListContents,
            facetLoading: false,
            currentPageProductCount: recordSetCount,
            totalProducts: recordSetTotal,
            facets,
            priceFacet,
            loading: false,
            plpError: false,
            pageLoading: false,
            productComparison,
          };
        }
      }
    );

    builder.addCase(
      FILTER_BY_PRICE_RANGE_ACTION,
      (state: IProductListState, action: any) => {
        const { maxPrice, minPrice } = action.payload as {
          maxPrice: string;
          minPrice: string;
        };

        const { selectedFacets, selectedGroups } = current(state);

        const priceFacetIndex = selectedFacets.findIndex(
          ({ isPriceFacet }) => isPriceFacet
        );

        const currentlySelectedFacets = [...selectedFacets];

        const priceFacet: IProductListFacetsEntry = {
          label: `${minPrice}-${maxPrice}`,
          isPriceFacet: true,
          count: '',
          extendedData: { uniqueId: '' },
          value: '',
        };

        const updatedSelectedGroup: ISelectedFacetGroups = {
          ...selectedGroups,
          Price_Range: [priceFacet],
        };

        let updatedSelectedFacets: IProductListFacetsEntry[] = [];

        if (priceFacetIndex > -1) {
          currentlySelectedFacets.splice(priceFacetIndex, 1);

          updatedSelectedFacets = [...[priceFacet], ...currentlySelectedFacets];
        } else {
          updatedSelectedFacets = [...[priceFacet], ...currentlySelectedFacets];
        }

        return {
          ...state,
          maxPrice,
          minPrice,
          selectedFacets: updatedSelectedFacets,
          selectedGroups: updatedSelectedGroup,
        };
      }
    );

    builder.addCase(
      CLEAR_PRICE_RANGE_FACET_ACTION,
      (state: IProductListState) => {
        const { selectedFacets, selectedGroups } = current(state);

        const updatedSelectedFacets: IProductListFacetsEntry[] = [
          ...selectedFacets,
        ];

        const updatedSelectedGroups: ISelectedFacetGroups = {
          ...selectedGroups,
        };

        const priceRangeFacetIndex = updatedSelectedFacets.findIndex(
          ({ isPriceFacet }) => isPriceFacet
        );

        updatedSelectedFacets.splice(priceRangeFacetIndex, 1);

        delete updatedSelectedGroups['Price_Range'];

        return {
          ...state,
          selectedFacets: updatedSelectedFacets,
          selectedGroups: updatedSelectedGroups,
          minPrice: '',
          maxPrice: '',
        };
      }
    );

    builder.addCase(
      SHOW_PLP_META_TAGS_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, showMetaTags: action.payload };
      }
    );

    builder.addCase(
      SHOW_PLP_ERROR_ACTION,
      (state: IProductListState, action: any) => {
        return {
          ...state,
          plpError: action.payload,
          loading: false,
          facetLoading: false,
          productComparison: initProductListState.productComparison,
        };
      }
    );

    builder.addCase(
      SET_PLP_PAGE_LOADING_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, pageLoading: action.payload };
      }
    );

    builder.addCase(
      FETCH_INVENTORY_INFO_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        const shippingInfoResponse: IShippingInfoResponse =
          action.payload as IShippingInfoResponse;

        const currentShippingInfo = current(state).shippingInfo;

        const shippingInfo: IShippingInfoResponse = {
          products: [
            ...currentShippingInfo.products,
            ...shippingInfoResponse.products,
          ],
        };

        return { ...state, shippingInfo };
      }
    );

    builder.addCase(
      TOP_BRANDS_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, topBrands: action.payload };
      }
    );

    builder.addCase(RESET_TOP_BRANDS_ACTION, (state: IProductListState) => {
      return { ...state, topBrands: [] };
    });

    builder.addCase(
      ADD_CATEGORY_IDS_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, categoryIdentifiers: action.payload };
      }
    );

    builder.addCase(
      CURRENT_BRAND_ID_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, currentBrandId: action.payload };
      }
    );

    builder.addCase(
      CURRENT_BRAND_IDENTIFIER_ACTION,
      (state: IProductListState, action: any) => {
        return { ...state, currentBrandIdentifier: action.payload };
      }
    );

    builder.addCase(
      CURRENT_BRAND_INFO_ACTION,
      (state: IProductListState, action: any) => {
        return {
          ...state,
          currentBrandName: action.payload.brandName,
          currentBrandSeo: action.payload.seo,
        };
      }
    );

    builder.addCase(
      FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        const contents = action.payload.contents;

        if (contents && contents.length !== 0) {
          const { subCategories } = current(state);

          const currentSubCategories: ISubCategories[] = [...subCategories];

          contents.forEach((content: any) => {
            const subCategory: ISubCategories = {
              seo: content.seo.href,
              id: content.id,
              identifier: content.identifier,
              label: content.name,
            };

            currentSubCategories.push(subCategory);
          });

          return {
            ...state,
            subCategories: currentSubCategories,
            subCategoriesForCarousel: contents,
          };
        } else {
          return {
            ...state,
            subCategories: [],
            subCategoriesForCarousel: [],
          };
        }
      }
    );

    builder.addCase(
      FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
      (state: IProductListState, action: any) => {
        const contents = action.payload.contents;

        if (contents && contents.length !== 0) {
          const subCategory: ISubCategories = {
            seo: contents[0]?.seo?.href,
            id: contents[0]?.id,
            identifier: contents[0]?.identifier,
            label: contents[0]?.name,
          };

          return { ...state, subCategory };
        }
      }
    );

    /**
     * SET_PROMO_PRODUCT_ID_ACTION
     *
     */
    builder.addCase(
      SET_PROMO_PRODUCT_ID_ACTION,
      (state: IProductListState, action: any) => {
        return {
          ...state,
          promoProductIds: [...state.promoProductIds, action.payload],
        };
      }
    );
  }
);

const searchDisplayReducer = createReducer(
  initSearchDisplayState,
  (builder) => {
    /**
     * SEARCH_DISPLAY_SUCCESS_ACTION
     * updates the seacrh display response with search term
     */
    builder.addCase(
      SEARCH_DISPLAY_SUCCESS_ACTION,
      (state: ISearchDisplayState, action: any) => {
        const { viewTaskName, redirecturl, storeId, searchTerm } =
          action.payload as ISearchDisplayState;

        if (viewTaskName) {
          return {
            ...state,
            redirecturl: redirecturl,
            viewTaskName: viewTaskName,
            storeId: storeId,
            searchTerm: searchTerm,
            noSearchResultsFound: false,
          };
        }
      }
    );

    builder.addCase(
      REDIRECT_TO_PDP_ACTION,
      (state: ISearchDisplayState, action: any) => {
        return {
          ...state,
          pdpRedirectUrl: action.payload,
          noSearchResultsFound: false,
        };
      }
    );

    builder.addCase(
      NO_SEARCH_RESULTS_FOUND_ACTION,
      (state: ISearchDisplayState) => {
        return { ...state, noSearchResultsFound: true };
      }
    );

    /**
     * RESET_SEARCH_DISPLAY_ACTION
     * Responsible to reset the state
     */
    builder.addCase(
      RESET_SEARCH_DISPLAY_ACTION,
      (state: ISearchDisplayState, action: any) => {
        return {
          ...initSearchDisplayState,
        };
      }
    );
  }
);

export { productListPageReducer, searchDisplayReducer };
