import { useSelector } from 'react-redux';
import { seoSelector } from '../../../components/Seo/redux/selector/seo';
import { ProductCardSectionMessagesConstants } from '../../../components/Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { formatToTitleCase } from '../../utils';
import { useCurrentPathName } from '../current-pathname/CurrentPathName';

/**
 * @method useGetPageType Fetches the current page type from the seo config.
 */
const useGetPageType = () => {
  const { PLP_PAGE_TYPE } = ProductCardSectionMessagesConstants;

  const seoConfig = useSelector(seoSelector);

  const { currentPathName, pathname } = useCurrentPathName();

  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const isSearchPage = pathname.includes('search');

  const pageType = !isSearchPage
    ? seoConfig[formattedPath]?.page?.type
    : PLP_PAGE_TYPE;

  return { pageType };
};

export { useGetPageType };
