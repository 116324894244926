import axios, { CancelTokenSource } from 'axios';
import HTMLReactParser, {
  DOMNode,
  domToReact,
  Element,
} from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { storeLocatorSelector } from '../../redux/selectors/storeLocator';
import { useEspotAccessibility } from '../../utils/hooks/espot-accessibility/EspotAccessibility';
import { getRegionAdForStore } from '../../utils/utils';
import { eSpotService } from '../../_foundation/apis/transaction/espot/eSpot.service';
import { CANCEL_ON_UNMOUNT } from '../../_foundation/constants/cancel';
import { REGION_AD } from '../../_foundation/constants/cookie';
import { REQUIRED_ESPOT_ATTRIBUTE_MISSING } from '../../_foundation/constants/error';
import { useSite } from '../../_foundation/hooks/usesite/useSite';
import { NteEspotSkeleton } from './skeleton/NteEspotSkeleton';

/**
 * @interface NteEspotProps
 */
export interface NteEspotProps {
  espotId: string;
  className?: string;
  parseHtml?: boolean;
  setHideEspotComponent?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @component NteEspot renders the espot component.
 */
const NteEspot: React.FC<NteEspotProps> = ({
  espotId,
  className = '',
  parseHtml = true,
  setHideEspotComponent,
}) => {
  const { mySite } = useSite();

  const [espotContents, setEspotContents] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [hideEspot, setHideEspot] = useState<boolean>(false);

  const { currentStoreDetails } = useSelector(storeLocatorSelector);

  const { addAltAttributesToImageCells } = useEspotAccessibility();

  /**
   * @method customHtmlParser
   * Responsible to replace the anchor tag with React Link component
   */
  const customHtmlParser = (content: any) => {
    const replace = (node: DOMNode): any => {
      if (node.type && node.type === 'tag') {
        const nodeElement = node as Element;

        if (
          nodeElement.name === 'a' &&
          parseHtml &&
          nodeElement?.attribs?.href &&
          nodeElement?.attribs?.href.startsWith('/')
        ) {
          return (
            <Link
              to={nodeElement.attribs.href ? nodeElement.attribs.href : '/'}
              className={
                nodeElement.attribs.class ? nodeElement.attribs.class : ''
              }>
              {nodeElement.children && domToReact(nodeElement.children)}
            </Link>
          );
        }
      } else {
        return;
      }
    };

    return HTMLReactParser(content, {
      replace,
    });
  };

  const initializeEspotContent = useCallback(
    async (cancelToken: CancelTokenSource): Promise<void> => {
      if (espotId) {
        try {
          const { storeID, catalogID } = mySite;

          const regionAd =
            currentStoreDetails && getRegionAdForStore(currentStoreDetails);

          const DMESpot_region = sessionStorage.getItem(REGION_AD);

          const espotPayload = {
            storeID,
            catalogID,
            name: espotId,
            dmEspotRegion: regionAd,
          };

          if ((DMESpot_region && regionAd) || !DMESpot_region) {
            const espotResponse = await eSpotService.fetchEspotData(
              espotPayload,
              cancelToken.token
            );

            if (espotResponse) {
              const espotArray =
                espotResponse?.MarketingSpotData[0]
                  ?.baseMarketingSpotActivityData;

              if (espotArray) {
                const allEspotContent = espotArray.map(
                  ({ marketingContentDescription }) =>
                    marketingContentDescription[0].marketingText
                );

                setEspotContents(allEspotContent);

                setLoading(false);
              } else {
                throw new Error(REQUIRED_ESPOT_ATTRIBUTE_MISSING);
              }
            } else {
              throw new Error(REQUIRED_ESPOT_ATTRIBUTE_MISSING);
            }
          }
        } catch (err) {
          console.warn(err);

          setLoading(false);

          setHideEspot(true);

          if (setHideEspotComponent) {
            setHideEspotComponent(true);
          }
        }
      }
    },
    [currentStoreDetails, espotId, mySite, setHideEspotComponent]
  );

  useEffect(() => {
    /**
     * CancelSource indicates the source from which cancels the network request.
     *
     * In this case we need to define this inside a useEffect hook since, it triggers the
     * service call on component mount.
     */
    const cancelSource = axios.CancelToken.source();

    if (mySite) {
      initializeEspotContent(cancelSource);
    }

    return () => {
      cancelSource.cancel(CANCEL_ON_UNMOUNT);

      setEspotContents([]);

      setLoading(true);
    };
  }, [initializeEspotContent, mySite]);

  return !loading ? (
    <>
      {!hideEspot && espotContents && (
        <div
          id={espotId}
          data-espot={espotId}
          className={`cmc-content ${className}`}>
          {/* Render Multiple Espot Contents. */}
          {espotContents.length > 1 && (
            <ul className='cg-cols-auto'>
              {espotContents.map((content: string, index) => (
                <li key={index}>
                  {customHtmlParser(
                    addAltAttributesToImageCells({
                      htmlString: content,
                      attributeName: 'alt',
                      className: 'image-cell',
                    })
                  )}
                </li>
              ))}
            </ul>
          )}

          {/* Render Just one Espot Content. */}
          {espotContents.length > 0 &&
            espotContents.length < 2 &&
            customHtmlParser(espotContents[0])}
        </div>
      )}
    </>
  ) : (
    <NteEspotSkeleton className={className} />
  );
};

export { NteEspot };
