import { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { makeRequest } from '../../axios/axiosConfig';

/**
 * categoryService responsible for making the call to search service.
 */
const CategoriesService = {
  /**
   * `@method`
   * `@name Categories#getV2CategoryResourcesUsingGET`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {array} depthAndLimit ` Depth for @top categories
   ** `@property {array} id ` The list of category identifiers.
   ** `@property {array} identifier ` The list of category identifiers, not the ones assigned by the database.
   ** `@property {integer} parentCategoryId ` Parent category identifier
   ** `@property {integer} storeId (required)` The store ID.
   */

  getV2CategoryResourcesUsingGET(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    let domain = '/search/resources';
    let path = '/api/v2/categories';
    let requestUrl = domain + path;
    let method: Method = 'GET';
    let form: any = {};
    let body = {};
    let header: Headers;
    let queryParameters = new URLSearchParams();
    let formParams = new URLSearchParams();
    let storeId;

    const categoryProfileName = process.env
      .REACT_APP_CATEGORY_QUERY_PROFILE_NAME as string;

    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    if (parameters === undefined) {
      parameters = {};
    }
    if (parameters['storeId'] === undefined && storeId !== null) {
      parameters['storeId'] = storeId;
    }
    let headerValues: any = {};
    headerValues['Accept'] = ['application/json'];
    for (let val of headerValues['Accept']) {
      header.append('Accept', val);
    }
    if (parameters['depthAndLimit'] !== undefined) {
      const name = 'depthAndLimit';
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters['id'] !== undefined) {
      const name = 'id';
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }
    if (parameters['identifier'] !== undefined) {
      const name = 'identifier';
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters['parentCategoryId'] !== undefined) {
      const name = 'parentCategoryId';
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        "Request '/api/v2/categories' missing required parameter storeId"
      );
    }
    if (parameters['storeId'] !== undefined) {
      const name = 'storeId';
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    /**
     * Added queryparam for the productCount
     */
    queryParameters.set('profileName', categoryProfileName);

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        let parameter = parameters.$queryParameters[parameterName];
        queryParameters.set(parameterName, parameter);
      });
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8');
    }
    const accept = header.get('Accept');
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json');
    }
    if (
      header.get('content-type') === 'multipart/form-data' &&
      Object.keys(form).length > 0
    ) {
      let formData = new FormData();
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name);
        } else {
          formData.append(p, form[p]);
        }
      }
      body = formData;
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded');
      for (let p in form) {
        formParams.append(p, form[p]);
      }
      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1];
    }
    queryParameters.sort();
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    );
    return makeRequest(requestOptions);
  },
};

export { CategoriesService };
