import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBody } from '../../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../../Modals/ModalHeader/ModalHeader';
import { PromoCodeConstants } from './PromoCodeConstants';

/**
 * @interface ISeeDetails
 */
interface ISeeDetails {
  shortDescription: string;
  longDescription: string;
  seeDetailsCloseHandler: () => void;
  promoCode: string;
}

/**
 * @component SeeDetails renders the SeeDetails Modal.
 */
const SeeDetails: React.FC<ISeeDetails> = ({
  shortDescription,
  longDescription,
  seeDetailsCloseHandler,
  promoCode,
}) => {
  const {
    PROMOCODE: { PROMO_CODE_DETAILS },
  } = PromoCodeConstants;

  const { t } = useTranslation();

  return (
    <>
      <ModalHeader
        heading={t(PROMO_CODE_DETAILS)}
        id='see-details'
        modalCloseHandler={seeDetailsCloseHandler}
      />

      <ModalBody>
        <>
          <Typography variant='body1' className='prompt-margin color-green'>
            {shortDescription}
          </Typography>
          <Typography variant='body2'>
            <i dangerouslySetInnerHTML={{ __html: longDescription }} />
          </Typography>
        </>
      </ModalBody>
    </>
  );
};

export { SeeDetails };
