import { createReducer, current } from '@reduxjs/toolkit';
import {
  ISaveToListItem,
  ISaveToListItemResponse,
  ISaveToListMenuItem,
  ISaveToListState,
} from '../../../../_foundation/interface/SaveToList/ISaveToList';
import {
  IGiftList,
  IWishListMenu,
  IWishListResponse,
} from '../../../../_foundation/interface/WishList/IWishList';
import {
  ADD_ITEM_TO_MENU_LIST_ACTION,
  GET_ITEM_FROM_MENU_LIST_ACTION,
  RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION,
} from '../actions/save-to-list.actions';

const initSaveToListState: ISaveToListState = {
  listItems: {},
  listMenuItems: [],
};

const saveToListReducer = createReducer(initSaveToListState, (builder) => {
  /**
   * @action GET_ITEM_FROM_MENU_LIST_ACTION
   * Fetches the user product list from the server based on the current user credentials.
   */
  builder.addCase(
    GET_ITEM_FROM_MENU_LIST_ACTION,
    (state: ISaveToListState, action: any) => {
      const { GiftList } = action.payload as IWishListResponse;

      let updatedListItems: ISaveToListItem = {};

      GiftList.forEach((list) => {
        const listItem = list.item;

        const items: ISaveToListItemResponse[] = [];

        let constructItem: ISaveToListItemResponse = {
          descriptionName: list.descriptionName,
          uniqueID: list.uniqueID,
        };

        if (listItem) {
          listItem.forEach((item) => {
            constructItem = {
              ...constructItem,
              giftListItemId: item.giftListItemID,
              productId: item.productId,
              partNumber: item.partNumber,
            };

            items.push(constructItem);
          });

          const constructListItem = {
            [list.descriptionName]: items,
          };

          updatedListItems = { ...updatedListItems, ...constructListItem };
        }
      });

      const listMenuItems: IWishListMenu[] = [];

      GiftList.forEach((giftList: IGiftList) => {
        listMenuItems.push({ item: giftList.descriptionName });
      });

      return { listMenuItems, listItems: updatedListItems };
    }
  );

  /**
   * @action ADD_ITEM_TO_MENU_LIST_ACTION
   * Adds an product under the user created item category.
   * Removes an already added product under the item category.
   */
  builder.addCase(
    ADD_ITEM_TO_MENU_LIST_ACTION,
    (state: ISaveToListState, action: any) => {
      const {
        productId,
        descriptionName,
        isAlreadySaved,
        item,
        uniqueID,
        partNumber,
      } = action.payload;

      const currentState = current(state);

      // Array of the item names formatted to be displayed inside menu list.
      const updateListNames = [...currentState.listMenuItems];

      if (!productId) {
        updateListNames.push(item);

        return { ...state, listMenuItems: updateListNames };
      }

      // Object with item names along with the array of product under each item.
      let updatedList = {
        ...currentState.listItems,
      };

      // Extract the index of the current item name.
      const currentListNameIndex = updateListNames.findIndex(
        (listName) => listName.item === descriptionName
      );

      /**
       * If an item has already saved in a list and the user clicks on the
       * same item name again, we remove that particular product under the item.
       */
      if (isAlreadySaved) {
        // Create a copy of the product lists inside a specific item.
        const currentItemList = [...updatedList[descriptionName]];

        const currentItemIndex = currentItemList.findIndex(
          (currentItem) => currentItem.productId === productId
        );

        /**
         * Remove that product under the item list.
         */
        if (currentItemIndex > -1) {
          currentItemList.splice(currentItemIndex, 1);

          updatedList = {
            ...updatedList,
            [descriptionName]: [...currentItemList],
          };
        }

        // Update the state and break the case.
        return {
          ...state,
          listMenuItems: updateListNames,
          listItems: updatedList,
        };
      }

      /**
       * Create a new menu list for the newly created item.
       */
      if (currentListNameIndex === -1) {
        const newMenuItem: ISaveToListMenuItem = {
          item: descriptionName,
        };

        updateListNames.push(newMenuItem);
      }

      const prevValues = updatedList[descriptionName]
        ? updatedList[descriptionName]
        : [];

      item.forEach((giftCard: any) => {
        // Update the products under that item.
        updatedList = {
          ...updatedList,
          [descriptionName]: [
            ...prevValues,
            {
              descriptionName,
              productId,
              uniqueID,
              giftListItemID: giftCard.giftListItemID,
              partNumber,
            },
          ],
        };
      });

      // Update the state and break the case.
      return {
        ...state,
        listItems: updatedList,
        listMenuItems: updateListNames,
      };
    }
  );

  builder.addCase(RESET_SAVE_TO_LIST_MENU_ITEMS_ACTION, () => {
    return { ...initSaveToListState };
  });
});

export { saveToListReducer };
