import React from 'react';
import { Typography } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { useTranslation } from 'react-i18next';
import ChatLinkConstants from './ChatLink.constants';

/**
 * @component renders the chat link in the checkout header.
 */
const ChatLink: React.FC = () => {
  const { t } = useTranslation();

  const supportClickHandler = (selectedEvent: any): void => {
    if (selectedEvent) {
      window.openMChat();
    }
  };

  const { TEXT } = ChatLinkConstants.TRANSLATIONS;
  return (
    <>
      <div className='header-chat-link'>
        <ChatIcon />
        <Typography onClick={supportClickHandler} variant='body2'>
          {t(TEXT)}
        </Typography>
      </div>
    </>
  );
};

export default ChatLink;
