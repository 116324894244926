import { AxiosRequestConfig } from 'axios';
import { ASSET_LIST } from '../../../components/PDP/PdpPageConstants';
import {
  BrightCovePlayerConfig,
  BVCustomerConfig,
  BVReviewConfig,
  ProductAssetsURL,
} from '../../../constants/ProductHotBarConstant';
import IEspotResponse from '../../interface/Responses/IEspotResponse';
import { makeUnauthorizedRequest } from '../axios/axiosConfig';
import { eSpotService } from '../transaction/espot/eSpot.service';
import { EspotParam } from '../transaction/espot/espotParams.model';

/**
 * @Interface for argument passed in create URl fn
 */
interface IAssetURL {
  imgUrl?: string;
  imgWidth?: number;
  pdfUrl?: string;
}

/**
 * @interface for managing Product assets in redux
 */
export interface IProductAssets {
  images: IAssests[];
  pdfs: IAssests[];
}

/**
 * @interface for images from ASSET API
 */
interface IAssests {
  assetPath: string;
  assetType: string;
  name: string;
  partNumber: string;
  primaryFlag: string;
  sequence: number;
  assetSubtype: string | null;
}

/**
 * @interface for Asset Response From API
 */
interface IAssestsResponse {
  data: [
    {
      value: IAssests[];
    }
  ];
}

const ProductDetailService = {
  /**api call for getting brightcove token */
  async getBrightCoveToken() {
    const tokenRequest: AxiosRequestConfig = {
      url: BrightCovePlayerConfig.TOKEN,
      baseURL: BrightCovePlayerConfig.OUATH,
      method: 'POST',
      params: BrightCovePlayerConfig.PARAMS,
    };
    try {
      const tokenData = await makeUnauthorizedRequest(tokenRequest);
      return tokenData;
    } catch (err) {
      throw err;
    }
  },

  /**api call for brightcove videos */
  async getBrightCoveVideo(token: string, partNumber: string) {
    const videoRequest: AxiosRequestConfig = {
      url: BrightCovePlayerConfig.VIDEO_URL,
      baseURL: BrightCovePlayerConfig.BASE_URL,
      method: 'GET',
      params: {
        query: `(item_num:${partNumber})AND(state:ACTIVE)`,
      },
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    try {
      const videoData = await makeUnauthorizedRequest(videoRequest);
      return videoData;
    } catch (err) {
      throw err;
    }
  },

  /*** API call for getting espot data */
  async getEspotsByName(espotPayload: EspotParam) {
    try {
      const espotResponse: IEspotResponse = await eSpotService.fetchEspotData(
        espotPayload
      );
      return espotResponse;
    } catch (err) {
      throw err;
    }
  },

  /*** API call for getting BV customer Images */
  async getCustomerReviewByPartNumber(partNum: string) {
    const tokenRequest: AxiosRequestConfig = {
      url: BVCustomerConfig.BASE_URL,
      baseURL: BVCustomerConfig.BASE_URL,
      method: 'GET',
      params: {
        ...BVCustomerConfig.PARAMS,
        ...{ filter: 'ProductId:' + partNum },
      },
    };

    try {
      const custPhotograph = [];
      const customerImagesData = await makeUnauthorizedRequest(tokenRequest);
      if (customerImagesData && customerImagesData.Results.length) {
        for (let customerImage of customerImagesData.Results) {
          const reviewObj = {
            id: customerImage.Id,
            userNickname: customerImage.UserNickname,
            reviewText: customerImage.ReviewText,
            rating: customerImage.Rating,
            ratingRange: customerImage.RatingRange,
            submissionTime: customerImage.SubmissionTime,
            photoCount: customerImage.Photos.length,
            allPhotos: customerImage.Photos,
          };
          for (let photos of customerImage.Photos) {
            const photo = {
              reviewObj: reviewObj,
              id: photos.Id,
              caption: photos.Caption,
              thumbnailUrl: photos.Sizes.thumbnail.Url,
              normalSizeUrl: photos.Sizes.normal.Url,
            };
            custPhotograph.push(photo);
          }
        }
      }
      return custPhotograph;
    } catch (err) {
      throw err;
    }
  },

  /*** API call for getting BV review details for SEO */
  async getProductReviewByPartNumber(partNum: string) {
    const tokenRequest: AxiosRequestConfig = {
      url: BVReviewConfig.BASE_URL,
      baseURL: BVReviewConfig.BASE_URL,
      method: 'GET',
      params: {
        ...BVReviewConfig.PARAMS,
        ...{ ProductId: partNum, contentType: 'reviews', rev: 0 },
      },
    };
    try {
      const pdpReviewData = await makeUnauthorizedRequest(tokenRequest);
      const bvPdpData = {
        reviewCount: pdpReviewData?.reviewSummary?.numReviews,
        ratingValue: pdpReviewData?.reviewSummary?.primaryRating?.average,
        bestRating: pdpReviewData?.reviewSummary?.primaryRating?.ratingRange,
      };

      return bvPdpData;
    } catch (err) {
      throw err;
    }
  },

  /**
   * @method getALLAssetsByPartNumber fetch all assets from assets API for part number
   *
   * @param partNumber
   */
  async getALLAssetsByPartNumber(partNumber: string) {
    const { DOCUMENT, IMAGE } = ASSET_LIST;
    const tokenRequest: AxiosRequestConfig = {
      url: ProductAssetsURL.ASSET_LIST_API,
      baseURL: ProductAssetsURL.ASSET_LIST_API,
      method: 'POST',
      headers: ProductAssetsURL.HEADERS,
      data: {
        partNumbers: [partNumber],
      },
    };

    try {
      const productAssets: IProductAssets = {
        images: [],
        pdfs: [],
      };

      const assetsAssociated: IAssestsResponse = await makeUnauthorizedRequest(
        tokenRequest
      );

      assetsAssociated.data.forEach((assets) => {
        assets.value.forEach((asset) => {
          if (asset.assetType.toLowerCase() === DOCUMENT) {
            productAssets.pdfs.push(asset);
          } else if (asset.assetType.toLowerCase() === IMAGE) {
            productAssets.images.push(asset);
          }
        });
      });

      /** for maintaining the sequence of the manuals */
      productAssets.pdfs.sort(ProductDetailService.setAssetSequence);
      /** for maintaining the sequence of the manuals */

      return productAssets;
    } catch (err) {
      throw err;
    }
  },

  /**
   * @method createImageURL create URL for Images and pdf
   *
   * @param assetObj will contain imgurl or pdfurl and size.
   */
  createImageURL(assetObj: IAssetURL) {
    const { IMAGE_DEF_SIZE } = ASSET_LIST;
    let nteImageSrcURL = '';

    const assetUrl = assetObj.imgUrl?.startsWith('/')
      ? assetObj.imgUrl
      : `/${assetObj.imgUrl}`;

    if (assetObj.imgUrl) {
      nteImageSrcURL = `${
        ProductAssetsURL.IMAGE_URL
      }cdn-cgi/image/fit=scale-down,width=${
        assetObj.imgWidth || IMAGE_DEF_SIZE
      }${assetUrl}`;
    } else {
      nteImageSrcURL = `${ProductAssetsURL.IMAGE_URL}${assetObj.pdfUrl}`;
    }
    return nteImageSrcURL;
  },

  /** this is for sorting of the assets based on the sequence provided*/
  setAssetSequence(asset1: IAssests, asset2: IAssests) {
    return asset1.sequence - asset2.sequence;
  },
};

export { ProductDetailService };
