
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IStoreDataResponse from './IStoreDataResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IStoreDataResponse };

  export const IStoreDataResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IResultListItem": {
            "properties": {
                "defaultCatalogId": {
                    "type": "string"
                },
                "defaultCurrency": {
                    "type": "string"
                },
                "defaultLanguageId": {
                    "type": "string"
                },
                "inventorySystem": {
                    "type": "number"
                },
                "storeId": {
                    "type": "string"
                },
                "storeName": {
                    "type": "string"
                }
            },
            "required": [
                "defaultCatalogId",
                "defaultCurrency",
                "defaultLanguageId",
                "inventorySystem",
                "storeId",
                "storeName"
            ],
            "type": "object"
        }
    },
    "properties": {
        "resultList": {
            "items": {
                "$ref": "#/definitions/IResultListItem"
            },
            "type": "array"
        }
    },
    "required": [
        "resultList"
    ],
    "type": "object"
}

;

  export const isIStoreDataResponse = ajv.compile(IStoreDataResponseSchema) as ValidateFunction<
    IStoreDataResponse
  >;

  export default function validateIStoreDataResponse(value: unknown): IStoreDataResponse {
    if (isIStoreDataResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIStoreDataResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IStoreDataResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIStoreDataResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IStoreDataResponse' },
        ) +
          inspect(value)
      );
    }
  }