import { Typography } from '@material-ui/core';
import React from 'react';
import { ModalBody } from '../../Modals/ModalBody/ModalBody';
import { ModalHeader } from '../../Modals/ModalHeader/ModalHeader';

/**
 * @interface ISeePickUpDetailsModalModal
 */
interface ISeePickUpDetailsModalModal {
  /**
   * @prop modalHeaderText Header of the modal.
   */
  modalHeaderText: string;

  /**
   * @prop modalHeaderId Modal header's id.
   */
  modalHeaderId: string;

  /**
   * @prop modalCloseHandler Close handler.
   */
  modalCloseHandler: () => void;
  /**
   * @prop seePickUpIcons Icons.
   */
  seePickUpIcons: Object[];
  /**
   * @prop seePickUpDetails list of seePickUpDetails.
   */
  seePickUpDetails: String[];
}

/**
 * @component ConfirmationModal renders the confirmation modal.
 */
const SeePickUpDetailsModal: React.FC<ISeePickUpDetailsModalModal> = ({
  modalCloseHandler,
  modalHeaderId,
  modalHeaderText,
  seePickUpIcons,
  seePickUpDetails,
}) => (
  <div className='see_pick_up_details_modal'>
    <ModalHeader
      modalCloseHandler={modalCloseHandler}
      heading={modalHeaderText}
      id={modalHeaderId}
    />

    <ModalBody>
      <>
        <ul className='no-wrap pickup-steps section-row'>
          {seePickUpIcons.map((seePickUpIcon: any) => (
            <li className='pickup-steps--row'>
              <seePickUpIcon.icon
                alt='seePickUpIcon.info'
                width='65'
                height='65'
                role='img'
                aria-label={`${seePickUpIcon.info} icon`}
              />
              <Typography variant='h6'>{seePickUpIcon.info}</Typography>
            </li>
          ))}
        </ul>
        <ul className='nte-ul-list'>
          {seePickUpDetails.map((seePickUpDetail) => (
            <li>{seePickUpDetail}</li>
          ))}
        </ul>
      </>
    </ModalBody>
  </div>
);

export { SeePickUpDetailsModal };
