import { useCallback, useEffect } from 'react';

/**
 * @method useEscapeHandler is a custom hook that takes in
 * and executes the callback when the escape key press
 * is detected.
 *
 * @param callbackHandler
 * Pass in the Callback that needs to be executed
 * on escape key press.
 */
const useEscapeHandler = (callbackHandler?: () => void) => {
  /**
   * @callback executeCallback checks if the current
   * key pressed matches the escape key code and
   * executes the callback.
   */
  const executeCallback = useCallback(
    (e: any) => {
      if (e.keyCode === 27) {
        if (callbackHandler) {
          callbackHandler();
        }
      }
    },
    [callbackHandler]
  );

  useEffect(() => {
    window.addEventListener('keydown', executeCallback);
    return () => window.removeEventListener('keydown', executeCallback);
  }, [executeCallback]);
};

export { useEscapeHandler };
