import { decode } from 'html-entities';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SEE_ALL_DEALS, SHOP_ALL } from '../../../../constants/routes';
import { FETCH_BREADCRUMB_ACTION } from '../../../../redux/actions/breadcrumb.actions';
import { breadcrumbSelector } from '../../../../redux/selectors/breadcrumb.selector';
import { productListSelector } from '../../../../redux/selectors/productList.selector';
import {
  formatToTitleCase,
  formatUrlToTitleCase,
} from '../../../../utils/utils';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import { IProductListFacets } from '../../../../_foundation/interface/ProductList/IProductList';
import { NteAccordion } from '../../Accordion/NteAccordion';
import { SoldInStoreFacet } from '../SoldInStore/SoldInStore';
import { FacetBody } from './FacetBody/FacetBody';
import { FacetConstants } from './FacetConstants';
import { FacetSkeleton } from './FacetSkeleton';
import { useLeafPage } from './hooks/LeafPageHook';
import { PriceFacet } from './PriceFacet/PriceFacet';
import { PriceFacetConstants } from './PriceFacet/PriceFacetConstants';

/**
 * @interface IFacet
 */
interface IFacet {
  /**
   * @prop defaultExpanded true if the accordions are expanded, be default.
   */
  defaultExpanded?: boolean;

  facetValues: IProductListFacets[];
}

/**
 * @component Facet renders the list of filters from the Plp response.
 */
const Facet: React.FC<IFacet> = ({ defaultExpanded = true, facetValues }) => {
  const { PRICE_FACET_TITLE, PRICE_IDENTIFIER, SOLD_IN_STORES } =
    PriceFacetConstants;

  const {
    PARENT_CATALOG_GROUP,
    CATEGORY,
    PROMOTION,
    MORE_SAVINGS,
    SAVE_ON_CATEGORIES,
    RATINGS,
    CUSTOMER_RATINGS,
  } = FacetConstants;

  const dispatch = useDispatch();

  const { mySite } = useSite();

  const { t } = useTranslation();

  const history = useHistory();

  const {
    loading,
    priceFacet,
    totalProducts,
    categoryIdentifiers,
    subCategory,
  } = useSelector(productListSelector);

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const { displayableFacet } = useLeafPage(facetValues);

  const isSeeAllDeals: boolean = history.location.pathname === SEE_ALL_DEALS;

  const isShopAll = history.location.pathname.includes(SHOP_ALL);

  const initCategoryBreadcrumbs = useCallback((): void => {
    if (
      categoryIdentifiers.length !== 0 &&
      breadCrumbTrailEntryView.length === 0 &&
      mySite &&
      subCategory.id
    ) {
      dispatch(
        FETCH_BREADCRUMB_ACTION({
          catalogId: mySite.catalogID,
          categoryId: subCategory.id,
          storeID: mySite.storeID,
        })
      );
    }
  }, [
    breadCrumbTrailEntryView,
    categoryIdentifiers,
    dispatch,
    mySite,
    subCategory,
  ]);

  useEffect(() => {
    initCategoryBreadcrumbs();
  }, [initCategoryBreadcrumbs]);

  return (
    <>
      <ul className='filters facets_component'>
        {!loading ? (
          <>
            {displayableFacet &&
              displayableFacet.length > 0 &&
              totalProducts > 0 && (
                <>
                  {displayableFacet.map(
                    (
                      { entry, name, value }: IProductListFacets,
                      index: number
                    ) => (
                      <li key={index}>
                        {entry.length !== 0 && (
                          <>
                            {name === PRICE_IDENTIFIER ||
                            name === RATINGS ||
                            name.toLowerCase() ===
                              SOLD_IN_STORES.toLowerCase() ? (
                              <>
                                {name === PRICE_IDENTIFIER && (
                                  <NteAccordion
                                    defaultExpanded={defaultExpanded}
                                    accordionTitle={t(PRICE_FACET_TITLE)}
                                    children={
                                      <PriceFacet
                                        filterData={priceFacet.entry}
                                      />
                                    }
                                  />
                                )}

                                {name.toLowerCase() ===
                                  SOLD_IN_STORES.toLowerCase() &&
                                  entry.length !== 0 && (
                                    <NteAccordion
                                      defaultExpanded={defaultExpanded}
                                      accordionTitle={formatUrlToTitleCase(
                                        decode(name)
                                      )}
                                      children={
                                        <SoldInStoreFacet
                                          facetGroup={name}
                                          filterData={entry}
                                        />
                                      }
                                    />
                                  )}

                                {name === RATINGS && (
                                  <NteAccordion
                                    defaultExpanded={defaultExpanded}
                                    accordionTitle={t(CUSTOMER_RATINGS)}
                                    children={
                                      <FacetBody
                                        facetGroup={name}
                                        filterData={entry}
                                        isRatings={true}
                                      />
                                    }
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {name === PARENT_CATALOG_GROUP && isShopAll ? (
                                  <NteAccordion
                                    defaultExpanded={defaultExpanded}
                                    accordionTitle={CATEGORY}
                                    children={
                                      <FacetBody
                                        filterData={entry}
                                        facetGroup={name}
                                        parentValue={value}
                                      />
                                    }
                                  />
                                ) : (
                                  <NteAccordion
                                    defaultExpanded={defaultExpanded}
                                    accordionTitle={
                                      name === PARENT_CATALOG_GROUP
                                        ? CATEGORY
                                        : name === PROMOTION
                                        ? isSeeAllDeals
                                          ? t(SAVE_ON_CATEGORIES)
                                          : t(MORE_SAVINGS)
                                        : formatToTitleCase(decode(name))
                                    }
                                    children={
                                      <FacetBody
                                        filterData={entry}
                                        facetGroup={name}
                                        parentValue={value}
                                      />
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </li>
                    )
                  )}
                </>
              )}
          </>
        ) : (
          <FacetSkeleton />
        )}
      </ul>
    </>
  );
};

export { Facet };
