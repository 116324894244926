
  import { inspect } from 'util';
  import Ajv, { ValidateFunction } from 'ajv';
  import IOnlineStoreResponse from './IOnlineStoreResponse';

  export const ajv = new Ajv({
    allErrors: true,
    coerceTypes: false,
    unicode: true,
    useDefaults: true,
  });

  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));

  export type { IOnlineStoreResponse };

  export const IOnlineStoreResponseSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "IAddress": {
            "properties": {
                "addressLine": {
                    "items": {},
                    "type": "array"
                }
            },
            "required": [
                "addressLine"
            ],
            "type": "object"
        },
        "ICatalogIdentifier": {
            "properties": {
                "externalIdentifier": {},
                "uniqueID": {
                    "type": "string"
                }
            },
            "required": [
                "externalIdentifier",
                "uniqueID"
            ],
            "type": "object"
        },
        "IContactInfoIdentifier": {
            "properties": {
                "contactInfoId": {
                    "type": "string"
                },
                "contactPersonId": {
                    "type": "string"
                },
                "contactPersonNickName": {
                    "type": "string"
                }
            },
            "required": [
                "contactInfoId",
                "contactPersonNickName"
            ],
            "type": "object"
        },
        "IContactInfoItem": {
            "properties": {
                "address": {
                    "$ref": "#/definitions/IAddress"
                },
                "contactInfoIdentifier": {
                    "$ref": "#/definitions/IContactInfoIdentifier"
                },
                "languageId": {
                    "type": "string"
                }
            },
            "required": [
                "address",
                "contactInfoIdentifier",
                "languageId"
            ],
            "type": "object"
        },
        "IDefaultCatalogItem": {
            "properties": {
                "catalogIdentifier": {
                    "$ref": "#/definitions/ICatalogIdentifier"
                },
                "storeIdentifier": {
                    "$ref": "#/definitions/IStoreIdentifier"
                },
                "uniqueID": {
                    "type": "string"
                },
                "userData": {
                    "$ref": "#/definitions/IUserData"
                }
            },
            "required": [
                "catalogIdentifier",
                "storeIdentifier",
                "uniqueID",
                "userData"
            ],
            "type": "object"
        },
        "IDescriptionItem": {
            "properties": {
                "description": {
                    "type": "string"
                },
                "displayName": {
                    "type": "string"
                },
                "languageId": {
                    "type": "string"
                }
            },
            "required": [
                "description",
                "displayName",
                "languageId"
            ],
            "type": "object"
        },
        "ILocationInfoItem": {
            "properties": {
                "address": {
                    "$ref": "#/definitions/IAddress"
                },
                "contactInfoIdentifier": {
                    "$ref": "#/definitions/IContactInfoIdentifier"
                },
                "languageId": {
                    "type": "string"
                }
            },
            "required": [
                "address",
                "contactInfoIdentifier",
                "languageId"
            ],
            "type": "object"
        },
        "IRelatedStoresItem": {
            "properties": {
                "relatedStoreId": {
                    "type": "string"
                },
                "relationshipSequence": {
                    "type": "string"
                },
                "relationshipType": {
                    "type": "string"
                },
                "state": {
                    "type": "string"
                }
            },
            "required": [
                "relatedStoreId",
                "relationshipSequence",
                "relationshipType",
                "state"
            ],
            "type": "object"
        },
        "IResultListItem": {
            "properties": {
                "contactInfo": {
                    "items": {
                        "$ref": "#/definitions/IContactInfoItem"
                    },
                    "type": "array"
                },
                "defaultCatalog": {
                    "items": {
                        "$ref": "#/definitions/IDefaultCatalogItem"
                    },
                    "type": "array"
                },
                "description": {
                    "items": {
                        "$ref": "#/definitions/IDescriptionItem"
                    },
                    "type": "array"
                },
                "identifier": {
                    "type": "string"
                },
                "locationInfo": {
                    "items": {
                        "$ref": "#/definitions/ILocationInfoItem"
                    },
                    "type": "array"
                },
                "ownerId": {
                    "type": "string"
                },
                "relatedStores": {
                    "items": {
                        "$ref": "#/definitions/IRelatedStoresItem"
                    },
                    "type": "array"
                },
                "state": {
                    "type": "string"
                },
                "storeId": {
                    "type": "string"
                },
                "storeType": {
                    "type": "string"
                },
                "supportedCurrencies": {
                    "$ref": "#/definitions/ISupportedCurrencies"
                },
                "supportedLanguages": {
                    "$ref": "#/definitions/ISupportedLanguages"
                },
                "userData": {
                    "$ref": "#/definitions/IUserData"
                }
            },
            "required": [
                "contactInfo",
                "defaultCatalog",
                "description",
                "identifier",
                "locationInfo",
                "ownerId",
                "relatedStores",
                "state",
                "storeId",
                "storeType",
                "supportedCurrencies",
                "supportedLanguages",
                "userData"
            ],
            "type": "object"
        },
        "IStoreIdentifier": {
            "properties": {
                "externalIdentifier": {},
                "uniqueID": {
                    "type": "string"
                }
            },
            "required": [
                "externalIdentifier",
                "uniqueID"
            ],
            "type": "object"
        },
        "ISupportedCurrencies": {
            "properties": {
                "defaultCurrency": {
                    "type": "string"
                },
                "supportedCurrencies": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                }
            },
            "required": [
                "defaultCurrency",
                "supportedCurrencies"
            ],
            "type": "object"
        },
        "ISupportedLanguages": {
            "properties": {
                "defaultLanguageId": {
                    "type": "string"
                },
                "supportedLanguages": {
                    "items": {
                        "type": "string"
                    },
                    "type": "array"
                }
            },
            "required": [
                "defaultLanguageId",
                "supportedLanguages"
            ],
            "type": "object"
        },
        "IUserData": {
            "properties": {
                "Exclusion_State_B_CA": {
                    "type": "string"
                },
                "Exclusion_State_B_US": {
                    "type": "string"
                },
                "Exclusion_State_S_CA": {
                    "type": "string"
                },
                "Exclusion_State_S_US": {
                    "type": "string"
                },
                "NT_TOKENX_ID": {
                    "type": "string"
                },
                "NT_TOKENX_SCHEME": {
                    "type": "string"
                },
                "NorthernToolAdvantagePriceList": {
                    "type": "string"
                },
                "State_Included_Countries": {
                    "type": "string"
                },
                "TOKENX_ORIGIN_SERVER": {
                    "type": "string"
                },
                "TOKENX_SECRET_FRAME_KEY": {
                    "type": "string"
                },
                "bazaarVoiceRatingTest": {
                    "type": "string"
                },
                "bvExcludedItems": {
                    "type": "string"
                },
                "clearance.percentage.value": {
                    "type": "string"
                },
                "e-GCPartNumbers": {
                    "type": "string"
                },
                "efAdvantagePartNumbers": {
                    "type": "string"
                },
                "efWarrantyPartNumbers": {
                    "type": "string"
                },
                "google-site-verification": {
                    "type": "string"
                },
                "googleGeocodeCountry": {
                    "type": "string"
                },
                "googleGeocodeURL": {
                    "type": "string"
                },
                "googleServerAPIkey": {
                    "type": "string"
                },
                "hcl.externalContent.enabled": {
                    "type": "string"
                },
                "hcl.imagePath": {
                    "type": "string"
                },
                "hcl.imagePathFilter": {
                    "type": "string"
                },
                "headlessStore": {
                    "type": "string"
                },
                "headlessStore.storeContextRoot": {
                    "type": "string"
                },
                "msvalidate.01": {
                    "type": "string"
                },
                "nte.recaptcha.default.threshold": {
                    "type": "string"
                },
                "nte.recaptcha.secretkey": {
                    "type": "string"
                },
                "onlineInventory": {
                    "type": "string"
                },
                "physicalGCPartNumbers": {
                    "type": "string"
                },
                "productCountPerPage": {
                    "type": "string"
                },
                "storeLocatorDefaultRadius": {
                    "type": "string"
                },
                "storeLocatorResponseCount": {
                    "type": "string"
                },
                "tagline": {
                    "type": "string"
                },
                "userDataField": {
                    "items": {},
                    "type": "array"
                },
                "wc.search.priceMode.compatiblePriceIndex": {
                    "type": "string"
                }
            },
            "type": "object"
        }
    },
    "properties": {
        "recordSetComplete": {
            "type": "boolean"
        },
        "recordSetCount": {
            "type": "number"
        },
        "recordSetStartNumber": {
            "type": "number"
        },
        "recordSetTotal": {
            "type": "number"
        },
        "resourceId": {
            "type": "string"
        },
        "resourceName": {
            "type": "string"
        },
        "resultList": {
            "items": {
                "$ref": "#/definitions/IResultListItem"
            },
            "type": "array"
        }
    },
    "required": [
        "recordSetComplete",
        "recordSetCount",
        "recordSetStartNumber",
        "recordSetTotal",
        "resourceId",
        "resourceName",
        "resultList"
    ],
    "type": "object"
}

;

  export const isIOnlineStoreResponse = ajv.compile(IOnlineStoreResponseSchema) as ValidateFunction<
    IOnlineStoreResponse
  >;

  export default function validateIOnlineStoreResponse(value: unknown): IOnlineStoreResponse {
    if (isIOnlineStoreResponse(value)) {
      return value;
    } else {
      console.error(
        ajv.errorsText(
            isIOnlineStoreResponse.errors!.filter((e: any) => e.keyword !== 'if'),
            { dataVar: 'IOnlineStoreResponse' },
          ) +
            inspect(value),
    );
    
    
      throw new Error(
        ajv.errorsText(
          isIOnlineStoreResponse.errors!.filter((e: any) => e.keyword !== 'if'),
          { dataVar: 'IOnlineStoreResponse' },
        ) +
          inspect(value)
      );
    }
  }