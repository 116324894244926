// Constants for Email Deals.
const EmailDealsConstants = {
  TITLE1: 'FOOTER.EMAIL_DEALS.TITLE_1',
  TITLE2: 'FOOTER.EMAIL_DEALS.TITLE_2',
  SIGNUP: 'FOOTER.EMAIL_DEALS.SIGN_UP',
  CANADA: 'CA',
  SUCCESS_MESSAGE: 'FOOTER.EMAIL_DEALS.SUCESS_MESSAGE',
  MAX_LENGTH: 50,
  E_MAIL: 'email',
  EMAIL_REQUIRED: 'Email address is required.',
  INVALID_FORMAT: 'Email format is invalid.',
  INVALID_CHARACTER: 'Invalid character has been entered.',
  TITLE1_FALLBACK: 'Get Email Deals',
  TITLE2_FALLBACK: 'Sales, Specials + Exclusives',
  SIGNUP_FALLBACK: 'Sign Up',
};

export { EmailDealsConstants };
