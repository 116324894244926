import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

/**
 * @method useCurrentPathName extracts the name of the current path.
 */
const useCurrentPathName = () => {
  const { pathname } = useLocation();

  const [currentPathName, setCurrentPathName] = useState('');

  const intiCurrentPath = useCallback(
    (currentPathName: string) => {
      if (currentPathName) {
        const paths = currentPathName.split('/');

        const currentPath = paths[paths.length - 1];

        setCurrentPathName(currentPath);
      }
    },
    [setCurrentPathName]
  );

  useEffect(() => {
    intiCurrentPath(pathname);

    return () => {
      setCurrentPathName('');
    };
  }, [currentPathName, intiCurrentPath, pathname]);

  return { currentPathName, pathname };
};

export { useCurrentPathName };
