export const REQUIRED_ESPOT_ATTRIBUTE_MISSING =
  'Required e-spot attribute is missing.';

export const REQUIRED_CONTENTS_ARRAY_MISSING_PRODUCTS_BY_PARTNUMBERS =
  'Missing contents in fetch products by partnumbers response';

export const TOP_BRANDS_FACETS_MISSING =
  'Facets attribute missing in the top brands api response.';

export const SHIPPING_OPTION_FAILED = 'Shipping info api service down';
