import { takeLatest } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../actions/tax-information.actions';
import * as WORKERS from '../workers/tax-information.workers';

export function* watchSaga() {
  yield takeLatest(
    ACTION_TYPES.GET_TAX_INFORMATION_ACTION,
    WORKERS.fetchTaxInformation
  );
}
