import { Typography } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import axios, { CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PROMO_PRODUCT_ID_ACTION } from '../../../redux/actions/productList.actions';
import { productListSelector } from '../../../redux/selectors/productList.selector';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import {
  IAssociatedPromotion,
  IProductPromoContent,
} from '../../../_foundation/interface/ProductList/IProductList';
import { PricingAndOfferConstants } from '../Plp/ProductCard/ProductCardSectionMessages/ProductPringAndOffer/PricingAndOfferConstants';
import { ProductPromotionSkeleton } from '../Plp/ProductCard/ProductCardSectionMessages/ProductPringAndOffer/ProductPromotion/ProductPromotionSkeleton';
import { ProductPromoDescription } from './ProductPromoDescription';
import useInViewPort from './ViewPort/useInViewPort';

/**
 * @interface IProductOffer
 */
interface IProductOffer {
  productId: string;
  hideProductOfferHandler?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @component ProductOffer renders the product offer component.
 *
 * @param ProductOffer
 */
const ProductOffer: React.FC<IProductOffer> = ({
  productId,
  hideProductOfferHandler,
}) => {
  const { mySite } = useSite();

  const [productPromo, setProductPromo] = useState<IAssociatedPromotion[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [hideProductOffer, setHideProductOffer] = useState<boolean>(false);

  const { OFFER } = PricingAndOfferConstants;

  const refData = useRef<HTMLDivElement>(null);

  const { inViewport } = useInViewPort(refData);

  const [promoChecks, setPromoChecks] = useState<String[]>([]);

  const { promoProductIds } = useSelector(productListSelector);

  const dispatch = useDispatch();

  /**
   * @callback fetchProductPromo fetches the product offer details for the current product id.
   */
  const fetchProductPromo = useCallback(
    async (cancelSource: CancelTokenSource): Promise<void> => {
      try {
        setIsLoading(true);
        if (productId) {
          const promo = promoChecks.find((promo) => promo === productId);
          setPromoChecks([...promoChecks, productId]);
          if (!promo) {
            dispatch(SET_PROMO_PRODUCT_ID_ACTION(productId));

            const promoResponse: IProductPromoContent =
              await productsService.fetchProductPromoContent(
                productId,
                mySite.storeID,
                cancelSource.token
              );
            if (promoResponse?.associatedPromotions) {
              setProductPromo(promoResponse?.associatedPromotions);
            } else {
              throw new Error();
            }
          } else {
            setIsLoading(false);
          }
        }

        setIsLoading(false);
      } catch (error) {
        setHideProductOffer(true);

        if (hideProductOfferHandler) {
          hideProductOfferHandler(true);
        }

        setIsLoading(false);
      }
    },
    [
      hideProductOfferHandler,
      mySite.storeID,
      productId,
      dispatch,
      promoProductIds,
    ]
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken;

    const cancelSource = cancelToken.source();

    if (mySite && inViewport) {
      fetchProductPromo(cancelSource);
    }
  }, [mySite, fetchProductPromo, inViewport]);

  useEffect(() => {
    setPromoChecks([]);
  }, []);

  return (
    <div ref={refData}>
      {!isLoading && productPromo && !hideProductOffer ? (
        <ul className='product-offer-list'>
          {productPromo.map(
            (promo, index) =>
              promo?.description?.shortDescription && (
                <li className='product_offer' key={index}>
                  <Typography className='pxs offer icon-row'>
                    <LocalOfferIcon />

                    <span className='offer-wrapper'>
                      {OFFER}
                      <span className='promo-description color-green'>
                        {promo?.description?.shortDescription}&nbsp;
                      </span>
                      <ProductPromoDescription promo={promo} />
                    </span>
                  </Typography>
                </li>
              )
          )}
        </ul>
      ) : (
        isLoading && <ProductPromotionSkeleton />
      )}
    </div>
  );
};

export { ProductOffer };
