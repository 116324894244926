import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { seoSelector } from '../../../components/Seo/redux/selector/seo';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { formatToTitleCase } from '../../utils';
import { useCurrentPathName } from '../current-pathname/CurrentPathName';

/**
 * @method useDealsCheck Checks whether a given page is a deals page
 * and extracts the current deals facet value.
 */
const useDealsCheck = () => {
  const { mySite } = useSite();

  const seoConfig = useSelector(seoSelector);

  const { currentPathName } = useCurrentPathName();

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const currentDeals = useMemo(
    () =>
      mySite?.dealPages
        ?.split(',')
        .find(
          (identifier: string) =>
            identifier === seoConfig[formattedPath]?.identifier
        ),
    [formattedPath, mySite, seoConfig]
  );

  const isDealsPage = Boolean(currentDeals);

  const currentDealsFilterValue = mySite?.[`${currentDeals}Filter`];

  return { isDealsPage, currentDeals, currentDealsFilterValue };
};

export { useDealsCheck };
