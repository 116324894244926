import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuListBody } from '../../../../../Menu/MenuListBody/MenuListBody';
import { MenuListHeader } from '../../../../../Menu/MenuListHeader/MenuListHeader';
import { CurrentStoreAddress } from './CurrentStoreAddress/CurrentStoreAddress';
import { StoreDetailsConstants } from './StoreDetailsConstants';

/**
 * @interface IStoreDetails
 */
interface IStoreDetails {
  /**
   * @prop myStoreCloseHandler is responsible for closing
   * the MyStore menu.
   */
  myStoreCloseHandler: () => void;

  /**
   * @prop storeDetails List of menu items rendered in store finder.
   */
  storeDetails: any;

  /**
   * @prop menuClickHandler handles the individual menu
   * item click events.
   */
  menuClickHandler: (selectedEvent?: any) => void;
}

/**
 * @component StoreDetails renders the StoreDetails component.
 *
 * @param IStoreDetails
 */
const StoreDetails: React.FC<IStoreDetails> = ({
  myStoreCloseHandler,
  storeDetails,
  menuClickHandler,
}) => {
  const { STORE_DETAILS_LIST, MY_STORE, MY_STORE_FALLBACK } =
    StoreDetailsConstants;

  const { t, i18n } = useTranslation();

  /**
   * This method is responsible for handling menu list click events.
   * @param selectedEvent carries the event object triggered by menu list onClick.
   */
  const clickHandler = (selectedEvent: any): void => {
    menuClickHandler(selectedEvent);
  };

  return (
    <>
      {storeDetails && (
        <>
          <MenuListHeader
            closeHandler={myStoreCloseHandler}
            menuTitle={
              i18n.exists(MY_STORE).valueOf() ? t(MY_STORE) : MY_STORE_FALLBACK
            }
            showBack={false}
            id='store-locator-header'
          />

          <CurrentStoreAddress
            menuClickHandler={menuClickHandler}
            physicalStoreDetails={storeDetails}
          />

          <MenuListBody
            clickHandler={clickHandler}
            menuItems={STORE_DETAILS_LIST}
          />
        </>
      )}
    </>
  );
};

export { StoreDetails };
