
const EdealsSignupModalConstants = {
  EDEALS_ESPOT_NAME: 'EmailSignUpModal_Initial',
  TRANSLATIONS: {
    EDEALS_MODAL_TITLE: 'EDEALS_SIGNUP.TITLE',
    EDEALS_SIGNUP_EMAIL: 'FORM_FIELDS.EMAIL_ADDRESS',
    EDEALS_SIGNUP_EMAIL_ERROR: 'EDEALS_SIGNUP.MESSAGE.ERROR.EMAIL_FORMAT_INVALID',
    REQUIRED_FIELDS: 'FORM_FIELDS.INDICATES_REQUIRED_INFORMATION',
    TRANSLATION_KEY: {
      EDEALS_SIGNUP_CTA: 'EDEALS_SIGNUP.FORM_SUBMIT',
      EDEALS_SIGNUP_SUCCESS: 'EDEALS_SIGNUP.MESSAGE.SUCCESS',
      EDEALS_SIGNUP_ERROR: 'EDEALS_SIGNUP.MESSAGE.ERROR.SUBMIT_ERROR',
    },
  },
};

export { EdealsSignupModalConstants };
