const FacetConstants = {
  BRAND_FACET_KEY: 'Brand',
  SEO_QUERY_KEY: 'index',
  SEO_QUERY_VALUE: '0',
  FACET_QUERY_KEY: 'f',
  CATEGORY_QUERY_KEY: 'c',
  BRAND_QUERY_KEY: 'b',
  PARENT_CATALOG_GROUP: 'ParentCatalogGroup',
  CATEGORY: 'Category',
  PRICE_LABEL: 'OfferPrice_usd',
  PRICE_RANGE: 'Price_Range',
  PRICE: 'Price',
  MY_STORE: 'APP_BAR.SECONDARY_APP_BAR.STORE_LOCATOR.MY_STORE',
  PROMOTION: 'Promotion',
  COLLECTIONS: 'Collections',
  CATEGORY_PAGE: 'categories',
  MORE_SAVINGS: 'FACETS.MORE_SAVINGS',
  ALL_DEALS_LABEL: 'All Deals',
  SAVE_ON_CATEGORIES: 'FACETS.SAVE_ON_CATEGORIES',
  RATINGS: 'Rating',
  CUSTOMER_RATINGS: 'Customer Ratings',
  ADVANTAGE_EXCLUSIVE: 'Advantage Exclusives',
  INVALID_FACET_QUERY_PARAM:
    'PRODUCT_LIST_PAGE.MESSAGE.ERROR.INVALID_FACET_QUERY_PARAM',
  ALL_DEALS_FACET_VALUE: 'all_deals',
};

export { FacetConstants };
