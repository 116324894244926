import { AxiosRequestConfig } from 'axios';
import { SeoConstants } from '../../../../components/Seo/SeoConstants';
import { BreadcrumbsConstants } from '../../../../components/Widgets/BreadCrumbs/BreadcrumbsConstants';
import { IBreadcrumbRequest } from '../../../interface/Breadcrumb/IBreadcrumb';
import IBreadcrumbsResponse from '../../../interface/Responses/IBreadcrumbsResponse';
import validateIBreadcrumbsResponse from '../../../interface/Responses/IBreadcrumbsResponse.validator';
import { makeRequest } from '../../axios/axiosConfig';
import { BreadcrumbServiceConstants } from './breadcrumb.service.constants';

const breadcrumbService = {
  /**
   * @method fetchBreadcrumb Fetches the breadcrumb values for the current category.
   *
   * @param IBreadcrumbRequest
   */
  async fetchBreadcrumb({
    catalogId,
    categoryId,
    storeID,
  }: IBreadcrumbRequest): Promise<IBreadcrumbsResponse> {
    const { FETCH_BREADCRUMBS } = BreadcrumbServiceConstants;

    const { BRANDS, CATEGORIES } = BreadcrumbsConstants;

    const { BRAND_PATH, CATEGORY_PATH } = SeoConstants;

    const breadCrumbUrl = FETCH_BREADCRUMBS(categoryId, storeID, catalogId);

    try {
      const request: AxiosRequestConfig = {
        url: breadCrumbUrl,
        method: 'GET',
      };

      const breadcrumbResponse: IBreadcrumbsResponse =
        validateIBreadcrumbsResponse(await makeRequest(request));

      let breadcrumbPath =
        breadcrumbResponse?.breadCrumbTrailEntryView[0]?.label;

      if (breadcrumbPath) {
        let path = '';

        if (breadcrumbPath === BRANDS) {
          path = BRAND_PATH;
        }

        if (breadcrumbPath === CATEGORIES) {
          path = CATEGORY_PATH;
        }

        if (path) {
          breadcrumbResponse?.breadCrumbTrailEntryView.forEach((breadCrumb) => {
            if (
              breadCrumb.label !== BRANDS &&
              breadCrumb.label !== CATEGORIES
            ) {
              breadCrumb.seo.href = path + breadCrumb.seo.href;
            }
          });
        }
      }

      return breadcrumbResponse;
    } catch (e) {
      console.log('error', e);

      throw e;
    }
  },
};

export { breadcrumbService };
