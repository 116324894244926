import { createAction } from '@reduxjs/toolkit';
import IGetExtendedUsableShippingResponse from '../../_foundation/interface/Responses/IGetExtendedUsableShippingResponse';
import IGetUsableShippingResponse from '../../_foundation/interface/Responses/IGetUsableShippingResponse';
import {
  IGetUsableShippingDetails,
  IGetUsableShippingMethodDetails,
} from '../../_foundation/interface/Shipping/IUsableShipping';
import * as ACTION_TYPES from '../action-types/usable-shipping-info.action-types';

export const FETCH_USABLE_SHIPPING_INFO_REQUEST_ACTION =
  createAction<IGetUsableShippingDetails>(
    ACTION_TYPES.FETCH_USABLE_SHIPPING_INFO_REQUEST
  );

export const FETCH_USABLE_SHIPPING_INFO_SUCCESS_ACTION = createAction<
  IGetUsableShippingResponse,
  string
>(ACTION_TYPES.FETCH_USABLE_SHIPPING_INFO_SUCCESS);

export const RESET_FETCH_USABLE_SHIPPING_INFO_ACTION = createAction(
  ACTION_TYPES.RESET_FETCH_USABLE_SHIPPING_INFO
);

export const FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST_ACTION =
  createAction<IGetUsableShippingMethodDetails>(
    ACTION_TYPES.FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST
  );

export const FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS_ACTION = createAction<
  IGetExtendedUsableShippingResponse,
  string
>(ACTION_TYPES.FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS);

export const RESET_EXTENDED_USABLE_SHIPPING_INFO_ACTION = createAction(
  ACTION_TYPES.RESET_EXTENDED_USABLE_SHIPPING_INFO
);
