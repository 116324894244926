import { createAction } from '@reduxjs/toolkit';
import {
  IGetSeoAction,
  IGetSeoActionSuccess,
  INavigateToSeoAction,
} from '../../../../_foundation/interface/Seo/ISeo';
import * as ACTIONTYPES from '../action-types/seo';

const GET_SEO_CONFIG_ACTION = createAction<IGetSeoAction>(
  ACTIONTYPES.GET_SEO_CONFIG
);

const GET_SEO_CONFIG_SUCCESS_ACTION = createAction<IGetSeoActionSuccess>(
  ACTIONTYPES.GET_SEO_CONFIG_SUCCESS
);

const NAVIGATE_TO_PLP_ACTION = createAction<INavigateToSeoAction>(
  ACTIONTYPES.NAVIGATE_TO_PLP
);

const REFRESH_SEO_ACTION = createAction(ACTIONTYPES.REFRESH_SEO);

export {
  GET_SEO_CONFIG_ACTION,
  GET_SEO_CONFIG_SUCCESS_ACTION,
  NAVIGATE_TO_PLP_ACTION,
  REFRESH_SEO_ACTION,
};
