export const FETCH_USABLE_SHIPPING_INFO_REQUEST =
  'FETCH_USABLE_SHIPPING_INFO_REQUEST';
export const FETCH_USABLE_SHIPPING_INFO_SUCCESS =
  'FETCH_USABLE_SHIPPING_INFO_SUCCESS';
export const RESET_FETCH_USABLE_SHIPPING_INFO =
  'RESET_FETCH_USABLE_SHIPPING_INFO';
export const FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST =
  'FETCH_EXTENDED_USABLE_SHIPPING_INFO_REQUEST';
export const FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS =
  'FETCH_EXTENDED_USABLE_SHIPPING_INFO_SUCCESS';
export const RESET_EXTENDED_USABLE_SHIPPING_INFO =
  'RESET_EXTENDED_USABLE_SHIPPING_INFO';
