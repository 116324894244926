import { createAction } from '@reduxjs/toolkit';
import { INteAlertMessage } from '../../../_foundation/interface/NteAlert/INteAlert';
import * as ACTION_TYPES from '../action-types/nte-alert.action-types';

const SHOW_NTE_ALERT_ACTION = createAction<INteAlertMessage>(
  ACTION_TYPES.SHOW_NTE_ALERT
);

const CLOSE_NTE_ALERT_ACTION = createAction<any>(ACTION_TYPES.CLOSE_NTE_ALERT);

export { SHOW_NTE_ALERT_ACTION, CLOSE_NTE_ALERT_ACTION };
