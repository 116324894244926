import { AlertProps } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { alertMessageId } from '../../../utils/utils';
import { SHOW_NTE_ALERT_ACTION } from '../actions/nte-alert.actions';

/**
 * @interface ISetNteAlert
 */
interface ISetNteAlert {
  /**
   * message text that needs to be displayed.
   */
  message: string;

  /**
   * severity of the alert. ex: Warning, Success, Info, Error.
   */
  severity?: AlertProps['severity'];
}

/**
 * @method useNteAlert Provides a setAlert function to trigger the alert component.
 */
const useNteAlert = () => {
  const dispatch = useDispatch();

  /**
   * @method setAlert Triggers the alert component with the current message and severity type.
   */
  const setAlert = ({ message, severity }: ISetNteAlert): void => {
    dispatch(
      SHOW_NTE_ALERT_ACTION({
        message,
        messageId: alertMessageId(),
        severity,
      })
    );
  };

  return { setAlert };
};

export { useNteAlert };
